import React from 'react'
import { Modal, Typography, Row, Col } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { ShowNotificationDeleteUserReducer } from '../../Redux/Actions/Users/Users'

const NotificationDeleted = () => {

    const { Title } = Typography

    const dispatch = useDispatch()

    const {
        rex_notification_deleted_users,
        rex_message_notification_deleted_users
    } = useSelector(({users}) => users);

    const handleCancel = () => {
        dispatch(ShowNotificationDeleteUserReducer(false))
    }

    return (
        <Modal 
            title={null}
            centered={true}
            open={rex_notification_deleted_users}
            closable={false}
            width={284}
            mask={true}
            maskClosable={true}
            footer={null}
            zIndex={100000000}
            className='Modal-Notification-User-Created'
        >
            <Row className='Modal-Container-Text-User-Created'>
                <Col span={24} className='Modal-Container-Text-Main'>
                    <Title>
                        ¡Listo!
                    </Title>
                </Col>
                <Col span={24} className='Modal-Container-Text-Description'>
                    <p>{rex_message_notification_deleted_users}</p>
                </Col>
                <Col span={24}>
                    <div
                        onClick={() => handleCancel()}
                        className='Notification-Button-User Notification-Button-User-Created'
                    >
                        Ok
                    </div>
                </Col>
            </Row>
        </Modal>
    )
}

export default NotificationDeleted