import { Button, Form, Input, Modal, Upload } from 'antd'
import React, { useEffect, useState } from 'react'
import { UploadOutlined } from '@ant-design/icons';
import { useDispatch } from 'react-redux';
import { EnviarCorreoDashboardReducer } from '../../../Redux/Actions/Dashboards/Dashboards';

const EnviarCorreoManual = ({
	openModal,
	setOpenModal

}) => {

	const { TextArea } = Input;
	const [ sendingMail, setSendingMail ] = useState(false)

	const [ valueMessage, setValueMessage ] = useState("")

	const dispatch = useDispatch()

	const [form] = Form.useForm();

	const fileList = [
		{
		  uid: '-1',
		  name: 'Dashboard Retail Channel.pdf',
		  status: 'done',
		  url: 'https://backend-spider-kimberly-corporativa.grow-corporate.com/Sistema/bolivia/pdf/Daily%20BO%20Junio%202023.pdf',
		  thumbUrl: 'https://cdn-icons-png.flaticon.com/256/4726/4726010.png',
		},
	];

	const enviarCorreo = async (values) => {
		const data = {...values, message : valueMessage}
		setSendingMail(true)
		const response = await dispatch(EnviarCorreoDashboardReducer(data))
		setSendingMail(false)
		setOpenModal(false)
	}


    return (
        <Modal
			open={openModal}
			closeIcon={false}
			closable={false}
			centered
			zIndex={100000000}
			onCancel={() => {
				setOpenModal(false)
			}}
			footer={null}
		>
			<Form
				form={form}
				name="FormCorreoManual"
				onFinish={enviarCorreo}
				layout='vertical'
				autoComplete='off'
			>
				<Form.Item
					style={{
						marginBottom:'4px'
					}}
					label='Para:'
					name='to'
				>
					<Input
						autoComplete='off'
						placeholder='Correo'
					/>
				</Form.Item>
				<Form.Item
					style={{
						marginBottom:'4px'
					}}
					label='Asunto:'
					name='subject'
				>
					<Input
						placeholder='Asunto'
					/>
				</Form.Item>
				<Form.Item
					label='Mensaje:'
					name='message'
					style={{
						marginBottom:'0px'
					}}
				>
					<TextArea
						onChange={(e) => setValueMessage(e.target.value)}
						rows={2}
						placeholder='Mensaje'
					/>
					<Upload
						action="https://660d2bd96ddfa2943b33731c.mockapi.io/api/upload"
						listType="picture"
						defaultFileList={[...fileList]}
						>
					</Upload>
				</Form.Item>
				<div style={{fontSize:'9px', marginBottom:'10px'}}>
				Este mensaje es estrictamente para el uso de la persona o entidad a quien va dirigida y puede contener información privilegiada, confidencial y de acceso restringido según la legislación aplicable. Si el lector de este mensaje no es el destinatario,  el empleado o agente responsable de entregar este mensaje al destinatario indicado, queda notificado de que cualquier divulgación, distribución o copia de esta comunicación está estrictamente prohibido. Si usted ha recibido esta comunicación por error, le rogamos nos lo notifique inmediatamente a la dirección de respuesta.
				</div>
				<div style={{display:'flex', justifyContent:'end', gap:'10px'}}>
					<Button
						danger
						onClick={() => setOpenModal(false)}
					>Cancelar</Button>
					<Button 
						loading={sendingMail}
						type='primary'
						htmlType="submit"
					>Enviar Correo</Button>
				</div>
			</Form>
        </Modal>
    )
}

export default EnviarCorreoManual