import React from 'react'
import { Typography } from 'antd'

const TitleModules = (props) => {
    const titleModule = props.titleModule
    const textModule = props.textModule
    const { Title } = Typography
    return(
        <>
        <Title
            className="Title-Modules"
            level={2}
        >
            {titleModule}
        </Title>
        <p className="Text-Modules">{textModule}</p>
        </>
    )
}

export default TitleModules