import React from 'react'
import { Link } from 'react-router-dom'
import { ChangeDisabledCollapseSubSubModulesReducer, DeleteDataSubSubModuleReducer, GetDataModulesReducer } from '../../../Redux/Actions/Modules/Modules'
import { useDispatch } from 'react-redux'
import { Dropdown, Modal, Tooltip } from 'antd'
import { HolderOutlined } from '@ant-design/icons'
import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'

const TabListSubSubModules = (props) => {
    const subsubm = props.subsubm
    const lastSubSubmodule = props.lastSubSubmodule
    const idModule = props.idModule
    const moduleId = props.moduleId
    const idSubModule = props.idSubModule
    const { attributes, listeners, setNodeRef, transform, setActivatorNodeRef, isDragging } = useSortable({id: props.id})
    const { confirm } = Modal
    const dispatch = useDispatch()

    const style = {
        transform: CSS.Transform.toString(transform),
        border: isDragging ? '1px solid #EFEBE6' : '',
    }

    const styleButton = {
        touchAction: 'none',
        cursor: isDragging ? 'grabbing' : 'grab',
    }

    const items = [
        {
            key: '1',
            label:  <Link to={`/administrator/modules/edit-modules/${idModule}`}>
                    <span className="Text-Edit-Menu">
                        Editar
                        <div className="Icon-Edit-Menu"></div>
                    </span>
                    </Link>,
        },
        {
            key: '2',
            label:  <span className="Text-Delete-Menu" onClick={() => deleteSubSubModule()}>
                        Eliminar 
                        <div className="Icon-Delete-Menu"></div>
                    </span>,
        },
    ]

    const deleteSubSubModule = () => {
        confirm({
            title: 'Confirmar',
            zIndex: 100000000,
            icon: <></>,
            content: '¿Estas seguro de eliminar el subsubmódulo?',
            width: 260,
            centered: true,
            className: 'Modal-Delete-Module',
            okButtonProps: {className: 'Confirm-Modal-Delete-Module'},
            okText: <>
                        <span className="First-Confirm">Confirmar</span>
                        <span className="Last-Confirm">Confirmar</span>
                    </>,
            cancelButtonProps: {className: 'Cancel-Modal-Delete-Module'},
            cancelText: <>
                            <span className="First-Cancel">Cancelar</span>
                            <span className="Last-Cancel">Cancelar</span>
                        </>,
            async onOk() {
                const response = await dispatch(DeleteDataSubSubModuleReducer(subsubm.ssmid))
                if(response){
                    dispatch(GetDataModulesReducer())
                }
            },
            onCancel() {
            },
        })
    }

    return(
        <div 
            className={`Container-Items-Sub-Sub-Modules ${!subsubm.ssmvisualizacion ? 'Disabled-Card-Item-Sub-Sub-Modules' : ''} ${lastSubSubmodule.key == subsubm.key ? 'Last-Sub-Sub-Modules' : ''}`}
            ref={setNodeRef} 
            style={style} 
            {...attributes}
        >
            <div className="Container-Text-Item-Sub-Sub-Modules">
                <Tooltip
                    placement='bottom'
                    title='Mover'
                    color='#3B72FF'
                    showArrow={false}
                    overlayStyle={{marginTop:'20px', fontSize: '12px', minHeight: '30px'}}
                >
                    <HolderOutlined 
                        ref={setActivatorNodeRef} 
                        style={styleButton} 
                        {...listeners} 
                        className="Icon-DragDrop-Item-Sub-Sub-Modules" 
                    />
                </Tooltip>
                <span className="Text-Items-Sub-Sub-Modules">{subsubm.ssmnombre}</span>
            </div>
            <div className="Container-Icon-Item-Sub-Sub-Modules">
                {
                    !subsubm.ssmvisualizacion
                        ?   <Tooltip
                                placement='bottom'
                                title='Habilitar'
                                color='#3B72FF'
                                showArrow={false}
                                overlayStyle={{marginTop:'20px', fontSize: '12px', minHeight: '30px'}}
                            >
                                <div
                                    onClick={() => dispatch(ChangeDisabledCollapseSubSubModulesReducer(true, moduleId, idSubModule, subsubm.key))}
                                    className="Icon-View-Disabled-Item-Sub-Sub-Modules"
                                ></div>
                            </Tooltip>
                        :   <Tooltip
                                placement='bottom'
                                title='Deshabilitar'
                                color='#3B72FF'
                                showArrow={false}
                                overlayStyle={{marginTop:'20px', fontSize: '12px', minHeight: '30px'}}
                            >
                                <div
                                    onClick={() => dispatch(ChangeDisabledCollapseSubSubModulesReducer(false, moduleId, idSubModule, subsubm.key))}
                                    className="Icon-View-Item-Sub-Sub-Modules"
                                ></div>
                            </Tooltip>
                }
                <Tooltip
                    placement='bottom'
                    title='Opciones'
                    color='#3B72FF'
                    showArrow={false}
                    overlayStyle={{marginTop:'20px', fontSize: '12px', minHeight: '30px'}}
                >
                    <Dropdown
                        menu={{items}} 
                        placement="bottomRight"
                        overlayClassName="Menu-Item-Modules"
                        trigger={['click']}
                    >
                        <div className="Icon-Menu-Item-Sub-Sub-Modules" ></div>
                    </Dropdown>
                </Tooltip>
            </div>
        </div>
    )
}

export default TabListSubSubModules