import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ShowModalListUsersProfileReducer } from '../../Redux/Actions/ProfileTypes/ProfileTypes'
import { Modal, Typography, Row, Col} from 'antd';
import '../../Styles/Components/ProfileTypes/ListUsersProfileType.css'

const ListUsersProfileType = () => {

    const { Title } = Typography
    const dispatch = useDispatch()

    const {
        rex_modal_list_users_profile,
        rex_list_users_profile
    } = useSelector(({profileTypes}) => profileTypes)

    return (
        <Modal
            zIndex={100000000}
            open={rex_modal_list_users_profile}
            footer={false}
            closable={false}
            onCancel={() => dispatch(ShowModalListUsersProfileReducer(false))}
            width={422}
            className='Modal-Notification-List-Users-Profile'
        >
            <Row className='Modal-Container-Text-List-Users-Profile'>
                <Col span={24} className='Modal-Container-Text-Main'>
                    <Title>
                        Lista de usuarios
                    </Title>
                </Col>
                <Col span={24} className='Modal-Container-List-Users-Profile' style={{justifyContent: 'center'}}>
                    <div style={{ display:'flex', flexDirection:'column', justifyContent:'flex-start'}}>
                        <span>Aquí podrás encontrar todos los usuarios que tienen</span>
                        <div style={{display:'flex', justifyContent:'center'}}>
                            <span>este perfil.</span>
                        </div>                    
                    </div>
                </Col>
                <Col span={24} className='Modal-Container-List-Users-Profile' style={{marginTop: '5px'}}>
                    <div style={{display:'flex', flexDirection:'column'}}>
                        {rex_list_users_profile.map( (usu, index_usu) => (
                            <span
                                className={`${usu.estid == 2 ? 'User-Disabled-Profile': 'User-Active-Profile' }`}
                            >{index_usu + 1}. {usu.perpersonas.pernombrecompleto}</span>
                        ))}
                    </div>
                </Col>
            </Row>
        </Modal>
    )
}

export default ListUsersProfileType