export const OBTENER_TIPOS_PERFILES = 'OBTENER_TIPOS_PERFILES'
export const SELECCIONAR_TIPO_PERFIL = 'SELECCIONAR_TIPO_PERFIL'
export const OBTENER_TIPO_PERFIL = 'OBTENER_TIPO_PERFIL'
export const OBTENER_TIPO_PERFIL_COPIA = 'OBTENER_TIPO_PERFIL_COPIA'
export const PERSONALIZAR_TIPO_PERFIL = 'PERSONALIZAR_TIPO_PERFIL'
export const MODULO_PAIS_SELECCIONADO = 'MODULO_PAIS_SELECCIONADO'
export const SELECCIONAR_TODOS_MODULOS = 'SELECCIONAR_TODOS_MODULOS'
export const GET_PROFILE_TYPES = 'GET_PROFILE_TYPES'
export const GET_PROFILE_TYPES_CLONE = 'GET_PROFILE_TYPES_CLONE'
export const GET_PROFILE_TYPE_SELECTED = 'GET_PROFILE_TYPE_SELECTED'
export const MODAL_LIST_USERS_PROFILE = 'MODAL_LIST_USERS_PROFILE'
export const LIST_USERS_PROFILE = 'LIST_USERS_PROFILE'
export const REGISTER_USERS_CONNECTED_MODULES_PROFILE_TYPES = 'REGISTER_USERS_CONNECTED_MODULES_PROFILE_TYPES'
export const GET_DATA_MODULES_PROFILE = 'GET_DATA_MODULES_PROFILE'
export const GET_DATA_MODULES_PROFILE_CLONE = 'GET_DATA_MODULES_PROFILE_CLONE'
export const SELECTED_PROFILE_TYPE_UNIQUE = 'SELECTED_PROFILE_TYPE_UNIQUE'
export const CHECK_ALL_MODULE_COUNTRY = 'CHECK_ALL_MODULE_COUNTRY'
export const PERMISSIONS_COUNTRY = 'PERMISSIONS_COUNTRY'
export const NAME_TYPE_PROFILE = 'NAME_TYPE_PROFILE'
export const GET_UNIQUE_TYPE_PROFILE = 'GET_UNIQUE_TYPE_PROFILE'
export const GET_TYPE_PROFILE_COUNTRY = 'GET_TYPE_PROFILE_COUNTRY'
export const EDIT_TYPE_PROFILE = 'EDIT_TYPE_PROFILE'
export const SELECTED_COUNTRY_PROFILE_TYPE = 'SELECTED_COUNTRY_PROFILE_TYPE'
export const LOADING_PROFILE_TYPE = 'LOADING_PROFILE_TYPE'