export const GET_DATA_CHANNELS_EXECUTIVES               = 'GET_DATA_CHANNELS_EXECUTIVES'
export const GET_DATA_UPDATE_CHANNELS_EXECUTIVES        = 'GET_DATA_UPDATE_CHANNELS_EXECUTIVES'
export const GET_DATA_CHANNELS_EXECUTIVES_CLONE         = 'GET_DATA_CHANNELS_EXECUTIVES_CLONE'
export const LOADING_DATA_CHANNELS_EXECUTIVES           = 'LOADING_DATA_CHANNELS_EXECUTIVES'
export const GET_DATA_FILTERS_TXT_CHANNELS_EXECUTIVES_TOTAL = "GET_DATA_FILTERS_TXT_CHANNELS_EXECUTIVES_TOTAL"
export const DATA_FORM_EDIT_CHANNELS_EXECUTIVE          = 'DATA_FORM_EDIT_CHANNELS_EXECUTIVE'
export const DATA_FILTER_EXECUTIVES_CHANNELS_EXECUTIVE  = 'DATA_FILTER_EXECUTIVES_CHANNELS_EXECUTIVE'
export const DATA_FILTER_CHANNELS_CHANNELS_EXECUTIVE    = 'DATA_FILTER_CHANNELS_CHANNELS_EXECUTIVE'
export const DATA_FILTER_ZONES_CHANNELS_EXECUTIVE       = 'DATA_FILTER_ZONES_CHANNELS_EXECUTIVE'
export const DATE_FILTER_CHANNELS_EXECUTIVE             = 'DATE_FILTER_CHANNELS_EXECUTIVE'
export const DATA_INFO_NO_SELECTED_CHANNELS_EXECUTIVE   = 'DATA_INFO_NO_SELECTED_CHANNELS_EXECUTIVE'
export const DATA_INFO_NO_SELECTED_CHANNELS_EXECUTIVE_CLONE = 'DATA_INFO_NO_SELECTED_CHANNELS_EXECUTIVE_CLONE'



export const GET_DATA_FILTERS_TXT_CHANNELS_EXECUTIVE_TOTAL = 'GET_DATA_FILTERS_TXT_CHANNELS_EXECUTIVE_TOTAL'
export const GET_DATA_FILTERS_CHANNELS_EXECUTIVES_GBA = 'GET_DATA_FILTERS_CHANNELS_EXECUTIVES_GBA'
export const GET_DATA_FILTERS_CHANNELS_EXECUTIVES_CUSTOMER_GROUP = 'GET_DATA_FILTERS_CHANNELS_EXECUTIVES_CUSTOMER_GROUP'
export const GET_DATA_FILTERS_CHANNELS_EXECUTIVES_CHANNEL = 'GET_DATA_FILTERS_CHANNELS_EXECUTIVES_CHANNEL'
export const GET_DATA_FILTERS_CHANNELS_EXECUTIVES_ZONE = 'GET_DATA_FILTERS_CHANNELS_EXECUTIVES_ZONE'
export const GET_DATA_FILTERS_CHANNELS_EXECUTIVES_CLIENT = 'GET_DATA_FILTERS_CHANNELS_EXECUTIVES_CLIENT'
export const GET_DATA_FILTERS_CHANNELS_EXECUTIVES_INCENTIVES = 'GET_DATA_FILTERS_CHANNELS_EXECUTIVES_INCENTIVES'

