import {
    GET_DATA_CHANNELS_EXECUTIVES,
    GET_DATA_CHANNELS_EXECUTIVES_CLONE,
    LOADING_DATA_CHANNELS_EXECUTIVES,
    DATA_FORM_EDIT_CHANNELS_EXECUTIVE,
    DATA_FILTER_EXECUTIVES_CHANNELS_EXECUTIVE,
    DATA_FILTER_CHANNELS_CHANNELS_EXECUTIVE,
    DATA_FILTER_ZONES_CHANNELS_EXECUTIVE,
    DATA_INFO_NO_SELECTED_CHANNELS_EXECUTIVE,
    DATA_INFO_NO_SELECTED_CHANNELS_EXECUTIVE_CLONE,
    GET_DATA_UPDATE_CHANNELS_EXECUTIVES,
    DATE_FILTER_CHANNELS_EXECUTIVE,


    GET_DATA_FILTERS_TXT_CHANNELS_EXECUTIVE_TOTAL,
    GET_DATA_FILTERS_CHANNELS_EXECUTIVES_GBA,
    GET_DATA_FILTERS_CHANNELS_EXECUTIVES_CHANNEL,
    GET_DATA_FILTERS_CHANNELS_EXECUTIVES_ZONE,
    GET_DATA_FILTERS_CHANNELS_EXECUTIVES_CLIENT,
    GET_DATA_FILTERS_CHANNELS_EXECUTIVES_INCENTIVES,
    GET_DATA_FILTERS_CHANNELS_EXECUTIVES_CUSTOMER_GROUP
} from "../../../Constants/ChannelsExecutive/ChannelsExecutive"

const INIT_STATE = {
    rex_data_channels_executives                    : [],
    rex_date_filter_channels_executives             : "",
    rex_data_update_channels_executives             : [],
    rex_data_channels_executives_clone              : [],
    rex_data_form_edit_channels_executive           : [],
    rex_data_filter_executives_channels_executive   : [],
    rex_data_filter_channels_channels_executive     : [],
    rex_data_filter_zones_channels_executive        : [],
    rex_data_info_no_selected_channels_executive_clone    : {
                                                        dataZones : [], 
                                                        dataShipTo: [], 
                                                        dataChannel : []
    },
    rex_data_info_no_selected_channels_executive    : {
                                                        dataZones : [], 
                                                        dataShipTo: [], 
                                                        dataChannel : []
                                                    },
    rex_loading_channels_executives                 : true,


    rex_filters_channels_executive_program_total : {
        rex_filter_channels_executive_program : {
            rex_txt_uniqueChannels : "",
            rex_txt_unique_zona : "",
            rex_txt_unique_cliente : "",
            rex_txt_unique_sucursal : "",
            rex_txt_unique_gba : "",
            rex_txt_unique_incentive : "",
            rex_txt_unique_customer_group : "",
        },
    },
    rex_filters_items_gba : [],
    rex_filters_items_channel : [],
    rex_filters_items_zone : [],
    rex_filters_items_client : [],
    rex_filters_items_incentive : [],
    rex_filters_items_customer_group : [],
}

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_DATA_FILTERS_CHANNELS_EXECUTIVES_CUSTOMER_GROUP: {
            return {
                ...state,
                rex_filters_items_customer_group : action.payload
            }
        }
        case DATE_FILTER_CHANNELS_EXECUTIVE: {
            return {
                ...state,
                rex_date_filter_channels_executives : action.payload
            }
        }
        case GET_DATA_FILTERS_CHANNELS_EXECUTIVES_INCENTIVES: {
            return {
                ...state,
                rex_filters_items_incentive : action.payload
            }
        }
        case GET_DATA_FILTERS_CHANNELS_EXECUTIVES_CLIENT: {
            return {
                ...state,
                rex_filters_items_client : action.payload
            }
        }
        case GET_DATA_FILTERS_CHANNELS_EXECUTIVES_ZONE: {
            return {
                ...state,
                rex_filters_items_zone : action.payload
            }
        }
        case GET_DATA_FILTERS_CHANNELS_EXECUTIVES_CHANNEL: {
            return {
                ...state,
                rex_filters_items_channel : action.payload
            }
        }
        case GET_DATA_FILTERS_CHANNELS_EXECUTIVES_GBA: {
            return {
                ...state,
                rex_filters_items_gba : action.payload
            }
        }
        case GET_DATA_FILTERS_TXT_CHANNELS_EXECUTIVE_TOTAL: {
            return {
                ...state,
                rex_filters_channels_executive_program_total : action.payload
            }
        }
        case GET_DATA_UPDATE_CHANNELS_EXECUTIVES: {
            return {
                ...state,
                rex_data_update_channels_executives : action.payload
            }
        }
        case DATA_INFO_NO_SELECTED_CHANNELS_EXECUTIVE_CLONE: {
            return {
                ...state,
                rex_data_info_no_selected_channels_executive_clone : action.payload
            }
        }
        case DATA_INFO_NO_SELECTED_CHANNELS_EXECUTIVE: {
            return {
                ...state,
                rex_data_info_no_selected_channels_executive : action.payload
            }
        }
        case DATA_FILTER_ZONES_CHANNELS_EXECUTIVE: {
            return {
                ...state,
                rex_data_filter_zones_channels_executive : action.payload
            }
        }
        case DATA_FILTER_CHANNELS_CHANNELS_EXECUTIVE: {
            return {
                ...state,
                rex_data_filter_channels_channels_executive : action.payload
            }
        }
        case DATA_FILTER_EXECUTIVES_CHANNELS_EXECUTIVE: {
            return {
                ...state,
                rex_data_filter_executives_channels_executive : action.payload
            }
        }
        case DATA_FORM_EDIT_CHANNELS_EXECUTIVE: {
            return {
                ...state,
                rex_data_form_edit_channels_executive : action.payload
            }
        }
        case GET_DATA_CHANNELS_EXECUTIVES: {
            return {
                ...state,
                rex_data_channels_executives : action.payload
            }
        }
        case GET_DATA_CHANNELS_EXECUTIVES_CLONE: {
            return {
                ...state,
                rex_data_channels_executives_clone : action.payload
            }
        }
        case LOADING_DATA_CHANNELS_EXECUTIVES: {
            return {
                ...state,
                rex_loading_channels_executives : action.payload
            }
        }

        default:
            return state
    }
}
