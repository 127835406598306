import React, { useState } from 'react'
import { Row, Col, Checkbox, Input } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { EditValueChannelExecutiveReducer, FilterChannelChannelsExecutivesReducer, FilterShipToChannelsExecutivesReducer, FilterZonesChannelsExecutivesReducer, SelectAllChannelsChannelsExecutivesReducer, SelectAllShipToChannelsExecutivesReducer, SelectAllZonesChannelsExecutivesReducer, SelectChannelChannelsExecutivesReducer, SelectZonesChannelsExecutivesReducer } from '../../Redux/Actions/ChannelsExecutives/ChannelsExecutives'
import '../../Styles/Components/ChannelsExecutives/SelectionRowsChannelsExecutives.css'
import { 
    CaretUpOutlined,
    CaretDownOutlined,
    FilterOutlined
} from '@ant-design/icons'

const SelectionRowsChannelsExecutives = () => {
    
    const dispatch = useDispatch()
    const [ showFilterDT, setShowFilterDT ] = useState(false)
    const [ showFilterZone, setShowFilterZone ] = useState(false)
    const [ showFilterChannel, setShowFilterChannel ] = useState(false)
    const [ txtChannel, setTxtChannel ] = useState({selected : false, id : null, txt : ""})
    const [ txtZone, setTxtZone ] = useState({selected : false, id : null, txt : ""})
    const [ txtShipTo, setTxtShipTo ] = useState('')
    const [ orderShipTo, setOrderShipTo ] = useState(null)
    const [ orderZone, setOrderZone ] = useState(null)
    const [ orderChannel, setOrderChannel ] = useState(null)

    const { 
        rex_data_info_no_selected_channels_executive
    } = useSelector(({channelsExecutives}) => channelsExecutives)

    const parseNumber = (value) => {

        const number = parseFloat(value);
        const numberFormated = number.toLocaleString("es-US", { minimumFractionDigits: 0, maximumFractionDigits: 0 });

        return numberFormated
    }

    return (
        <Row className="Container-Selection-Rows-Channels-Executives" gutter={4}>

            {/* CHANNEL */}
            <Col span={8}>
                <div className="Title-Section-Selection-Rows">
                    <Checkbox
                        checked={
                            rex_data_info_no_selected_channels_executive.dataChannel
                            .filter(ch => ch.selected).length == rex_data_info_no_selected_channels_executive.dataChannel.length
                            && rex_data_info_no_selected_channels_executive.dataChannel.length > 0
                            ? true : false
                        }
                        onChange={(e) => {
                            dispatch(SelectAllChannelsChannelsExecutivesReducer(e.target.checked))
                        }}
                    />
                    {
                        showFilterChannel
                        ?   <Input 
                                size='small'
                                placeholder='Filtrar Canal'
                                onChange={(e) => {
                                    setTxtChannel({...txtChannel, txt: e.target.value})
                                    dispatch(FilterChannelChannelsExecutivesReducer(e.target.value, orderChannel))
                                }}
                                style={{marginLeft:"5px"}}
                            />
                        : <>Canal</>
                    }
                    <div style={{display:"flex"}}>
                        <div className="Container-Order-Column-Channels-Executives" >
                            <CaretUpOutlined 
                                onClick={() => {
                                    dispatch(FilterChannelChannelsExecutivesReducer(txtChannel.txt, orderChannel== "asc" ? null : "asc"))
                                    setOrderChannel(orderChannel == "asc" ? null : "asc")
                                }} 
                                style={{cursor:"pointer", color: orderChannel == "asc" ? "#1677ff" : "#0000004a"}} 
                            />
                            <CaretDownOutlined 
                                onClick={() => {
                                    dispatch(FilterChannelChannelsExecutivesReducer(txtChannel.txt, orderChannel== "desc" ? null : "desc"))
                                    setOrderChannel(orderChannel == "desc" ? null : "desc")
                                }} 
                                style={{cursor:"pointer", color: orderChannel == "desc" ? "#1677ff" : "#0000004a"}} 
                            />
                        </div>
                        <div 
                            className="Button-Show-Filter-Channel-Executive"
                            onClick={()=> setShowFilterChannel(!showFilterChannel)} size='small'>
                            <FilterOutlined style={{fontSize: "13px", color: txtChannel.txt.length > 0 ? "#1677ff":"#0000004a"}}/>
                        </div>
                    </div>
                </div>
                {
                    rex_data_info_no_selected_channels_executive.dataChannel.map(ch => {
                        return <div
                            className="Container-Checkbox-Channel-Executives"
                            title={ch.channel}
                        >
                            <Checkbox
                                style={{width:"100%"}}
                                checked={ch.selected}
                                onChange={(e) => {
                                    dispatch(SelectChannelChannelsExecutivesReducer(ch.channel, e.target.checked))
                                    setTxtChannel({...txtZone, selected : e.target.checked, id : ch.channel == txtChannel.id ? null : ch.channel})
                                    if(!e.target.checked){
                                        dispatch(FilterZonesChannelsExecutivesReducer(txtZone.txt, orderZone ))
                                    }
                                }}
                            ><div className="Text-Checkbox-Selection-Rows">{ch.channel}</div>
                            </Checkbox>
                        </div>
                    })
                }
            </Col>

            {/* ZONE */}
            
            <Col span={6}>
                <div className="Title-Section-Selection-Rows">
                    <Checkbox
                        checked={
                            rex_data_info_no_selected_channels_executive.dataZones
                            .filter(zon => zon.selected).length == rex_data_info_no_selected_channels_executive.dataZones.length
                            && rex_data_info_no_selected_channels_executive.dataZones.length > 0
                            ? true : false
                        }
                        onChange={(e) => {
                            dispatch(SelectAllZonesChannelsExecutivesReducer(e.target.checked))
                        }}
                    />
                    {
                        showFilterZone
                        ?   <Input 
                                size='small'
                                placeholder='Filtrar Zona'
                                onChange={(e) => {
                                    setTxtZone({...txtZone, txt: e.target.value})
                                    dispatch(FilterZonesChannelsExecutivesReducer(e.target.value, orderZone ))
                                }}
                                style={{marginLeft:"5px"}}
                            />
                        : <>Zona</>
                    }
                    <div style={{display:"flex"}}>
                        <div className="Container-Order-Column-Channels-Executives" >
                            <CaretUpOutlined 
                                onClick={() => setOrderZone(orderZone == "asc" ? null : "asc")} 
                                style={{cursor:"pointer", color: orderZone == "asc" ? "#1677ff" : "#0000004a"}} 
                            />
                            <CaretDownOutlined 
                                onClick={() => setOrderZone(orderZone == "desc" ? null : "desc")} 
                                style={{cursor:"pointer", color: orderZone == "desc" ? "#1677ff" : "#0000004a"}} 
                            />
                        </div>
                        <div
                            className="Button-Show-Filter-Channel-Executive"
                            onClick={()=> setShowFilterZone(!showFilterZone)} size='small'>
                            <FilterOutlined style={{fontSize: "13px", color: txtZone.txt.length > 0 ? "#1677ff":"#0000004a"}}/>
                        </div>
                    </div>
                </div>

                {
                    rex_data_info_no_selected_channels_executive.dataZones.map(zon => {
                        return <div 
                                className="Container-Checkbox-Channel-Executives"
                                title={zon.zone}>
                                <Checkbox
                                    checked={zon.selected}
                                    onChange={(e) => {
                                        dispatch(SelectZonesChannelsExecutivesReducer(zon.zone, e.target.checked))
                                        if(!e.target.checked){
                                            dispatch(FilterShipToChannelsExecutivesReducer(txtShipTo, orderShipTo))
                                        }
                                    }}
                                    style={{width:"100%"}}
                                    
                                ><div className="Text-Checkbox-Selection-Rows">{zon.zone}</div></Checkbox>
                            </div>
                    })
                }
            </Col>

            {/* DT */}
            <Col span={10}>
                <div className="Title-Section-Selection-Rows">
                    <Checkbox
                        onChange={(e) => {
                            dispatch(SelectAllShipToChannelsExecutivesReducer(e.target.checked))
                        }}
                        checked={
                            rex_data_info_no_selected_channels_executive.dataShipTo
                                .filter(shi => shi.selected).length == rex_data_info_no_selected_channels_executive.dataShipTo.length
                                && rex_data_info_no_selected_channels_executive.dataShipTo.length > 0
                            ? true : false}
                    />
                    {
                        showFilterDT
                        ?   <Input
                                size='small'
                                placeholder='Filtrar DT'
                                onChange={(e) => {
                                    setTxtShipTo(e.target.value)
                                    dispatch(FilterShipToChannelsExecutivesReducer(e.target.value, orderShipTo))
                                }}
                                style={{marginLeft:"5px"}}
                            />
                        : <>Distribuidoras ({parseNumber(rex_data_info_no_selected_channels_executive.dataShipTo.length)})</>
                    }
                    <div style={{display:"flex"}}>
                        <div className="Container-Order-Column-Channels-Executives" >
                            <CaretUpOutlined 
                                onClick={() => setOrderShipTo(orderShipTo == "asc" ? null : "asc")} 
                                style={{cursor:"pointer", color: orderShipTo == "asc" ? "#1677ff" : "#0000004a"}} 
                            />
                            <CaretDownOutlined 
                                onClick={() => setOrderShipTo(orderShipTo == "desc" ? null : "desc")} 
                                style={{cursor:"pointer", color: orderShipTo == "desc" ? "#1677ff" : "#0000004a"}} 
                            />
                        </div>
                        <div 
                            className="Button-Show-Filter-Channel-Executive"
                            onClick={()=> setShowFilterDT(!showFilterDT)} size='small'
                        >
                            <FilterOutlined style={{fontSize: "13px", color: txtShipTo.length > 0 ? "#1677ff":"#0000004a"}}/>
                        </div>
                    </div>
                    
                </div>
                {
                    rex_data_info_no_selected_channels_executive.dataShipTo.map(shi => {
                        return <div
                                    className="Container-Checkbox-Channel-Executives"
                                    title={`${shi.ship_to} (${shi.cod_sold_to})`}
                                >
                                    <Checkbox 
                                        style={{width:"100%"}}
                                        checked={shi.selected}
                                        
                                        onChange={(e) => dispatch(EditValueChannelExecutiveReducer(e.target.checked, shi))}
                                    ><div className="Text-Checkbox-Selection-Rows">{shi.ship_to} ({shi.cod_sold_to})</div></Checkbox>
                                </div>
                    })
                }
            </Col>
        </Row>
    )
}

export default SelectionRowsChannelsExecutives