export const REGISTER_USERS_CONNECTED_MODULES_ANALYTICS = 'REGISTER_USERS_CONNECTED_MODULES_ANALYTICS'
export const OBTENER_VISTAS_USUARIO_ANALYTICS           = 'OBTENER_VISTAS_USUARIO_ANALYTICS'
export const OBTENER_VISTAS_ANALYTICS                   = 'OBTENER_VISTAS_ANALYTICS'
export const OBTENER_VISTAS_PROMEDIOS_MINUTOS_ANALYTICS = 'OBTENER_VISTAS_PROMEDIOS_MINUTOS_ANALYTICS'
export const OBTENER_USUARIOS_GANADOS_ANALYTICS         = 'OBTENER_USUARIOS_GANADOS_ANALYTICS'
export const OBTENER_USUARIOS_PERDIDOS_ANALYTICS        = 'OBTENER_USUARIOS_PERDIDOS_ANALYTICS'
export const OBTENER_VISTAS_MODULOS_ANALYTICS           = 'OBTENER_VISTAS_MODULOS_ANALYTICS'
export const OBTENER_VISTAS_SUBMODULOS_ANALYTICS        = 'OBTENER_VISTAS_SUBMODULOS_ANALYTICS'
export const OBTENER_DATA_SLIDER_ANALYTICS              = 'OBTENER_DATA_SLIDER_ANALYTICS'
export const OBTENER_VISTAS_SUBSUBMODULOS_ANALYTICS     = 'OBTENER_VISTAS_SUBSUBMODULOS_ANALYTICS'
export const OBTENER_DATA_FECHAS_ANALYTICS              = 'OBTENER_DATA_FECHAS_ANALYTICS'
