import React from 'react'
import { useDispatch } from 'react-redux'
import { Dropdown, Modal, Tooltip } from 'antd'
import { HolderOutlined } from '@ant-design/icons'
import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import { ChangeDisabledSubsubmodulesReducer, ChangeValueFormReducer, RemoveSubSubModuleReducer } from '../../../Redux/Actions/Modules/CreateEditModules'
import { DeleteDataSubSubModuleReducer } from '../../../Redux/Actions/Modules/Modules'

const ListItemsSubSubModules = (props) => {
    const subsubmodule = props.subsubmodule
    const keysub = props.keysub
    const lastSubsubmodule = props.lastSubsubmodule
    const { confirm } = Modal
    const { attributes, listeners, setNodeRef, transform, setActivatorNodeRef, isDragging } = useSortable({id: props.id})
    const dispatch = useDispatch()

    const style = {
        transform: CSS.Transform.toString(transform),
    }

    const styleButton = {
        touchAction: 'none',
        cursor: isDragging ? 'grabbing' : 'grab',
    }

    const items = [
        {
            key: '1',
            label:  <span className="Text-Edit-Subsubmodules" onClick={() => dispatch(ChangeValueFormReducer(props.id, true))}>
                        Editar
                        <div className="Icon-Edit-Menu"></div>
                    </span>,
        },
        {
            key: '2',
            label:  <span className="Text-Delete-Subsubmodules" onClick={() => deleteSubSubModule()}>
                        Eliminar 
                        <div className="Icon-Delete-Menu"></div>
                    </span>,
        },
    ]

    const deleteSubSubModule = () => {
        confirm({
            title: 'Confirmar',
            zIndex: 100000000,
            icon: <></>,
            content: '¿Estas seguro de eliminar el sub submódulo?',
            width: 260,
            centered: true,
            className: 'Modal-Delete',
            okButtonProps: {className: 'Confirm-Modal-Delete'},
            okText: <>
                        <span className="First-Confirm">Confirmar</span>
                        <span className="Last-Confirm">Confirmar</span>
                    </>,
            cancelButtonProps: {className: 'Cancel-Modal-Delete'},
            cancelText: <>
                            <span className="First-Cancel">Cancelar</span>
                            <span className="Last-Cancel">Cancelar</span>
                        </>,
            async onOk() {
                if(subsubmodule.ssmsubmodulosnew){
                    dispatch(RemoveSubSubModuleReducer(keysub, subsubmodule.key))
                }else{
                    const response = await dispatch(DeleteDataSubSubModuleReducer(subsubmodule.smoid))
                    if(response){
                        dispatch(RemoveSubSubModuleReducer(keysub, subsubmodule.key))
                    }
                }
            },
            onCancel() {
            },
        })
    }

    return(
        <div 
            className={`Card-Item-Subsubmodules ${subsubmodule.validation ? 'Error-Subsubmodule' : ''} ${lastSubsubmodule.key == subsubmodule.key ? 'Subsubmodules-Disabled-Border-Bottom' : ''}`}
            ref={setNodeRef} 
            style={style} 
            {...attributes}
        >
            <div className="Container-DrapDrop">
                <Tooltip
                    placement='bottom'
                    title='Mover'
                    color='#3B72FF'
                    showArrow={false}
                    overlayStyle={{marginTop:'20px', fontSize: '12px', minHeight: '30px'}}
                >
                    <HolderOutlined 
                        className="DragDrop-Subsubmodules"
                        ref={setActivatorNodeRef} 
                        style={styleButton} 
                        {...listeners} 
                    />
                </Tooltip>
                <p className={`Text-Subsubmodules ${!subsubmodule.ssmvisualizacion ? 'Text-Disable-Subsubmodules' : ''}`}>
                    {subsubmodule.ssmnombre}
                </p>
            </div>
            <div className="Container-Options">
                {
                    !subsubmodule.ssmvisualizacion
                    ?   <Tooltip
                            placement='bottom'
                            title='Habilitar'
                            color='#3B72FF'
                            showArrow={false}
                            overlayStyle={{marginTop:'20px', fontSize: '12px', minHeight: '30px'}}
                        >
                            <div 
                                className="View-Disable-Subsubmodules"
                                onClick={() => dispatch(ChangeDisabledSubsubmodulesReducer(true, keysub, subsubmodule.key))}
                            ></div>
                        </Tooltip>
                    :   <Tooltip
                            placement='bottom'
                            title='Deshabilitar'
                            color='#3B72FF'
                            showArrow={false}
                            overlayStyle={{marginTop:'20px', fontSize: '12px', minHeight: '30px'}}
                        >
                            <div 
                                className="View-Subsubmodules"
                                onClick={() => dispatch(ChangeDisabledSubsubmodulesReducer(false, keysub, subsubmodule.key))}
                            ></div>
                        </Tooltip>
                }
                <Tooltip
                    placement='bottom'
                    title='Opciones'
                    color='#3B72FF'
                    showArrow={false}
                    overlayStyle={{marginTop:'20px', fontSize: '12px', minHeight: '30px'}}
                >
                    <Dropdown
                        menu={{items}} 
                        placement="bottomRight"
                        overlayClassName="Menu-Subsubmodules"
                        trigger={['click']}
                    >
                        <div className="Options-Subsubmodules"></div>
                    </Dropdown>
                </Tooltip>
            </div>
        </div>
    )
}

export default ListItemsSubSubModules