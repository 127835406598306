import React, { useEffect, useRef, useState } from 'react'
import { Button } from 'antd'
import Mute from '../../Assets/Images/Preload/Mute.png'
import Unmute from '../../Assets/Images/Preload/Unmute.png'
import Skip from '../../Assets/Images/Preload/Skip.png'
import videoLoginPreloadW from '../../Assets/Images/Login/Videofinal.mp4'
// import videoLoginPreloadW from '../../Assets/Images/Login/Intro_v2.mp4'
// import videoLoginPreloadW from '../../Assets/Images/Login/Intro_v1.webm'

const PreloadLogin = () => {
    const [activeLoginPreload, setActiveLoginPreload] = useState(false)
    const [activeMutedLoginPreload, setActiveMutedLoginPreload] = useState(true)
    const videoRef = useRef(null)

    const mutedPreloadLogin = () => {
        setActiveMutedLoginPreload(prevMuted => !prevMuted)
        if(videoRef.current){
            videoRef.current.muted = !activeMutedLoginPreload
        }
    }

    const stopVideoPreloadLogin = () => {
        setActiveLoginPreload(true)
        setTimeout(() => {
            if(videoRef.current){
                videoRef.current.currentTime = 44
            }
        }, 1000)
    }

    useEffect(() => {
        setTimeout(() => {
            setActiveLoginPreload(true)
        }, 44000)
    }, [])
    
    return(
        <div className={`Container-Preload-Login ${activeLoginPreload ? 'Active-Hide-Preload-Login' : ''}`}>
            <video style={{position: 'absolute', zIndex: 3, left: 0, width: '100%', height: activeLoginPreload ? '0' : '100vh', top: '0', objectFit: 'cover', transition: '.8s height ease-in'}} muted autoPlay controls={false} ref={videoRef} src={videoLoginPreloadW}></video>
            <div className="Container-Button-Preload-Login">
                <Button className={`Unmute-Preload-Login${activeMutedLoginPreload ?"-Active" : ""}`} onClick={() => mutedPreloadLogin()}>
                    {activeMutedLoginPreload ? <>Activar audio <img src={Unmute} width={20} height={20} /></> : <>Silenciar audio <img src={Mute} width={20} height={20} /></>}
                </Button>
                <Button className="Skip-Preload-Login" onClick={() => stopVideoPreloadLogin()}>
                    Omitir video <img src={Skip} width={10} height={15} />
                </Button>
            </div>
        </div>
    )
}

export default PreloadLogin