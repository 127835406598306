import config from '../../../config'
import {
    GET_MODULE_DATA,
    GET_MODULE_DATA_CLONE,
    LOADING_SAVE_DATA,
} from "../../../Constants/Modules/CreateEditModules"
import { GET_DATA_MODULES } from '../../../Constants/Modules/Modules'
import { notification } from 'antd'
const notificacionServidor = (type, mensaje) => {
    if(type === 'success'){
        notification['success']({
            message: 'Éxito',
            description: mensaje,
        })
    }else if(type === 'warning'){
        notification['warning']({
            message: 'Advertencia',
            description: mensaje,
        })
    }else if(type === 'error'){
        notification['error']({
            message: 'Error',
            description: mensaje,
        })
    }
}

export const GetDataModuleReducer = (id_module) => async (dispatch, getState) => {
    let dataModule = {}
    if(id_module){
        await fetch(config.api+'modules/get-module-data',
        {
            mode:'cors',
            method: 'POST',
            headers: {
                'Accept' : 'application/json',
                'Content-type' : 'application/json',
                'usutoken'	   : localStorage.getItem('usutoken'),
                'middle_usuario' : localStorage.getItem('usutoken'),
            },
            body: JSON.stringify({
                req_id_module: id_module
            }),
        })
        .then(res => res.json())
        .then(data => {
            if(data.response){
                dataModule = data.data
                dataModule.modtienesubmodulos = dataModule.smosubmodulos.length > 0 ? true : false
                dataModule.modNamCou = dataModule.paipaises.painombre

                dataModule.modruta = dataModule.modruta.replace("/Sistema/dashboards/", "");

                dataModule.validation = false
                dataModule.smosubmodulos.map((smo, indexsmo) => {
                    dataModule["smosubmodulos"][indexsmo]["key"] = `smosubmodulos-${indexsmo + 1}`
                    dataModule["smosubmodulos"][indexsmo]["smotienesubmodulos"] = smo.ssmsubmodulos.length > 0 ? true : false
                    dataModule["smosubmodulos"][indexsmo]["smosubmodulosnew"] = false
                    dataModule["smosubmodulos"][indexsmo]["smoformulario"] = false
                    dataModule["smosubmodulos"][indexsmo]["expandible"] = true
                    dataModule["smosubmodulos"][indexsmo]["validation"] = false
                    if(smo.ssmsubmodulos.length > 0){
                        smo.ssmsubmodulos.map((ssm, indexssm) => {
                            dataModule["smosubmodulos"][indexsmo]["ssmsubmodulos"][indexssm]["key"] = `ssmsubmodulos-${indexssm + 1}`
                            dataModule["smosubmodulos"][indexsmo]["ssmsubmodulos"][indexssm]["ssmsubmodulosnew"] = false
                            dataModule["smosubmodulos"][indexsmo]["ssmsubmodulos"][indexssm]["ssmformulario"] = false
                            dataModule["smosubmodulos"][indexsmo]["ssmsubmodulos"][indexssm]["expandible"] = false
                            dataModule["smosubmodulos"][indexsmo]["ssmsubmodulos"][indexssm]["validation"] = false
                        })
                    }
                })
                dispatch({
                    type: GET_MODULE_DATA,
                    payload : dataModule
                })
                dispatch({
                    type: GET_MODULE_DATA_CLONE,
                    payload : dataModule
                })
            }
        }).catch((error)=> {
            console.log(error)
        })
    }else{
        dataModule = {
            modid: '',
            modicono: '',
            modnombre: '',
            modruta: '',
            modslug: '',
            paiid: '',
            modpowerbi: '/',
            modRutaPre : "/Sistema/dashboards/",
            modtienesubmodulos: false,
            validation: false,
            smosubmodulos: [],
            modNamCou : '',
            modpais : ''
        }
        dispatch({
            type: GET_MODULE_DATA,
            payload : dataModule
        })
        dispatch({
            type: GET_MODULE_DATA_CLONE,
            payload : dataModule
        })
    }
}

export const CreateModuleSubModuleSubSubModuleReducer = (id) => async (dispatch, getState) => {
    const { rex_get_module_data_clone } = getState().createEditModules
    let alert = false

    if(!rex_get_module_data_clone.modicono || !rex_get_module_data_clone.modnombre || !rex_get_module_data_clone.modruta || !rex_get_module_data_clone.modslug || !rex_get_module_data_clone.paiid || !rex_get_module_data_clone.modpowerbi){
        alert = true
        rex_get_module_data_clone.validation = true
    }else{
        rex_get_module_data_clone.validation = false
    }
    
    rex_get_module_data_clone.smosubmodulos.map((smo, indexsmo) => {
        if(!smo.smonombre || !smo.smopowerbi || !smo.smoruta || !smo.smoslug){
            alert = true
            rex_get_module_data_clone['smosubmodulos'][indexsmo]['validation'] = true
        }else{
            rex_get_module_data_clone['smosubmodulos'][indexsmo]['validation'] = false
        }
        smo.ssmsubmodulos.map((ssm, indexssm) => {
            if(!ssm.ssmnombre || !ssm.ssmpowerbi || !ssm.ssmruta || !ssm.ssmslug){
                alert = true
                rex_get_module_data_clone['smosubmodulos'][indexsmo]['ssmsubmodulos'][indexssm]['validation'] = true
            }else{
                rex_get_module_data_clone['smosubmodulos'][indexsmo]['ssmsubmodulos'][indexssm]['validation'] = false
            }
        })
    })

    dispatch({
        type: GET_MODULE_DATA_CLONE,
        payload : rex_get_module_data_clone
    })
    
    if(alert){
        notificacionServidor('warning', "Por favor llene todos los campos.")
    }else{
        dispatch({
            type: LOADING_SAVE_DATA,
            payload : true
        })
        let response = false
        let ruta = 'modules/create-mod-submod-subsubmod'
        if(id){
            ruta = 'modules/edit-mod-submod-subsubmod'
        }
        await fetch(config.api+ruta,
            {
                mode:'cors',
                method: 'POST',
                headers: {
                    'Accept' : 'application/json',
                    'Content-type' : 'application/json',
                    'usutoken'	   : localStorage.getItem('usutoken'),
                    'middle_usuario' : localStorage.getItem('usutoken'),
                },
                body: JSON.stringify({
                    req_data_modules: rex_get_module_data_clone
                }),
            }
        )
        .then(res => res.json())
        .then(data => {
            if(data.response){
                notificacionServidor('success', data.message)
                response = true
            }else{
                notificacionServidor('warning', data.message)
            }
        }).catch((error)=> {
            console.log(error)
        })
        dispatch({
            type: LOADING_SAVE_DATA,
            payload : false
        })
        return response
    }
}

export const EditSubModuleReducer = (keysub) => async (dispatch, getState) => {
    const { rex_get_module_data_clone } = getState().createEditModules
    const find_submodule = rex_get_module_data_clone.smosubmodulos.find(smo => smo.key == keysub)
    let error = false
    let error_subsubmod = []
    let error_val_duplicate = false
    let message_val = ''

    const smonombres = new Set()
    rex_get_module_data_clone.smosubmodulos.map(smo => {
        const { smonombre } = smo
        if(smonombres.has(smonombre)){
            error_val_duplicate = true
            message_val = "El submódulo ya existen."
        }else{
            smonombres.add(smonombre)
        }
    })

    if(!error_val_duplicate){
        const ssmnombres = new Set()
        rex_get_module_data_clone.smosubmodulos.map(smo => 
            smo.ssmsubmodulos.map(ssm => {
                const { ssmnombre } = ssm
                if(ssmnombres.has(ssmnombre)){
                    error_val_duplicate = true
                    message_val = "El sub submódulo o los sub submódulos se repiten."
                }else{
                    ssmnombres.add(ssmnombre)
                }
            })
        )
    }
    
    if(error_val_duplicate){
        notificacionServidor('warning', message_val)
    }else{
        await fetch(config.api+'modules/edit-submod',
            {
                mode:'cors',
                method: 'POST',
                headers: {
                    'Accept' : 'application/json',
                    'Content-type' : 'application/json',
                    'usutoken'	   : localStorage.getItem('usutoken'),
                    'middle_usuario' : localStorage.getItem('usutoken'),
                },
                body: JSON.stringify({
                    req_smoid: find_submodule.smoid,
                    req_smopaiid: parseInt(rex_get_module_data_clone.paiid),
                    req_smopemid: find_submodule.pemid,
                    req_smomodid: rex_get_module_data_clone.modid,
                    req_smonombre: find_submodule.smonombre,
                    req_smoruta: find_submodule.smoruta,
                    req_smopowerbi: find_submodule.smopowerbi,
                    req_smoslug: find_submodule.smoslug,
                    req_smovisualizacion: find_submodule.smovisualizacion,
                    req_smoicono: null,
                    req_smoiconoseleccionado: null,
                    req_smotienesubmodulos: find_submodule.smotienesubmodulos,
                }),
            }
        )
        .then(res => res.json())
        .then(async data => {
            if(data.response){
                if(find_submodule.ssmsubmodulos.length > 0){
                    let message_subsubmod = ''
                    for await(const ssm of find_submodule.ssmsubmodulos){
                        const error_val = await dispatch(EditSubSubModuleReducer(data.smo.smoid, ssm))
                        if(error_val){
                            error_subsubmod.push(ssm.key)
                            message_subsubmod = message_subsubmod + ssm.ssmnombre + ', '
                        }
                    }
                    if(error_subsubmod.length > 0){
                        notificacionServidor('warning', `Los sentimos hubo un error al guardar los sub submódulos ${message_subsubmod}.`)
                    }
                }
            }else{
                error = true
                notificacionServidor('warning', data.message)
            }
        }).catch((error)=> {
            console.log(error)
        })

        if(!error && error_subsubmod.length == 0){
            notificacionServidor('success', 'Se guardo correctamente.')
            dispatch(GetDataModuleReducer(rex_get_module_data_clone.modid))
        }
    }
}

export const EditSubSubModuleReducer = (smoid, submodule) => async (dispatch, getState) => {
    const { rex_get_module_data_clone } = getState().createEditModules
    let error = false
    
    await fetch(config.api+'modules/edit-submod-subsubmod',
        {
            mode:'cors',
            method: 'POST',
            headers: {
                'Accept' : 'application/json',
                'Content-type' : 'application/json',
                'usutoken'	   : localStorage.getItem('usutoken'),
                'middle_usuario' : localStorage.getItem('usutoken'),
            },
            body: JSON.stringify({
                req_ssmid: submodule.ssmid,
                req_ssmpaiid: parseInt(rex_get_module_data_clone.paiid),
                req_ssmpemid: submodule.pemid,
                req_ssmsmoid: smoid,
                req_ssmnombre: submodule.ssmnombre,
                req_ssmruta: submodule.ssmruta,
                req_ssmpowerbi: submodule.ssmpowerbi,
                req_ssmslug: submodule.ssmslug,
                req_ssmvisualizacion: submodule.ssmvisualizacion,
            }),
        }
    )
    .then(res => res.json())
    .then(data => {
        if(data.response){
        }else{
            error = true
        }
    }).catch((error)=> {
        console.log(error)
    })

    return error
}

export const StoreModuleReducer = (value, type) => async (dispatch, getState) => {
    const { rex_get_module_data_clone } = getState().createEditModules

    let valueChange = value ? value : ''

    let valPrefixRoute = "/Sistema/dashboards/"
    const valNom = type == 'nombreModulo' ? valueChange : rex_get_module_data_clone.modnombre
    const valRou = type == 'routeModulo' ? valueChange : rex_get_module_data_clone.modruta
    const valLink = type == 'linkModulo' ? valueChange : rex_get_module_data_clone.modpowerbi
    const valSlug = type == 'slugModulo' ? valueChange : rex_get_module_data_clone.modslug
    const valCou = type == 'countryModulo' ? valueChange.value : rex_get_module_data_clone.paiid
    const valNamCou = type == 'countryModulo' ? valueChange.label : rex_get_module_data_clone.modNamCou
    const valIma = type == 'imageModulo' ? valueChange : rex_get_module_data_clone.modicono
    let changeDataModule = {
        ...rex_get_module_data_clone,
        modnombre: valNom,
        modruta: valRou,
        modpowerbi: valLink,
        modslug: valSlug,
        paiid: valCou,
        modicono: valIma,
        modNamCou : valNamCou,
        modprefixroute : valPrefixRoute
    }
    dispatch({
        type: GET_MODULE_DATA_CLONE,
        payload : changeDataModule
    })
}

export const AddSubModuleReducer = () => async (dispatch, getState) => {
    const module_data_clone = getState().createEditModules.rex_get_module_data_clone

    let modCountry  = module_data_clone['paipaises']['painombre'].normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase().replace(/\s/g, '');
    let modName     = module_data_clone['modnombre'].normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase().replace(/\s/g, '');

    modCountry  = `${modCountry}.`
    modName     = `${modName}.`

    const newsubmodule = {
        key: `smosubmodulos-${module_data_clone.smosubmodulos.length + 1}`,
        smoid               : '',
        smonombre           : '',
        smoruta             : '',
        smopowerbi          : '',
        smomodcountry       : modCountry,
        smomodname          : modName,
        smoslug             : modCountry + modName,
        smotienesubmodulos  : false,
        smosubmodulosnew    : true,
        smovisualizacion    : true,
        smoformulario       : true,
        ssmsubmodulos       : [],
        expandible          : true,
        validation          : false,
    }
    module_data_clone.smosubmodulos.push(newsubmodule)
    module_data_clone.modtienesubmodulos = module_data_clone.smosubmodulos.length > 0 ? true : false
    dispatch({
        type: GET_MODULE_DATA_CLONE,
        payload : module_data_clone
    })
}

export const RemoveSubModuleReducer = (key) => async (dispatch, getState) => {
    let module_data_clone = getState().createEditModules.rex_get_module_data_clone
    const filterSubModule = module_data_clone.smosubmodulos.filter(subm => subm.key !== key)
    module_data_clone.smosubmodulos = filterSubModule.map((fsm, index) => {
        return {
            ...fsm,
            key: `smosubmodulos-${index + 1}`,
        }
    })
    module_data_clone.modtienesubmodulos = module_data_clone.smosubmodulos.length > 0 ? true : false
    dispatch({
        type: GET_MODULE_DATA_CLONE,
        payload : module_data_clone
    })
}

export const StoreSubModuleReducer = (value, key, input) => async (dispatch, getState) => {
    let module_data_clone = getState().createEditModules.rex_get_module_data_clone
    const findSubModule = module_data_clone.smosubmodulos.map(subm => {

        if(subm.key == key){
            return {
                ...subm,
                smonombre: input == "subnombre" ? value : subm.smonombre,
                smoruta: input == "subroute" ? value : subm.smoruta,
                smopowerbi: input == "sublink" ? value : subm.smopowerbi,
                smoslug: input == "subslug" ? value : subm.smoslug,
            }
        }else{
            return {...subm}
        }
    })
    module_data_clone.smosubmodulos = findSubModule
    dispatch({
        type: GET_MODULE_DATA_CLONE,
        payload : module_data_clone
    })
}

export const ChangeExpandibleSubModuleReducer = (estado, key) => async (dispatch, getState) => {
    let module_data_clone = getState().createEditModules.rex_get_module_data_clone
    const findSubModule = module_data_clone.smosubmodulos.map(subm => subm.key == key ? {...subm, expandible: estado} : {...subm})
    module_data_clone.smosubmodulos = findSubModule
    dispatch({
        type: GET_MODULE_DATA_CLONE,
        payload : module_data_clone
    })
}

export const AddSubSubModuleReducer = (key) => async (dispatch, getState) => {
    let module_data_clone = getState().createEditModules.rex_get_module_data_clone
    let addmoduledata = module_data_clone.smosubmodulos.find(sub => sub.key == key)
    const valuesSubSubModule = {
        key: `ssmsubmodulos-${addmoduledata.ssmsubmodulos.length + 1}`,
        ssmid: '',
        ssmnombre: '',
        ssmruta: '',
        ssmpowerbi: '',
        ssmslug: '',
        ssmformulario: true,
        ssmsubmodulosnew: true,
        expandible: true,
        validation: false,
        ssmvisualizacion: true,
    }
    addmoduledata.ssmsubmodulos.push(valuesSubSubModule)
    addmoduledata.smotienesubmodulos = addmoduledata.ssmsubmodulos.length > 0 ? true : false
    const juntardatamodule = module_data_clone.smosubmodulos.map(sub => {
        if(sub.key == addmoduledata.key){
            return {...addmoduledata}
        }else{
            return {...sub}
        }
    })
    module_data_clone.smosubmodulos = juntardatamodule
    dispatch({
        type: GET_MODULE_DATA_CLONE,
        payload : module_data_clone
    })
}

export const RemoveSubSubModuleReducer = (keySub, key) => async (dispatch, getState) => {
    let module_data_clone = getState().createEditModules.rex_get_module_data_clone
    let filterSubModule = module_data_clone.smosubmodulos.find(sub => sub.key == keySub)
    filterSubModule.ssmsubmodulos = filterSubModule.ssmsubmodulos.filter(subsubmod => subsubmod.key !== key)
    filterSubModule.smotienesubmodulos = filterSubModule.ssmsubmodulos.length > 0 ? true : false
    filterSubModule.ssmsubmodulos = filterSubModule.ssmsubmodulos.map((subsubm, index) => {
        return {
            ...subsubm,
            key: `ssmsubmodulos-${index + 1}`,
        }
    })
    const juntardatamodule = module_data_clone.smosubmodulos.map(sub => {
        if(sub.key == filterSubModule.key){
            return {...filterSubModule}
        }else{
            return {...sub}
        }
    })
    module_data_clone.smosubmodulos = juntardatamodule
    dispatch({
        type: GET_MODULE_DATA_CLONE,
        payload : module_data_clone
    })
}

export const RemoveSubSubModuleNewReducer = (keySub) => async (dispatch, getState) => {
    let module_data_clone = getState().createEditModules.rex_get_module_data_clone
    let filterSubModule = module_data_clone.smosubmodulos.find(sub => sub.key == keySub)
    filterSubModule['ssmsubmodulos'] = filterSubModule.ssmsubmodulos.filter(ssm => {
        if(ssm.ssmsubmodulosnew) return false
        return true
    })

    module_data_clone['smosubmodulos'] = module_data_clone.smosubmodulos.map(smo => {
        if(smo.key == filterSubModule.key) return {...filterSubModule}
        return {...smo}
    })

    dispatch({
        type: GET_MODULE_DATA_CLONE,
        payload : module_data_clone
    })
}

export const StoreSubSubModuleReducer = (value, key, input, subkey) => async (dispatch, getState) => {
    let module_data_clone = getState().createEditModules.rex_get_module_data_clone
    let findsubmodule = module_data_clone.smosubmodulos.find(subm => subm.key == subkey)
    findsubmodule.ssmsubmodulos = findsubmodule.ssmsubmodulos.map(subsubm => {
        if(subsubm.key == key){
            return {
                ...subsubm,
                ssmnombre: input == "subsubnombre" ? value : subsubm.ssmnombre,
                ssmruta: input == "subsubroute" ? value : subsubm.ssmruta,
                ssmpowerbi: input == "subsublink" ? value : subsubm.ssmpowerbi,
                ssmslug: input == "subsubslug" ? value : subsubm.ssmslug,
            }
        }else{
            return {...subsubm}
        }
    })
    module_data_clone.smosubmodulos = module_data_clone.smosubmodulos.map(subm => {
        if(subm.key == findsubmodule.key){
            return {...findsubmodule}
        }else{
            return {...subm}
        }
    })
    dispatch({
        type: GET_MODULE_DATA_CLONE,
        payload : module_data_clone
    })
}

export const ChangeExpandibleSubSubModuleReducer = (estado, subkey, key) => async (dispatch, getState) => {
    let module_data_clone = getState().createEditModules.rex_get_module_data_clone
    let findSubModule = module_data_clone.smosubmodulos.find(subm => subm.key == subkey)
    findSubModule.ssmsubmodulos = findSubModule.ssmsubmodulos.map(subm => subm.key == key ? {...subm, expandible: estado} : {...subm})
    const juntardatamodule = module_data_clone.smosubmodulos.map(sub => {
        if(sub.key == findSubModule.key){
            return {...findSubModule}
        }else{
            return {...sub}
        }
    })
    module_data_clone.smosubmodulos = juntardatamodule
    dispatch({
        type: GET_MODULE_DATA_CLONE,
        payload : module_data_clone
    })
}

export const ChangeValueFormReducer = (value, state) => async (dispatch, getState) => {
    let module_data_clone = getState().createEditModules.rex_get_module_data_clone

    let modCountry  = module_data_clone['paipaises']['painombre'].normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase().replace(/\s/g, '');
    let modName     = module_data_clone['modnombre'].normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase().replace(/\s/g, '');

    modCountry  = `${modCountry}.`
    modName     = `${modName}.`

    let find_sub_module = module_data_clone.smosubmodulos.map(smo => smo.key == value 
        ? {
            ...smo, 
            smoformulario: state,
            smomodcountry       : modCountry,
            smomodname          : modName,
            smoslug             : modCountry + modName + smo.smonombre.normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase().replace(/\s/g, ''),
        } 
        : {...smo})

    module_data_clone.smosubmodulos = find_sub_module
    dispatch({
        type: GET_MODULE_DATA_CLONE,
        payload : module_data_clone
    })
}

export const ChangeOrderSubmodulesReducer = (active, over, arrayMove) => async (dispatch, getState) => {
    if(active.id !== over.id){
        let module_data_clone = getState().createEditModules.rex_get_module_data_clone
        const activeIndex = module_data_clone.smosubmodulos.findIndex(i => i.key === active.id)
        const overIndex = module_data_clone.smosubmodulos.findIndex(i => i.key === over.id)
        module_data_clone.smosubmodulos = arrayMove(module_data_clone.smosubmodulos, activeIndex, overIndex)
        dispatch({
            type: GET_MODULE_DATA_CLONE,
            payload : module_data_clone
        })
    }
}

export const ChangeOrderSubsubmodulesReducer = (active, over, arrayMove, submoduleKey) => async (dispatch, getState) => {
    if(active.id !== over.id){
        let module_data_clone = getState().createEditModules.rex_get_module_data_clone
        let findSubmodule = module_data_clone['smosubmodulos'].find(smo => smo.key == submoduleKey)
        const activeIndex = findSubmodule['ssmsubmodulos'].findIndex(i => i.key === active.id)
        const overIndex = findSubmodule['ssmsubmodulos'].findIndex(i => i.key === over.id)
        findSubmodule['ssmsubmodulos'] = arrayMove(findSubmodule['ssmsubmodulos'], activeIndex, overIndex)
        module_data_clone['smosubmodulos'] = module_data_clone['smosubmodulos'].map(smo => {
            if(smo.key == findSubmodule.key){
                return {...findSubmodule}
            }else{
                return {...smo}
            }
        })

        dispatch({
            type: GET_MODULE_DATA_CLONE,
            payload : module_data_clone
        })
    }
}

export const ChangeDisabledSubmodulesReducer = (state, subkey) => async (dispatch, getState) => {
    let module_data_clone = getState().createEditModules.rex_get_module_data_clone
    module_data_clone['smosubmodulos'].map((smo, index) => {
        if(smo.key == subkey){
            module_data_clone['smosubmodulos'][index]['smovisualizacion'] = state
        }
    })

    dispatch({
        type: GET_MODULE_DATA_CLONE,
        payload : module_data_clone
    })
}

export const ChangeDisabledSubsubmodulesReducer = (state, subkey, subsubkey) => async (dispatch, getState) => {
    let module_data_clone = getState().createEditModules.rex_get_module_data_clone
    module_data_clone['smosubmodulos'].map((smo, indexsmo) => {
        if(smo.key == subkey){
            smo.ssmsubmodulos.map((ssm, indexssm) => {
                if(ssm.key == subsubkey){
                    module_data_clone['smosubmodulos'][indexsmo]['ssmsubmodulos'][indexssm]['ssmvisualizacion'] = state
                }
            })
        }
    })

    dispatch({
        type: GET_MODULE_DATA_CLONE,
        payload : module_data_clone
    })
}

export const AddDataSubModuleSubsubmoduleReducer = (key) => async (dispatch, getState) => {
    const { rex_get_module_data_clone } = getState().createEditModules
    let alert = false
    
    rex_get_module_data_clone.smosubmodulos.map((smo, indexsmo) => {
        if(smo.key == key){
            if(!smo.smonombre || !smo.smopowerbi || !smo.smoruta || !smo.smoslug){
                alert = true
                rex_get_module_data_clone['smosubmodulos'][indexsmo]['validation'] = true
            }else{
                rex_get_module_data_clone['smosubmodulos'][indexsmo]['validation'] = false
            }
            smo.ssmsubmodulos.map((ssm, indexssm) => {
                if(!ssm.ssmnombre || !ssm.ssmpowerbi || !ssm.ssmruta || !ssm.ssmslug){
                    alert = true
                    rex_get_module_data_clone['smosubmodulos'][indexsmo]['ssmsubmodulos'][indexssm]['validation'] = true
                }else{
                    rex_get_module_data_clone['smosubmodulos'][indexsmo]['ssmsubmodulos'][indexssm]['validation'] = false
                }
            })
        }
    })

    dispatch({
        type: GET_MODULE_DATA_CLONE,
        payload : rex_get_module_data_clone
    })

    if(alert){
        notificacionServidor('warning', "Por favor llene todos los campos.")
        return false
    }else{
        return true
    }
}

export const ClearDataModulesReducer = () => async (dispatch, getState) => {
    dispatch({
        type: GET_DATA_MODULES,
        payload : []
    })
}