import React from 'react'
import { useNavigate } from 'react-router'
import { Button } from 'antd'
import {
    PlusCircleOutlined 
} from '@ant-design/icons'

const ButtonGlobal = ({nameRoute, txtButton, actionButton=() => {}}) => {

    let navigate = useNavigate()

    return (
        <Button
            className='Button-Global'
            type="primary"
            shape="round"
            size='large'
            onClick={() => {
                navigate(`${nameRoute}`)
                actionButton()
            }}
        >
            <PlusCircleOutlined style={{fontSize:'20px'}}/>
            <span>{txtButton}</span>
        </Button>
    )
}

export default ButtonGlobal