import {
    OBTENER_TIPOS_PERFILES,
    SELECCIONAR_TIPO_PERFIL,
    OBTENER_TIPO_PERFIL,
    OBTENER_TIPO_PERFIL_COPIA,
    PERSONALIZAR_TIPO_PERFIL,
    MODULO_PAIS_SELECCIONADO,
    SELECCIONAR_TODOS_MODULOS,
    GET_PROFILE_TYPES,
    GET_PROFILE_TYPES_CLONE,
    GET_PROFILE_TYPE_SELECTED,
    MODAL_LIST_USERS_PROFILE,
    LIST_USERS_PROFILE,
    REGISTER_USERS_CONNECTED_MODULES_PROFILE_TYPES,
    GET_DATA_MODULES_PROFILE,
    GET_DATA_MODULES_PROFILE_CLONE,
    SELECTED_PROFILE_TYPE_UNIQUE,
    NAME_TYPE_PROFILE,
    PERMISSIONS_COUNTRY,
    CHECK_ALL_MODULE_COUNTRY,
    GET_UNIQUE_TYPE_PROFILE,
    GET_TYPE_PROFILE_COUNTRY,
    EDIT_TYPE_PROFILE,
    SELECTED_COUNTRY_PROFILE_TYPE,
    LOADING_PROFILE_TYPE,
} from "../../../Constants/ProfileTypes/ProfileTypes"

const INIT_STATE = {
    rex_data_tipos_perfiles             : [],
    rex_tipo_perfil_seleccionado        : null,
    rex_data_tipo_perfil                : [],
    rex_data_tipo_perfil_copia          : [],
    rex_personalizar_tipo_perfil        : false,
    rex_modulo_pais_seleccionado        : null,
    rex_seleccionar_todos_modulos       : false,
    rex_profile_types                   : [],
    rex_profile_types_clone             : [],
    rex_profile_type_selected           : {},
    rex_modal_list_users_profile        : false,
    rex_list_users_profile              : [],
    rex_users_profile_types_connected   : [],
    rex_data_modules_profile            : [],
    rex_data_modules_profile_clone      : [],
    rex_selected_profile_type_unique    : {},
    rex_name_type_profile               : '',
    rex_permissions_country             : [],
    rex_check_all_module_country        : [],
    rex_unique_type_profile             : {},
    rex_type_profile_country            : [],
    rex_edit_type_profile               : false,
    rex_selected_country_profile_type   : null,
    rex_loading_profile_type            : false,
}

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case REGISTER_USERS_CONNECTED_MODULES_PROFILE_TYPES: {
            return {
                ...state,
                rex_users_profile_types_connected : action.payload,
            }
        }
        case LIST_USERS_PROFILE: {
            return {
                ...state,
                rex_list_users_profile : action.payload,
            }
        }
        case GET_PROFILE_TYPES: {
            return {
                ...state,
                rex_profile_types : action.payload,
            }
        }
        case GET_PROFILE_TYPES_CLONE: {
            return {
                ...state,
                rex_profile_types_clone : action.payload,
            }
        }
        case MODAL_LIST_USERS_PROFILE: {
            return {
                ...state,
                rex_modal_list_users_profile : action.payload,
            }
        }
        case GET_PROFILE_TYPE_SELECTED: {
            return {
                ...state,
                rex_profile_type_selected : action.payload,
            }
        }
        case SELECCIONAR_TODOS_MODULOS: {
            return {
                ...state,
                rex_seleccionar_todos_modulos : action.payload,
            }
        }
        case OBTENER_TIPOS_PERFILES: {
            return {
                ...state,
                rex_data_tipos_perfiles : action.payload,
            }
        }
        case PERSONALIZAR_TIPO_PERFIL: {
            return {
                ...state,
                rex_personalizar_tipo_perfil : action.payload,
            }
        }
        case MODULO_PAIS_SELECCIONADO: {
            return {
                ...state,
                rex_modulo_pais_seleccionado : action.payload,
            }
        }
        case OBTENER_TIPO_PERFIL: {
            return {
                ...state,
                rex_data_tipo_perfil : action.payload,
            }
        }
        case OBTENER_TIPO_PERFIL_COPIA: {
            return {
                ...state,
                rex_data_tipo_perfil_copia : action.payload,
            }
        }
        case SELECCIONAR_TIPO_PERFIL: {
            return {
                ...state,
                rex_tipo_perfil_seleccionado : action.payload,
            }
        }
        case GET_DATA_MODULES_PROFILE: {
            return {
                ...state,
                rex_data_modules_profile : action.payload,
            }
        }
        case GET_DATA_MODULES_PROFILE_CLONE: {
            return {
                ...state,
                rex_data_modules_profile_clone : action.payload,
            }
        }
        case SELECTED_PROFILE_TYPE_UNIQUE: {
            return {
                ...state,
                rex_selected_profile_type_unique : action.payload,
            }
        }
        case CHECK_ALL_MODULE_COUNTRY: {
            return {
                ...state,
                rex_check_all_module_country : action.payload,
            }
        }
        case NAME_TYPE_PROFILE: {
            return {
                ...state,
                rex_name_type_profile : action.payload,
            }
        }
        case PERMISSIONS_COUNTRY: {
            return {
                ...state,
                rex_permissions_country : action.payload,
            }
        }
        case GET_UNIQUE_TYPE_PROFILE: {
            return {
                ...state,
                rex_unique_type_profile : action.payload,
            }
        }
        case GET_TYPE_PROFILE_COUNTRY: {
            return {
                ...state,
                rex_type_profile_country : action.payload,
            }
        }
        case EDIT_TYPE_PROFILE: {
            return {
                ...state,
                rex_edit_type_profile : action.payload,
            }
        }
        case SELECTED_COUNTRY_PROFILE_TYPE: {
            return {
                ...state,
                rex_selected_country_profile_type : action.payload,
            }
        }
        case LOADING_PROFILE_TYPE: {
            return {
                ...state,
                rex_loading_profile_type : action.payload,
            }
        }
        default:
            return state;
    }
}
