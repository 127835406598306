import React, { useState, useRef } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Typography, Radio, Spin } from 'antd'
import {
    ObtenerDataTablaReducer,
} from '../../../Redux/Actions/PowerBi/PowerBi'
import { SelectFilterDailyReducer } from "../../../Redux/Actions/PowerBi/PowerBiFiltros"
import {
    FilterFilled,
    LoadingOutlined,
} from '@ant-design/icons'

import { Picky } from "react-picky-with-clear"
import 'react-picky-with-clear/dist/picky.css'
import FlechaDesplegar from '../../../Assets/Images/PowerBi/FlechaDesplegar.png'

const SelectRadio = (props) => {
    const titulo = props.titulo
    const data = props.data
    const labelKey = props.labelKey
    const valueKey = props.valueKey
    const placeholder = props.placeholder
    const filterPlaceholder = props.filterPlaceholder
    const clearFilterOnClose = props.clearFilterOnClose
    const includeFilter = props.includeFilter
    const height = props.height
    const dispatch = useDispatch()
    const pickyCerrarRef = useRef()

    const { Title } = Typography
    const [value, setValue] = useState([])
    const [abrir, setAbrir] = useState(false)

    const {
        rex_cargando_lista_derecha,
    } = useSelector(({powerBi}) => powerBi)

    return(
        <div className="contenedorSimpleFiltro">
            <Title className="filtroTitulo">{titulo}</Title>
            {
                Object.entries(value).length > 0
                &&  <FilterFilled 
                        className="iconoFiltro" 
                        onClick={() => {
                            dispatch(SelectFilterDailyReducer( undefined, titulo ))
                            dispatch(ObtenerDataTablaReducer(true))
                            setValue([])
                        }} 
                    />
            }
            {
                abrir
                ?   <img className="imgIconoFlechaFiltro flechaAbajo" src={FlechaDesplegar} />
                :   <img className="imgIconoFlechaFiltro" src={FlechaDesplegar} />
            }
            <Picky
                className="filtro"
                options={data}
                ref={pickyCerrarRef}
                labelKey={labelKey}
                valueKey={valueKey}
                placeholder={placeholder}
                value={Array.isArray(value) ? props.defaultAnio : value} 
                onChange={(data) => {
                    if(titulo == 'Año'){
                        dispatch(SelectFilterDailyReducer( data['anio'], titulo ))
                    }else if(titulo == 'Mes'){
                        dispatch(SelectFilterDailyReducer( data['key'], titulo ))
                    }
                    dispatch(ObtenerDataTablaReducer(true))
                    setValue(data)
                }}
                filterPlaceholder={filterPlaceholder}
                clearFilterOnClose={clearFilterOnClose}
                includeFilter={includeFilter}
                dropdownHeight={height ? height : 210}
                onOpen={() => setAbrir(true)}
                onClose={() => setAbrir(false)}
                render={({
                    isSelected,
                    item,
                    selectValue,
                    labelKey,
                    valueKey,
                }) => {
                    return (
                        <Spin 
                            spinning={rex_cargando_lista_derecha} 
                            className="iconoRadio"
                            indicator={<LoadingOutlined />}
                        >
                            <Radio 
                                key={item[valueKey]} 
                                className="option"
                                onClick={() => {
                                    selectValue(item)
                                    pickyCerrarRef.current.toggleDropDown()
                                }} 
                                checked={isSelected}
                            >
                                <span title={item[labelKey]}>{item[labelKey]}</span>
                            </Radio>
                        </Spin>
                    )
                }}
            />
        </div>
    )
}

export default SelectRadio