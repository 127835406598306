import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { Form, Typography, Input, Row, Col, Button, Collapse, Modal } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate} from "react-router-dom"
import { CreateMasterClientManageReducer, EditMasterClientManageReducer, EditValueMasterClient, GetMasterClientManageReducer } from '../../../Redux/Actions/Manage/MasterClients/MasterClients'
import DividerGlobal from '../../Global/DividerGlobal'
import '../../../Styles/Components/Manage/MasterClients/FormMasterClient.css'
import notificationGlobal from '../../Global/NotificationGlobal'
import { 
    ExclamationCircleFilled,
} from '@ant-design/icons'
import SideMenu from '../../Global/SideMenu'

const FormMasterClient = () => {
    
    const [ loadingSend, setLoadingSend ] = useState(false)
    const { Panel } = Collapse
    const { confirm } = Modal

    const { id } = useParams()

    const dispatch = useDispatch()
    let navigate = useNavigate()
    const { Title } = Typography
    const [form] = Form.useForm()

    const { 
        rex_users_conected_dashboard,
    } = useSelector(({registerUsers}) => registerUsers)

    const { 
        rex_data_users_online
    } = useSelector(({users}) => users)

    const onFinishFailed = (errorInfo) => {
        notificationGlobal("warning", "Se han encontrado errores dentro del formulario.")
    }

    const editFormField = (data) => {
        form.setFieldsValue({
            cod_sales_organization  : data.cod_sales_organization,
            sales_organization      : data.sales_organization,
            cod_bussiness           : data.cod_bussiness,
            bussiness               : data.bussiness,
            cod_ship_to             : data.cod_ship_to,
            ship_to                 : data.ship_to,
            cod_sold_to             : data.cod_sold_to,
            sold_to                 : data.sold_to,
            hml_cod_client          : data.hml_cod_client,
            hml_client              : data.hml_client,
            hml_cod_subsidiary      : data.hml_cod_subsidiary,
            hml_subsidiary          : data.hml_subsidiary,
            corporate_group         : data.corporate_group,
            cod_regional            : data.cod_regional,
            regional                : data.regional,
            cod_channel             : data.cod_channel,
            channel                 : data.channel,
            cod_sub_channel         : data.cod_sub_channel,
            sub_channel             : data.sub_channel,
            cod_zone                : data.cod_zone,
            zone                    : data.zone,
            cod_territory           : data.cod_territory,
            territory               : data.territory,
            class_1                 : data.class_1,
            class_2                 : data.class_2,
            class_3                 : data.class_3,
            class_4                 : data.class_4,
            class_5                 : data.class_5,
            class_6                 : data.class_6,
            class_7                 : data.class_7,
            class_8                 : data.class_8,
            cod_regional_manager    : data.cod_regional_manager,
            regional_manager        : data.regional_manager,
            cod_channel_manager     : data.cod_channel_manager,
            channel_manager         : data.channel_manager,
            cod_zone_att            : data.cod_zone_att,
            cod_zone_manager        : data.cod_zone_manager,
            zone_manager            : data.zone_manager,
            cod_territory_manager   : data.cod_territory_manager,
            territory_manager       : data.territory_manager,
            cod_si_executive        : data.cod_si_executive,
            si_executive            : data.si_executive,
            cod_so_executor         : data.cod_so_executor,
            so_executor             : data.so_executor,
            class_9                 : data.class_9,
            class_10                : data.class_10,
            class_11                : data.class_11,
            hml_cod_country         : data.hml_cod_country,
            hml_country             : data.hml_country,
            hml_cod_departament     : data.hml_cod_departament,
            hml_departament         : data.hml_departament,
            hml_cod_province        : data.hml_cod_province,
            hml_province            : data.hml_province,
            hml_cod_district        : data.hml_cod_district,
            hml_district            : data.hml_district,
            hml_address             : data.hml_address,
            latitude                : data.latitude,
            longitude               : data.longitude,
            status                  : data.status,
            robot                   : data.robot,
            class_12                : data.class_12,
        })
    }

    const onFinish = async (values) => {

        //Hasta que esté el input de fecha
        const currentDate = new Date();
        const currentYear = currentDate.getFullYear();
        const currentMonth = currentDate.getMonth() + 1;
        const dateFormat = `${currentYear}-${currentMonth.toString().padStart(2, '0')}`;
        //Hasta que esté el input de fecha

        let response = false
        setLoadingSend(true)
        if(id){
            response = await dispatch(EditMasterClientManageReducer( id, values ))
        }else{
            confirm({
                title   : "Crear Cliente",
                icon    : <ExclamationCircleFilled/>,
                content : "¿Está seguro que desea crear el cliente?",
                zIndex  : 100000000,
                async onOk(){
                    response = await dispatch(CreateMasterClientManageReducer({...values, anio:currentYear, mes:currentMonth, fecha: dateFormat}))
                    if(response){
                        navigate("/master-clients")
                    }        
                },
                okCancel(){}
            })   
        }
    }

    const fetchData = async () => {
        if(id){
            
            let data = await dispatch(GetMasterClientManageReducer(id))
            editFormField(data)
        }
    }

    useEffect(() => {
        fetchData()
    },[])

    return (
        <div className="Container-Main" style={{paddingBottom: '10px'}}>
            <Title level={2} className='Title-Search-Text-Global'>
                {
                    id ? "Editar Cliente" : "Nuevo Cliente"
                }
            </Title>
            <Form
                layout='vertical'
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                form={form}
            >
                <DividerGlobal orientation="left" text="Información principal"/>
                <Row gutter={24}>
                    <Col xs={24} sm={12} md={6} lg={6}>
                        <Form.Item
                            label="Código SHIP TO:"
                            name="cod_ship_to"
                            className="Create-User-Form-Item-Text"
                            rules={[
                                {
                                    required: true,
                                    message: "El código es requerido",
                                },
                            ]}
                            >
                            <Input
                                autoComplete='off'
                                placeholder="Código SKU"
                                onChange={(e) => dispatch(EditValueMasterClient('cod_ship_to', e.target.value))}
                            />
                        </Form.Item>

                    </Col>
                    <Col xs={24} sm={12} md={6} lg={6}>
                        <Form.Item
                            label="SHIP TO:"
                            name="ship_to"
                            className="Create-User-Form-Item-Text"
                            rules={[
                                {
                                    required: true,
                                    message: "El nombre es requerido",
                                },
                            ]}
                            >
                            <Input
                                autoComplete='off'
                                placeholder="SHIP TO"
                                onChange={(e) => dispatch(EditValueMasterClient('ship_to', e.target.value))}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={6} lg={6}>
                        <Form.Item
                            label="Código sales organización:"
                            name="cod_sales_organization"
                            className="Create-User-Form-Item-Text"
                            >
                            <Input
                                autoComplete='off'
                                placeholder="Código sales organización"
                                onChange={(e) => dispatch(EditValueMasterClient('cod_sales_organization', e.target.value))}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={6} lg={6}>
                        <Form.Item
                            label="Sales organización:"
                            name="sales_organization"
                            className="Create-User-Form-Item-Text"
                            >
                            <Input
                                autoComplete='off'
                                placeholder="Sales organización"
                                onChange={(e) => dispatch(EditValueMasterClient('sales_organization', e.target.value))}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={6} lg={6}>
                        <Form.Item
                            label="Código Bussiness:"
                            name="cod_bussiness"
                            className="Create-User-Form-Item-Text"
                            >
                            <Input
                                autoComplete='off'
                                placeholder="Código Bussiness"
                                onChange={(e) => dispatch(EditValueMasterClient('cod_bussiness', e.target.value))}
                            />
                        </Form.Item>

                    </Col>
                    <Col xs={24} sm={12} md={6} lg={6}>
                        <Form.Item
                            label="Bussiness:"
                            name="bussiness"
                            className="Create-User-Form-Item-Text"
                            >
                            <Input
                                autoComplete='off'
                                placeholder="Bussiness"
                                onChange={(e) => dispatch(EditValueMasterClient('bussiness', e.target.value))}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={6} lg={6}>
                        <Form.Item
                            label="Código SOLD TO:"
                            name="cod_sold_to"
                            className="Create-User-Form-Item-Text"
                            >
                            <Input
                                autoComplete='off'
                                placeholder="Código SOLD TO"
                                onChange={(e) => dispatch(EditValueMasterClient('cod_sold_to', e.target.value))}
                            />
                        </Form.Item>

                    </Col>
                    <Col xs={24} sm={12} md={6} lg={6}>
                        <Form.Item
                            label="SOLD TO:"
                            name="sold_to"
                            className="Create-User-Form-Item-Text"
                            >
                            <Input
                                autoComplete='off'
                                placeholder="SOLD TO"
                            name={(e) => dispatch(EditValueMasterClient('sold_to', e.target.value))}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <DividerGlobal orientation="left" text="Filtros"/>
                <Row gutter={24}>
                    <Col xs={24} sm={12} md={6} lg={6}>
                        <Form.Item
                            label="HML código cliente:"
                            name="hml_cod_client"
                            className="Create-User-Form-Item-Text"
                            >
                            <Input
                                autoComplete='off'
                                placeholder="HML código cliente"
                                onChange={(e) => dispatch(EditValueMasterClient('hml_cod_client', e.target.value))}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={6} lg={6}>
                        <Form.Item
                            label="HML cliente:"
                            name="hml_client"
                            className="Create-User-Form-Item-Text"
                            >
                            <Input
                                autoComplete='off'
                                placeholder="HML cliente"
                                onChange={(e) => dispatch(EditValueMasterClient('hml_client', e.target.value))}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={6} lg={6}>
                        <Form.Item
                            label="HML código subsidiario:"
                            name="hml_cod_subsidiary"
                            className="Create-User-Form-Item-Text"
                            >
                            <Input
                                autoComplete='off'
                                placeholder="HML código subsidiario"
                                onChange={(e) => dispatch(EditValueMasterClient('hml_cod_subsidiary', e.target.value))}
                            />
                        </Form.Item>

                    </Col>
                    <Col xs={24} sm={12} md={6} lg={6}>
                        <Form.Item
                            label="HML subsidiary:"
                            name="hml_subsidiary"
                            className="Create-User-Form-Item-Text"
                            >
                            <Input
                                autoComplete='off'
                                placeholder="HML subsidiary"
                                onChange={(e) => dispatch(EditValueMasterClient('hml_subsidiary', e.target.value))}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={6} lg={6}>
                        <Form.Item
                            label="Corporate Group:"
                            name="corporate_group"
                            className="Create-User-Form-Item-Text"
                            >
                            <Input
                                autoComplete='off'
                                placeholder="Corporate Group"
                                onChange={(e) => dispatch(EditValueMasterClient('corporate_group', e.target.value))}
                            />
                        </Form.Item>

                    </Col>
                    <Col xs={24} sm={12} md={6} lg={6}>
                        <Form.Item
                            label="Código regional:"
                            name="cod_regional"
                            className="Create-User-Form-Item-Text"
                            >
                            <Input
                                autoComplete='off'
                                placeholder="Código regional"
                                onChange={(e) => dispatch(EditValueMasterClient('cod_regional', e.target.value))}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={6} lg={6}>
                        <Form.Item
                            label="Regional:"
                            name="regional"
                            className="Create-User-Form-Item-Text"
                            >
                            <Input
                                autoComplete='off'
                                placeholder="Regional"
                                onChange={(e) => dispatch(EditValueMasterClient('regional', e.target.value))}
                            />
                        </Form.Item>

                    </Col>
                    <Col xs={24} sm={12} md={6} lg={6}>
                        <Form.Item
                            label="Código canal:"
                            name="cod_channel"
                            className="Create-User-Form-Item-Text"
                            >
                            <Input
                                autoComplete='off'
                                placeholder="Código canal"
                                onChange={(e) => dispatch(EditValueMasterClient('cod_channel', e.target.value))}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={6} lg={6}>
                        <Form.Item
                            label="Canal:"
                            name="channel"
                            className="Create-User-Form-Item-Text"
                            >
                            <Input
                                autoComplete='off'
                                placeholder="Canal"
                                onChange={(e) => dispatch(EditValueMasterClient('channel', e.target.value))}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={6} lg={6}>
                        <Form.Item
                            label="Código subcanal:"
                            name="cod_sub_channel"
                            className="Create-User-Form-Item-Text"
                            >
                            <Input
                                autoComplete='off'
                                placeholder="Código subcanal"
                                onChange={(e) => dispatch(EditValueMasterClient('cod_sub_channel', e.target.value))}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={6} lg={6}>
                        <Form.Item
                            label="Subcanal:"
                            name="sub_channel"
                            className="Create-User-Form-Item-Text"
                            >
                            <Input
                                autoComplete='off'
                                placeholder="Subcanal"
                                onChange={(e) => dispatch(EditValueMasterClient('sub_channel', e.target.value))}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={6} lg={6}>
                        <Form.Item
                            label="Código zona:"
                            name="cod_zone"
                            className="Create-User-Form-Item-Text"
                            >
                            <Input
                                autoComplete='off'
                                placeholder="Código zona"
                                onChange={(e) => dispatch(EditValueMasterClient('cod_zone', e.target.value))}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={6} lg={6}>
                        <Form.Item
                            label="Zona:"
                            name="zone"
                            className="Create-User-Form-Item-Text"
                            >
                            <Input
                                autoComplete='off'
                                placeholder="Zona"
                                onChange={(e) => dispatch(EditValueMasterClient('zone', e.target.value))}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={6} lg={6}>
                        <Form.Item
                            label="Código territorio:"
                            name="cod_territory"
                            className="Create-User-Form-Item-Text"
                            >
                            <Input
                                autoComplete='off'
                                placeholder="Código territorio"
                                onChange={(e) => dispatch(EditValueMasterClient('cod_territory', e.target.value))}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={6} lg={6}>
                        <Form.Item
                            label="Territorio:"
                            name="territory"
                            className="Create-User-Form-Item-Text"
                            >
                            <Input
                                autoComplete='off'
                                placeholder="Territorio"
                                onChange={(e) => dispatch(EditValueMasterClient('territory', e.target.value))}
                            />
                        </Form.Item>
                    </Col>

                </Row>
                <Row gutter={24}>
                    <Col span={24}>
                        <Collapse className="Collapse-Form-Master-Client">
                            <Panel header="Campos extras">
                                <Row gutter={24}>
                                    <Col xs={24} sm={12} md={6} lg={6}>
                                        <Form.Item
                                            label="Class 1:"
                                            name="class_1"
                                            className="Create-User-Form-Item-Text"
                                            >
                                            <Input
                                                autoComplete='off'
                                                placeholder="Class 1"
                                                onChange={(e) => dispatch(EditValueMasterClient('class_1', e.target.value))}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={12} md={6} lg={6}>
                                        <Form.Item
                                            label="Class 2:"
                                            name="class_2"
                                            className="Create-User-Form-Item-Text"
                                            >
                                            <Input
                                                autoComplete='off'
                                                placeholder="Class 2"
                                                onChange={(e) => dispatch(EditValueMasterClient('class_2', e.target.value))}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={12} md={6} lg={6}>
                                        <Form.Item
                                            label="Class 3:"
                                            name="class_3"
                                            className="Create-User-Form-Item-Text"
                                            >
                                            <Input
                                                autoComplete='off'
                                                placeholder="Class 3"
                                                onChange={(e) => dispatch(EditValueMasterClient('class_3', e.target.value))}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={12} md={6} lg={6}>
                                        <Form.Item
                                            label="Class 4:"
                                            name="class_4"
                                            className="Create-User-Form-Item-Text"
                                            >
                                            <Input
                                                autoComplete='off'
                                                placeholder="Class 4"
                                                onChange={(e) => dispatch(EditValueMasterClient('class_4', e.target.value))}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={12} md={6} lg={6}>
                                        <Form.Item
                                            label="Class 5:"
                                            name="class_5"
                                            className="Create-User-Form-Item-Text"
                                            >
                                            <Input
                                                autoComplete='off'
                                                placeholder="Class 5"
                                                onChange={(e) => dispatch(EditValueMasterClient('class_5', e.target.value))}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={12} md={6} lg={6}>
                                        <Form.Item
                                            label="Class 6:"
                                            name="class_6"
                                            className="Create-User-Form-Item-Text"
                                            >
                                            <Input
                                                autoComplete='off'
                                                placeholder="Class 6"
                                                onChange={(e) => dispatch(EditValueMasterClient('class_6', e.target.value))}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={12} md={6} lg={6}>
                                        <Form.Item
                                            label="Class 7:"
                                            name="class_7"
                                            className="Create-User-Form-Item-Text"
                                            >
                                            <Input
                                                autoComplete='off'
                                                placeholder="Class 7"
                                                onChange={(e) => dispatch(EditValueMasterClient('class_6', e.target.value))}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={12} md={6} lg={6}>
                                        <Form.Item
                                            label="Class 8:"
                                            name="class_8"
                                            className="Create-User-Form-Item-Text"
                                            >
                                            <Input
                                                autoComplete='off'
                                                placeholder="Class 8"
                                                onChange={(e) => dispatch(EditValueMasterClient('class_6', e.target.value))}
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Panel>
                        </Collapse>
                    </Col>
                </Row>
                <DividerGlobal orientation="left" text="Atributos"/>
                <Row gutter={24}>
                    <Col xs={24} sm={12} md={6} lg={6}>
                        <Form.Item
                            label="Código regional manager:"
                            name="cod_regional_manager"
                            className="Create-User-Form-Item-Text"
                            >
                            <Input
                                autoComplete='off'
                                placeholder="Código regional manager"
                                onChange={(e) => dispatch(EditValueMasterClient('cod_regional_manager', e.target.value))}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={6} lg={6}>
                        <Form.Item
                            label="Regional manager:"
                            name="regional_manager"
                            className="Create-User-Form-Item-Text"
                            >
                            <Input
                                autoComplete='off'
                                placeholder="Regional manager"
                                onChange={(e) => dispatch(EditValueMasterClient('regional_manager', e.target.value))}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={6} lg={6}>
                        <Form.Item
                            label="Código canal manager:"
                            name="cod_channel_manager"
                            className="Create-User-Form-Item-Text"
                            >
                            <Input
                                autoComplete='off'
                                placeholder="Código canal manager"
                                onChange={(e) => dispatch(EditValueMasterClient('cod_channel_manager', e.target.value))}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={6} lg={6}>
                        <Form.Item
                            label="Canal manager:"
                            name="channel_manager"
                            className="Create-User-Form-Item-Text"
                            >
                            <Input
                                autoComplete='off'
                                placeholder="Canal manager"
                                onChange={(e) => dispatch(EditValueMasterClient('channel_manager', e.target.value))}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={6} lg={6}>
                        <Form.Item
                            label="Código zona:"
                            name="cod_zone_att"
                            className="Create-User-Form-Item-Text"
                            >
                            <Input
                                autoComplete='off'
                                placeholder="Código zona"
                                onChange={(e) => dispatch(EditValueMasterClient('cod_zone_att', e.target.value))}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={6} lg={6}>
                        <Form.Item
                            label="Código zone maganer:"
                            name="cod_zone_manager"
                            className="Create-User-Form-Item-Text"
                            >
                            <Input
                                autoComplete='off'
                                placeholder="Código zone maganer"
                                onChange={(e) => dispatch(EditValueMasterClient('cod_zone_manager', e.target.value))}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={6} lg={6}>
                        <Form.Item
                            label="Zone maganer:"
                            name="zone_manager"
                            className="Create-User-Form-Item-Text"
                            >
                            <Input
                                autoComplete='off'
                                placeholder="Zone maganer"
                                onChange={(e) => dispatch(EditValueMasterClient('zone_manager', e.target.value))}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={6} lg={6}>
                        <Form.Item
                            label="Código territorio manager:"
                            name="cod_territory_manager"
                            className="Create-User-Form-Item-Text"
                            >
                            <Input
                                autoComplete='off'
                                placeholder="Código territorio manager"
                                onChange={(e) => dispatch(EditValueMasterClient('cod_territory_manager', e.target.value))}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={6} lg={6}>
                        <Form.Item
                            label="Territorio manager:"
                            name="territory_manager"
                            className="Create-User-Form-Item-Text"
                            >
                            <Input
                                autoComplete='off'
                                placeholder="Territorio manager"
                                onChange={(e) => dispatch(EditValueMasterClient('territory_manager', e.target.value))}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={6} lg={6}>
                        <Form.Item
                            label="Código SI ejecutivo:"
                            name="cod_si_executive"
                            className="Create-User-Form-Item-Text"
                            >
                            <Input
                                autoComplete='off'
                                placeholder="Código SI ejecutivo"
                                onChange={(e) => dispatch(EditValueMasterClient('cod_si_executive', e.target.value))}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={6} lg={6}>
                        <Form.Item
                            label="SI ejecutivo:"
                            name="si_executive"
                            className="Create-User-Form-Item-Text"
                            >
                            <Input
                                autoComplete='off'
                                placeholder="SI ejecutivo"
                                onChange={(e) => dispatch(EditValueMasterClient('si_executive', e.target.value))}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={6} lg={6}>
                        <Form.Item
                            label="Código SO executor:"
                            name="cod_so_executor"
                            className="Create-User-Form-Item-Text"
                            >
                            <Input
                                autoComplete='off'
                                placeholder="Código SO executor"
                                onChange={(e) => dispatch(EditValueMasterClient('cod_so_executor', e.target.value))}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={6} lg={6}>
                        <Form.Item
                            label="SO executor:"
                            name="so_executor"
                            className="Create-User-Form-Item-Text"
                            >
                            <Input
                                autoComplete='off'
                                placeholder="SO executor"
                                onChange={(e) => dispatch(EditValueMasterClient('so_executor', e.target.value))}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={24}>
                        <Collapse className="Collapse-Form-Master-Client">
                            <Panel header="Campos extras">
                                <Row gutter={24}>
                                    <Col xs={24} sm={12} md={6} lg={6}>
                                        <Form.Item
                                            label="Class 9:"
                                            name="class_9"
                                            className="Create-User-Form-Item-Text"
                                            >
                                            <Input
                                                autoComplete='off'
                                                placeholder="Class 9"
                                                onChange={(e) => dispatch(EditValueMasterClient('class_9', e.target.value))}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={12} md={6} lg={6}>
                                        <Form.Item
                                            label="Class 10:"
                                            name="class_10"
                                            className="Create-User-Form-Item-Text"
                                            >
                                            <Input
                                                autoComplete='off'
                                                placeholder="Class 10"
                                                onChange={(e) => dispatch(EditValueMasterClient('class_10', e.target.value))}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={12} md={6} lg={6}>
                                        <Form.Item
                                            label="Class 11:"
                                            name="class_11"
                                            className="Create-User-Form-Item-Text"
                                            >
                                            <Input
                                                autoComplete='off'
                                                placeholder="Class 11"
                                                onChange={(e) => dispatch(EditValueMasterClient('class_11', e.target.value))}
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Panel>
                        </Collapse>
                    </Col>
                </Row>
                <DividerGlobal orientation="left" text="Filtros"/>
                <Row gutter={24}>
                    <Col xs={24} sm={12} md={6} lg={6}>
                        <Form.Item
                            label="HML código pais:"
                            name="hml_cod_country"
                            className="Create-User-Form-Item-Text"
                            >
                            <Input
                                autoComplete='off'
                                placeholder="HML código pais"
                                onChange={(e) => dispatch(EditValueMasterClient('hml_cod_country', e.target.value))}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={6} lg={6}>
                        <Form.Item
                            label="HML pais:"
                            name="hml_country"
                            className="Create-User-Form-Item-Text"
                            >
                            <Input
                                autoComplete='off'
                                placeholder="HML pais"
                                onChange={(e) => dispatch(EditValueMasterClient('hml_country', e.target.value))}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={6} lg={6}>
                        <Form.Item
                            label="HML código departamento:"
                            name="hml_cod_departament"
                            className="Create-User-Form-Item-Text"
                            >
                            <Input
                                autoComplete='off'
                                placeholder="HML código departamento"
                                onChange={(e) => dispatch(EditValueMasterClient('hml_cod_departament', e.target.value))}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={6} lg={6}>
                        <Form.Item
                            label="HML departamento:"
                            name="hml_departament"
                            className="Create-User-Form-Item-Text"
                            >
                            <Input
                                autoComplete='off'
                                placeholder="HML departamento"
                                onChange={(e) => dispatch(EditValueMasterClient('hml_departament', e.target.value))}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={6} lg={6}>
                        <Form.Item
                            label="HML código provincia:"
                            name="hml_cod_province"
                            className="Create-User-Form-Item-Text"
                            >
                            <Input
                                autoComplete='off'
                                placeholder="HML código provincia"
                                onChange={(e) => dispatch(EditValueMasterClient('hml_cod_province', e.target.value))}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={6} lg={6}>
                        <Form.Item
                            label="HML provincia:"
                            name="hml_province"
                            className="Create-User-Form-Item-Text"
                            >
                            <Input
                                autoComplete='off'
                                placeholder="HML provincia"
                                onChange={(e) => dispatch(EditValueMasterClient('hml_province', e.target.value))}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={6} lg={6}>
                        <Form.Item
                            label="HML código distrito:"
                            name="hml_cod_district"
                            className="Create-User-Form-Item-Text"
                            >
                            <Input
                                autoComplete='off'
                                placeholder="HML código distrito"
                                onChange={(e) => dispatch(EditValueMasterClient('hml_cod_district', e.target.value))}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={6} lg={6}>
                        <Form.Item
                            label="HML distrito:"
                            name="hml_district"
                            className="Create-User-Form-Item-Text"
                            >
                            <Input
                                autoComplete='off'
                                placeholder="HML distrito"
                                onChange={(e) => dispatch(EditValueMasterClient('hml_district', e.target.value))}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={6} lg={6}>
                        <Form.Item
                            label="HML dirección:"
                            name="hml_address"
                            className="Create-User-Form-Item-Text"
                            >
                            <Input
                                autoComplete='off'
                                placeholder="HML dirección"
                                onChange={(e) => dispatch(EditValueMasterClient('hml_address', e.target.value))}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={6} lg={6}>
                        <Form.Item
                            label="Latitud:"
                            name="latitude"
                            className="Create-User-Form-Item-Text"
                            rules={[
                                {
                                    pattern: new RegExp(/^\d+(\.\d{1,2})?$/),
                                    message: "Formato inválido"
                                }
                            ]}
                            >
                            <Input
                                autoComplete='off'
                                placeholder="Latitud"
                                onChange={(e) => dispatch(EditValueMasterClient('latitude', e.target.value))}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={6} lg={6}>
                        <Form.Item
                            label="Longitud:"
                            name="longitude"
                            className="Create-User-Form-Item-Text"
                            rules={[
                                {
                                    pattern: new RegExp(/^\d+(\.\d{1,2})?$/),
                                    message: "Formato inválido"
                                }
                            ]}
                            >
                            <Input
                                autoComplete='off'
                                placeholder="Longitud"
                                onChange={(e) => dispatch(EditValueMasterClient('longitude', e.target.value))}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <DividerGlobal orientation="left" text="Extra"/>
                <Row gutter={24}>
                    <Col xs={24} sm={12} md={6} lg={6}>
                        <Form.Item
                            label="Status:"
                            name="status"
                            className="Create-User-Form-Item-Text"
                            >
                            <Input
                                autoComplete='off'
                                placeholder="Status"
                                onChange={(e) => dispatch(EditValueMasterClient('status', e.target.value))}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={6} lg={6}>
                        <Form.Item
                            label="Robot:"
                            name="robot"
                            className="Create-User-Form-Item-Text"
                            >
                            <Input
                                autoComplete='off'
                                placeholder="Robot"
                                onChange={(e) => dispatch(EditValueMasterClient('robot', e.target.value))}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={24}>
                        <Collapse className="Collapse-Form-Master-Client">
                            <Panel header="Campos extras">
                                <Row gutter={24}>
                                    <Col xs={24} sm={12} md={6} lg={6}>
                                        <Form.Item
                                            label="Class 12:"
                                            name="class_12"
                                            className="Create-User-Form-Item-Text"
                                            >
                                            <Input
                                                autoComplete='off'
                                                placeholder="Class 12"
                                                onChange={(e) => dispatch(EditValueMasterClient('class_12', e.target.value))}
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Panel>
                        </Collapse>
                    </Col>
                </Row>
                <Row style={{display:"flex", justifyContent:"center", marginTop:"20px"}} gutter={24}>
                    <Col>
                        <Form.Item
                            wrapperCol={{
                                offset: 8,
                                span: 16,
                            }}
                            >
                            <Button
                                onClick={() => navigate("/master-clients")}
                                danger
                                style={{
                                    height: "34px",
                                    paddingLeft: "15px",
                                    paddingRight: "15px",
                                    display: "flex",
                                    alignItems: "center",
                                    borderRadius: "20px",
                                    fontSize: "13px",
                                    fontFamily: "Inter",
                                }}
                            >
                                Cancelar
                            </Button>
                        </Form.Item>
                    </Col>
                    <Col>
                        <Form.Item
                            wrapperCol={{
                                offset: 8,
                                span: 16,
                            }}
                            >
                            <Button 
                                type="primary" 
                                htmlType="submit"
                                style={{
                                    background: "#2AD295",
                                    height: "34px",
                                    paddingLeft: "15px",
                                    paddingRight: "15px",
                                    display: "flex",
                                    alignItems: "center",
                                    borderRadius: "20px",
                                    color: "white",
                                    fontSize: "13px",
                                    fontFamily: "Inter",
                                }}
                            >
                                Guardar
                            </Button>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>

            <SideMenu
                usersCurrentView  = {rex_users_conected_dashboard}
                usersAllConnected ={rex_data_users_online}
            />
        </div>
    )
}

export default FormMasterClient