import React, {useEffect} from 'react'
import { Typography, Card } from 'antd'
import WOW from 'wowjs'
import config from '../../config'
import { useDispatch } from 'react-redux'
import { SelectModuleSelectedHomeModulesReducer } from '../../Redux/Actions/HomeModules/HomeModules'

const CardHome = (props) => {
    const titleCard = props.titleCard
    const imgCard   = props.imgCard
    const bgcolor   = props.backgroundColor
    const idCard    = props.idCard

    const dispatch = useDispatch()

    useEffect(() => {
        new WOW.WOW({
            live: false
        }).init()
    }, [])

    const { Title } = Typography
    
    return(
        <Card 
            onClick={() => {
                dispatch(SelectModuleSelectedHomeModulesReducer(idCard))
            }}
            className={`Container-Card-Categories ${bgcolor == "#A9D2FE" ? 'Shadow-Bluesky' : bgcolor == "#FDCA0F" ? 'Shadow-Yellow' : 'Shadow-Purple'}`}
        >
            <div className="Card-Background" style={{backgroundColor: bgcolor}}></div>
            <div className="Container-Round"></div>
            <img
                src={ config.api_url+imgCard }
                className="Image-Item-Categories"
                style={{
                    borderRadius : '100%'
                }}
            />
            <Title 
                level={3}
                className="Title-Item-Categories"
            >
                {titleCard}
            </Title>
        </Card>
    )
}

export default CardHome