import {
    GET_USERS_HOME_MODULES_CONNECTED,
    GET_MODULE_SELECTED_HOME_MODULES,
    GET_MODULE_ACTIVE_CLICK_HOME_MODULES,
    GET_MODULE_ACTIVE_HOVER_HOME_MODULES
} from "../../../Constants/HomeModules/HomeModules"


const INIT_STATE = {
    rex_users_home_modules_connected            : [],
    rex_module_selected_home_modules            : null,
    rex_module_active_click_home_modules        : null,
    rex_module_active_hover_home_modules        : null,
}

export default (state = INIT_STATE, action) => {

    switch (action.type) {
        case GET_USERS_HOME_MODULES_CONNECTED: {
            return {
                ...state,
                rex_users_home_modules_connected : action.payload,
            }
        }
        case GET_MODULE_SELECTED_HOME_MODULES: {
            return {
                ...state,
                rex_module_selected_home_modules : action.payload,
            }
        }
        case GET_MODULE_ACTIVE_CLICK_HOME_MODULES: {
            return {
                ...state,
                rex_module_active_click_home_modules : action.payload,
            }
        }
        case GET_MODULE_ACTIVE_HOVER_HOME_MODULES: {
            return {
                ...state,
                rex_module_active_hover_home_modules : action.payload,
            }
        }
        default:
            return state;
    }
}