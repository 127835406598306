import React, { useEffect, useState } from 'react'
import { Row, Popconfirm, Tooltip, Modal, Table, Input, Pagination } from 'antd'
import TitleSearchGlobal from '../../Components/Global/TitleSearchGlobal'
import FilterSelectGlobal from '../../Components/Global/FilterSelectGlobal'
import { useDispatch, useSelector } from 'react-redux'
import { DeleteFileUploadedFilesReducer, FilterFileNameUploadedFilesReducer, FilterFileUploadedFilesReducer, GetDataFilesUploadedFilesReducer, ProcessFileUploadedFilesReducer } from '../../Redux/Actions/UploadedFiles/UploadedFiles'
import NotificationUploadedFiles from '../../Components/UploadedFiles/NotificationUploadedFiles'
import '../../Styles/Routes/UploadedFiles/UploadedFiles.css'
import { 
    FilterOutlined, 
    NotificationOutlined,
    CheckCircleTwoTone    
} from '@ant-design/icons'
import SideMenu from '../../Components/Global/SideMenu'
import SimpleTableGlobal from '../../Components/Global/SimpleTableGlobal'
import ProfileTypesFilterSelect from '../../Components/ProfileTypes/ProfileTypesFilterSelect'
import TrashOptionButtonGlobal from '../../Assets/Images/Global/TrashOptionButtonGlobal'
import TrashButtonGlobal from '../../Assets/Images/Global/TrashButtonGlobal'
import moment from 'moment'

const UploadedFiles = () => {

    const [ openNotifications, setOpenNotifications ] = useState(false)
    const [pageTableGlobal, setPageTableGlobal ] = useState(1)
    const [pageSizeTableGlobal, setPageSizeTableGlobal ] = useState(10)
    const [ notifications, setNotifications ] = useState(null)
    const [ mouseOverRow ,setMouseOverRow] = useState(null)
    const [ showModal, setShowModal ] = useState(false)
    const [ infoModal, setInfoModal ] = useState({})
    const [ keyRowAction, setKeyRowAction ] = useState(null)
    const [ typeFile, setTypeFile ] = useState(null)
    const [ paiFilter, setPaiFilter ] = useState([])
    const [ txtFilter, setTxtFilter ] = useState('')
    const [ selectedRowKeys, setSelectedRowKeys ] = useState([])

    const dispatch = useDispatch()
    const { confirm } = Modal

    useEffect(() => {
        dispatch(GetDataFilesUploadedFilesReducer())
    },[])

    const { 
        rex_data_paises,
        rex_data_filtro_paises
    } = useSelector(({paises}) => paises)

    const { 
        rex_get_data_files_uploaded_files_clone,
        rex_get_data_filters_files_uploaded_files
    } = useSelector(({uploadedFiles}) => uploadedFiles)

    const { 
        rex_users_conected_dashboard,
    } = useSelector(({registerUsers}) => registerUsers)

    const { 
        rex_data_users_online
    } = useSelector(({users}) => users)

    const getColumnSearchProps = (dataIndex, nameColumn) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
            <div
                style={{
                    padding: 8,
                }}
                onKeyDown={(e) => e.stopPropagation()}
            >
                <Input.Search
                    placeholder={`Filtrar ${nameColumn}`}
                    value={selectedKeys[0]}
                    onChange={(e) => {
                        setSelectedKeys(e.target.value ? [e.target.value] : [])
                        confirm({
                            closeDropdown: false,
                        })
                    }}
                    style={{
                        display: 'block',
                    }}
                />
            </div>
        ),
        filterIcon: () => (<FilterOutlined/>),
        onFilter: (value, record) => {
            return record[dataIndex] != null ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : false
        }
    })

    const columns = [
        {
            title: 'Item',
            dataIndex: 'item',
            align: 'center',
            ellipsis : true,
            render: (text, record, index) => (pageTableGlobal - 1) * pageSizeTableGlobal + index + 1,
            width : '4%'
        },
        Table.SELECTION_COLUMN,
        {
            title: 'Archivo',
            dataIndex: 'carnombre',
            align: 'center',
            ellipsis : true,
            width : '10%',
            align : 'center',
            ...getColumnSearchProps('carnombre', 'nombre del archivo'),
            sorter: (a, b) => a.carnombre ? a.carnombre.localeCompare(b.carnombre) : false,
            showSorterTooltip: false,
            render:(_,record) => (
                <div className='Simple-Table-Contain-Text-Global Text-Left'>
                    <a href={record.carurl} target="_blank">
                        <span 
                            className='Text-Import-Data-Control'
                        >
                            {record.carnombre}
                        </span>
                    </a>
                </div>
            )
        },
        {
            title: 'Tipo de archivo',
            dataIndex: 'cartipo',
            align: 'center',
            ellipsis : true,
            width : '12%',
            sorter: (a, b) => a.cartipo ? a.cartipo.localeCompare(b.cartipo) : false,
            showSorterTooltip: false,
            render:(_, record) => (
                <div className='Simple-Table-Contain-Text-Global Text-Left'>
                    {record.cartipo}
                </div>
            ),
            filters: rex_get_data_filters_files_uploaded_files,
            onFilter: (value, record) => record.cartipo == value,
        },
        {
            title: 'Pais',
            dataIndex: 'painombre',
            align: 'center',
            ellipsis : true,
            width : '7%',
            sorter: (a, b) => a.painombre ? a.painombre.localeCompare(b.painombre) : false,
            showSorterTooltip: false,
            render:(_, record) => (
                <div className='Simple-Table-Contain-Text-Global'>
                    {record.painombre ? record.painombre : ''}
                </div>
            ),
            filters: rex_data_filtro_paises,
            onFilter: (value, record) => {
                return record.paipaises.paiid == value
            },
        },
        {
            title: 'Notificación',
            dataIndex: 'carnotificacion',
            align: 'center',
            width : '10%',
            ellipsis : true,
            width : '8%',
            showSorterTooltip: false,
            sorter: (a, b) => {
                if (a.carexito === true && b.carexito === true) {
                    return 0
                }
                if (a.carexito === true) {
                    return -1
                }
                if (b.carexito === true) {
                    return 1
                }
                return 0
            },
            render:(_, record) => (
                <Tooltip
                    title={'Notificación'}
                    color='#013A81'
                    placement='bottom'
                    overlayStyle={{marginTop:'20px'}}
                    showArrow={false}
                    overlayInnerStyle={{
                        fontSize: "12px",
                        minHeight: "26px",
                        display:"flex",
                        alignItems:"center",
                        height:"26px"
                    }}
                >
                    <div className='Uploaded-Files'>
                        <NotificationOutlined 
                            className={`Icon-Notification-${record.carexito ? 'Blue' : 'Red'}`}
                            onClick={() => {
                                setNotifications(record.carnotificaciones)
                                setTypeFile(record.cartipo)
                                setOpenNotifications(true)
                            }}
                        />
                    </div>
                </Tooltip>
            )
        },
        {
            title: 'Procesado',
            dataIndex: 'carprocesado',
            align: 'center',
            width : '12%',
            ellipsis : true,
            width : '8%',
            showSorterTooltip: false,
            sorter: (a, b) => {
                if (a.carfechaprocesado === null && b.carfechaprocesado === null) {
                    return 0
                }
                if (a.carfechaprocesado === null) {
                    return -1
                }
                if (b.carfechaprocesado === null) {
                    return 1
                }
                return a.carfechaprocesado.localeCompare(b.carfechaprocesado);
            },
            render:(_, record) => 
                <div title={record.carfechaprocesado ? 'Procesado' : ''}>
                    {
                        record.carfechaprocesado == null
                        ?   <div>
                                <div className='Cell-Action-Process-File'>
                                    <Tooltip
                                        title={'Procesar archivo'}
                                        placement='bottom'
                                        color='#013A81'
                                        overlayStyle={{marginTop:'20px'}}
                                        showArrow={false}
                                        overlayInnerStyle={{
                                            fontSize: "12px",
                                            minHeight: "26px",
                                            display:"flex",
                                            alignItems:"center",
                                            height:"26px"
                                        }}
                                    >
                                        <CheckCircleTwoTone
                                            onClick={() => dispatch(ProcessFileUploadedFilesReducer(record.carid))}
                                        />
                                    </Tooltip>

                                </div>


                                <span className='Cell-Information-No-Process-Uploaded-File'>
                                    No procesado
                                </span>   
                            </div>
                        :   
                            <div className='Table-Contain-Text-Global'>
                                Procesado
                            </div>
                            


                    }
                </div>,
            filters: [
                {
                    text: 'Procesado',
                    value: '',
                },
                {
                    text: 'No procesado',
                    value: null,
                },
            ],
            onFilter: (value, record) => {
                return record.carfechaprocesado == null ?  value == null : record.carfechaprocesado.includes('')
            },
        },
        {
            title: 'Fecha y hora de creación',
            dataIndex: 'created_at',
            align: 'center',
            width : '12%',
            ellipsis : true,
            width : '8%',
            sorter: (a, b) => a.carid? a.carid - b.carid : false,
            showSorterTooltip: false,
            render:(_, record) => (
                <div className='Simple-Table-Contain-Text-Global'>
                    {record.created_at ? moment(record.created_at).utc().format('DD MMM YYYY h:mm a') : ''}
                </div>
            )
        },
        {
            title: 'Fecha y hora de actualización',
            dataIndex: 'updated_at',
            align: 'center',
            width : '10%',
            ellipsis : true,
            width : '8%',
            render:(_, record) => (
                <div className='Simple-Table-Contain-Text-Global'>
                    {
                        record.updated_at 
                            ? moment(record.updated_at).utc().format('DD MMM YYYY h:mm a') 
                            : moment(record.created_at).utc().format('DD MMM YYYY h:mm a')
                    }
                </div>
            )
        },
        {
            title: 'Usuario',
            dataIndex: 'usuusuario',
            ellipsis : true,
            width : '12%',
            align : 'center',
            ...getColumnSearchProps('usuusuario', 'usuario que actualizó'),
            sorter: (a, b) => a.usuusuario ? a.usuusuario.localeCompare(b.usuusuario) : false,
            showSorterTooltip: false,
            render:(_,record) => (
                <div className='Simple-Table-Contain-Text-Global Text-Left'>
                    {record.usuusuario ? record.usuusuario : ''}
                </div>
            )
        },
        {
            title: 'Acción',
            dataIndex: 'actions',
            align: 'center',
            width: '7%',
            ellipsis: true,
            render:(_, record, index) => (
                <Tooltip
                    title={'Eliminar'}
                >
                    <Popconfirm
                        overlayClassName='PopOver-Confirm-Delete-Uploaded-Files'
                        title='Eliminar archivo'
                        description='¿Está seguro de eliminar el registro del archivo?'
                        okText='Confirmar'
                        cancelText='Cancelar'
                        placement="bottomRight"
                        onConfirm={() => dispatch(DeleteFileUploadedFilesReducer([record.carid]))}
                    >
                        <div>
                            <TrashOptionButtonGlobal/>
                        </div>
                    </Popconfirm>
                </Tooltip>
            )
        },
    ]
    
    const deleteUploadedFiles = (id, resetValues) => {

        if(id.length > 0){
            confirm({
                title   : <span>Confirmar</span>,
                icon    : null,
                content : `¿Está seguro que desea eliminar ${id.length > 1 ? 'los archivos': 'el archivo'}?. Esta acción es irreversible`,
                className :"Modal-Confirm-Data-Control",
                zIndex  : 100000000,
                async onOk(){
                    let respuesta = await dispatch(DeleteFileUploadedFilesReducer(id))
                    if(respuesta){
                        resetValues([])
                        setShowModal(true)
                        setInfoModal({
                            ...infoModal, 
                            title : "¡Listo!", 
                            description : `${selectedRowKeys.length > 1 ? "Los registros fueron eliminados" :  "El registro fue eliminado"} con éxito`,
                            status : true,
                            small: true
                        })
                    }
                },
                okCancel(){},
                okText : "Confirmar",
                okButtonProps: {
                    size:"small",
                },
                cancelText: "Cancelar",
                cancelButtonProps : {
                    size:"small",
                    danger:true
                },
            })
        }
    }

    const statusModal = () => {
        setShowModal(false)
    }
    
    const filterUploadedFiles = (value, type) => {
        
        if(type == 'paises'){
            dispatch(FilterFileUploadedFilesReducer(value))
        }else if(type == 'txtBuscar'){
            dispatch(FilterFileNameUploadedFilesReducer(value))
        }
    }

    const onSelectChange = (newSelectedRowKeys) => {
        setSelectedRowKeys(newSelectedRowKeys);
    };

    const toggleSelectAll = (valueCheckbox) => {
        setSelectedRowKeys((keys) =>
        valueCheckbox
            ?  rex_get_data_files_uploaded_files_clone.map((r) => r.key) 
            : []
        )
    }

    const rowSelection = {
        columnTitle: true ? <ProfileTypesFilterSelect
                                    showDropDown={true}
                                    toggleSelectAll={toggleSelectAll}
                                    selectedRowKeys={null}
                                    dataAmount={rex_get_data_files_uploaded_files_clone.length}
                                    setSelectedRowKeys={setSelectedRowKeys}
                                    data={rex_get_data_files_uploaded_files_clone}
                                    valueCheckbox={selectedRowKeys.length == rex_get_data_files_uploaded_files_clone.length}
                                    setValueCheckbox={() => console.log("ok")}
                                    actInacChechBox={false}
                                    themeColor='green'
        /> : <></>,
        selectedRowKeys,
        onChange: onSelectChange,
        type: "checkbox",
        fixed: true,
        onSelect: () => console.log("oko"),
    };

    return (
        <div className='Container-Main'>
            <TitleSearchGlobal
                title='Uploaded Files'
                setTxtFilter={setTxtFilter}
                funtionFilter={(valueSelected, type) => filterUploadedFiles(valueSelected, type)}
                showInputSearch={false}
            />

            <Row className='Users-Container-Actions'>
                <div style={{display:"flex", alignItems: "center", columnGap: "12px"}}>
                    <FilterSelectGlobal
                        data={rex_data_paises}
                        filterBy='paises'
                        setTypFilter={setPaiFilter}
                        funtionFilter={(valueSelected, type) => {
                            filterUploadedFiles(valueSelected, type)
                        }}
                    />
                    <div style={{display:"flex", alignItems: "center", columnGap: "12px"}}>
                        <TrashButtonGlobal
                            color='#013A81'
                            optionHover={true}
                            functionDelete={() => deleteUploadedFiles(selectedRowKeys, setSelectedRowKeys)}
                        />
                    </div>
                </div>
                <Pagination
                    total={rex_get_data_files_uploaded_files_clone.length}
                    showTotal={(total, range) => `${range[0]}-${range[1]} de ${rex_get_data_files_uploaded_files_clone.length}`}
                    defaultPageSize={pageSizeTableGlobal}
                    defaultCurrent={pageTableGlobal}
                    current={pageTableGlobal}
                    className='Simple-Table-Pagination-Global'
                    onChange={(page, pageSize) => {
                        setPageTableGlobal(page == 0? 1 : page)
                        setPageSizeTableGlobal(pageSize)
                    }}
                />
            </Row>

            <SimpleTableGlobal
                data={rex_get_data_files_uploaded_files_clone}
                keyRowAction={keyRowAction}
                rowSelection={rowSelection}
                columns={columns}
                pageTableGlobal={pageTableGlobal}
                pageSizeTableGlobal={pageSizeTableGlobal}
                actionEnterRow={setMouseOverRow}
                actionLeaveRow={() => console.log("ok")}
            />
            <Modal
                open={showModal}
                title={<span className={`${infoModal.status ? "Text-Ok" : "Text-Alert"}`}>{infoModal.title}</span>}
                width={300}
                zIndex={100000000}
                className={`Modal-Create-Data-Control ${infoModal.small && 'Modal-Small'}`}
                footer={[
                    <div style={{display:"flex", justifyContent:"center"}}>
                        <div className={`Button-Close Button${infoModal.status ? "-Ok" : "-Alert"}`} onClick={() => statusModal()}>
                            Ok
                        </div>
                    </div>
                ]}
            >
                <div className='Modal-Description'>
                    {infoModal.description}
                </div>
            </Modal>
            <NotificationUploadedFiles
                openModal={openNotifications}
                setOpenModal={setOpenNotifications}
                notifications={notifications}
                typeFile={typeFile}
            />
            
            <SideMenu
                usersCurrentView  = {rex_users_conected_dashboard}
                usersAllConnected ={rex_data_users_online}
            />
        </div>   
    )
}

export default UploadedFiles