import dayjs from "dayjs"
import notificationGlobal from "../../../Components/Global/NotificationGlobal"
import { 
    GET_DATA_FILTERS_CHANNEL, 
    GET_DATA_FILTERS_CLIENT, 
    GET_DATA_FILTERS_GBA, 
    GET_DATA_FILTERS_INCENTIVES, 
    GET_DATA_FILTERS_TXT_INCENTIV_PROGRAM_TOTAL, 
    GET_DATA_FILTERS_ZONE, 
    GET_DATA_INCENTIV_PROGRAM, 
    GET_DATA_INCENTIV_PROGRAM_CLONE, 
    GET_DATA_INCENTIV_PROGRAM_EDIT, 
    GET_DATA_LAST_UPDATE_INCENTIV_PROGRAM
} from "../../../Constants/IncentivProgram/IncentivProgram"
import config from "../../../config"

export const GetDataIncentivenProgramReducer = (date = null) => async ( dispatch, getState ) => {

    const filtersTotal      = getState().incentivProgram.rex_filters_incentiv_program_total
    let currentDate
    if(typeof(date) == "string" && date.length == 7){
        currentDate = date
    }else{
        const date = dayjs(dayjs(), "AAAA-MM")
        const currentMonth  = date.month() + 1 < 10 ? "0"+ (date.month() + 1) : date.month() + 1
        const currentYear   = date.year()
        currentDate   = currentYear + "-" + currentMonth
    }

    await fetch(config.api+'incentiven-program/all',
        {
            mode:'cors',
            method: 'POST',
            headers: {
                'Accept' : 'application/json',
                'Content-type' : 'application/json',
                'usutoken'	   : localStorage.getItem("usutoken"),
            },
            body: JSON.stringify({
                req_date : currentDate,
                req_filter_channel  : filtersTotal.rex_filter_incentiv_program.rex_txt_uniqueChannels,
                req_filter_client   : filtersTotal.rex_filter_incentiv_program.rex_txt_unique_cliente,
                req_filter_zone     : filtersTotal.rex_filter_incentiv_program.rex_txt_unique_zona,
                req_filter_gba      : filtersTotal.rex_filter_incentiv_program.rex_txt_unique_gba,
                req_filter_incentive: filtersTotal.rex_filter_incentiv_program.rex_txt_unique_incentive
            }),
        }
    )
    .then(res => res.json())
    .then(data => {
        if(data.response){
            dispatch({
                type    : GET_DATA_INCENTIV_PROGRAM,
                payload : [...data.data]
            })
            dispatch({
                type    : GET_DATA_INCENTIV_PROGRAM_CLONE,
                payload : [...data.dataClone]
            })

            dispatch({
                type    : GET_DATA_INCENTIV_PROGRAM_EDIT,
                payload : [...data.dataEdit]
            })

            dispatch({
                type    : GET_DATA_LAST_UPDATE_INCENTIV_PROGRAM,
                payload : data.lastUpdated
            })

            dispatch(GetDataFiltersIncentivenProgramReducer(currentDate))
        }
    }).catch((error) => {
        console.log(error)
    })
}

export const GetDataFiltersIncentivenProgramReducer = (date) => async ( dispatch, getState ) => {

    const filtersTotal      = getState().incentivProgram.rex_filters_incentiv_program_total
    await fetch(config.api+'incentiven-program/filters',
        {
            mode:'cors',
            method: 'POST',
            headers: {
                'Accept' : 'application/json',
                'Content-type' : 'application/json',
                'usutoken'	   : localStorage.getItem("usutoken"),
            },
            body: JSON.stringify({
                req_filter_channel  : filtersTotal.rex_filter_incentiv_program.rex_txt_uniqueChannels,
                req_filter_client   : filtersTotal.rex_filter_incentiv_program.rex_txt_unique_cliente,
                req_filter_zone     : filtersTotal.rex_filter_incentiv_program.rex_txt_unique_zona,
                req_filter_gba      : filtersTotal.rex_filter_incentiv_program.rex_txt_unique_gba,
                req_date            : date
            }),
        }
    )
    .then(res => res.json())
    .then(data => {
        if(data.response){
            dispatch({
                type    : GET_DATA_FILTERS_GBA,
                payload : data.uniqueGba
            })
            dispatch({
                type    : GET_DATA_FILTERS_CHANNEL,
                payload : data.uniqueChannels
            })

            dispatch({
                type    : GET_DATA_FILTERS_ZONE,
                payload : data.uniqueZona
            })

            dispatch({
                type    : GET_DATA_FILTERS_CLIENT,
                payload : data.uniqueCliente
            })
            dispatch({
                type    : GET_DATA_FILTERS_INCENTIVES,
                payload : data.uniqueIncentives
            })

        }
    }).catch((error) => {
        console.log(error)
    })
}

export const CambiarTxtFilterIncentivenProgramReducer = (type, txt_data, dateFilter, view) => async ( dispatch, getState ) => {

    const filtersTotal      = getState().incentivProgram.rex_filters_incentiv_program_total
    const filteredGba       = getState().incentivProgram.rex_filters_items_gba
    const filteredChannel   = getState().incentivProgram.rex_filters_items_channel
    const filteredZone      = getState().incentivProgram.rex_filters_items_zone
    const filteredClient    = getState().incentivProgram.rex_filters_items_client
    let dataIncentivenFiltered    = getState().incentivProgram.rex_data_incentiv_program_clone

    if(!txt_data){
        txt_data = ""
    }

    if(type == "GBA"){
        type = "rex_txt_unique_gba"
    }else if(type == "Canal"){
        type = "rex_txt_uniqueChannels"
    }else if(type == "Zona"){
        type = "rex_txt_unique_zona"
    }else if(type == "Cliente HML"){
        type = "rex_txt_unique_cliente"
    }else if(type == "Incentivos"){
        type = "rex_txt_unique_incentive"
    }
    
    const valuesFilter = filtersTotal['rex_filter_incentiv_program']
    filtersTotal['rex_filter_incentiv_program'] = {...valuesFilter, [type] : txt_data}

    dispatch({
        type : GET_DATA_FILTERS_TXT_INCENTIV_PROGRAM_TOTAL,
        payload : filtersTotal
    })

    dispatch({
        type : GET_DATA_FILTERS_GBA,
        payload : filteredGba
    })

    dispatch({
        type : GET_DATA_FILTERS_CLIENT,
        payload : filteredClient
    })

    dispatch({
        type : GET_DATA_FILTERS_CHANNEL,
        payload : filteredChannel
    })
    dispatch({
        type : GET_DATA_FILTERS_ZONE,
        payload : filteredZone
    })

    dispatch({
        type : GET_DATA_INCENTIV_PROGRAM,
        payload : dataIncentivenFiltered
    })

    dispatch(GetDataIncentivenProgramReducer(dateFilter))
}

export const EditRowTableIncentivenProgramReducer = ( titleTable, indexKey, titleSection, titleDetail ) => async ( dispatch, getState ) => {

    const dataIncentivenProgram = getState().incentivProgram.rex_data_incentiv_program

    let indexZone = dataIncentivenProgram.findIndex(dat => dat.pcinombre == titleTable)
    let indexSection = dataIncentivenProgram[indexZone].pti_pe_tipos_incentivos.findIndex(inf=> inf.ptinombre == titleSection)    
    let indexDetail = dataIncentivenProgram[indexZone]['pti_pe_tipos_incentivos'][indexSection]['data'].findIndex(det => det.ptiindicador == titleDetail)

    dataIncentivenProgram[indexZone]['pti_pe_tipos_incentivos'][indexSection]['data'][indexDetail]['data'].map((dat, index) => {
        if(index == indexKey){
            dat['editing'] = true
        }
    })

    dispatch({
        type : GET_DATA_INCENTIV_PROGRAM,
        payload : dataIncentivenProgram
    })
}

export const AddRowTableIncentivenProgramReducer = ( titleTable, indexKey, titleSection, titleDetail ) => async ( dispatch, getState ) => {

    const dataIncentivenProgram = getState().incentivProgram.rex_data_incentiv_program

    let indexZone = dataIncentivenProgram.findIndex(dat => dat.pcinombre == titleTable)
    let indexSection = dataIncentivenProgram[indexZone].pti_pe_tipos_incentivos.findIndex(inf=> inf.ptinombre == titleSection)    
    let indexDetail = dataIncentivenProgram[indexZone]['pti_pe_tipos_incentivos'][indexSection]['data'].findIndex(det => det.ptiindicador == titleDetail)

    dataIncentivenProgram[indexZone]['pti_pe_tipos_incentivos'][indexSection]['data'][indexDetail]['data'].map((dat, index) => {
        if(index == indexKey){
            let newOrden = (dataIncentivenProgram[indexZone]['pti_pe_tipos_incentivos'][indexSection]['data'][indexDetail]['data'][index]?.orden) 
            
            let newRow = {
                petdesde    : "",
                pethasta    : "",
                petganancia : "",
                usuusuarios : { usuusuario : localStorage.getItem('usuusuario')},
                date        : "",
                petid       : Math.floor(Math.random() * (9999999999 - 1000000000 + 1)) + 1000000000,
                editing     : true,
                newRow      : true,
                ptiid       : dat.ptiid,
                orden       : newOrden +1 ,
                pciid       : dat.pciid,
            }
            dataIncentivenProgram[indexZone]['pti_pe_tipos_incentivos'][indexSection]['data'][indexDetail]['data'].splice(index+1, 0, newRow)
        }
    })

    dispatch({
        type : GET_DATA_INCENTIV_PROGRAM,
        payload : dataIncentivenProgram
    })

    dispatch({
        type : GET_DATA_INCENTIV_PROGRAM_CLONE,
        payload : dataIncentivenProgram
    })
}

export const DeleteNewRowTableIncentivenProgramReducer = ( titleTable, titleSection, titleDetail, rowData, date, indexRow ) => async ( dispatch, getState ) => {

    let dataDelete = {}
    
    switch (rowData.type) {
        case 'gba':
            dataDelete = {...dataDelete, type : "gba", id : rowData.igeid }
            break;
        case 'channel':
            dataDelete = {...dataDelete, type : "channel", id : rowData.iceid }
            break;
        case 'zone':
            dataDelete = {...dataDelete, type : "zone", id : rowData.izeid }
            break;
        case 'client':
            dataDelete = {...dataDelete, type : "client", id : rowData.icleid }
            break;
            default:
            dataDelete = {...dataDelete, type : "incentive", id : rowData.petid }
    }

    await fetch(config.api+'incentiven-program/delete',
        {
            mode:'cors',
            method: 'POST',
            headers: {
                'Accept' : 'application/json',
                'Content-type' : 'application/json',
                'usutoken'	   : localStorage.getItem("usutoken"),
            },
            body: JSON.stringify({
                req_data : dataDelete,
            }),
        }
    )
    .then(res => res.json())
    .then(data => {
        if(data.response){
            dispatch(GetDataIncentivenProgramReducer(date))
            notificationGlobal("success", data.message)
        }
    }).catch((error) => {
        console.log(error)
    })
}

export const DeleteRowEditingTableIncentivenProgramReducer = ( titleTable, titleSection, titleDetail, key, indexRow ) => async ( dispatch, getState ) => {

    const dataIncentivenProgram = getState().incentivProgram.rex_data_incentiv_program

    let indexZone = dataIncentivenProgram.findIndex(dat => dat.pcinombre == titleTable)
    let indexSection = dataIncentivenProgram[indexZone].pti_pe_tipos_incentivos.findIndex(inf=> inf.ptinombre == titleSection)    
    let indexDetail = dataIncentivenProgram[indexZone]['pti_pe_tipos_incentivos'][indexSection]['data'].findIndex(det => det.ptiindicador == titleDetail)

    let dataFiltered = dataIncentivenProgram[indexZone]['pti_pe_tipos_incentivos'][indexSection]['data'][indexDetail]['data']

    dataIncentivenProgram[indexZone]['pti_pe_tipos_incentivos'][indexSection]['data'][indexDetail]['data'].map((dat, index) => {
        if(index == indexRow){
            if(dat.newRow){
                dataIncentivenProgram[indexZone]['pti_pe_tipos_incentivos'][indexSection]['data'][indexDetail]['data'] = dataFiltered.filter((dat, index) => index != indexRow)
            }else{
                dat['editing'] = false
            }
        }
    })

    dispatch({
        type : GET_DATA_INCENTIV_PROGRAM,
        payload : dataIncentivenProgram
    })
}

export const SaveNewRowTableIncentivenProgramReducer = ( titleTable, titleSection, titleDetail, key, index, date = null ) => async ( dispatch, getState ) => {

    const dataForm              = getState().incentivProgram.rex_data_incentiv_program_edit
    const filtersTotal          = getState().incentivProgram.rex_filters_incentiv_program_total
    const dataIncentivenProgram = getState().incentivProgram.rex_data_incentiv_program

    let currentMonth
    let currentYear
    let currentDate = ""

    if(typeof(date) == "string" && date.length == 7){
        currentDate     = date
        let yearMonth   = currentDate.split("-")
        currentYear     = yearMonth[0]
        currentMonth    = yearMonth[1]
    }else{
        const date = dayjs(dayjs(), "AAAA-MM")
        currentMonth  = date.month() + 1 < 10 ? "0"+ (date.month() + 1) : date.month() + 1
        currentYear   = date.year()
        currentDate   = currentYear + "-" + currentMonth
    }

    let indexZoneDataForm       = dataForm.findIndex(dat => dat.pcinombre == titleTable)
    let indexSectionDataForm    = dataForm[indexZoneDataForm].pti_pe_tipos_incentivos.findIndex(inf=> inf.ptinombre == titleSection)    
    let indexDetailDataForm     = dataForm[indexZoneDataForm]['pti_pe_tipos_incentivos'][indexSectionDataForm]['data'].findIndex(det => det.ptiindicador == titleDetail)
    let rowData                 = dataForm[indexZoneDataForm]['pti_pe_tipos_incentivos'][indexSectionDataForm]['data'][indexDetailDataForm]['data'][index]

    let apiAction = rowData.newRow ? "create" : "edit"

    let idEdit

    if(filtersTotal.rex_filter_incentiv_program.rex_txt_unique_cliente != ""){
        rowData.type = "client"
    }else if(filtersTotal.rex_filter_incentiv_program.rex_txt_unique_zona != ""){
        rowData.type = "zone"
    }else if(filtersTotal.rex_filter_incentiv_program.rex_txt_uniqueChannels != ""){
        rowData.type = "channel"
    }else if(filtersTotal.rex_filter_incentiv_program.rex_txt_unique_gba != ""){
        rowData.type = "gba"
    }else{
        rowData.type = "incentive"
    }

    switch (rowData.type) {
        case 'gba':
            idEdit = rowData.igeid
            break;
        case 'channel':
            idEdit = rowData.iceid
            break;
        case 'zone':
            idEdit = rowData.izeid
            break;
        case 'client':
            idEdit = rowData.icleid
            break;
        default:
            idEdit = rowData.petid
    }

    let responseSave = false

    await fetch(config.api+"incentiven-program/"+apiAction,
        {
            mode:'cors',
            method: 'POST',
            headers: {
                'Accept' : 'application/json',
                'Content-type' : 'application/json',
                'usutoken'	   : localStorage.getItem("usutoken"),
            },
            body: JSON.stringify({
                req_petdesde    : rowData.petdesde,
                req_pethasta    : rowData.pethasta,
                req_petganancia : rowData.petganancia,
                req_ptiid       : rowData.ptiid,
                req_orden       : rowData.orden,
                req_pciid       : rowData.pciid,
                req_type        : rowData.type,
                req_anio        : currentYear,
                req_id          : idEdit,
                req_mes         : currentMonth,
                req_fecha       : currentDate,
                req_client      : filtersTotal.rex_filter_incentiv_program.rex_txt_unique_cliente,
                req_zone        : filtersTotal.rex_filter_incentiv_program.rex_txt_unique_zona,
                req_channel     : filtersTotal.rex_filter_incentiv_program.rex_txt_uniqueChannels,
                req_gba         : filtersTotal.rex_filter_incentiv_program.rex_txt_unique_gba,
            }),
        }
    )
    .then(res => res.json())
    .then(data => {
        if(data.response){
            let rowInfo = dataIncentivenProgram[indexZoneDataForm]['pti_pe_tipos_incentivos'][indexSectionDataForm]['data'][indexDetailDataForm]['data'][index]
            rowInfo['editing'] = false
            rowInfo['petdesde'] = rowData.petdesde
            rowInfo['pethasta'] = rowData.pethasta
            rowInfo['petganancia'] = rowData.petganancia
            rowInfo['newRow'] = false
            responseSave = true
            // dispatch(GetDataIncentivenProgramReducer(currentDate))
            
            notificationGlobal("success", data.message)
            dispatch({
                type: GET_DATA_INCENTIV_PROGRAM,
                payload : dataIncentivenProgram
            })
        }
    }).catch((error) => {
        console.log(error)
    })

    if(responseSave && !rowData.newRow){
        let response = await dispatch(GetDataIncentivenProgramBackupReducer(currentDate))
        let dataResponse = response[indexZoneDataForm]['pti_pe_tipos_incentivos'][indexSectionDataForm]['data'][indexDetailDataForm]['data'][index]

        dataIncentivenProgram[indexZoneDataForm]['pti_pe_tipos_incentivos'][indexSectionDataForm]['data'][indexDetailDataForm]['data'][index] = dataResponse

        dispatch({
            type: GET_DATA_INCENTIV_PROGRAM,
            payload : dataIncentivenProgram
        })
    }   
}

export const EditValueRowTableIncentivenProgramReducer = ( value, type, titleTable, titleSection, titleDetail, indexRow ) => async ( dispatch, getState ) => {

    const dataIncentivenProgramClone = [...getState().incentivProgram.rex_data_incentiv_program_clone]
    let indexZone = dataIncentivenProgramClone.findIndex(dat => dat.pcinombre == titleTable)

    dataIncentivenProgramClone[indexZone].pti_pe_tipos_incentivos.map(zon => {
        zon.data.map(inf => {
            inf.data.map((typ, index)  => {
                if(index == indexRow){
                    typ[type] = value.toString()
                }
            })
        })
    })
    
    dispatch({
        type : GET_DATA_INCENTIV_PROGRAM_EDIT,
        payload : dataIncentivenProgramClone
    })
}

export const GetDataIncentivenProgramBackupReducer = (date = null) => async ( dispatch, getState ) => {
    
    const filtersTotal = getState().incentivProgram.rex_filters_incentiv_program_total

    let response

    let currentDate
    if(typeof(date) == "string" && date.length == 7){
        currentDate = date
    }else{
        const date = dayjs(dayjs(), "AAAA-MM")
        const currentMonth  = date.month() + 1 < 10 ? "0"+ (date.month() + 1) : date.month() + 1
        const currentYear   = date.year()
        currentDate   = currentYear + "-" + currentMonth
    }

    await fetch(config.api+'incentiven-program/all',
        {
            mode:'cors',
            method: 'POST',
            headers: {
                'Accept' : 'application/json',
                'Content-type' : 'application/json',
                'usutoken'	   : localStorage.getItem("usutoken"),
            },
            body: JSON.stringify({
                req_date : currentDate,
                req_filter_channel  : filtersTotal.rex_filter_incentiv_program.rex_txt_uniqueChannels,
                req_filter_client   : filtersTotal.rex_filter_incentiv_program.rex_txt_unique_cliente,
                req_filter_zone     : filtersTotal.rex_filter_incentiv_program.rex_txt_unique_zona,
                req_filter_gba      : filtersTotal.rex_filter_incentiv_program.rex_txt_unique_gba,
                req_filter_incentive: filtersTotal.rex_filter_incentiv_program.rex_txt_unique_incentive
            }),
        }
    )
    .then(res => res.json())
    .then(data => {
        if(data.response){

            response = [...data.dataClone]
        }
    }).catch((error) => {
        console.log(error)
    })

    return response
}

export const SaveIncentivenProgramReducer = (date, action) => async ( dispatch, getState ) => {
    
    const filterDate    = date ? dayjs(dayjs(date), "AAAA-MM") : dayjs(dayjs(), "AAAA-MM")
    const filterYear    = filterDate.year()
    const filterMonth   = filterDate.month() + 1

    const confirmDelete = action == "confirm-save-incentiven-program"

    let response

    await fetch(config.api+'incentiven-program/'+action,
        {
            mode:'cors',
            method: 'POST',
            headers: {
                'Accept' : 'application/json',
                'Content-type' : 'application/json',
                'usutoken'	   : localStorage.getItem("usutoken"),
            },
            body: JSON.stringify({
                req_month   : filterMonth,
                req_year    : filterYear
            }),
        }
    )
    .then(res => res.json())
    .then(data => {
        response = data.response
        if(confirmDelete || data.generate){
            notificationGlobal(data.response ? "success" : "warning", data.message)
        }
    }).catch((error) => {
        console.log(error)
    })

    return response
}