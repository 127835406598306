import React, { useState } from 'react'

function OptionButton({fill, width, height}){
    return (
        <svg width={width ? width : "15"} height={height ? height : "10"} viewBox="0 0 15 10" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M13.6667 1.22659C13.4169 0.978256 13.0789 0.838867 12.7267 0.838867C12.3744 0.838867 12.0365 0.978256 11.7867 1.22659L7.00001 5.94659L2.28001 1.22659C2.0302 0.978256 1.69226 0.838867 1.34001 0.838867C0.987765 0.838867 0.64983 0.978256 0.400013 1.22659C0.275042 1.35054 0.175849 1.49801 0.108157 1.66049C0.0404659 1.82297 0.00561523 1.99724 0.00561523 2.17326C0.00561523 2.34927 0.0404659 2.52355 0.108157 2.68603C0.175849 2.84851 0.275042 2.99597 0.400013 3.11992L6.05335 8.77326C6.1773 8.89823 6.32476 8.99742 6.48724 9.06511C6.64972 9.1328 6.824 9.16765 7.00001 9.16765C7.17603 9.16765 7.3503 9.1328 7.51278 9.06511C7.67526 8.99742 7.82273 8.89823 7.94668 8.77326L13.6667 3.11992C13.7916 2.99597 13.8908 2.84851 13.9585 2.68603C14.0262 2.52355 14.0611 2.34927 14.0611 2.17326C14.0611 1.99724 14.0262 1.82297 13.9585 1.66049C13.8908 1.49801 13.7916 1.35054 13.6667 1.22659Z" fill={fill}/>
        </svg>
    )
}

const ArrowDownCollapse = ({onHover, width, height}) => {
    return (
        <OptionButton fill={onHover} width={width} height={height} />
    )
}

export default ArrowDownCollapse