import {
    GET_MODULE_DATA,
    GET_MODULE_DATA_CLONE,
    STORE_SUBMODULE_DATA,
    STORE_SUBSUBMODULE_DATA,
    LOADING_SAVE_DATA,
    GET_MODULE_DATA_MENU,
    GET_MODULE_DATA_MENU_CLONE,
} from "../../../Constants/Modules/CreateEditModules"

const INIT_STATE = {
    rex_get_module_data  : {},
    rex_get_module_data_clone  : {},
    rex_get_module_data_home  : [
        {
            modid: 1,
            modnombre: 'Status',
            modicono: false,
            modvisualizacion: true,
            key: 'home-1',
            title: 'Status',
            toUrl: '/status',
            children: [],
            type: "menu",
        },
        {
            modid: 2,
            modnombre: 'Analytics',
            modicono: false,
            modvisualizacion: true,
            key: 'home-2',
            title: 'Analytics',
            toUrl: '/analytics',
            children: [],
            type: "menu",
        },
        {
            modid: 3,
            modnombre: 'Download Files',
            modicono: false,
            modvisualizacion: true,
            key: 'home-3',
            title: 'Download Files',
            toUrl: '/download-data',
            children: [],
            type: "menu",
        },
        {
            modid: 4,
            modnombre: 'File Upload',
            modicono: false,
            modvisualizacion: true,
            key: 'home-4',
            title: 'File Upload',
            toUrl: '/file-upload',
            children: [],
            type: "menu",
        },
        {
            modid: 5,
            modnombre: 'Administrador',
            modicono: false,
            modvisualizacion: true,
            key: 'home-5',
            title: 'Administrador',
            toUrl: '/home-administrator',
            children: [],
            type: "menu",
        },
    ],
    rex_get_module_data_home_clone : [],
    rex_store_submodule_data  : [],
    rex_store_subsubmodule_data  : [],
    rex_loading_save_data  : false,
}

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_MODULE_DATA: {
            return {
                ...state,
                rex_get_module_data : action.payload,
            }
        }
        case GET_MODULE_DATA_CLONE: {
            return {
                ...state,
                rex_get_module_data_clone : action.payload,
            }
        }
        case GET_MODULE_DATA_MENU: {
            return {
                ...state,
                rex_get_module_data_home : action.payload,
            }
        }
        case GET_MODULE_DATA_MENU_CLONE: {
            return {
                ...state,
                rex_get_module_data_home_clone : action.payload,
            }
        }
        case STORE_SUBMODULE_DATA: {
            return {
                ...state,
                rex_store_submodule_data : action.payload,
            }
        }
        case STORE_SUBSUBMODULE_DATA: {
            return {
                ...state,
                rex_store_subsubmodule_data : action.payload,
            }
        }
        case LOADING_SAVE_DATA: {
            return {
                ...state,
                rex_loading_save_data : action.payload,
            }
        }
        default:
            return state;
    }
}