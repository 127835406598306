import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button } from 'antd'
import { ArrowUpOutlined, ArrowDownOutlined, CloudDownloadOutlined } from '@ant-design/icons'
import { DownloadDataExcelReducer } from '../../Redux/Actions/DownloadData/DownloadData'
import ModalDownloadData from './ModalDownloadData'

const CardDownload = (props) => {
    let downloadData = props.downloadData
    const [mouseOver, setMouseOver] = useState(null)
    const [activeBackground, setActiveBackground] = useState(false)
    const [activeButton, setActiveButton] = useState(true)
    const [initialPosition, setInitialPosition] = useState(1)
    const [containerPosition, setContainerPosition] = useState(1)
    const [animationText, setAnimationText] = useState("")
    const [isTabVisible, setIsTabVisible] = useState(false)
    const [ showModal, setShowModal ] = useState(false)
    const [ dataDownload, setDataDownload ] = useState({})

    const dispatch = useDispatch()
    
    // downloadData = [
    //     {key: 1, ardnombre: 'Kimberly Pivot en línea 1', ardarchivo: "Kimberly Pivot"},
    //     {key: 2, ardnombre: 'Kimberly Pivot en línea 2', ardarchivo: "Kimberly Pivot"},
    //     {key: 3, ardnombre: 'Kimberly Pivot en línea 3', ardarchivo: "Kimberly Pivot"},
    //     {key: 4, ardnombre: 'Kimberly Pivot en línea 4', ardarchivo: "Kimberly Pivot"},
    //     {key: 5, ardnombre: 'Kimberly Pivot en línea 5', ardarchivo: "Kimberly Pivot"},
    //     {key: 6, ardnombre: 'Kimberly Pivot en línea 6', ardarchivo: "Kimberly Pivot"},
    //     // {key: 7, ardnombre: 'Kimberly Pivot en línea 7', ardarchivo: "Kimberly Pivot"},
    //     // {key: 8, ardnombre: 'Kimberly Pivot en línea 8', ardarchivo: "Kimberly Pivot"},
    //     // {key: 9, ardnombre: 'Kimberly Pivot en línea 9', ardarchivo: "Kimberly Pivot"},
    // ]

    const upButtonCarrousel = () => {
        setAnimationText("top")
        setActiveButton(false)
        setIsTabVisible(false)
        if(downloadData.length == 5){
            if(containerPosition == 10){
                setContainerPosition(1)
            }else{
                setContainerPosition(containerPosition + 1)
            }
        }else{
            if(downloadData.length == initialPosition){
                setInitialPosition(1)
            }else{
                setInitialPosition(initialPosition + 1)
            }
        }

        setTimeout(() => {
            setActiveButton(true)
        }, 500)
        setTimeout(() => {
            setIsTabVisible(true)
        }, 1000)
    }

    const downButtonCarrousel = () => {
        setAnimationText("bottom")
        setActiveButton(false)
        setIsTabVisible(false)
        if(downloadData.length == 5){
            if(containerPosition == 1){
                setContainerPosition(10)
            }else{
                setContainerPosition(containerPosition - 1)
            }
        }else{
            if(initialPosition == 1){
                setInitialPosition(downloadData.length)
            }else{
                setInitialPosition(initialPosition - 1)
            }
        }

        setTimeout(() => {
            setActiveButton(true)
        }, 500)
        setTimeout(() => {
            setIsTabVisible(true)
        }, 1000)
    }

    useEffect(() => {
        const visibleTab = () => {
            setTimeout(() => {
                setIsTabVisible(!document.hidden)
            }, 1000)
        }

        visibleTab()
        document.addEventListener('visibilitychange', visibleTab)
    
        return () => document.removeEventListener('visibilitychange', visibleTab)
    }, [])

    useEffect(() => {
        let timerInterval
        if(isTabVisible){
            timerInterval = setInterval(() => {
                if(downloadData.length > 4){
                    setAnimationText("top")
                    setActiveButton(false)
                    if(downloadData.length == 5){
                        setContainerPosition((position) => {
                            if(position == 10){
                                return 1
                            }else{
                                return position + 1
                            }
                        })
                    }else{
                        setInitialPosition((initial) => {
                            if(downloadData.length == initial){
                                return 1
                            }else{
                                return initial + 1
                            }
                        })
                    }
                    setTimeout(() => {
                        setActiveButton(true)
                    }, 500)
                }
            }, 3500)
        }
        return () => clearInterval(timerInterval)
    }, [isTabVisible])

    return(
        <div className={`Container-Card-Download ${activeBackground && 'Container-Active-Download'}`}>
            <Button 
                className="Button-Scroll"
                style={{
                    transform: `translate(52vw, 1vh)`,
                    display: downloadData.length <= 4 && 'none',
                    pointerEvents: activeButton == false && 'none',
                }}
                onClick={() => upButtonCarrousel()}
            >
                ver más <ArrowUpOutlined />
            </Button>
            {
                downloadData.length > 0
                ?
                <div className="Container-Overflow-Item-Download">
                {
                    downloadData.length == 5
                    ? <>
                    <div 
                        className="Container-Card-Item-Download" 
                        style={{
                            transform: 
                            containerPosition == 2 
                                ? 'translateY(70vh)' 
                                : containerPosition == 3
                                    ? 'translateY(47vh)'
                                    : containerPosition == 4
                                        ? 'translateY(27vh)'
                                        : containerPosition == 5
                                            ? 'translateY(13vh)'
                                            : containerPosition == 6
                                                ? 'translateY(-2vh)'
                                                : containerPosition == 7
                                                    ? 'translateY(-7vh)'
                                                    : containerPosition == 8
                                                        ? 'translateY(-17vh)'
                                                        : containerPosition == 9
                                                            ? 'translateY(-35vh)'
                                                            : containerPosition == 10
                                                                ? 'translateY(-48vh)'
                                                                : 'translateY(-73vh)', 
                            transition: 'transform 1s, z-index 0s', 
                            paddingTop: '0', 
                            position: 'relative', 
                            height: '72vh', 
                            width: '600px',
                            backgroundColor: '#ffffff',
                            visibility: containerPosition == 1 || containerPosition == 2 ? 'hidden' : 'visible',
                            zIndex: containerPosition == 1 || containerPosition == 2 ? '-1' : '1',
                        }}
                    >
                        {
                            downloadData.map((data, index) => {
                                let key_index = index + 1
                                let styleTransform = 'translate(-250px, 20vh)'
                                if(containerPosition == 1){
                                    if(key_index == 5){
                                        styleTransform = 'translate(-250px, 50vh)'
                                    }
                                }else if(containerPosition == 2){
                                    if(key_index == 1){
                                        styleTransform = 'translate(-250px, 5vh)'
                                    }
                                }else if(containerPosition == 3){
                                    if(key_index == 1){
                                        styleTransform = 'translate(-150px, 10vh)'
                                    }else{
                                        styleTransform = 'translate(-250px, 26vh)'
                                    }
                                }else if(containerPosition == 4){
                                    if(key_index == 1){
                                        styleTransform = 'translate(-100px, 14vh)'
                                    }else if(key_index == 2){
                                        styleTransform = 'translate(-150px, 26.5vh)'
                                    }else{
                                        styleTransform = 'translate(-250px, 45vh)'
                                    }
                                }else if(containerPosition == 5){
                                    if(key_index == 1){
                                        styleTransform = 'translate(-100px, 11vh)'
                                    }else if(key_index == 2){
                                        styleTransform = 'translate(-100px, 25vh)'
                                    }else if(key_index == 3){
                                        styleTransform = 'translate(-150px, 38vh)'
                                    }else{
                                        styleTransform = 'translate(-250px, 55vh)'
                                    }
                                }else if(containerPosition == 6){
                                    if(key_index == 1){
                                        styleTransform = 'translate(-150px, 11vh)'
                                    }else if(key_index == 2){
                                        styleTransform = 'translate(-100px, 24vh)'
                                    }else if(key_index == 3){
                                        styleTransform = 'translate(-100px, 38vh)'
                                    }else if(key_index == 4){
                                        styleTransform = 'translate(-150px, 51vh)'
                                    }else{
                                        styleTransform = 'translate(-250px, 65vh)'
                                    }
                                }else if(containerPosition == 7){
                                    if(key_index == 1){
                                        styleTransform = 'translate(-250px, -2vh)'
                                    }else if(key_index == 2){
                                        styleTransform = 'translate(-150px, 14vh)'
                                    }else if(key_index == 3){
                                        styleTransform = 'translate(-100px, 27vh)'
                                    }else if(key_index == 4){
                                        styleTransform = 'translate(-100px, 40vh)'
                                    }else if(key_index == 5){
                                        styleTransform = 'translate(-150px, 53vh)'
                                    }
                                }else if(containerPosition == 8){
                                    if(key_index == 1){
                                        styleTransform = 'translate(-250px, -2vh)'
                                    }else if(key_index == 2){
                                        styleTransform = 'translate(-250px, 4vh)'
                                    }else if(key_index == 3){
                                        styleTransform = 'translate(-150px, 20vh)'
                                    }else if(key_index == 4){
                                        styleTransform = 'translate(-100px, 34vh)'
                                    }else if(key_index == 5){
                                        styleTransform = 'translate(-100px, 48vh)'
                                    }
                                }else if(containerPosition == 9){
                                    if(key_index == 1){
                                        styleTransform = 'translate(-250px, 5vh)'
                                    }else if(key_index == 2){
                                        styleTransform = 'translate(-250px, 15vh)'
                                    }else if(key_index == 3){
                                        styleTransform = 'translate(-250px, 20vh)'
                                    }else if(key_index == 4){
                                        styleTransform = 'translate(-150px, 36vh)'
                                    }else if(key_index == 5){
                                        styleTransform = 'translate(-100px, 49vh)'
                                    }
                                }else if(containerPosition == 10){
                                    if(key_index == 1){
                                        styleTransform = 'translate(-250px, 5vh)'
                                    }else if(key_index == 2){
                                        styleTransform = 'translate(-250px, 15vh)'
                                    }else if(key_index == 3){
                                        styleTransform = 'translate(-250px, 25vh)'
                                    }else if(key_index == 4){
                                        styleTransform = 'translate(-250px, 30vh)'
                                    }else if(key_index == 5){
                                        styleTransform = 'translate(-150px, 46.5vh)'
                                    }
                                }
                                return(
                                <div 
                                    className={`Container-Item-Download ${mouseOver == key_index + 5 && 'Container-Active-Item-Download'}`} 
                                    style={{
                                        transform: styleTransform,
                                        transition: 'transform 1s, z-index 0s',
                                        zIndex: containerPosition == 1 || containerPosition == 2 ? '-1' : '1',
                                    }}
                                    key={key_index}
                                >
                                    <div 
                                        className="Container-Circle-Download"
                                        onMouseOver={() => {
                                            setMouseOver(key_index + 5)
                                            setActiveBackground(true)
                                        }}
                                        onMouseLeave={() => {
                                            setMouseOver(null)
                                            setActiveBackground(false)
                                        }}
                                        style={{cursor: 'pointer'}}
                                        onClick={async () => {
                                            setIsTabVisible(false)
                                            if(!data.ardfiltros){
                                                const fileUrl = await dispatch(DownloadDataExcelReducer(data, null))
                                                if(fileUrl){
                                                    const link = document.createElement('a')
                                                    link.href = fileUrl
                                                    link.download = data.ardnombre
                                                    link.click()
                                                }
                                                setIsTabVisible(true)
                                            }else{
                                                setShowModal(true)
                                                setDataDownload(data)
                                            }
                                            setIsTabVisible(true)
                                        }}
                                    >
                                        <div className="Circle-Download">
                                            <CloudDownloadOutlined className="Icon-Circle-Download" />
                                            <span className="Text-Circle-Download">Descargar</span>
                                        </div>
                                    </div>
                                    <div
                                        onMouseOver={() => {
                                            setMouseOver(key_index + 5)
                                            setActiveBackground(true)
                                        }}
                                        onMouseLeave={() => {
                                            setMouseOver(null)
                                            setActiveBackground(false)
                                        }}
                                        className="Container-Text-Download" 
                                        style={{cursor: 'pointer'}}
                                        onClick={async () => {
                                            setIsTabVisible(false)
                                            const fileUrl = await dispatch(DownloadDataExcelReducer(data))
                                            if(fileUrl){
                                                const link = document.createElement('a')
                                                link.href = fileUrl
                                                link.download = data.ardnombre
                                                link.click()
                                            }
                                            setIsTabVisible(true)
                                        }}
                                    >
                                        <p 
                                            className={`Text-Download ${mouseOver == key_index + 5 && 'Text-Active-Download'}`}
                                        >
                                            {data.ardnombre}
                                        </p>
                                        {
                                            animationText == "top"
                                                ? key_index + 5 == containerPosition + 3 && <span></span>
                                                : key_index + 5 == containerPosition && <span></span>
                                        }
                                    </div>
                                </div>
                                )
                            })
                        }
                    </div>
                    <div 
                        className="Container-Card-Item-Download" 
                        style={{
                            transform: 
                            containerPosition == 2 
                                ? 'translateY(-70vh)' 
                                : containerPosition == 3 
                                    ? 'translateY(-94vh)'
                                    : containerPosition == 4 
                                        ? 'translateY(-111vh)'
                                        : containerPosition == 5
                                            ? 'translateY(-129vh)'
                                            : containerPosition == 6
                                                ? 'translateY(-144vh)'
                                                : containerPosition == 7
                                                    ? 'translateY(-2vh)'
                                                    : containerPosition == 8
                                                        ? 'translateY(-18vh)'
                                                        :  containerPosition == 9
                                                            ? 'translateY(-35vh)'
                                                            : containerPosition == 10
                                                                ? 'translateY(-48vh)'
                                                                : 'translateY(-75vh)',
                            transition: 'transform 1s, z-index 0s', 
                            paddingTop: '0', 
                            position: 'relative', 
                            height: '72vh', 
                            width: '600px',
                            backgroundColor: '#ffffff',
                            visibility: containerPosition == 6 || containerPosition == 7 ? 'hidden' : 'visible',
                            zIndex: containerPosition == 6 || containerPosition == 7 ? '-1' : '1',
                        }}
                    >
                        {
                            downloadData.map((data, index) => {
                                let key_index = index + 1
                                let styleTransform = 'translate(-250px, -5vh)'
                                if(containerPosition == 1){
                                    if(key_index == 1){
                                        styleTransform = 'translate(-150px, 12vh)'
                                    }else if(key_index == 2){
                                        styleTransform = 'translate(-100px, 25vh)'
                                    }else if(key_index == 3){
                                        styleTransform = 'translate(-100px, 39vh)'
                                    }else if(key_index == 4){
                                        styleTransform = 'translate(-150px, 51vh)'
                                    }else if(key_index == 5){
                                        styleTransform = 'translate(-250px, 67vh)'
                                    }
                                }else if(containerPosition == 2){
                                    if(key_index == 1){
                                        styleTransform = 'translate(-250px, -13vh)'
                                    }else if(key_index == 2){
                                        styleTransform = 'translate(-150px, 4vh)'
                                    }else if(key_index == 3){
                                        styleTransform = 'translate(-100px, 17vh)'
                                    }else if(key_index == 4){
                                        styleTransform = 'translate(-100px, 31vh)'
                                    }else if(key_index == 5){
                                        styleTransform = 'translate(-150px, 44vh)'
                                    }
                                }else if(containerPosition == 3){
                                    if(key_index == 1){
                                        styleTransform = 'translate(-250px, 2vh)'
                                    }else if(key_index == 2){
                                        styleTransform = 'translate(-250px, 10vh)'
                                    }else if(key_index == 3){
                                        styleTransform = 'translate(-150px, 25vh)'
                                    }else if(key_index == 4){
                                        styleTransform = 'translate(-100px, 38.5vh)'
                                    }else if(key_index == 5){
                                        styleTransform = 'translate(-100px, 53vh)'
                                    }
                                }else if(containerPosition == 4){
                                    if(key_index == 1){
                                        styleTransform = 'translate(-250px, 8vh)'
                                    }else if(key_index == 2){   
                                        styleTransform = 'translate(-250px, 12vh)'
                                    }else if(key_index == 3){
                                        styleTransform = 'translate(-250px, 24vh)'
                                    }else if(key_index == 4){
                                        styleTransform = 'translate(-150px, 39vh)'
                                    }else if(key_index == 5){
                                        styleTransform = 'translate(-100px, 52vh)'
                                    }
                                }else if(containerPosition == 5 || containerPosition == 6){
                                    if(key_index == 1){
                                        styleTransform = 'translate(-250px, 8vh)'
                                    }else if(key_index == 2){
                                        styleTransform = 'translate(-250px, 19vh)'
                                    }else if(key_index == 3){
                                        styleTransform = 'translate(-250px, 23vh)'
                                    }else if(key_index == 4){
                                        styleTransform = 'translate(-250px, 38vh)'
                                    }else if(key_index == 5){
                                        if(containerPosition == 5){
                                            styleTransform = 'translate(-150px, 55vh)'
                                        }else{
                                            styleTransform = 'translate(-250px, 50vh)'
                                        }
                                    }
                                }else if(containerPosition == 7){
                                    if(key_index == 1){
                                        styleTransform = 'translate(-250px, 6vh)'
                                    }else if(key_index == 2){
                                        styleTransform = 'translate(-250px, 19vh)'
                                    }else if(key_index == 3){
                                        styleTransform = 'translate(-250px, 32vh)'
                                    }else if(key_index == 4){
                                        styleTransform = 'translate(-250px, 43vh)'
                                    }else if(key_index == 5){
                                        styleTransform = 'translate(-250px, 56vh)'
                                    }
                                }else if(containerPosition == 8){
                                    if(key_index == 1){
                                        styleTransform = 'translate(-150px, 2vh)'
                                    }else if(key_index == 2){
                                        styleTransform = 'translate(-250px, 18vh)'
                                    }else if(key_index == 3){
                                        styleTransform = 'translate(-250px, 32vh)'
                                    }else if(key_index == 4){
                                        styleTransform = 'translate(-250px, 43vh)'
                                    }else if(key_index == 5){
                                        styleTransform = 'translate(-250px, 56vh)'
                                    }
                                }else if(containerPosition == 9){
                                    if(key_index == 1){
                                        styleTransform = 'translate(-100px, 5vh)'
                                    }else if(key_index == 2){
                                        styleTransform = 'translate(-150px, 18vh)'
                                    }else if(key_index == 3){
                                        styleTransform = 'translate(-250px, 33vh)'
                                    }else if(key_index == 4){
                                        styleTransform = 'translate(-250px, 43vh)'
                                    }else if(key_index == 5){
                                        styleTransform = 'translate(-250px, 56vh)'
                                    }
                                }else if(containerPosition == 10){
                                    if(key_index == 1){
                                        styleTransform = 'translate(-100px, 1.5vh)'
                                    }else if(key_index == 2){
                                        styleTransform = 'translate(-100px, 15.5vh)'
                                    }else if(key_index == 3){
                                        styleTransform = 'translate(-150px, 28vh)'
                                    }else if(key_index == 4){
                                        styleTransform = 'translate(-250px, 44vh)'
                                    }else if(key_index == 5){
                                        styleTransform = 'translate(-250px, 56vh)'
                                    }
                                }
                                return(
                                <div 
                                    className={`Container-Item-Download ${mouseOver == key_index && 'Container-Active-Item-Download'}`} 
                                    style={{
                                        transform: styleTransform,
                                        transition: 'transform 1s, z-index 0s',
                                        zIndex: containerPosition == 6 || containerPosition == 7 ? '-1' : '1',
                                    }}
                                    key={key_index}
                                >
                                    <div 
                                        className="Container-Circle-Download"
                                        onMouseOver={() => {
                                            setMouseOver(key_index)
                                            setActiveBackground(true)
                                        }}
                                        onMouseLeave={() => {
                                            setMouseOver(null)
                                            setActiveBackground(false)
                                        }}
                                        style={{cursor: 'pointer'}}
                                        onClick={async () => {
                                            setIsTabVisible(false)
                                            if(!data.ardfiltros){
                                                const fileUrl = await dispatch(DownloadDataExcelReducer(data))
                                                if(fileUrl){
                                                    const link = document.createElement('a')
                                                    link.href = fileUrl
                                                    link.download = data.ardnombre
                                                    link.click()
                                                }
                                                setIsTabVisible(true)
                                            }else{
                                                setShowModal(true)
                                                setDataDownload(data)
                                            }
                                            setIsTabVisible(true)
                                        }}
                                    >
                                        <div className="Circle-Download">
                                            <CloudDownloadOutlined className="Icon-Circle-Download" />
                                            <span className="Text-Circle-Download">Descargar</span>
                                        </div>
                                    </div>
                                    <div
                                        onMouseOver={() => {
                                            setMouseOver(key_index)
                                            setActiveBackground(true)
                                        }}
                                        onMouseLeave={() => {
                                            setMouseOver(null)
                                            setActiveBackground(false)
                                        }}
                                        className="Container-Text-Download" 
                                        style={{cursor: 'pointer'}}
                                        onClick={async () => {
                                            setIsTabVisible(false)
                                            const fileUrl = await dispatch(DownloadDataExcelReducer(data))
                                            if(fileUrl){
                                                const link = document.createElement('a')
                                                link.href = fileUrl
                                                link.download = data.ardnombre
                                                link.click()
                                            }
                                            setIsTabVisible(true)
                                        }}
                                    >
                                        <p className={`Text-Download ${mouseOver == key_index && 'Text-Active-Download'}`}>
                                            {data.ardnombre}
                                        </p>
                                        {
                                            animationText == "top"
                                                ? containerPosition == 8 && key_index == 1
                                                    ? <span></span>
                                                    : containerPosition == 9 && key_index == 2
                                                    ? <span></span>
                                                    : containerPosition == 10 && key_index == 3 
                                                    ? <span></span>
                                                    : containerPosition + 3 == key_index && <span></span>
                                                : key_index == containerPosition && <span></span>
                                        }
                                    </div>
                                </div>
                                )
                            })
                        }
                    </div>
                    </>
                    : <div 
                        className="Container-Card-Item-Download" 
                        style={{position: 'relative', height: 'calc(100% + 2vh)', width: '600px', backgroundColor: '#ffffff', top: '-2vh'}}
                    >
                        {
                            downloadData.map((data, index) => {
                                let key_index = index + 1
                                let styleTransform = 'translate(-30px, 80vh)'
                                let styleVisibility = 'hidden'
                                let styleZindex = '-1'
                                if(initialPosition == key_index){
                                    styleTransform = 'translate(40px, 11vh)'
                                    styleVisibility = 'visible'
                                    styleZindex = '1'
                                }else if(initialPosition+1 == key_index){
                                    styleTransform = 'translate(90px, 26vh)'
                                    styleVisibility = 'visible'
                                    styleZindex = '1'
                                }else if(initialPosition+2 == key_index){
                                    styleTransform = 'translate(90px, 43vh)'
                                    styleVisibility = 'visible'
                                    styleZindex = '1'
                                }else if(initialPosition+3 == key_index){
                                    styleTransform = 'translate(40px, 58vh)'
                                    styleVisibility = 'visible'
                                    styleZindex = '1'
                                }else if(initialPosition-1 == key_index){
                                    styleTransform = 'translate(-30px, -10vh)'
                                    styleVisibility = 'hidden'
                                    styleZindex = '-1'
                                }else if(initialPosition-2 == key_index){
                                    styleTransform = 'translate(-30px, 80vh)'
                                    styleVisibility = 'hidden'
                                    styleZindex = '-1'
                                }else if(initialPosition == downloadData.length - 2){
                                    if(key_index == 1){
                                        styleTransform = 'translate(40px, 58vh)'
                                        styleVisibility = 'visible'
                                        styleZindex = '1'
                                    }
                                }else if(initialPosition == downloadData.length - 1){
                                    if(key_index == 1){
                                        styleTransform = 'translate(90px, 43vh)'
                                        styleVisibility = 'visible'
                                        styleZindex = '1'
                                    }else if(key_index == 2){
                                        styleTransform = 'translate(40px, 58vh)'
                                        styleVisibility = 'visible'
                                        styleZindex = '1'
                                    }
                                }else if(initialPosition == downloadData.length){
                                    if(key_index == 1){
                                        styleTransform = 'translate(90px, 26vh)'
                                        styleVisibility = 'visible'
                                        styleZindex = '1'
                                    }else if(key_index == 2){
                                        styleTransform = 'translate(90px, 43vh)'
                                        styleVisibility = 'visible'
                                        styleZindex = '1'
                                    }else if(key_index == 3){
                                        styleTransform = 'translate(40px, 58vh)'
                                        styleVisibility = 'visible'
                                        styleZindex = '1'
                                    }
                                }else if(initialPosition == 1){
                                    if(key_index == downloadData.length){
                                        styleTransform = 'translate(-30px, -10vh)'
                                        styleVisibility = 'hidden'
                                        styleZindex = '-1'
                                    }
                                }
                                return(
                                <div 
                                    className={`Container-Item-Download ${mouseOver == key_index && 'Container-Active-Item-Download'}`} 
                                    style={{
                                        transition: 'transform 1s, visibility 0s, z-index .5s',
                                        position: 'absolute',
                                        transform: styleTransform,
                                        visibility: styleVisibility,
                                        zIndex: styleZindex,
                                    }}
                                    key={key_index}
                                >
                                    <div 
                                        className="Container-Circle-Download"
                                        onMouseOver={() => {
                                            setMouseOver(key_index)
                                            setActiveBackground(true)
                                        }}
                                        onMouseLeave={() => {
                                            setMouseOver(null)
                                            setActiveBackground(false)
                                        }}
                                        style={{cursor: 'pointer'}}
                                        onClick={async () => {
                                            setIsTabVisible(false)
                                            if(!data.ardfiltros){
                                                const fileUrl = await dispatch(DownloadDataExcelReducer(data))
                                                if(fileUrl){
                                                    const link = document.createElement('a')
                                                    link.href = fileUrl
                                                    link.download = data.ardnombre
                                                    link.click()
                                                }
                                                setIsTabVisible(true)
                                            }else{
                                                setShowModal(true)
                                                setDataDownload(data)
                                            }
                                            setIsTabVisible(true)
                                        }}
                                    >
                                        <div className="Circle-Download">
                                            <CloudDownloadOutlined className="Icon-Circle-Download" />
                                            <span className="Text-Circle-Download">Descargar</span>
                                        </div>
                                    </div>
                                    <div
                                        onMouseOver={() => {
                                            setMouseOver(key_index)
                                            setActiveBackground(true)
                                        }}
                                        onMouseLeave={() => {
                                            setMouseOver(null)
                                            setActiveBackground(false)
                                        }}
                                        className="Container-Text-Download" 
                                        style={{cursor: 'pointer'}}
                                        onClick={async () => {
                                            setIsTabVisible(false)
                                            if(!data.ardfiltros){
                                                const fileUrl = await dispatch(DownloadDataExcelReducer(data))
                                                if(fileUrl){
                                                    const link = document.createElement('a')
                                                    link.href = fileUrl
                                                    link.download = data.ardnombre
                                                    link.click()
                                                }
                                                setIsTabVisible(true)
                                            }else{
                                                setShowModal(true)
                                                setDataDownload(data)
                                            }
                                            setIsTabVisible(true)
                                        }}
                                    >
                                        <p className={`Text-Download ${mouseOver == key_index && 'Text-Active-Download'}`}>
                                            {data.ardnombre}
                                        </p>
                                        {
                                            animationText == "top"
                                                ? downloadData.length < initialPosition + 3 
                                                    ? key_index == ((initialPosition + 3) - downloadData.length) && <span></span>
                                                    : key_index == initialPosition + 3 && <span></span>
                                                : key_index == initialPosition && <span></span>
                                        }
                                    </div>
                                </div>
                                )
                            })
                        }
                    </div>
                }
                </div>
                : null
            }

            <Button 
                className="Button-Scroll"
                style={{
                    transform: `translate(52vw, 63vh)`,
                    display: downloadData.length <= 4 && 'none',
                    pointerEvents: activeButton == false && 'none',
                }}
                onClick={() => downButtonCarrousel()}
            >
                ver más <ArrowDownOutlined />
            </Button>
            <ModalDownloadData 
                showModal={showModal}
                dataDownload={dataDownload}

                setShowModal={setShowModal}
            />
        </div>
    )
}

export default CardDownload