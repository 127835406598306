import React, { useState, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { SendFileUploadFileReducer } from '../../Redux/Actions/FileUpload/FileUpload'
import notificationGlobal from '../Global/NotificationGlobal'
import { Card, Tag, Upload, DatePicker, Typography } from 'antd'
import { PlusOutlined } from '@ant-design/icons'
import Cargando from '../../Assets/Images/FileUpload/Cargando.gif'
import DescargaExitosa from '../../Assets/Images/FileUpload/Descarga exitosa.gif'
import PreCarga from '../../Assets/Images/FileUpload/Pre-carga.gif'
import Error from '../../Assets/Images/FileUpload/error.gif'
import '../../Styles/Components/FileUpload/CardFile.css'

const CardFile = ({title, subtitle, url, disabled, area, template}) => {
    
    const { Meta } = Card
    const { Text } = Typography

    const dispatch = useDispatch()

    const [ sendDataFile, setSendDataFile ] = useState(false)
    const [ errorFile, setErrorFile ] = useState(false)
    const [ showDatePicker, setShowDatePicker ] = useState(false)
    const [ dateDatePicker, setDateDatePicker ] = useState(null)

    const [ sendLoading, setLoading] = useState(false)

    const RefFile = useRef(null)
    
    const [ uploadFile, setUploadFile ] = useState({
        url         : url,
        titleFile   : title,
        dataFile    : null,
        typeFile    : null,
        nameFile    : null,
        sending     : false
    })

    const props_upload = {
        onRemove: (file) => {
          setUploadFile({...uploadFile, dataFile : null, nameFile: null, typeFile: null, date : null})
        },
        beforeUpload: (file) => {
            setUploadFile({...uploadFile, dataFile : file, nameFile: file.name, typeFile: file.type, date : dateDatePicker})
            return false;
        },
    }

    const UploadFile = async () => {

        if((!uploadFile.dataFile && !errorFile && !sendLoading)){
            notificationGlobal('warning','Por favor cargue algún archivo')
            return
        }
        
        if(!errorFile){
            setSendDataFile(true)        
            let updateFileUpdate = {...uploadFile, sending: false}
            setUploadFile({...uploadFile, sending: true, cararea : area})
            const response_upload = await dispatch(SendFileUploadFileReducer(uploadFile, area))
            if(response_upload){
                setLoading(true)
                setErrorFile(false)
                updateFileUpdate = {...uploadFile, nameFile: null, typeFile: null, dataFile: null}
            }else{
                setLoading(true)
                setErrorFile(true)
            }
            setUploadFile({...updateFileUpdate, sending: false})
            
        }else{
            setSendDataFile(false)
            setErrorFile(false)
            setUploadFile({...uploadFile,
                dataFile    : null,
                typeFile    : null,
                nameFile    : null,
                sending     : false})
        }

    }
    
    return(
        <>
        {
            disabled && <div className="Disabled-Card-File"></div>
        }
        <Card
            className={`Card-File ${uploadFile.sending && "Sending-File"}`}
            cover={
                sendDataFile ?
                    uploadFile.sending
                    ? <div style={{textAlign: 'center'}}>
                        <img src={Cargando} className="Image-Card-File" />
                    </div> 
                    :
                        errorFile
                        ? <img src={Error} className="Image-Card-File" />
                        : <img src={sendLoading ? DescargaExitosa : PreCarga} className="Image-Card-File" />

                :   <img
                        className="Image-Card-File"
                        src={PreCarga}
                    />
            }
        >
            {
                !uploadFile.sending
                ? <>
                    {
                        errorFile
                        ? <>
                            <div className="Container-Message-Error">
                                <div className="Title-Error">!Algo salió mal!</div>
                                <div className="Text-Error">Tu archivo no se subió porque encontramos un error, por favor revisar tus notificaciones</div>
                            </div>
                            </>
                        : 
                        
                        sendLoading ? 
                            <div className={`Container-SendFile-Success`}>
                                ¡Archivo subido con éxito!
                            </div>
                        : <>
                            <Meta
                                avatar={
                                    title == "Clientes Ejecutivos" || title == "Master de Clientes" || title == "Master de Producto"
                                    ?  
                                    <>
                                        <div style={{display: 'inline-block'}}>
                                            <div style={{display: 'inline-block'}}>
                                                <div 
                                                    onClick={() => {
                                                        setShowDatePicker(!showDatePicker)
                                                    }} 
                                                    className="Container-Icon-Card-File"
                                                >
                                                    <PlusOutlined className="Image-Icon-Card-File" />
                                                </div>
                                            </div>
                                            <DatePicker
                                                className="Date-Card"
                                                onChange={(_, date) => {
                                                    setDateDatePicker(date)
                                                    RefFile.current.click()
                                                }}
                                                picker="month" 
                                                allowClear={false}
                                                open={showDatePicker} 
                                                popupClassName='DatePicker-File-Upload'
                                                bordered={false}
                                            />
                                            <input type="file" ref={RefFile} style={{display: 'none'}} 
                                                onChange={(e) => {
                                                    setShowDatePicker(false)
                                                    setUploadFile({...uploadFile, 
                                                        dataFile : e.target.files[0], 
                                                        nameFile: e.target.files[0].name, 
                                                        typeFile: e.target.files[0].type, 
                                                        date : dateDatePicker
                                                    })
                                                }} 
                                                className="Container-Icon-Card-File"
                                            />
                                        </div>
                                    </>
                                    : <Upload maxCount={1} {...props_upload}>
                                        <div  
                                            className="Container-Icon-Card-File"
                                        >
                                            <PlusOutlined 
                                                className="Image-Icon-Card-File" 
                                            />
                                        </div>
                                    </Upload>
                                }
                                title={title}
                                description={subtitle}
                            />
                            {
                                template
                                ? <a  href={template} className="Link-Template-File-Upload">Click aquí para descargar Plantilla</a>
                                : null
                            }
                            
                            <div className={`Container-Tag-Card-File`}>
                                {
                                    uploadFile.nameFile && <span className="Linear-Tag-Card-File"></span>
                                }
                                
                                {
                                    uploadFile.nameFile
                                        &&  <Tag 
                                                className={`Tag-Card-File`}
                                                closable
                                                onClose={() =>setUploadFile({...uploadFile, dataFile: null, nameFile: null, typeFile : null})}
                                            >
                                                <Text
                                                    title={uploadFile.nameFile}
                                                    style={{width:'100%'}}
                                                >
                                                    {uploadFile.nameFile}
                                                </Text>
                                            </Tag>
                                }
                            </div>
                          </>
                    }
                    <div style={{display:"flex", justifyContent:"center"}}>
                        <button 
                            onClick={() => {
                                setLoading(false)
                                if(!uploadFile.dataFile && (errorFile || sendLoading)){
                                    return
                                }
                                UploadFile()
                            }} className={`Button-Card-File ${(errorFile || sendLoading) && "Button-Error"} ${(errorFile || sendLoading) && "Send-Another"}`}
                        >
                            {
                                errorFile || sendLoading
                                    ? <>
                                        <span className="Text-Span">Subir otro</span>
                                        <span className="Text-Span-Card-File">Subir otro</span>
                                        <div className="Circle-Button-Card-File"></div>
                                    </>
                                    : <>
                                        <span className="Text-Span">Enviar</span>
                                        <span className="Text-Span-Card-File">Enviar</span>
                                        <div className="Circle-Button-Card-File"></div>
                                    </>
                            }
                        </button>
                    </div>
                  </>
                : <div style={{marginTop:'5px', marginBottom: '43px'}}>
                    <div className="Title-Loading-Card-File">Cargando...</div>
                    <div className="Text-Loading-Card-File">Cargando 1 archivo</div>
                  </div>

            }

        </Card>
        </>
    )
}

export default CardFile