import React from 'react'
import { useDispatch } from 'react-redux'
import { Switch } from 'antd'
import { TermsConditionsReducer } from '../../Redux/Actions/TermsConditions/TermsConditions'

const ContentTermsConditions = ({title, switchValue, activeTermsConditions, type}) => {
    const dispatch = useDispatch()
    return(
        <div className={`Container-Content-Terms-Conditions ${title == activeTermsConditions ? 'Active-Content-Terms-Conditions' : ''}`}>
            <p className={`Text-Content-Terms-Conditions ${title == activeTermsConditions ? 'Active-Content-Text-Terms-Conditions' : ''}`}>{title}</p>
            <Switch 
                className={`Switch-Content-Terms-Conditions ${title == activeTermsConditions ? 'Active-Content-Switch-Terms-Conditions' : ''}`}
                size="small"
                onChange={(e) => dispatch(TermsConditionsReducer(e, type))}
                checked={switchValue}
            />
        </div>
    )
}

export default ContentTermsConditions