import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { Modal, Typography, Row, Col } from 'antd'
import { ShowNotificationCreatedUserReducer } from '../../Redux/Actions/Users/Users'
import { NOTIFICATION_CREATED_USER_MSG } from '../../Constants/Users/Users'
import '../../Styles/Components/CreateUser/NotificationCreated.css'

const NotificationCreated = () => {

    const { Title } = Typography
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const handleCancel = () => {
        dispatch(ShowNotificationCreatedUserReducer(false))
        dispatch({
            type : NOTIFICATION_CREATED_USER_MSG,
            payload : {}
        })
        if(rex_notification_created_user_msg.response){
            navigate('/administrator/users')
        }
    }

    const {
        rex_notification_created_user,
        rex_notification_created_user_msg
    } = useSelector(({users}) => users)

    return (
        <Modal 
            title={null}
            centered={true}
            open={rex_notification_created_user}
            closable={false}
            width={264}
            footer={null}
            zIndex={100000000}
            className='Modal-Notification-User-Created'
        >
            <Row 
                className={`${rex_notification_created_user.response ?"Modal-Container-Text-User-Created" :"Modal-Container-Text-User-Created-Alert"}`}
            >
                <Col span={24} 
                    className='Modal-Container-Text-Main'
                >
                    <Title>
                        {rex_notification_created_user_msg.response ? '¡Listo!' : 'Alerta!'}
                    </Title>
                </Col>
                <Col span={24} className='Modal-Container-Text-Description'>
                    <p>{rex_notification_created_user_msg.message}</p>
                </Col>
                <Col span={24}>
                    <div
                        onClick={() => handleCancel()}
                        className={`Notification-Button-User ${rex_notification_created_user.response ?"Notification-Button-User-Created" :"Notification-Button-User-Created-Alert"}`}
                    >
                        Ok
                    </div>
                </Col>
            </Row>
        </Modal>
    )
}

export default NotificationCreated