import {
    GET_DATA_FILE_CONTROL, GET_DATA_FILE_CONTROL_CLONE, GET_DATA_TYPES_FILE_CONTROL, LOADING_FILE_CONTROL
} from "../../../Constants/FileControl/FileControl"
import config from  '../../../config'
import dayjs from 'dayjs';

export const GetDataFileControlReducer = () => async ( dispatch, getState ) => {

    dispatch({
        type    : LOADING_FILE_CONTROL,
        payload : true
    })

    await fetch(config.api+'file-control/all',
        {
            mode:'cors',
            method: 'POST',
            headers: {
                'Accept' 	   : 'application/json',
                'Content-type' : 'application/json',
                'usutoken'      : localStorage.getItem('usutoken')
            },
        }
    )
    .then( async res => {
        return res.json()
    })
    .then(data => {
        if(data.response){
            dispatch({
                type: GET_DATA_FILE_CONTROL,
                payload : data.data
            })
            dispatch({
                type: GET_DATA_FILE_CONTROL_CLONE,
                payload : data.data
            })
            dispatch({
                type: GET_DATA_TYPES_FILE_CONTROL,
                payload : data.filters
            })
        }
        dispatch({
            type    : LOADING_FILE_CONTROL,
            payload : false
        })    
    }).catch((error)=> {
        console.log(error)
    });
}

export const DownloadFileControlReducer = (id) => async ( dispatch, getState ) => {

    let url
    await fetch(config.api+'files/download-file',
        {
            mode:'cors',
            method: 'POST',
            headers: {
                'Accept' 	   : 'application/json',
                'Content-type' : 'application/json',
                'usutoken'      : localStorage.getItem('usutoken')
            },
            body: JSON.stringify({
                req_id      : id,
            }),
        }
    )
    .then( async res => {
        return res.json()
    })
    .then(data => {
        if(data.response){
            url = data.data
        }
    }).catch((error)=> {
        console.log(error)
    });

    return url
}

export const DownloadDataFileControlReducer = () => async ( dispatch, getState ) => {

    let url = false

    await fetch(config.api+'file-control/download-data',
        {
            mode:'cors',
            method: 'POST',
            headers: {
                'Accept' 	   : 'application/json',
                'Content-type' : 'application/json',
                'usutoken'      : localStorage.getItem('usutoken')
            },
        }
    )
    .then( async res => {
        return res.json()
    })
    .then(data => {
        url = data.data
    }).catch((error)=> {
        console.log(error)
    });

    return url
}


export const DeleteDataFileControlReducer = (ids) => async ( dispatch, getState ) => {

    let response = false

    await fetch(config.api+'file-control/delete-file',
        {
            mode:'cors',
            method: 'POST',
            headers: {
                'Accept' 	   : 'application/json',
                'Content-type' : 'application/json',
                'usutoken'      : localStorage.getItem('usutoken')
            },
            body: JSON.stringify({
                req_id      : ids,
            }),
        }
    )
    .then( async res => {
        return res.json()
    })
    .then(data => {
        if(data.response){
            response = true
            dispatch(GetDataFileControlReducer())
        }
    }).catch((error)=> {
        console.log(error)
    });

    return response
}

export const FilterFileControlReducer = ( values, type ) => async ( dispatch, getState ) => {

    let dataFileControl = getState().fileControl.rex_data_file_control

    const dataFilter = dataFileControl.filter(dat => {
        return values.length > 0 ? values.includes(dat.paipaises.paiid) : true
    })

    dispatch({
        type : GET_DATA_FILE_CONTROL_CLONE,
        payload : dataFilter
    })

}

export const FilterFileDateControlReducer = ( data, value, type ) => async ( dispatch, getState ) => {

    let dataFileControl = [...data]

    let dataFilter = dataFileControl.filter(fil => {
        let dateFile = dayjs(fil.created_at)
        return value ? (
            dateFile.year() == value.year()
            && dateFile.month() == value.month()
            && dateFile.date() == value.date()
        ) : true
    })

    dispatch({
        type : GET_DATA_FILE_CONTROL_CLONE,
        payload : dataFilter
    })
}