import React, { useEffect, useState } from 'react'
import { Tooltip } from 'antd'
import TitleSearchGlobal from '../../Global/TitleSearchGlobal'
import { useDispatch, useSelector } from 'react-redux'
import { FilterMslManageReducer, GetMslManageReducer } from '../../../Redux/Actions/Manage/MSL/MSL'
import FormCreateMSL from './FormCreateMSL'
import { GetMasterProductsManageReducer, GetMasterProductsManualsManageReducer, GetFiltersMasterProductsManageReducer } from '../../../Redux/Actions/Manage/MasterProducts/MasterProducts'
import "../../../Styles/Routes/MSL/MSL.css"
import ModalMessageAlert from './ModalMessageAlert'
import ViewMSL from './ViewMSL'
import ViewProducts from './ViewProducts'
import dayjs from 'dayjs';
import ViewNewProducts from './ViewNewProducts'
import SideMenu from '../../Global/SideMenu'
import moment from 'moment'

const MSL = () => {

    const [ dateFilter, setDateFilter ] = useState('')
    const [ channelFilterMSL, setChannelFilterMSL ] = useState([])
    const [ channelFilter, setChannelFilter ] = useState([])
    const [ idsSelectedMsl, setIdsSelectedMsl ] = useState([])
    const [ openModal, setOpenModal ] = useState('')
    const [ viewSelected, setViewSelected ] = useState('products')
    const [ typeActionMsl, setTypeActionMsl ] = useState('')
    const [ dataMsl, setDataMsl ] = useState({})
    const [ listSelectedMSL ,setListSelectedMSL ] = useState([])
    const [ listSelected ,setListSelected ] = useState([])
    const [ dateMsl, setDateMSL ] = useState(dayjs(dayjs(), "AAAA-MM"))
    const [ dateNewProducts, setDateNewProducts ] = useState(dayjs(dayjs(), "AAAA-MM"))
    const [ dateProducts, setDateProducts ] = useState(dayjs(dayjs(), "AAAA-MM"))
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(GetMslManageReducer())
        dispatch(GetMasterProductsManageReducer())
        dispatch(GetMasterProductsManualsManageReducer())
        dispatch(GetFiltersMasterProductsManageReducer())
    }, [])
    
    const {
        rex_data_msl_manage,
        rex_data_msl_manage_current_source,
    } = useSelector(({msl}) => msl)

    const { 
        rex_data_master_products_manage,
        rex_data_master_products_manuals_manage,
        rex_date_updated_product_manage
    } = useSelector(({masterProducts}) => masterProducts)

    const { 
        rex_users_conected_dashboard,
    } = useSelector(({registerUsers}) => registerUsers)

    const { 
        rex_data_users_online
    } = useSelector(({users}) => users)

    const buttonChangeView = () => (
        <div className="Container-Actions-Show-MSL">
            <Tooltip
                placement='bottom'
                title="Todo"
                color='#3B72FF'
            >
                <div 
                    onClick={() => setViewSelected('products')} 
                    className={`Button-Show-Products ${viewSelected == "products" && "Cursor-Blocked"}`}
                >
                    <div className={`Content-Button-Show-Products ${viewSelected == "products" && "Selected"}`}></div>
                </div>
            </Tooltip>
            <Tooltip
                placement='bottom'
                title="Foco"
                color='#3B72FF'      
            >
                <div
                    onClick={() => setViewSelected('msl')}
                    className={`Button-Show-MSL ${viewSelected == "msl" && "Cursor-Blocked"}`}
                >
                    <div className={`Content-Button-Show-MSL ${viewSelected == "msl" && "Selected"}`}></div>
                </div>
            </Tooltip>
            <Tooltip
                placement='bottom'
                title="Nuevos"
                color='#3B72FF'
            >
                <div
                    onClick={() => setViewSelected('new-products')}
                    className={`Button-Show-Manuals-Products ${viewSelected == "new-products" && "Cursor-Blocked"}`}
                >
                    <div className={`Content-Button-Show-Manuals-Products ${viewSelected == "new-products" && "Selected"}`}></div>
                </div>
            </Tooltip>
        </div>
    )

    return (
        <div className='Container-Main' style={{paddingBottom: '20px'}}>
            <TitleSearchGlobal
                title={'Maestra de Productos'}
                subTitle={
                    rex_date_updated_product_manage.updated_at 
                    ? moment(rex_date_updated_product_manage.updated_at).format('D [de] MMMM [del] YYYY h:mm a')
                    : ''
                }
                showInputSearch={false}
                funtionFilter={(valueSelected, type) => {
                    dispatch(FilterMslManageReducer(dateFilter, valueSelected, channelFilter))
                }}
            />
            <div className="Container-MSL">
                {
                    viewSelected == "msl" &&
                        <ViewMSL
                            dateMsl={dateMsl}
                            dateNewProducts={dateNewProducts}
                            dateProducts={dateProducts}
                            setDateMSL={setDateMSL}
                            data={rex_data_msl_manage}
                            currentData={rex_data_msl_manage_current_source}
                            componentExtraLeftPagination={buttonChangeView()}
                            channelFilterMSL={channelFilterMSL}
                            functionEditSelectedRows={(values, resetValues) => {
                                if(values.length > 0){
                                    setOpenModal(!openModal)
                                    setIdsSelectedMsl(values)
                                    setTypeActionMsl('edit')
                                }
                            }}
                            setChannelFilterMSL={setChannelFilterMSL}
                            listSelectedMSL={listSelectedMSL}
                            setListSelectedMSL={setListSelectedMSL}
                        />
                }

                {
                    viewSelected == "products" &&
                        <ViewProducts
                            dateMsl={dateMsl}
                            dateNewProducts={dateNewProducts}
                            dateProducts={dateProducts}
                            setDateMSL={setDateProducts}
                            data={rex_data_master_products_manage}
                            componentExtraLeftPagination={buttonChangeView()}
                            channelFilter={channelFilter}
                            setChannelFilter={setChannelFilter}
                            listSelected={listSelected}
                            setListSelected={setListSelected}
                        />
                }
                {
                    viewSelected == "new-products" &&
                    <ViewNewProducts
                        dateMsl={dateMsl}
                        dateProducts={dateProducts}
                        dateFilter={dateNewProducts}
                        data={rex_data_master_products_manuals_manage}
                        setDateFilter={setDateNewProducts}
                        componentExtraLeftPagination={buttonChangeView()}
                    />
                }

                <FormCreateMSL
                    setOpenModal={setOpenModal}
                    openModal={openModal}
                    idsSelectedMsl={idsSelectedMsl}
                    typeActionMsl={typeActionMsl}
                    dataMsl={dataMsl}
                    setDataMsl={setDataMsl}
                />
                <ModalMessageAlert
                    view={viewSelected}
                    dateProducts={dateProducts}
                    dateMsl={dateMsl}
                    dateNewProducts={dateNewProducts}
                />

            </div>

            <SideMenu
                usersCurrentView  = {rex_users_conected_dashboard}
                usersAllConnected ={rex_data_users_online}
            />
        </div>
    )
}

export default MSL