import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams, useNavigate } from 'react-router-dom'
import { AddSubModuleReducer, ClearDataModulesReducer, CreateModuleSubModuleSubSubModuleReducer, GetDataModuleReducer } from '../../Redux/Actions/Modules/CreateEditModules'
import { Row, Col, Typography, Button, Spin } from 'antd'
import { PlusCircleOutlined, LoadingOutlined } from '@ant-design/icons'
import TitleModules from '../../Components/Modules/TitleModules'
import FormNewModules from '../../Components/Modules/SubModules/FormNewModules'
import FormNewSubModules from '../../Components/Modules/SubModules/FormNewSubmodules'
import ListSubModules from '../../Components/Modules/SubModules/ListSubModules'
import SideMenu from '../../Components/Global/SideMenu'
import NavigationGlobal from '../../Components/Global/NavigationGlobal'

const NewModules = () => {
    const { Title } = Typography
    const dispatch = useDispatch()
    const { id } = useParams()
    const navigate = useNavigate()

    const { 
        rex_get_module_data_clone,
        rex_loading_save_data,
    } = useSelector(({createEditModules}) => createEditModules)

    const { 
        rex_users_conected_dashboard,
    } = useSelector(({registerUsers}) => registerUsers)

    const { 
        rex_data_users_online
    } = useSelector(({users}) => users)

    useEffect(() => {
        dispatch(GetDataModuleReducer(id))
        dispatch(ClearDataModulesReducer())
    }, [])

    const antIcon = (
        <LoadingOutlined
            style={{
                fontSize: '14px',
                marginLeft: '10px',
                color: '#fff'
            }}
            spin
        />
    )

    const menuProfileTypes = [
        {
            url: '/home-administrator',
            nombre: 'Administrador',
        },
        {
            url: '/administrator/modules',
            nombre: 'Módulos',
        },
        {
            url: id ? `/administrator/modules/edit-modules/${id}` : '/administrator/modules/create-modules',
            nombre: id ? 'Editar Módulo Home' : 'Nuevo Módulo Home',
        },
    ]

    return(
        <div className="Container-Main Container-New-Modules">
            <Row>
                <Col span={24}>
                    <NavigationGlobal menuItems={menuProfileTypes}/>
                </Col>
                <Col span={24}>
                    <TitleModules 
                        titleModule={id ? "Editar Módulo" : "Nuevo Módulo"}
                        textModule={id ? "Aquí podrás editar un módulo más sus submódulos si se requiere" : "Aquí podrás crear módulos más sus submódulos si se requiere"}
                    />
                </Col>
                <Col span={24}>
                    {
                        Object.keys(rex_get_module_data_clone).length > 0 &&
                        <FormNewModules rex_get_module_data_clone={rex_get_module_data_clone} />
                    }
                </Col>
                <Col span={18} style={{marginBottom: '8px', display: 'flex', alignItems: 'end'}}>
                    {
                        Object.keys(rex_get_module_data_clone).length > 0 &&
                        rex_get_module_data_clone.smosubmodulos.length > 0 && 
                        rex_get_module_data_clone.smosubmodulos.find(smo =>  smo.smosubmodulosnew == false) &&
                        <Title className="Title-Submodules-Modules">
                            Submódulos
                        </Title>
                    }
                </Col>
                <Col span={6} style={{marginBottom: '8px'}}>
                    <Button 
                        className="Button-New-Sub-Modules"
                        type="primary"
                        onClick={() => dispatch(AddSubModuleReducer())}
                    >
                        <PlusCircleOutlined className="Icon-Button-New-Sub-Modules" /> Añadir Submódulo
                    </Button>
                </Col>
                {
                    Object.keys(rex_get_module_data_clone).length > 0 &&
                    rex_get_module_data_clone.smosubmodulos.length > 0 &&  
                    <ListSubModules 
                        submodulos={rex_get_module_data_clone.smosubmodulos}
                    />
                }
                {
                    Object.keys(rex_get_module_data_clone).length > 0 &&
                    rex_get_module_data_clone.smosubmodulos.length > 0 &&  
                    rex_get_module_data_clone.smosubmodulos.map((sub, index) =>
                        sub.smosubmodulosnew
                        ?<Col span={24} style={{marginBottom: '4px'}} key={index}>
                            <FormNewSubModules value={sub} />
                        </Col>
                        :null
                    )
                }
                <Col span={24} className="Col-Button-Modules">
                    <button 
                        onClick={() => navigate('/administrator/modules')}
                        className="Button-Cancel-Modules"
                    >
                        <span className="Text-Cancel">Cancelar</span>
                        <span className="Text-Cancel-Effect">Cancelar</span>
                    </button>
                    <button 
                        onClick={async () => {
                            const response = await dispatch(CreateModuleSubModuleSubSubModuleReducer(id))
                            if(response){
                                navigate('/administrator/modules')
                            }
                        }} 
                        className="Button-New-Modules"
                    >
                        <span className="Text-New">Guardar</span>
                        <span className="Text-New-Effect">Guardar</span>
                        {
                            rex_loading_save_data && <Spin className="Spin-Loading" indicator={antIcon} />
                        }
                    </button>
                </Col>
            </Row>

            <SideMenu
                usersCurrentView  = {rex_users_conected_dashboard}
                usersAllConnected ={rex_data_users_online}
            />
        </div>
    )
}

export default NewModules