import { 
    GET_DATA_FILES_UPLOADED_FILES,
    GET_DATA_FILES_UPLOADED_FILES_CLONE,
    GET_DATA_FILTERS_FILES_UPLOADED_FILES
} from "../../../Constants/UploadedFiles/UploadedFiles";

const INIT_STATE = {
    rex_get_data_filters_files_uploaded_files   : [],
    rex_get_data_files_uploaded_files           : [],
    rex_get_data_files_uploaded_files_clone     : [],
};

export default (state = INIT_STATE, action) => {
    
    switch (action.type) {
        case GET_DATA_FILTERS_FILES_UPLOADED_FILES: {
            return {
                ...state,
                rex_get_data_filters_files_uploaded_files : action.payload,
            }
        }
        case GET_DATA_FILES_UPLOADED_FILES_CLONE: {
            return {
                ...state,
                rex_get_data_files_uploaded_files_clone : action.payload,
            }
        }
        case GET_DATA_FILES_UPLOADED_FILES: {
            return {
                ...state,
                rex_get_data_files_uploaded_files : action.payload,
            }
        }
        default:
            return state;
    }
}