import React from 'react'
import inteligenciaartificial from '../../Assets/Images/Home/banner_2023_home_2.mp4'

const IaPlus = () => {
	return (
		<>
			<div
				className="Container-Main Container-Home" style={{height: '100vh'}}
			>
				<video style={{position: 'absolute', zIndex: -1, left: 0, width: '100%', height: '100vh', top: '0', objectFit: 'cover'}} src={inteligenciaartificial} autoPlay loop controls={false} muted></video>
			</div>
			<div className='IA-Plus-Gia' style={{padding:'10px', zIndex:'3'}}>
				<iframe
					src="https://chatbot-gia-grow.streamlit.app?embed=true"
					width="100%"
					height="800px"
					style={{ border: 'none' }}
					title="IA PLUS"
				/>
			</div>
			<div style={{
				position:'absolute',
				zIndex:'3',
				bottom:'0',
				width:'100%',
				height:'85px',
				backgroundColor:'#FFFFFF'
			}}></div>
		</>
	)
}

export default IaPlus