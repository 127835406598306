import React from 'react'
import { Link } from 'react-router-dom'

const Footer = () => {
    return (
        <div className="Container-Footer">
            <div className="Item-Container-Footer">
                <p className="Text-Footer">© 2023 All rights reserved Grow Analytics</p>
                <Link to="/terms-conditions">
                    <p className="Link-Footer">Condiciones de privacidad</p>
                </Link>
            </div>
        </div>
    )
}

export default Footer