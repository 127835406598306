import {
    OBTENER_ARCHIVOS_DOWNLOAD_DATA,
    REGISTER_USERS_CONNECTED_MODULES,
    DATA_DOWNLOAD_SELECTED,
    FILTERS_DOWNLOAD_DATA
} from "../../../Constants/DownloadData/DownloadData"


const INIT_STATE = {
    rex_archivos_download_data          : [],
    rex_users_download_data_connected   : [],
    rex_data_download_selected          : {},
    rex_filters_download_data           : [
        {
            name    : "Fecha",
            show    : false,
            data    : [
                {
                    show    : false,
                    name    : "Año",
                    value   : null,
                    filterName : "req_anio",
                    label   : "",
                    items   : [
                        {
                            label: "2021",
                            value: 2021,
                        },
                        {   
                            label: "2022",
                            value: 2022,
                        },
                        {   
                            label: "2023",
                            value: 2023,
                        },
                    ]
                },
                {
                    show    : false,
                    name    : "Mes",
                    value   : null,
                    filterName : "req_mes",
                    label   : "",
                    items   : [
                        {
                            label   : "Enero",
                            value   : 1
                        },
                        {
                            label   : "Febrero",
                            value   : 2
                        },
                        {
                            label   : "Marzo",
                            value   : 3
                        },
                        {
                            label   : "Abril",
                            value   : 4
                        },
                        {
                            label   : "Mayo",
                            value   : 5
                        },
                        {
                            label   : "Junio",
                            value   : 6
                        },
                        {
                            label   : "Julio",
                            value   : 7
                        },
                        {
                            label   : "Agosto",
                            value   : 8
                        },
                        {
                            label   : "Setiembre",
                            value   : 9
                        },
                        {
                            label   : "Octubre",
                            value   : 10
                        },
                        {
                            label   : "Noviembre",
                            value   : 11
                        },
                        {
                            label   : "Diciembre",
                            value   : 12
                        },
                    ]
                },
            ]
        },
        // {
        //     name    : "Columnas",
        //     show    : false,
        //     data    : [
        //         {
        //             show    : false,
        //             name    : "Sold To",
        //             value   : false
        //         },
        //         {
        //             show    : false,
        //             name    : "Materiales",
        //             value   : false
        //         },
        //     ]
        // }
    ],
    rex_filters_download_data_copy      : [
        {
            name    : "Fecha",
            show    : false,
            data    : [
                {
                    show    : false,
                    name    : "Año",
                    value   : null,
                    filterName : "req_anio",
                    label   : "",
                    items   : [
                        {
                            label: "2021",
                            value: 2021,
                        },
                        {   
                            label: "2022",
                            value: 2022,
                        },
                        {   
                            label: "2023",
                            value: 2023,
                        },
                    ]
                },
                {
                    show    : false,
                    name    : "Mes",
                    value   : null,
                    filterName : "req_mes",
                    label   : "",
                    items   : [
                        {
                            label   : "Enero",
                            value   : 1
                        },
                        {
                            label   : "Febrero",
                            value   : 2
                        },
                        {
                            label   : "Marzo",
                            value   : 3
                        },
                        {
                            label   : "Abril",
                            value   : 4
                        },
                        {
                            label   : "Mayo",
                            value   : 5
                        },
                        {
                            label   : "Junio",
                            value   : 6
                        },
                        {
                            label   : "Julio",
                            value   : 7
                        },
                        {
                            label   : "Agosto",
                            value   : 8
                        },
                        {
                            label   : "Setiembre",
                            value   : 9
                        },
                        {
                            label   : "Octubre",
                            value   : 10
                        },
                        {
                            label   : "Noviembre",
                            value   : 11
                        },
                        {
                            label   : "Diciembre",
                            value   : 12
                        },
                    ]
                },
            ]
        },
    ]
};


export default (state = INIT_STATE, action) => {
    switch (action.type) {

        case FILTERS_DOWNLOAD_DATA: {
            return {
                ...state,
                rex_filters_download_data : action.payload,
            }
        }
        case OBTENER_ARCHIVOS_DOWNLOAD_DATA: {
            return {
                ...state,
                rex_archivos_download_data : action.payload,
            }
        }
        case REGISTER_USERS_CONNECTED_MODULES: {
            return {
                ...state,
                rex_users_download_data_connected : action.payload,
            }
        }
        case DATA_DOWNLOAD_SELECTED: {
            return {
                ...state,
                rex_data_download_selected : action.payload,
            }
        }
        default:
            return state;
    }
}


