import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, Form, Input, Card, Tooltip, Modal } from 'antd'
import { MinusOutlined, PlusOutlined } from '@ant-design/icons'
import { ChangeExpandibleSubSubModuleReducer, RemoveSubSubModuleReducer, StoreSubSubModuleReducer } from '../../../Redux/Actions/Modules/CreateEditModules'
import { DeleteDataSubSubModuleReducer } from '../../../Redux/Actions/Modules/Modules'

const FormNewSubSubModules = (props) => {
    const values = props.valuessubsub
    const submodulekey = props.submodulekey
    const { confirm } = Modal

    const dispatch = useDispatch()

    const DeleteSubSubModule = (keySub, key) => {
        confirm({
            title: 'Confirmar',
            zIndex: 100000000,
            icon: <></>,
            content: '¿Estás seguro de eliminar el sub submódulo?',
            width: 260,
            centered: true,
            className: 'Modal-Delete',
            okButtonProps: {className: 'Confirm-Modal-Delete'},
            okText: <>
                        <span className="First-Confirm">Confirmar</span>
                        <span className="Last-Confirm">Confirmar</span>
                    </>,
            cancelButtonProps: {className: 'Cancel-Modal-Delete'},
            cancelText: <>
                            <span className="First-Cancel">Cancelar</span>
                            <span className="Last-Cancel">Cancelar</span>
                        </>,
            async onOk() {
                if(values.ssmsubmodulosnew){
                    dispatch(RemoveSubSubModuleReducer(keySub, key))
                }else{
                    const response = await dispatch(DeleteDataSubSubModuleReducer(values.ssmid))
                    if(response){
                        dispatch(RemoveSubSubModuleReducer(keySub, key))
                    }
                }
            },
            onCancel() {
            },
        })
    }

    return(
        <Card 
            className={`Card-Form-SubSubmodules ${values.validation ? 'Error-SubSubmodules' : ''}`}
            style={{height: values.expandible ? '100%' : '46px', overflowY: !values.expandible ? 'hidden' : 'hidden', transitionDuration: '.5s'}}
        >
            <Row style={{marginBottom: values.expandible ? '0' : '5px',}}>
                <Col span={24} style={{display: 'flex', justifyContent: 'end', alignItems: 'center', columnGap: '10px', position: 'relative'}}>
                    <Tooltip
                        placement='bottom'
                        title={values.expandible ? 'Minimizar Sub Módulo' : 'Maximizar Sub Módulo'}
                        color='#3B72FF'
                        showArrow={false}
                        overlayStyle={{marginTop:'20px', fontSize: '12px', minHeight: '30px'}}
                    >
                        {
                        values.expandible
                            ?<MinusOutlined 
                                onClick={() => dispatch(ChangeExpandibleSubSubModuleReducer(false, submodulekey, values.key))} 
                                className="Icon-Min-Max-Submodules" 
                            />
                            :<PlusOutlined 
                                onClick={() => dispatch(ChangeExpandibleSubSubModuleReducer(true, submodulekey, values.key))} 
                                className="Icon-Min-Max-Submodules" 
                            />
                        }
                    </Tooltip>
                    <Tooltip
                        placement='bottom'
                        title='Elminiar Sub Sub Módulo'
                        color='#3B72FF'
                        showArrow={false}
                        overlayStyle={{marginTop:'20px', fontSize: '12px', minHeight: '30px'}}
                    >
                        <div 
                            onClick={() => DeleteSubSubModule(submodulekey, values.key)}
                            className="Icon-Delete-SubSubmodules"
                        ></div>
                    </Tooltip>
                </Col>
            </Row>
            <Form
                className="Form-SubSubmodules"
                layout="vertical"
                autoComplete="off"
                onValuesChange={false}
            >
                <Row gutter={20}>
                    <Col md={12} xs={24}>
                        <Form.Item 
                            label="Nombre del sub submódulo"
                            className="Form-Item-SubSubmodules"
                            labelCol={{
                                span: 24,
                            }}
                            wrapperCol={{
                                span: 24,
                            }}
                        >
                            <Input 
                                onChange={(e) => dispatch(StoreSubSubModuleReducer(e.target.value, values.key, "subsubnombre", submodulekey))} 
                                placeholder="Ingresar nombre" 
                                value={values.ssmnombre}
                            />
                        </Form.Item>
                    </Col>
                    <Col md={12} xs={24}>
                        <Form.Item 
                            label="Ruta de submódulo"
                            className="Form-Item-SubSubmodules"
                            labelCol={{
                                span: 24,
                            }}
                                wrapperCol={{
                                span: 24,
                            }}
                        >
                            <Input 
                                onChange={(e) => dispatch(StoreSubSubModuleReducer(e.target.value, values.key, "subsubroute", submodulekey))} 
                                placeholder="Ingresar enlace" 
                                value={values.ssmruta}
                            />
                        </Form.Item>
                    </Col>
                    <Col md={12} xs={24}>
                        <Form.Item 
                            label="Enlace Power Bi"
                            className="Form-Item-SubSubmodules"
                            labelCol={{
                                span: 24,
                            }}
                            wrapperCol={{
                                span: 24,
                            }}
                        >
                            <Input 
                                onChange={(e) => dispatch(StoreSubSubModuleReducer(e.target.value, values.key, "subsublink", submodulekey))} 
                                placeholder="Ingresar ruta" 
                                value={values.ssmpowerbi}
                            />
                        </Form.Item>
                    </Col>
                    <Col md={12} xs={24}>
                        <Form.Item 
                            label="Slug del permiso (país.módulo.submódulo)"
                            className="Form-Item-SubSubmodules"
                            labelCol={{
                                span: 24,
                            }}
                            wrapperCol={{
                                span: 24,
                            }}
                        >
                            <Input 
                                onChange={(e) => dispatch(StoreSubSubModuleReducer(e.target.value, values.key, "subsubslug", submodulekey))} 
                                placeholder="Ingresar slug" 
                                value={values.ssmslug}
                            />
                        </Form.Item>
                    </Col>
                </Row>
            </Form>
        </Card>
    )
}

export default FormNewSubSubModules