import {
    BOTONES_FILTRO,
    OBTENER_DATA_ANIOS,
    OBTENER_DATA_MESES,
    OBTENER_DATA_CANAL,
    OBTENER_DATA_EJECUTIVO,
    OBTENER_DATA_CATEGORIA,
    OBTENER_DATA_SUBCATEGORIA,
    OBTENER_DATA_FORMATO,
    OBTENER_DATA_TALLA,
    OBTENER_DATA_CONTEO,
    OBTENER_DATA_SKU,
    OBTENER_DATA_DESCRIPCION_MATERIAL,
    OBTENER_DATA_CLIENTE_HML,
    OBTENER_TYPE_DATA_DAILY,
    SELECCIONADO_DATA_FORMATO,
    SELECCIONADO_DATA_TALLA,
    SELECCIONADO_DATA_CONTEO,
    SELECCIONADO_DATA_SKU,
    SELECCIONADO_DATA_DESCRIPCION_MATERIAL,
    SELECCIONADO_DATA_CLIENTE_HML
} from '../../../Constants/PowerBi/Filtros'

const INIT_STATE = {
    rex_botones_filtro: [
        {nombre: 'Canal', estado: true}, 
        {nombre: 'Cadena', estado: true}, 
        // {nombre: 'Sub cadena', estado: true}, 
        {nombre: 'Categoría', estado: true},
    ],
    rex_fecha_anios: [],
    rex_fecha_meses: [],
    rex_data_canal: [],
    rex_data_ejecutivo: [],
    rex_data_categoria: [],
    rex_data_subcategoria: [],
    rex_data_formato_original: [],
    rex_data_formato: [],
    rex_data_formato_seleccionado: [],
    rex_data_talla_original: [],
    rex_data_talla: [],
    rex_data_talla_seleccionado: [],
    rex_data_conteo_original: [],
    rex_data_conteo: [],
    rex_data_conteo_seleccionado: [],
    rex_data_sku_original: [],
    rex_data_sku: [],
    rex_data_sku_seleccionado: [],
    rex_data_descripcion_material_original: [],
    rex_data_descripcion_material: [],
    rex_data_descripcion_material_seleccionado: [],
    rex_data_cliente_hml: [],
    rex_data_cliente_hml_seleccionado: [],
    // rex_data_type_data : 'ventapublico'
    rex_data_type_data : 'cajas'
}

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case OBTENER_TYPE_DATA_DAILY :
            return {
                ...state,
                rex_data_type_data : action.payload
            }
        case BOTONES_FILTRO :
            return {
                ...state,
                rex_botones_filtro : action.payload
            }
        case OBTENER_DATA_ANIOS :
            return {
                ...state,
                rex_fecha_anios : action.payload
            }
        case OBTENER_DATA_MESES :
            return {
                ...state,
                rex_fecha_meses : action.payload
            }
        case OBTENER_DATA_CANAL :
            return {
                ...state,
                rex_data_canal : action.payload
            }
        case OBTENER_DATA_EJECUTIVO :
            return {
                ...state,
                rex_data_ejecutivo : action.payload
            }
        case OBTENER_DATA_CATEGORIA :
            return {
                ...state,
                rex_data_categoria : action.payload
            }
        case OBTENER_DATA_SUBCATEGORIA :
            return {
                ...state,
                rex_data_subcategoria : action.payload
            }
        case OBTENER_DATA_FORMATO :
            return {
                ...state,
                rex_data_formato : action.payload.data,
                rex_data_formato_original : action.payload.original
            }
        case OBTENER_DATA_TALLA :
            return {
                ...state,
                rex_data_talla : action.payload.data,
                rex_data_talla_original : action.payload.original
            }
        case OBTENER_DATA_CONTEO :
            return {
                ...state,
                rex_data_conteo : action.payload.data,
                rex_data_conteo_original : action.payload.original
            }
        case OBTENER_DATA_SKU :
            return {
                ...state,
                rex_data_sku : action.payload.data,
                rex_data_sku_original : action.payload.original
            }
        case OBTENER_DATA_DESCRIPCION_MATERIAL :
            return {
                ...state,
                rex_data_descripcion_material : action.payload.data,
                rex_data_descripcion_material_original : action.payload.original
            }
        case OBTENER_DATA_CLIENTE_HML :
            return {
                ...state,
                rex_data_cliente_hml : action.payload
            }
        case SELECCIONADO_DATA_FORMATO :
            return {
                ...state,
                rex_data_formato_seleccionado : action.payload
            }
        case SELECCIONADO_DATA_TALLA :
            return {
                ...state,
                rex_data_talla_seleccionado : action.payload
            }
        case SELECCIONADO_DATA_CONTEO :
            return {
                ...state,
                rex_data_conteo_seleccionado : action.payload
            }
        case SELECCIONADO_DATA_SKU :
            return {
                ...state,
                rex_data_sku_seleccionado : action.payload
            }
        case SELECCIONADO_DATA_DESCRIPCION_MATERIAL :
            return {
                ...state,
                rex_data_descripcion_material_seleccionado : action.payload
            }
        case SELECCIONADO_DATA_CLIENTE_HML :
            return {
                ...state,
                rex_data_cliente_hml_seleccionado : action.payload
            }
        default:
            return state;
    }
}