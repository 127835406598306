import notificationGlobal from "../../../Components/Global/NotificationGlobal";
import config from "../../../config";
import { 
    GET_DATA_FILES_UPLOADED_FILES,
    GET_DATA_FILES_UPLOADED_FILES_CLONE,
    GET_DATA_FILTERS_FILES_UPLOADED_FILES
} from "../../../Constants/UploadedFiles/UploadedFiles"

export const GetDataFilesUploadedFilesReducer = () => async ( dispatch, getState ) => {

    await fetch(config.api+'files/uploaded-files',
        {
            mode:'cors',
            method: 'POST',
            headers: {
                'Accept' 	   : 'application/json',
                'Content-type' : 'application/json',
                'usutoken'      : localStorage.getItem('usutoken')
            },
        }
    )
    .then( async res => {
        return res.json()
    })
    .then(data => {
        if(data.response){
            dispatch({
                type: GET_DATA_FILES_UPLOADED_FILES_CLONE,
                payload : data.data
            })
            dispatch({
                type: GET_DATA_FILES_UPLOADED_FILES,
                payload : data.data
            })
            dispatch({
                type: GET_DATA_FILTERS_FILES_UPLOADED_FILES,
                payload : data.filters
            })
        }else{
            // dispatch(RegisterAuditReducer('get-data/paises', 'Error al obtener los paises', data.message, localStorage.getItem('usutoken'), data, user.usuid, 'ip', 'GET COUNTRIES'))
        }
    }).catch((error)=> {
        console.log(error)
    });
}

export const DeleteFileUploadedFilesReducer = (id) => async ( dispatch, getState ) => {

    let response = false

    await fetch(config.api+'files/delete-file',
        {
            mode:'cors',
            method: 'POST',
            headers: {
                'Accept' 	   : 'application/json',
                'Content-type' : 'application/json',
                'usutoken'      : localStorage.getItem('usutoken')
            },
            body: JSON.stringify({
                req_carid : id
            }),
        }
    )
    .then( async res => {
        return res.json()
    })
    .then(data => {
        if(data.response){
            dispatch(GetDataFilesUploadedFilesReducer())
            response = true
        }
    }).catch((error)=> {
        console.log(error)
    });

    return response
}

export const FilterFileUploadedFilesReducer = (list) => async ( dispatch, getState ) => {

    const data_uploaded_files = getState().uploadedFiles.rex_get_data_files_uploaded_files
    
    if(list.length > 0){
        
        const data_filter = data_uploaded_files.filter(fil => fil.paipaises ? list.includes(fil.paipaises.paiid) : true )

        dispatch({
            type : GET_DATA_FILES_UPLOADED_FILES_CLONE,
            payload : data_filter
        })
    }else{
        dispatch({
            type : GET_DATA_FILES_UPLOADED_FILES_CLONE,
            payload : data_uploaded_files
        })
    }
}
export const FilterFileNameUploadedFilesReducer = (txtSearch) => async ( dispatch, getState ) => {

    const data_uploaded_files = getState().uploadedFiles.rex_get_data_files_uploaded_files
    
    if(txtSearch.length > 0){
        
        const data_filter = data_uploaded_files.filter(fil => 
            (
                fil.carnombre.toLowerCase().includes(txtSearch.toLocaleLowerCase())
                || fil.cartipo.toLowerCase().includes(txtSearch.toLocaleLowerCase())
                || fil.usuusuarios.usuusuario.toLowerCase().includes(txtSearch.toLocaleLowerCase())
            )    
        )

        dispatch({
            type : GET_DATA_FILES_UPLOADED_FILES_CLONE,
            payload : data_filter
        })
    }else{
        dispatch({
            type : GET_DATA_FILES_UPLOADED_FILES_CLONE,
            payload : data_uploaded_files
        })
    }
}

export const ProcessFileUploadedFilesReducer = (id) => async ( dispatch, getState ) => {

    await fetch(config.api+'files/process-file',
        {
            mode:'cors',
            method: 'POST',
            headers: {
                'Accept' 	   : 'application/json',
                'Content-type' : 'application/json',
                'usutoken'      : localStorage.getItem('usutoken')
            },
            body: JSON.stringify({
                req_carid : id
            }),
        }
    )
    .then( async res => {
        return res.json()
    })
    .then(data => {
        if(data.response){
            dispatch(GetDataFilesUploadedFilesReducer())
            notificationGlobal('success', data.message)
        }else{
            notificationGlobal('error', data.message)
            // dispatch(RegisterAuditReducer('get-data/paises', 'Error al obtener los paises', data.message, localStorage.getItem('usutoken'), data, user.usuid, 'ip', 'GET COUNTRIES'))
        }
    }).catch((error)=> {
        console.log(error)
    });
}