import React, { useEffect, useState } from 'react'
import { Row, Col, DatePicker, ConfigProvider, Space, Typography, Button, Modal } from 'antd'
import TitleSearchGlobal from '../../Components/Global/TitleSearchGlobal'
import TableGlobalCollapse from '../../Components/Global/TableGlobalCollapse'
import FilterSimpleSelectGlobal from '../../Components/Global/FilterSimpleSelectGlobal'
import '../../Styles/Routes/IncentivProgram/IncentivProgram.css'
import es_ES from "antd/lib/locale/es_ES"
import 'dayjs/locale/es';
import dayjs from 'dayjs';
import { useDispatch, useSelector } from 'react-redux'
import { CambiarTxtFilterIncentivenProgramReducer, GetDataFiltersIncentivenProgramReducer, GetDataIncentivenProgramReducer, SaveIncentivenProgramReducer } from '../../Redux/Actions/IncentivProgram/IncentivProgram'
import { 
    LoadingOutlined,
    ExclamationCircleFilled
} from '@ant-design/icons'
import { GenerateExcelReducer } from '../../Redux/Actions/DownloadDataInternal/DownloadDataInternal'
import moment from 'moment'
import SideMenu from '../../Components/Global/SideMenu'
dayjs.locale('es');

const IncentivProgram = () => {

    const dispatch = useDispatch()
    const { confirm } = Modal
    const [ loadingSave, setLoadingSave ] = useState(false)
    const [ loadingDownload, setLoadingDownload ] = useState(false)
    const [ dateFilter, setDateFilter ] = useState(dayjs(dayjs(), "AAAA-MM"))
    const { Title } = Typography

    const columnsPC = [
        {
            name    : "% NET SALES",
        },
        {
            name    : "% GANANCIA"
        },
        {
            name    : "USUARIO",
        },
        {
            name    : "ACCIONES"
        }
    ]

    const columnsNP = [
        {
            name    : "% NET / GSU",
        },
        {
            name    : "% GANANCIA"
        },
        {
            name    : "USUARIO",
        },
        {
            name    : "ACCIONES"
        }
    ]

    const {
        rex_filters_incentiv_program_total,
        rex_data_incentiv_program,
        rex_filters_items_gba,
        rex_filters_items_channel,
        rex_filters_items_zone,
        rex_filters_items_client,
        rex_filters_items_incentive,
        rex_data_last_update_incentiv_program,
        rex_data_incentiv_program_clone
    } = useSelector(({incentivProgram}) => incentivProgram)

    const { 
        rex_users_conected_dashboard,
    } = useSelector(({registerUsers}) => registerUsers)

    const { 
        rex_data_users_online
    } = useSelector(({users}) => users)

    const {
        rex_txt_unique_gba,
        rex_txt_uniqueChannels,
        rex_txt_unique_zona,
        rex_txt_unique_incentive,
        rex_txt_unique_cliente
    } = rex_filters_incentiv_program_total.rex_filter_incentiv_program

    useEffect(() => {
        dispatch(GetDataFiltersIncentivenProgramReducer())
        dispatch(GetDataIncentivenProgramReducer())
    },[])

    const validateDateDisable = (date) => {

        if(date == "" || typeof(date) == "object"){
            return false
        }
        const currentDate = dayjs().format("YYYY-MM")
        return currentDate == date ? false : true            
    }

    const saveIncentivenProgram = async () => {
        const response = await dispatch(SaveIncentivenProgramReducer(dateFilter, "save-incentiven-program"))
        if(!response){
            confirm({
                title   : "Guardar Programa de Incentivos",
                icon    : <ExclamationCircleFilled/>,
                content : "Existen el programa de incentivos para el mes siguiente, ¿Desea reemplazar la información?",
                zIndex  : 100000000,
                async onOk(){
                    const response = await dispatch(SaveIncentivenProgramReducer(dateFilter, "confirm-save-incentiven-program"))
                },
                okCancel(){}
            })   
        }
    }

    useEffect(() => {

    },[rex_data_incentiv_program])

    useEffect(() => {

    },[rex_data_incentiv_program_clone])

    return (
        <div className='Container-Main Container-Incentiv-Program'>
            <TitleSearchGlobal
                title='Incentiven Program'
                showInputSearch={false}
                subTitle={
                    rex_data_last_update_incentiv_program 
                    ? moment(rex_data_last_update_incentiv_program.updated_at).format('D [de] MMMM [del] YYYY h:mm a') 
                    : ''
                }
            />
            <Row className="Container-Fitler-Actions">
                <Col>
                    <Space>
                        <ConfigProvider
                        >
                            <DatePicker locale={es_ES}
                                onChange={(date, dateString) => {
                                    dispatch(GetDataIncentivenProgramReducer(dateString))
                                    setDateFilter(dateString)
                                }}
                                popupClassName='DatePicker-Filter-Incentiven-Program'
                                size='small'
                                picker='month'
                                className='Table-Global-Filter-DatePicker'
                                defaultValue={dateFilter}
                            /> 
                        </ConfigProvider>
                        <FilterSimpleSelectGlobal
                            typeFilter="Incentivos"
                            value={rex_txt_unique_incentive == "" ? null : rex_txt_unique_incentive}
                            onAction={CambiarTxtFilterIncentivenProgramReducer}
                            list_data = {rex_filters_items_incentive}
                            dateFilter={dateFilter}
                        />
                        <FilterSimpleSelectGlobal
                            typeFilter="GBA"
                            value={rex_txt_unique_gba == "" ? null : rex_txt_unique_gba}
                            onAction={CambiarTxtFilterIncentivenProgramReducer}
                            list_data = {rex_filters_items_gba}
                            dateFilter={dateFilter}
                        />
                        <FilterSimpleSelectGlobal
                            typeFilter="Canal"
                            value={rex_txt_uniqueChannels == "" ? null : rex_txt_uniqueChannels}
                            list_data = {rex_filters_items_channel}
                            onAction={CambiarTxtFilterIncentivenProgramReducer}
                            dateFilter={dateFilter}
                        />
                        <FilterSimpleSelectGlobal
                            typeFilter="Zona"
                            value={rex_txt_unique_zona == "" ? null : rex_txt_unique_zona}
                            list_data = {rex_filters_items_zone}
                            onAction={CambiarTxtFilterIncentivenProgramReducer}
                            dateFilter={dateFilter}
                        />
                        <FilterSimpleSelectGlobal
                            typeFilter="Cliente HML"
                            value={rex_txt_unique_cliente == "" ? null : rex_txt_unique_cliente}
                            list_data = {rex_filters_items_client}
                            onAction={CambiarTxtFilterIncentivenProgramReducer}
                            dateFilter={dateFilter}
                        />
                    </Space>

                </Col>
                <Col>
                    <Space>
                        <Button
                            onClick={async() => {
                                if(!validateDateDisable(dateFilter)){
                                    setLoadingSave(true)
                                    const response = await saveIncentivenProgram()
                                    setLoadingSave(false)
                                }
                            }}
                            className={`Button-Actions ${!validateDateDisable(dateFilter) ? "Button-Save-Incentiv-Program" : "Button-Disabled" }`}
                        >
                            { loadingSave ? <LoadingOutlined/> : "Guardar"}
                        </Button>
                        <Button 
                            className="Button-Actions Button-Download-Incentiv-Program"
                            onClick={async () => {
                                setLoadingDownload(true)
                                const fileUrl = await dispatch(GenerateExcelReducer('incentiven-program',dateFilter, "Incentiven Program"))
                                const link = document.createElement('a')
                                link.href = fileUrl
                                link.download = "Incentiven Program"
                                link.click()
                                setLoadingDownload(false)
                            }}
                        >{loadingDownload ? <LoadingOutlined/> : "Descargar"}</Button>
                    </Space>
                </Col>
            </Row>
            <Row gutter={24}>

                {
                    rex_data_incentiv_program.map((cha, index) => (
                        <Col key={index} lg={8} md={12} sm={24}>
                            <div className="Container-Title-Section-Zone-Incentiv-Program">
                                <span className="Title-Section-Zone-Incentiv-Program" level={5}>{cha.pcinombre}</span>
                            </div>
                            {cha.pti_pe_tipos_incentivos.map((pti, index) => (
                            <div key={index}>
                                <Title className="Title-Section-Incentiv-Program" level={5}>{pti.ptinombre}</Title>
                                {pti.data.map((dat, index) => {
                                    return (
                                        <TableGlobalCollapse
                                            key={index}
                                            title={dat.ptiindicador}
                                            columns={pti.ptinombre == "NET PRICE" ? columnsNP : columnsPC}
                                            data={dat.data}
                                            titleTable={cha.pcinombre}
                                            titleSection={pti.ptinombre}
                                            dateFilter={dateFilter}
                                        />        
                                    )
                                })}
                            </div>
                            ))}
                        </Col>
                    ))
                }
            </Row>

            <SideMenu
                usersCurrentView  = {rex_users_conected_dashboard}
                usersAllConnected ={rex_data_users_online}
            />
        </div>
    )
}

export default IncentivProgram