import {
    GET_AUDITS_USERS,
    GET_AUDITS_TYPES,
    GET_AUDITS_USERS_CLONE,
    GET_AUDITS_TYPES_USERS
} from "../../../Constants/Audits/Audits";


const INIT_STATE = {
    rex_audits_users        : [],
    rex_audits_users_clone  : [],
    rex_types_audits        : [],
    rex_types_users_audits  : []
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {

        case GET_AUDITS_TYPES_USERS: {
            return {
                ...state,
                rex_types_users_audits : action.payload,
            }
        }
        case GET_AUDITS_USERS_CLONE: {
            return {
                ...state,
                rex_audits_users_clone : action.payload,
            }
        }
        case GET_AUDITS_USERS: {
            return {
                ...state,
                rex_audits_users : action.payload,
            }
        }
        case GET_AUDITS_TYPES: {
            return {
                ...state,
                rex_types_audits : action.payload,
            }
        }
        default:
            return state;
    }
}