import React from 'react'
import { Modal, Row, Col, Typography } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { ShowModalNotificationGlobalReducer } from '../../Redux/Actions/Global/Global'
import './../../Styles/Components/Global/Global.css'

const ModalNotification = () => {
    
    const { Title } = Typography

    const dispatch = useDispatch()
    const { 
        rex_show_modal_notification_global,
        rex_data_modal_notification_global
    } = useSelector(({global}) => global)


    const showModal = () => {
        dispatch(ShowModalNotificationGlobalReducer(false))
    }

    return (
        <Modal
            open={rex_show_modal_notification_global}
            onCancel={() => showModal()}
            width={252}
            footer={null}
            zIndex={100000000}
            closeIcon={null}
            centered={true}
            closable={false}
            className='Modal-Notification-Global'
        >
            <Row>
                <Col span={24} className='Modal-Container-Text-Main'>
                    <Title>
                        Notificación
                    </Title>
                </Col>
                <Col span={24} className='Modal-Container-Text-Description'>
                    <div style={{marginBottom:"2px"}}>
                        {rex_data_modal_notification_global.title}
                    </div>
                    <div>

                    {
                        rex_data_modal_notification_global.messageDescription.length > 0 && rex_data_modal_notification_global.messageDescription?.map(dat => {
                            return <div><b>{dat}</b></div>
                        })
                    }
                    </div>
                </Col>
                <Col span={24} className='Container-Button-Close'>
                    {/* <div
                        onClick={() => showModal()}
                        className='Notification-Button-Global Button-Cancel'
                    >
                        Cancelar
                    </div> */}
                    <div
                        onClick={() => showModal()}
                        className='Notification-Button-Global Button-Ok'
                    >
                        Ok
                        {/* Continuar */}
                    </div>
                </Col>
            </Row>
        </Modal>
    )
}

export default ModalNotification