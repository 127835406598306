import React, { useState } from 'react'
import TableGlobal from '../../Global/TableGlobal'
import EditOptionButtonGlobal from '../../../Assets/Images/Global/EditOptionButtonGlobal'
import { Popconfirm, Input, Space, Modal, Table, Tooltip, Skeleton, InputNumber } from 'antd'
import TrashButtonGlobalSimpleColor from '../../../Assets/Images/Global/TrashButtonGlobalSimpleColor'
import { useDispatch, useSelector } from 'react-redux'
import { 
    FilterOutlined,
    ExclamationCircleFilled,
    LoadingOutlined,
    CheckCircleOutlined,
    CloseCircleOutlined,
} from '@ant-design/icons'
import FilterSimpleSelectGlobal from '../../Global/FilterSimpleSelectGlobal'
import { CambiarTxtFilterMasterClientsReducer, DeleteMasterClientManageReducer, EditMasterClientManageReducer, EditRowMasterClientManualManageReducer, EditValueMasterClientManual, GetMasterClientsManualsManageReducer, SaveMasterClientsReducer } from '../../../Redux/Actions/Manage/MasterClients/MasterClients'
import moment from 'moment'
import notificationGlobal from '../../Global/NotificationGlobal'

const MasterClientsManuals = ({
    componentExtraLeftPagination,
    dateFilterManual,
    setDateFilterManual,
    selectedRowKeys,
    setSelectedRowKeys,
    valueCheckbox,
    setValueCheckbox,
    dateClients,
    buttonSaveProductsDisable
}) => {

    const [pageTableGlobal, setPageTableGlobal ] = useState(1)
    const [pageSizeTableGlobal, setPageSizeTableGlobal ] = useState(15)
    const [ loadingEdit, setLoadingEdit ] = useState({id : null, status: false})

    const dispatch = useDispatch()
    const { confirm } = Modal

    const { 
        rex_data_master_clients_manuals_manage,
        rex_data_form_edit_master_client_manual,
        rex_loading_master_clients_manuals_manage
    } = useSelector(({masterClients}) => masterClients)

    const { 
        rex_uniqueChannels,
        rex_unique_zona,
        rex_unique_cliente,
        rex_unique_sucursal,
        rex_unique_gba,
        rex_unique_customer_group
    } = useSelector(({filtersProducts}) => filtersProducts)

    const {
        rex_filters_clients_total,
    } = useSelector(({filtersClients}) => filtersClients)
    const {
        rex_txt_unique_gba,
        rex_txt_uniqueChannels,
        rex_txt_unique_zona,
        rex_txt_unique_cliente,
        rex_txt_unique_sucursal,
        rex_txt_customer_group
    } = rex_filters_clients_total.rex_filter_new

    const getColumnSearchProps = (dataIndex, nameColumn) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
            <div
                style={{
                    padding: 8,
                }}
                onKeyDown={(e) => e.stopPropagation()}
            >
                <Input.Search
                    placeholder={`Filtrar ${nameColumn}`}
                    value={selectedKeys[0]}
                    onChange={(e) => {
                        setSelectedKeys(e.target.value ? [e.target.value] : [])
                        confirm({
                            closeDropdown: false,
                        })
                    }}
                    style={{
                        display: 'block',
                    }}
                />
            </div>
        ),
        filterIcon: () => (<FilterOutlined/>),
        onFilter: (value, record) => {
            return record[dataIndex] != null ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : false
        }
    })

    const filterChannelComponent = () => {
        return (
                <div style={{display:"flex", alignItems: "center"}}>
                    <Space>
                        <FilterSimpleSelectGlobal
                            typeFilter="Customer Group"
                            value={rex_txt_customer_group == "" ? null : rex_txt_customer_group}
                            list_data = {rex_unique_customer_group}
                            dateFilter={dateClients}
                            onAction={CambiarTxtFilterMasterClientsReducer}
                            view="clientsManual"
                        />
                        <FilterSimpleSelectGlobal
                            typeFilter="GBA"
                            value={rex_txt_unique_gba == "" ? null : rex_txt_unique_gba}
                            list_data = {rex_unique_gba}
                            dateFilter={dateClients}
                            onAction={CambiarTxtFilterMasterClientsReducer}
                            view="clientsManual"
                        />
                        <FilterSimpleSelectGlobal
                            typeFilter="Canal"
                            value={rex_txt_uniqueChannels == "" ? null : rex_txt_uniqueChannels}
                            list_data = {rex_uniqueChannels}
                            dateFilter={dateClients}
                            onAction={CambiarTxtFilterMasterClientsReducer}
                            view="clientsManual"
                        />
                        <FilterSimpleSelectGlobal
                            typeFilter="Zona"
                            value={rex_txt_unique_zona == "" ? null : rex_txt_unique_zona}
                            list_data = {rex_unique_zona}
                            dateFilter={dateClients}
                            onAction={CambiarTxtFilterMasterClientsReducer}
                            view="clientsManual"
                        />
                        <FilterSimpleSelectGlobal
                            typeFilter="Cliente HML"
                            value={rex_txt_unique_cliente == "" ? null : rex_txt_unique_cliente}
                            list_data = {rex_unique_cliente}
                            dateFilter={dateClients}
                            onAction={CambiarTxtFilterMasterClientsReducer}
                            view="clientsManual"
                        />
                        <FilterSimpleSelectGlobal
                            typeFilter="Sucursal HML"
                            value={rex_txt_unique_sucursal == "" ? null : rex_txt_unique_sucursal}
                            list_data = {rex_unique_sucursal}
                            dateFilter={dateClients}
                            onAction={CambiarTxtFilterMasterClientsReducer}
                            view="clientsManual"
                        />
                    </Space>
                </div>
            )
    }

    const deleteMasterClients = (ids, resetValues) => {

        if(ids.length > 0){
            confirm({
                title   : `¿Está seguro que desea eliminar ${ids.length > 1 ? 'los clientes': 'al cliente'}?`,
                icon    : <ExclamationCircleFilled/>,
                content : 'Esta acción es irreversible',
                zIndex  : 100000000,
                async onOk(){
                    const response = await dispatch(DeleteMasterClientManageReducer(ids, dateClients, dateFilterManual))
                    if(response){
                        setValueCheckbox(false)
                        resetValues([])
                    }
                },
                okCancel(){}
            })
        }
    }

    const editRow = async (id) => {

        const rows = rex_data_form_edit_master_client_manual
        const rowEdit = rows.find(row => row.id == id)

        if(rowEdit.data.cod_ship_to == "" || rowEdit.data.ship_to == ""){
            notificationGlobal('warning', "Los campos COD SHIP TO Y SHIP TO son requeridos")
            return
        }

        confirm({
            title   : "Editar Cliente",
            icon    : <ExclamationCircleFilled/>,
            content : "Está seguro que desea editar el cliente?",
            zIndex  : 100000000,
            async onOk(){
                setLoadingEdit({...loadingEdit, id : id, status : true})
                const response = await dispatch(EditMasterClientManageReducer(id, 'manual'))
                setLoadingEdit({...loadingEdit, id : null, status : false})
            },
            okCancel(){}
        })
    }

    const parseNumber = (value) => {

        const number = parseFloat(value);
        const numberFormated = number.toLocaleString("es-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 });

        return numberFormated
    }

    const columns = [
        {
            title: 'Item',
            dataIndex: 'item',
            align: 'center',
            render: (text, record, index) => (pageTableGlobal - 1) * pageSizeTableGlobal + index + 1,
            width: "60px",
            fixed : 'left',
        },
        Table.SELECTION_COLUMN,
        {
            title: 'Código SHIP TO',
            dataIndex: 'cod_ship_to',
            ...getColumnSearchProps('cod_ship_to', 'cod ship to'),
            sorter: (a, b) => a.cod_ship_to ? a.cod_ship_to.localeCompare(b.cod_ship_to) : false,
            showSorterTooltip: false,
            align: 'center',
            render:(_, record) => {
                const dataRow = rex_data_form_edit_master_client_manual.find(dat => dat.id == record.id)
                return <div className='Table-Contain-Text-Global'>
                    {
                        dataRow
                        ? <Input
                            defaultValue={record.cod_ship_to? record.cod_ship_to : ''}
                            value={dataRow.data.cod_ship_to}
                            size="small"
                            placeholder='Código SHIP TO'
                            onChange={(e) => dispatch(EditValueMasterClientManual('cod_ship_to', e.target.value, record.id))}
                        />
                        : record.cod_ship_to? record.cod_ship_to : ''
                    }
                </div>
            },
            fixed : 'left',
            ellipsis : true,
            width : 130
        },
        {
            title: 'SHIP TO',
            dataIndex: 'ship_to',
            ...getColumnSearchProps('ship_to', 'ship to'),
            sorter: (a, b) => a.ship_to ? a.ship_to.localeCompare(b.ship_to) : false,
            showSorterTooltip: false,
            align: 'center',
            render:(_, record) => {
                const dataRow = rex_data_form_edit_master_client_manual.find(dat => dat.id == record.id)
                return <div className='Table-Contain-Text-Global'>
                    {
                        dataRow
                        ? <Input
                            defaultValue={record.ship_to? record.ship_to : ''}
                            value={dataRow.data.ship_to}
                            size="small"
                            placeholder='SHIP TO'
                            onChange={(e) => dispatch(EditValueMasterClientManual('ship_to', e.target.value, record.id))}
                        />
                        : record.ship_to? record.ship_to : ''
                    }
                </div>
            },
            fixed : 'left',
            ellipsis : true,
            width : 120
        },
        {
            title: 'Código sales organization',
            dataIndex: 'cod_sales_organization',
            ...getColumnSearchProps('cod_sales_organization', 'código sales organization'),
            sorter: (a, b) => a.cod_sales_organization ? a.cod_sales_organization.localeCompare(b.cod_sales_organization) : false,
            showSorterTooltip: false,
            align: 'center',
            render:(_, record) => {
                const dataRow = rex_data_form_edit_master_client_manual.find(dat => dat.id == record.id)
                return <div className='Table-Contain-Text-Global'>
                    {
                        dataRow
                        ? <Input
                            defaultValue={record.cod_sales_organization? record.cod_sales_organization : ''}
                            value={dataRow.data.cod_sales_organization}
                            size="small"
                            placeholder='Código sales organization'
                            onChange={(e) => dispatch(EditValueMasterClientManual('cod_sales_organization', e.target.value, record.id))}
                        />
                        : record.cod_sales_organization? record.cod_sales_organization : ''
                    }
                </div>
            },
            ellipsis : true,
            width : 120
        },
        {
            title: 'Sales organización',
            dataIndex: 'sales_organization',
            ...getColumnSearchProps('sales_organization', 'sales organización'),
            sorter: (a, b) => a.sales_organization ? a.sales_organization.localeCompare(b.sales_organization) : false,
            showSorterTooltip: false,
            align: 'center',
            render:(_, record) => {
                const dataRow = rex_data_form_edit_master_client_manual.find(dat => dat.id == record.id)
                return <div className='Table-Contain-Text-Global'>
                    {
                        dataRow
                        ? <Input
                            defaultValue={record.sales_organization? record.sales_organization : ''}
                            value={dataRow.data.sales_organization}
                            size="small"
                            placeholder='Sales organización'
                            onChange={(e) => dispatch(EditValueMasterClientManual('sales_organization', e.target.value, record.id))}
                        />
                        : record.sales_organization? record.sales_organization : ''
                    }
                </div>
            },
            ellipsis : true,
            width : 120
        },
        {
            title: 'Código Bussiness',
            dataIndex: 'cod_bussiness',
            ...getColumnSearchProps('cod_bussiness', 'código bussiness'),
            sorter: (a, b) => a.cod_bussiness ? a.cod_bussiness.localeCompare(b.cod_bussiness) : false,
            showSorterTooltip: false,
            align: 'center',
            render:(_, record) => {
                const dataRow = rex_data_form_edit_master_client_manual.find(dat => dat.id == record.id)
                return <div className='Table-Contain-Text-Global'>
                    {
                        dataRow
                        ? <Input
                            defaultValue={record.cod_bussiness? record.cod_bussiness : ''}
                            value={dataRow.data.cod_bussiness}
                            size="small"
                            placeholder='Código Bussiness'
                            onChange={(e) => dispatch(EditValueMasterClientManual('cod_bussiness', e.target.value, record.id))}
                        />
                        : record.cod_bussiness? record.cod_bussiness : ''
                    }
                </div>
            },
            ellipsis : true,
            width : 120
        },
        {
            title: 'Bussiness',
            dataIndex: 'bussiness',
            ...getColumnSearchProps('bussiness', 'bussiness'),
            sorter: (a, b) => a.bussiness ? a.bussiness.localeCompare(b.bussiness) : false,
            showSorterTooltip: false,
            align: 'center',
            render:(_, record) => {
                const dataRow = rex_data_form_edit_master_client_manual.find(dat => dat.id == record.id)
                return <div className='Table-Contain-Text-Global'>
                    {
                        dataRow
                        ? <Input
                            defaultValue={record.bussiness? record.bussiness : ''}
                            value={dataRow.data.bussiness}
                            size="small"
                            placeholder='Bussiness'
                            onChange={(e) => dispatch(EditValueMasterClientManual('bussiness', e.target.value, record.id))}
                        />
                        : record.bussiness? record.bussiness : ''
                    }
                </div>
            },
            ellipsis : true,
            width : 120
        },
        {
            title: 'Código sold to',
            dataIndex: 'cod_sold_to',
            ...getColumnSearchProps('cod_sold_to', 'cod sold to'),
            sorter: (a, b) => a.cod_sold_to ? a.cod_sold_to.localeCompare(b.cod_sold_to) : false,
            showSorterTooltip: false,
            align: 'center',
            render:(_, record) => {
                const dataRow = rex_data_form_edit_master_client_manual.find(dat => dat.id == record.id)
                return <div className='Table-Contain-Text-Global'>
                    {
                        dataRow
                        ? <Input
                            defaultValue={record.cod_sold_to? record.cod_sold_to : ''}
                            value={dataRow.data.cod_sold_to}
                            size="small"
                            placeholder='Código sold to'
                            onChange={(e) => dispatch(EditValueMasterClientManual('cod_sold_to', e.target.value, record.id))}
                        />
                        : record.cod_sold_to? record.cod_sold_to : ''
                    }
                </div>
            },
            ellipsis : true,
            width : 120
        },
        {
            title: 'Sold to',
            dataIndex: 'sold_to',
            ...getColumnSearchProps('sold_to', 'sold to'),
            sorter: (a, b) => a.sold_to ? a.sold_to.localeCompare(b.sold_to) : false,
            showSorterTooltip: false,
            align: 'center',
            render:(_, record) => {
                const dataRow = rex_data_form_edit_master_client_manual.find(dat => dat.id == record.id)
                return <div className='Table-Contain-Text-Global'>
                    {
                        dataRow
                        ? <Input
                            defaultValue={record.sold_to? record.sold_to : ''}
                            value={dataRow.data.sold_to}
                            size="small"
                            placeholder='Sold to'
                            onChange={(e) => dispatch(EditValueMasterClientManual('sold_to', e.target.value, record.id))}
                        />
                        : record.sold_to? record.sold_to : ''
                    }
                </div>
            },
            ellipsis : true,
            width : 120
        },
        {
            title: 'HML código cliente',
            dataIndex: 'hml_cod_client',
            ...getColumnSearchProps('hml_cod_client', 'HML código cliente'),
            sorter: (a, b) => a.hml_cod_client ? a.hml_cod_client.localeCompare(b.hml_cod_client) : false,
            showSorterTooltip: false,
            align: 'center',
            render:(_, record) => {
                const dataRow = rex_data_form_edit_master_client_manual.find(dat => dat.id == record.id)
                return <div className='Table-Contain-Text-Global'>
                    {
                        dataRow
                        ? <Input
                            defaultValue={record.hml_cod_client? record.hml_cod_client : ''}
                            value={dataRow.data.hml_cod_client}
                            size="small"
                            placeholder='HML código cliente'
                            onChange={(e) => dispatch(EditValueMasterClientManual('hml_cod_client', e.target.value, record.id))}
                        />
                        : record.hml_cod_client? record.hml_cod_client : ''
                    }
                </div>
            },
            ellipsis : true,
            width : 120
        },
        {
            title: 'HML cliente',
            dataIndex: 'hml_client',
            ...getColumnSearchProps('hml_client', 'HML cliente'),
            sorter: (a, b) => a.hml_client ? a.hml_client.localeCompare(b.hml_client) : false,
            showSorterTooltip: false,
            align: 'center',
            render:(_, record) => {
                const dataRow = rex_data_form_edit_master_client_manual.find(dat => dat.id == record.id)
                return <div className='Table-Contain-Text-Global'>
                    {
                        dataRow
                        ? <Input
                            defaultValue={record.hml_client? record.hml_client : ''}
                            value={dataRow.data.hml_client}
                            size="small"
                            placeholder='HML cliente'
                            onChange={(e) => dispatch(EditValueMasterClientManual('hml_client', e.target.value, record.id))}
                        />
                        : record.hml_client? record.hml_client : ''
                    }
                </div>
            },
            ellipsis : true,
            width : 120
        },
        {
            title: 'HML código subsidiario',
            dataIndex: 'hml_cod_subsidiary',
            ...getColumnSearchProps('hml_cod_subsidiary', 'HML código subsidiario'),
            sorter: (a, b) => a.hml_cod_subsidiary ? a.hml_cod_subsidiary.localeCompare(b.hml_cod_subsidiary) : false,
            showSorterTooltip: false,
            align: 'center',
            render:(_, record) => {
                const dataRow = rex_data_form_edit_master_client_manual.find(dat => dat.id == record.id)
                return <div className='Table-Contain-Text-Global'>
                    {
                        dataRow
                        ? <Input
                            defaultValue={record.hml_cod_subsidiary? record.hml_cod_subsidiary : ''}
                            value={dataRow.data.hml_cod_subsidiary}
                            size="small"
                            placeholder='HML código subsidiario'
                            onChange={(e) => dispatch(EditValueMasterClientManual('hml_cod_subsidiary', e.target.value, record.id))}
                        />
                        : record.hml_cod_subsidiary? record.hml_cod_subsidiary : ''
                    }
                </div>
            },
            ellipsis : true,
            width : 120
        },
        {
            title: 'HML subsidiario',
            dataIndex: 'hml_subsidiary',
            ...getColumnSearchProps('hml_subsidiary', 'HML subsidiario'),
            sorter: (a, b) => a.hml_subsidiary ? a.hml_subsidiary.localeCompare(b.hml_subsidiary) : false,
            showSorterTooltip: false,
            align: 'center',
            render:(_, record) => {
                const dataRow = rex_data_form_edit_master_client_manual.find(dat => dat.id == record.id)
                return <div className='Table-Contain-Text-Global'>
                    {
                        dataRow
                        ? <Input
                            defaultValue={record.hml_subsidiary? record.hml_subsidiary : ''}
                            value={dataRow.data.hml_subsidiary}
                            size="small"
                            placeholder='HML subsidiario'
                            onChange={(e) => dispatch(EditValueMasterClientManual('hml_subsidiary', e.target.value, record.id))}
                        />
                        : record.hml_subsidiary? record.hml_subsidiary : ''
                    }
                </div>
            },
            ellipsis : true,
            width : 120
        },
        {
            title: 'Corporate group',
            dataIndex: 'corporate_group',
            ...getColumnSearchProps('corporate_group', 'corporate group'),
            sorter: (a, b) => a.corporate_group ? a.corporate_group.localeCompare(b.corporate_group) : false,
            showSorterTooltip: false,
            align: 'center',
            render:(_, record) => {
                const dataRow = rex_data_form_edit_master_client_manual.find(dat => dat.id == record.id)
                return <div className='Table-Contain-Text-Global'>
                    {
                        dataRow
                        ? <Input
                            defaultValue={record.corporate_group? record.corporate_group : ''}
                            value={dataRow.data.corporate_group}
                            size="small"
                            placeholder='Corporate group'
                            onChange={(e) => dispatch(EditValueMasterClientManual('corporate_group', e.target.value, record.id))}
                        />
                        : record.corporate_group? record.corporate_group : ''
                    }
                </div>
            },
            ellipsis : true,
            width : 120
        },
        {
            title: 'Código regional',
            dataIndex: 'cod_regional',
            ...getColumnSearchProps('cod_regional', 'código regional'),
            sorter: (a, b) => a.cod_regional ? a.cod_regional.localeCompare(b.cod_regional) : false,
            showSorterTooltip: false,
            align: 'center',
            render:(_, record) => {
                const dataRow = rex_data_form_edit_master_client_manual.find(dat => dat.id == record.id)
                return <div className='Table-Contain-Text-Global'>
                    {
                        dataRow
                        ? <Input
                            defaultValue={record.cod_regional? record.cod_regional : ''}
                            value={dataRow.data.cod_regional}
                            size="small"
                            placeholder='Código regional'
                            onChange={(e) => dispatch(EditValueMasterClientManual('cod_regional', e.target.value, record.id))}
                        />
                        : record.cod_regional? record.cod_regional : ''
                    }
                </div>
            },
            ellipsis : true,
            width : 120
        },
        {
            title: 'Regional',
            dataIndex: 'regional',
            ...getColumnSearchProps('regional', 'regional'),
            sorter: (a, b) => a.regional ? a.regional.localeCompare(b.regional) : false,
            showSorterTooltip: false,
            align: 'center',
            render:(_, record) => {
                const dataRow = rex_data_form_edit_master_client_manual.find(dat => dat.id == record.id)
                return <div className='Table-Contain-Text-Global'>
                    {
                        dataRow
                        ? <Input
                            defaultValue={record.regional? record.regional : ''}
                            value={dataRow.data.regional}
                            size="small"
                            placeholder='Regional'
                            onChange={(e) => dispatch(EditValueMasterClientManual('regional', e.target.value, record.id))}
                        />
                        : record.regional? record.regional : ''
                    }
                </div>
            },
            ellipsis : true,
            width : 120
        },
        {
            title: 'Código channel',
            dataIndex: 'cod_channel',
            ...getColumnSearchProps('cod_channel', 'código channel'),
            sorter: (a, b) => a.cod_channel ? a.cod_channel.localeCompare(b.cod_channel) : false,
            showSorterTooltip: false,
            align: 'center',
            render:(_, record) => {
                const dataRow = rex_data_form_edit_master_client_manual.find(dat => dat.id == record.id)
                return <div className='Table-Contain-Text-Global'>
                    {
                        dataRow
                        ? <Input
                            defaultValue={record.cod_channel? record.cod_channel : ''}
                            value={dataRow.data.cod_channel}
                            size="small"
                            placeholder='Código channel'
                            onChange={(e) => dispatch(EditValueMasterClientManual('cod_channel', e.target.value, record.id))}
                        />
                        : record.cod_channel? record.cod_channel : ''
                    }
                </div>
            },
            ellipsis : true,
            width : 120
        },
        {
            title: 'Channel',
            dataIndex: 'channel',
            ...getColumnSearchProps('channel', 'channel'),
            sorter: (a, b) => a.channel ? a.channel.localeCompare(b.channel) : false,
            showSorterTooltip: false,
            align: 'center',
            render:(_, record) => {
                const dataRow = rex_data_form_edit_master_client_manual.find(dat => dat.id == record.id)
                return <div className='Table-Contain-Text-Global'>
                    {
                        dataRow
                        ? <Input
                            defaultValue={record.channel? record.channel : ''}
                            value={dataRow.data.channel}
                            size="small"
                            placeholder='Channel'
                            onChange={(e) => dispatch(EditValueMasterClientManual('channel', e.target.value, record.id))}
                        />
                        : record.channel? record.channel : ''
                    }
                </div>
            },
            ellipsis : true,
            width : 120
        },
        {
            title: 'Código sub channel',
            dataIndex: 'cod_sub_channel',
            ...getColumnSearchProps('cod_sub_channel', 'Código sub channel'),
            sorter: (a, b) => a.cod_sub_channel ? a.cod_sub_channel.localeCompare(b.cod_sub_channel) : false,
            showSorterTooltip: false,
            align: 'center',
            render:(_, record) => {
                const dataRow = rex_data_form_edit_master_client_manual.find(dat => dat.id == record.id)
                return <div className='Table-Contain-Text-Global'>
                    {
                        dataRow
                        ? <Input
                            defaultValue={record.cod_sub_channel? record.cod_sub_channel : ''}
                            value={dataRow.data.cod_sub_channel}
                            size="small"
                            placeholder='Código sub channel'
                            onChange={(e) => dispatch(EditValueMasterClientManual('cod_sub_channel', e.target.value, record.id))}
                        />
                        : record.cod_sub_channel? record.cod_sub_channel : ''
                    }
                </div>
            },
            ellipsis : true,
            width : 120
        },
        {
            title: 'Sub channel',
            dataIndex: 'sub_channel',
            ...getColumnSearchProps('sub_channel', 'sub channel'),
            sorter: (a, b) => a.sub_channel ? a.sub_channel.localeCompare(b.sub_channel) : false,
            showSorterTooltip: false,
            align: 'center',
            render:(_, record) => {
                const dataRow = rex_data_form_edit_master_client_manual.find(dat => dat.id == record.id)
                return <div className='Table-Contain-Text-Global'>
                    {
                        dataRow
                        ? <Input
                            defaultValue={record.sub_channel? record.sub_channel : ''}
                            value={dataRow.data.sub_channel}
                            size="small"
                            placeholder='Sub channel'
                            onChange={(e) => dispatch(EditValueMasterClientManual('sub_channel', e.target.value, record.id))}
                        />
                        : record.sub_channel? record.sub_channel : ''
                    }
                </div>
            },
            ellipsis : true,
            width : 120
        },
        {
            title: 'Código zone',
            dataIndex: 'cod_zone',
            ...getColumnSearchProps('cod_zone', 'cod zone'),
            sorter: (a, b) => a.cod_zone ? a.cod_zone.localeCompare(b.cod_zone) : false,
            showSorterTooltip: false,
            align: 'center',
            render:(_, record) => {
                const dataRow = rex_data_form_edit_master_client_manual.find(dat => dat.id == record.id)
                return <div className='Table-Contain-Text-Global'>
                    {
                        dataRow
                        ? <Input
                            defaultValue={record.cod_zone? record.cod_zone : ''}
                            value={dataRow.data.cod_zone}
                            size="small"
                            placeholder='Código zone'
                            onChange={(e) => dispatch(EditValueMasterClientManual('cod_zone', e.target.value, record.id))}
                        />
                        : record.cod_zone? record.cod_zone : ''
                    }
                </div>
            },
            ellipsis : true,
            width : 120
        },
        {
            title: 'Zone',
            dataIndex: 'zone',
            ...getColumnSearchProps('zone', 'zone'),
            sorter: (a, b) => a.zone ? a.zone.localeCompare(b.zone) : false,
            showSorterTooltip: false,
            align: 'center',
            render:(_, record) => {
                const dataRow = rex_data_form_edit_master_client_manual.find(dat => dat.id == record.id)
                return <div className='Table-Contain-Text-Global'>
                    {
                        dataRow
                        ? <Input
                            defaultValue={record.zone? record.zone : ''}
                            value={dataRow.data.zone}
                            size="small"
                            placeholder='Zone'
                            onChange={(e) => dispatch(EditValueMasterClientManual('zone', e.target.value, record.id))}
                        />
                        : record.zone? record.zone : ''
                    }
                </div>
            },
            ellipsis : true,
            width : 120
        },
        {
            title: 'Código territorio',
            dataIndex: 'cod_territory',
            ...getColumnSearchProps('cod_territory', 'código territorio'),
            sorter: (a, b) => a.cod_territory ? a.cod_territory.localeCompare(b.cod_territory) : false,
            showSorterTooltip: false,
            align: 'center',
            render:(_, record) => {
                const dataRow = rex_data_form_edit_master_client_manual.find(dat => dat.id == record.id)
                return <div className='Table-Contain-Text-Global'>
                    {
                        dataRow
                        ? <Input
                            defaultValue={record.cod_territory? record.cod_territory : ''}
                            value={dataRow.data.cod_territory}
                            size="small"
                            placeholder='Código territorio'
                            onChange={(e) => dispatch(EditValueMasterClientManual('cod_territory', e.target.value, record.id))}
                        />
                        : record.cod_territory? record.cod_territory : ''
                    }
                </div>
            },
            ellipsis : true,
            width : 120
        },
        {
            title: 'Territorio',
            dataIndex: 'territory',
            ...getColumnSearchProps('territory', 'territorio'),
            sorter: (a, b) => a.territory ? a.territory.localeCompare(b.territory) : false,
            showSorterTooltip: false,
            align: 'center',
            render:(_, record) => {
                const dataRow = rex_data_form_edit_master_client_manual.find(dat => dat.id == record.id)
                return <div className='Table-Contain-Text-Global'>
                    {
                        dataRow
                        ? <Input
                            defaultValue={record.territory? record.territory : ''}
                            value={dataRow.data.territory}
                            size="small"
                            placeholder='Territorio'
                            onChange={(e) => dispatch(EditValueMasterClientManual('territory', e.target.value, record.id))}
                        />
                        : record.territory? record.territory : ''
                    }
                </div>
            },
            ellipsis : true,
            width : 120
        },
        {
            title: 'Class 1',
            dataIndex: 'class_1',
            ...getColumnSearchProps('class_1', 'class 1'),
            sorter: (a, b) => a.class_1 ? a.class_1.localeCompare(b.class_1) : false,
            showSorterTooltip: false,
            align: 'center',
            render:(_, record) => {
                const dataRow = rex_data_form_edit_master_client_manual.find(dat => dat.id == record.id)
                return <div className='Table-Contain-Text-Global'>
                    {
                        dataRow
                        ? <Input
                            defaultValue={record.class_1? record.class_1 : ''}
                            value={dataRow.data.class_1}
                            size="small"
                            placeholder='Class 1'
                            onChange={(e) => dispatch(EditValueMasterClientManual('class_1', e.target.value, record.id))}
                        />
                        : record.class_1? record.class_1 : ''
                    }
                </div>
            },
            ellipsis : true,
            width : 120
        },
        {
            title: 'Class 2',
            dataIndex: 'class_2',
            ...getColumnSearchProps('class_2', 'class 2'),
            sorter: (a, b) => a.class_2 ? a.class_2.localeCompare(b.class_2) : false,
            showSorterTooltip: false,
            align: 'center',
            render:(_, record) => {
                const dataRow = rex_data_form_edit_master_client_manual.find(dat => dat.id == record.id)
                return <div className='Table-Contain-Text-Global'>
                    {
                        dataRow
                        ? <Input
                            defaultValue={record.class_2? record.class_2 : ''}
                            value={dataRow.data.class_2}
                            size="small"
                            placeholder='Class 2'
                            onChange={(e) => dispatch(EditValueMasterClientManual('class_2', e.target.value, record.id))}
                        />
                        : record.class_2? record.class_2 : ''
                    }
                </div>
            },
            ellipsis : true,
            width : 120
        },
        {
            title: 'Class 3',
            dataIndex: 'class_3',
            ...getColumnSearchProps('class_3', 'class 3'),
            sorter: (a, b) => a.class_3 ? a.class_3.localeCompare(b.class_3) : false,
            showSorterTooltip: false,
            align: 'center',
            render:(_, record) => {
                const dataRow = rex_data_form_edit_master_client_manual.find(dat => dat.id == record.id)
                return <div className='Table-Contain-Text-Global'>
                    {
                        dataRow
                        ? <Input
                            defaultValue={record.class_3? record.class_3 : ''}
                            value={dataRow.data.class_3}
                            size="small"
                            placeholder='Class 2'
                            onChange={(e) => dispatch(EditValueMasterClientManual('class_3', e.target.value, record.id))}
                        />
                        : record.class_3? record.class_3 : ''
                    }
                </div>
            },
            ellipsis : true,
            width : 120
        },
        {
            title: 'Class 4',
            dataIndex: 'class_4',
            ...getColumnSearchProps('class_4', 'class 4'),
            sorter: (a, b) => a.class_4 ? a.class_4.localeCompare(b.class_4) : false,
            showSorterTooltip: false,
            align: 'center',
            render:(_, record) => {
                const dataRow = rex_data_form_edit_master_client_manual.find(dat => dat.id == record.id)
                return <div className='Table-Contain-Text-Global'>
                    {
                        dataRow
                        ? <Input
                            defaultValue={record.class_4? record.class_4 : ''}
                            value={dataRow.data.class_4}
                            size="small"
                            placeholder='Class 4'
                            onChange={(e) => dispatch(EditValueMasterClientManual('class_4', e.target.value, record.id))}
                        />
                        : record.class_4? record.class_4 : ''
                    }
                </div>
            },
            ellipsis : true,
            width : 120
        },
        {
            title: 'Class 5',
            dataIndex: 'class_5',
            ...getColumnSearchProps('class_5', 'class 5'),
            sorter: (a, b) => a.class_5 ? a.class_5.localeCompare(b.class_5) : false,
            showSorterTooltip: false,
            align: 'center',
            render:(_, record) => {
                const dataRow = rex_data_form_edit_master_client_manual.find(dat => dat.id == record.id)
                return <div className='Table-Contain-Text-Global'>
                    {
                        dataRow
                        ? <Input
                            defaultValue={record.class_5? record.class_5 : ''}
                            value={dataRow.data.class_5}
                            size="small"
                            placeholder='Class 5'
                            onChange={(e) => dispatch(EditValueMasterClientManual('class_5', e.target.value, record.id))}
                        />
                        : record.class_5? record.class_5 : ''
                    }
                </div>
            },
            ellipsis : true,
            width : 120
        },
        {
            title: 'Class 6',
            dataIndex: 'class_6',
            ...getColumnSearchProps('class_6', 'class 6'),
            sorter: (a, b) => a.class_6 ? a.class_6.localeCompare(b.class_6) : false,
            showSorterTooltip: false,
            align: 'center',
            render:(_, record) => {
                const dataRow = rex_data_form_edit_master_client_manual.find(dat => dat.id == record.id)
                return <div className='Table-Contain-Text-Global'>
                    {
                        dataRow
                        ? <Input
                            defaultValue={record.class_6? record.class_6 : ''}
                            value={dataRow.data.class_6}
                            size="small"
                            placeholder='Class 6'
                            onChange={(e) => dispatch(EditValueMasterClientManual('class_6', e.target.value, record.id))}
                        />
                        : record.class_6? record.class_6 : ''
                    }
                </div>
            },
            ellipsis : true,
            width : 120
        },
        {
            title: 'Class 7',
            dataIndex: 'class_7',
            ...getColumnSearchProps('class_7', 'class 7'),
            sorter: (a, b) => a.class_7 ? a.class_7.localeCompare(b.class_7) : false,
            showSorterTooltip: false,
            align: 'center',
            render:(_, record) => {
                const dataRow = rex_data_form_edit_master_client_manual.find(dat => dat.id == record.id)
                return <div className='Table-Contain-Text-Global'>
                    {
                        dataRow
                        ? <Input
                            defaultValue={record.class_7? record.class_7 : ''}
                            value={dataRow.data.class_7}
                            size="small"
                            placeholder='Class 7'
                            onChange={(e) => dispatch(EditValueMasterClientManual('class_7', e.target.value, record.id))}
                        />
                        : record.class_7? record.class_7 : ''
                    }
                </div>
            },
            ellipsis : true,
            width : 120
        },
        {
            title: 'Class 8',
            dataIndex: 'class_8',
            ...getColumnSearchProps('class_8', 'class 8'),
            sorter: (a, b) => a.class_8 ? a.class_8.localeCompare(b.class_8) : false,
            showSorterTooltip: false,
            align: 'center',
            render:(_, record) => {
                const dataRow = rex_data_form_edit_master_client_manual.find(dat => dat.id == record.id)
                return <div className='Table-Contain-Text-Global'>
                    {
                        dataRow
                        ? <Input
                            defaultValue={record.class_8? record.class_8 : ''}
                            value={dataRow.data.class_8}
                            size="small"
                            placeholder='Class 8'
                            onChange={(e) => dispatch(EditValueMasterClientManual('class_8', e.target.value, record.id))}
                        />
                        : record.class_8? record.class_8 : ''
                    }
                </div>
            },
            ellipsis : true,
            width : 120
        },
        {
            title: 'Código regional manager',
            dataIndex: 'cod_regional_manager',
            ...getColumnSearchProps('cod_regional_manager', 'código regional manager'),
            sorter: (a, b) => a.cod_regional_manager ? a.cod_regional_manager.localeCompare(b.cod_regional_manager) : false,
            showSorterTooltip: false,
            align: 'center',
            render:(_, record) => {
                const dataRow = rex_data_form_edit_master_client_manual.find(dat => dat.id == record.id)
                return <div className='Table-Contain-Text-Global'>
                    {
                        dataRow
                        ? <Input
                            defaultValue={record.cod_regional_manager? record.cod_regional_manager : ''}
                            value={dataRow.data.cod_regional_manager}
                            size="small"
                            placeholder='Código regional manager'
                            onChange={(e) => dispatch(EditValueMasterClientManual('cod_regional_manager', e.target.value, record.id))}
                        />
                        : record.cod_regional_manager? record.cod_regional_manager : ''
                    }
                </div>
            },
            ellipsis : true,
            width : 120
        },
        {
            title: 'Regional manager',
            dataIndex: 'regional_manager',
            ...getColumnSearchProps('regional_manager', 'regional manager'),
            sorter: (a, b) => a.regional_manager ? a.regional_manager.localeCompare(b.regional_manager) : false,
            showSorterTooltip: false,
            align: 'center',
            render:(_, record) => {
                const dataRow = rex_data_form_edit_master_client_manual.find(dat => dat.id == record.id)
                return <div className='Table-Contain-Text-Global'>
                    {
                        dataRow
                        ? <Input
                            defaultValue={record.regional_manager? record.regional_manager : ''}
                            value={dataRow.data.regional_manager}
                            size="small"
                            placeholder='Regional manager'
                            onChange={(e) => dispatch(EditValueMasterClientManual('regional_manager', e.target.value, record.id))}
                        />
                        : record.regional_manager? record.regional_manager : ''
                    }
                </div>
            },
            ellipsis : true,
            width : 120
        },
        {
            title: 'Código channel manager',
            dataIndex: 'cod_channel_manager',
            ...getColumnSearchProps('cod_channel_manager', 'código channel manager'),
            sorter: (a, b) => a.cod_channel_manager ? a.cod_channel_manager.localeCompare(b.cod_channel_manager) : false,
            showSorterTooltip: false,
            align: 'center',
            render:(_, record) => {
                const dataRow = rex_data_form_edit_master_client_manual.find(dat => dat.id == record.id)
                return <div className='Table-Contain-Text-Global'>
                    {
                        dataRow
                        ? <Input
                            defaultValue={record.cod_channel_manager? record.cod_channel_manager : ''}
                            value={dataRow.data.cod_channel_manager}
                            size="small"
                            placeholder='Código channel manager'
                            onChange={(e) => dispatch(EditValueMasterClientManual('cod_channel_manager', e.target.value, record.id))}
                        />
                        : record.cod_channel_manager? record.cod_channel_manager : ''
                    }
                </div>
            },
            ellipsis : true,
            width : 120
        },
        {
            title: 'Channel manager',
            dataIndex: 'channel_manager',
            ...getColumnSearchProps('channel_manager', 'channel manager'),
            sorter: (a, b) => a.channel_manager ? a.channel_manager.localeCompare(b.channel_manager) : false,
            showSorterTooltip: false,
            align: 'center',
            render:(_, record) => {
                const dataRow = rex_data_form_edit_master_client_manual.find(dat => dat.id == record.id)
                return <div className='Table-Contain-Text-Global'>
                    {
                        dataRow
                        ? <Input
                            defaultValue={record.channel_manager? record.channel_manager : ''}
                            value={dataRow.data.channel_manager}
                            size="small"
                            placeholder='Channel manager'
                            onChange={(e) => dispatch(EditValueMasterClientManual('channel_manager', e.target.value, record.id))}
                        />
                        : record.channel_manager? record.channel_manager : ''
                    }
                </div>
            },
            ellipsis : true,
            width : 120
        },
        {
            title: 'Código zone att',
            dataIndex: 'cod_zone_att',
            ...getColumnSearchProps('cod_zone_att', 'código zone att'),
            sorter: (a, b) => a.cod_zone_att ? a.cod_zone_att.localeCompare(b.cod_zone_att) : false,
            showSorterTooltip: false,
            align: 'center',
            render:(_, record) => {
                const dataRow = rex_data_form_edit_master_client_manual.find(dat => dat.id == record.id)
                return <div className='Table-Contain-Text-Global'>
                    {
                        dataRow
                        ? <Input
                            defaultValue={record.cod_zone_att? record.cod_zone_att : ''}
                            value={dataRow.data.cod_zone_att}
                            size="small"
                            placeholder='Código zone att'
                            onChange={(e) => dispatch(EditValueMasterClientManual('cod_zone_att', e.target.value, record.id))}
                        />
                        : record.cod_zone_att? record.cod_zone_att : ''
                    }
                </div>
            },
            ellipsis : true,
            width : 120
        },
        {
            title: 'Código zone manager',
            dataIndex: 'cod_zone_manager',
            ...getColumnSearchProps('cod_zone_manager', 'código zone manager'),
            sorter: (a, b) => a.cod_zone_manager ? a.cod_zone_manager.localeCompare(b.cod_zone_manager) : false,
            showSorterTooltip: false,
            align: 'center',
            render:(_, record) => {
                const dataRow = rex_data_form_edit_master_client_manual.find(dat => dat.id == record.id)
                return <div className='Table-Contain-Text-Global'>
                    {
                        dataRow
                        ? <Input
                            defaultValue={record.cod_zone_manager? record.cod_zone_manager : ''}
                            value={dataRow.data.cod_zone_manager}
                            size="small"
                            placeholder='Código zone manager'
                            onChange={(e) => dispatch(EditValueMasterClientManual('cod_zone_manager', e.target.value, record.id))}
                        />
                        : record.cod_zone_manager? record.cod_zone_manager : ''
                    }
                </div>
            },
            ellipsis : true,
            width : 120
        },
        {
            title: 'Zone manager',
            dataIndex: 'zone_manager',
            ...getColumnSearchProps('zone_manager', 'zone manager'),
            sorter: (a, b) => a.zone_manager ? a.zone_manager.localeCompare(b.zone_manager) : false,
            showSorterTooltip: false,
            align: 'center',
            render:(_, record) => {
                const dataRow = rex_data_form_edit_master_client_manual.find(dat => dat.id == record.id)
                return <div className='Table-Contain-Text-Global'>
                    {
                        dataRow
                        ? <Input
                            defaultValue={record.zone_manager? record.zone_manager : ''}
                            value={dataRow.data.zone_manager}
                            size="small"
                            placeholder='Zone manager'
                            onChange={(e) => dispatch(EditValueMasterClientManual('zone_manager', e.target.value, record.id))}
                        />
                        : record.zone_manager? record.zone_manager : ''
                    }
                </div>
            },
            ellipsis : true,
            width : 120
        },
        {
            title: 'Código territory manager',
            dataIndex: 'cod_territory_manager',
            ...getColumnSearchProps('cod_territory_manager', 'cod territory manager'),
            sorter: (a, b) => a.cod_territory_manager ? a.cod_territory_manager.localeCompare(b.cod_territory_manager) : false,
            showSorterTooltip: false,
            align: 'center',
            render:(_, record) => {
                const dataRow = rex_data_form_edit_master_client_manual.find(dat => dat.id == record.id)
                return <div className='Table-Contain-Text-Global'>
                    {
                        dataRow
                        ? <Input
                            defaultValue={record.cod_territory_manager? record.cod_territory_manager : ''}
                            value={dataRow.data.cod_territory_manager}
                            size="small"
                            placeholder='Código territory manager'
                            onChange={(e) => dispatch(EditValueMasterClientManual('cod_territory_manager', e.target.value, record.id))}
                        />
                        : record.cod_territory_manager? record.cod_territory_manager : ''
                    }
                </div>
            },
            ellipsis : true,
            width : 120
        },
        {
            title: 'Territory manager',
            dataIndex: 'territory_manager',
            ...getColumnSearchProps('territory_manager', 'territory manager'),
            sorter: (a, b) => a.territory_manager ? a.territory_manager.localeCompare(b.territory_manager) : false,
            showSorterTooltip: false,
            align: 'center',
            render:(_, record) => {
                const dataRow = rex_data_form_edit_master_client_manual.find(dat => dat.id == record.id)
                return <div className='Table-Contain-Text-Global'>
                    {
                        dataRow
                        ? <Input
                            defaultValue={record.territory_manager? record.territory_manager : ''}
                            value={dataRow.data.territory_manager}
                            size="small"
                            placeholder='Territory manager'
                            onChange={(e) => dispatch(EditValueMasterClientManual('territory_manager', e.target.value, record.id))}
                        />
                        : record.territory_manager? record.territory_manager : ''
                    }
                </div>
            },
            ellipsis : true,
            width : 120
        },
        {
            title: 'Código SI executive',
            dataIndex: 'cod_si_executive',
            ...getColumnSearchProps('cod_si_executive', 'Código SI executive'),
            sorter: (a, b) => a.cod_si_executive ? a.cod_si_executive.localeCompare(b.cod_si_executive) : false,
            showSorterTooltip: false,
            align: 'center',
            render:(_, record) => {
                const dataRow = rex_data_form_edit_master_client_manual.find(dat => dat.id == record.id)
                return <div className='Table-Contain-Text-Global'>
                    {
                        dataRow
                        ? <Input
                            defaultValue={record.cod_si_executive? record.cod_si_executive : ''}
                            value={dataRow.data.cod_si_executive}
                            size="small"
                            placeholder='Código SI executive'
                            onChange={(e) => dispatch(EditValueMasterClientManual('cod_si_executive', e.target.value, record.id))}
                        />
                        : record.cod_si_executive? record.cod_si_executive : ''
                    }
                </div>
            },
            ellipsis : true,
            width : 120
        },
        {
            title: 'SI executive',
            dataIndex: 'si_executive',
            ...getColumnSearchProps('si_executive', 'si executive'),
            sorter: (a, b) => a.si_executive ? a.si_executive.localeCompare(b.si_executive) : false,
            showSorterTooltip: false,
            align: 'center',
            render:(_, record) => {
                const dataRow = rex_data_form_edit_master_client_manual.find(dat => dat.id == record.id)
                return <div className='Table-Contain-Text-Global'>
                    {
                        dataRow
                        ? <Input
                            defaultValue={record.si_executive? record.si_executive : ''}
                            value={dataRow.data.si_executive}
                            size="small"
                            placeholder='SI executive'
                            onChange={(e) => dispatch(EditValueMasterClientManual('si_executive', e.target.value, record.id))}
                        />
                        : record.si_executive? record.si_executive : ''
                    }
                </div>
            },
            ellipsis : true,
            width : 120
        },
        {
            title: 'Código SO executor',
            dataIndex: 'cod_so_executor',
            ...getColumnSearchProps('cod_so_executor', 'código so executor'),
            sorter: (a, b) => a.cod_so_executor ? a.cod_so_executor.localeCompare(b.cod_so_executor) : false,
            showSorterTooltip: false,
            align: 'center',
            render:(_, record) => {
                const dataRow = rex_data_form_edit_master_client_manual.find(dat => dat.id == record.id)
                return <div className='Table-Contain-Text-Global'>
                    {
                        dataRow
                        ? <Input
                            defaultValue={record.cod_so_executor? record.cod_so_executor : ''}
                            value={dataRow.data.cod_so_executor}
                            size="small"
                            placeholder='Código SO executor'
                            onChange={(e) => dispatch(EditValueMasterClientManual('cod_so_executor', e.target.value, record.id))}
                        />
                        : record.cod_so_executor? record.cod_so_executor : ''
                    }
                </div>
            },
            ellipsis : true,
            width : 120
        },
        {
            title: 'SO executor',
            dataIndex: 'so_executor',
            ...getColumnSearchProps('so_executor', 'so executor'),
            sorter: (a, b) => a.so_executor ? a.so_executor.localeCompare(b.so_executor) : false,
            showSorterTooltip: false,
            align: 'center',
            render:(_, record) => {
                const dataRow = rex_data_form_edit_master_client_manual.find(dat => dat.id == record.id)
                return <div className='Table-Contain-Text-Global'>
                    {
                        dataRow
                        ? <Input
                            defaultValue={record.so_executor? record.so_executor : ''}
                            value={dataRow.data.so_executor}
                            size="small"
                            placeholder='SO executor'
                            onChange={(e) => dispatch(EditValueMasterClientManual('so_executor', e.target.value, record.id))}
                        />
                        : record.so_executor? record.so_executor : ''
                    }
                </div>
            },
            ellipsis : true,
            width : 120
        },
        {
            title: 'Class 9',
            dataIndex: 'class_9',
            ...getColumnSearchProps('class_9', 'class 9'),
            sorter: (a, b) => a.class_9 ? a.class_9.localeCompare(b.class_9) : false,
            showSorterTooltip: false,
            align: 'center',
            render:(_, record) => {
                const dataRow = rex_data_form_edit_master_client_manual.find(dat => dat.id == record.id)
                return <div className='Table-Contain-Text-Global'>
                    {
                        dataRow
                        ? <Input
                            defaultValue={record.class_9? record.class_9 : ''}
                            value={dataRow.data.class_9}
                            size="small"
                            placeholder='Class 9'
                            onChange={(e) => dispatch(EditValueMasterClientManual('class_9', e.target.value, record.id))}
                        />
                        : record.class_9? record.class_9 : ''
                    }
                </div>
            },
            ellipsis : true,
            width : 120
        },
        {
            title: 'Class 10',
            dataIndex: 'class_10',
            ...getColumnSearchProps('class_10', 'class 10'),
            sorter: (a, b) => a.class_10 ? a.class_10.localeCompare(b.class_10) : false,
            showSorterTooltip: false,
            align: 'center',
            render:(_, record) => {
                const dataRow = rex_data_form_edit_master_client_manual.find(dat => dat.id == record.id)
                return <div className='Table-Contain-Text-Global'>
                    {
                        dataRow
                        ? <Input
                            defaultValue={record.class_10? record.class_10 : ''}
                            value={dataRow.data.class_10}
                            size="small"
                            placeholder='Class 10'
                            onChange={(e) => dispatch(EditValueMasterClientManual('class_10', e.target.value, record.id))}
                        />
                        : record.class_10? record.class_10 : ''
                    }
                </div>
            },
            ellipsis : true,
            width : 120
        },
        {
            title: 'Class 11',
            dataIndex: 'class_11',
            ...getColumnSearchProps('class_11', 'class 11'),
            sorter: (a, b) => a.class_11 ? a.class_11.localeCompare(b.class_11) : false,
            showSorterTooltip: false,
            align: 'center',
            render:(_, record) => {
                const dataRow = rex_data_form_edit_master_client_manual.find(dat => dat.id == record.id)
                return <div className='Table-Contain-Text-Global'>
                    {
                        dataRow
                        ? <Input
                            defaultValue={record.class_11? record.class_11 : ''}
                            value={dataRow.data.class_11}
                            size="small"
                            placeholder='Class 11'
                            onChange={(e) => dispatch(EditValueMasterClientManual('class_11', e.target.value, record.id))}
                        />
                        : record.class_11? record.class_11 : ''
                    }
                </div>
            },
            ellipsis : true,
            width : 120
        },
        {
            title: 'MHL código country',
            dataIndex: 'hml_cod_country',
            ...getColumnSearchProps('hml_cod_country', 'hml cod country'),
            sorter: (a, b) => a.hml_cod_country ? a.hml_cod_country.localeCompare(b.hml_cod_country) : false,
            showSorterTooltip: false,
            align: 'center',
            render:(_, record) => {
                const dataRow = rex_data_form_edit_master_client_manual.find(dat => dat.id == record.id)
                return <div className='Table-Contain-Text-Global'>
                    {
                        dataRow
                        ? <Input
                            defaultValue={record.hml_cod_country? record.hml_cod_country : ''}
                            value={dataRow.data.hml_cod_country}
                            size="small"
                            placeholder='MHL código country'
                            onChange={(e) => dispatch(EditValueMasterClientManual('hml_cod_country', e.target.value, record.id))}
                        />
                        : record.hml_cod_country? record.hml_cod_country : ''
                    }
                </div>
            },
            ellipsis : true,
            width : 120
        },
        {
            title: 'HML country',
            dataIndex: 'hml_country',
            ...getColumnSearchProps('hml_country', 'hml country'),
            sorter: (a, b) => a.hml_country ? a.hml_country.localeCompare(b.hml_country) : false,
            showSorterTooltip: false,
            align: 'center',
            render:(_, record) => {
                const dataRow = rex_data_form_edit_master_client_manual.find(dat => dat.id == record.id)
                return <div className='Table-Contain-Text-Global'>
                    {
                        dataRow
                        ? <Input
                            defaultValue={record.hml_country? record.hml_country : ''}
                            value={dataRow.data.hml_country}
                            size="small"
                            placeholder='HML country'
                            onChange={(e) => dispatch(EditValueMasterClientManual('hml_country', e.target.value, record.id))}
                        />
                        : record.hml_country? record.hml_country : ''
                    }
                </div>
            },
            ellipsis : true,
            width : 120
        },
        {
            title: 'HML código departamento',
            dataIndex: 'hml_cod_departament',
            ...getColumnSearchProps('hml_cod_departament', 'hml código departamento'),
            sorter: (a, b) => a.hml_cod_departament ? a.hml_cod_departament.localeCompare(b.hml_cod_departament) : false,
            showSorterTooltip: false,
            align: 'center',
            render:(_, record) => {
                const dataRow = rex_data_form_edit_master_client_manual.find(dat => dat.id == record.id)
                return <div className='Table-Contain-Text-Global'>
                    {
                        dataRow
                        ? <Input
                            defaultValue={record.hml_cod_departament? record.hml_cod_departament : ''}
                            value={dataRow.data.hml_cod_departament}
                            size="small"
                            placeholder='HML código departamento'
                            onChange={(e) => dispatch(EditValueMasterClientManual('hml_cod_departament', e.target.value, record.id))}
                        />
                        : record.hml_cod_departament? record.hml_cod_departament : ''
                    }
                </div>
            },
            ellipsis : true,
            width : 120
        },
        {
            title: 'HML departamento',
            dataIndex: 'hml_departament',
            ...getColumnSearchProps('hml_departament', 'hml departamento'),
            sorter: (a, b) => a.hml_departament ? a.hml_departament.localeCompare(b.hml_departament) : false,
            showSorterTooltip: false,
            align: 'center',
            render:(_, record) => {
                const dataRow = rex_data_form_edit_master_client_manual.find(dat => dat.id == record.id)
                return <div className='Table-Contain-Text-Global'>
                    {
                        dataRow
                        ? <Input
                            defaultValue={record.hml_departament? record.hml_departament : ''}
                            value={dataRow.data.hml_departament}
                            size="small"
                            placeholder='HML departamento'
                            onChange={(e) => dispatch(EditValueMasterClientManual('hml_departament', e.target.value, record.id))}
                        />
                        : record.hml_departament? record.hml_departament : ''
                    }
                </div>
            },
            ellipsis : true,
            width : 120
        },
        {
            title: 'HL código provincia',
            dataIndex: 'hml_cod_province',
            ...getColumnSearchProps('hml_cod_province', 'bussiness'),
            sorter: (a, b) => a.hml_cod_province ? a.hml_cod_province.localeCompare(b.hml_cod_province) : false,
            showSorterTooltip: false,
            align: 'center',
            render:(_, record) => {
                const dataRow = rex_data_form_edit_master_client_manual.find(dat => dat.id == record.id)
                return <div className='Table-Contain-Text-Global'>
                    {
                        dataRow
                        ? <Input
                            defaultValue={record.hml_cod_province? record.hml_cod_province : ''}
                            value={dataRow.data.hml_cod_province}
                            size="small"
                            placeholder='HL código provincia'
                            onChange={(e) => dispatch(EditValueMasterClientManual('hml_cod_province', e.target.value, record.id))}
                        />
                        : record.hml_cod_province? record.hml_cod_province : ''
                    }
                </div>
            },
            ellipsis : true,
            width : 120
        },
        {
            title: 'HML provincia',
            dataIndex: 'hml_province',
            ...getColumnSearchProps('hml_province', 'hml provincia'),
            sorter: (a, b) => a.hml_province ? a.hml_province.localeCompare(b.hml_province) : false,
            showSorterTooltip: false,
            align: 'center',
            render:(_, record) => {
                const dataRow = rex_data_form_edit_master_client_manual.find(dat => dat.id == record.id)
                return <div className='Table-Contain-Text-Global'>
                    {
                        dataRow
                        ? <Input
                            defaultValue={record.hml_province? record.hml_province : ''}
                            value={dataRow.data.hml_province}
                            size="small"
                            placeholder='HML provincia'
                            onChange={(e) => dispatch(EditValueMasterClientManual('hml_province', e.target.value, record.id))}
                        />
                        : record.hml_province? record.hml_province : ''
                    }
                </div>
            },
            ellipsis : true,
            width : 120
        },
        {
            title: 'HML código distrito',
            dataIndex: 'hml_cod_district',
            ...getColumnSearchProps('hml_cod_district', 'hml_cod_district'),
            sorter: (a, b) => a.hml_cod_district ? a.hml_cod_district.localeCompare(b.hml_cod_district) : false,
            showSorterTooltip: false,
            align: 'center',
            render:(_, record) => {
                const dataRow = rex_data_form_edit_master_client_manual.find(dat => dat.id == record.id)
                return <div className='Table-Contain-Text-Global'>
                    {
                        dataRow
                        ? <Input
                            defaultValue={record.hml_cod_district? record.hml_cod_district : ''}
                            value={dataRow.data.hml_cod_district}
                            size="small"
                            placeholder='HML código distrito'
                            onChange={(e) => dispatch(EditValueMasterClientManual('hml_cod_district', e.target.value, record.id))}
                        />
                        : record.hml_cod_district? record.hml_cod_district : ''
                    }
                </div>
            },
            ellipsis : true,
            width : 120
        },
        {
            title: 'HML distrito',
            dataIndex: 'hml_district',
            ...getColumnSearchProps('hml_district', 'hml district'),
            sorter: (a, b) => a.hml_district ? a.hml_district.localeCompare(b.hml_district) : false,
            showSorterTooltip: false,
            align: 'center',
            render:(_, record) => {
                const dataRow = rex_data_form_edit_master_client_manual.find(dat => dat.id == record.id)
                return <div className='Table-Contain-Text-Global'>
                    {
                        dataRow
                        ? <Input
                            defaultValue={record.hml_district? record.hml_district : ''}
                            value={dataRow.data.hml_district}
                            size="small"
                            placeholder='HML distrito'
                            onChange={(e) => dispatch(EditValueMasterClientManual('hml_district', e.target.value, record.id))}
                        />
                        : record.hml_district? record.hml_district : ''
                    }
                </div>
            },
            ellipsis : true,
            width : 120
        },
        {
            title: 'HML address',
            dataIndex: 'hml_address',
            ...getColumnSearchProps('hml_address', 'hml address'),
            sorter: (a, b) => a.hml_address ? a.hml_address.localeCompare(b.hml_address) : false,
            showSorterTooltip: false,
            align: 'center',
            render:(_, record) => {
                const dataRow = rex_data_form_edit_master_client_manual.find(dat => dat.id == record.id)
                return <div className='Table-Contain-Text-Global'>
                    {
                        dataRow
                        ? <Input
                            defaultValue={record.hml_address? record.hml_address : ''}
                            value={dataRow.data.hml_address}
                            size="small"
                            placeholder='HML address'
                            onChange={(e) => dispatch(EditValueMasterClientManual('hml_address', e.target.value, record.id))}
                        />
                        : record.hml_address? record.hml_address : ''
                    }
                </div>
            },
            ellipsis : true,
            width : 120
        },
        {
            title: 'Latitud',
            dataIndex: 'latitude',
            ...getColumnSearchProps('latitude', 'latitud'),
            sorter: (a, b) => a.latitude ? a.latitude - b.latitude : false,
            showSorterTooltip: false,
            align: 'center',
            render:(_, record) => {
                const dataRow = rex_data_form_edit_master_client_manual.find(dat => dat.id == record.id)
                return <div className='Table-Contain-Text-Global'>
                    {
                        dataRow
                        ? <InputNumber
                            defaultValue={record.latitude? record.latitude : ''}
                            value={dataRow.data.latitude}
                            min="0"
                            max="9999999999"
                            step="0.01"
                            size="small"
                            placeholder='Latitud'
                            onChange={(value) => dispatch(EditValueMasterClientManual('latitude', value, record.id))}
                        />
                        : isNaN(record.latitude) || !record.latitude ? '-' : parseNumber(record.latitude)
                    }
                </div>
            },
            ellipsis : true,
            width : 120
        },
        {
            title: 'Longitud',
            dataIndex: 'longitude',
            ...getColumnSearchProps('longitude', 'longitud'),
            sorter: (a, b) => a.longitude ? a.longitude - b.longitude : false,
            showSorterTooltip: false,
            align: 'center',
            render:(_, record) => {
                const dataRow = rex_data_form_edit_master_client_manual.find(dat => dat.id == record.id)
                return <div className='Table-Contain-Text-Global'>
                    {
                        dataRow
                        ? <InputNumber
                            defaultValue={record.longitude? record.longitude : ''}
                            value={dataRow.data.longitude}
                            min="0"
                            max="9999999999"
                            step="0.01"
                            size="small"
                            placeholder='Longitud'
                            onChange={(value) => dispatch(EditValueMasterClientManual('longitude', value, record.id))}
                        />
                        : isNaN(record.longitude) || !record.longitude ? '-' : parseNumber(record.longitude)
                    }
                </div>
            },
            ellipsis : true,
            width : 120
        },
        {
            title: 'Status',
            dataIndex: 'status',
            ...getColumnSearchProps('status', 'status'),
            sorter: (a, b) => a.status ? a.status.localeCompare(b.status) : false,
            showSorterTooltip: false,
            align: 'center',
            render:(_, record) => {
                const dataRow = rex_data_form_edit_master_client_manual.find(dat => dat.id == record.id)
                return <div className='Table-Contain-Text-Global'>
                    {
                        dataRow
                        ? <Input
                            defaultValue={record.status? record.status : ''}
                            value={dataRow.data.status}
                            size="small"
                            placeholder='Status'
                            onChange={(e) => dispatch(EditValueMasterClientManual('status', e.target.value, record.id))}
                        />
                        : record.status? record.status : ''
                    }
                </div>
            },
            ellipsis : true,
            width : 120
        },
        {
            title: 'Robot',
            dataIndex: 'robot',
            ...getColumnSearchProps('robot', 'robot'),
            sorter: (a, b) => a.robot ? a.robot.localeCompare(b.robot) : false,
            showSorterTooltip: false,
            align: 'center',
            render:(_, record) => {
                const dataRow = rex_data_form_edit_master_client_manual.find(dat => dat.id == record.id)
                return <div className='Table-Contain-Text-Global'>
                    {
                        dataRow
                        ? <Input
                            defaultValue={record.robot? record.robot : ''}
                            value={dataRow.data.robot}
                            size="small"
                            placeholder='Robot'
                            onChange={(e) => dispatch(EditValueMasterClientManual('robot', e.target.value, record.id))}
                        />
                        : record.robot? record.robot : ''
                    }
                </div>
            },
            ellipsis : true,
            width : 120
        },
        {
            title: 'Class 12',
            dataIndex: 'class_12',
            ...getColumnSearchProps('class_12', 'class 12'),
            sorter: (a, b) => a.class_12 ? a.class_12.localeCompare(b.class_12) : false,
            showSorterTooltip: false,
            align: 'center',
            render:(_, record) => {
                const dataRow = rex_data_form_edit_master_client_manual.find(dat => dat.id == record.id)
                return <div className='Table-Contain-Text-Global'>
                    {
                        dataRow
                        ? <Input
                            defaultValue={record.class_12? record.class_12 : ''}
                            value={dataRow.data.class_12}
                            size="small"
                            placeholder='Class 12'
                            onChange={(e) => dispatch(EditValueMasterClientManual('class_12', e.target.value, record.id))}
                        />
                        : record.class_12? record.class_12 : ''
                    }
                </div>
            },
            ellipsis : true,
            width : 120
        },
        {
            title: 'Usuario',
            dataIndex: 'usuusuario',
            ...getColumnSearchProps('usucorreo', 'usuario'),
            sorter: (a, b) => a.usucorreo ? a.usucorreo.localeCompare(b.usucorreo) : false,
            showSorterTooltip: false,
            align: 'center',
            render:(_, record) => (
                <div className='Table-Contain-Text-Global'>
                    {record.usucorreo}
                </div>
            ),
            width : 120,
            ellipsis: true,
        },
        {
            title: 'Fecha de actualización',
            dataIndex: 'updated_at',
            ...getColumnSearchProps('updated_at', 'fecha de actualización'),
            sorter: (a, b) => a.updated_at ? a.updated_at.localeCompare(b.updated_at) : false,
            showSorterTooltip: false,
            align: 'center',
            render:(_, record) => (
                <div className='Table-Contain-Text-Global'>
                    {record.updated_at ? moment(record.updated_at).format('DD/MM/YYYY h:mm a') : ''}
                </div>
            ),
            width : 180,
            ellipsis: true,
        },
        {
            title: 'Acciones',
            dataIndex: 'acciones',
            align: 'center',
            render:(_, record) => {

                const EditingRow = rex_data_form_edit_master_client_manual.find(dat => dat.id == record.id)

                return <div style={{display:"flex", justifyContent:"center", columnGap:"10px", alignItems:"center"}}>

                    {
                        EditingRow
                        ? loadingEdit.status
                            ?   loadingEdit.id == record.id
                                ? <LoadingOutlined style={{color: "#2AD295"}}/>
                                : <div className="Actions-Table-Products-Editing">
                                    <Skeleton.Avatar active={true} size="small"/><Skeleton.Avatar active={true} size="small" />
                                </div>

                            : <div className="Actions-Table-Products-Editing">
                                <Tooltip
                                    placement='left'
                                    title="Confirmar"
                                    color='#3B72FF'
                                >
                                    <div onClick={() => editRow(record.id)}>
                                        <CheckCircleOutlined style={{ color:'#008000', fontSize:"18px"}}/>
                                    </div>
                                </Tooltip>
                                <Tooltip
                                    placement='right'
                                    title="Confirmar"
                                    color='#3B72FF'
                                >
                                    <div onClick={() => dispatch(EditRowMasterClientManualManageReducer(record.id, false))}>
                                        <CloseCircleOutlined style={{ color:'red', fontSize:"18px"}}/>
                                    </div>
                                </Tooltip>
                            </div>
                        : <>
                            <Tooltip
                                placement='right'
                                title="Editar"
                                color='#3B72FF'
                            >
                                <div 
                                    style={{display:"flex",alignItems:"center"}} 
                                    onClick={() => dispatch(EditRowMasterClientManualManageReducer(record.id, true))}
                                >
                                    <EditOptionButtonGlobal/>
                                </div>
                            </Tooltip>
                            <Tooltip
                                placement='right'
                                title="Editar"
                                color='#3B72FF'
                            >
                                <Popconfirm
                                    overlayClassName='PopOver-Confirm-Delete-Uploaded-Files'
                                    title='Eliminar cliente'
                                    description='¿Está seguro de eliminar el cliente?'
                                    okText='Confirmar'
                                    cancelText='Cancelar'
                                    placement="bottomRight"
                                    onConfirm={() => {
                                        dispatch(DeleteMasterClientManageReducer([record.id], dateClients, dateFilterManual))
                                    }}
                                >
                                    <div style={{display:"flex",alignItems:"center"}}>
                                        <TrashButtonGlobalSimpleColor/>
                                    </div>
                                </Popconfirm>
                            </Tooltip>
                        </>
                    }
                </div>
            },
            width: 120,
            fixed: 'right'
        },
    ]

    return (
        <>
            <TableGlobal
                data={rex_data_master_clients_manuals_manage}
                columns={columns}
                showSaveProducts={true}
                buttonSaveProductsDisable={buttonSaveProductsDisable}
                date={dateFilterManual}
                setDate={setDateFilterManual}
                actionButtonSave={() => dispatch(SaveMasterClientsReducer(dateFilterManual))}
                loading_data={rex_loading_master_clients_manuals_manage}
                valueCheckbox={valueCheckbox}
                showSaveButton={true}
                dataDownload={{route : "pe/clients", nameFile : "Maestra de Clientes"}}
                showFilterDate={true}
                showDownloadProducts={true}
                showNewButton={true}
                pathNewButton="/create/master-client"
                functionDelete={(values, resetValues) => deleteMasterClients(values, resetValues)}
                setValueCheckbox={setValueCheckbox}
                pageTableGlobal={pageTableGlobal}
                selectedRowKeys={selectedRowKeys}
                componentExtraLeftPagination={componentExtraLeftPagination}
                setSelectedRowKeys={setSelectedRowKeys}
                selectRows={true}
                selectRowsTable={true}
                pageSizeTableGlobal={pageSizeTableGlobal}
                setPageTableGlobal={setPageTableGlobal}
                setPageSizeTableGlobal={setPageSizeTableGlobal}
                showTrash={true}
                showDownload={false}
                componentFilterExtra={filterChannelComponent()}
                functionFilterDate = {(date, dateString) => {
                    setDateFilterManual(dateString)
                    dispatch(GetMasterClientsManualsManageReducer(dateString))
                }}
            />
        </>
    )
}

export default MasterClientsManuals