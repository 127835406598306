import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Modal, Typography, Row, Col } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { ShowNotificationEditedUserReducer } from '../../Redux/Actions/Users/Users'
import { NOTIFICATION_EDITED_USER_MSG } from '../../Constants/Users/Users'

const NotificationEdited = () => {

    const { Title } = Typography
    const navigate = useNavigate()

    const dispatch = useDispatch()

    const {
        rex_notification_edited_user,
        rex_notification_edited_user_msg
    } = useSelector(({users}) => users);

    const handleCancel = () => {
        dispatch(ShowNotificationEditedUserReducer(false))

        dispatch({
            type : NOTIFICATION_EDITED_USER_MSG,
            payload : {}
        })

        if(rex_notification_edited_user_msg.response){
            navigate('/administrator/users')
        }
    }

    return (
        <Modal 
            title={null}
            centered={true}
            open={rex_notification_edited_user}
            closable={false}
            width={264}
            footer={null}
            zIndex={100000000}
            className='Modal-Notification-User-Created'
        >
            <Row    className={`${rex_notification_edited_user_msg.response ?"Modal-Container-Text-User-Created" :"Modal-Container-Text-User-Created-Alert"}`}>
                <Col span={24} className='Modal-Container-Text-Main'>
                    <Title>
                    {rex_notification_edited_user_msg.response ? '¡Listo!' : 'Alerta!'}
                    </Title>
                </Col>
                <Col span={24} className='Modal-Container-Text-Description'>
                    <p>{rex_notification_edited_user_msg.message}</p>
                </Col>
                <Col span={24}>
                    <div
                        onClick={() => handleCancel()}
                        className={`Notification-Button-User ${rex_notification_edited_user_msg.response ?"Notification-Button-User-Created" :"Notification-Button-User-Created-Alert"}`}
                    >
                        Ok
                    </div>
                </Col>
            </Row>
        </Modal>
    )
}

export default NotificationEdited