import React from 'react'
import { Typography } from 'antd'

const ItemTermsConditions = ({titleItem, textItem}) => {
    const { Title } = Typography
    return(
        <>
        <Title
            level={3}
            className="Title-Item-Terms-Conditions"
        >
            {titleItem}
        </Title>
        <p className="Text-Item-Terms-Conditions">{textItem}</p>
        </>
    )
}

export default ItemTermsConditions
