import { 
    REGISTER_USERS_CONNECTED_MODULES_PROFILE
} from "../../../Constants/Profile/Profile";

const INIT_STATE = {
    rex_users_profile_connected : []
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {

        case REGISTER_USERS_CONNECTED_MODULES_PROFILE: {
            return {
                ...state,
                rex_users_profile_connected : action.payload,
            }
        }
        default:
            return state;
    }
}