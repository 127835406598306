import React, { useEffect, useState, useRef } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { GetDataModulesHomeReducer, RegisterUsersConnectedHomeReducer } from '../../Redux/Actions/Home/Home'
import { Row, Col, Typography, Card, Tooltip } from 'antd'
import { ArrowRightOutlined } from '@ant-design/icons'
import { Element } from 'react-scroll'
import { Waypoint } from 'react-waypoint'
import WOW from 'wowjs'
import CardHome from '../../Components/Home/CardHome'
import CardBenefitHome from '../../Components/Home/CardBenefitHome'
import BarraHome from '../../Components/Home/BarraHome'
import ButtonFloat from '../../Components/Global/ButtonFloat'
import icono1 from '../../Assets/Images/Home/iconoBeneficio1.png'
import icono2 from '../../Assets/Images/Home/iconoBeneficio2.png'
import icono3 from '../../Assets/Images/Home/iconoBeneficio3.png'
import inteligenciaartificial from '../../Assets/Images/Home/logoGrow.png'
// import inteligenciaartificial from '../../Assets/Images/Home/inteligenciaartificial.avif'
import videohome from '../../Assets/Images/Home/Videohomev5.mp4'
import SideMenu from '../../Components/Global/SideMenu'
import { useIdleTimer } from 'react-idle-timer'
import socket from '../../App/socket'

const Home = () => {
    const { Title } = Typography

    const dispatch = useDispatch()
    const timeout = 300000

    const ref_redirect = useRef(null)

    const [someMouseOver, setSomeMouseOver] = useState(4)
    const [scrollMove, setScrollMove] = useState("Bottom")
    const [lastScrollMove, setLastScrollMove] = useState(false)
    const [backgroundColor, setBackgroundColor] = useState('#AF88FD')
    const [ scrollPosition, setScrollPosition ] = useState(0);
    const [ positionScrollBottom, setPositionScrollBottom ] = useState(false)

    const {
		rex_data_modulos_dashboards_first,
        rex_data_modulos_dashboards_second,
    } = useSelector(({dashboards}) => dashboards)

    const { 
        rex_data_user_logingrow,
        rex_status_menu_open
    } = useSelector(({loginGrow}) => loginGrow)

    const { 
        rex_data_users_online
    } = useSelector(({users}) => users)

    useEffect(() => {
        dispatch(GetDataModulesHomeReducer())
    }, [])

    useEffect(() => {
        socket.on('update-category', () => {
            dispatch(GetDataModulesHomeReducer())
        })
    },[])

    useEffect(() => {

        if (window.location.hash === "#Category" || window.location.hash === "#Home") {
            window.history.pushState("", document.title, window.location.pathname);
        }

        socket.emit('reg-modules', { module: 'home'}, {usuusuario: rex_data_user_logingrow.usuusuario})
    },[])

    useEffect(() => {
        socket.emit('reg-modules', { module: 'home'}, {usuusuario: rex_data_user_logingrow.usuusuario})

        socket.on('get-users-modules', (data_users) => {
            dispatch(RegisterUsersConnectedHomeReducer(data_users))
        });

        new WOW.WOW({
            live: false
        }).init()
        // dispatch(ObtenerDataModulosDashboardsReducer())

        return () => {
            socket.emit('unreg-modules', { module: 'home'}, {usuusuario: rex_data_user_logingrow.usuusuario} )
            socket.off('get-users-modules')
        }
    }, [])

    const onIdle = () => {
        socket.emit('reg-status-modules', { module: 'home'}, {usuusuario: rex_data_user_logingrow.usuusuario}, false )
    }
    const onActive = () => {
        socket.emit('reg-status-modules', { module: 'home'}, {usuusuario: rex_data_user_logingrow.usuusuario}, true )
    }

    useIdleTimer({
        onIdle,
        onActive,
        timeout,
        throttle: 500
    })

    const {
        rex_users_home_connected
    } = useSelector(({home}) => home)

    const nombreTitulo = [
        {key: 1, nombre: 'trabajo'},
        {key: 2, nombre: 'innovar'},
        {key: 3, nombre: 'estrategia'},
    ]

    const [totalTitle, setTotalTitle] = useState(1)

    useEffect(() => {
        const timer = setInterval(() => {
            if(nombreTitulo.length > 2){
                setTotalTitle((total) => {
                    if(total == nombreTitulo.length){
                        return 1
                    }else{
                        return total + 1
                    }
                })
            }
        }, 2000)

        return () => clearInterval(timer)
    }, [])

    const updateUrlHome = () => {
        if (window.location.hash === "#Category" || window.location.hash === "#Home") {
            window.history.pushState("", document.title, window.location.pathname)
        }
    }

    useEffect(() => {
        updateUrlHome()
    }, [rex_status_menu_open])

    useEffect(() => {
        const updateColor = () => {
            const now = new Date()
            const hour = now.getHours()
        
            if(hour >= 6 && hour < 12){
                setBackgroundColor('#A9D2FE')
            }else if(hour >= 12 && hour < 18){
                setBackgroundColor('#FDCA0F')
            }else{
                setBackgroundColor('#AF88FD')
            }
        }
    
        updateColor()
        const interval = setInterval(updateColor, 60 * 1000)
    
        return () => {
            clearInterval(interval)
        }
    }, [])

    useEffect(() => {
        const handleScroll = () => {
            const scrollTop = window.scrollY;
            const vh = window.innerHeight;
            const positionInVh = (scrollTop / vh) * 100

            const windowHeight = window.innerHeight
            const pageHeight = document.documentElement.scrollHeight
            const distanceToBottom = pageHeight - (scrollTop + windowHeight)

            setPositionScrollBottom(distanceToBottom == 0 ? true : false)
            setScrollPosition(positionInVh);
        };
      
        window.addEventListener('scroll', handleScroll)
      
        return () => {
          window.removeEventListener('scroll', handleScroll)
        };
    }, [])

    return (
        <>
        <Element name="Home">
        <div className="Container-Main Container-Home" style={{height: '100vh'}}>
            <video style={{position: 'absolute', zIndex: -1, left: 0, width: '100%', height: '100vh', top: '0', objectFit: 'cover'}} src={videohome} autoPlay loop controls={false} muted></video>
            <div style={{width: '100%', height: '100vh', top: 0, left: 0, position: 'absolute', zIndex: 0, backgroundColor: '#000', opacity: '.5'}}></div>
            <Row align="middle" style={{height: '100%', rowGap: '40px'}}>
                <Col lg={16} md={24} xs={24}>
                    <div style={{marginLeft: '20px'}}>
                        <Title
                            className={`Title-Home-Mobile ${scrollMove == "Category" && 'titleUp'}`}
                        >
                            Un nuevo modelo de 
                            <div className="Container-Text-Effect">
                            {
                                nombreTitulo.map((nt) => 
                                    <>
                                    {
                                        nombreTitulo.length == 2
                                        ?<span 
                                            className={`Text-Effect Text-Effect-Slider-${nt.key}`}
                                            key={nt.key}
                                        >{nt.nombre}</span>
                                        :nombreTitulo.length > 2
                                        ?<span 
                                            className="Text-Effect" 
                                            style={{
                                                transform: `translateY(
                                                ${nt.key == totalTitle
                                                    ? '0'
                                                    : totalTitle == 1
                                                        ? nt.key == nombreTitulo.length
                                                            ? '-80px'
                                                            : '65px'
                                                        : totalTitle < nt.key 
                                                            ? '65px' 
                                                            : nt.key == totalTitle-1 
                                                                ? '-80px' : '65px'}
                                                )`, 
                                                transition: 'transform 1s, visibility 1s',
                                                backgroundColor: 'transparent',
                                                visibility: totalTitle == nt.key ? 'visible' : 'hidden',
                                                width: '100%',
                                            }}
                                            key={nt.key}
                                        >{nt.nombre}</span>
                                        :<span className="Text-Effect" key={nt.key}>{nt.nombre}</span>
                                    }
                                    </>
                                )
                            }
                            </div>
                        </Title>
                        <Title
                            className={`Title-Home ${scrollMove == "Category" && 'titleUp'}`}
                        >
                            Un nuevo modelo <br/>de
                            <div className="Container-Text-Effect">
                            {
                                nombreTitulo.map((nt) => 
                                    <>
                                    {
                                        nombreTitulo.length == 2
                                        ?<span 
                                            className={`Text-Effect Text-Effect-Slider-${nt.key}`}
                                            key={nt.key}
                                        >{nt.nombre}</span>
                                        :nombreTitulo.length > 2
                                        ?<span 
                                            className="Text-Effect" 
                                            style={{
                                                transform: `translateY(
                                                ${nt.key == totalTitle
                                                    ? '0'
                                                    : totalTitle == 1
                                                        ? nt.key == nombreTitulo.length
                                                            ? '-80px'
                                                            : '65px'
                                                        : totalTitle < nt.key 
                                                            ? '65px' 
                                                            : nt.key == totalTitle-1 
                                                                ? '-80px' : '65px'}
                                                )`, 
                                                transition: 'transform 1s, visibility 1s',
                                                backgroundColor: 'transparent',
                                                visibility: totalTitle == nt.key ? 'visible' : 'hidden',
                                                width: '100%',
                                            }}
                                            key={nt.key}
                                        >{nt.nombre}</span>
                                        :<span className="Text-Effect" key={nt.key}>{nt.nombre}</span>
                                    }
                                    </>
                                )
                            }
                            </div>
                        </Title>
                        <div style={{display: 'flex'}}>
                            <a href="https://grow-analytics.com/" target='_blank' className="Button-Home Button-Responsive-Home">
                                <span>Conoce más al equipo</span>
                                <ArrowRightOutlined className="Icon-Button-Home" />
                            </a>
                        </div>
                        
                    </div>
                </Col>
                <Col lg={8} md={24} xs={24}>
                    <Card className="Card-Container-Home Card-Height-Home"></Card>
                </Col>
                <Col lg={0} md={24} xs={24} style={{display: 'none'}}>
                    <a href="https://grow-analytics.com/" target='_blank' className="Button-Home">
                        <span>Conoce más al equipo</span>
                        <ArrowRightOutlined className="Icon-Button-Home" />
                    </a>
                </Col>
                <Waypoint onEnter={() => setScrollMove("Category")} />
            </Row>
            <BarraHome />
        </div>
        </Element>
        <Row style={{marginTop: '30px', marginBottom: '40px', position: 'relative'}}>
            <Col span={24}>
                <div className="Circle-Big-Categories">
                    <div className="Circle-Big-Categories-Spheres-X"></div>
                    <div className="Circle-Big-Categories-Spheres-Y"></div>
                </div>
                <div className="Container-Categories">
                    <div className={`Item-Categories Categories-${rex_data_modulos_dashboards_first.length + rex_data_modulos_dashboards_second.length}`}>
                        <div style={{position: 'absolute', top: '50px'}}>
                            <Element name="Category"></Element>
                        </div>
                        {
                            rex_data_modulos_dashboards_second.length > 0 &&
                            rex_data_modulos_dashboards_second.map((dashSecond, index) => 
                                <div className={`Container-Item-${index}-Categories wow`}>
                                    <Link 
                                        to="/home-modules"
                                        state={dashSecond.modid}
                                    >
                                        <CardHome
                                            idCard={dashSecond.modid}
                                            titleCard={dashSecond.modnombre}
                                            imgCard={dashSecond.modicono}
                                            backgroundColor={backgroundColor}
                                        />
                                    </Link>
                                </div>
                            )
                        }
                        <div className="Item-Categories-Round">
                            <div className="Item-Item-Categories-Round"></div>
                        </div>
                        <div className="Subitem-Categories">
                            <div className="Subitem-Categories-Round">
                                <div className="Subitem-Subitem-Categories-Round"></div>
                            </div>
                            <Waypoint 
                                onEnter={() => {
                                    setLastScrollMove(true)
                                    setScrollMove("Home")
                                }}
                                onLeave={() => {
                                    setLastScrollMove(false)
                                    setScrollMove("Category")
                                }}
                            />
                            <Title
                                level={2}
                                className="Title-Card-Home wow View-Title-Category"
                            >
                                <span>Categorías</span>
                            </Title>
                            {
                                rex_data_modulos_dashboards_first.length > 0 &&
                                rex_data_modulos_dashboards_first.map(dashFirst => 
                                    <div className={`Container-Subitem-${dashFirst.key}-Categories wow`}>
                                        <Link to="/home-modules" state={dashFirst.modid}>
                                            <CardHome
                                                idCard={dashFirst.modid}
                                                titleCard={dashFirst.modnombre}
                                                imgCard={dashFirst.modicono}
                                                backgroundColor={backgroundColor}
                                            />
                                        </Link>
                                    </div>
                                )
                            }
                        </div>
                    </div>
                </div>
            </Col>
        </Row>
        {/* <Element name="Benefit">
        <div className="Container-Main Container-Home" style={{paddingTop: '0', position: 'relative', backgroundColor: '#fff'}}>
            <Row style={{rowGap: '40px'}}>
                <Col span={24}>
                    <div style={{position: 'absolute', top: '50px'}}>
                        <Element name="Benefit"></Element>
                    </div>
                    <Card className="Container-Card-Benefit">
                        <Title
                            level={2}
                            className="Title-Card-Home"
                        >
                            Beneficios
                        </Title>
                        <Waypoint onEnter={() => setScrollMove("Bottom")} />
                        <div className="Card-Container-Benefit-Home">
                            <div 
                                onMouseOver={() => setSomeMouseOver(1)}
                                className={`Container-Item-Benefit ${someMouseOver == 1 ? 'Active-Container' : ''}`}
                            >
                            <CardBenefitHome 
                                titleBenefit="Flexible"
                                paragraphBenefit="Muchos paquetes de autoedición y editores de páginas web ahora usan Lorem Ipsum Muchos paquetes de autoedición y editores de páginas web ahora usan Lorem Ipsum"
                                someMouseOver={someMouseOver}
                                number={1}
                                icon={icono1}
                            />
                            </div>
                            <div 
                                onMouseOver={() => setSomeMouseOver(2)}
                                className={`Container-Item-Benefit ${someMouseOver == 2 ? 'Active-Container' : ''}`}
                            >
                            <CardBenefitHome 
                                titleBenefit="Rápido"
                                paragraphBenefit="Muchos paquetes de autoedición y editores de páginas web ahora usan Lorem Ipsum Muchos paquetes de autoedición y editores de páginas web ahora usan Lorem Ipsum"
                                someMouseOver={someMouseOver}
                                number={2}
                                color="#FF1CDB"
                                icon={icono2}
                            />
                            </div>
                            <div 
                                onMouseOver={() => setSomeMouseOver(3)}
                                className={`Container-Item-Benefit ${someMouseOver == 3 ? 'Active-Container' : ''}`}
                            >
                            <CardBenefitHome 
                                titleBenefit="Soporte"
                                paragraphBenefit="Muchos paquetes de autoedición y editores de páginas web ahora usan Lorem Ipsum Muchos paquetes de autoedición y editores de páginas web ahora usan Lorem Ipsum"
                                someMouseOver={someMouseOver}
                                number={3}
                                color="#2AD295"
                                icon={icono3}
                            />
                            </div>
                            <div 
                                onMouseOver={() => setSomeMouseOver(4)}
                                className={`Container-Item-Benefit ${someMouseOver == 4 ? 'Active-Container' : ''}`}
                            >
                            <CardBenefitHome 
                                titleBenefit="Rápido"
                                paragraphBenefit="Muchos paquetes de autoedición y editores de páginas web ahora usan Lorem Ipsum Muchos paquetes de autoedición y editores de páginas web ahora usan Lorem Ipsum"
                                someMouseOver={someMouseOver}
                                number={4}
                                color="#013A81"
                                icon={icono1}
                            />
                            </div>
                        </div>
                    </Card>
                </Col>
            </Row>
        </div>
        </Element> */}
        {/* <Element name="Bottom">
        <Waypoint 
            onEnter={() => {
                setLastScrollMove(true)
                setScrollMove("Home")
            }}
            onLeave={() => {
                setLastScrollMove(false)
                setScrollMove("Bottom")
            }}
        />
        </Element> */}
        
        {/* {
            rex_data_user_logingrow.usuid
            ?   <a 
                    style={{position: 'fixed', width: '70px', height: '70px', borderRadius: '50%', backgroundColor: '#fff', bottom: '50px', left: '50px'}} 
                    // style={{position: 'fixed', width: '70px', height: '70px', borderRadius: '50%', backgroundColor: '#fff', bottom: '50px', left: '50px'}} 
                href="https://api.whatsapp.com/send?phone=51951331671" target='_blank'
            >
                <Tooltip placement="right" color="#2AD295" style={{display:'flex', alignItems:'center'}} title="Hola, ¿En qué te puedo ayudar?" >
                    <img src={inteligenciaartificial} style={{width: '70px', height: '70px', display: 'flex', borderRadius: '50%'}} />
                </Tooltip>
            </a>
            : null
        } */}

            {/* <div
                className='Consultar-Soporte-Intrologin'
                onClick={() => [
                    ref_redirect.current.click()
                ]}
            >
                <div className='Nube-Contactar-Soporte' >
                    Chat with us
                </div>

                <div >
                    <img className='Icono-Soporte' src={inteligenciaartificial} />
                </div>
            </div>

            <a
                ref={ref_redirect} 
                href="https://api.whatsapp.com/send?phone=51951331671" target="_blank" style={{display:'none'}}></a> */}
        
        <ButtonFloat 
            scrollMove={scrollMove}
            lastScrollMove={lastScrollMove}
            scrollPosition={scrollPosition > 14 ? true : false}
            positionScrollBottom={positionScrollBottom}
        />
        {
            rex_users_home_connected &&
                <SideMenu
                    usersCurrentView  = {rex_users_home_connected}
                    usersAllConnected ={rex_data_users_online}
                />
        }
        </>
    )
}

export default Home