import {
    GET_DATA_FILTERS_TXT_PRODUCTS_TOTAL,
    GET_DATA_FILTERS_GBA,
    GET_DATA_FILTERS_CHANNEL,
    GET_DATA_FILTERS_ZONE,
    GET_DATA_FILTERS_CLIENT,
    GET_DATA_FILTERS_INCENTIVES,
    GET_DATA_MASTER_PRODUCTS_MANAGE,
    GET_DATA_MASTER_PRODUCTS_MANAGE_CLONE,
    GET_DATA_MASTER_PRODUCT_MANAGE,
    LOADING_GET_DATA_MASTER_PRODUCTS_MANAGE,
    LOADING_GET_DATA_MASTER_PRODUCTS_MANUAL_MANAGE,
    SHOW_MODAL_ALERT_DELETE_PRODUCTS,
    DATA_ALERT_DELETE_PRODUCTS,
    GET_DATA_MASTER_PRODUCT_MANUALS_MANAGE,
    DATA_FORM_EDIT_MASTER_PRODUCT,
    DATA_FORM_EDIT_MASTER_PRODUCT_MANUAL,
    DATA_FORM_EDIT_MASTER_PRODUCT_MSL,
    DATE_LAST_UPDATED_PRODUCTS_MANAGE
} from "../../../../Constants/Manage/MasterProducts/MasterProducts";

const INIT_STATE = {
    rex_data_master_products_manage             : [],
    rex_data_master_products_manage             : [],
    rex_data_master_products_manuals_manage     : [],
    rex_date_updated_product_manage             : "",
    rex_loading_data_master_products_manage     : true,
    rex_loading_data_master_products_manual_manage  : true,

    rex_filters_incentiv_products_total : {
        rex_filter_products : {
            rex_txt_uniqueChannels : "",
            rex_txt_unique_zona : "",
            rex_txt_unique_cliente : "",
            rex_txt_unique_sucursal : "",
            rex_txt_unique_gba : "",
            rex_txt_unique_incentive : ""
        },
    },

    rex_filters_items_gba : [],
    rex_filters_items_channel : [],
    rex_filters_items_zone : [],
    rex_filters_items_client : [],
    rex_filters_items_incentive : [],

    rex_show_modal_alert_delete_products        : false,
    rex_data_alert_delete_products              : [],
    rex_data_form_edit_master_product           : [],
    rex_data_form_edit_master_product_manual    : [],
    rex_data_form_edit_master_product_msl       : [],
    rex_data_master_product_manage              : {
        cod_material            : "",
        material                : "",
        cod_sales_organization  : "",
        sales_organization      : "",
        cod_bussiness           : "",
        bussiness               : "",
        cod_sku                 : "",
        ship_sku                : "",
        cod_ean13               : "",
        cod_ean14               : "",
        cod_category            : "",
        category                : "",
        cod_sub_category        : "",
        sub_category            : "",
        cod_format              : "",
        format                  : "",
        cod_family_sku          : "",
        family_sku              : "",
        cod_marca               : "",
        marca                   : "",
        class_1                 : "",
        class_2                 : "",
        class_3                 : "",
        class_4                 : "",
        class_5                 : "",
        class_6                 : "",
        cod_count               : "",
        count                   : "",
        cod_size                : "",
        size                    : "",
        cod_presentation        : "",
        presentation            : "",
        cod_peso                : "",
        peso                    : "",
        cod_fc                  : "",
        fc                      : "",
        class_7                 : "",
        class_8                 : "",
        class_9                 : "",
        class_10                : "",
        cod_cj                  : "",
        cj                      : "",
        cod_tn                  : "",
        tn                      : "",
        cod_gsu                 : "",
        gsu                     : "",
        class_11                : "",
        class_12                : "",
        class_13                : "",
        status                  : "",
        robot                   : "",
        mslpo                   : ""
    },
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {

        case DATE_LAST_UPDATED_PRODUCTS_MANAGE: {
            return {
                ...state,
                rex_date_updated_product_manage : action.payload,
            }
        }
        case GET_DATA_FILTERS_INCENTIVES: {
            return {
                ...state,
                rex_filters_items_incentive : action.payload,
            }
        }
        case GET_DATA_FILTERS_CLIENT: {
            return {
                ...state,
                rex_filters_items_client : action.payload,
            }
        }
        case GET_DATA_FILTERS_ZONE: {
            return {
                ...state,
                rex_filters_items_zone : action.payload,
            }
        }
        case GET_DATA_FILTERS_CHANNEL: {
            return {
                ...state,
                rex_filters_items_channel : action.payload,
            }
        }
        case GET_DATA_FILTERS_GBA: {
            return {
                ...state,
                rex_filters_items_gba : action.payload,
            }
        }
        case GET_DATA_FILTERS_TXT_PRODUCTS_TOTAL: {
            return {
                ...state,
                rex_filters_incentiv_products_total : action.payload,
            }
        }


        case DATA_FORM_EDIT_MASTER_PRODUCT_MSL: {
            return {
                ...state,
                rex_data_form_edit_master_product_msl : action.payload,
            }
        }
        case DATA_FORM_EDIT_MASTER_PRODUCT_MANUAL: {
            return {
                ...state,
                rex_data_form_edit_master_product_manual : action.payload,
            }
        }
        case DATA_FORM_EDIT_MASTER_PRODUCT: {
            return {
                ...state,
                rex_data_form_edit_master_product : action.payload,
            }
        }
        case GET_DATA_MASTER_PRODUCT_MANUALS_MANAGE: {
            return {
                ...state,
                rex_data_master_products_manuals_manage : action.payload,
            }
        }
        case SHOW_MODAL_ALERT_DELETE_PRODUCTS: {
            return {
                ...state,
                rex_show_modal_alert_delete_products : action.payload,
            }
        }
        case DATA_ALERT_DELETE_PRODUCTS: {
            return {
                ...state,
                rex_data_alert_delete_products : action.payload,
            }
        }
        case LOADING_GET_DATA_MASTER_PRODUCTS_MANUAL_MANAGE: {
            return {
                ...state,
                rex_loading_data_master_products_manual_manage : action.payload,
            }
        }
        case LOADING_GET_DATA_MASTER_PRODUCTS_MANAGE: {
            return {
                ...state,
                rex_loading_data_master_products_manage : action.payload,
            }
        }
        case GET_DATA_MASTER_PRODUCT_MANAGE: {
            return {
                ...state,
                rex_data_master_product_manage : action.payload,
            }
        }
        case GET_DATA_MASTER_PRODUCTS_MANAGE: {
            return {
                ...state,
                rex_data_master_products_manage : action.payload,
            }
        }
        case GET_DATA_MASTER_PRODUCTS_MANAGE_CLONE: {
            return {
                ...state,
                rex_data_master_products_manage_clone : action.payload,
            }
        }
        default:
            return state;
    }
}