import React, { useState, useEffect } from 'react'
import { Pagination, Row, Switch, Table, Col, Typography, Modal, Input } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import FilterSelectGlobal from '../../Components/Global/FilterSelectGlobal'
import SideMenu from '../../Components/Global/SideMenu'
import SimpleTableGlobal from '../../Components/Global/SimpleTableGlobal'
import TrashButtonGlobal from '../../Assets/Images/Global/TrashButtonGlobal'
import DownloadButtonGlobal from '../../Assets/Images/Global/DownloadButtonGlobal'
import NavigationGlobal from '../../Components/Global/NavigationGlobal'
import ProfileTypesFilterSelect from '../../Components/ProfileTypes/ProfileTypesFilterSelect'
import { useNavigate } from 'react-router'
import { 
    GetDataControlReducer, 
    ChangeStatusDataControlReducer, 
    DeleteDataControlReducer,
    FilterDataControlReducer,
    DownloadDataDataControlReducer,
    DownloadFileDataControlReducer
} from '../../Redux/Actions/DataControl/DataControl'
import moment from 'moment'
import ButtonGlobal from '../../Components/Global/ButtonGlobal'
import EditOptionButtonGlobal from '../../Assets/Images/Global/EditOptionButtonGlobal'
import TrashOptionButtonGlobal from '../../Assets/Images/Global/TrashOptionButtonGlobal'
import { 
    FilterOutlined,
} from '@ant-design/icons'

const DataControl = () => {

    const [ mouseOverRow ,setMouseOverRow] = useState(null)
    const [ paiFilter, setPaiFilter ] = useState([])
    const [selectedRowKeys, setSelectedRowKeys] = useState([])
    const [ loadingData, setLoadingData ] = useState(false)
    const [pageTableGlobal, setPageTableGlobal ] = useState(1)
    const [pageSizeTableGlobal, setPageSizeTableGlobal ] = useState(20)
    const [ keyRowAction, setKeyRowAction ] = useState(null)
    const navigate = useNavigate()
    const [ showModal, setShowModal ] = useState(false)
    const [ infoModal, setInfoModal ] = useState({})

    const { Title } = Typography
    const { confirm } = Modal
    const dispatch = useDispatch()

    const menuDataControl = [
        {
            url: '/home-administrator/',
            nombre: 'Administrador'
        },
        {
            url: '/administrator/data-control',
            nombre: 'Control de datos'
        },
    ]
    const { 
        rex_data_paises,
        rex_data_filtro_paises
    } = useSelector(({paises}) => paises)

    const { 
        rex_users_conected_dashboard,
    } = useSelector(({registerUsers}) => registerUsers)

    const { 
        rex_data_users_online
    } = useSelector(({users}) => users)

    const { 
        rex_data_control_clone,
        rex_loading_data_control
    } = useSelector(({dataControl}) => dataControl)

    const statusModal = () => {
        setShowModal(false)
    }

    const getColumnSearchProps = (dataIndex, nameColumn) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
            <div
                style={{
                    padding: 8,
                }}
                onKeyDown={(e) => e.stopPropagation()}
            >
                <Input.Search
                    placeholder={`Filtrar ${nameColumn}`}
                    value={selectedKeys[0]}
                    onChange={(e) => {
                        setSelectedKeys(e.target.value ? [e.target.value] : [])
                        confirm({
                            closeDropdown: false,
                        })
                    }}
                    style={{
                        display: 'block',
                    }}
                />
            </div>
        ),
        filterIcon: () => (<FilterOutlined/>),
        onFilter: (value, record) => {
            return record[dataIndex] != null ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : false
        }
    })

    const functionDelete = async (ids, setIdsSelected) => {
        if(ids.length > 0){
            confirm({
                title   : <span>Confirmar</span>,
                icon    : null,
                className :"Modal-Confirm-Data-Control",
                content : `¿Estás seguro de eliminar ${ids.length > 1 ? "los archivos" : "el archivo"}?`,
                zIndex  : 100000000,
                async onOk(){
                    let response = await dispatch(DeleteDataControlReducer(ids))
                    if(response){
                        setIdsSelected([])
                        setShowModal(true)
                        setInfoModal({
                            ...infoModal, 
                            title : "¡Listo!", 
                            description : `${ids.length > 1 ? "Los archivos fueron eliminados con éxito" : "El archivo fue eliminado con éxito"}`,
                            status : true,
                            small: true
                        })
                    }
                },
                okText : "Confirmar",
                okButtonProps: {
                    size:"small",
                },
                cancelText: "Cancelar",
                cancelButtonProps : {
                    size:"small",
                    danger:true
                },
                okCancel(){}
            })
        }
    }
    
    const getDataControl = async () => {
        setLoadingData(true)
        let response = await dispatch(GetDataControlReducer())
        setLoadingData(false)
    }

    useEffect(() => {
        getDataControl()
    },[])

    const changeStatus = async (id, status) => {

        confirm({
            title   : <span>Confirmar</span>,
            icon    : null,
            className :"Modal-Confirm-Data-Control",
            content : `¿Estás seguro de ${status ? "activar" : "desactivar"} el archivo?`,
            zIndex  : 100000000,
            async onOk(){
                let response = await dispatch(ChangeStatusDataControlReducer(id, status))
                if(response){
                    setShowModal(true)
                    setInfoModal({
                        ...infoModal, 
                        title : "¡Listo!", 
                        description : `El archivo fue editado con éxito`,
                        status : true,
                        small: true
                    })
                }
            },
            okText : "Confirmar",
            okButtonProps: {
                size:"small",
            },
            cancelText: "Cancelar",
            cancelButtonProps : {
                size:"small",
                danger:true
            },
            okCancel(){}
        })
    }

    const deleteRow = async (ids) => {
        confirm({
            title   : <span>Confirmar</span>,
            icon    : null,
            className :"Modal-Confirm-Data-Control",
            content : `¿Estás seguro de eliminar el archivo?`,
            zIndex  : 100000000,
            async onOk(){
                let response = await dispatch(DeleteDataControlReducer(ids))
                if(response){
                    setShowModal(true)
                    setInfoModal({
                        ...infoModal, 
                        title : "¡Listo!", 
                        description : `El archivo fue eliminado con éxito`,
                        status : true,
                        small: true
                    })
                }
            },
            okText : "Confirmar",
            okButtonProps: {
                size:"small",
            },
            cancelText: "Cancelar",
            cancelButtonProps : {
                size:"small",
                danger:true
            },
            okCancel(){}
        })
    }

    const columns = [
        {
            title: 'Item',
            dataIndex: 'item',
            ellipsis : true,
            align: 'center',
            fixed:"left",
            render: (text, record, index) => (pageTableGlobal - 1) * pageSizeTableGlobal + index + 1,
            width : "50px"
        },
        Table.SELECTION_COLUMN,
        {
            title: 'Archivo',
            dataIndex: 'ardnombre',
            ...getColumnSearchProps('ardnombre', 'archivo'),
            sorter: (a, b) => a.ardnombre ? a.ardnombre.localeCompare(b.ardnombre) : false,
            showSorterTooltip: false,
            align: 'center',
            ellipsis : true,
            render:(_,record) => (

                <div
                    onClick={async() => {
                        const fileUrl = await dispatch(DownloadFileDataControlReducer(record.ardid))
                        if(fileUrl){
                            const link = document.createElement('a')
                            link.href = fileUrl
                            link.download = record.ardnombre
                            link.click()
                        }
                    }} 
                    title={record.carnombre} className='Simple-Table-Contain-Text-Global Text-Left'>
                    <a>{record.ardnombre}</a>
                </div>
            ),
            width : "200px"
        },
        {
            title: 'Nivel país',
            dataIndex: 'countries',
            sorter: (a, b) => a.countries ? a.countries.localeCompare(b.countries) : false,
            showSorterTooltip: false,
            align: "center",
            render:(_,record) => (
                <div className='Simple-Table-Contain-Text-Global Text-Left'>
                    {record.countries}
                </div>
            ),
            ellipsis : true,
            filters: rex_data_filtro_paises,
            onFilter: (value, record) => {
                return record.valuesCountries.includes(value)
            },
            width : "140px"
        },
        {
            title: 'Estado',
            dataIndex: 'ardmostrar',
            align: 'center',
            sorter: (a, b) => a.ardmostrar? a.ardmostrar - b.ardmostrar : false,
            showSorterTooltip : false,
            ellipsis : true,
            render:(_, record, index) => {
                let statusText = record.ardmostrar ? "activo" : "inactivo"
                return <div title={statusText} className='Table-Contain-Text-Global Cell-Space-Items'>
                            <span>{statusText}</span>
                            <Switch
                                onChange={(value) => {
                                    changeStatus(record.ardid, value)
                                }}
                                className='Switch-Status-Data-Control'
                                size='small'
                                checked={record.ardmostrar}
                            />
                        </div>
            },
            width : "120px",
            filters: [
                {
                  text: 'activo',
                  value: true,
                },
                {
                  text: 'inactivo',
                  value: false,
                },
            ],
            onFilter: (value, record) => record.ardmostrar == value,
        },
        {
            title: 'Fecha y hora de creación',
            dataIndex: 'created_at',
            align: 'center',
            ellipsis : true,
            render:(_, record) => (
                <div className='Simple-Table-Contain-Text-Global'>
                    {record.created_at ? moment(record.created_at).format('DD MMM YYYY h:mm a') : ""}
                </div>
            ),
            width : "180px"
        },
        {
            title: 'Fecha y hora de actualización',
            dataIndex: 'updated_at',
            align: 'center',
            ellipsis : true,
            render:(_, record) => (
                <div className='Simple-Table-Contain-Text-Global'>
                    {record.updated_at 
                        ? moment(record.updated_at).format('DD MMM YYYY h:mm a') 
                        : moment(record.created_at).format('DD MMM YYYY h:mm a')
                    }
                </div>
            ),
            width : "180px"
        },
        {
            title: 'Usuario creación',
            dataIndex: 'usuario',
            align: 'center',
            ...getColumnSearchProps('usuario', 'usuario que creó'),
            sorter: (a, b) => a.usuario ? a.usuario.localeCompare(b.usuario) : false,
            showSorterTooltip: false,
            ellipsis : true,
            render:(_, record) => (
                <div className='Simple-Table-Contain-Text-Global Text-Left'>
                    {record.usuario}
                </div>
            ),
            width : "160px"
        },
        {
            title: 'Usuario actualización',
            dataIndex: 'usuedited',
            align: 'center',
            ...getColumnSearchProps('usuedited', 'usuario que editó'),
            sorter: (a, b) => a.usuedited ? a.usuedited.localeCompare(b.usuedited) : false,
            showSorterTooltip: false,
            ellipsis : true,
            render:(_, record) => (
                <div className='Simple-Table-Contain-Text-Global Text-Left'>
                    {record.usuedited ? record.usuedited : record.usuario}
                </div>
            ),
            width : "170px"
        },
        // {
        //     title: 'Importar',
        //     dataIndex: 'import',
        //     align: 'center',
        //     render:(_, record) => (
        //         <div className='Table-Contain-Text-Global' style={{cursor:"pointer"}}>
        //             <span 
        //                 className='Text-Import-Data-Control'
        //             >
        //                 Importar excel
        //             </span>
        //             <img src={DownloadIcon}/>
        //         </div>
        //     )
        // },
        {
            title: 'Acciones',
            dataIndex: 'actions',
            align: 'center',
            width : "90px",
            fixed : "right",
            ellipsis : true,
            render:(_, record, index) => (
                <div style={{display:"flex", justifyContent:"center", columnGap: "10px"}}>
                    <div 
                        onClick={() => navigate('/administrator/edit-data-control/'+record.key)}
                    >
                        <EditOptionButtonGlobal/>
                    </div>
                    <div 
                        style={{display:"flex",alignItems:"center"}}
                        onClick={() => deleteRow([record.key])}
                    >
                        <TrashOptionButtonGlobal/>
                    </div>
                    {/* <ButtonMenuPointsGlobal
                        keyRowAction={index}
                        urlDownload={record.urlDownload}
                        setKeyRowAction={setKeyRowAction}
                        actionDelete={() => deleteRow([record.key])}
                        actionEdit={() =>  navigate('/administrator/edit-data-control/'+record.key)}
                    />  */}
                </div>
            )
        },
    ]

    const onSelectChange = (newSelectedRowKeys) => {
        setSelectedRowKeys(newSelectedRowKeys);
    };

    const toggleSelectAll = (valueCheckbox) => {
        setSelectedRowKeys((keys) =>
        valueCheckbox
            ? rex_data_control_clone?.length > 0 ? rex_data_control_clone.map((r) => r.key) : rex_data_control_clone.map((r) => r.key) 
            : []
        )
    }

    const rowSelection = {
        columnTitle: true ? <ProfileTypesFilterSelect
                                    showDropDown={true}
                                    toggleSelectAll={toggleSelectAll}
                                    selectedRowKeys={null}
                                    dataAmount={rex_data_control_clone.length}
                                    setSelectedRowKeys={setSelectedRowKeys}
                                    data={rex_data_control_clone}
                                    valueCheckbox={selectedRowKeys.length == rex_data_control_clone.length}
                                    setValueCheckbox={() => console.log("ok")}
                                    actInacChechBox={false}
                                    themeColor='green'
        /> : <></>,
        selectedRowKeys,
        onChange: onSelectChange,
        type: "checkbox",
        fixed: true,
        onSelect: () => console.log("oko"),
    };
    

    return (
        <div className='Container-Main'>

            <Row style={{position: 'relative'}}>
                <Col span={24} style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <Title className="Title-Type-Profile">
                        Control de datos
                    </Title>
                </Col>
                <Col span={24} style={{position: 'absolute', top: '6px'}}>
                    <NavigationGlobal menuItems={menuDataControl}/>
                </Col>
            </Row>
            <Row className='Users-Container-Actions'>
                <div style={{display:"flex", alignItems: "center", columnGap: "12px"}}>
                    <FilterSelectGlobal
                        data={rex_data_paises}
                        filterBy='país'
                        setTypFilter={setPaiFilter}
                        funtionFilter={(valueSelected, type) => {
                            dispatch(FilterDataControlReducer(valueSelected, type))
                        }}
                    />
                    <TrashButtonGlobal
                        color='#013A81'
                        optionHover={true}
                        functionDelete={() => functionDelete(selectedRowKeys, setSelectedRowKeys)}
                    />
                    <div style={{marginLeft:"-10px", zIndex:"1"}}>
                        <DownloadButtonGlobal 
                            color='#013A81'
                            functionDownload={ async () => {
                                const link = document.createElement('a')
                                let url = await dispatch(DownloadDataDataControlReducer())
                                link.href = url
                                link.click()
                            }}
                            downloadFile={true}
                        />
                    </div>
                </div>
                <div style={{display: "flex", columnGap:"15px"}}>
                    <ButtonGlobal
                        nameRoute="/administrator/create-data-control"
                        txtButton="Nuevo"                    
                    />
                    <Pagination
                        total={rex_data_control_clone.length}
                        showTotal={(total, range) => `${range[0]}-${range[1]} de ${rex_data_control_clone.length}`}
                        defaultPageSize={pageSizeTableGlobal}
                        defaultCurrent={pageTableGlobal}
                        current={pageTableGlobal}
                        className='Simple-Table-Pagination-Global'
                        onChange={(page, pageSize) => {
                            setPageTableGlobal(page == 0? 1 : page)
                            setPageSizeTableGlobal(pageSize)
                        }}
                    />
                </div>
            </Row>
            <div className='Table-Actions'>
                <SimpleTableGlobal
                    data={rex_data_control_clone}
                    keyRowAction={keyRowAction}
                    rowSelection={rowSelection}
                    columns={columns}
                    loadingData={rex_loading_data_control}
                    pageTableGlobal={pageTableGlobal}
                    pageSizeTableGlobal={pageSizeTableGlobal}
                    actionEnterRow={setMouseOverRow}
                    actionLeaveRow={() => console.log("ok")}
                />
            </div>
            <Modal
                open={showModal}
                title={<span className={`${infoModal.status ? "Text-Ok" : "Text-Alert"}`}>{infoModal.title}</span>}
                width={300}
                zIndex={100000000}
                className={`Modal-Create-Data-Control ${infoModal.small && 'Modal-Small'}`}
                footer={[
                    <div style={{display:"flex", justifyContent:"center"}}>
                        <div className={`Button-Close Button${infoModal.status ? "-Ok" : "-Alert"}`} onClick={() => statusModal()}>
                            Ok
                        </div>
                    </div>
                ]}
            >
                <div className='Modal-Description'>
                    {infoModal.description}
                </div>
            </Modal>
            <SideMenu
                usersCurrentView  = {rex_users_conected_dashboard}
                usersAllConnected ={rex_data_users_online}
            />
        </div>   
    )
}

export default DataControl