import {
    GET_DATA_FILTERS_TXT_CHANNELS_EXECUTIVES_TOTAL
} from "../../../Constants/ChannelsExecutive/ChannelsExecutive";

const INIT_STATE = {

    rex_filters_channels_executives_total : {
        rex_filter_channels_executives : {
            rex_txt_uniqueChannels : "",
            rex_txt_unique_zona : "",
            rex_txt_unique_cliente : "",
            rex_txt_unique_sucursal : "",
            rex_txt_unique_gba : "",
        },
    },
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {

        case GET_DATA_FILTERS_TXT_CHANNELS_EXECUTIVES_TOTAL: {
            return {
                ...state,
                rex_filters_channels_executives_total : action.payload,
            }
        }

        default:
            return state;
    }
}