import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Modal, Typography, Row, Col } from 'antd'
import { ShowNotificationErrorServerReducer } from '../../Redux/Actions/Users/Users'

const NotificationErrorServer = () => {

    const dispatch = useDispatch()
    const { Title } = Typography

    const {
        rex_notification_error_server
    } = useSelector(({users}) => users);

    
    const handleCancel = () => {
        dispatch(ShowNotificationErrorServerReducer(false))
    }
    return (
        <Modal 
            title={null}
            centered={true}
            open={rex_notification_error_server}
            closable={false}
            width={284}
            mask={true}
            maskClosable={true}
            footer={null}
            zIndex={100000000}
            className='Modal-Notification-User-Created'
        >
            <Row className='Modal-Container-Text-User-Created'>
                <Col span={24} className='Modal-Container-Text-Main-Alert'>
                    <Title>
                        !Alerta!
                    </Title>
                </Col>
                <Col span={24} className='Modal-Container-Text-Description'>
                    <p>Ha ocurrido un error en el servidor, intente luego por favor.</p>
                </Col>
                <Col span={24}>
                    <div
                        onClick={() => handleCancel()}
                        className='Notification-Button-User Notification-Button-User-Alert'
                    >
                        Ok
                    </div>
                </Col>
            </Row>
        </Modal>  
    )
}

export default NotificationErrorServer