import {
    OBTENER_PAISES_GET_DATA,
    OBTENER_PAISES_FILTRO_DATA,
    GET_DATA_COUNTRY_CLONE,
} from "../../../../Constants/GetData/Paises/Paises";


const INIT_STATE = {
    rex_data_paises         : [],
    rex_data_filtro_paises  : [],
    rex_data_country_clone  : [],
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case OBTENER_PAISES_GET_DATA: {
            return {
                ...state,
                rex_data_paises : action.payload,
            }
        }
        case OBTENER_PAISES_FILTRO_DATA: {
            return {
                ...state,
                rex_data_filtro_paises : action.payload,
            }
        } 
        case GET_DATA_COUNTRY_CLONE: {
            return {
                ...state,
                rex_data_country_clone : action.payload,
            }
        }
        default:
            return state;
    }
}
