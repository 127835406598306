import { Row, Typography, Col, Pagination, Modal, Table, Input, DatePicker } from 'antd'
import React, { useState } from 'react'
import NavigationGlobal from '../../Components/Global/NavigationGlobal'
import FilterSelectGlobal from '../../Components/Global/FilterSelectGlobal'
import { useDispatch, useSelector } from 'react-redux'
import TrashButtonGlobal from '../../Assets/Images/Global/TrashButtonGlobal'
import DownloadButtonGlobal from '../../Assets/Images/Global/DownloadButtonGlobal'
import SimpleTableGlobal from '../../Components/Global/SimpleTableGlobal'
import SideMenu from '../../Components/Global/SideMenu'
import ProfileTypesFilterSelect from '../../Components/ProfileTypes/ProfileTypesFilterSelect'
import { useNavigate } from 'react-router'
import { DeleteDataFileControlReducer, DownloadDataFileControlReducer, DownloadFileControlReducer, FilterFileControlReducer, FilterFileDateControlReducer, GetDataFileControlReducer } from '../../Redux/Actions/FileControl/FileControl'
import moment from 'moment'
import { 
    FilterOutlined,
} from '@ant-design/icons'

const FileControl = () => {

    const [ mouseOverRow ,setMouseOverRow] = useState(null)
    const [ paiFilter, setPaiFilter ] = useState([])
    const [selectedRowKeys, setSelectedRowKeys] = useState([])    
    const [pageTableGlobal, setPageTableGlobal ] = useState(1)
    const [pageSizeTableGlobal, setPageSizeTableGlobal ] = useState(20)
    const [ keyRowAction, setKeyRowAction ] = useState(null)
    const [ showModal, setShowModal ] = useState(false)
    const [ infoModal, setInfoModal ] = useState({})
    const navigate = useNavigate()
    const dispatch = useDispatch()
    const [ currentData, setCurrentData ] = useState([])

    const { Title } = Typography
    const { confirm } = Modal

    
    const menuDataControl = [
        {
            url: '/home-administrator/',
            nombre: 'Administrador'
        },
        {
            url: '/administrator/file-control',
            nombre: 'Control de archivos'
        },
    ]

    const getColumnSearchProps = (dataIndex, nameColumn, type="input") => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
            type == "input"
            ?<div
                style={{
                    padding: 8,
                }}
                onKeyDown={(e) => e.stopPropagation()}
            >
                <Input.Search
                    placeholder={`Filtrar ${nameColumn}`}
                    value={selectedKeys[0]}
                    onChange={(e) => {
                        setSelectedKeys(e.target.value ? [e.target.value] : [])
                        confirm({
                            closeDropdown: false,
                        })
                    }}
                    style={{
                        display: 'block',
                    }}
                />
            </div>
            :<div style={{padding:"8px"}}>
                <DatePicker
                    allowClear={true}
                    onChange={(value)=> {
                        dispatch(FilterFileDateControlReducer(currentData.length > 0 ? currentData : rex_data_file_control_clone, value, type))
                    }}
                />
            </div>
        ),
        filterIcon: () => (<FilterOutlined/>),
        onFilter: (value, record) => {
            return record[dataIndex] != null ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : false
        }
    })

    const { 
        rex_data_paises,
    } = useSelector(({paises}) => paises)

    const { 
        rex_data_file_control_clone,
        rex_data_types_file_control,
        rex_loading_file_control
    } = useSelector(({fileControl}) => fileControl)

    const { 
        rex_users_conected_dashboard,
    } = useSelector(({registerUsers}) => registerUsers)

    const { 
        rex_data_users_online
    } = useSelector(({users}) => users)

    const filterFileControl = async (values, type) => {
        dispatch(FilterFileControlReducer(values, type))
    }

    const statusModal = () => {
        setShowModal(false)
    }

    const functionDelete = async () => {
        if(selectedRowKeys){
            confirm({
                title   : <span>Confirmar</span>,
                icon    : null,
                className :"Modal-Confirm-Data-Control",
                content : `¿Estas seguro de eliminar ${selectedRowKeys.length > 1 ? "los archivos?" : "el archivo?" }`,
                zIndex  : 100000000,
                async onOk(){
                    let response = await dispatch(DeleteDataFileControlReducer(selectedRowKeys))
                    if(response){
                        setShowModal(true)
                        setInfoModal({
                            ...infoModal, 
                            title : "¡Listo!", 
                            description : `${selectedRowKeys.length > 1 ? "Los archivos fueron eliminados" :  "El archivo fue eliminado"} con éxito`,
                            status : true,
                            small: true
                        })
                        setSelectedRowKeys([])
                    }
                },
                okText : "Confirmar",
                okButtonProps: {
                    size:"small",
                },
                cancelText: "Cancelar",
                cancelButtonProps : {
                    size:"small",
                    danger:true
                },
                okCancel(){}
            })
        }
    }

    const getFileControl = async () => {
        let response = await dispatch(GetDataFileControlReducer())
    }

    useState(() => {
        getFileControl()
    },[])

    const columns = [
        {
            title: 'Item',
            dataIndex: 'item',
            ellipsis : true,
            align: 'center',
            width:"50px",
            fixed:"left",
            render: (text, record, index) => (pageTableGlobal - 1) * pageSizeTableGlobal + index + 1,
        },
        Table.SELECTION_COLUMN,
        {
            title: <div style={{textAlign:"center"}}>{'Archivo'}</div>,
            dataIndex: 'carnombre',
            ellipsis : true,
            ...getColumnSearchProps('carnombre', 'archivo'),
            render:(_,record) => (
                <div
                    onClick={async() => {
                        const fileUrl = await dispatch(DownloadFileControlReducer(record.carid))
                        if(fileUrl){
                            const link = document.createElement('a')
                            link.href = fileUrl
                            link.download = record.carnombre
                            link.click()
                        }
                    }} 
                    title={record.carnombre} className='Simple-Table-Contain-Text-Global'>
                    <a>{record.carnombre}</a>
                </div>
            ),
            sorter: (a, b) => a.carnombre? a.carnombre.localeCompare(b.carnombre) : false,
            showSorterTooltip : false,
            align:"left",
            width : "200px"
        },
        {
            title: <div style={{textAlign:"center"}}>{'Tipo'}</div>,
            dataIndex: 'cartipo',
            ellipsis : true,
            sorter: (a, b) => a.cartipo? a.cartipo.localeCompare(b.cartipo) : false,
            render:(_,record) => (
                <div title={record.cartipo} className='Simple-Table-Contain-Text-Global'>
                    {record.cartipo}
                </div>
            ),
            align:"left",
            width:"160px",
            showSorterTooltip: false,
            filters: rex_data_types_file_control,
            onFilter: (value, record) => record.cartipo == value,
        },
        {
            title: <div style={{textAlign:"center"}}>Área</div>,
            dataIndex: 'cararea',
            sorter: (a, b) => a.cararea? a.cararea.localeCompare(b.cararea) : false,
            ...getColumnSearchProps('cararea', 'área'),
            render:(_,record) => (
                <div title={record.cararea} className='Simple-Table-Contain-Text-Global'>
                    {record.cararea}
                </div>
            ),
            ellipsis : true,
            width:"130px",
            align:"left",
            showSorterTooltip: false,
        },
        {
            title: 'Nivel país',
            dataIndex: 'painombre',
            ...getColumnSearchProps('painombre', 'país'),
            sorter: (a, b) => a.painombre? a.painombre.localeCompare(b.painombre) : false,
            showSorterTooltip : false,
            render:(_,record) => (
                <div className='Simple-Table-Contain-Text-Global'>
                    {record.painombre}
                </div>
            ),
            ellipsis : true,
            width : '120px',
            align: "center"
        },
        {
            title: 'Estado',
            dataIndex: 'carexito',
            align: 'center',
            sorter: (a, b) => a.carexito? a.carexito - b.carexito : false,
            showSorterTooltip : false,
            render:(_, record) => (
                <div className='Simple-Table-Contain-Text-Global'>
                    {record.carexito ? 'Subido' : 'Cargado'}
                </div>
            ),
            ellipsis : true,
            width:"110px",
            filters: [
                {
                  text: 'Subido',
                  value: true,
                },
                {
                  text: 'Cargado',
                  value: false,
                },
            ],
            onFilter: (value, record) => record.carexito == value,
        },
        {
            title: 'Fecha de cargado',
            dataIndex: 'created_at',
            align: 'center',
            sorter: (a, b) => a.key? a.key - b.key : false,
            showSorterTooltip : false,
            width : '150px',
            ellipsis : true,
            render:(_, record) => (
                <div className='Simple-Table-Contain-Text-Global'>
                    {
                        record.created_at ? moment(record.created_at).format('DD MMM YYYY') : ""
                    }
                </div>
            )
        },
        {
            title: 'Fecha y hora de subida',
            dataIndex: 'created_at',
            align: 'center',
            sorter: (a, b) => a.key? a.key - b.key : false,
            showSorterTooltip : false,
            ellipsis : true,
            render:(_, record) => (
                <div className='Simple-Table-Contain-Text-Global'>
                    {
                        record.created_at ? moment(record.created_at).format('DD MMM YYYY h:mm a') : ""
                    }
                </div>
            ),
            width : '180px'
        },
        {
            title: 'Fecha y hora de actualización',
            dataIndex: 'updated_at',
            align: 'center',
            showSorterTooltip : false,
            ellipsis : true,
            render:(_, record) => (
                <div className='Simple-Table-Contain-Text-Global'>
                    {
                        record.updated_at 
                            ? moment(record.updated_at).format('DD MMM YYYY h:mm a')
                            : moment(record.created_at).format('DD MMM YYYY h:mm a')
                    }
                </div>
            ),
            width : '180px'
        },
        {
            title: 'Usuario creación',
            dataIndex: 'usucorreo',
            ...getColumnSearchProps('usucorreo', 'usuario que creó'),
            sorter: (a, b) => a.usucorreo? a.usucorreo.localeCompare(b.usucorreo) : false,
            showSorterTooltip: false,
            width:'180px',
            align : 'center',
            ellipsis : true,
            render:(_, record) => (
                <div className='Simple-Table-Contain-Text-Global Text-Left'>
                    {record.usucorreo}
                </div>
            ),
        },
        {
            title: 'Usuario actualización',
            dataIndex: 'usuedited',
            ...getColumnSearchProps('usuedited', 'usuario que actualizó'),
            sorter: (a, b) => a.usuedited? a.usuedited.localeCompare(b.usuedited) : false,
            showSorterTooltip: false,
            width:'180px',
            align : 'center',
            ellipsis : true,
            render:(_, record) => (
                <div className='Simple-Table-Contain-Text-Global Text-Left'>
                    {record.usuedited ? record.usuedited : record.usucorreo}
                </div>
            ),
        },
        // {
        //     title: '',
        //     dataIndex: 'actions',
        //     align: 'center',
        //     width:60,
        //     render:(_, record, index) => (
        //         <div className='Table-Contain-Text-Global'>
        //             {
        //                 mouseOverRow == index &&
        //                 <ButtonMenuPointsGlobal
        //                     keyRowAction={index}
        //                     setKeyRowAction={setKeyRowAction}
        //                     actionDelete={() => deleteRow(record.key)}
        //                     actionEdit={() =>  navigate('/administrator/edit-data-control/'+record.key)}
        //                 /> 
        //             }
        //         </div>
        //     )
        // },
    ]

    const onSelectChange = (newSelectedRowKeys) => {
        setSelectedRowKeys(newSelectedRowKeys);
    };

    const toggleSelectAll = (valueCheckbox) => {
        setSelectedRowKeys((keys) =>
        valueCheckbox
            ? rex_data_file_control_clone?.length > 0 ? rex_data_file_control_clone.map((r) => r.key) : rex_data_file_control_clone.map((r) => r.key) 
            : []
        );
    };

    const rowSelection = {
        columnTitle: true ? <ProfileTypesFilterSelect
                                    showDropDown={true}
                                    toggleSelectAll={toggleSelectAll}
                                    selectedRowKeys={null}
                                    dataAmount={rex_data_file_control_clone.length}
                                    setSelectedRowKeys={setSelectedRowKeys}
                                    data={rex_data_file_control_clone}
                                    valueCheckbox={selectedRowKeys.length == rex_data_file_control_clone.length}
                                    setValueCheckbox={() => console.log("ok")}
                                    actInacChechBox={false}
                                    themeColor='green'
        /> : <></>,
        selectedRowKeys,
        onChange: onSelectChange,
        type: "checkbox",
        fixed: true,
        onSelect: () => console.log("oko"),
    };

    return (
        <div className='Container-Main'>

            <Row style={{position: 'relative'}}>
                <Col span={24} style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <Title className="Title-Type-Profile">
                        Control de archivos
                    </Title>
                </Col>
                <Col span={24} style={{position: 'absolute', top: '6px'}}>
                    <NavigationGlobal menuItems={menuDataControl}/>
                </Col>
            </Row>
            <Row className='Users-Container-Actions'>
                <div style={{display:"flex", alignItems: "center", columnGap: "12px"}}>
                    <FilterSelectGlobal
                        data={rex_data_paises}
                        filterBy='país'
                        setTypFilter={setPaiFilter}
                        funtionFilter={(valueSelected, type) => {
                            filterFileControl(valueSelected, type)
                        }}
                    />
                    <TrashButtonGlobal
                        color='#013A81'
                        optionHover={true}
                        functionDelete={() => functionDelete(selectedRowKeys, setSelectedRowKeys)}
                    />
                    <div style={{marginLeft:"-10px", zIndex:"1"}}>
                        <DownloadButtonGlobal 
                            functionDownload={ async () => {
                                const link = document.createElement('a')
                                let url = await dispatch(DownloadDataFileControlReducer())
                                link.href = url
                                link.click()
                            }}
                            downloadFile={true}
                            color='#013A81'
                        />

                    </div>
                </div>
                <div style={{display: "flex", columnGap:"15px"}}>
                    <Pagination
                        total={rex_data_file_control_clone.length}
                        showTotal={(total, range) => `${range[0]}-${range[1]} de ${rex_data_file_control_clone.length}`}
                        defaultPageSize={pageSizeTableGlobal}
                        defaultCurrent={pageTableGlobal}
                        current={pageTableGlobal}
                        className='Simple-Table-Pagination-Global'
                        onChange={(page, pageSize) => {
                            setPageTableGlobal(page == 0? 1 : page)
                            setPageSizeTableGlobal(pageSize)
                        }}
                    />
                </div>
            </Row>
            <SimpleTableGlobal
                data={rex_data_file_control_clone}
                keyRowAction={keyRowAction}
                updateCurrentData={true}
                setCurrentData={setCurrentData}
                rowSelection={rowSelection}
                columns={columns}
                pageTableGlobal={pageTableGlobal}
                pageSizeTableGlobal={pageSizeTableGlobal}
                actionEnterRow={setMouseOverRow}
                actionLeaveRow={() => console.log("ok")}
                loadingData={rex_loading_file_control}
            />
            <Modal
                open={showModal}
                title={<span className={`${infoModal.status ? "Text-Ok" : "Text-Alert"}`}>{infoModal.title}</span>}
                width={300}
                zIndex={100000000}
                className={`Modal-Create-Data-Control ${infoModal.small && 'Modal-Small'}`}
                footer={[
                    <div style={{display:"flex", justifyContent:"center"}}>
                        <div className={`Button-Close Button${infoModal.status ? "-Ok" : "-Alert"}`} onClick={() => statusModal()}>
                            Ok
                        </div>
                    </div>
                ]}
            >
                <div className='Modal-Description'>
                    {infoModal.description}
                </div>
            </Modal>
            <SideMenu
                usersCurrentView  = {rex_users_conected_dashboard}
                usersAllConnected ={rex_data_users_online}
            />
        </div>
    )
}

export default FileControl