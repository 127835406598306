import React, { useState } from 'react'

function CloseButton({size}){
    return (

        <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M8.23614 6.99774L10.4989 4.73265C10.6458 4.56568 10.7236 4.34903 10.7165 4.12674C10.7094 3.90445 10.6179 3.69321 10.4607 3.53595C10.3034 3.37868 10.0922 3.28721 9.86988 3.28011C9.64759 3.27301 9.43094 3.35083 9.26397 3.49774L6.99889 5.76049L4.72972 3.49074C4.64844 3.40946 4.55195 3.34499 4.44576 3.301C4.33956 3.25701 4.22575 3.23438 4.1108 3.23438C3.99586 3.23437 3.88204 3.25701 3.77585 3.301C3.66965 3.34499 3.57316 3.40946 3.49189 3.49074C3.41061 3.57202 3.34614 3.66851 3.30215 3.7747C3.25816 3.88089 3.23552 3.99471 3.23552 4.10966C3.23552 4.2246 3.25816 4.33842 3.30215 4.44461C3.34614 4.5508 3.41061 4.64729 3.49189 4.72857L5.76164 6.99774L3.49889 9.26224C3.41014 9.34157 3.33852 9.43816 3.2884 9.54612C3.23827 9.65408 3.2107 9.77113 3.20737 9.89011C3.20404 10.0091 3.22502 10.1275 3.26903 10.2381C3.31304 10.3487 3.37915 10.4491 3.46331 10.5333C3.54748 10.6175 3.64793 10.6836 3.75852 10.7276C3.86912 10.7716 3.98753 10.7926 4.10651 10.7893C4.22549 10.7859 4.34254 10.7583 4.4505 10.7082C4.55846 10.6581 4.65506 10.5865 4.73439 10.4977L6.99889 8.23499L9.26105 10.4977C9.4252 10.6619 9.64783 10.7541 9.87997 10.7541C10.1121 10.7541 10.3347 10.6619 10.4989 10.4977C10.663 10.3336 10.7552 10.111 10.7552 9.87882C10.7552 9.64668 10.663 9.42405 10.4989 9.25991L8.23614 6.99774Z" fill="#013A81"/>
        </svg>
    )
}

const CloseIconGlobal = ({ size=14, mouseOver }) => {


    return (
        <CloseButton size={size}/>
    )
}

export default CloseIconGlobal