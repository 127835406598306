import React from 'react'
import Top from '../../Components/Menu/Top'

const TopMenu = () => {

    return (
        <Top/>
    )
}

export default TopMenu