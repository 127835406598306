import React, { useRef, useState } from 'react'
import { Link } from 'react-router-dom'
import { DownOutlined } from '@ant-design/icons'
import { DescargarPageOneDashboardReducer } from '../../../Redux/Actions/FileUpload/FileUpload'
import { useDispatch } from 'react-redux'

const CardSubModuleItem5 = ({submodule, modid, activeSub, setActiveSub, activeSubText, setActiveSubText, activeCard, setActiveCard, index}) => {
    const [activeSubModule, setActiveSubModule] = useState(false)
    const [timerAnimation, setTimerAnimation] = useState(null)
    const titleModule = useRef(null)

    const dispatch = useDispatch()
    const timeListAnimation = [
        {title: '.active-sub-0', timer: 1400},
        {title: '.active-sub-1', timer: 1400},
        {title: '.active-sub-2', timer: 1400},
        {title: '.active-sub-3', timer: 2000},
        {title: '.active-sub-4', timer: 2000},
        {title: '.active-sub-0.card-1', timer: 1400},
        {title: '.active-sub-0.card-2', timer: 1400},
        {title: '.active-sub-0.card-3', timer: 1700},
        {title: '.active-sub-0.card-4', timer: 1400},
        {title: '.active-sub-1.card-0', timer: 1400},
        {title: '.active-sub-1.card-2', timer: 1300},
        {title: '.active-sub-1.card-3', timer: 1550},
        {title: '.active-sub-1.card-4', timer: 1800},
        {title: '.active-sub-2.card-0', timer: 1400},
        {title: '.active-sub-2.card-1', timer: 1300},
        {title: '.active-sub-2.card-3', timer: 1400},
        {title: '.active-sub-2.card-4', timer: 1700},
        {title: '.active-sub-3.card-0', timer: 1700},
        {title: '.active-sub-3.card-1', timer: 1700},
        {title: '.active-sub-3.card-2', timer: 1300},
        {title: '.active-sub-3.card-4', timer: 2000},
        {title: '.active-sub-4.card-0', timer: 1300},
        {title: '.active-sub-4.card-1', timer: 1700},
        {title: '.active-sub-4.card-2', timer: 1700},
        {title: '.active-sub-4.card-3', timer: 2000},
    ]

    const activeTimerAnimation = () => {
        const timerId = setTimeout(() => {
            if(activeSub !== index){
                setActiveSub(index)
                if(activeCard){
                    setActiveSubText(`active-sub-${activeSub}`)
                    setActiveCard(`card-${index}`)
                }else{
                    if(activeSubText == "active-sub-0" || activeSubText == "active-sub-1" || activeSubText == "active-sub-2" || activeSubText == "active-sub-3" || activeSubText == "active-sub-4"){
                        setActiveCard(`card-${index}`)
                    }else{
                        setActiveSubText(`active-sub-${index}`)
                        setActiveCard("")
                    }
                }
            }
        }, 500)
    
        setTimerAnimation(timerId)
    }

    const timerCanceled = () => {
        if (timerAnimation) {
            clearTimeout(timerAnimation)
        }
    }

    return (
        <div 
            className={`Card-Submodule-Item ${activeSub == index ? 'active-submodule-item' : ''} ${activeSubText} ${activeCard}`}
            onMouseLeave={() => setActiveSubModule(false)}
            onMouseOver={() => {
                setActiveSubModule(true)
                if(submodule.children.length == 0){
                    activeTimerAnimation()
                }
            }}
            onMouseOut={() => {
                if(submodule.children.length == 0){
                    timerCanceled()
                }
            }}
        >
            <div className="Container-Header-Submodule" style={{height: activeSub == index ? `${titleModule.current.clientHeight + 40}px` : '102px'}}>
                {
                    submodule.smoruta.includes('br-corporativo')
                    ?
                        <div 
                            className="Text-Submodule" 
                            ref={titleModule}
                            onClick={async () => {
                                const fileUrl = await dispatch(DescargarPageOneDashboardReducer('br-corporativo'))
                                const link = document.createElement('a')
                                link.href = fileUrl
                                link.download = 'BusinessReview.pdf'
                                link.click()
                            }}
                            style={{
                                bottom: activeSub == index
                                    ? `calc(100% - ${titleModule.current.clientHeight}px)` 
                                    : activeSubModule 
                                        ? `calc(100% - ${titleModule.current.clientHeight}px)` 
                                        : 0,
                                transition: 'bottom .5s',
                                cursor:'pointer'
                            }}
                        >{submodule.smonombre}</div>
                    : submodule.smoruta.includes('br-canal')?
                    <div 
                        className="Text-Submodule" 
                        onClick={async () => {
                            const fileUrl = await dispatch(DescargarPageOneDashboardReducer('br-canal'))
                            const link = document.createElement('a')
                            link.href = fileUrl
                            link.download = 'BRCanalAASS.pdf'
                            link.click()
                        }}
                        ref={titleModule} 
                        style={{
                            bottom: activeSub == index
                                ? `calc(100% - ${titleModule.current.clientHeight}px)` 
                                : activeSubModule 
                                    ? `calc(100% - ${titleModule.current.clientHeight}px)` 
                                    : 0,
                            transition: 'bottom .5s',
                            cursor:'pointer'
                        }}
                    >{submodule.smonombre}</div>
                    : submodule.smoruta.includes('br-cliente-1') ?
                    <div 
                        className="Text-Submodule" 
                        ref={titleModule}
                        onClick={async () => {
                            const fileUrl = await dispatch(DescargarPageOneDashboardReducer('br-cliente-uno'))
                            const link = document.createElement('a')
                            link.href = fileUrl
                            link.download = 'BRCadena1.pdf'
                            link.click()
                        }}
                        style={{
                            bottom: activeSub == index
                                ? `calc(100% - ${titleModule.current.clientHeight}px)` 
                                : activeSubModule 
                                    ? `calc(100% - ${titleModule.current.clientHeight}px)` 
                                    : 0,
                            transition: 'bottom .5s',
                            cursor:'pointer'
                        }}
                    >{submodule.smonombre}</div>
                    : submodule.smoruta.includes('br-cliente-2') ? <a href='' target='_blank'>
                    <div 
                        className="Text-Submodule" 
                        ref={titleModule} 
                        style={{
                            bottom: activeSub == index
                                ? `calc(100% - ${titleModule.current.clientHeight}px)` 
                                : activeSubModule 
                                    ? `calc(100% - ${titleModule.current.clientHeight}px)` 
                                    : 0,
                            transition: 'bottom .5s',
                        }}
                    >{submodule.smonombre}</div>
                    </a>
                    : submodule.smoruta.includes('br-cliente-3') ? 
                    <a href='' target='_blank'>
                        <div 
                            className="Text-Submodule" 
                            ref={titleModule} 
                            style={{
                                bottom: activeSub == index
                                    ? `calc(100% - ${titleModule.current.clientHeight}px)` 
                                    : activeSubModule 
                                        ? `calc(100% - ${titleModule.current.clientHeight}px)` 
                                        : 0,
                                transition: 'bottom .5s',
                            }}
                        >{submodule.smonombre}</div>
                    </a>
                    :<Link to={submodule.smorutaabsoluta ? `/${submodule.smoruta}` :  `/dashboards/modulo/${modid}/${submodule.smoruta}`}>
                        <div 
                            className="Text-Submodule"
                            ref={titleModule} 
                            style={{
                                bottom: activeSub == index
                                    ? `calc(100% - ${titleModule.current.clientHeight}px)` 
                                    : activeSubModule 
                                        ? `calc(100% - ${titleModule.current.clientHeight}px)` 
                                        : 0,
                                transition: 'bottom .5s',
                            }}
                        >
                            {submodule.smonombre}
                        </div>
                    </Link>
                }
                
            </div>
            {
                submodule.children.length > 0 ?
                <>
                    <div 
                        onClick={() => {
                            if(activeSub == index){
                                setActiveSub(null)
                                setActiveSubText(`inactive-sub-${index}`)
                                setActiveCard("")
                            }else{
                                setActiveSub(index)
                                if(activeCard){
                                    setActiveSubText(`active-sub-${activeSub}`)
                                    setActiveCard(`card-${index}`)
                                }else{
                                    if(activeSubText == "active-sub-0" || activeSubText == "active-sub-1" || activeSubText == "active-sub-2" || activeSubText == "active-sub-3" || activeSubText == "active-sub-4"){
                                        setActiveCard(`card-${index}`)
                                    }else{
                                        setActiveSubText(`active-sub-${index}`)
                                        setActiveCard("")
                                    }
                                }
                            }
                        }} 
                        className="Container-Icon-Submodule"
                    >
                        <DownOutlined 
                            className="Icon-Submodule-Item" 
                            rotate={activeSub == index ? 180 : 0}
                        />
                    </div>
                    {
                        activeSub == index &&
                        submodule.children.map((ssm, index) => 
                        <div 
                            className="Container-Text-SubSubmodule"
                            key={index}
                        >
                            <span className="Icon-SubSubmodule"></span>
                            <Link to={ssm.rutaabsoluta ? `/${ssm.ssmruta}` : `/dashboards/modulo/${modid}/submodulo/${submodule.smoid}/${ssm.ssmruta}`}>
                                <div className="Text-SubSubmodule">
                                    {ssm.ssmnombre}
                                    <span className="Underline-SubSubmodule"></span>
                                </div>
                            </Link>
                        </div>)
                    }
                </>
                :   submodule.smoruta.includes('creciendo-juntos')
                ?   <a href='https://smartview.grow-corporate.com' target='_blank'>
                        <div className="Container-Icon-Without-Submodule">
                            <DownOutlined 
                                className="Icon-Submodule-Without-Item" 
                                rotate={270}
                            />
                        </div>
                    </a>
                :  submodule.smoruta.includes('innovacion')
                ? <a href='https://spider.grow-corporate.com/Sistema/simplePowerbi' target='_blank'>
                    <div className="Container-Icon-Without-Submodule">
                        <DownOutlined 
                            className="Icon-Submodule-Without-Item" 
                            rotate={270}
                        />
                    </div>
                </a>
                :   <Link to={submodule.smorutaabsoluta ? `/${submodule.smorutaabsoluta}` : `/dashboards/modulo/${modid}/${submodule.smoruta}`}>
                    <div className="Container-Icon-Without-Submodule">
                        <DownOutlined 
                            className="Icon-Submodule-Without-Item" 
                            rotate={270}
                        />
                    </div>
                </Link>
            }
        </div>
    )
}

export default CardSubModuleItem5