import React from 'react'
import { useDispatch } from 'react-redux'
import { ChangeOrderModulesReducer } from '../../../Redux/Actions/Modules/Modules'
import { Col } from 'antd'
import { DndContext, closestCenter } from '@dnd-kit/core'
import { arrayMove, SortableContext, rectSortingStrategy } from '@dnd-kit/sortable'
import TabListModules from './TabListModules'

const TabModules = (props) => {
    const modules = props.modules
    const type = props.type
    const dispatch = useDispatch()

    const DragDropModule = (event) => {
        const { active, over } = event
        dispatch(ChangeOrderModulesReducer(active, over, arrayMove, type))
    }
    
    return(
        <DndContext
            collisionDetection={closestCenter}
            onDragEnd={DragDropModule}
        >
            <SortableContext
                items={modules.map(m => m.key)}
                strategy={rectSortingStrategy}
            >
                {
                    modules.map((module, index) => 
                        <Col span={24} key={index}>
                            <TabListModules 
                                id={module.key} 
                                module={module} 
                                type={type}
                            />
                        </Col>
                    )
                }
            </SortableContext>
        </DndContext>
    )
}

export default TabModules