import React, { useState } from 'react'
import { useNavigate} from "react-router-dom";
import ButtonBack from '../../Assets/Images/components/Menu/Button/ButtonBack'

const ButtonBackMenu = () => {

    const [ overButtonBack, setOverButtonBack ] = useState(null)

    let navigate = useNavigate()

    return (
        <>
            <div
                className='Perfil-Button-Back'
                onMouseLeave={() => setOverButtonBack(null)}
                onMouseOver={() => setOverButtonBack('over')}
                onClick={() => navigate(-1)}
            >
                <ButtonBack over={overButtonBack}/>
            </div>
        </>

    )
}

export default ButtonBackMenu