import {
    GET_DATA_FILE_CONTROL,
    GET_DATA_FILE_CONTROL_CLONE,
    GET_DATA_TYPES_FILE_CONTROL,
    LOADING_FILE_CONTROL
} from "../../../Constants/FileControl/FileControl"


const INIT_STATE = {
    rex_data_file_control       : [],
    rex_data_file_control_clone : [],
    rex_data_types_file_control : [],
    rex_loading_file_control    : false
}

export default (state = INIT_STATE, action) => {

    switch (action.type) {
        case LOADING_FILE_CONTROL : {
            return {
                ...state,
                rex_loading_file_control : action.payload,
            }
        }
        case GET_DATA_FILE_CONTROL : {
            return {
                ...state,
                rex_data_file_control : action.payload,
            }
        }
        case GET_DATA_FILE_CONTROL_CLONE : {
            return {
                ...state,
                rex_data_file_control_clone : action.payload,
            }
        }
        case GET_DATA_TYPES_FILE_CONTROL : {
            return {
                ...state,
                rex_data_types_file_control : action.payload,
            }
        }
        default:
            return state;
    }
}