import config from '../../../config'
import {
    OBTENER_DATA_MODULO_SELECCIONADO_DASHBOARDS,
    OBTENER_DATA_OTROS_MODULO_SELECCIONADO_DASHBOARDS,
    OBTENER_DATA_ITEMS_SLIDER_GLOBAL_DASHBOARDS,
    REGISTRAR_ACTIVIDAD_MODULO_DASHBOARDS,
    REGISTRAR_ID_MODULO_SELECCIONADO_DASHBOARDS,
    REGISTRAR_DRIID_MODULO_DASHBOARDS,
    REGISTRAR_RISID_MODULO_DASHBOARDS,
    REGISTRAR_TIPO_MODULO_DASHBOARDS,
    PRELOAD_ANIMATION,
    OBTENER_ITEMS_OTROS_MODULOS_DASHBOARDS,
    GET_SUBMODULE_DASHBOARD,
    DATA_DASHBOARD,
    TITLE_MODULE,
    TITLE_SUBMODULE,
    TITLE_SUBSUBMODULE,
    TOKEN_ACCESO_POWER_BI,
} from "../../../Constants/Dashboards/Dashboards"
import { Link } from 'react-router-dom'
import socket from '../../../App/socket'
import { ValidationUserReducer } from '../Validations/Validations'
import { RegisterAuditReducer } from '../Audits/Audits'
import SoundOption from '../../../Assets/Audio/sonidoOptionNew.mp3'

// export const ActualizarModuloSeleccionadoDashboardsReducer = (url) => async ( dispatch, getState ) => {
//     let modulos = getState().dashboards.rex_data_modulos_dashboards_total

//     let modulo_seleccionado
//     let modulo_otros_seleccionado
//     let items = []

//     await modulos.map((mod ) => {
//         if(mod.url == url){
//             modulo_seleccionado = mod
//             modulo_otros_seleccionado = modulos
//         }
//         if(mod['children']){
//             mod['children'].map(( sub ) => {
//                 if(sub.url == url){
//                     modulo_seleccionado = sub
//                     modulo_otros_seleccionado = mod['children']
//                 }
                
//                 if(sub['children']){
//                     sub['children'].map(( sus ) => {
//                         if(sus.url == url){
//                             modulo_seleccionado = sus
//                             modulo_otros_seleccionado = sub['children']
//                         }
//                     })
//                 }
//             })
//         }
//     })

//     modulo_otros_seleccionado.map((mods, index) => (
//         items.push({
//             nombre: mods.nombre,
//             key: index,
//             label:
//                 <Link
//                     to={mods.url}
//                     title={mods.nombre}
//                     style={{color: window.location.pathname == mods.url && 'gray'}}
//                     onClick={() => dispatch(SeleccionarModuloDashboardsReducer(mods, modulo_otros_seleccionado))}
//                 >
//                     {mods.nombre}
//                 </Link>,
//             link: mods.url,
//             disabled: window.location.pathname == mods.url ? true : false
//         })
//     ))

//     dispatch({
//         type: OBTENER_DATA_ITEMS_SLIDER_GLOBAL_DASHBOARDS,
//         payload: items
//     })
//     dispatch({
//         type: OBTENER_DATA_OTROS_MODULO_SELECCIONADO_DASHBOARDS,
//         payload: modulo_otros_seleccionado
//     })
//     // dispatch({
//     //     type: OBTENER_DATA_MODULO_SELECCIONADO_DASHBOARDS,
//     //     payload: modulo_seleccionado
//     // })
// }

export const ObtenerDataModulosDashboardsReducer = (id, idsub, route, soli_token) => async (dispatch, getState) => {

    await dispatch(ValidationUserReducer())

    let response = false

    const usuario = getState().loginGrow.rex_data_user_logingrow
    let urlfetch
    if(idsub){
        urlfetch = 'modules/get-subsubmodule'
    }else{
        urlfetch = 'modules/get-submodule'
    }

    dispatch({
        type    : TOKEN_ACCESO_POWER_BI,
        payload : null
    })

    await fetch(config.api+urlfetch,
        {
            mode:'cors',
            method: 'POST',
            headers: {
                'Accept' : 'application/json',
                'Content-type' : 'application/json',
                'usutoken'	   : localStorage.getItem('usutoken'),
            },
            body: JSON.stringify({ 
                req_id      : idsub ? idsub : id,
                soli_token  : soli_token
            }),
        }
    )
    .then(async res => {
        return res.json()
    })
    .then(async data => {
        if(data.response == true){
            socket.emit('reg-dashboards', data.data, {usucorreo: usuario.usucorreo});
            
            if(soli_token){
                dispatch({
                    type    : TOKEN_ACCESO_POWER_BI,
                    payload : data.tempToken
                })
            }

            dispatch({
                type    : DATA_DASHBOARD,
                payload : data.data
            })


            if(idsub){

                const subsubmoduleUnique = data.data.ssmsubmodulos.find(s => s.ssmruta == route)

                dispatch({
                    type    : TITLE_MODULE,
                    payload : data.data.modnombre
                })
                dispatch({
                    type    : TITLE_SUBMODULE,
                    payload : data.data.smonombre
                })
                dispatch({
                    type    : TITLE_SUBSUBMODULE,
                    payload : subsubmoduleUnique.ssmnombre
                })
                
                dispatch({
                    type    : OBTENER_DATA_MODULO_SELECCIONADO_DASHBOARDS,
                    payload : subsubmoduleUnique
                })
                dispatch({
                    type    : REGISTRAR_ID_MODULO_SELECCIONADO_DASHBOARDS,
                    payload : subsubmoduleUnique.ssmid
                })

                await dispatch(StoreFilterSubSubModuleReducer(data.data, route, false))
            }else{

                const submoduleUnique = data.data.smosubmodulos.find(s => s.smoruta == route)

                dispatch({
                    type    : TITLE_MODULE,
                    payload : data.data.modnombre
                })
                dispatch({
                    type    : TITLE_SUBMODULE,
                    payload : submoduleUnique.smonombre
                })
                dispatch({
                    type    : TITLE_SUBSUBMODULE,
                    payload : null
                })

                dispatch({
                    type    : OBTENER_DATA_MODULO_SELECCIONADO_DASHBOARDS,
                    payload : submoduleUnique
                })
                dispatch({
                    type    : REGISTRAR_ID_MODULO_SELECCIONADO_DASHBOARDS,
                    payload : submoduleUnique.smoid
                })
                await dispatch(StoreFilterSubModuleReducer(data.data, route, false))
                response = true
            }
        }else{
        }

    }).catch((error)=> {
        console.log(error)
    })

    dispatch({
        type: PRELOAD_ANIMATION,
        payload: true
    })
    return response
}

export const StoreFilterSubModuleReducer = (data, url) => async ( dispatch, getState ) => {
    const audio = new Audio(SoundOption)
    const submoduleUnique = data.smosubmodulos.find(s => s.smoruta == url)
    dispatch({
        type: GET_SUBMODULE_DASHBOARD,
        payload : submoduleUnique
    })

    let items = []

    const usuario = getState().loginGrow.rex_data_user_logingrow

    data.smosubmodulos.map(smoc => {
        items.push({
            link  : smoc.smoruta,
            key   : smoc.smoid,
            disabled: url == smoc.smoruta ? true : false,
            label : (
                <Link
                    to={`/dashboards/modulo/${data.modid}/${smoc.smoruta}`}
                    onClick={() => {
                        socket.emit('unreg-dashboards', submoduleUnique, {usucorreo: usuario.usucorreo} );
                        dispatch(ObtenerDataModulosDashboardsReducer(data.modid, undefined, smoc.smoruta))
                    }}
                    onMouseEnter={() => {
                        audio.play()
                    }}
                    title={smoc.smonombre}
                >
                    <div className={`Text-Button-Dropdown ${url == smoc.smoruta && "Item-Selected"}`}>
                        <div className={`${url == smoc.smoruta ? "Round-Button-Selected" : "Round-Button"} `}></div>
                        <span>{smoc.smonombre}</span>
                    </div>
                </Link>
              )
        })
    })

    dispatch({
        type: OBTENER_DATA_ITEMS_SLIDER_GLOBAL_DASHBOARDS,
        payload : items
    })

    return true
}

export const StoreFilterSubSubModuleReducer = (data, url) => async ( dispatch, getState) => {

    const audio = new Audio(SoundOption)
    const submoduleUnique = data.ssmsubmodulos.find(s => s.ssmruta == url)
    dispatch({
        type: GET_SUBMODULE_DASHBOARD,
        payload : submoduleUnique
    })

    let items = []

    const usuario = getState().loginGrow.rex_data_user_logingrow

    data.ssmsubmodulos.map(ssm => {
        items.push({
            link  : ssm.ssmruta,
            key   : ssm.ssmid,
            disabled: url == ssm.ssmruta ? true : false,
            label : (
                <Link
                    to={`/dashboards/modulo/${data.modid}/submodulo/${data.smoid}/${ssm.ssmruta}`}
                    onClick={() => {
                        socket.emit('unreg-dashboards', submoduleUnique, {usucorreo: usuario.usucorreo} )
                        dispatch(ObtenerDataModulosDashboardsReducer(data.modid, data.smoid, ssm.ssmruta))
                    }}
                    onMouseEnter={() => {
                        audio.play()
                    }}
                    title={ssm.ssmnombre}
                >
                    <div className="Text-Button-Dropdown">
                        <div className="Round-Button"></div>
                        <span>{ssm.ssmnombre}</span>
                    </div>
                </Link>
              )
        })
    })

    dispatch({
        type: OBTENER_DATA_ITEMS_SLIDER_GLOBAL_DASHBOARDS,
        payload : items
    })

    return true
}

export const RegistrarEstadoModuloDashboardReducer = ( estado ) => async ( dispatch, getState ) => {

    dispatch({
        type: REGISTRAR_ACTIVIDAD_MODULO_DASHBOARDS,
        payload: estado
    })

    // dispatch({
    //     type: REGISTRAR_DRIID_MODULO_DASHBOARDS,
    //     payload : 0
    // })

    dispatch(RegistrarIngresoModuloDashboardReducer())
}

export const SeleccionarTipoModuloDashboardsReducer = (idsub) => async (dispatch, getState) => {
    if(idsub){
        dispatch({
            type: REGISTRAR_TIPO_MODULO_DASHBOARDS,
            payload: 'ssm'
        })
    }else{
        dispatch({
            type: REGISTRAR_TIPO_MODULO_DASHBOARDS,
            payload: 'smo'
        })
    }

    dispatch({
        type: REGISTRAR_RISID_MODULO_DASHBOARDS,
        payload: 0
    })
    dispatch({
        type: REGISTRAR_DRIID_MODULO_DASHBOARDS,
        payload: 0
    })

    return true
}

export const RegistrarActividadModuloDashboardReducer = ( mod, estado, url, id, idsub, route ) => async ( dispatch, getState ) => {

    let id_modulo
    if(mod.length == 0){
        await dispatch(ObtenerDataModulosDashboardsReducer(id, idsub, route, false))
        const modSel = getState().dashboards.rex_data_modulo_seleccionado_dashboards
        id_modulo = modSel.ssmid? modSel.ssmid : modSel.smoid
    }else{
        id_modulo = mod.ssmid? mod.ssmid : mod.smoid
    }

    if(estado){
        dispatch({
            type: REGISTRAR_RISID_MODULO_DASHBOARDS,
            payload: 0
        })
    }
    dispatch({
        type : REGISTRAR_ID_MODULO_SELECCIONADO_DASHBOARDS,
        payload : id_modulo
    })

}

export const EnviarCorreoDashboardReducer = (data) => async ( dispatch, getState ) => {

    await fetch(config.api+'data/download-dashboard',
    {
        mode:'cors',
        method: 'POST',
        headers: {
            'Accept' : 'application/json',
            'Content-type' : 'application/json',
            'usutoken'	   : localStorage.getItem('usutoken'),
        },
        body: JSON.stringify({
            req_to      : data.to,
            req_message    : data.message,
            req_subject : data.subject
        }),
    }
    )
    .then( async res => {
        // await dispatch(estadoRequestReducer(res.status))
        return res.json()
    })
    .then(data => {

    }).catch((error)=> {
        console.log(error)
    });
    return true
}
export const EnviarFileDashboardReducer = (data) => async ( dispatch, getState ) => {
    let url = ""
    await fetch(config.api+'data/download-file-ds',
    {
        mode:'cors',
        method: 'GET',
        headers: {
            'Accept' : 'application/json',
            'Content-type' : 'application/json',
            'usutoken'	   : localStorage.getItem('usutoken'),
        },
    }
    )
    .then(async res => {
		return res.blob()
    })
    .then((blob) => {
        url = URL.createObjectURL(blob)
    })
    .catch((error)=> {
        // notificacionServidor('error', 'Error con el servidor. Vuelva a ingresar más tarde.')
    })

    return url
}

export const RegistrarIngresoModuloDashboardReducer = () => async ( dispatch, getState ) => {

    const estado_modulo = getState().dashboards.rex_registrar_actividad_modulo_dashboards
    const risid         = getState().dashboards.rex_risid_modulo_seleccionado_dashboards
    const id_submodulo     = getState().dashboards.rex_id_selecionado_modulo_dashboards
    const driid         = getState().dashboards.rex_driid_selecionado_modulo_dashboards
    const tipo         = getState().dashboards.rex_seleccionar_tipo_modulo_dashboards

    const data_module_register_activity = {
        req_risid     : risid,
        req_driid     : driid,
        req_idmod     : id_submodulo,
        req_estado    : estado_modulo,
        req_tipo      : tipo == null ? "smo" : tipo
    }


    await fetch(config.api+'analytics/audit/register-activity-module',
    {
        mode:'cors',
        method: 'POST',
        headers: {
            'Accept' : 'application/json',
            'Content-type' : 'application/json',
            'usutoken'	   : localStorage.getItem('usutoken'),
        },
        body: JSON.stringify(data_module_register_activity),
    }
    )
    .then( async res => {
        // await dispatch(estadoRequestReducer(res.status))
        return res.json()
    })
    .then(data => {

        if(data.response){
            dispatch({
                type:REGISTRAR_DRIID_MODULO_DASHBOARDS,
                payload: data.driid
            })
            dispatch({
                type: REGISTRAR_RISID_MODULO_DASHBOARDS,
                payload: data.risid
            })
        }else{
            // dispatch(RegisterAuditReducer('analytics/audit/register-activity-module', 'Error al registrar la actividad del módulo', data.message, data_module_register_activity, data, usuario.usuid, 'ip', 'REGISTER ACTIVITY MODULE'))
        }
    }).catch((error)=> {
        console.log(error)
        // dispatch(RegistrarErrorReducer(error, config.api+'login', datos))
    });
}

// export const SeleccionarModuloDashboardsReducer = ( mod, children ) => async ( dispatch, getState ) => {

//     let items = []

//     await children.map((mods, index) => (
//         items.push({
//             nombre: mods.nombre,
//             key: index,
//             label:
//                 <Link
//                     to={mods.url}
//                     title={mods.nombre}
//                     style={{color: mods.url == mod.url && 'gray'}}
//                     onClick={() => dispatch(SeleccionarModuloDashboardsReducer(mods, children))}
//                 >
//                     {mods.nombre}
//                 </Link>,
//             link: mods.url,
//             disabled: mods.url == mod.url ? true : false
//         })
//     ))

//     dispatch({
//         type: OBTENER_DATA_ITEMS_SLIDER_GLOBAL_DASHBOARDS,
//         payload: items
//     })

//     dispatch({
//         type: OBTENER_DATA_OTROS_MODULO_SELECCIONADO_DASHBOARDS,
//         payload: children
//     })
//     dispatch({
//         type: OBTENER_DATA_MODULO_SELECCIONADO_DASHBOARDS,
//         payload: mod
//     })
// }

// export const ActualizarItemsOtrosModulosSeleccionados = (smo) => async ( dispatch, getState ) => {

//     let items = []

//     const smo_seleccionado = getState().dashboards.rex_data_modulo_seleccionado_dashboards
//     const usuario = getState().loginGrow.rex_data_user_logingrow

//     smo.children.map(smo => {
//         items.push({
//             link  : smo.smoruta,
//             key   : smo.smoid,
//             disabled: smo_seleccionado.smoruta == smo.smoruta ? true : false,
//             label : (
//                 <Link
//                     to={smo.smoruta + "/" + smo.modid}
//                     onClick={() => {
//                         socket.emit('unreg-dashboards', smo_seleccionado, {usucorreo: usuario.usucorreo} );
//                         dispatch(ObtenerDataModulosDashboardsReducer(smo.smoruta, {"*": `${smo.smoruta}/${smo.modid}`}))
//                     }}
//                 >
//                     {smo.smonombre}
//                 </Link>
//             )
//         })
//     })

//     dispatch({
//         type: OBTENER_ITEMS_OTROS_MODULOS_DASHBOARDS,
//         payload : items

//     })
// }
    
export const ActivarVozReducer = () => async ( dispatch, getState ) => {
    // await fetch(config.api+'auth/voice-welcome',
    //     {
    //         mode:'cors',
    //         method: 'GET',
    //         headers: {
    //             'Accept' 	   : 'application/json',
    //             'Content-type' : 'application/json',
    //             'usutoken'	   : localStorage.getItem('usutoken'),
    //         }
    //     }
    // )
    // .then( async res => {
    // }).catch((error)=> {
    //     console.log(error)
    // })
}