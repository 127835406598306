import React, { useState } from 'react'

function OptionButton({fill, stroke}){
    return (

        <svg width="9" height="5" viewBox="0 0 9 5" fill="none" xmlns="http://www.w3.org/2000/svg">
        
        <path d="M1.47429 0.875H7.52579C7.63291 0.875023 7.73761 0.906803 7.82666 0.966324C7.91572 1.02584 7.98513 1.11043 8.02612 1.2094C8.0671 1.30836 8.07783 1.41725 8.05694 1.52231C8.03605 1.62737 7.98448 1.72387 7.90875 1.79962L4.883 4.82537C4.78142 4.92691 4.64367 4.98396 4.50004 4.98396C4.35641 4.98396 4.21866 4.92691 4.11708 4.82537L1.09133 1.79962C1.0156 1.72387 0.964032 1.62737 0.943141 1.52231C0.92225 1.41725 0.932977 1.30836 0.973965 1.2094C1.01495 1.11043 1.08436 1.02584 1.17342 0.966324C1.26247 0.906803 1.36718 0.875023 1.47429 0.875Z" fill={fill}/>
        </svg>

    )
}

const ArrowSelectPopOver = ({onHover, themeColor='blue'}) => (

    themeColor == "blue" ? 
        <div style={{margin:'0 4px', display:'flex',alignItems:'center'}}>
            <OptionButton fill={onHover == true ? '#3B72FF' : '#013A81'}/>
        </div> 
    :   <div style={{margin:'0 4px', display:'flex',alignItems:'center'}}>
            <OptionButton fill={onHover == true ? '#2AD295' : '#2AD295'}/>
        </div> 
)

export default ArrowSelectPopOver