import React from 'react'
import { Link } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { Collapse, Dropdown, Modal, Tooltip } from 'antd'
import { HolderOutlined } from '@ant-design/icons'
import { DndContext, closestCenter } from '@dnd-kit/core'
import { arrayMove, SortableContext, verticalListSortingStrategy, useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import TabListSubSubModules from './TabListSubSubModules'
import { ChangeDisabledCollapseSubModulesReducer, ChangeOrderSubSubModulesReducer, DeleteDataSubModuleReducer, GetDataModulesReducer } from '../../../Redux/Actions/Modules/Modules'

const TabListSubModules = (props) => {
    const submodule = props.submodule
    const moduleId = props.moduleId
    const lastSubmodule = props.lastSubmodule
    const idModule = props.idModule
    const lastSubSubmodule = submodule.children && submodule.children[submodule.children.length-1]
    const { Panel } = Collapse
    const { confirm } = Modal
    const dispatch = useDispatch()

    const { attributes, listeners, setNodeRef, transform, setActivatorNodeRef, isDragging } = useSortable({id: props.id})

    const style = {
        transform: CSS.Transform.toString(transform),
    }

    const styleCollapse = {
        border: isDragging ? '1px solid #EFEBE6' : '',
    }

    const styleContainer = {
        transform: CSS.Transform.toString(transform),
        border: isDragging ? '1px solid #EFEBE6' : '',
    }

    const styleButton = {
        touchAction: 'none',
        cursor: isDragging ? 'grabbing' : 'grab',
    }

    const items = [
        {
            key: '1',
            label:  <Link to={`/administrator/modules/edit-modules/${idModule}`}>
                    <span className="Text-Edit-Menu" >
                        Editar
                        <div className="Icon-Edit-Menu"></div>
                    </span>
                    </Link>,
        },
        {
            key: '2',
            label:  <span className="Text-Delete-Menu" onClick={() => deleteSubModule()}>
                        Eliminar 
                        <div className="Icon-Delete-Menu"></div>
                    </span>,
        },
    ]

    const DragDropSubSubModule = (event) => {
        const { active, over } = event
        dispatch(ChangeOrderSubSubModulesReducer(active, over, arrayMove, moduleId, submodule.key))
    }

    const deleteSubModule = () => {
        confirm({
            title: 'Confirmar',
            zIndex: 100000000,
            icon: <></>,
            content: '¿Estas seguro de eliminar el submódulo?',
            width: 260,
            centered: true,
            className: 'Modal-Delete-Module',
            okButtonProps: {className: 'Confirm-Modal-Delete-Module'},
            okText: <>
                        <span className="First-Confirm">Confirmar</span>
                        <span className="Last-Confirm">Confirmar</span>
                    </>,
            cancelButtonProps: {className: 'Cancel-Modal-Delete-Module'},
            cancelText: <>
                            <span className="First-Cancel">Cancelar</span>
                            <span className="Last-Cancel">Cancelar</span>
                        </>,
            async onOk() {
                const response = await dispatch(DeleteDataSubModuleReducer(submodule.smoid))
                if(response){
                    dispatch(GetDataModulesReducer())
                }
            },
            onCancel() {
            },
        })
    }

    return(
        <>
        {
            submodule.children.length > 0
            ?   <div
                    className={`Container-Collapse-Item-Sub-Modules ${lastSubmodule.key == submodule.key ? 'Div-Container' : ''}`}
                    ref={setNodeRef} 
                    style={style} 
                    {...attributes}
                >
                <Collapse
                    className={`Card-Item-Sub-Modules ${!submodule.smovisualizacion ? 'Disabled-Card-Item-Sub-Modules' : ''}`}
                    expandIconPosition="end"
                    collapsible='icon'
                    expandIcon={(e) => 
                        <Tooltip
                            placement='bottom'
                            title={e.isActive ? 'Cerrar' : 'Desplegar'}
                            color='#3B72FF'
                            showArrow={false}
                            overlayStyle={{marginTop:'20px', fontSize: '12px', minHeight: '30px'}}
                        >
                            <div className={`Icon-Expand-Item-Sub-Modules ${!submodule.smovisualizacion ? '' : e.isActive ? 'Expand-Rotate-Sub-Item-Modules' : ''}`}></div>
                        </Tooltip>
                    }
                    style={styleCollapse}
                >
                    <Panel 
                        header={
                            <>
                                <div className="Container-Text-Item-Sub-Modules">
                                    <Tooltip
                                        placement='bottom'
                                        title='Mover'
                                        color='#3B72FF'
                                        showArrow={false}
                                        overlayStyle={{marginTop:'20px', fontSize: '12px', minHeight: '30px'}}
                                    >
                                        <HolderOutlined className="Icon-DragDrop-Item-Sub-Modules" 
                                            ref={setActivatorNodeRef} 
                                            style={styleButton} 
                                            {...listeners} 
                                        />
                                    </Tooltip>
                                    <span className="Text-Items-Sub-Modules">{submodule.smonombre}</span>
                                </div>
                                <div className="Container-Icon-Item-Sub-Modules">
                                    {
                                        !submodule.smovisualizacion
                                            ?   <Tooltip
                                                    placement='bottom'
                                                    title='Habilitar'
                                                    color='#3B72FF'
                                                    showArrow={false}
                                                    overlayStyle={{marginTop:'20px', fontSize: '12px', minHeight: '30px'}}
                                                >
                                                    <div
                                                        onClick={() => dispatch(ChangeDisabledCollapseSubModulesReducer(true, moduleId, submodule.key))}
                                                        className="Icon-View-Disabled-Item-Sub-Modules"
                                                    ></div>
                                                </Tooltip>
                                            :   <Tooltip
                                                    placement='bottom'
                                                    title='Deshabilitar'
                                                    color='#3B72FF'
                                                    showArrow={false}
                                                    overlayStyle={{marginTop:'20px', fontSize: '12px', minHeight: '30px'}}
                                                >
                                                    <div
                                                        onClick={() => dispatch(ChangeDisabledCollapseSubModulesReducer(false, moduleId, submodule.key))}
                                                        className="Icon-View-Item-Sub-Modules"
                                                    ></div>
                                                </Tooltip>
                                    }
                                </div>
                            </>
                        }
                        extra={
                            <Tooltip
                                placement='bottom'
                                title='Opciones'
                                color='#3B72FF'
                                showArrow={false}
                                overlayStyle={{marginTop:'20px', fontSize: '12px', minHeight: '30px'}}
                            >
                                <Dropdown
                                    menu={{items}} 
                                    placement="bottomRight"
                                    overlayClassName="Menu-Item-Modules"
                                    trigger={['click']}
                                >
                                    <div className="Icon-Menu-Item-Sub-Modules"></div>
                                </Dropdown>
                            </Tooltip>
                        }
                        key={submodule.key}
                    >
                        <DndContext
                            collisionDetection={closestCenter}
                            onDragEnd={DragDropSubSubModule}
                        >
                            <SortableContext
                                items={submodule.children && submodule.children.map(subsubm => subsubm.key)}
                                strategy={verticalListSortingStrategy}
                            >
                                {
                                    submodule.children.map(subsubm => 
                                        <TabListSubSubModules 
                                            key={subsubm.key} 
                                            id={subsubm.key} 
                                            subsubm={subsubm} 
                                            lastSubSubmodule={lastSubSubmodule}
                                            idModule={idModule}
                                            moduleId={moduleId}
                                            idSubModule={submodule.key}
                                        />
                                    )
                                }
                            </SortableContext>
                        </DndContext>
                    </Panel>
                </Collapse>
                </div>
            :   <div 
                    ref={setNodeRef} 
                    style={styleContainer}
                    {...attributes}
                    className={`Container-Item-Sub-Modules ${lastSubmodule.key == submodule.key ? 'Div-Container' : ''} ${!submodule.smovisualizacion ? 'Disabled-Card-Item-Sub-Modules' : ''}`}
                >
                    <div className="Container-Text-Item-Sub-Modules">
                        <Tooltip
                            placement='bottom'
                            title='Mover'
                            color='#3B72FF'
                            showArrow={false}
                            overlayStyle={{marginTop:'20px', fontSize: '12px', minHeight: '30px'}}
                        >
                            <HolderOutlined 
                                className="Icon-DragDrop-Item-Sub-Modules"
                                ref={setActivatorNodeRef} 
                                style={styleButton} 
                                {...listeners} 
                            />
                        </Tooltip>
                        <span className="Text-Items-Sub-Modules">{submodule.smonombre}</span>
                    </div>
                    <div className="Container-Icon-Item-Sub-Modules">
                        {
                            !submodule.smovisualizacion
                                ?   <Tooltip
                                        placement='bottom'
                                        title='Habilitar'
                                        color='#3B72FF'
                                        showArrow={false}
                                        overlayStyle={{marginTop:'20px', fontSize: '12px', minHeight: '30px'}}
                                    >
                                        <div
                                            onClick={() => dispatch(ChangeDisabledCollapseSubModulesReducer(true, moduleId, submodule.key))}
                                            className="Icon-View-Disabled-Item-Sub-Modules"
                                        ></div>
                                    </Tooltip>
                                :   <Tooltip
                                        placement='bottom'
                                        title='Deshabilitar'
                                        color='#3B72FF'
                                        showArrow={false}
                                        overlayStyle={{marginTop:'20px', fontSize: '12px', minHeight: '30px'}}
                                    >
                                        <div
                                            onClick={() => dispatch(ChangeDisabledCollapseSubModulesReducer(false, moduleId, submodule.key))}
                                            className="Icon-View-Item-Sub-Modules"
                                        ></div>
                                    </Tooltip>
                        }
                        <Tooltip
                            placement='bottom'
                            title='Opciones'
                            color='#3B72FF'
                            showArrow={false}
                            overlayStyle={{marginTop:'20px', fontSize: '12px', minHeight: '30px'}}
                        >
                            <Dropdown
                                menu={{items}} 
                                placement="bottomRight"
                                overlayClassName="Menu-Item-Modules"
                                trigger={['click']}
                            >
                                <div className="Icon-Menu-Item-Sub-Modules"></div>
                            </Dropdown>
                        </Tooltip>
                    </div>
                </div>
        }
        </>
    )
}

export default TabListSubModules