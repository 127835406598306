import {
    OBTENER_GRAFICO_VISTAS_PROMEDIO_ANALYTICS,
    OBTENER_GRAFICO_VISTAS_PROMEDIOS_MINUTOS_ANALYTICS,
    OBTENER_GRAFICO_USUARIOS_GANADOS_ANALYTICS,
    OBTENER_GRAFICO_USUARIOS_PERDIDOS_ANALYTICS,
    OBTENER_GRAFICO_VISITAS_USUARIO_ANALYTICS,
    OBTENER_GRAFICO_SELECCIONADO_ANALYTICS
} from "../../../../Constants/Analytics/Graficos/GraficosAnalytics";

const INIT_STATE = {
    rex_grafico_visitas_promedio            : [],
    rex_grafico_vistas_promedios_minutos    : [],
    rex_grafico_usuarios_ganados            : [],
    rex_grafico_usuarios_perdidos           : [],
    rex_grafico_visitas_usuario             : [],
    rex_grafico_seleccionado                : []
};

export default (state = INIT_STATE, action) => {
    switch (action.type){

        case OBTENER_GRAFICO_SELECCIONADO_ANALYTICS: {
            return {
                ...state,
                rex_grafico_seleccionado : action.payload
            }
        }
        case OBTENER_GRAFICO_VISTAS_PROMEDIO_ANALYTICS: {
            return {
                ...state,
                rex_grafico_visitas_promedio : action.payload
            }
        }
        case OBTENER_GRAFICO_VISTAS_PROMEDIOS_MINUTOS_ANALYTICS: {
            return {
                ...state,
                rex_grafico_vistas_promedios_minutos : action.payload
            }
        }
        case OBTENER_GRAFICO_USUARIOS_GANADOS_ANALYTICS: {
            return {
                ...state,
                rex_grafico_usuarios_ganados : action.payload
            }
        }
        case OBTENER_GRAFICO_USUARIOS_PERDIDOS_ANALYTICS: {
            return {
                ...state,
                rex_grafico_usuarios_perdidos : action.payload
            }
        }
        case OBTENER_GRAFICO_VISITAS_USUARIO_ANALYTICS: {
            return {
                ...state,
                rex_grafico_visitas_usuario : action.payload
            }
        }
        default:
            return state;
    }
}