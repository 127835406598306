
import notificationGlobal from "../../../../Components/Global/NotificationGlobal";
import config from "../../../../config";
import {
    GET_DATA_MSL_MANAGE,
    GET_DATA_MSL_MANAGE_CLONE,
    GET_DATA_CHANNELS_MSL_MANAGE,
    SHOW_MODAL_NOTIFICATIONS_ALERT,
    DATA_MODAL_NOTIFICATIONS_ALERT,
    GET_DATA_MSL_MANAGE_CURRENT_SOURCE,
    LOADING_DATA_MSL_MANAGE
} from "../../../../Constants/Manage/MSL/MSL";
import { RegisterAuditReducer } from "../../Audits/Audits";
import dayjs from 'dayjs';

export const GetMslManageReducer = (dateFilter = null) => async ( dispatch, getState) => {

    const { 
        rex_filters_products_total
    } = getState().filtersProducts

    dispatch({
        type    : LOADING_DATA_MSL_MANAGE,
        payload : true
    })

    let currentDate
    if(typeof(dateFilter) == "string" && dateFilter.length == 7){
        currentDate = dateFilter
    }else{
        const date = dayjs(dayjs(), "AAAA-MM")
        const currentMonth  = date.month() + 1 < 10 ? "0"+ (date.month() + 1) : date.month() + 1
        const currentYear   = date.year()
        currentDate   = currentYear + "-" + currentMonth
    }
    
    await fetch(config.api+'manage/pe/msl',
        {
            mode:'cors',
            method: 'POST',
            headers: {
                'Accept' 	   : 'application/json',
                'Content-type' : 'application/json',
                'usutoken'      : localStorage.getItem('usutoken')
            },
            body: JSON.stringify({
                req_date    : currentDate,
                req_fil_customer : rex_filters_products_total.rex_filter_mls.rex_txt_unique_gba,
                req_fil_zona : rex_filters_products_total.rex_filter_mls.rex_txt_unique_zona,
                req_fil_canal : rex_filters_products_total.rex_filter_mls.rex_txt_uniqueChannels,
                req_fil_cliente : rex_filters_products_total.rex_filter_mls.rex_txt_unique_cliente,
                req_fil_sucursal : rex_filters_products_total.rex_filter_mls.rex_txt_unique_sucursal,
            }),
        }
    )
    .then( async res => {
        return res.json()
    })
    .then(data => {
        if(data.response){
            dispatch({
                type: GET_DATA_MSL_MANAGE,
                payload : data.data
            })
        }
    }).catch((error)=> {
        console.log(error)
    });

    dispatch({
        type    : LOADING_DATA_MSL_MANAGE,
        payload : false
    })
}

export const CreateMslManageReducer = (ids, channel, msl, date, dateFilter) => async ( dispatch, getState) => {

    let response = false

    const monthString = date.$d.getMonth() + 1 < 10 ? "0" + (date.$d.getMonth() + 1) : date.$d.getMonth() + 1
    const yearString  = date.$d.getFullYear().toString()

    const fullDate = yearString + "-" + monthString

    await fetch(config.api+'manage/pe/create-msl',
        {
            mode:'cors',
            method: 'POST',
            headers: {
                'Accept' 	   : 'application/json',
                'Content-type' : 'application/json',
                'usutoken'      : localStorage.getItem('usutoken')
            },
            body: JSON.stringify({
                req_ids     : ids,
                req_channel : channel,
                req_msl     : msl,
                req_date    : fullDate,
                req_year    : parseInt(yearString),
                req_month   : parseInt(monthString)
            }),
        }
    )
    .then( async res => {
        return res.json()        
    })
    .then(data => {
        if(data.response){
            response = true
            notificationGlobal('success', data.message)
            dispatch(GetMslManageReducer(dateFilter))
        }else{
            if(data.messageAlert.length > 0){
                dispatch(ShowModalNotificationsAlertReducer(true, data.messageAlert))
            }else{
                notificationGlobal('warning', data.message)
            }
        }
    }).catch((error)=> {
        console.log(error)
    });

    return response
}

export const FilterMslManageReducer = (dateSearch, txtSearch, channelSearch) => async ( dispatch, getState) => {

    const dataMsl = getState().msl.rex_data_msl_manage_clone

    const filterMsl = dataMsl.filter(msl => (
        (dateSearch != "" ?  msl.fecha.includes(dateSearch) : true)
    ))

    dispatch({
        type    : GET_DATA_MSL_MANAGE,
        payload : filterMsl
    })
}

export const SetDataMslCurrentSourceReducer = ( data ) => async ( dispatch, getState) => {
    
    dispatch({
        type : GET_DATA_MSL_MANAGE_CURRENT_SOURCE,
        payload : data
    })
}

export const DeleteMslManageReducer = (ids, dateFilter) => async ( dispatch, getState) => {

    let response = false

    await fetch(config.api+'manage/pe/delete-msl',
        {
            mode:'cors',
            method: 'POST',
            headers: {
                'Accept' 	   : 'application/json',
                'Content-type' : 'application/json',
                'usutoken'      : localStorage.getItem('usutoken')
            },
            body: JSON.stringify({
                req_ids     : ids,
            }),
        }
    )
    .then( async res => {
        return res.json()        
    })
    .then(data => {
        if(data.response){
            response = true
            notificationGlobal('success', data.message)
            dispatch(GetMslManageReducer(dateFilter))
        }else{
            notificationGlobal('warning', data.message)
        }
    }).catch((error)=> {
        console.log(error)
    });

    return response
}
export const EditMslManageReducer = (ids, channel, msl, date) => async ( dispatch, getState) => {

    let response = false

    const monthString = date.$d.getMonth() + 1 < 10 ? "0" + (date.$d.getMonth() + 1) : date.$d.getMonth() + 1
    const yearString  = date.$d.getFullYear().toString()

    const fullDate = yearString + "-" + monthString

    await fetch(config.api+'manage/pe/edit-msl',
        {
            mode:'cors',
            method: 'POST',
            headers: {
                'Accept' 	   : 'application/json',
                'Content-type' : 'application/json',
                'usutoken'      : localStorage.getItem('usutoken')
            },
            body: JSON.stringify({
                req_ids     : ids,
                req_channel : channel,
                req_msl     : msl,
                req_date    : fullDate,
                req_year    : parseInt(yearString),
                req_month   : parseInt(monthString)
            }),
        }
    )
    .then( async res => {
        return res.json()        
    })
    .then(data => {
        if(data.response){
            response = true
            notificationGlobal('success', data.message)
            dispatch(GetMslManageReducer())
        }else{
            if(data.messageAlert.length > 0){
                dispatch(ShowModalNotificationsAlertReducer(true, data.messageAlert))
            }else{
                notificationGlobal('warning', data.message)
            }
        }
    }).catch((error)=> {
        console.log(error)
    });

    return response
}

export const ShowModalNotificationsAlertReducer = ( value, data ) => async ( dispatch, getState) => {
    
    dispatch({
        type    : SHOW_MODAL_NOTIFICATIONS_ALERT,
        payload : value
    })
    dispatch({
        type    : DATA_MODAL_NOTIFICATIONS_ALERT,
        payload : data
    })
}