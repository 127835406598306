import React from "react"

const TituloPowerBi = (props) => {
    const titulo = props.titulo
    const active = props.active
    const titutloArray = Array.from(titulo)
    return(
        <div className="tituloEncabezado">
            <div className="ant-page-header-heading-left">
                <div className="ant-page-header-back" style={{width: '32px', height: '32px'}}></div>
                <div className="ant-page-header-heading-title">
                    {titutloArray.map(t => <span className={`${active ?'animateEffect' : 'animateEffectInactive'}`}>{t}</span>)}
                </div>
            </div>
        </div>
    )
}

export default TituloPowerBi