import React, { useEffect } from "react"
import { useSelector, useDispatch} from "react-redux"
import { Row, Col, Spin } from 'antd'
import { LoadingOutlined  } from '@ant-design/icons'
import { ObtenerDataFiltrosPowerBiReducer } from "../../Redux/Actions/PowerBi/PowerBiFiltros"
import BotonSelectFiltro from "../../Components/PowerBi/BotonSelectFiltro"
import TablaFiltro from "../../Components/PowerBi/TablaFiltro"
import EstadisticaGrafica from "../../Components/PowerBi/EstadisticaGrafica"
import SideMenu from "../../Components/Global/SideMenu"
import { Element } from 'react-scroll'
// import {
//     SeleccionarModuloReducer
// } from '../../../appRedux/actions/Dashboard/Dashboard'

const PowerBi = () => {

    const dispatch = useDispatch()

    const { 
        rex_cargando_data_daily
    } = useSelector(({daily}) => daily)

    const { 
        rex_users_conected_dashboard,
    } = useSelector(({registerUsers}) => registerUsers)

    const { 
        rex_data_users_online
    } = useSelector(({users}) => users)

    const antIcon = (
        <LoadingOutlined
          style={{
            fontSize: 24,
          }}
          spin
        />
    )

    useEffect(() => {
        dispatch(ObtenerDataFiltrosPowerBiReducer())
    }, [])

    return(
        <>
        <div style={{padding: '0 30px 0 30px'}}>
            <Row>
                <Col span={24}>
                    <BotonSelectFiltro />
                </Col>
                <Col span={24}>
                    <TablaFiltro />
                </Col>
                <Col span={24}>
                    <Element name="Grafico">
                        <Spin
                            indicator={antIcon}
                            spinning={rex_cargando_data_daily}
                        >
                            <EstadisticaGrafica />
                        </Spin>
                    </Element>
                </Col>
                {/* <Link to="Grafico" isDynamic={true} smooth={true} duration={500}>
                    <div className="Button-Up">
                        <DownOutlined className="Icon-Button-Up-First" />
                        <DownOutlined className="Icon-Button-Up-Second" />
                    </div>
                </Link> */}
            </Row>
        </div>
        <SideMenu
            usersCurrentView  = {rex_users_conected_dashboard}
            usersAllConnected ={rex_data_users_online}
        />
        </>
    )
}

export default PowerBi