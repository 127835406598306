import {
    GET_DATA_MASTER_CLIENTS_MANAGE,
    GET_DATA_MASTER_CLIENTS_MANAGE_CLONE,
    GET_DATA_MASTER_CLIENT_MANAGE,
    LOADING_GET_DATA_MASTER_CLIENTS_MANAGE,
    GET_DATA_MASTER_CLIENTS_MANUALS_MANAGE,
    LOADING_GET_DATA_MASTER_CLIENTS_MANUALS_MANAGE,
    DATA_FORM_EDIT_MASTER_CLIENT,
    DATA_FORM_EDIT_MASTER_CLIENT_MANUAL,
    SHOW_MODAL_ALERT_DELETE_CLIENTS,
    DATA_ALERT_DELETE_CLIENTS,
    DATA_FILTER_DATE_MASTER_CLIENTS,
    DATA_FILTER_DATE_MASTER_CLIENTS_MANUAL,
    DATE_LAST_UPDATED_CLIENTS_MANAGE
} from "../../../../Constants/Manage/MasterClients/MasterClients";

const INIT_STATE = {
    rex_data_master_clients_manage              : [],
    rex_data_master_clients_manuals_manage      : [],
    rex_data_filter_date_master_clients         : null,
    rex_data_filter_date_master_clients_manual  : null,
    rex_date_updated_client_manage              : '',
    rex_loading_master_clients_manage           : true,
    rex_loading_master_clients_manuals_manage   : true,
    rex_data_form_edit_master_client            : [],
    rex_data_form_edit_master_client_manual     : [],
    rex_data_master_clients_manage_clone        : [],
    rex_show_modal_alert_delete_clients         : false,
    rex_data_alert_delete_clients               : [],
    rex_data_master_client_manage               : {
        cod_sales_organization  : "",
        sales_organization      : "",
        cod_bussiness           : "",
        bussiness               : "",
        cod_ship_to             : "",
        ship_to                 : "",
        cod_sold_to             : "",
        sold_to                 : "",
        hml_cod_client          : "",
        hml_client              : "",
        hml_cod_subsidiary      : "",
        hml_subsidiary          : "",
        corporate_group         : "",
        cod_regional            : "",
        regional                : "",
        cod_channel             : "",
        channel                 : "",
        cod_sub_channel         : "",
        sub_channel             : "",
        cod_zone                : "",
        zone                    : "",
        cod_territory_manager   : "",
        territory               : "",
        class_1                 : "",
        class_2                 : "",
        class_3                 : "",
        class_4                 : "",
        class_5                 : "",
        class_6                 : "",
        class_7                 : "",
        class_8                 : "",
        cod_regional_manager    : "",
        regional_manager        : "",
        cod_channel_manager     : "",
        cod_zone_att            : "",
        channel_manager         : "",
        cod_zone_manager        : "",
        zone_manager            : "",
        cod_territory_manager   : "",
        territory_manager       : "",
        cod_si_executive        : "",
        si_executive            : "",
        cod_so_executor         : "",
        so_executor             : "",
        class_9                 : "",
        class_10                : "",
        class_11                : "",
        hml_cod_country         : "",
        hml_country             : "",
        hml_cod_departament     : "",
        hml_departament         : "",
        hml_cod_province        : "",
        hml_province            : "",
        hml_cod_district        : "",
        hml_district            : "",
        hml_address             : "",
        latitude                : "",
        longitude               : "",
        status                  : "",
        robot                   : "",
        class_12                : ""
    },
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        
        case DATE_LAST_UPDATED_CLIENTS_MANAGE: {
            return {
                ...state,
                rex_date_updated_client_manage : action.payload,
            }
        }
        case DATA_FILTER_DATE_MASTER_CLIENTS: {
            return {
                ...state,
                rex_show_modal_alert_delete_clients : action.payload,
            }
        }
        case DATA_FILTER_DATE_MASTER_CLIENTS_MANUAL: {
            return {
                ...state,
                rex_show_modal_alert_delete_clients : action.payload,
            }
        }
        case SHOW_MODAL_ALERT_DELETE_CLIENTS: {
            return {
                ...state,
                rex_show_modal_alert_delete_clients : action.payload,
            }
        }
        case DATA_ALERT_DELETE_CLIENTS: {
            return {
                ...state,
                rex_data_alert_delete_clients : action.payload,
            }
        }
        case DATA_FORM_EDIT_MASTER_CLIENT: {
            return {
                ...state,
                rex_data_form_edit_master_client : action.payload,
            }
        }
        case DATA_FORM_EDIT_MASTER_CLIENT_MANUAL: {
            return {
                ...state,
                rex_data_form_edit_master_client_manual : action.payload,
            }
        }
        case GET_DATA_MASTER_CLIENTS_MANUALS_MANAGE: {
            return {
                ...state,
                rex_data_master_clients_manuals_manage : action.payload,
            }
        }
        case LOADING_GET_DATA_MASTER_CLIENTS_MANUALS_MANAGE: {
            return {
                ...state,
                rex_loading_master_clients_manuals_manage : action.payload,
            }
        }
        case LOADING_GET_DATA_MASTER_CLIENTS_MANAGE: {
            return {
                ...state,
                rex_loading_master_clients_manage : action.payload,
            }
        }
        case GET_DATA_MASTER_CLIENT_MANAGE: {
            return {
                ...state,
                rex_data_master_client_manage : action.payload,
            }
        }
        case GET_DATA_MASTER_CLIENTS_MANAGE: {
            return {
                ...state,
                rex_data_master_clients_manage : action.payload,
            }
        }
        case GET_DATA_MASTER_CLIENTS_MANAGE_CLONE: {
            return {
                ...state,
                rex_data_master_clients_manage_clone : action.payload,
            }
        }
        default:
            return state;
    }
}