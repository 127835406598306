import {
    GET_CARDS_UPLOAD_FILES,
    ITEM_NOTIFICATION_MODAL_UPLOAD_FILE,
    MESSAGES_NOTIFICATIONS_UPLOAD_FILE,
    REGISTER_USERS_CONNECTED_MODULES_FILE_UPLOAD,
    SHOW_NOTIFICATION_MODAL_UPLOAD_FILE
} from "../../../Constants/FileUpload/FileUpload"
import axios from 'axios';
import config from  '../../../config'
import { RegisterAuditReducer } from "../Audits/Audits"
import moment from "moment";
import SoundNotification from "../../../Assets/Audio/soundNotification.mp3"
let timeoutNotificationOpen

export const RegisterUsersConnectedFileUploadReducer = ( data ) => async (dispatch, getState) => {

    dispatch({
        type: REGISTER_USERS_CONNECTED_MODULES_FILE_UPLOAD,
        payload: data
    })
}

export const GetCardsFileUpload = () => async (dispatch, getState) => {

    const { rex_cards_total_upload_file } = getState().fileUpload
    const paiid = localStorage.getItem('usupais')

    
    const cards_file_upload = rex_cards_total_upload_file.find(car => car.paiid == paiid)
    
    if(rex_cards_total_upload_file.length > 0 && cards_file_upload){    
        dispatch({
            type : GET_CARDS_UPLOAD_FILES,
            payload : cards_file_upload.cards
        })
    }else{
        dispatch({
            type : GET_CARDS_UPLOAD_FILES,
            payload : []
        })
    }

} 

export const SendFileUploadFileReducer = ( file, area ) => async ( dispatch, getState ) => {
    
    const audio = new Audio(SoundNotification)

    const { rex_messages_notifications_upload_files } = getState().fileUpload;

    const number_notificacion = parseInt(rex_messages_notifications_upload_files.length) < 9 
                                    ? "0"+(parseInt(rex_messages_notifications_upload_files.length) + 1) 
                                    : parseInt(rex_messages_notifications_upload_files.length) + 1

    const formData = new FormData();

    const currentHourMinute = moment().format('h:mma')
    const currentYearMonth = moment().format('DD/MM')

    const actions_file = { process_data : true, delete_data : true}
    
    formData.append('data_file', file.dataFile)
    formData.append('req_title_type_file', file.titleFile)
    formData.append('req_area', area)
    formData.append('req_paiid', localStorage.getItem('usupais'))
    formData.append('req_action_file', JSON.stringify(actions_file))
    formData.append('req_date', file.date)

    let response = false

    try{
        
        const rquest = await axios.post(`${config.api}${file.url}`, formData, {
            headers: {
                'Content-Type'  : 'multipart/form-data',
                'usutoken'	    : localStorage.getItem('usutoken'),
                'req_paiid'     : localStorage.getItem('usupais'),
            },
        })

        if(rquest.status == 200){
            
            response = true
            audio.play()
            let itemNotification

            timeoutNotificationOpen = setTimeout(() => {

                itemNotification = {
                    "item"      : parseInt(number_notificacion),
                    "titulo"    : "Notificación N°"+number_notificacion,
                    "mensaje"   : `El archivo de ${file.titleFile} se subió correctamente`,
                    "abierta"   : false,
                    "respuesta" : rquest.data.response,
                    "data"      : [],
                    "fecha"     : `${currentHourMinute} ${currentYearMonth}`
                }
    
                rex_messages_notifications_upload_files.unshift(itemNotification)
                
                dispatch({
                    type    : MESSAGES_NOTIFICATIONS_UPLOAD_FILE,
                    payload : rex_messages_notifications_upload_files
                })

                if(timeoutNotificationOpen) {
                    clearTimeout(timeoutNotificationOpen);
                }    
                
                timeoutNotificationOpen = setTimeout(() => {
                    dispatch(ViewNotificationStatusFileUploadReducer(itemNotification))
                }, 10000);
                
            }, 500);

        }

    }catch(error){

        const rquest = error.response
        let message_audit
        
        audio.play()

        if(timeoutNotificationOpen) {
            clearTimeout(timeoutNotificationOpen);
        }

        timeoutNotificationOpen = setTimeout(() => {
            rex_messages_notifications_upload_files.unshift({
                "item"      : parseInt(number_notificacion),
                "titulo"    : "Notificación N°"+number_notificacion,
                "mensaje"   : rquest.data.message,
                "abierta"   : rquest.data.notificaciones.length > 0 ? false : true,
                "respuesta" : rquest.data.response,
                "data"      : rquest.data.notificaciones,
                "fecha"     : `${currentHourMinute} ${currentYearMonth}`,
            })
    
            dispatch({
                type    : MESSAGES_NOTIFICATIONS_UPLOAD_FILE,
                payload : rex_messages_notifications_upload_files
            })
    
            if(rquest.data.notificaciones.length > 0){
                rquest.data.messages_error.map((msg) => {
                    message_audit.push(msg)
                })
            }
            
        },500)
        
        message_audit = [rquest.data.message]
        const json_audit = {
            url : file.url,
            ip : '',
            json_input : {"data_file": file.nameFile, "req_title_type_file": file.titleFile, "req_paiid": localStorage.getItem('usupais'), "req_action_file": actions_file},
            json_output : rquest.data,
            description : message_audit,
            message     : rquest.data.message,
            action      : file.titleFile + "-" + localStorage.getItem('usupais'),
            status      : false,
            module      : "File Upload"
        }
        dispatch(RegisterAuditReducer(json_audit))
        return false
    }

    return response
}

export const DescargarPageOneDashboardReducer = (data) => async ( dispatch, getState ) => {
    let url = ""
    await fetch(config.api+'file-control/download-one-page',
    {
        mode:'cors',
        method: 'POST',
        headers: {
            'Accept' : 'application/json',
            'Content-type' : 'application/json',
            'usutoken'	   : localStorage.getItem('usutoken'),
        },
        body: JSON.stringify({
            req_name      : data,
        }),
    }
    )
    .then(async res => {
		return res.blob()
    })
    .then((blob) => {
        url = URL.createObjectURL(blob)
    })
    .catch((error)=> {
        // notificacionServidor('error', 'Error con el servidor. Vuelva a ingresar más tarde.')
    })

    return url
}

export const DeleteItemNotificationReducer = ( index ) => async ( dispatch, getState ) => {

    const { rex_messages_notifications_upload_files } = getState().fileUpload
        
    rex_messages_notifications_upload_files.splice(index, 1)
    const itemsFilter = rex_messages_notifications_upload_files.filter(item => item.item != index)
    let not_length = itemsFilter.length

    //Item Modal Notification
    let currentItem = {}
    if(rex_messages_notifications_upload_files.find(item => item.item == index+1)){
        currentItem = rex_messages_notifications_upload_files.find(item => item.item == index+1)
    }else if(rex_messages_notifications_upload_files.find(item => item.item == index-1)){
        currentItem = rex_messages_notifications_upload_files.find(item => item.item == index-1)
    }else{
        dispatch({
            type: SHOW_NOTIFICATION_MODAL_UPLOAD_FILE,
            payload : false
        })
    }
    //Item Modal Notification
    
    //Items Card Notification
    itemsFilter.map( not => {
        let index_not_value = not_length < 10 ? '0'+parseInt(not_length) : not_length
        not["titulo"] = `Notificación N°${index_not_value}`
        not["item"] = parseInt(index_not_value)
        not_length = not_length - 1
    })
    //Items Card Notification

    dispatch({
        type: ITEM_NOTIFICATION_MODAL_UPLOAD_FILE,
        payload : currentItem
    })

    dispatch({
        type: MESSAGES_NOTIFICATIONS_UPLOAD_FILE,
        payload : itemsFilter
    })
}

export const ViewNotificationStatusFileUploadReducer = ( item ) => async ( dispatch, getState ) => {

    const { rex_messages_notifications_upload_files } = getState().fileUpload

    console.log(item)

    rex_messages_notifications_upload_files.forEach((not, index) => {
        if(item.item == not.item){
            rex_messages_notifications_upload_files[index]['abierta'] = true
        }
    })

    dispatch({
        type: MESSAGES_NOTIFICATIONS_UPLOAD_FILE,
        payload : rex_messages_notifications_upload_files
    })
}

export const ShowNotificationModalFileUploadReducer = ( value, item ) => async ( dispatch, getState ) => {

    dispatch({
        type: SHOW_NOTIFICATION_MODAL_UPLOAD_FILE,
        payload : value
    })

    dispatch({
        type: ITEM_NOTIFICATION_MODAL_UPLOAD_FILE,
        payload : item
    })
}

export const SelectItemNotificationModalFileUploadReducer = ( item, next ) => async ( dispatch, getState ) => {

    const itemsNotification = getState().fileUpload.rex_messages_notifications_upload_files

    itemsNotification.forEach((not, index) => {
        if(not['item'] == item){
            itemsNotification[index]['abierta'] = true
        }
    })

    const indexItem = next ? item-1 : item+1
    const showItem = itemsNotification.find(item => item.item == indexItem);

    dispatch({
        type: ITEM_NOTIFICATION_MODAL_UPLOAD_FILE,
        payload : showItem
    })
    
    dispatch({
        type: MESSAGES_NOTIFICATIONS_UPLOAD_FILE,
        payload : itemsNotification
    })
}