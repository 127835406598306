import config from '../../../config'
import axios from 'axios';

import {
    GET_DATA_CONTROL,
    GET_DATA_CONTROL_CLONE,
    GET_INFO_DATA_CONTROL_EDITED,
    LOADING_DATA_CONTROL
} from '../../../Constants/DataControl/DataControl'

export const GetDataControlReducer = () => async ( dispatch, getState ) => {

    dispatch({
        type    : LOADING_DATA_CONTROL,
        payload : true
    })

    await fetch(config.api+'data-control/all',
        {
            mode:'cors',
            method: 'POST',
            headers: {
                'Accept' 	   : 'application/json',
                'Content-type' : 'application/json',
                'usutoken'      : localStorage.getItem('usutoken')
            },
        }
    )
    .then( async res => {
        return res.json()
    })
    .then(data => {
        if(data.response){
            dispatch({
                type: GET_DATA_CONTROL,
                payload : data.data
            })
            dispatch({
                type: GET_DATA_CONTROL_CLONE,
                payload : data.data
            })
        }
        dispatch({
            type    : LOADING_DATA_CONTROL,
            payload : false
        })    
    }).catch((error)=> {
        console.log(error)
    });
}

export const ChangeStatusDataControlReducer = (id, status) => async ( dispatch, getState ) => {
    let response = false

    await fetch(config.api+'data-control/edit-status',
        {
            mode:'cors',
            method: 'POST',
            headers: {
                'Accept' 	   : 'application/json',
                'Content-type' : 'application/json',
                'usutoken'      : localStorage.getItem('usutoken')
            },
            body: JSON.stringify({
                req_id      : id,
                req_status  : status
            }),
        }
    )
    .then( async res => {
        return res.json()
    })
    .then(data => {
        if(data.response){
            response = true
            dispatch(GetDataControlReducer())
        }
    }).catch((error)=> {
        console.log(error)
    });

    return response
}

export const DeleteDataControlReducer = (ids) => async ( dispatch, getState ) => {
    let response = false

    await fetch(config.api+'data-control/delete-file',
        {
            mode:'cors',
            method: 'POST',
            headers: {
                'Accept' 	   : 'application/json',
                'Content-type' : 'application/json',
                'usutoken'      : localStorage.getItem('usutoken')
            },
            body: JSON.stringify({
                req_id      : ids,
            }),
        }
    )
    .then( async res => {
        return res.json()
    })
    .then(data => {
        if(data.response){
            response = true
            dispatch(GetDataControlReducer())
        }
    }).catch((error)=> {
        console.log(error)
    });

    return response
}

export const FilterDataControlReducer = (values, type) => async ( dispatch, getState ) => {

    const dataDataControl = getState().dataControl.rex_data_control

    const dataFilter = dataDataControl.filter(dat => {
        return values.length > 0 ?  values.some(val => dat.valuesCountries.includes(val)) : true
        // return values.length > 0 ? values.includes(dat.paipaises.paiid) : true
    })

    dispatch({
        type: GET_DATA_CONTROL_CLONE,
        payload : dataFilter
    })
}


export const CreateDataControlReducer = ( values ) => async ( dispatch, getState ) => {

    let response = false

    
    const formData = new FormData();

    formData.append('req_file', values.file)
    formData.append('req_ardnombre', values.file_name)
    formData.append('req_paiid', values.paiid)

    try{
        
        const rquest = await axios.post(`${config.api}data-control/create-file`, formData, {
            headers: {
                'Content-Type'  : 'multipart/form-data',
                'usutoken'	    : localStorage.getItem('usutoken'),
                'paiid'         : localStorage.getItem('usupais'),
            },
        })

        if(rquest.status == 200){
            
            response = true
            dispatch(GetDataControlReducer())
        }
    }catch(error){
        response = false
    }

    return response
}
export const EditInfoDataControlReducer = (type, value) => async ( dispatch, getState ) => {

    let dataControl = getState().dataControl.rex_info_data_control_edited
    if(type == "ardnombre"){
        dataControl[type] = value
    }else if(type == "country"){
        let existCountry = dataControl.countries.find(cou => cou.label == value.label)
        if(existCountry){
            let countries = dataControl['countries']
            dataControl['countries'] = countries.filter(cou => cou.label != value.label)
        }else{
            dataControl['countries'].push(value)
        }
    }else if(type == "file"){
        if(value){
            dataControl['req_file'] = value
            dataControl['file_name'] = value.name
        }else{
            dataControl['req_file'] = value
            dataControl['file_name'] = value
        }
    }

    dispatch({
        type: GET_INFO_DATA_CONTROL_EDITED,
        payload : dataControl
    })
}

export const SaveInfoDataControlReducer = (id) => async ( dispatch, getState ) => {

    let dataControl = getState().dataControl.rex_info_data_control_edited

    let response = false

    const formData = new FormData();

    const req_paiid = dataControl.countries.map(cou => cou.value)

    formData.append('req_file', dataControl.req_file)
    formData.append('req_ardnombre', dataControl.ardnombre)
    formData.append('req_paiid', req_paiid)
    formData.append('req_id', dataControl.ardid)

    try{
        
        const rquest = await axios.post(`${config.api}data-control/edit-file`, formData, {
            headers: {
                'Content-Type'  : 'multipart/form-data',
                'usutoken'	    : localStorage.getItem('usutoken'),
                'paiid'     : localStorage.getItem('usupais'),
            },
        })

        if(rquest.status == 200){
            
            response = true
            dispatch(GetDataControlReducer())
        }
    }catch(error){
        response = false
    }
    
    return response
}

export const GetInfoDataControlReducer = (id) => async ( dispatch, getState ) => {

    await fetch(config.api+'data-control/get-file',
        {
            mode:'cors',
            method: 'POST',
            headers: {
                'Accept' 	   : 'application/json',
                'Content-type' : 'application/json',
                'usutoken'      : localStorage.getItem('usutoken')
            },
            body: JSON.stringify({
                req_id      : id,
            }),
        }
    )
    .then( async res => {
        return res.json()
    })
    .then(data => {
        if(data.response){
            dispatch({
                type: GET_INFO_DATA_CONTROL_EDITED,
                payload : data.data
            })
        }
    }).catch((error)=> {
        console.log(error)
    });
}

export const DownloadDataDataControlReducer = () => async ( dispatch, getState ) => {

    let url = false

    await fetch(config.api+'data-control/download-data',
        {
            mode:'cors',
            method: 'POST',
            headers: {
                'Accept' 	   : 'application/json',
                'Content-type' : 'application/json',
                'usutoken'      : localStorage.getItem('usutoken')
            },
        }
    )
    .then( async res => {
        return res.json()
    })
    .then(data => {
        url = data.data
    }).catch((error)=> {
        console.log(error)
    });

    return url
}

export const DownloadFileDataControlReducer = (id) => async ( dispatch, getState ) => {
    let url
    await fetch(config.api+'data-control/download-file',
        {
            mode:'cors',
            method: 'POST',
            headers: {
                'Accept' 	   : 'application/json',
                'Content-type' : 'application/json',
                'usutoken'      : localStorage.getItem('usutoken')
            },
            body: JSON.stringify({
                req_id      : id,
            }),
        }
    )
    .then( async res => {
        return res.json()
    })
    .then(data => {
        if(data.response){
            url = data.data
        }
    }).catch((error)=> {
        console.log(error)
    });

    return url
}