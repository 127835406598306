import React from 'react'
import { 
    AreaChart, 
    Area, 
    XAxis, 
    YAxis, 
    CartesianGrid, 
    Tooltip, 
    ResponsiveContainer 
} from 'recharts'

const AreaAnalytics = (props) => {
    const data = props.data
    const textTooltip = props.textTooltip
    return(
        <ResponsiveContainer width="100%" height={400}>
            <AreaChart
                data={data}
                margin={{
                    top: 30,
                    right: 40,
                    left: 0,
                    bottom: 10,
                }}
            >
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="label" />
                <YAxis allowDecimals={false}/>
                <Tooltip />
                <Area 
                    type="monotone" 
                    dataKey="cantidad" 
                    name={textTooltip}
                    stroke='#2FA5CB' 
                    fill='#EAF6FA' 
                />
            </AreaChart>
        </ResponsiveContainer>
    )
}

export default AreaAnalytics