import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from "react-router-dom"
import { 
    ClearSelectedPersonalizeTypeProfileReducer,
    CreateEditTypeProfileReducer,
    GetModulesProfileReducer,
    GetProfileTypeUniqueReducer,
    GetTypeProfileCountryReducer,
    ObtenerTiposPerfilesReducer,
    SaveNameTypeProfileReducer,
    SaveProfileTypeUserReducer,
    SelectedPersonalizeProfileTypeReducer,
    SelectedProfileTypeReducer
} from '../../Redux/Actions/ProfileTypes/ProfileTypes'
import { Row, Col, Typography, Form, Switch, Select, Checkbox } from 'antd'
import { CaretDownOutlined } from '@ant-design/icons'
import IconInfo from '../../Assets/Images/ProfileTypes/IconInfo'
import ProfileTypesLevelCountries from '../../Components/ProfileTypes/ProfileTypesLevelCountries'
import ProfileTypesCountriesPermissions from '../../Components/ProfileTypes/ProfileTypesCountriesPermissions'
import ProfileTypesSelectModules from '../../Components/ProfileTypes/ProfileTypesSelectModules'
import SideMenu from '../../Components/Global/SideMenu'
import ProfileTypesSelectModulesSecond from '../../Components/ProfileTypes/ProfileTypesSelectModulesSecond'

const ProfileTypeSelect = () => {
    const { Title } = Typography
    const { Option } = Select

    const navigate = useNavigate()
    const dispatch = useDispatch()

    const { 
        rex_data_tipos_perfiles,
        rex_tipo_perfil_seleccionado,
        rex_personalizar_tipo_perfil,
    } = useSelector(({profileTypes}) => profileTypes)

    const { 
        rex_users_conected_dashboard,
    } = useSelector(({registerUsers}) => registerUsers)

    const { 
        rex_data_users_online,
        rex_data_users_form,
    } = useSelector(({users}) => users)

    useEffect(() => {
        dispatch(ObtenerTiposPerfilesReducer())
        dispatch(GetTypeProfileCountryReducer())
        if(rex_data_users_form['tputiposusuarios']){
            dispatch(GetModulesProfileReducer(rex_data_users_form['tputiposusuarios']['tpuid']))
            dispatch(SelectedProfileTypeReducer(rex_data_users_form['tputiposusuarios']['tpuid']))
        }else{
            dispatch(GetModulesProfileReducer())
        }
    }, [])

    return (
        <div className='Container-Main Container-Profile-Select'>
            <Form layout='vertical' className="Form-Type-Profile">
                <Row>
                    <Col span={12} style={{paddingRight: '10px'}}>
                        <Row>
                            <Col>
                                <Title 
                                    className='Profile-Type-Select-Text-Main'
                                    level={2}
                                >
                                    Tipo de perfil
                                </Title>
                                <p className='Profile-Type-Select-Text-Description'>Aquí podrá seleccionar el tipo de perfil que desea asignar al nuevo usuario</p>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24}>
                                <Form.Item
                                    className="Container-Type-Profile-Select"
                                >
                                    <Select
                                        className={`Profile-Type-Select-Type-Profile 
                                        ${rex_tipo_perfil_seleccionado
                                            && rex_tipo_perfil_seleccionado['tpuid_padre'] 
                                                ? 'Selected-Favorite-Type-Profile' 
                                                : rex_personalizar_tipo_perfil
                                                    ? 'Selected-Favorite-Type-Profile' 
                                                    : ''}
                                        `}
                                        popupClassName="Container-Select-Dropdown-Type-Profile"
                                        size='large'
                                        suffixIcon={<CaretDownOutlined className="Icon-Select-Type-profile" />}
                                        placeholder="Tipo de usuario"
                                        onChange={(value, info) => {
                                            dispatch(SelectedProfileTypeReducer(value))
                                            dispatch(GetProfileTypeUniqueReducer(value, true))
                                            dispatch(SaveNameTypeProfileReducer(info.children.props.children))
                                        }}
                                        bordered={false}
                                        value={rex_tipo_perfil_seleccionado ? rex_tipo_perfil_seleccionado.value : null}
                                    >
                                        {
                                            rex_data_tipos_perfiles.map((tpe) => (
                                                <Option
                                                    key={tpe.value}
                                                    className={`Select-Option-Type-Profile ${tpe.tpuid_padre ? 'Select-Option-Personalize' : ''}`}
                                                    value={tpe.value}
                                                >
                                                    <Checkbox
                                                        checked={rex_tipo_perfil_seleccionado 
                                                            ? tpe.value == rex_tipo_perfil_seleccionado['value'] 
                                                                ? true : false
                                                            : false
                                                        }
                                                    >{tpe.label}</Checkbox>
                                                </Option>
                                            ))
                                        }
                                    </Select>
                                </Form.Item>
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24}>
                                <Title
                                    level={4}
                                    className='Profile-Type-Select-Text-Permissions'
                                >Permisos</Title>
                            </Col>
                            <Col span={24}>
                                <ProfileTypesLevelCountries/>
                            </Col>
                            <Col span={24}>
                                <ProfileTypesCountriesPermissions />
                            </Col>
                        </Row>
                        <Row>
                            <Col span={24}>
                                <ProfileTypesSelectModules />
                            </Col>
                        </Row>
                    </Col>
                    <Col span={12} style={{paddingLeft: '10px'}}>
                        <ProfileTypesSelectModulesSecond />
                        <div className='Select-Type-Profile-Container-Button-Action'>
                            <button
                                onClick={() => {
                                    if(!rex_data_users_form['tputiposusuarios']){
                                        dispatch(ClearSelectedPersonalizeTypeProfileReducer())
                                        navigate("/administrator/users/create-user")
                                    }else{
                                        navigate("/administrator/users/edit-user")
                                    }
                                }}
                                className='Select-Type-Profile-Container-Button Select-Type-Profile-Button-Cancel'
                            >
                                <span>Cancelar</span>
                                <span className='Text-Span-Cancel-Select-Type-Profile'>Cancelar</span>
                            </button>
                            <button
                                onClick={async () => {
                                    dispatch(SaveProfileTypeUserReducer())
                                    if(rex_data_users_form['tputiposusuarios']){
                                        navigate("/administrator/users/edit-user")
                                    }else{
                                        navigate("/administrator/users/create-user")
                                    }
                                }} 
                                className='Select-Type-Profile-Container-Button Select-Type-Profile-Button-Save'
                            >
                                <span>
                                    {rex_tipo_perfil_seleccionado
                                        ? rex_tipo_perfil_seleccionado['tpuid_padre']
                                            ? 'Aplicar'
                                            : rex_personalizar_tipo_perfil
                                                ? 'Guardar' : 'Aplicar'
                                        :   'Aplicar'
                                    }
                                </span>
                                <span className='Text-Span-Save-Select-Type-Profile'>
                                    {rex_tipo_perfil_seleccionado
                                        ? rex_tipo_perfil_seleccionado['tpuid_padre']
                                            ? 'Aplicar'
                                            : rex_personalizar_tipo_perfil
                                                ? 'Guardar' : 'Aplicar'
                                        :   'Aplicar'
                                    }
                                </span>
                            </button>
                        </div>
                    </Col>
                </Row>
                {
                    rex_tipo_perfil_seleccionado?.tpuid_padre
                    ? null
                    : <Row>
                        <Col span={12} className='Container-Personalized-Actions-Select-Type-Profile'>
                            <Switch
                                onChange={(value) => dispatch(SelectedPersonalizeProfileTypeReducer(value))}
                                className='Switch-Action-Personalized-Type-Profile'
                                size='small'
                                checked={rex_personalizar_tipo_perfil}
                                disabled={!rex_tipo_perfil_seleccionado ? true : false}
                            />
                            <div className={`Star-Icon-Profile-Types ${rex_personalizar_tipo_perfil ? 'Star-Selected-Profile-Types' : ''}`}></div>
                            <span className={`Text-Personalized-Select-Type-Profile ${rex_personalizar_tipo_perfil ? 'Selected-Text-Profile-Types' : ''}`}>Personalizar Perfil</span>
                                <IconInfo
                                    descriptionInfo={null}
                                />
                        </Col>
                    </Row>
                }
            </Form>

            <SideMenu
                usersCurrentView  = {rex_users_conected_dashboard}
                usersAllConnected ={rex_data_users_online}
            />
        </div>
    )
}

export default ProfileTypeSelect