import React from 'react'
import { Typography } from 'antd'

const TitleGlobal = (props) => {
    const titleSection = props.titleSection
    const subTitleSection = props.subTitleSection
    const paragraphSection = props.paragraphSection
    const { Title } = Typography
    return(
        <div style={{marginBottom:"12px"}}>
            <Title
                level={2}
                className="Title-Global"
            >
                {titleSection}
            </Title>
            { subTitleSection &&
            <Title
                level={3}
                className="Sub-Title-Global"
            >
                {subTitleSection}
            </Title>}
            {paragraphSection && <p className="Paragraph-Global">{paragraphSection}</p>}
        </div>
    )
}

export default TitleGlobal