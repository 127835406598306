import React, { useState } from 'react'
import { Popover, Checkbox, DatePicker } from 'antd'
import ArrowSelectPopOver from '../../Assets/Images/ProfileTypes/arrow-popover.png'
import '../../Styles/Components/Global/FilterSelectGlobal.css'

const FilterSelectGlobal = ({data, filterBy, setTypFilter, funtionFilter, size='medium'}) => {

    const [ showListCountries, setShowListCountries ] = useState(false)

    const [ listSelected, setListSelected ] = useState([])

    const onChangeSelected = (id) => {

        let valueFilter = []
        let typeFilter = null

        if(filterBy == "tiempo"){
            valueFilter = id
            setTypFilter(id)
        }else{
            let exist_id = listSelected.findIndex(lis => lis == id)
    
            if(exist_id == -1){
                valueFilter = [...listSelected, id]
                setListSelected([...listSelected, id])
                setTypFilter([...listSelected, id])
            }else{
                let new_list = listSelected.filter(lis => lis != id)
                valueFilter = new_list
                setListSelected(new_list)
                setTypFilter(new_list)
            }
        }

        
        funtionFilter(valueFilter, filterBy)
    }

    const listCountries = () => (

        <div className="Filter-Select-Container-PopOver-Global">
            {
                data.map(dat => 
                    <div key={dat.value} className='Filter-Select-Option-PopOver-Global' title={dat.label}>
                        <Checkbox
                            onChange={() => onChangeSelected(dat.value)}
                        >
                            {dat.label}
                        </Checkbox>
                    </div>
                )
            }
        </div>
        
    )

    return (

        <Popover
            title={null}
            content={
                filterBy == "tiempo" 
                ? <DatePicker 
                    className="DatePicker-Filter-Select-Global"
                    onChange={(date, string) => onChangeSelected(string)}
                    />
                : listCountries()
            }
            trigger='click'
            open={showListCountries}
            showArrow={false}
            placement="bottomLeft"
            onOpenChange={() => setShowListCountries(!showListCountries)}
            overlayClassName={`Pop-Over-Filter-Select-Global${filterBy == "tiempo" ? "-DatePicker": ""}`}
        >
            <div className={`Filter-Select-Button-PopOver-Global${size == 'small' ? '-Small' : ''}`}>
                <span style={{textOverflow:'ellipsis', overflow:'hidden',whiteSpace:'nowrap'}}>Filtro por {filterBy}</span>
                <img style={{marginLeft:'20px'}} src={ArrowSelectPopOver}/>
            </div>
        </Popover>
    )
}

export default FilterSelectGlobal