export const GET_DATA_USERS                         = 'GET_DATA_USERS'
export const GET_DATA_FILTER_USERS                  = 'GET_DATA_FILTER_USERS'
export const DATA_CREATE_USER                       = 'DATA_CREATE_USER'
export const DATA_USERS_FORM                        = 'DATA_USERS_FORM'
export const LOADING_DATA_USERS                     = 'LOADING_DATA_USERS'
export const NOTIFICATION_DELETE_USERS              = 'NOTIFICATION_DELETE_USERS'
export const MESSAGE_NOTIFICATION_DELETE_USERS      = 'MESSAGE_NOTIFICATION_DELETE_USERS'
export const NOTIFICATION_ERROR_SERVER              = 'NOTIFICATION_ERROR_SERVER'
export const NOTIFICATION_EDITED_USER               = 'NOTIFICATION_EDITED_USER'
export const NOTIFICATION_EDITED_USER_MSG               = 'NOTIFICATION_EDITED_USER_MSG'
export const NOTIFICATION_CREATED_USER              = 'NOTIFICATION_CREATED_USER'
export const NOTIFICATION_CREATED_USER_MSG              = 'NOTIFICATION_CREATED_USER_MSG'
export const GET_DATA_USERS_ONLINE                  = 'GET_DATA_USERS_ONLINE'
export const REGISTER_USERS_CONNECTED_MODULES_USERS = 'REGISTER_USERS_CONNECTED_MODULES_USERS'