import config from "../../../config"
import { 
    TERMS_CONDITIONS,
    TERMS_CONDITIONS_FULL,
} from "../../../Constants/TermsConditions/TermsConditions"
import { notification } from 'antd'
const notificacionServidor = (type, mensaje) => {
    if(type === 'success'){
        notification['success']({
            message: 'Éxito',
            description: mensaje,
        })
    }else if(type === 'warning'){
        notification['warning']({
            message: 'Advertencia',
            description: mensaje,
        })
    }else if(type === 'error'){
        notification['error']({
            message: 'Error',
            description: mensaje,
        })
    }
}

export const TermsConditionsReducer = (value, type) => async (dispatch, getState) => {
    const { rex_terms_conditions } = getState().termsConditions
    rex_terms_conditions[type] = value

    dispatch({
        type: TERMS_CONDITIONS,
        payload: rex_terms_conditions
    })

    if(rex_terms_conditions["termsUse"] && rex_terms_conditions["intellectualPropertyRights"] && rex_terms_conditions["copyrightTrademark"] && rex_terms_conditions["acceptanceTerms"] && rex_terms_conditions["userObligations"]){
        dispatch({
            type: TERMS_CONDITIONS_FULL,
            payload: true
        })
    }else{
        dispatch({
            type: TERMS_CONDITIONS_FULL,
            payload: false
        })
    }
}

export const ValidationTermsConditionsReducer = () => async (dispatch, getState) => {
    const { rex_data_user_logingrow } = getState().loginGrow
    const { rex_terms_conditions } = getState().termsConditions

    // Actualizacion alicorp
    rex_terms_conditions.termsUse                   = true
    rex_terms_conditions.intellectualPropertyRights = true
    rex_terms_conditions.copyrightTrademark         = true
    rex_terms_conditions.acceptanceTerms            = true
    rex_terms_conditions.userObligations            = true

    dispatch({
        type: TERMS_CONDITIONS,
        payload: rex_terms_conditions
    })

    dispatch({
        type: TERMS_CONDITIONS_FULL,
        payload: true
    })

    
    // if(rex_data_user_logingrow.usuaceptoterminos){
    //     rex_terms_conditions.termsUse                   = true
    //     rex_terms_conditions.intellectualPropertyRights = true
    //     rex_terms_conditions.copyrightTrademark         = true
    //     rex_terms_conditions.acceptanceTerms            = true
    //     rex_terms_conditions.userObligations            = true

    //     dispatch({
    //         type: TERMS_CONDITIONS,
    //         payload: rex_terms_conditions
    //     })

    //     dispatch({
    //         type: TERMS_CONDITIONS_FULL,
    //         payload: true
    //     })
    // }else{
    //     rex_terms_conditions.termsUse                   = false
    //     rex_terms_conditions.intellectualPropertyRights = false
    //     rex_terms_conditions.copyrightTrademark         = false
    //     rex_terms_conditions.acceptanceTerms            = false
    //     rex_terms_conditions.userObligations            = false
    //     dispatch({
    //         type: TERMS_CONDITIONS,
    //         payload: rex_terms_conditions
    //     })

    //     dispatch({
    //         type: TERMS_CONDITIONS_FULL,
    //         payload: false
    //     })
    // }
}

export const SaveTermsConditionsReducer = () => async (dispatch, getState) => {
    let response = true
    let status = 200
    const { rex_data_user_logingrow } = getState().loginGrow
    await fetch(config.api+'terms-conditions/update-terms-conditions',
        {
            mode:'cors',
            method: 'POST',
            headers: {
                'Accept' 	   : 'application/json',
                'Content-type' : 'application/json',
                'usutoken'      : localStorage.getItem('usutoken')
            },
            body: JSON.stringify({
                req_id_usu: rex_data_user_logingrow.usuid,
            }),
        }
    )
    .then(res => {
        status = res.status
        return res.json()
    })
    .then(data => {
        if(!data.response){
            if(status == 400){
                notificacionServidor('warning', data.message)
            }else if(status == 500){
                notificacionServidor('error', data.message)
            }
            response = false
        }
    }).catch((error)=> {
        console.log(error)
    })

    return response
}