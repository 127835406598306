import {
    GET_DATA_FILTERS_TXT_INCENTIV_PROGRAM_TOTAL,
    GET_DATA_INCENTIV_PROGRAM,
    GET_DATA_FILTERS_GBA,
    GET_DATA_FILTERS_CHANNEL,
    GET_DATA_FILTERS_ZONE,
    GET_DATA_FILTERS_CLIENT,
    GET_DATA_FILTERS_INCENTIVES,
    GET_DATA_INCENTIV_PROGRAM_EDIT,
    GET_DATA_INCENTIV_PROGRAM_CLONE,
    GET_DATA_LAST_UPDATE_INCENTIV_PROGRAM,
} from "../../../Constants/IncentivProgram/IncentivProgram";

const INIT_STATE = {

    rex_filters_incentiv_program_total : {
        rex_filter_incentiv_program : {
            rex_txt_uniqueChannels : "",
            rex_txt_unique_zona : "",
            rex_txt_unique_cliente : "",
            rex_txt_unique_sucursal : "",
            rex_txt_unique_gba : "",
            rex_txt_unique_incentive : ""
        },
    },

    rex_data_incentiv_program : [],
    rex_data_last_update_incentiv_program : null,
    rex_data_incentiv_program_clone : [],
    rex_data_incentiv_program_edit : [],
    rex_filters_items_gba : [],
    rex_filters_items_channel : [],
    rex_filters_items_zone : [],
    rex_filters_items_client : [],
    rex_filters_items_incentive : [],
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {

        case GET_DATA_FILTERS_INCENTIVES: {
            return {
                ...state,
                rex_filters_items_incentive : action.payload,
            }
        }
        case GET_DATA_LAST_UPDATE_INCENTIV_PROGRAM: {
            return {
                ...state,
                rex_data_last_update_incentiv_program : action.payload,
            }
        }
        case GET_DATA_FILTERS_ZONE: {
            return {
                ...state,
                rex_filters_items_zone : action.payload,
            }
        }
        case GET_DATA_INCENTIV_PROGRAM_CLONE: {
            return {
                ...state,
                rex_data_incentiv_program_clone : action.payload,
            }
        }
        case GET_DATA_INCENTIV_PROGRAM_EDIT: {
            return {
                ...state,
                rex_data_incentiv_program_edit : action.payload,
            }
        }
        case GET_DATA_FILTERS_GBA: {
            return {
                ...state,
                rex_filters_items_gba : action.payload,
            }
        }
        case GET_DATA_FILTERS_CHANNEL: {
            return {
                ...state,
                rex_filters_items_channel : action.payload,
            }
        }
        case GET_DATA_FILTERS_ZONE: {
            return {
                ...state,
                rex_txt_unique_zona : action.payload,
            }
        }
        case GET_DATA_FILTERS_CLIENT: {
            return {
                ...state,
                rex_filters_items_client : action.payload,
            }
        }
        case GET_DATA_FILTERS_TXT_INCENTIV_PROGRAM_TOTAL: {
            return {
                ...state,
                rex_filters_incentiv_program_total : action.payload,
            }
        }
        case GET_DATA_INCENTIV_PROGRAM: {
            return {
                ...state,
                rex_data_incentiv_program : action.payload,
            }
        }

        default:
            return state;
    }
}