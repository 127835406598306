import React, { useEffect, useState } from 'react'
import { Row, Col, Typography } from 'antd'
import SliderAnalytics from '../../Components/Analytics/SliderAnalytics'
import AreaAnalytics from '../../Components/Analytics/AreaAnalytics'
import TableAnalytics from '../../Components/Analytics/TableAnalytics'
import { useIdleTimer } from 'react-idle-timer'
import { useNavigate } from 'react-router'
import { ValidationUserReducer } from '../../Redux/Actions/Validations/Validations'
import socket from '../../App/socket'
import { useDispatch, useSelector } from 'react-redux'
import SideMenu from '../../Components/Global/SideMenu'
import { 
    RegisterUsersConnectedAnalyticsReducer,
    ObtenerUsuariosGanadosReducer,
    ObtenerUsuariosPerdidosReducer,
    ObtenerVistasModulosReducer,
    ObtenerVistasPromedioReducer,
    ObtenerVistasReducer,
    ObtenerVistasSubmodulosReducer,
    ObtenerVistasPlataformaReducer,
    ObtenerVistasSubSubmodulosReducer
} from '../../Redux/Actions/Analytics/Analytics'
import { 
    ObtenerGraficaSeleccionadaReducer,
    ObtenerGraficaVistasUsuariosReducer
} from '../../Redux/Actions/Analytics/Graficos/GraficosAnalytics'

const Analytics = () => {

    const timeout = 300000
    const [textTooltip, setTextTooltip] = useState("Visitas")
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { Title } = Typography

    const { 
        rex_data_user_logingrow
    } = useSelector(({loginGrow}) => loginGrow);

    const ValidateUser = async () => {
        const validate_user = await dispatch(ValidationUserReducer())
        if(!validate_user){
            navigate('/login')
        }else{
            socket.emit('reg-modules', { module: 'analytics'}, {usuusuario: rex_data_user_logingrow.usuusuario}); 
        }
    }

    const getDataAnalytics = async () => {
        dispatch(ObtenerVistasReducer())
        dispatch(ObtenerVistasPromedioReducer())
        dispatch(ObtenerUsuariosGanadosReducer())
        dispatch(ObtenerUsuariosPerdidosReducer())

        let resViewsModules= await dispatch(ObtenerVistasModulosReducer())
        let resViewsPlatform = await dispatch(ObtenerVistasPlataformaReducer())
        let resViewsSubModule = await dispatch(ObtenerVistasSubmodulosReducer())
        let resViewsUsers = await dispatch(ObtenerGraficaVistasUsuariosReducer())
        let resViewsSubSubmodule = await dispatch(ObtenerVistasSubSubmodulosReducer())

    }

    useEffect(() => {
        ValidateUser()
        getDataAnalytics()   
    }, [])

    useEffect(() => {
        socket.on('get-users-modules', (data_users) => {
            dispatch(RegisterUsersConnectedAnalyticsReducer(data_users))
        });

        return () => {
            socket.emit('unreg-modules', { module: 'analytics'}, {usuusuario: rex_data_user_logingrow.usuusuario} );
            socket.off('get-users-modules');
        };
    },[])

    const onIdle = () => {
        socket.emit('reg-status-modules', { module: 'analytics'}, {usuusuario: rex_data_user_logingrow.usuusuario}, false );
    }
    const onActive = () => {
        socket.emit('reg-status-modules', { module: 'analytics'}, {usuusuario: rex_data_user_logingrow.usuusuario}, true );
    }

    useIdleTimer({
        onIdle,
        onActive,
        timeout,
        throttle: 500
    })

    const { 
        rex_data_users_online
    } = useSelector(({users}) => users);

    const {
        rex_users_analytics_connected,
        rex_vistas_modulos,
        rex_vistas_submodulos,
        rex_vistas_subsubmodulos,
        rex_data_slider,
        rex_vistas_usuario
    } = useSelector(({analytics}) => analytics)

    const {
        rex_grafico_seleccionado
    } = useSelector(({graficosAnalytics}) => graficosAnalytics);

    return(
        <div className="Container-Main Container-Analytics">
            <Row>
                <Col span={24}>
                    <Title className='Title-Analytics-Header'>Analytics</Title>
                    <Title className='SubTitle-Analytics-Header'>Datos generales</Title>
                    <p 
                        onClick={() => {
                            dispatch(ObtenerGraficaSeleccionadaReducer(5))
                            setTextTooltip("Visitas")
                        }}
                        className="Text-Analytics"
                    >
                        {rex_vistas_usuario}
                    </p>
                </Col>
            </Row>
            <Row style={{margin: '0 -10px'}}>
                <Col span={24}>
                    {
                        rex_data_slider.length > 0
                        &&  <SliderAnalytics
                                itemSlider={rex_data_slider}
                                setTextTooltip={setTextTooltip}
                            />
                    }
                </Col>
            </Row>
            <Row style={{marginBottom: '20px'}}>
                <Col span={24} style={{border: '1px solid #EFEBE6', borderRadius: '20px'}}>
                    <AreaAnalytics
                        data={rex_grafico_seleccionado}
                        textTooltip={textTooltip}
                    />
                </Col>
            </Row>
            <Row gutter={20} style={{rowGap: '20px'}}>
                <Col className="gutter-row" lg={12} xs={24}>
                    {
                        rex_vistas_modulos.length > 0
                        &&  <TableAnalytics 
                            titleTable="Tus módulos más vistos en este periodo"
                            itemTable={rex_vistas_modulos}
                        />
                    }
                    {
                        rex_vistas_subsubmodulos.length > 0
                        &&  <TableAnalytics 
                            titleTable="Tus sub-subsubmódulos más vistos en este periodo"
                            itemTable={rex_vistas_subsubmodulos}
                        />
                    }
                </Col>
                <Col className="gutter-row" lg={12} xs={24}>
                    {
                        rex_vistas_submodulos.length > 0
                        &&  <TableAnalytics 
                                titleTable="Tus submódulos más vistos en este periodo"
                                itemTable={rex_vistas_submodulos}
                            />
                    }
                </Col>
            </Row>
            {
                rex_users_analytics_connected &&
                    <SideMenu
                        usersCurrentView  = {rex_users_analytics_connected}
                        usersAllConnected = {rex_data_users_online}
                    />
            }
        </div>
    )
}

export default Analytics