import React from 'react'
import { Modal, Row, Col, Typography, Radio, Collapse, Button } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { DownloadDataExcelReducer, ResetSelectFilterDownloadDataReducer, SelectFilterDownloadDataReducer } from '../../Redux/Actions/DownloadData/DownloadData'
import '../../Styles/Components/DownloadData/ModalDownloadData.css'
import { 
    CaretRightOutlined, 
    CaretLeftOutlined 
} from '@ant-design/icons'
const ModalDownloadData = ({ showModal, setShowModal, dataDownload}) => {

    const { Title } = Typography
    const { Panel } = Collapse

    const dispatch = useDispatch()

    const { 
        rex_filters_download_data,
    } = useSelector(({downloadData}) => downloadData)

    return (
        
        <Modal 
            title={null}
            centered={false}
            open={showModal}
            closable={false}
            width={650}
            footer={null}
            zIndex={100000000}
            className='Modal-Download-Data'
        >
            <Row>
                <Col span={24}>
                    <Title className='Title-Modal-Download-Data' level={5}>Filtro de Columnas</Title>     
                </Col>
            </Row>
            <Row style={{display:"flex"}}>
                <Col span={11}>
                    <div
                        className='Container-Filter-Modal-Download-Data'
                    >
                        <div className='Container-Column-Filter-Text'>
                            <div>
                                <p style={{fontWeight:"700", marginBottom:"0"}}>Columnas a mostrar</p>
                                <p style={{fontSize:"12px", marginBottom:"0"}}>Lista de columnas disponibles</p>
                            </div>
                        </div>
                        <div
                            className='Container-Filters-Scroll-Download-Data'
                        >
                            {
                                rex_filters_download_data.map(dod => {
                                    return <Collapse
                                            defaultActiveKey={['1']}
                                            expandIcon={({ isActive }) => <CaretRightOutlined style={{fontSize:"14px", color:"#FFFFFF"}} rotate={isActive ? 90 : 0} />}
                                        >
                                            <Panel
                                                header={dod.name}
                                                key="1"
                                                className='Panel-Collapse-Select-Date'
                                            >
                                                <Collapse
                                                    defaultActiveKey={dod.data.filter(dat => dat.show == true).map((nam, index) => (index + 1).toString())}
                                                    style={{borderRadius:"0"}}
                                                    expandIcon={({ isActive }) => <CaretRightOutlined style={{fontSize:"14px", color:"#3B72FF"}} rotate={isActive ? 90 : 0} />}
                                                >
                                                    {
                                                        dod.data.map((dat, index) => {
                                                            return  <Panel
                                                                    key={(index+1).toString()}
                                                                    className='Panel-Collapse-Select-Date-Year'
                                                                    header={dat.name}
                                                                >

                                                                    {dat.items.map((lab, index) => {
                                                                        return <div
                                                                            key={index}
                                                                            >
                                                                                <Radio
                                                                                    onClick={() => {
                                                                                        dispatch(SelectFilterDownloadDataReducer(dod.name, dat.name,  lab.label))
                                                                                    }}
                                                                                    checked={lab.value == dat.value ? true : false}
                                                                                >
                                                                                    {lab.label}
                                                                                </Radio>
                                                                        </div>
                                                                    })}

                                                                </Panel>
                                                        })
                                                    }
                                                </Collapse>
                                            </Panel>      

                                        </Collapse>
                                })
                            }
                        </div>
                    </div>

                </Col>
                <Col 
                    style={{display:"flex", alignItems:"center", justifyContent:"center"}}
                    span={2}
                >
                    <div style={{display:"flex", flexDirection:"column", alignItems:"center"}}>
                        <div className="Container-Button-Arrow">
                            <CaretRightOutlined style={{color:"#013A81"}}/>
                        </div>
                        <div className="Container-Button-Arrow">
                            <CaretLeftOutlined style={{color:"#013A81"}}/>
                        </div>
                    </div>
                </Col>
                <Col span={11}>
                    <div
                        className='Container-Filter-Modal-Download-Data'
                    >
                        <div className='Container-Column-Filter-Text'>
                            <div>
                                <p style={{fontWeight:"700", marginBottom:"0"}}>Columnas a mostrar</p>
                                <p style={{fontSize:"12px", marginBottom:"0"}}>Seleccionar las columnas que desea mostrar</p>
                            </div>
                        </div>
                        <div
                            className='Container-Filters-Scroll-Download-Data'
                        >
                            {
                                rex_filters_download_data.map(dod => {
                                    return dod.show
                                    ?   <Collapse
                                            defaultActiveKey={['1']}
                                            expandIcon={({ isActive }) => <CaretRightOutlined style={{fontSize:"14px", color:"#FFFFFF"}} rotate={isActive ? 90 : 0} />}
                                        >
                                            <Panel
                                                header={dod.name}
                                                key="1"
                                                className='Panel-Collapse-Select-Date'
                                            >
                                                <Collapse
                                                    defaultActiveKey={dod.data.filter(dat => dat.show == true).map((nam, index) => (index + 1).toString())}
                                                    style={{borderRadius:"0"}}
                                                    expandIcon={({ isActive }) => <CaretRightOutlined style={{fontSize:"14px", color:"#3B72FF"}} rotate={isActive ? 90 : 0} />}
                                                >
                                                    {
                                                        dod.data.map((dat, index) => {
                                                            return dat.show
                                                            ?   <Panel
                                                                    key={(index+1).toString()}
                                                                    className='Panel-Collapse-Select-Date-Year'
                                                                    header={dat.name}
                                                                >
                                                                    <div onClick={() => {
                                                                        dispatch(SelectFilterDownloadDataReducer(dod.name, dat.name, null))
                                                                    }}>
                                                                        <Radio.Group
                                                                            value={dat.name}
                                                                            style={{display:"flex", flexDirection: "column"}}
                                                                        >
                                                                            <Radio
                                                                                value={dat.name}
                                                                                >
                                                                                {dat.label}
                                                                            </Radio>
                                                                        </Radio.Group>

                                                                    </div>
                                                                </Panel>
                                                            : null
                                                        })
                                                    }
                                                </Collapse>
                                            </Panel>      

                                        </Collapse>
                                    : null
                                })
                            }
                        </div>
                    </div>
                </Col>
            </Row>
            <div
                style={{display:"flex", columnGap:"5px", justifyContent:"end", marginTop:"10px"}}
            >
                <Button
                    onClick={async () => {
                        const fileUrl = await dispatch(DownloadDataExcelReducer(dataDownload))
                        if(fileUrl){
                            const link = document.createElement('a')
                            link.href = fileUrl
                            link.download = dataDownload.ardnombre
                            link.click()
                            setShowModal(false)
                        }
                    }}
                    style={{backgroundColor:"#2AD295", color :"white", border:"none"}}
                >Aceptar</Button>
                <Button
                    onClick={() => {
                        setShowModal(false)
                        dispatch(ResetSelectFilterDownloadDataReducer())
                    }}
                >Cancelar</Button>
            </div>
        </Modal>
    )
}

export default ModalDownloadData