import config from '../../../config'
import { 
    GET_DATA_FILTER_USERS,
    GET_DATA_USERS,
    DATA_CREATE_USER,
    LOADING_DATA_USERS,
    NOTIFICATION_DELETE_USERS,
    NOTIFICATION_ERROR_SERVER,
    NOTIFICATION_EDITED_USER,
    NOTIFICATION_CREATED_USER,
    GET_DATA_USERS_ONLINE,
    REGISTER_USERS_CONNECTED_MODULES_USERS,
    DATA_USERS_FORM,
    NOTIFICATION_CREATED_USER_MSG,
    NOTIFICATION_EDITED_USER_MSG,
    MESSAGE_NOTIFICATION_DELETE_USERS
} from '../../../Constants/Users/Users'
import { 
    SELECCIONAR_TIPO_PERFIL,
    OBTENER_TIPO_PERFIL,
    OBTENER_TIPO_PERFIL_COPIA,
    MODULO_PAIS_SELECCIONADO,
    PERSONALIZAR_TIPO_PERFIL,
    SELECTED_PROFILE_TYPE_UNIQUE,
    SELECTED_COUNTRY_PROFILE_TYPE,
    PERMISSIONS_COUNTRY,
    GET_DATA_MODULES_PROFILE_CLONE,
    CHECK_ALL_MODULE_COUNTRY,
} from "../../../Constants/ProfileTypes/ProfileTypes"
import { 
    PersonalizarTipoPerfilReducer,
    SeleccionarTipoPerfilReducer
} from '../ProfileTypes/ProfileTypes'
import moment from "moment"
import { RegisterAuditReducer } from '../Audits/Audits'

export const CreateUserReducer = () => async ( dispatch, getState ) => {

    let respuesta           = true

    const { rex_data_users_form } = getState().users
    const { rex_data_modules_profile_clone } = getState().profileTypes

    const data_usu_create = {
        req_usunombre               : rex_data_users_form['perpersonas']['pernombre'],
        req_usuapellido             : rex_data_users_form['perpersonas']['perapellidos'],
        req_usutelefono             : rex_data_users_form['usutelefono'],
        req_usucontrasenia          : rex_data_users_form['contrasenia'],
        req_usuusuario              : rex_data_users_form['usuusuario'],
        req_usucorreo               : rex_data_users_form['usucorreo'],
        req_usuestado               : rex_data_users_form['estid'],
        req_gnrid                   : rex_data_users_form['gnrid'],
        req_usufechaincorporacion   : rex_data_users_form['usufechaincorporacion'] ? rex_data_users_form['usufechaincorporacion'] : moment().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
        req_usuarea                 : rex_data_users_form['usuarea'],
        req_mod_perms               :  rex_data_modules_profile_clone
    }

    await fetch(config.api+'users/create-user',
        {
            mode:'cors',
            method: 'POST',
            headers: {
                'Accept' 	   : 'application/json',
                'Content-type' : 'application/json',
                'usutoken'      : localStorage.getItem('usutoken')
            },
            body: JSON.stringify(data_usu_create),
        }
    )
    .then( async res => {
        return res.json()        
    })
    .then(data => {
        if(data.response == false){
            respuesta = false
            dispatch({
                type : NOTIFICATION_CREATED_USER_MSG,
                payload : { response : false, message : data.message}
            })
            dispatch({
                type : NOTIFICATION_CREATED_USER,
                payload : true
            })
            // dispatch(RegisterAuditReducer('users/create-user',  'Error al crear al usuario', data.message, data_usu_create, data, user.usuid, 'ip', 'CREATE USER'))
        }else{
            // dispatch(ShowNotificationCreatedUserReducer(true))
            dispatch(GetUsersReducer())
            dispatch(CleanDataCreateUserReducer())
            // dispatch(PersonalizarTipoPerfilReducer(false))
        }
    }).catch((error)=> {
        console.log(error)
    })

    return respuesta
}

export const EditUserReducer = () => async ( dispatch, getState ) => {

    let respuesta           = true
    const { rex_data_users_form } = getState().users
    const { rex_data_modules_profile_clone } = getState().profileTypes

    const restartPassword = typeof rex_data_users_form['contrasenia'] === 'string' && rex_data_users_form['contrasenia'].length > 0 

    const data_usu_edit = {
        req_usuid                   : rex_data_users_form['usuid'],
        req_perid                   : rex_data_users_form['perpersonas']['perid'],
        req_usunombre               : rex_data_users_form['perpersonas']['pernombre'],
        req_usuapellido             : rex_data_users_form['perpersonas']['perapellidos'],
        req_usutelefono             : rex_data_users_form['usutelefono'],
        req_usucontrasenia          : restartPassword ? rex_data_users_form['contrasenia'] : null,
        req_usuusuario              : rex_data_users_form['usuusuario'],
        req_usucorreo               : rex_data_users_form['usucorreo'],
        req_usuestado               : rex_data_users_form['estid'],
        req_gnrid                   : rex_data_users_form['gnrid'],
        req_usufechaincorporacion   : rex_data_users_form['usufechaincorporacion'] ? rex_data_users_form['usufechaincorporacion'] : moment().format('YYYY-MM-DDTHH:mm:ss.SSS[Z]'),
        req_usuarea                 : rex_data_users_form['usuarea'],
        req_mod_perms               : rex_data_modules_profile_clone
    }
    
    await fetch(config.api+'users/edit-user',
    {
        mode:'cors',
        method: 'POST',
        headers: {
            'Accept' 	   : 'application/json',
            'Content-type' : 'application/json',
            'usutoken'      : localStorage.getItem('usutoken')
        },
        body: JSON.stringify(data_usu_edit),
    }
    )
    .then( async res => {
        return res.json()        
    })
    .then(data => {
        if(data.response == false){
            respuesta = false
            // dispatch(ShowNotificationErrorServerReducer(true))
            dispatch({
                type : NOTIFICATION_EDITED_USER_MSG,
                payload : { response : false, message : data.message}
            })
            dispatch({
                type : NOTIFICATION_EDITED_USER,
                payload : true
            })
        }else{
            // dispatch(ShowNotificationEditedUserReducer(true))
            dispatch(GetUsersReducer())
            dispatch(CleanDataCreateUserReducer())
            // dispatch(PersonalizarTipoPerfilReducer(false))
        }
    }).catch((error)=> {
        console.log(error)
    });

    return respuesta
}

export const LoadingUsersReducer = (state) => async (dispatch, getState) => {
    dispatch({
        type    : LOADING_DATA_USERS,
        payload : state
    })
}

export const ChangeUserDataReducer = (data, type) => async (dispatch, getState) => {
    const { rex_data_users_form } = getState().users

    if(type == "pernombre" || type == "perapellidos"){
        rex_data_users_form['perpersonas'][type] = data ? data : ''
    }else if(type == "usuarea" || type == "usuusuario" || type == "usucorreo" || type == "usutelefono" || type == "contrasenia"){
        rex_data_users_form[type] = data ? data : ''
    }else if(type == "gnrid" || type == "estid"){
        rex_data_users_form[type] = data ? data : 0
    }else if(type == "usufechaincorporacion"){
        const format_date_val = moment(data, 'DD/MM/YYYY').format('YYYY-MM-DD')
        let format_date = moment(format_date_val + ' ' + moment().format('HH:mm:ss.SSS[Z]'), 'YYYY-MM-DD HH:mm:ss.SSS[Z]')
        format_date = format_date.format('YYYY-MM-DDTHH:mm:ss.SSS[Z]')
        rex_data_users_form[type] = format_date
    }

    dispatch({
        type    : DATA_USERS_FORM,
        payload : rex_data_users_form
    })
}

export const GetPemdisUserReducer = () => async ( dispatch , getState ) => {

    let data_profile        = getState().profileTypes.rex_data_tipo_perfil_copia
    let pemids = []

    data_profile.map( dat => {
        if(dat.tiene_permiso){
            dat.modulos.map(mod => {
                if(mod.children.some(child => child.tiene_permiso === true)){
                    pemids.push({pemid : mod.pemid})
                    mod.children.map(ch => {
                        if(ch.tiene_permiso){
                            pemids.push({pemid : ch.pemid})
                        }
                    })
                }
            })
        }
    })

    return pemids
}

export const CleanDataCreateUserReducer = () => async ( dispatch, getState ) => {

    dispatch({
        type: DATA_CREATE_USER,
        payload: {perpersonas : {}, estid: 1, usutelefono : ''}
    })

    dispatch({
        type: DATA_USERS_FORM,
        payload: {perpersonas : {}, estid: 1, usutelefono : ''}
    })

    dispatch({
        type    : SELECTED_COUNTRY_PROFILE_TYPE,
        payload : null
    })

    dispatch({
        type    : SELECTED_PROFILE_TYPE_UNIQUE,
        payload : {}
    })

    dispatch({
        type : SELECCIONAR_TIPO_PERFIL,
        payload : {}
    })

    dispatch({
        type: OBTENER_TIPO_PERFIL,
        payload: []
    })
    dispatch({
        type: OBTENER_TIPO_PERFIL_COPIA,
        payload: []
    })
    dispatch({
        type : MODULO_PAIS_SELECCIONADO,
        payload : null
    })
}
export const GetDataCreateUserReducer = (value) => async ( dispatch, getState ) => {

    let data_usu = getState().users.rex_data_create_users

    let name_input  = Object.keys(value)
    let value_input = Object.values(value)
    let name        = name_input[0]
    let valor       = value_input[0]

    if(name == 'pernombre' || name == 'perapellidos'){
        data_usu['perpersonas'][name] = valor
    }else{
        data_usu[name]  = valor
    }   

    dispatch({
        type: DATA_CREATE_USER,
        payload: data_usu
    })
}

export const GetDataEditUserReducer = ( usu ) => async ( dispatch, getState ) => {
    
    let accessCountry = []
    usu['paupaises'].map(pau => {
        accessCountry.push(pau.paiid)
    })
    usu['accessCountry'] = accessCountry

    dispatch({
        type    : DATA_CREATE_USER,
        payload : usu
    })

    dispatch({
        type    : DATA_USERS_FORM,
        payload : usu
    })

    dispatch({
        type    : SELECTED_PROFILE_TYPE_UNIQUE,
        payload : {
            id_profile: usu['tputiposusuarios']['tpuid'],
            name_profile: usu['tputiposusuarios']['tpunombre'],
            personalize_profile: usu['tputiposusuarios']['tpuid_padre'] ? true : false,
        }
    })

    dispatch({
        type    : PERSONALIZAR_TIPO_PERFIL,
        payload : usu.tputiposusuarios.personalized
    })

    dispatch({
        type    : SELECCIONAR_TIPO_PERFIL,
        payload : usu.tputiposusuarios
    })

    let tpuid = usu.tputiposusuarios.tpuid_padre == null ? usu.tputiposusuarios.tpuid : usu.tputiposusuarios.tpuid_padre
    dispatch(SeleccionarTipoPerfilReducer(tpuid))
}

export const GetUsersReducer = () => async ( dispatch, getState ) => {

    const user = getState().loginGrow.rex_data_user_logingrow

    dispatch({
        type    : LOADING_DATA_USERS,
        payload : true
    })
    
    await fetch(config.api+'users/get-users',
        {
            mode:'cors',
            method: 'POST',
            headers: {
                'Accept' 	    : 'application/json',
                'Content-type'  : 'application/json',
                'usutoken'      : localStorage.getItem('usutoken')
            },
        }
    )
    .then( async res => {
        return res.json()        
    })
    .then(data => {
        if(data.response){
            dispatch({
                type: GET_DATA_USERS,
                payload: data.data
            })
            dispatch({
                type: GET_DATA_FILTER_USERS,
                payload: data.data
            })
        }else{
            // dispatch(ShowNotificationErrorServerReducer(true))
            // dispatch(RegisterAuditReducer('users/get-users', 'Error al obtener a los usuarios', data.message, localStorage.getItem('usutoken'), data, user.usuid, 'ip', 'GET USERS'))
        }
    }).catch((error)=> {
        console.log(error)
    });

    dispatch({
        type    : LOADING_DATA_USERS,
        payload : false
    })
}

export const DeleteUsersReducer = (usuids) => async ( dispatch, getState ) => {

    let response = false
    const user = getState().loginGrow.rex_data_user_logingrow

    const data_usu_delete = {
        req_usuids : usuids
    }
    
    await fetch(config.api+'users/delete-users',
        {
            mode:'cors',
            method: 'POST',
            headers: {
                'Accept' 	   : 'application/json',
                'Content-type' : 'application/json',
                'usutoken'      : localStorage.getItem('usutoken')
            },
            body: JSON.stringify(data_usu_delete),
        }
    )
    .then( async res => {
        return res.json()        
    })
    .then(data => {
        if(data.response){
            dispatch(GetUsersReducer())
            dispatch(ShowNotificationDeleteUserReducer(true, data.message))
            response = true
        }else{
            dispatch(ShowNotificationErrorServerReducer(true))

            const json_audit = {
                url : "users/delete-users",
                ip : '',
                json_input  : data_usu_delete,
                json_output : data,
                description : data.message,
                message     : data.message,
                action      : "DELETE USERS",
                status      : false,
                module      : "Users"
            }
            dispatch(RegisterAuditReducer(json_audit))

        }
    }).catch((error)=> {
        console.log(error)
    });

    return response
}

export const FilterUsersReducer = ( pai_values, tpu_value, txtBuscar_value ) => async ( dispatch, getState ) => {

    const data_users = getState().users.rex_data_users
    let option_tpu = tpu_value.length > 0 ? true : false
    let option_pai = pai_values.length > 0 ? true : false

    
    let users_filter = data_users.filter( usu => 
        // (
        //     usu.usuusuario.toLowerCase().includes(txtBuscar_value.toLocaleLowerCase())
        //     || usu.usucorreo.toLowerCase().includes(txtBuscar_value.toLocaleLowerCase())
        //     || usu.perpersonas.pernombrecompleto.toLowerCase().includes(txtBuscar_value.toLocaleLowerCase())
        // )
        // && ( option_tpu ? tpu_value.includes(usu.tputiposusuarios.tpuid) : typeof usu.tputiposusuarios.tpuid == 'number')
        // &&
         ( option_pai ?
            ( usu.paupaises.filter( pau => (pai_values.includes(pau.paiid)) && usu.paupaises.length > 0).length > 0 )
            : typeof usu.paupaises == 'object' )
    )

    dispatch({
        type: GET_DATA_FILTER_USERS,
        payload: users_filter
    })
}

export const ShowNotificationDeleteUserReducer = ( value, message = "" ) => async ( dispatch, getState ) => {
    dispatch({
        type    : NOTIFICATION_DELETE_USERS,
        payload : value
    })

    dispatch({
        type: MESSAGE_NOTIFICATION_DELETE_USERS,
        payload : message
    })
}

export const ShowNotificationCreatedUserReducer = ( value ) => async ( dispatch, getState ) => {
    dispatch({
        type: NOTIFICATION_CREATED_USER,
        payload: value
    })
}

export const ShowNotificationErrorServerReducer = ( value ) => async ( dispatch, getState ) => {
    dispatch({
        type    : NOTIFICATION_ERROR_SERVER,
        payload : value
    })
}

export const EditStatusUserReducer = ( value, usuid ) => async ( dispatch, getState ) => {

    let response = false
    const user = getState().loginGrow.rex_data_user_logingrow

    const data_usu_edit_status = {
        req_estid   : value,
        req_usuid   : usuid
    }

    await fetch(config.api+'users/edit-status-user',
    {
        mode:'cors',
        method: 'POST',
        headers: {
            'Accept' 	    : 'application/json',
            'Content-type'  : 'application/json',
            'usutoken'      : localStorage.getItem('usutoken')
        },
        body: JSON.stringify(data_usu_edit_status),
    }
    )
    .then( async res => {
        return res.json()        
    })
    .then(data => {
        if(data.response){
            response = true
            dispatch(GetUsersReducer())
            // dispatch(ShowNotificationEditedUserReducer(true))
        }else{
            dispatch(ShowNotificationErrorServerReducer(true))

            const json_audit = {
                url : "/users/edit-status-user",
                ip : '',
                json_input  : data_usu_edit_status,
                json_output : data,
                description : data.message,
                message     : data.message,
                action      : "UPDATE STATUS USER",
                status      : false,
                module      : "Usuarios"
            }
            dispatch(RegisterAuditReducer(json_audit))
        }
    }).catch((error)=> {
        console.log(error)
    });

    return response
}

export const ShowNotificationEditedUserReducer = ( value ) => async ( dispatch, getState ) => {

    dispatch({
        type    : NOTIFICATION_EDITED_USER,
        payload : value
    })
}

export const GetUsersOnlineReducer = ( users ) => async ( dispatch, getState) => {
    
    dispatch({
        type    : GET_DATA_USERS_ONLINE,
        payload : users
    })
}

export const RegisterUsersConnectedUsersReducer = ( data ) => async (dispatch, getState) => {
    dispatch({
        type: REGISTER_USERS_CONNECTED_MODULES_USERS,
        payload: data
    })
}

export const DownloadDataUsersReducer = () => async ( dispatch, getState ) => {

    let url = false

    await fetch(config.api+'users/download-data',
        {
            mode:'cors',
            method: 'POST',
            headers: {
                'Accept' 	   : 'application/json',
                'Content-type' : 'application/json',
                'usutoken'      : localStorage.getItem('usutoken')
            },
        }
    )
    .then( async res => {
        return res.json()
    })
    .then(data => {
        url = data.data
    }).catch((error)=> {
        console.log(error)
    });

    return url
}

export const GetUserModulosPermisosReducer = (id) => async ( dispatch, getState ) => {
    const modules_profile = getState().profileTypes.rex_data_modules_profile_clone
    await fetch(config.api+'users/get-user-modules',
    {
        mode:'cors',
        method: 'POST',
        headers: {
            'Accept' 	   : 'application/json',
            'Content-type' : 'application/json',
            'usutoken'      : localStorage.getItem('usutoken')
        },
        body: JSON.stringify({
            req_id   : parseInt(id),
        }),
    })
    .then(res => res.json())
    .then(async data => {
        if(data.response){
            const data_mod_perms = data.data
            data_mod_perms.map(perms => {
                modules_profile.map((mod, index) => {
                    if(perms.pemid == mod.pemid){
                        modules_profile[index]['mod_checked'] = true
                    }
                    if(mod.smosubmodulos.length > 0){
                        mod.smosubmodulos.map((smo, indexsmo) => {
                            if(perms.pemid == mod.pemid){
                                modules_profile[index]['smosubmodulos'][indexsmo]['submod_checked'] = true
                            }else if(perms.pemid == smo.pemid){
                                modules_profile[index]['smosubmodulos'][indexsmo]['submod_checked'] = true
                            }
                            if(smo.ssmsubmodulos.length > 0){
                                smo.ssmsubmodulos.map((ssm, ssmindex) => {
                                    if(perms.pemid == mod.pemid || perms.pemid == smo.pemid){
                                        modules_profile[index]['smosubmodulos'][indexsmo]['ssmsubmodulos'][ssmindex]['subsubmod_checked'] = true
                                    }else if(perms.pemid == ssm.pemid){
                                        modules_profile[index]['smosubmodulos'][indexsmo]['ssmsubmodulos'][ssmindex]['subsubmod_checked'] = true
                                    }
                                })
                            }
                        })
                    }
                })
            })

            dispatch({
                type    : GET_DATA_MODULES_PROFILE_CLONE,
                payload : modules_profile
            })

            const { rex_data_country_clone } = getState().paises
            let value_all_country = []

            rex_data_country_clone.map(cou => {
                let contador_mod = 0
                const filter_mod_pai = modules_profile.filter(mod => mod.paiid == cou.value)
                filter_mod_pai.map(fmp => {
                    if(fmp.mod_checked){
                        contador_mod = contador_mod + 1
                    }
                })

                if(filter_mod_pai.length == contador_mod) value_all_country.push(cou.value)
            })

            dispatch({
                type    : CHECK_ALL_MODULE_COUNTRY,
                payload : value_all_country
            })
        }
    }).catch((error)=> {
        console.log(error)
    })
}

export const CheckBoxChangeCountryReducer = (countries) => async (dispatch, getState) => {
    const { rex_selected_country_profile_type } = getState().profileTypes
    const { rex_data_users_form } = getState().users

    const find_selected_country = countries.find(cou => cou == rex_selected_country_profile_type)

    dispatch({
        type    : PERMISSIONS_COUNTRY,
        payload : countries
    })

    rex_data_users_form['accessCountry'] = countries
    dispatch({
        type    : DATA_USERS_FORM,
        payload : rex_data_users_form
    })

    if(!find_selected_country){
        let country_selected = null
        countries.map((cou, index) => {
            if(index == 0) country_selected = cou
        })
        dispatch({
            type    : SELECTED_COUNTRY_PROFILE_TYPE,
            payload : country_selected
        })
    }
}

export const SelectedCountryUserReducer = (country) => async (dispatch) => {
    dispatch({
        type    : SELECTED_COUNTRY_PROFILE_TYPE,
        payload : country
    })
}