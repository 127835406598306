import { Table } from 'antd'
import React from 'react'

const SimpleTableGlobal = ({
    data,
    columns,
    rowSelection,
    pageSizeTableGlobal,
    pageTableGlobal,
    actionOnRow,
    actionEnterRow,
    actionLeaveRow,
    keyRowAction,
    setCurrentData,
    updateCurrentData=false,
    loadingData=false
}) => {
    return (
        <>
            <Table
                className='Simple-Table-Global'
                dataSource={data}
                loading={loadingData}
                columns={columns}
                rowSelection={
                    {...rowSelection, columnWidth: "50px", }
                }
                onChange={(pagination, filters, sorter, extra) => {
                    if(updateCurrentData){
                        setCurrentData([extra.currentDataSource])
                    }
                }}
                scroll={{
                    x: '1100px',
                }}
                rowClassName={ record => {

                    let carprocesado    = record.carfechaprocesado  ? 'Row-File-Process' : 'Row-File-No-Process'

                    if(record.active || record.estid == 1){
                        return `Row-Simple-Table-Global ${carprocesado}`
                    }else{
                        return `Row-Simple-Table-Global-Row-Inactive ${carprocesado}`
                    }
                }}
                pagination={{
                    defaultCurrent: pageTableGlobal,
                    current: pageTableGlobal,
                    pageSize: pageSizeTableGlobal,
                    position: ['none','none'],
                }}
                onRow={(record, rowIndex) => {
                    return {
                        onClick : event => { 
                            actionOnRow(record)
                        },
                        onMouseEnter: (event) => actionEnterRow(rowIndex),
                        onMouseLeave: (event) => actionLeaveRow(null)
                    }
                }}
                size='small'
            />
        </>
    )
}

export default SimpleTableGlobal