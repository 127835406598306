const api_url = 'https://gia-back.grow-corporate.com/'
// const api_url = 'http://127.0.0.1:8001/'
// const api_url_powerbi = '/'
const api_url_powerbi = 'https://express-backend-spider.grow-corporate.com/'
export default {
    api_url : api_url,
    api_public: api_url+'public/',
    api: api_url+'protected/',
    // api: 'https://back-corporativav.gavsistemas.com/',
    api_url_powerbi : api_url_powerbi,
    basename: '/',
    produccion : false,
};