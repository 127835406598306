import React, { useEffect } from 'react'
import { Row, Col } from 'antd'
import CardDownload from '../../Components/DownloadData/CardDownload'
import TitleGlobal from '../../Components/Global/TitleGlobal'
import { useDispatch, useSelector } from 'react-redux'
import { ClearDownloadDataReducer, ObtenerArchivosDownloadDataReducer, RegisterUsersConnectedModulesReducer } from '../../Redux/Actions/DownloadData/DownloadData'
import SideMenu from '../../Components/Global/SideMenu'
import socket from '../../App/socket'
import { useIdleTimer } from 'react-idle-timer'

const DownloadData = () => {

    const dispatch = useDispatch()

    const timeout = 300000

    const { 
        rex_data_user_logingrow,
    } = useSelector(({loginGrow}) => loginGrow)

    const getDataDownloadData = () => {
        dispatch(ObtenerArchivosDownloadDataReducer())
        dispatch(ClearDownloadDataReducer())
    }

    useEffect(() => {

        socket.on('get-update-profile-types', (data_users) => {
            getDataDownloadData()
        })

        getDataDownloadData()
    },[])
    
    useEffect(() => {
        socket.on('get-users-modules', (data_users) => {
            dispatch(RegisterUsersConnectedModulesReducer(data_users))
        })

        return () => {
            socket.emit('unreg-modules', { module: 'downloadData'}, {usuusuario: rex_data_user_logingrow.usuusuario} )
            socket.off('get-users-modules')
            socket.off('get-update-profile-types')
        }
    },[])

    const onIdle = () => {
        socket.emit('reg-status-modules', { module: 'downloadData'}, {usuusuario: rex_data_user_logingrow.usuusuario}, false )
    }
    const onActive = () => {
        socket.emit('reg-status-modules', { module: 'downloadData'}, {usuusuario: rex_data_user_logingrow.usuusuario}, true )
    }

    useIdleTimer({
        onIdle,
        onActive,
        timeout,
        throttle: 500
    })

    const { 
        rex_data_users_online
    } = useSelector(({users}) => users)

    const {
        rex_users_download_data_connected,
        rex_archivos_download_data,
    } = useSelector(({downloadData}) => downloadData)

    useEffect(() => {

    },[rex_archivos_download_data])

    return(
        <>
        <div className="Container-Main" style={{paddingBottom: '0', paddingTop: '42px'}}>
            <Row>
                <Col 
                    span={24}
                >
                    <TitleGlobal 
                        titleSection="Descarga de Data"
                        paragraphSection="Recuerda que solo podrás visualizar los archivos que tienes disponible, pero solo podrás descargarlo desde tu desktop"
                    />
                </Col>
            </Row>
        </div>
        <Row gutter={0} style={{marginTop: '0'}}>
            <Col className="gutter-row" span={24}>
                <CardDownload downloadData={rex_archivos_download_data} />
            </Col>
        </Row>
        
        {
            rex_users_download_data_connected && 
                <SideMenu
                    usersCurrentView  = {rex_users_download_data_connected}
                    usersAllConnected ={rex_data_users_online}
                />
        }
        </>
    )
}

export default DownloadData