import config from "../../../config"
import { 
    REGISTER_USERS_CONNECTED_MODULES_ANALYTICS,
    OBTENER_VISTAS_MODULOS_ANALYTICS,
    OBTENER_VISTAS_SUBMODULOS_ANALYTICS,
    OBTENER_DATA_SLIDER_ANALYTICS,
    OBTENER_VISTAS_ANALYTICS,
    OBTENER_VISTAS_PROMEDIOS_MINUTOS_ANALYTICS,
    OBTENER_USUARIOS_GANADOS_ANALYTICS,
    OBTENER_USUARIOS_PERDIDOS_ANALYTICS,
    OBTENER_VISTAS_USUARIO_ANALYTICS,
    OBTENER_VISTAS_SUBSUBMODULOS_ANALYTICS
} from "../../../Constants/Analytics/Analytics"
import { RegisterAuditReducer } from "../Audits/Audits"

export const RegisterUsersConnectedAnalyticsReducer = ( data ) => async (dispatch, getState) => {

    dispatch({
        type: REGISTER_USERS_CONNECTED_MODULES_ANALYTICS,
        payload: data
    })
}

export const ObtenerVistasReducer = () => async (dispatch, getState) => {

    const user = getState().loginGrow.rex_data_user_logingrow

    const data_module_views_get = {
        req_paiid : parseInt(localStorage.getItem('usupais')),
    }

    let dataViews = [
        {
            "respuesta": true,
            "message": "Se obtuvo con éxito la cantidad promedio de vistas por usuario",
            "vistas_ultimo_mes": 1189,
            "vistas_penultimo_mes": 0,
            "diferencia": "1189 más de lo habitual",
            "titleSlider": "Vistas",
            "numericSlider": 1189,
            "textSlider": "1826 más de lo habitual",
            "key": 1
        }
    ]
    dispatch({
        type: OBTENER_VISTAS_ANALYTICS,
        payload: [dataViews]
    })
    dispatch(ObtenerDataSliderReducer({
        "respuesta": true,
        "message": "Se obtuvo con éxito la cantidad promedio de vistas por usuario",
        "vistas_ultimo_mes": 1189,
        "vistas_penultimo_mes": 0,
        "diferencia": "1189 más de lo habitual",
        "titleSlider": "Vistas",
        "numericSlider": 1189,
        "textSlider": "1826 más de lo habitual",
        "key": 1
    }, 1))
    // await fetch(config.api + 'analytics/audit/views-average',
    //     {
    //         mode:'cors',
    //         method: 'POST',
    //         headers: {
    //             'Accept'        : 'application/json',
    //             'Content-type'  : 'application/json',
    //             'usutoken'	    : localStorage.getItem('usutoken'),
    //         },
    //         body: JSON.stringify(data_module_views_get),
    //     }
    // )
    // .then( async res => {
    //         // await dispatch(estadoRequestReducer(res.status))
    //         return res.json()
    // })
    // .then(data => {
    //     if(data.respuesta == true){

    //         let dataViews = [
    //             {
    //                 "respuesta": true,
    //                 "message": "Se obtuvo con éxito la cantidad promedio de vistas por usuario",
    //                 "vistas_ultimo_mes": 1189,
    //                 "vistas_penultimo_mes": 0,
    //                 "diferencia": "1189 más de lo habitual",
    //                 "titleSlider": "Vistas",
    //                 "numericSlider": 1189,
    //                 "textSlider": "1826 más de lo habitual",
    //                 "key": 1
    //             }
    //         ]
    //         dispatch({
    //             type: OBTENER_VISTAS_ANALYTICS,
    //             payload: [dataViews]
    //         })
    //         dispatch(ObtenerDataSliderReducer(data, 1))
    //     }else{

    //         const audit = {
    //             url         : 'analytics/audit/views-average',
    //             message     : 'Error al obtener el promedio vistas', 
    //             description :data.message, 
    //             json_input  : data_module_views_get,
    //             json_output : data, 
    //             ip          : 'ip', 
    //             action      : 'GET AVERAGE VIEWS',
    //             status      : data.respuesta,
    //             module      : "ANALYTICS"
    //         }
    //         dispatch(RegisterAuditReducer(audit))
    //     }

    // }).catch((error)=> {
    //     console.log(error)
    // });
}
export const ObtenerVistasPromedioReducer = () => async (dispatch, getState) => {
    
    const user = getState().loginGrow.rex_data_user_logingrow

    const data_module_average_minutes = {
        req_paiid : parseInt(localStorage.getItem('usupais')),
    }

    const data = {
        "respuesta": true,
        "message": "Se obtuvo con éxito la cantidad promedio de vistas por minuto",
        "promedio_ultimo_mes": 35,
        "promedio_penultimo_mes": 0,
        "diferencia": "35 más de lo habitual",
        "titleSlider": "Tiempo promedio de actividad",
        "numericSlider": 35,
        "textSlider": "35 más de lo habitual",
        "key": 2
    }

    dispatch({
        type: OBTENER_VISTAS_PROMEDIOS_MINUTOS_ANALYTICS,
        payload: [data]
    })
    dispatch(ObtenerDataSliderReducer(data, 2))

    // await fetch(config.api + 'analytics/audit/views-average-minutes',
    //     {
    //         mode:'cors',
    //         method: 'POST',
    //         headers: {
    //             'Accept'        : 'application/json',
    //             'Content-type'  : 'application/json',
    //             'usutoken'	    : localStorage.getItem('usutoken'),
    //         },
    //         body: JSON.stringify(data_module_average_minutes),
    //     }
    // )
    // .then( async res => {
    //     // await dispatch(estadoRequestReducer(res.status))
    //     return res.json()
    // })
    // .then(data => {
    //     if(data.respuesta == true){
    //         dispatch({
    //             type: OBTENER_VISTAS_PROMEDIOS_MINUTOS_ANALYTICS,
    //             payload: [data]
    //         })
    //         dispatch(ObtenerDataSliderReducer(data, 2))
    //     }else{
    //         const audit = {
    //             url         : 'analytics/audit/views-average-minutes',
    //             message     : 'Error al obtener el promedio de vistas por minutos', 
    //             description :data.message, 
    //             json_input  : data_module_average_minutes,
    //             json_output : data, 
    //             ip          : 'ip', 
    //             action      : 'GET AVERAGE VIEWS MINUTES',
    //             status      : data.respuesta,
    //             module      : "ANALYTICS"
    //         }
    //         dispatch(RegisterAuditReducer(audit))
    //     }

    // }).catch((error)=> {
    //     console.log(error)
    //     // dispatch(RegistrarErrorReducer(error, config.api+'login', datos))
    // });
}

export const ObtenerUsuariosGanadosReducer = () => async (dispatch, getState) => {

    const user = getState().loginGrow.rex_data_user_logingrow

    const data_module_gained_users = {
        req_paiid : parseInt(localStorage.getItem('usupais')),
    }

    const data = {
        "respuesta": true,
        "message": "Se obtuvo la cantidad ganada de usuarios con éxito",
        "usuariosGanadosUltimoMes": 322,
        "numericSlider": 322,
        "usuariosGanadosPenultimoMes": 9,
        "promedio": "Un 9% más que los 30 días anteriores",
        "titleSlider": "Usuarios ganados",
        "textSlider": "9% más de lo habitual",
        "key": 3
    }

    dispatch({
        type: OBTENER_USUARIOS_GANADOS_ANALYTICS,
        payload: [data]
    })

    dispatch(ObtenerDataSliderReducer(data, 3))

    // await fetch(config.api + 'analytics/audit/users-gained',
    //     {
    //         mode:'cors',
    //         method: 'POST',
    //         headers: {
    //             'Accept'        : 'application/json',
    //             'Content-type'  : 'application/json',
    //             'usutoken'	    : localStorage.getItem('usutoken'),
    //         },
    //         body: JSON.stringify(data_module_gained_users),
    //     }
    // )
    // .then( async res => {
    //     // await dispatch(estadoRequestReducer(res.status))
    //     return res.json()
    // })
    // .then(data => {
    //     if(data.respuesta == true){
    //         dispatch({
    //             type: OBTENER_USUARIOS_GANADOS_ANALYTICS,
    //             payload: [data]
    //         })

    //         dispatch(ObtenerDataSliderReducer(data, 3))
    //     }else{
    //         const audit = {
    //             url         : 'analytics/audit/users-gained',
    //             message     : 'Error al obtener la cantidad de usuarios ganados', 
    //             description :data.message, 
    //             json_input  : data_module_gained_users,
    //             json_output : data, 
    //             ip          : 'ip', 
    //             action      : 'GET GAINED USERS',
    //             status      : data.respuesta,
    //             module      : "ANALYTICS"
    //         }
    //         dispatch(RegisterAuditReducer(audit))
    //     }

    // }).catch((error)=> {
    //     console.log(error)
    //     // dispatch(RegistrarErrorReducer(error, config.api+'login', datos))
    // });
}
export const ObtenerUsuariosPerdidosReducer = () => async (dispatch, getState) => {

    const user = getState().loginGrow.rex_data_user_logingrow

    const data_module_lost_users = {
        req_paiid : parseInt(localStorage.getItem('usupais')),
    }

    const data = {
        "respuesta": true,
        "message": "Se obtuvo la cantidad perdida de usuarios con éxito",
        "usuarios_perdidos_ultimo_mes": 15,
        "usuarios_perdidos_penultimo_mes": 0,
        "promedio": "Un 7% menos que los 30 días anteriores",
        "titleSlider": "Usuarios perdidos",
        "numericSlider": 15,
        "textSlider": "7% menos de lo habitual",
        "key": 4
    }
    
    dispatch({
        type: OBTENER_USUARIOS_PERDIDOS_ANALYTICS,
        payload: [data]
    })

    dispatch(ObtenerDataSliderReducer(data, 4))
    // await fetch(config.api + 'analytics/audit/users-lost',
    //     {
    //         mode:'cors',
    //         method: 'POST',
    //         headers: {
    //             'Accept'        : 'application/json',
    //             'Content-type'  : 'application/json',
    //             'usutoken'	    : localStorage.getItem('usutoken'),
    //         },
    //         body: JSON.stringify(data_module_lost_users),
    //     }
    // )
    // .then( async res => {
    //     // await dispatch(estadoRequestReducer(res.status))
    //     return res.json()
    // })
    // .then(data => {
    //     if(data.respuesta == true){
    //         dispatch({
    //             type: OBTENER_USUARIOS_PERDIDOS_ANALYTICS,
    //             payload: [data]
    //         })

    //         dispatch(ObtenerDataSliderReducer(data, 4))
    //     }else{
    //         const audit = {
    //             url         : 'analytics/audit/users-lost',
    //             message     : 'Error al obtener la cantidad de usuarios perdidos', 
    //             description :data.message, 
    //             json_input  : data_module_lost_users,
    //             json_output : data, 
    //             ip          : 'ip', 
    //             action      : 'GET LOST USERS',
    //             status      : data.respuesta,
    //             module      : "ANALYTICS"
    //         }
    //         dispatch(RegisterAuditReducer(audit))
    //     }

    // }).catch((error)=> {
    //     console.log(error)
    //     // dispatch(RegistrarErrorReducer(error, config.api+'login', datos))
    // });
}
export const ObtenerVistasModulosReducer = () => async (dispatch, getState) => {

    const user = getState().loginGrow.rex_data_user_logingrow

    const data_module_views_get = {
        req_paiid   : parseInt(localStorage.getItem('usupais')),
    }

    dispatch({
        type : OBTENER_VISTAS_MODULOS_ANALYTICS,
        payload: [
            {
                "modid": 3,
                "contenido": "Dashboard Commercial",
                "duracion": 6,
                "vista": 20,
                "minutos_vistas": 6
            },
            {
                "modid": 4,
                "contenido": "Traditional Channel",
                "duracion": 3,
                "vista": 14,
                "minutos_vistas": 3
            },
            {
                "modid": 5,
                "contenido": "Retail Channel",
                "duracion": 5,
                "vista": 14,
                "minutos_vistas": 5
            },
            {
                "modid": 6,
                "contenido": "Incentivos Comerciales",
                "duracion": 3,
                "vista": 3,
                "minutos_vistas": 3
            },
            {
                "modid": 14,
                "contenido": "Pharmacy Channel",
                "duracion": 5,
                "vista": 14,
                "minutos_vistas": 5
            },
            {
                "modid": 26,
                "contenido": "Trade Marketing",
                "duracion": 6,
                "vista": 20,
                "minutos_vistas": 6
            },
            {
                "modid": 42,
                "contenido": "POS Execution",
                "duracion": 6,
                "vista": 6,
                "minutos_vistas": 6
            },
            {
                "modid": 43,
                "contenido": "Commercial Incentives",
                "duracion": 6,
                "vista": 14,
                "minutos_vistas": 6
            },
            {
                "modid": 44,
                "contenido": "Innovación",
                "duracion": 5,
                "vista": 14,
                "minutos_vistas": 5
            }
        ]
    })
    // await fetch(config.api + 'analytics/audit/views-average-module',
    //     {
    //         mode:'cors',
    //         method: 'POST',
    //         headers: {
    //             'Accept'        : 'application/json',
    //             'Content-type'  : 'application/json',
    //             'usutoken'	    : localStorage.getItem('usutoken'),
    //         },
    //         body: JSON.stringify(data_module_views_get),
    //     }
    // )
    // .then( async res => {
    //         // await dispatch(estadoRequestReducer(res.status))
    //         return res.json()
    // })
    // .then(data => {
    //     if(data.respuesta == true){
    //         dispatch({
    //             type : OBTENER_VISTAS_MODULOS_ANALYTICS,
    //             payload: data.datos
    //         })
    //     }else{
    //         const audit = {
    //             url         : 'analytics/audit/views-average-module',
    //             message     : 'Error al obtener las vistas promedio por modulos', 
    //             description :data.message, 
    //             json_input  : data_module_views_get,
    //             json_output : data, 
    //             ip          : 'ip', 
    //             action      : 'GET VIEWS MODULES',
    //             status      : data.respuesta,
    //             module      : "ANALYTICS"
    //         }
    //         dispatch(RegisterAuditReducer(audit))
    //     }

    // }).catch((error)=> {
    //     console.log(error)
    //     // dispatch(RegistrarErrorReducer(error, config.api+'login', datos))
    // });
}
export const ObtenerVistasSubmodulosReducer = () => async (dispatch, getState) => {

    const user = getState().loginGrow.rex_data_user_logingrow

    const data_submodule_views_get = {
        req_paiid   : parseInt(localStorage.getItem('usupais')),
    }

    dispatch({
        type : OBTENER_VISTAS_SUBMODULOS_ANALYTICS,
        payload: [
            {
                "smoid": 6,
                "contenido": "Dashboard Comercial",
                "duracion": 3,
                "vista": 9,
                "minutos_vistas": 6
            },
            {
                "smoid": 73,
                "contenido": "Big Bets",
                "duracion": 6,
                "vista": 6,
                "minutos_vistas": 6
            },
            {
                "smoid": 106,
                "contenido": "Daily Sell In",
                "duracion": 3,
                "vista": 11,
                "minutos_vistas": 4
            },
            {
                "smoid": 125,
                "contenido": "Net Price | P&L",
                "duracion": 5,
                "vista": 3,
                "minutos_vistas": 5
            },
            {
                "smoid": 127,
                "contenido": "Commercial Policy",
                "duracion": 4,
                "vista": 17,
                "minutos_vistas": 6
            },
            {
                "smoid": 130,
                "contenido": "Dashboard TP",
                "duracion": 6,
                "vista": 13,
                "minutos_vistas": 5
            },
            {
                "smoid": 148,
                "contenido": "Portafolio Obligatorio (P.O.)",
                "duracion": 6,
                "vista": 11,
                "minutos_vistas": 3
            },
            {
                "smoid": 151,
                "contenido": "Mix Management",
                "duracion": 6,
                "vista": 12,
                "minutos_vistas": 6
            },
            {
                "smoid": 160,
                "contenido": "Estimados de Sell Out",
                "duracion": 6,
                "vista": 9,
                "minutos_vistas": 3
            },
            {
                "smoid": 161,
                "contenido": "Innovaciones",
                "duracion": 4,
                "vista": 2,
                "minutos_vistas": 4
            },
            {
                "smoid": 162,
                "contenido": "JBP",
                "duracion": 6,
                "vista": 7,
                "minutos_vistas": 6
            },
            {
                "smoid": 15,
                "contenido": "Productivity DTT Performance",
                "duracion": 4,
                "vista": 5,
                "minutos_vistas": 4
            },
            {
                "smoid": 53,
                "contenido": "Pricing HUB",
                "duracion": 3,
                "vista": 17,
                "minutos_vistas": 4
            },
            {
                "smoid": 54,
                "contenido": "Daily Sell Out Overview",
                "duracion": 6,
                "vista": 15,
                "minutos_vistas": 5
            },
            {
                "smoid": 55,
                "contenido": "Daily Sell Out Detailed",
                "duracion": 5,
                "vista": 13,
                "minutos_vistas": 6
            },
            {
                "smoid": 57,
                "contenido": "Inventory Performance",
                "duracion": 6,
                "vista": 9,
                "minutos_vistas": 5
            },
            {
                "smoid": 61,
                "contenido": "Daily Sell Out Summary",
                "duracion": 5,
                "vista": 18,
                "minutos_vistas": 5
            },
            {
                "smoid": 62,
                "contenido": "Sell Out Analyzer",
                "duracion": 4,
                "vista": 4,
                "minutos_vistas": 5
            },
            {
                "smoid": 121,
                "contenido": "Ranking de Coberturas",
                "duracion": 4,
                "vista": 4,
                "minutos_vistas": 5
            },
            {
                "smoid": 157,
                "contenido": "Proyectado Sell Out",
                "duracion": 6,
                "vista": 17,
                "minutos_vistas": 6
            },
            {
                "smoid": 158,
                "contenido": "Prioridades SO",
                "duracion": 3,
                "vista": 13,
                "minutos_vistas": 5
            },
            {
                "smoid": 166,
                "contenido": "Tracking Megarrollo",
                "duracion": 4,
                "vista": 18,
                "minutos_vistas": 3
            },
            {
                "smoid": 18,
                "contenido": "Daily Sell Out Summary",
                "duracion": 3,
                "vista": 9,
                "minutos_vistas": 4
            },
            {
                "smoid": 19,
                "contenido": "Daily Sell Out Detailed",
                "duracion": 5,
                "vista": 17,
                "minutos_vistas": 4
            },
            {
                "smoid": 20,
                "contenido": "Market Share",
                "duracion": 5,
                "vista": 17,
                "minutos_vistas": 5
            },
            {
                "smoid": 34,
                "contenido": "Sell Out Analyzer",
                "duracion": 6,
                "vista": 18,
                "minutos_vistas": 5
            },
            {
                "smoid": 36,
                "contenido": "Inventory Performance",
                "duracion": 5,
                "vista": 9,
                "minutos_vistas": 3
            },
            {
                "smoid": 40,
                "contenido": "Daily Sell Out Overview",
                "duracion": 6,
                "vista": 7,
                "minutos_vistas": 6
            },
            {
                "smoid": 150,
                "contenido": "Daily Sell Out Coverage",
                "duracion": 5,
                "vista": 18,
                "minutos_vistas": 6
            },
            {
                "smoid": 168,
                "contenido": "Radiografia",
                "duracion": 6,
                "vista": 15,
                "minutos_vistas": 5
            },
            {
                "smoid": 169,
                "contenido": "Quiebres-DHO",
                "duracion": 6,
                "vista": 5,
                "minutos_vistas": 6
            },
            {
                "smoid": 170,
                "contenido": "Sugeridos",
                "duracion": 5,
                "vista": 7,
                "minutos_vistas": 4
            },
            {
                "smoid": 171,
                "contenido": "Dashboard Web",
                "duracion": 5,
                "vista": 10,
                "minutos_vistas": 4
            },
            {
                "smoid": 21,
                "contenido": "Seguimiento Driver Perú | Gerencial",
                "duracion": 6,
                "vista": 8,
                "minutos_vistas": 6
            },
            {
                "smoid": 22,
                "contenido": "Seguimiento Driver Perú | Ejecutivo",
                "duracion": 3,
                "vista": 18,
                "minutos_vistas": 6
            },
            {
                "smoid": 131,
                "contenido": "Incentivos | Seguimiento Semanal",
                "duracion": 6,
                "vista": 17,
                "minutos_vistas": 5
            },
            {
                "smoid": 132,
                "contenido": "Incentivos | Seguimiento Semanal Gerencial",
                "duracion": 3,
                "vista": 11,
                "minutos_vistas": 6
            },
            {
                "smoid": 50,
                "contenido": "Productivity DTT Performance",
                "duracion": 3,
                "vista": 12,
                "minutos_vistas": 5
            },
            {
                "smoid": 64,
                "contenido": "Daily Sell Out Overview",
                "duracion": 3,
                "vista": 15,
                "minutos_vistas": 5
            },
            {
                "smoid": 65,
                "contenido": "Daily Sell Out Summary",
                "duracion": 5,
                "vista": 13,
                "minutos_vistas": 6
            },
            {
                "smoid": 66,
                "contenido": "Daily Sell Out Detailed",
                "duracion": 3,
                "vista": 18,
                "minutos_vistas": 3
            },
            {
                "smoid": 69,
                "contenido": "Sell Out Analyzer",
                "duracion": 4,
                "vista": 13,
                "minutos_vistas": 5
            },
            {
                "smoid": 70,
                "contenido": "Inventory Performance",
                "duracion": 5,
                "vista": 18,
                "minutos_vistas": 4
            },
            {
                "smoid": 154,
                "contenido": "Sell Out Analyzer Minicadenas",
                "duracion": 4,
                "vista": 2,
                "minutos_vistas": 3
            },
            {
                "smoid": 88,
                "contenido": "LP Perú",
                "duracion": 5,
                "vista": 15,
                "minutos_vistas": 4
            },
            {
                "smoid": 89,
                "contenido": "Resumen de Alzas",
                "duracion": 6,
                "vista": 18,
                "minutos_vistas": 3
            },
            {
                "smoid": 91,
                "contenido": "INDEX FEM",
                "duracion": 6,
                "vista": 14,
                "minutos_vistas": 4
            },
            {
                "smoid": 159,
                "contenido": "Creciendo Juntos",
                "duracion": 4,
                "vista": 10,
                "minutos_vistas": 6
            },
            {
                "smoid": 142,
                "contenido": "Trade AASS",
                "duracion": 3,
                "vista": 2,
                "minutos_vistas": 3
            },
            {
                "smoid": 143,
                "contenido": "Trade FARMA",
                "duracion": 3,
                "vista": 10,
                "minutos_vistas": 4
            },
            {
                "smoid": 144,
                "contenido": "Trade TRADICIONAL",
                "duracion": 5,
                "vista": 10,
                "minutos_vistas": 6
            },
            {
                "smoid": 145,
                "contenido": "Trade INTERCANAL",
                "duracion": 6,
                "vista": 18,
                "minutos_vistas": 4
            },
            {
                "smoid": 155,
                "contenido": "Frontend",
                "duracion": 3,
                "vista": 2,
                "minutos_vistas": 4
            },
            {
                "smoid": 156,
                "contenido": "Backend",
                "duracion": 5,
                "vista": 8,
                "minutos_vistas": 4
            },
            {
                "smoid": 165,
                "contenido": "Innovacion",
                "duracion": 6,
                "vista": 13,
                "minutos_vistas": 3
            }
        ]
    })

    // await fetch(config.api + 'analytics/audit/views-average-submodule',
    //     {
    //         mode:'cors',
    //         method: 'POST',
    //         headers: {
    //             'Accept'        : 'application/json',
    //             'Content-type'  : 'application/json',
    //             'usutoken'	    : localStorage.getItem('usutoken'),
    //         },
    //         body: JSON.stringify(data_submodule_views_get),
    //     }
    // )
    // .then( async res => {
    //     // await dispatch(estadoRequestReducer(res.status))
    //     return res.json()
    // })
    // .then(data => {
    //     if(data.respuesta == true){
    //         dispatch({
    //             type : OBTENER_VISTAS_SUBMODULOS_ANALYTICS,
    //             payload: data.datos
    //         })
    //     }else{
    //         const audit = {
    //             url         : 'analytics/audit/views-average-submodule',
    //             message     : 'Error al obtener las vistas promedio por submodulos', 
    //             description :data.message, 
    //             json_input  : data_submodule_views_get,
    //             json_output : data, 
    //             ip          : 'ip', 
    //             action      : 'GET VIEWS SUBMODULES',
    //             status      : data.respuesta,
    //             module      : "ANALYTICS"
    //         }
    //         dispatch(RegisterAuditReducer(audit))
    //     }

    // }).catch((error)=> {
    //     console.log(error)
    //     // dispatch(RegistrarErrorReducer(error, config.api+'login', datos))
    // });
}

export const ObtenerVistasSubSubmodulosReducer = () => async (dispatch, getState) => {

    const user = getState().loginGrow.rex_data_user_logingrow

    const data_subsubmodule_views_get = {
        req_paiid   : parseInt(localStorage.getItem('usupais')),
    }

    dispatch({
        type : OBTENER_VISTAS_SUBSUBMODULOS_ANALYTICS,
        payload: [
            {
                "ssmnombre": "Master Productos",
                "contenido": "Master Productos",
                "vista": 18,
                "duracion": 6,
                "key": 1
            },
            {
                "ssmnombre": "Master Clientes",
                "contenido": "Master Clientes",
                "vista": 6,
                "duracion": 3,
                "key": 2
            },
            {
                "ssmnombre": "Comercial Team",
                "contenido": "Comercial Team",
                "vista": 3,
                "duracion": 5,
                "key": 3
            },
            {
                "ssmnombre": "Incentive Program",
                "contenido": "Incentive Program",
                "vista": 7,
                "duracion": 6,
                "key": 4
            },
            {
                "ssmnombre": "Download Data",
                "contenido": "Download Data",
                "vista": 14,
                "duracion": 6,
                "key": 5
            }
        ]
    })
    // await fetch(config.api + 'analytics/audit/views-average-subsubmodule',
    //     {
    //         mode:'cors',
    //         method: 'POST',
    //         headers: {
    //             'Accept'        : 'application/json',
    //             'Content-type'  : 'application/json',
    //             'usutoken'	    : localStorage.getItem('usutoken'),
    //         },
    //         body: JSON.stringify(data_subsubmodule_views_get),
    //     }
    // )
    // .then( async res => {
    //     // await dispatch(estadoRequestReducer(res.status))
    //     return res.json()
    // })
    // .then(data => {
    //     if(data.respuesta == true){
    //         dispatch({
    //             type : OBTENER_VISTAS_SUBSUBMODULOS_ANALYTICS,
    //             payload: [
    //                 {
    //                     "ssmnombre": "Master Productos",
    //                     "contenido": "Master Productos",
    //                     "vista": 18,
    //                     "duracion": 6,
    //                     "key": 1
    //                 },
    //                 {
    //                     "ssmnombre": "Master Clientes",
    //                     "contenido": "Master Clientes",
    //                     "vista": 6,
    //                     "duracion": 3,
    //                     "key": 2
    //                 },
    //                 {
    //                     "ssmnombre": "Comercial Team",
    //                     "contenido": "Comercial Team",
    //                     "vista": 3,
    //                     "duracion": 5,
    //                     "key": 3
    //                 },
    //                 {
    //                     "ssmnombre": "Incentive Program",
    //                     "contenido": "Incentive Program",
    //                     "vista": 7,
    //                     "duracion": 6,
    //                     "key": 4
    //                 },
    //                 {
    //                     "ssmnombre": "Download Data",
    //                     "contenido": "Download Data",
    //                     "vista": 14,
    //                     "duracion": 6,
    //                     "key": 5
    //                 }
    //             ]
    //         })
    //     }else{
    //         const audit = {
    //             url         : 'analytics/audit/views-average-subsubmodule',
    //             message     : 'Error al obtener las vistas promedio por subsubmodulos', 
    //             description :data.message, 
    //             json_input  : data_subsubmodule_views_get,
    //             json_output : data, 
    //             ip          : 'ip', 
    //             action      : 'GET VIEWS SUBSUBMODULES',
    //             status      : data.respuesta,
    //             module      : "ANALYTICS"
    //         }
    //         dispatch(RegisterAuditReducer(audit))
    //     }

    // }).catch((error)=> {
    //     console.log(error)
    //     // dispatch(RegistrarErrorReducer(error, config.api+'login', datos))
    // });
}

export const ObtenerVistasPlataformaReducer = () => async (dispatch, getState) => {

    const user = getState().loginGrow.rex_data_user_logingrow

    const data_module_views_total = {
        req_paiid : parseInt(localStorage.getItem('usupais')),
    }
    
    await fetch(config.api + 'analytics/audit/views-users',
        {
            mode:'cors',
            method: 'POST',
            headers: {
                'Accept'        : 'application/json',
                'Content-type'  : 'application/json',
                'usutoken'	    : localStorage.getItem('usutoken'),
            },
            body: JSON.stringify(data_module_views_total),
        }
    )
    .then( async res => {
        // await dispatch(estadoRequestReducer(res.status))
        return res.json()
    })
    .then(data => {
        if(data.respuesta == true){
            dispatch({
                type : OBTENER_VISTAS_USUARIO_ANALYTICS,
                payload: data.cantidad_vistas
            })
        }else{
            const audit = {
                url         : 'analytics/audit/views-users',
                message     : 'Error al obtener las vistas por modulos', 
                description :data.message, 
                json_input  : data_module_views_total,
                json_output : data, 
                ip          : 'ip', 
                action      : 'GET VIEWS TOTAL',
                status      : data.respuesta,
                module      : "ANALYTICS"
            }
            dispatch(RegisterAuditReducer(audit))
        }

    }).catch((error)=> {
        console.log(error)
        // dispatch(RegistrarErrorReducer(error, config.api+'login', datos))
    });
}

export const ObtenerDataSliderReducer = (dataType, type) => async ( dispatch, getState ) => {

    let data_slider             = getState().analytics.rex_data_slider

    data_slider.map((data, indexDat) => {
        if(data.key == type){
            data_slider[indexDat]['numericSlider']  = dataType.numericSlider
            data_slider[indexDat]['textSlider']     = dataType.textSlider
        }
    })

    dispatch({
        type    : OBTENER_DATA_SLIDER_ANALYTICS,
        payload : data_slider
    })
}