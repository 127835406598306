import config from '../../../config'

export const GetDataHomeAdministratorReducer = () => async ( dispatch, getState ) => {
    
    let response = false
    await fetch(config.api+'home-administrator/all',
        {
            mode:'cors',
            method: 'POST',
            headers: {
                'Accept' 	     : 'application/json',
                'Content-type'   : 'application/json',
                'usutoken'	     : localStorage.getItem('usutoken'),
            },
            body: JSON.stringify({
                req_usupais : localStorage.getItem('usupais')
            }),
        }
    )
    .then( async res => {
        return res.json()
    })
    .then(async data => {
        if(data.response){
            
        }else{
        }
    }).catch((error)=> {
        // notificationGlobal('warning', 'Ha ocurrido un error en el servidor')
        console.log(error)
    });

    return response
}