import React, { useState } from 'react'

function DownArrow({size}){
    return (

        <svg width="22" height="22" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M14.1671 7.64076C14.0109 7.48556 13.7997 7.39844 13.5796 7.39844C13.3594 7.39844 13.1482 7.48556 12.9921 7.64076L10.0004 10.5908L7.0504 7.64076C6.89427 7.48556 6.68306 7.39844 6.4629 7.39844C6.24275 7.39844 6.03154 7.48556 5.8754 7.64076C5.7973 7.71823 5.7353 7.8104 5.693 7.91195C5.65069 8.0135 5.62891 8.12242 5.62891 8.23243C5.62891 8.34244 5.65069 8.45136 5.693 8.55291C5.7353 8.65446 5.7973 8.74663 5.8754 8.8241L9.40874 12.3574C9.48621 12.4355 9.57837 12.4975 9.67992 12.5398C9.78147 12.5821 9.89039 12.6039 10.0004 12.6039C10.1104 12.6039 10.2193 12.5821 10.3209 12.5398C10.4224 12.4975 10.5146 12.4355 10.5921 12.3574L14.1671 8.8241C14.2452 8.74663 14.3072 8.65446 14.3495 8.55291C14.3918 8.45136 14.4136 8.34244 14.4136 8.23243C14.4136 8.12242 14.3918 8.0135 14.3495 7.91195C14.3072 7.8104 14.2452 7.71823 14.1671 7.64076Z" fill="#013A81"/>
        </svg>
    )
}

const DownArrowButton = ({ size=14, mouseOver }) => {


    return (
        <DownArrow size={size}/>
    )
}

export default DownArrowButton