import {
    GET_DATA_HOME_ADMINISTRATOR,
} from "../../../Constants/HomeAdministrator/HomeAdministrator"


const INIT_STATE = {
    rex_data_home_administrator            : [],
}

export default (state = INIT_STATE, action) => {

    switch (action.type) {
        case GET_DATA_HOME_ADMINISTRATOR: {
            return {
                ...state,
                rex_data_home_administrator : action.payload,
            }
        }
        default:
            return state;
    }
}