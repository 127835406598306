import config from '../../../config'
import {
    LOADING_DATA_CHANNELS_EXECUTIVES,
    GET_DATA_CHANNELS_EXECUTIVES,
    DATA_FORM_EDIT_CHANNELS_EXECUTIVE,
    DATA_FILTER_EXECUTIVES_CHANNELS_EXECUTIVE,
    DATA_FILTER_CHANNELS_CHANNELS_EXECUTIVE,
    DATA_FILTER_ZONES_CHANNELS_EXECUTIVE,
    DATA_INFO_NO_SELECTED_CHANNELS_EXECUTIVE,
    GET_DATA_UPDATE_CHANNELS_EXECUTIVES,
    DATA_INFO_NO_SELECTED_CHANNELS_EXECUTIVE_CLONE,
    GET_DATA_FILTERS_CHANNELS_EXECUTIVES_GBA,
    GET_DATA_FILTERS_CHANNELS_EXECUTIVES_CHANNEL,
    GET_DATA_FILTERS_CHANNELS_EXECUTIVES_ZONE,
    GET_DATA_FILTERS_CHANNELS_EXECUTIVES_CLIENT,
    GET_DATA_FILTERS_TXT_CHANNELS_EXECUTIVE_TOTAL,
    GET_DATA_FILTERS_CHANNELS_EXECUTIVES_CUSTOMER_GROUP,
    DATE_FILTER_CHANNELS_EXECUTIVE,
} from '../../../Constants/ChannelsExecutive/ChannelsExecutive'
import notificationGlobal from '../../../Components/Global/NotificationGlobal'
import dayjs from 'dayjs'

export const GetDataChannelsExecutivesReducer = () => async ( dispatch, getState ) => {

    const date =  getState().channelsExecutives.rex_date_filter_channels_executives

    dispatch({
        type    : LOADING_DATA_CHANNELS_EXECUTIVES,
        payload : true
    })

    const { 
        rex_filters_channels_executive_program_total
    } = getState().channelsExecutives
    
    let currentDate
    if(typeof(date) == "string" && date.length == 7){
        currentDate = date
    }else{
        const date = dayjs(dayjs(), "AAAA-MM")
        const currentMonth  = date.month() + 1 < 10 ? "0"+ (date.month() + 1) : date.month() + 1
        const currentYear   = date.year()
        currentDate   = currentYear + "-" + currentMonth
    }

    await fetch(config.api+'channels-executives/get-channels-executives',
        {
            mode:'cors',
            method: 'POST',
            headers: {
                'Accept' : 'application/json',
                'Content-type' : 'application/json',
                'usutoken'	   : localStorage.getItem("usutoken"),
            },
            body: JSON.stringify({
                req_date : currentDate,
                req_fil_customer    : rex_filters_channels_executive_program_total.rex_filter_channels_executive_program.rex_txt_unique_customer_group,
                req_fil_gba         : rex_filters_channels_executive_program_total.rex_filter_channels_executive_program.rex_txt_unique_gba,
                req_fil_canal       : rex_filters_channels_executive_program_total.rex_filter_channels_executive_program.rex_txt_uniqueChannels,
                req_fil_zona        : rex_filters_channels_executive_program_total.rex_filter_channels_executive_program.rex_txt_unique_zona,
                req_fil_sucursal    : rex_filters_channels_executive_program_total.rex_filter_channels_executive_program.rex_txt_unique_sucursal,
            }),
        }
    )
    .then(res => res.json())
    .then(data => {
        if(data.response){
            const channelsExecutives = data.data.channelsExecutive
            channelsExecutives.map((ce, index) => { 
                channelsExecutives[index]["item"] = index + 1
            })
            dispatch({
                type    : GET_DATA_CHANNELS_EXECUTIVES,
                payload : channelsExecutives
            })

            dispatch({
                type    : GET_DATA_UPDATE_CHANNELS_EXECUTIVES,
                payload : data.data.dataLastUpdate
            })
        }
    }).catch((error) => {
        console.log(error)
    })

    dispatch({
        type : LOADING_DATA_CHANNELS_EXECUTIVES,
        payload : false
    })
}

export const GetFiltersChannelsExecutivesReducer = () => async ( dispatch, getState ) => {

    const filtersTotal      = getState().channelsExecutives.rex_filters_channels_executive_program_total

    await fetch(config.api+'channels-executives/filters',
        {
            mode:'cors',
            method: 'POST',
            headers: {
                'Accept' : 'application/json',
                'Content-type' : 'application/json',
                'usutoken'	   : localStorage.getItem("usutoken"),
            },
            body: JSON.stringify({
                req_filter_channel  : filtersTotal.rex_filter_channels_executive_program.rex_txt_uniqueChannels,
                req_filter_client   : filtersTotal.rex_filter_channels_executive_program.rex_txt_unique_cliente,
                req_filter_zone     : filtersTotal.rex_filter_channels_executive_program.rex_txt_unique_zona,
                req_filter_gba      : filtersTotal.rex_filter_channels_executive_program.rex_txt_unique_gba,
                req_filter_incentive: filtersTotal.rex_filter_channels_executive_program.rex_txt_unique_incentive,
                req_filter_sucursal : filtersTotal.rex_filter_channels_executive_program.rex_txt_unique_sucursal,
                req_filter_customer_group : filtersTotal.rex_filter_channels_executive_program.rex_txt_unique_customer_group,
            }),
        }
    )
    .then(res => res.json())
    .then(data => {
        if(data.response){
            dispatch({
                type    : GET_DATA_FILTERS_CHANNELS_EXECUTIVES_CUSTOMER_GROUP,
                payload : data.uniqueCustomerGroup
            })
            dispatch({
                type    : GET_DATA_FILTERS_CHANNELS_EXECUTIVES_GBA,
                payload : data.uniqueGba
            })
            dispatch({
                type    : GET_DATA_FILTERS_CHANNELS_EXECUTIVES_CHANNEL,
                payload : data.uniqueChannels
            })

            dispatch({
                type    : GET_DATA_FILTERS_CHANNELS_EXECUTIVES_ZONE,
                payload : data.uniqueZona
            })

            dispatch({
                type    : GET_DATA_FILTERS_CHANNELS_EXECUTIVES_CLIENT,
                payload : data.uniqueCliente
            })
        }
    }).catch((error) => {
        console.log(error)
    })
} 

export const EditValueChannelExecutiveReducer = (checked, ship_to) => async ( dispatch, getState ) => {

    let dataForm = getState().channelsExecutives.rex_data_form_edit_channels_executive
    let dataRows = getState().channelsExecutives.rex_data_info_no_selected_channels_executive

    if(checked){
        dataForm.unshift({...ship_to, key : ship_to.id, itemDefault : false })
    }else{
        dataForm = dataForm.filter(dat => dat.id != ship_to.id)
    }

    const indexShipTo = dataRows.dataShipTo.findIndex(shi => shi.id == ship_to.id)

    dataRows['dataShipTo'][indexShipTo]['selected'] = checked

    await dispatch({
        type    : DATA_FORM_EDIT_CHANNELS_EXECUTIVE,
        payload : dataForm
    })

    await dispatch({
        type    : DATA_INFO_NO_SELECTED_CHANNELS_EXECUTIVE,
        payload : dataRows
    })
}

export const DeleteChannelExecutiveReducer = (ids) => async ( dispatch, getState ) => {

    const date =  getState().channelsExecutives.rex_date_filter_channels_executives

    let response = false
    let alertDelete = false
    let messageConfirm = []

    let currentDate
    if(typeof(date) == "string" && date.length == 7){
        currentDate = date
    }else{
        const date = dayjs(dayjs(), "AAAA-MM")
        const currentMonth  = date.month() + 1 < 10 ? "0"+ (date.month() + 1) : date.month() + 1
        const currentYear   = date.year()
        currentDate   = currentYear + "-" + currentMonth
    }

    await fetch(config.api+'channels-executives/delete-channel-executive',
        {
            mode:'cors',
            method: 'POST',
            headers: {
                'Accept' : 'application/json',
                'Content-type' : 'application/json',
                'usutoken'	   : localStorage.getItem("usutoken"),
            },
            body: JSON.stringify({
                req_ids     : ids,
            }),
        }
    )
    .then(res => res.json())
    .then(data => {
        if(data.response){
            dispatch(GetDataChannelsExecutivesReducer(currentDate))
            notificationGlobal('success', data.message)
        }else{
            notificationGlobal('warning', data.message)
        }
    }).catch((error) => {
        console.log(error)
    })

    return { response, alertDelete, messageConfirm }
}

export const GetInfoChannelsExecutivesReducer = (item = null) => async ( dispatch, getState ) => {

    const { 
        rex_data_info_no_selected_channels_executive
    } = getState().channelsExecutives

    dispatch({
        type : DATA_FORM_EDIT_CHANNELS_EXECUTIVE,
        payload : []
    })

    let ejecutivo = ""
    let canal = ""
    let zona = ""

    await fetch(config.api+'channels-executives/all-filters',
        {
            mode:'cors',
            method: 'POST',
            headers: {
                'Accept' : 'application/json',
                'Content-type' : 'application/json',
                'usutoken'	   : localStorage.getItem("usutoken"),
            },
            body: JSON.stringify({
                req_key     : item,
            }),
        }
    )
    .then(res => res.json())
    .then(data => {
        if(data.response){
        
            //Filtro ejecutivo
            dispatch({
                type: DATA_FILTER_EXECUTIVES_CHANNELS_EXECUTIVE,
                payload : data.data.executivesSelect
            })

            dispatch({
                type : DATA_FILTER_CHANNELS_CHANNELS_EXECUTIVE,
                payload : data.data.channelsSelect
            })

            dispatch({
                type    : DATA_FILTER_ZONES_CHANNELS_EXECUTIVE,
                payload : data.data.zonesSelect
            })

            //Data estática
            const dataNoSelected = {
                ...rex_data_info_no_selected_channels_executive, 
                dataChannel : data.data.allData[0],
                dataZones   : data.data.allData[1], 
                dataShipTo  : data.data.allData[2],
            }

            dispatch({
                type    : DATA_INFO_NO_SELECTED_CHANNELS_EXECUTIVE_CLONE,
                payload : dataNoSelected
            })
            dispatch({
                type    : DATA_INFO_NO_SELECTED_CHANNELS_EXECUTIVE,
                payload : dataNoSelected
            })

            //Data default
            dispatch({
                type : DATA_FORM_EDIT_CHANNELS_EXECUTIVE,
                payload : data.data.defaultData.dataTableDefault
            })

            ejecutivo   = data.data.defaultData.ejecutivo
            canal       = data.data.defaultData.canal
            zona        = data.data.defaultData.zona
        }
    }).catch((error) => {
        console.log(error)
    })

    return { ejecutivo, canal, zona }
}


export const DeleteItemFormEditChannelsExecutivesReducer = (item, selectedAll=true) => async ( dispatch, getState ) => {
    
    let dataFormEdit = getState().channelsExecutives.rex_data_form_edit_channels_executive
    let dataRows = getState().channelsExecutives.rex_data_info_no_selected_channels_executive

    if(!selectedAll){


        let idsDelete = item.map(ite => ite.id)

        dataRows.dataShipTo.map(shi => {
            if(idsDelete.includes(shi.id)){
                shi['selected'] = false
            }
        })

        dataFormEdit = dataFormEdit.filter(shi => idsDelete.includes(shi.id) ? false : true)
    }else{
        const indexShipTo = dataRows.dataShipTo.findIndex(shi => shi.id == item.id)
        dataRows['dataShipTo'][indexShipTo]['selected'] = false
        
        dataFormEdit = dataFormEdit.filter(dat => dat.id != item.id)    
    }

    await dispatch({
        type    : DATA_INFO_NO_SELECTED_CHANNELS_EXECUTIVE,
        payload : dataRows
    })
    
    dispatch({
        type : DATA_FORM_EDIT_CHANNELS_EXECUTIVE,
        payload : dataFormEdit
    })
}

export const AddItemSelectExecutiveFormEditChannelsExecutivesReducer = ( valueName ) => async ( dispatch, getState ) => {

    let filtersTotal = getState().channelsExecutives.rex_data_filter_executives_channels_executive

    const newValueSelect = { label : valueName, value: valueName }

    filtersTotal = [...filtersTotal, newValueSelect ]

    dispatch({
        type    : DATA_FILTER_EXECUTIVES_CHANNELS_EXECUTIVE,
        payload : filtersTotal
    })
}

export const AddItemSelectChannelFormEditChannelsExecutivesReducer = ( valueName ) => async ( dispatch, getState ) => {

    let filtersTotal = getState().channelsExecutives.rex_data_filter_channels_channels_executive

    const newValueSelect = { label : valueName, value: valueName }

    filtersTotal = [...filtersTotal, newValueSelect ]

    dispatch({
        type    : DATA_FILTER_CHANNELS_CHANNELS_EXECUTIVE,
        payload : filtersTotal
    })
}

export const AddItemSelectZoneFormEditChannelsExecutivesReducer = ( valueName ) => async ( dispatch, getState ) => {

    let filtersTotal = getState().channelsExecutives.rex_data_filter_zones_channels_executive

    const newValueSelect = { label : valueName, value: valueName }

    filtersTotal = [...filtersTotal, newValueSelect ]

    dispatch({
        type    : DATA_FILTER_ZONES_CHANNELS_EXECUTIVE,
        payload : filtersTotal
    })
}

export const CambiarTxtFilterChannelsExecutivesReducer = (type, txt_data, dateFilter, view) => async ( dispatch, getState ) => {

    const filtersTotal = getState().channelsExecutives.rex_filters_channels_executive_program_total

    if(!txt_data){
        txt_data = ""
    }

    if(type == "Customer Group"){
        type = "rex_txt_unique_customer_group"
    }else if(type == "Canal"){
        type = "rex_txt_uniqueChannels"
    }else if(type == "Zona"){
        type = "rex_txt_unique_zona"
    }else if(type == "Cliente HML"){
        type = "rex_txt_unique_sucursal"
    }else if(type == "GBA"){
        type = "rex_txt_unique_gba"
    }

    if(view == "channelsExecutive"){
        const valuesFilter = filtersTotal['rex_filter_channels_executive_program']
        filtersTotal['rex_filter_channels_executive_program'] = {...valuesFilter, [type] : txt_data}
    }

    dispatch({
        type : GET_DATA_FILTERS_TXT_CHANNELS_EXECUTIVE_TOTAL,
        payload : filtersTotal
    })

    dispatch(GetFiltersChannelsExecutivesReducer())

    if(view == "channelsExecutive"){
        await dispatch(GetDataChannelsExecutivesReducer(dateFilter))
    }
}

export const SaveExecutiveChannelChannelsExecutivesReducer = (id = null, executive, channel, zone, date) => async ( dispatch, getState ) => {

    let response = false
    let dataClients = getState().channelsExecutives.rex_data_form_edit_channels_executive
    let currentDate

    if(typeof(date) == "string" && date.length == 7){
        currentDate = date
    }else{
        const date = dayjs(dayjs(), "AAAA-MM")
        const currentMonth  = date.month() + 1 < 10 ? "0"+ (date.month() + 1) : date.month() + 1
        const currentYear   = date.year()
        currentDate   = currentYear + "-" + currentMonth
    }
    dataClients = dataClients.map(cli => {
        return { id : cli.id, sold_to : cli.sold_to }
    })

    await fetch(config.api+'channels-executives/create-channel-executive',
        {
            mode:'cors',
            method: 'POST',
            headers: {
                'Accept' : 'application/json',
                'Content-type' : 'application/json',
                'usutoken'	   : localStorage.getItem("usutoken"),
            },
            body: JSON.stringify({
                req_date        : currentDate,
                req_executive   : executive,
                req_channel     : channel,
                req_zone        : zone,
                req_clients     : dataClients,
                req_id          : id
            }),
        }
    )
    .then(res => res.json())
    .then(data => {
        if(data.response){
            response = true
            notificationGlobal('success', data.message)
        }else{
            notificationGlobal('warning', data.message)
        }
    }).catch((error) => {
        console.log(error)
    })

    return response
}

export const FilterShipToChannelsExecutivesReducer = (txt, order) => async ( dispatch, getState ) => {
    
    const dataFilters = {...getState().channelsExecutives.rex_data_info_no_selected_channels_executive_clone}
    const dataFiltersOriginal = {...getState().channelsExecutives.rex_data_info_no_selected_channels_executive}

    const zonesSelect = dataFiltersOriginal.dataZones.filter(zon => zon.selected == true).map(zon => zon.zone)
    let dataFiltered = []

    if(txt != ""){
        dataFiltered = dataFilters.dataShipTo.filter(shi  => {
            if(zonesSelect.length > 0){
                return shi.ship_to.toLowerCase().includes(txt.toLocaleLowerCase()) && zonesSelect.includes(shi.zone)
            }else{
                return shi.ship_to.toLowerCase().includes(txt.toLocaleLowerCase())
            }
        }).sort((a, b) => {
            if(order == "asc"){
                return a.channel.trimStart().localeCompare(b.channel.trimStart(), 'es', { sensitivity: 'base' })
            }else if(order == "desc"){
                return b.channel.trimStart().localeCompare(a.channel.trimStart(), 'es', { sensitivity: 'base' })
            }else{
                return 0
            }
        })
    }else{

        if(zonesSelect.length > 0){
            dataFiltered = dataFilters.dataShipTo.filter(shi  => zonesSelect.includes(shi.zone))
        }else{
            dataFiltered = dataFilters.dataShipTo
        }
    }

    const channelsSelected = dataFilters.dataChannel.filter(ch => ch.selected == true).map(chs => chs.channel)

    if(channelsSelected.length > 0){
        
        let dataFilteredZone = []

        dataFilters.dataZones.map(zon => {
            if(channelsSelected.includes(zon.zones[0])){
                dataFilteredZone.push(zon)
            }
        })

        dataFilters['dataZones'] = dataFilteredZone
    }

    dataFilters['dataShipTo'] = dataFiltered

    dispatch({
        type    : DATA_INFO_NO_SELECTED_CHANNELS_EXECUTIVE,
        payload : dataFilters
    })
}

export const FilterChannelChannelsExecutivesReducer = (txt, order) => async ( dispatch, getState ) => {
    
    const dataFilters = {...getState().channelsExecutives.rex_data_info_no_selected_channels_executive_clone}

    const dataFiltered = dataFilters.dataChannel.filter(ch  => {
        return ch.channel.toLowerCase().includes(txt.toLocaleLowerCase())
    }).sort((a, b) => {
        if(order == "asc"){
            return a.channel.trimStart().localeCompare(b.channel.trimStart(), 'es', { sensitivity: 'base' })
        }else if(order == "desc"){
            return b.channel.trimStart().localeCompare(a.channel.trimStart(), 'es', { sensitivity: 'base' })
        }else{
            return 0
        }
    })

    dataFilters['dataChannel'] = dataFiltered

    dispatch({
        type    : DATA_INFO_NO_SELECTED_CHANNELS_EXECUTIVE,
        payload : dataFilters
    })
}

export const FilterZonesChannelsExecutivesReducer = (txt, order) => async ( dispatch, getState ) => {

    const dataFilters = {...getState().channelsExecutives.rex_data_info_no_selected_channels_executive}
    const dataFiltersOriginal = {...getState().channelsExecutives.rex_data_info_no_selected_channels_executive_clone}

    const channelsSelected = dataFilters.dataChannel.filter(ch => ch.selected == true).map(chs => chs.channel)

    let dataFiltered
    if(txt != ""){
        dataFiltered = dataFilters.dataZones.filter(ch  => {
            if(channelsSelected.length > 0){
                return ch.zone.toLowerCase().includes(txt.toLocaleLowerCase()) && channelsSelected.includes(ch.zones[0])
            }else{
                return ch.zone.toLowerCase().includes(txt.toLocaleLowerCase())
            }
        }).sort((a, b) => {
            if(order == "asc"){
                return a.zone.trimStart().localeCompare(b.zone.trimStart(), 'es', { sensitivity: 'base' })
            }else if(order == "desc"){
                return b.zone.trimStart().localeCompare(a.zone.trimStart(), 'es', { sensitivity: 'base' })
            }else{
                return 0
            }
        })
    }else{
        const channelsSelected = dataFilters.dataChannel.filter(ch => ch.selected == true).map(chs => chs.channel)

        if(channelsSelected.length > 0){
            dataFiltered = dataFiltersOriginal.dataZones.filter(zon => channelsSelected.includes(zon.zones[0]))
        }else{
            dataFiltered = dataFiltersOriginal.dataZones
        }
    }

    dataFilters['dataZones'] = dataFiltered

    dispatch({
        type    : DATA_INFO_NO_SELECTED_CHANNELS_EXECUTIVE,
        payload : dataFilters
    })
}

export const SelectChannelChannelsExecutivesReducer = (channel, value) => async ( dispatch, getState ) => {

    const dataFilters = {...getState().channelsExecutives.rex_data_info_no_selected_channels_executive_clone}

    const indexChannel = dataFilters.dataChannel.findIndex(ch => ch.channel == channel)
    dataFilters.dataChannel[indexChannel]['selected'] = value

    const channelsSelected = dataFilters.dataChannel.filter(ch => ch.selected == true).map(chs => chs.channel)

    if(channelsSelected.length > 0){
        const zonesSelected = dataFilters.dataZones.filter(zon => channelsSelected.includes(zon.zones[0]))
        dataFilters.dataZones = zonesSelected
    }

    dispatch({
        type : DATA_INFO_NO_SELECTED_CHANNELS_EXECUTIVE,
        payload : dataFilters 
    })
}

export const SelectZonesChannelsExecutivesReducer = (zone, value) => async ( dispatch, getState ) => {

    const dataFilters = { ...getState().channelsExecutives.rex_data_info_no_selected_channels_executive_clone }
    const dataFiltersOriginal = { ...getState().channelsExecutives.rex_data_info_no_selected_channels_executive }

    const indexZone = dataFilters.dataZones.findIndex(zon => zon.zone == zone)
    dataFilters.dataZones[indexZone]['selected'] = value

    const zonesSelected = dataFilters.dataZones.filter(zon => zon.selected == true).map(zos => zos.zone)

    dataFilters.dataZones = dataFiltersOriginal.dataZones
    
    if(zonesSelected.length > 0){
        const shipToSelected = dataFilters.dataShipTo.filter(shi => zonesSelected.includes(shi.zone))
        dataFilters.dataShipTo = shipToSelected
    }

    dispatch({
        type : DATA_INFO_NO_SELECTED_CHANNELS_EXECUTIVE,
        payload : dataFilters 
    })
}

export const SelectAllShipToChannelsExecutivesReducer = (checked) => async ( dispatch, getState ) => {

    const dataFilters = { ...getState().channelsExecutives.rex_data_info_no_selected_channels_executive }
    let dataForm = getState().channelsExecutives.rex_data_form_edit_channels_executive
    let newDataForm = []

    if(!checked){
        const idsDelete = dataFilters.dataShipTo.map(shi => shi.id)
        dataFilters.dataShipTo.map(shi => {
            shi['selected'] = false
        })

        dataForm = dataForm.filter(shi => { return idsDelete.includes(shi.id)? false : true})

    }else{
        dataFilters.dataShipTo.map(shi => {
            shi['selected'] = true
            if(checked){
                newDataForm.push({...shi, key: shi.id})
            }
        })
    }

    dataForm = dataForm.concat(newDataForm)

    const key = 'id';
    const uniqueShipTo = [...new Map(dataForm.map(item =>
        [item[key], item])).values()];

    await dispatch({
        type    : DATA_FORM_EDIT_CHANNELS_EXECUTIVE,
        payload : uniqueShipTo
    })

    dispatch({
        type : DATA_INFO_NO_SELECTED_CHANNELS_EXECUTIVE,
        payload : dataFilters 
    })
}

export const SelectAllZonesChannelsExecutivesReducer = (checked) => async ( dispatch, getState ) => {
    
    const dataFilters = { ...getState().channelsExecutives.rex_data_info_no_selected_channels_executive }
    const dataFiltersOriginal = { ...getState().channelsExecutives.rex_data_info_no_selected_channels_executive_clone }

    dataFilters.dataZones.map(zon => {
        zon['selected'] = checked
    })

    if(!checked){
        dataFilters.dataShipTo.map(shi => {
            
            let indexShipTo = dataFiltersOriginal.dataShipTo.findIndex(shif => shif.id == shi.id)
            dataFiltersOriginal.dataShipTo[indexShipTo]['selected'] = shi.selected

        })
    }
    
    dataFilters['dataShipTo'] = dataFiltersOriginal.dataShipTo
    
    dispatch({
        type : DATA_INFO_NO_SELECTED_CHANNELS_EXECUTIVE,
        payload : dataFilters 
    })

}

export const SelectAllChannelsChannelsExecutivesReducer = (checked) => async ( dispatch, getState ) => {

    const dataFilters = { ...getState().channelsExecutives.rex_data_info_no_selected_channels_executive }
    const dataFiltersOriginal = { ...getState().channelsExecutives.rex_data_info_no_selected_channels_executive_clone }

    dataFilters.dataChannel.map(ch => {
        ch['selected'] = checked
    })

    if(!checked){
        dataFilters.dataZones.map(zon => {
            
            let indexZone = dataFiltersOriginal.dataZones.findIndex(zonf => zonf.id == zon.id)
            dataFiltersOriginal.dataZones[indexZone]['selected'] = zon.selected

        })
    }

    dataFilters['dataZones'] = dataFiltersOriginal.dataZones
    
    dispatch({
        type : DATA_INFO_NO_SELECTED_CHANNELS_EXECUTIVE,
        payload : dataFilters 
    })
}

export const SetDateFilterChannelsExecutivesReducer = (date) => async ( dispatch, getState ) => {
    dispatch({
        type    : DATE_FILTER_CHANNELS_EXECUTIVE,
        payload : date
    })
}

export const SaveChannelsExecutivesReducer = (date, action) => async ( dispatch, getState ) => {

    const filterDate    = date ? dayjs(dayjs(date), "AAAA-MM") : dayjs(dayjs(), "AAAA-MM")
    const filterYear    = filterDate.year()
    const filterMonth   = filterDate.month() + 1

    const confirmDelete = action == "confirm-save-channels-executives"

    let response

    await fetch(config.api+'channels-executives/'+ action,
        {
            mode:'cors',
            method: 'POST',
            headers: {
                'Accept' : 'application/json',
                'Content-type' : 'application/json',
                'usutoken'	   : localStorage.getItem("usutoken"),
            },
            body: JSON.stringify({
                req_month   : filterMonth,
                req_year    : filterYear
            }),
        }
    )
    .then(res => res.json())
    .then(data => {

        response = data.response
        if(confirmDelete || data.generate){
            notificationGlobal(data.alert ? "warning" : data.response ? "success" : "warning", data.message)
        }
    }).catch((error) => {
        console.log(error)
    })

    return response
}