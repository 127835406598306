
import notificationGlobal from "../../../../Components/Global/NotificationGlobal";
import config from "../../../../config";
import {
    DATA_ALERT_DELETE_PRODUCTS,
    DATA_FORM_EDIT_MASTER_PRODUCT,
    DATA_FORM_EDIT_MASTER_PRODUCT_MANUAL,
    DATA_FORM_EDIT_MASTER_PRODUCT_MSL,
    GET_DATA_MASTER_PRODUCT_MANAGE,
    GET_DATA_MASTER_PRODUCT_MANUALS_MANAGE,
    GET_DATA_MASTER_PRODUCTS_MANAGE,
    GET_DATA_MASTER_PRODUCTS_MANAGE_CLONE,
    LOADING_GET_DATA_MASTER_PRODUCTS_MANAGE,
    SHOW_MODAL_ALERT_DELETE_PRODUCTS,

    // GETS FILTERS MASTER PRODUCTS
    GET_DATA_FILTERS_CHANNELS_MASTER_PRODUCTS_MANAGE,
    GET_DATA_FILTERS_ZONA_MASTER_PRODUCTS_MANAGE,
    GET_DATA_FILTERS_CLIENTES_MASTER_PRODUCTS_MANAGE,
    GET_DATA_FILTERS_SUCURSAL_MASTER_PRODUCTS_MANAGE,
    GET_DATA_FILTERS_GBA_MASTER_PRODUCTS_MANAGE,

    GET_DATA_FILTERS_TXT_PRODUCTS_TOTAL,
    LOADING_GET_DATA_MASTER_PRODUCTS_MANUAL_MANAGE,
    GET_DATA_FILTERS_CUSTOMER_GROUP_MASTER_PRODUCTS_MANAGE,
    DATE_LAST_UPDATED_PRODUCTS_MANAGE
} from "../../../../Constants/Manage/MasterProducts/MasterProducts";
import dayjs from 'dayjs';
import { GetMslManageReducer } from "../MSL/MSL";

export const DeleteMasterProductManageReducer  = ( ids, confirmDelete = false, dateProduct, dateMsl, DateNewProduct ) => async ( dispatch, getState) => {

    let response = false
    let alertDelete
    let messageConfirm = []

    let currentDateProduct
    if(typeof(dateProduct) == "string" && dateProduct.length == 7){
        currentDateProduct = dateProduct
    }else{
        const date = dayjs(dayjs(), "AAAA-MM")
        const currentMonth  = date.month() + 1 < 10 ? "0"+ (date.month() + 1) : date.month() + 1
        const currentYear   = date.year()
        currentDateProduct   = currentYear + "-" + currentMonth
    }

    let currentDateMsl
    if(typeof(dateMsl) == "string" && dateMsl.length == 7){
        currentDateMsl = dateMsl
    }else{
        const date = dayjs(dayjs(), "AAAA-MM")
        const currentMonth  = date.month() + 1 < 10 ? "0"+ (date.month() + 1) : date.month() + 1
        const currentYear   = date.year()
        currentDateMsl   = currentYear + "-" + currentMonth
    }

    let currentDateNewProduct
    if(typeof(DateNewProduct) == "string" && DateNewProduct.length == 7){
        currentDateNewProduct = DateNewProduct
    }else{
        const date = dayjs(dayjs(), "AAAA-MM")
        const currentMonth  = date.month() + 1 < 10 ? "0"+ (date.month() + 1) : date.month() + 1
        const currentYear   = date.year()
        currentDateNewProduct   = currentYear + "-" + currentMonth
    }


    await fetch(config.api+'manage/pe/delete-master-product',
        {
            mode:'cors',
            method: 'POST',
            headers: {
                'Accept' 	   : 'application/json',
                'Content-type' : 'application/json',
                'usutoken'      : localStorage.getItem('usutoken')
            },
            body: JSON.stringify({
                req_ids : ids,
                req_confirmDelete : confirmDelete
            }),
        }
    )
    .then( async res => {
        return res.json()
    })
    .then(data => {
        if(data.response){
            if(!data.alertDelete){
                notificationGlobal('success', data.message)
                dispatch(GetMasterProductsManualsManageReducer(currentDateNewProduct))
                dispatch(GetMasterProductsManageReducer(currentDateProduct))
                dispatch(GetMslManageReducer(currentDateMsl))
            }else{
                alertDelete = true
                dispatch({
                    type    : SHOW_MODAL_ALERT_DELETE_PRODUCTS,
                    payload : true
                })
                dispatch({
                    type    : DATA_ALERT_DELETE_PRODUCTS,
                    payload : data.messageConfirmDelete
                })
            }

            messageConfirm = data.messageConfirmDelete
            response = true
        }else{
            notificationGlobal('warning', data.message)
        }
    }).catch((error)=> {
        console.log(error)
    });

    return { response, alertDelete, messageConfirm }
}

export const CancelConfirmDeleteProductsManageReducer  = ( data ) => async ( dispatch, getState) => {

    dispatch({
        type : SHOW_MODAL_ALERT_DELETE_PRODUCTS,
        payload : false
    })

    dispatch({
        type : DATA_ALERT_DELETE_PRODUCTS,
        payload : []
    })
}

export const CreateMasterProductManageReducer = ( data ) => async ( dispatch, getState) => {

    let response = false

    await fetch(config.api+'manage/pe/create-master-product',
        {
            mode:'cors',
            method: 'POST',
            headers: {
                'Accept' 	   : 'application/json',
                'Content-type' : 'application/json',
                'usutoken'      : localStorage.getItem('usutoken')
            },
            body: JSON.stringify({
                req_data : data
            }),
        }
    )
    .then( async res => {
        return res.json()
    })
    .then(data => {
        if(data.response){
            response = true
            notificationGlobal('success', data.message)
        }else{
            notificationGlobal('warning', data.message)
        }
    }).catch((error)=> {
        console.log(error)
    });

    return response
}

export const EditMasterProductManageReducer  = ( id, typeForm, date ) => async ( dispatch, getState) => {

    let dataRows
    if(typeForm == 'manual'){
        dataRows = getState().masterProducts.rex_data_form_edit_master_product_manual
    }else if(typeForm == 'msl'){
        dataRows = getState().masterProducts.rex_data_form_edit_master_product_msl
    }else{
        dataRows = getState().masterProducts.rex_data_form_edit_master_product
    }
    const dataRow = dataRows.find(dat => dat.id == id)

    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth() + 1;
    const dateFormat = `${currentYear}-${currentMonth.toString().padStart(2, '0')}`;

    const dataProduct = {...dataRow.data, anio:currentYear, mes:currentMonth, fecha: dateFormat}

    let response = false

    await fetch(config.api+'manage/pe/edit-master-product',
        {
            mode:'cors',
            method: 'POST',
            headers: {
                'Accept' 	   : 'application/json',
                'Content-type' : 'application/json',
                'usutoken'      : localStorage.getItem('usutoken')
            },
            body: JSON.stringify({
                req_id : id,
                req_data : dataProduct
            }),
        }
    )
    .then( async res => {
        return res.json()
    })
    .then(data => {
        if(data.response){
            const rowsEditing = dataRows.filter(dat => dat.id != id)
            response = true
            dispatch(GetMasterProductsManageReducer(typeForm == 'products' ? date : null))
            dispatch(GetMasterProductsManualsManageReducer(typeForm == 'manual' ? date : null))
            dispatch(GetMslManageReducer(typeForm == 'msl' ? date : null))
            notificationGlobal('success', data.message)

            if(typeForm == 'manual'){
                dispatch({
                    type    : DATA_FORM_EDIT_MASTER_PRODUCT_MANUAL,
                    payload : rowsEditing
                })
            }else if(typeForm == 'msl'){
                dispatch({
                    type    : DATA_FORM_EDIT_MASTER_PRODUCT_MSL,
                    payload : rowsEditing
                })
            }else{
                dispatch({
                    type    : DATA_FORM_EDIT_MASTER_PRODUCT,
                    payload : rowsEditing
                })
            }

        }else{
            notificationGlobal('warning', data.message)
        }
    }).catch((error)=> {
        console.log(error)
    });

    return response
}

export const GetMasterProductManageReducer = ( id ) => async ( dispatch, getState) => {
    
    await fetch(config.api+'manage/pe/get-master-product',
        {
            mode:'cors',
            method: 'POST',
            headers: {
                'Accept' 	   : 'application/json',
                'Content-type' : 'application/json',
                'usutoken'      : localStorage.getItem('usutoken')
            },
            body: JSON.stringify({req_id : id}),
        }
    )
    .then( async res => {
        return res.json()
    })
    .then(data => {
        if(data.response){
            dispatch({
                type: GET_DATA_MASTER_PRODUCT_MANAGE,
                payload : data.data
            })
        }
    }).catch((error)=> {
        console.log(error)
    });
    const { rex_data_master_product_manage } = getState().masterProducts
    return rex_data_master_product_manage
}

export const GetMasterProductsManageReducer = ( date = null ) => async ( dispatch, getState) => {
    
    const { 
        rex_filters_products_total
    } = getState().filtersProducts

    dispatch({
        type    : LOADING_GET_DATA_MASTER_PRODUCTS_MANAGE,
        payload : true
    })

    let currentDate
    if(typeof(date) == "string" && date.length == 7){
        currentDate = date
    }else{
        const date = dayjs(dayjs(), "AAAA-MM")
        const currentMonth  = date.month() + 1 < 10 ? "0"+ (date.month() + 1) : date.month() + 1
        const currentYear   = date.year()
        currentDate   = currentYear + "-" + currentMonth
    }

    await fetch(config.api+'manage/pe/master-products',
        {
            mode:'cors',
            method: 'POST',
            headers: {
                'Accept' 	   : 'application/json',
                'Content-type' : 'application/json',
                'usutoken'      : localStorage.getItem('usutoken')
            },
            body: JSON.stringify({
                req_date : currentDate,
                req_fil_customer : rex_filters_products_total.rex_filter_products.rex_txt_customer_group,
                req_fil_gba : rex_filters_products_total.rex_filter_products.rex_txt_unique_gba,
                req_fil_zona : rex_filters_products_total.rex_filter_products.rex_txt_unique_zona,
                req_fil_canal : rex_filters_products_total.rex_filter_products.rex_txt_uniqueChannels,
                req_fil_cliente : rex_filters_products_total.rex_filter_products.rex_txt_unique_cliente,
                req_fil_sucursal : rex_filters_products_total.rex_filter_products.rex_txt_unique_sucursal,
            }),
        }
    )
    .then( async res => {
        return res.json()
    })
    .then(data => {
        if(data.response){

            dispatch({
                type: GET_DATA_MASTER_PRODUCTS_MANAGE,
                payload : data.data
            })
            dispatch({
                type: GET_DATA_MASTER_PRODUCTS_MANAGE_CLONE,
                payload : data.data
            })
            dispatch({
                type : DATE_LAST_UPDATED_PRODUCTS_MANAGE,
                payload : data.lastUpdatedProduct
            })
        }
    }).catch((error)=> {
        console.log(error)
    });
    dispatch({
        type    : LOADING_GET_DATA_MASTER_PRODUCTS_MANAGE,
        payload : false
    })
}
export const GetMasterProductsManualsManageReducer = ( date = null ) => async ( dispatch, getState) => {
    
    const { 
        rex_filters_products_total
    } = getState().filtersProducts

    dispatch({
        type    : LOADING_GET_DATA_MASTER_PRODUCTS_MANUAL_MANAGE,
        payload : true
    })

    let currentDate
    if(typeof(date) == "string" && date.length == 7){
        currentDate = date
    }else{
        const date = dayjs(dayjs(), "AAAA-MM")
        const currentMonth  = date.month() + 1 < 10 ? "0"+ (date.month() + 1) : date.month() + 1
        const currentYear   = date.year()
        currentDate   = currentYear + "-" + currentMonth
    }

    await fetch(config.api+'manage/pe/master-products-manuals',
        {
            mode:'cors',
            method: 'POST',
            headers: {
                'Accept' 	   : 'application/json',
                'Content-type' : 'application/json',
                'usutoken'      : localStorage.getItem('usutoken')
            },
            body: JSON.stringify({
                req_date : currentDate,
                req_fil_customer : rex_filters_products_total.rex_filter_new.rex_txt_customer_group,
                req_fil_gba : rex_filters_products_total.rex_filter_new.rex_txt_unique_gba,
                req_fil_zona : rex_filters_products_total.rex_filter_new.rex_txt_unique_zona,
                req_fil_canal : rex_filters_products_total.rex_filter_new.rex_txt_uniqueChannels,
                req_fil_cliente : rex_filters_products_total.rex_filter_new.rex_txt_unique_cliente,
                req_fil_sucursal : rex_filters_products_total.rex_filter_new.rex_txt_unique_sucursal,
            }),
        }
    )
    .then( async res => {
        return res.json()
    })
    .then(data => {
        if(data.response){

            dispatch({
                type: GET_DATA_MASTER_PRODUCT_MANUALS_MANAGE,
                payload : data.data
            })
        }
    }).catch((error)=> {
        console.log(error)
    });

    dispatch({
        type    : LOADING_GET_DATA_MASTER_PRODUCTS_MANUAL_MANAGE,
        payload : false
    })
}

export const FilterMasterProductsManageReducer = (txtSearch, countrySearch) => async ( dispatch, getState ) => {

    const master_products = getState().masterProducts.rex_data_master_products_manage_clone

    const dataFilter = master_products.filter((pro) => (
        pro.material.toLowerCase().includes(txtSearch.toLowerCase())
    ))

    dispatch({
        type : GET_DATA_MASTER_PRODUCTS_MANAGE,
        payload : dataFilter
    })
}


export const EditRowMasterProductManual = ( id, record ) => async ( dispatch, getState ) => {

    const dataProduct = getState().masterProducts.rex_data_form_edit_master_product_manual

    let updateData

    if(!record){
        updateData = dataProduct.filter(dat => dat.id != id)
    }else{
        const newRow = {id: id, data: {}}
        updateData = [ ...dataProduct,  newRow]
    }
    
    dispatch({
        type    : DATA_FORM_EDIT_MASTER_PRODUCT_MANUAL,
        payload : updateData
    })
}
export const EditRowMasterProduct = ( id, record ) => async ( dispatch, getState ) => {

    const dataProduct = getState().masterProducts.rex_data_form_edit_master_product

    let updateData

    if(!record){
        updateData = dataProduct.filter(dat => dat.id != id)
    }else{
        const newRow = {id: id, data: {}}
        updateData = [ ...dataProduct,  newRow]
    }
    
    dispatch({
        type    : DATA_FORM_EDIT_MASTER_PRODUCT,
        payload : updateData
    })
}
export const EditRowMasterProductMsl = ( id, record ) => async ( dispatch, getState ) => {

    const dataProduct = getState().masterProducts.rex_data_form_edit_master_product_msl

    let updateData

    if(!record){
        updateData = dataProduct.filter(dat => dat.id != id)
    }else{
        const newRow = {id: id, data: {}}
        updateData = [ ...dataProduct,  newRow]
    }
    
    dispatch({
        type    : DATA_FORM_EDIT_MASTER_PRODUCT_MSL,
        payload : updateData
    })
}

export const EditValueMasterProductManual = ( field, value, id ) => async ( dispatch, getState ) => {

    const dataProduct = getState().masterProducts.rex_data_form_edit_master_product_manual

    dataProduct.map((dat, index) => {
        if(dat.id == id){
            let valueForm = value ? value : ''
            dataProduct[index]['data'][field] = valueForm
        }
    })

    dispatch({
        type : DATA_FORM_EDIT_MASTER_PRODUCT_MANUAL,
        payload : dataProduct
    })
}
export const EditValueMasterProduct = ( field, value, id ) => async ( dispatch, getState ) => {

    const dataProduct = getState().masterProducts.rex_data_form_edit_master_product

    dataProduct.map((dat, index) => {
        if(dat.id == id){
            let valueForm = value ? value : ''
            dataProduct[index]['data'][field] = valueForm
        }
    })

    dispatch({
        type : DATA_FORM_EDIT_MASTER_PRODUCT,
        payload : dataProduct
    })
}
export const EditValueMasterProductMsl = ( field, value, id ) => async ( dispatch, getState ) => {

    const dataProduct = getState().masterProducts.rex_data_form_edit_master_product_msl

    dataProduct.map((dat, index) => {
        if(dat.id == id){
            let valueForm = value ? value : ''
            dataProduct[index]['data'][field] = valueForm
        }
    })

    dispatch({
        type : DATA_FORM_EDIT_MASTER_PRODUCT_MSL,
        payload : dataProduct
    })
}

// OBTENER FILTROS PARA LA MAESTRA DE PRODUCTOS
export const GetFiltersMasterProductsManageReducer = () => async ( dispatch, getState) => {

    const filtersTotal      = getState().filtersProducts.rex_filters_products_total.rex_filter_products

    await fetch(config.api+'manage/pe/filters-master-products',
        {
            mode:'cors',
            method: 'POST',
            headers: {
                'Accept' 	   : 'application/json',
                'Content-type' : 'application/json',
                'usutoken'      : localStorage.getItem('usutoken')
            },
            body: JSON.stringify({
                req_filter_channel  : filtersTotal.rex_txt_uniqueChannels,
                req_filter_client   : filtersTotal.rex_txt_unique_cliente,
                req_filter_zone     : filtersTotal.rex_txt_unique_zona,
                req_filter_sucursal : filtersTotal.rex_txt_unique_sucursal,
                req_fil_customer    : filtersTotal.rex_txt_customer_group,
                req_fil_gba         : filtersTotal.rex_txt_unique_gba,
                // req_date            : date
            }),
        }
    )
    .then( async res => {
        return res.json()
    })
    .then(data => {
        if(data.response){


            dispatch({
                type: GET_DATA_FILTERS_CHANNELS_MASTER_PRODUCTS_MANAGE,
                payload : data.uniqueChannels
            })

            dispatch({
                type: GET_DATA_FILTERS_ZONA_MASTER_PRODUCTS_MANAGE,
                payload : data.unique_zona
            })
            
            dispatch({
                type: GET_DATA_FILTERS_CLIENTES_MASTER_PRODUCTS_MANAGE,
                payload : data.unique_cliente
            })
            
            dispatch({
                type: GET_DATA_FILTERS_SUCURSAL_MASTER_PRODUCTS_MANAGE,
                payload : data.unique_sucursal
            })

            dispatch({
                type: GET_DATA_FILTERS_GBA_MASTER_PRODUCTS_MANAGE,
                payload : data.unique_gba
            })

            dispatch({
                type: GET_DATA_FILTERS_CUSTOMER_GROUP_MASTER_PRODUCTS_MANAGE,
                payload : data.unique_customer_group
            })

        }
    }).catch((error)=> {
        console.log(error)
    });
}

export const CambiarTxtFilterMasterProductsReducer = (type, txt_data, dateFilter, view) => async ( dispatch, getState) => {

    const filtersTotal = getState().filtersProducts.rex_filters_products_total

    if(!txt_data){
        txt_data = ""
    }

    if(type == "Customer Group"){
        type = "rex_txt_customer_group"
    }else if(type == "Canal"){
        type = "rex_txt_uniqueChannels"
    }else if(type == "Zona"){
        type = "rex_txt_unique_zona"
    }else if(type == "Cliente HML"){
        type = "rex_txt_unique_cliente"
    }else if(type == "GBA"){
        type = "rex_txt_unique_gba"
    }else{
        type = "rex_txt_unique_sucursal"
    }

    if(view == "products"){
        const valuesFilter = filtersTotal['rex_filter_products']
        filtersTotal['rex_filter_products'] = {...valuesFilter, [type] : txt_data}
        
    }else if(view == "mslProducts"){
        const valuesFilter = filtersTotal['rex_filter_mls']
        filtersTotal['rex_filter_mls'] = {...valuesFilter, [type] : txt_data}

    }else{
        const valuesFilter = filtersTotal['rex_filter_new']
        filtersTotal['rex_filter_new'] = {...valuesFilter, [type] : txt_data}
    }

    dispatch({
        type : GET_DATA_FILTERS_TXT_PRODUCTS_TOTAL,
        payload : filtersTotal
    })

    await dispatch(GetFiltersMasterProductsManageReducer())
    if(view == "products"){
        await dispatch(GetMasterProductsManageReducer(dateFilter))
    }else if(view == "mslProducts"){
        await dispatch(GetMslManageReducer(dateFilter))
    }else{
        await dispatch(GetMasterProductsManualsManageReducer(dateFilter))
    }
}


export const SaveMasterProductsReducer = (date, action) => async ( dispatch, getState ) => {
    
    const filterDate    = date ? dayjs(dayjs(date), "AAAA-MM") : dayjs(dayjs(), "AAAA-MM")
    const filterYear    = filterDate.year()
    const filterMonth   = filterDate.month() + 1

    const confirmDelete = action == "confirm-save-master-products"

    let response

    await fetch(config.api+'manage/pe/'+action,
        {
            mode:'cors',
            method: 'POST',
            headers: {
                'Accept' : 'application/json',
                'Content-type' : 'application/json',
                'usutoken'	   : localStorage.getItem("usutoken"),
            },
            body: JSON.stringify({
                req_month   : filterMonth,
                req_year    : filterYear
            }),
        }
    )
    .then(res => res.json())
    .then(data => {
        response = data.response
        if(confirmDelete || data.generate){
            notificationGlobal(data.response ? "success" : "warning", data.message)
        }
    }).catch((error) => {
        console.log(error)
    })

    return response
}