import {
    GET_DATA_FILTERS_CHANNELS_MASTER_PRODUCTS_MANAGE,
    GET_DATA_FILTERS_ZONA_MASTER_PRODUCTS_MANAGE,
    GET_DATA_FILTERS_CLIENTES_MASTER_PRODUCTS_MANAGE,
    GET_DATA_FILTERS_SUCURSAL_MASTER_PRODUCTS_MANAGE,
    GET_DATA_FILTERS_GBA_MASTER_PRODUCTS_MANAGE,
    GET_DATA_FILTERS_CUSTOMER_GROUP_MASTER_PRODUCTS_MANAGE,
    
    
    GET_DATA_FILTERS_TXT_CUSTOMER_GROUP_MASTER_PRODUCTS_MANAGE,
    GET_DATA_FILTERS_TXT_CHANNELS_MASTER_PRODUCTS_MANAGE,
    GET_DATA_FILTERS_TXT_ZONA_MASTER_PRODUCTS_MANAGE,
    GET_DATA_FILTERS_TXT_CLIENTES_MASTER_PRODUCTS_MANAGE,
    GET_DATA_FILTERS_TXT_SUCURSAL_MASTER_PRODUCTS_MANAGE,
    GET_DATA_FILTERS_TXT_GBA_MASTER_PRODUCTS_MANAGE,


    GET_DATA_FILTERS_TXT_PRODUCTS_TOTAL
} from "../../../../Constants/Manage/MasterProducts/MasterProducts";

const INIT_STATE = {

    rex_filters_products_total : {
        rex_filter_products : {
            rex_txt_uniqueChannels : "",
            rex_txt_unique_zona : "",
            rex_txt_unique_cliente : "",
            rex_txt_unique_sucursal : "",
            rex_txt_unique_gba : "",
            rex_txt_customer_group : ""
        },
        rex_filter_mls : {
            rex_txt_uniqueChannels : "",
            rex_txt_unique_zona : "",
            rex_txt_unique_cliente : "",
            rex_txt_unique_sucursal : "",
            rex_txt_unique_gba : "",        
            rex_txt_customer_group : ""
        },
        rex_filter_new : {
            rex_txt_uniqueChannels : "",
            rex_txt_unique_zona : "",
            rex_txt_unique_cliente : "",
            rex_txt_unique_sucursal : "",
            rex_txt_unique_gba : "",
            rex_txt_customer_group : ""
        }
    },

    rex_uniqueChannels : [],
    rex_unique_zona : [],
    rex_unique_cliente : [],
    rex_unique_sucursal : [],
    rex_unique_gba : [],
    rex_unique_customer_group : [],

    rex_txt_uniqueChannels : "",
    rex_txt_unique_zona : "",
    rex_txt_unique_cliente : "",
    rex_txt_unique_sucursal : "",
    rex_txt_unique_gba : "",
    rex_txt_unique_customer_group : "",
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {

        case GET_DATA_FILTERS_TXT_CUSTOMER_GROUP_MASTER_PRODUCTS_MANAGE: {
            return {
                ...state,
                rex_txt_unique_customer_group : action.payload,
            }
        }
        case GET_DATA_FILTERS_TXT_PRODUCTS_TOTAL: {
            return {
                ...state,
                rex_filters_products_total : action.payload,
            }
        }
        case GET_DATA_FILTERS_CHANNELS_MASTER_PRODUCTS_MANAGE: {
            return {
                ...state,
                rex_uniqueChannels : action.payload,
            }
        }
        case GET_DATA_FILTERS_ZONA_MASTER_PRODUCTS_MANAGE: {
            return {
                ...state,
                rex_unique_zona : action.payload,
            }
        }
        case GET_DATA_FILTERS_CLIENTES_MASTER_PRODUCTS_MANAGE: {
            return {
                ...state,
                rex_unique_cliente : action.payload,
            }
        }
        case GET_DATA_FILTERS_SUCURSAL_MASTER_PRODUCTS_MANAGE: {
            return {
                ...state,
                rex_unique_sucursal : action.payload,
            }
        }
        case GET_DATA_FILTERS_GBA_MASTER_PRODUCTS_MANAGE: {
            return {
                ...state,
                rex_unique_gba : action.payload,
            }
        }
        case GET_DATA_FILTERS_CUSTOMER_GROUP_MASTER_PRODUCTS_MANAGE: {
            return {
                ...state,
                rex_unique_customer_group : action.payload,
            }
        }


        // 

        case GET_DATA_FILTERS_TXT_CHANNELS_MASTER_PRODUCTS_MANAGE: {
            return {
                ...state,
                rex_txt_uniqueChannels : action.payload,
            }
        }
        case GET_DATA_FILTERS_TXT_ZONA_MASTER_PRODUCTS_MANAGE: {
            return {
                ...state,
                rex_txt_unique_zona : action.payload,
            }
        }
        case GET_DATA_FILTERS_TXT_CLIENTES_MASTER_PRODUCTS_MANAGE: {
            return {
                ...state,
                rex_txt_unique_cliente : action.payload,
            }
        }
        case GET_DATA_FILTERS_TXT_SUCURSAL_MASTER_PRODUCTS_MANAGE: {
            return {
                ...state,
                rex_txt_unique_sucursal : action.payload,
            }
        }
        case GET_DATA_FILTERS_TXT_GBA_MASTER_PRODUCTS_MANAGE: {
            return {
                ...state,
                rex_txt_unique_gba : action.payload,
            }
        }
        default:
            return state;
    }
}