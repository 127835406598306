import React, { useState } from 'react'

function OptionButton({fill, stroke}){
    return (

        <svg width="20" height="20" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_284_3955)">
                <path d="M12 0C9.62663 0 7.30655 0.703788 5.33316 2.02236C3.35977 3.34094 1.8217 5.21509 0.913451 7.4078C0.00519943 9.60051 -0.232441 12.0133 0.230582 14.3411C0.693605 16.6689 1.83649 18.8071 3.51472 20.4853C5.19295 22.1635 7.33115 23.3064 9.65892 23.7694C11.9867 24.2324 14.3995 23.9948 16.5922 23.0866C18.7849 22.1783 20.6591 20.6402 21.9776 18.6668C23.2962 16.6935 24 14.3734 24 12C23.9966 8.81846 22.7312 5.76821 20.4815 3.51852C18.2318 1.26883 15.1815 0.00344108 12 0V0ZM12 21C10.22 21 8.47992 20.4722 6.99987 19.4832C5.51983 18.4943 4.36628 17.0887 3.68509 15.4442C3.0039 13.7996 2.82567 11.99 3.17294 10.2442C3.5202 8.49836 4.37737 6.89471 5.63604 5.63604C6.89472 4.37737 8.49836 3.5202 10.2442 3.17293C11.99 2.82567 13.7996 3.0039 15.4442 3.68508C17.0887 4.36627 18.4943 5.51983 19.4832 6.99987C20.4722 8.47991 21 10.22 21 12C20.9974 14.3861 20.0483 16.6738 18.361 18.361C16.6738 20.0483 14.3861 20.9974 12 21Z" fill={fill}/>
            </g>
            <g clip-path="url(#clip1_284_3955)">
                <path d="M7.55324 12.1676L9.03657 13.2509L8.47324 14.9955C8.3822 15.2661 8.38105 15.5588 8.46995 15.8301C8.55886 16.1014 8.73305 16.3367 8.96657 16.5009C9.1961 16.6704 9.47424 16.7612 9.75956 16.7598C10.0449 16.7584 10.3221 16.6648 10.5499 16.493L12.0003 15.4255L13.4512 16.4918C13.6803 16.6603 13.9569 16.7518 14.2413 16.7532C14.5257 16.7546 14.8032 16.6657 15.034 16.4995C15.2647 16.3332 15.4368 16.098 15.5254 15.8278C15.6141 15.5575 15.6148 15.2662 15.5274 14.9955L14.9641 13.2509L16.4474 12.1676C16.6762 12.0003 16.8463 11.765 16.9334 11.4952C17.0205 11.2255 17.0201 10.9352 16.9323 10.6657C16.8445 10.3962 16.6738 10.1613 16.4445 9.99463C16.2152 9.82795 15.9392 9.73799 15.6557 9.73758H13.8337L13.2807 8.01425C13.1938 7.74298 13.0229 7.50634 12.7928 7.33844C12.5627 7.17055 12.2852 7.08008 12.0003 7.08008C11.7155 7.08008 11.438 7.17055 11.2078 7.33844C10.9777 7.50634 10.8069 7.74298 10.7199 8.01425L10.167 9.73758H8.34657C8.06313 9.73799 7.78707 9.82795 7.55782 9.99463C7.32856 10.1613 7.15784 10.3962 7.07004 10.6657C6.98224 10.9352 6.98185 11.2255 7.06893 11.4952C7.15601 11.765 7.3261 12.0003 7.55491 12.1676H7.55324Z" fill={fill}/>
            </g>
            <defs>
                <clipPath id="clip0_284_3955">
                    <rect width="24" height="24" fill="white"/>
                </clipPath>
                <clipPath id="clip1_284_3955">
                    <rect width="10" height="10" fill="white" transform="translate(7 7)"/>
                </clipPath>
            </defs>
        </svg>
    )
}

const StarPersonalized = ({onHover}) => {

    return (
        <div style={{display:'flex',alignItems:'center'}}>
            <OptionButton fill={onHover == true ? '#FF1CDB' : '#013A81'}/>
        </div>
    )
}

export default StarPersonalized