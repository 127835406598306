import {
    GET_DATA_CLIENTS_EXECUTIVES,
    GET_DATA_CLIENTS_EXECUTIVES_CLONE,
    DATA_CLIENT_EXECUTIVE,
    GET_DATA_MASTER_CLIENTS_EXECUTIVES,
    LOADING_DATA_CLIENTS_EXECUTIVES,
} from "../../../Constants/ClientsExecutives/ClientsExecutives"

const INIT_STATE = {
    rex_data_clients_executives          : [],
    rex_data_clients_executives_clone    : [],
    rex_data_client_executive            : {
        id: null,
        canal: '',
        ejecutivo: '',
        gerente: '',
        cod_cliente: '',
        cliente: '',
        anio: '',
        mes: '',
        mesanio: '',
    },
    rex_data_master_clients_executives   : [],
    rex_loading_data_clients_executives  : true,
}

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_DATA_CLIENTS_EXECUTIVES: {
            return {
                ...state,
                rex_data_clients_executives : action.payload
            }
        }
        case GET_DATA_CLIENTS_EXECUTIVES_CLONE: {
            return {
                ...state,
                rex_data_clients_executives_clone : action.payload
            }
        }
        case DATA_CLIENT_EXECUTIVE: {
            return {
                ...state,
                rex_data_client_executive : action.payload
            }
        }
        case GET_DATA_MASTER_CLIENTS_EXECUTIVES: {
            return {
                ...state,
                rex_data_master_clients_executives : action.payload
            }
        }
        case LOADING_DATA_CLIENTS_EXECUTIVES: {
            return {
                ...state,
                rex_loading_data_clients_executives : action.payload
            }
        }
        default:
            return state
    }
}
