import React from 'react'
import { Divider } from 'antd'
import '../../Styles/Components/Global/DividerGlobal.css'

const DividerGlobal = ({ orientation, text }) => {
    return (
        <Divider 
            orientation={orientation}
            className="Divider-Global"
        >
            {text}
        </Divider>
    )
}

export default DividerGlobal