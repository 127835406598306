import {
    GET_DATA_MODULES_HOME,
    REGISTER_USERS_CONNECTED_MODULES_HOME,
    MENU_HOME_CATEGORY,
} from "../../../Constants/Home/Home"

const INIT_STATE = {
    rex_data_modules_home : [{modulos:[]}],
    rex_users_home_connected : [],
    rex_menu_home_category : 'home',
}

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_DATA_MODULES_HOME: {
            return {
                ...state,
                rex_data_modules_home : action.payload,
            }
        }
        case REGISTER_USERS_CONNECTED_MODULES_HOME: {
            return {
                ...state,
                rex_users_home_connected : action.payload,
            }
        }
        case MENU_HOME_CATEGORY: {
            return {
                ...state,
                rex_menu_home_category : action.payload,
            }
        }
        default:
            return state;
    }
}
