import React, {useState} from 'react'
import { Button, Card, Avatar, Dropdown, Typography } from 'antd'
import { EllipsisOutlined, DeleteOutlined } from '@ant-design/icons'
import { DeleteItemNotificationReducer, ShowNotificationModalFileUploadReducer, ViewNotificationStatusFileUploadReducer } from '../../Redux/Actions/FileUpload/FileUpload'
import { useDispatch } from 'react-redux'
import DeleteNotification from '../../Assets/Images/Modules/DeleteSkyBlue.png'

const ItemNotificationFile = (props) => {

    const { titulo, mensaje, date, respuesta } = props.item
    const [ seeMore, setSeeMore ] = useState(false)
    const [ efecctRemoveCard, setEfecctRemoveCard ] = useState(false)
    const [ keyRemove, setKeyRemove ] = useState(null)

    const { Paragraph } = Typography

    const dispatch = useDispatch()

    const deleteCard = (key) => {
        setEfecctRemoveCard(true)
        setTimeout(() => {
            dispatch(DeleteItemNotificationReducer(key))
            setEfecctRemoveCard(false)
        }, 1000)
    }

    const items = [
        {
            key: props.index,
            label:  <span 
                        onClick={() => {
                            setKeyRemove(props.index)
                            deleteCard(props.item)
                        }} className="Delete-Notification-File"
                    >
                        Eliminar notificación 
                        <img src={DeleteNotification} width={14} height={14} />
                    </span>,
        },
    ]

    return(
        <>
        {
            <Card 
                className={`Card-Item-Notification-File ${!props.item.abierta && "Unread-Notification"} ${efecctRemoveCard && keyRemove == props.index ? "Card-Effect-Remove" : ""} ${!respuesta && "Container-Error-Notification"}`}
            >
                {
                    !seeMore
                    ?   <>
                            {props.item.data.length > 0
                                ? <Paragraph
                                    ellipsis={{
                                        rows : 3,
                                        expandable: false
                                    }}
                                >
                                    {
                                        props.item.data.map((dat, pos) => {
                                            return (
                                                <div className='Notifications-Colums'>
                                                    <ul>
                                                        <div style={{display:"flex", flexDirection:"row"}}>
                                                            <div style={{width:"30px"}}><span>{pos+1}.</span></div>
                                                            <div><p style={{lineHeight:'15px', marginBottom:"0"}}>Se encontraron las siguientes observaciones en la columna <b>{dat.columna}:</b></p></div>
                                                        </div>
                                                    {
                                                        dat.notificaciones.map(det => {
                                                            return (
                                                                det.rows
                                                                ?
                                                                    <div className='Detalle-Notificacion-Upload'>
                                                                        <li style={{marginLeft:"30px"}}>
                                                                            <p style={{lineHeight:'15px'}}>
                                                                            {
                                                                                det.msg
                                                                            // }, en las siguientes lineas: {
                                                                            }
                                                                            {dat.show_lines == true
                                                                                ? ", en las siguientes lineas: "
                                                                                : null
                                                                            }
                                                                            {
                                                                                dat.show_lines == true 
                                                                                ? det.rows.map((row, pos) => {
                                                                                    return (
                                                                                        <b>
                                                                                            {
                                                                                                pos >= 6
                                                                                                ?pos == 6
                                                                                                    ?<span>etc</span>
                                                                                                    :null
                                                                                                :<span>{row}, </span>
                                                                                            }
                                                                                        </b>
                                                                                    )
                                                                                })
                                                                                : null
                                                                            }
                                                                            </p>
                                                                        </li>
                                                                    </div>
                                                                : <div className='Detalle-Notificacion-Upload'>{det.msg}</div>
                                                            )
                                                        })
                                                    }
                                                    </ul>
                                                </div>
        
                                            )    
                                        })
                                    }    
                                </Paragraph>
                                :
                                <p className={ respuesta == true ?"Title-Correcto-Upload-Message" :"Title-Errors-Message"}>{mensaje}</p>
                            }
                        </>
                    :   <>
                            {props.item.data.length > 0
                                ? props.item.data.map((dat, pos) => {
                                    return (
                                        <div className='Notifications-Colums'>
                                            <>{pos+1}) Se encontraron las siguientes observaciones en la columna: <b>{dat.columna}</b></>
                                            
                                            {
                                                dat.notificaciones.map(det => {
                                                    return (
                                                        det.rows
                                                        ? <div className='Detalle-Notificacion-Upload'>
                                                                <div
                                                                    style={{
                                                                        paddingLeft:'20px'
                                                                    }}
                                                                >
                                                                {
                                                                    "- "+det.msg
                                                                }
                                                                {dat.show_lines == true
                                                                    ? ", en las siguientes lineas: "
                                                                    : null
                                                                }
                                                                {
                                                                    dat.show_lines == true 
                                                                    ?det.rows.map((row, pos) => {
                                                                        return (
                                                                            <b>
                                                                                {
                                                                                    pos >= 6
                                                                                    ?pos == 6
                                                                                        ?<span>etc</span>
                                                                                        :null
                                                                                    :<span>{row}, </span>
                                                                                }
                                                                            </b>
                                                                        )
                                                                    })
                                                                    : null
                                                                }
                                                                </div>
                                                            </div>
                                                        : <div className='Detalle-Notificacion-Upload'>{det.msg}</div>
                                                    )
                                                })
                                            }

                                        </div>

                                    )    
                                })
                                :
                                <p className={ respuesta == true ?"Title-Correcto-Upload-Message" :"Title-Errors-Message"}>{mensaje}</p>
                            }

                        </>
                }
                
                <div className="Container-Footer-Item-Notification">
                    {
                        props.item.data.length > 0
                        ?  <span 
                                onClick={() => {
                                    if(!props.item.abierta){
                                        dispatch(ViewNotificationStatusFileUploadReducer(props.item))
                                    }
                                    dispatch(ShowNotificationModalFileUploadReducer(true,props.item))
                                }} 
                                className="See-Notification-File"
                            >
                                {
                                    seeMore
                                        ?   "ver menos"
                                        :   "ver más"
                                }
                            </span>
                        : <div></div>
                    }
                    <span className="Container-Date-Notification-Upload-File">{props.item.fecha}</span>
                </div>
                <Dropdown
                    menu={{items}} 
                    placement="bottomRight"
                    overlayClassName="Menu-Notification-File"
                    trigger={['click']}
                >
                    <Button className="Button-Deploy-Notification-File">
                        <EllipsisOutlined 
                            rotate={90} 
                            className="Icon-Deploy-Notification-File" 
                        />
                    </Button>
                </Dropdown>
                <small className="Date-Notification-File">{date}</small>
            </Card>
        }
        </>
    )
}

export default ItemNotificationFile