import React, { useState, useEffect } from 'react'
import TitleSearchGlobal from '../../Components/Global/TitleSearchGlobal'
import { Space, Input, Popover, Modal, Table, Tooltip } from 'antd'
import TableGlobal from '../../Components/Global/TableGlobal'
import { 
    EyeTwoTone,
    FilterOutlined,
    ExclamationCircleFilled
} from '@ant-design/icons'
import TrashButtonGlobalSimpleColor from '../../Assets/Images/Global/TrashButtonGlobalSimpleColor'
import EditOptionButtonGlobal from '../../Assets/Images/Global/EditOptionButtonGlobal'
import '../../Styles/Routes/ChannelsExecutives/ChannelsExecutives.css'
import PopOverChannelsExecutives from '../../Components/ChannelsExecutives/PopOverChannelsExecutives'
import FilterSimpleSelectGlobal from '../../Components/Global/FilterSimpleSelectGlobal'
import { useDispatch, useSelector } from 'react-redux'
import { CambiarTxtFilterChannelsExecutivesReducer, DeleteChannelExecutiveReducer, GetDataChannelsExecutivesReducer, GetFiltersChannelsExecutivesReducer, SaveChannelsExecutivesReducer, SetDateFilterChannelsExecutivesReducer } from '../../Redux/Actions/ChannelsExecutives/ChannelsExecutives'
import { useNavigate} from "react-router-dom"
import moment from 'moment'
import dayjs from 'dayjs'
import SideMenu from '../../Components/Global/SideMenu'

const ChannelsExecutives = () => {

    const [ pageTableGlobal, setPageTableGlobal ] = useState(1)
    const [ pageSizeTableGlobal, setPageSizeTableGlobal ] = useState(20)
    const [ selectedRowKeys, setSelectedRowKeys ] = useState([])
    const [ valueCheckbox, setValueCheckbox ]     = useState(false)

    const dispatch = useDispatch()
    const { confirm } = Modal
    let navigate = useNavigate()
    

    const getColumnSearchProps = (dataIndex, nameColumn) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
            <div
                style={{
                    padding: 8,
                }}
                onKeyDown={(e) => e.stopPropagation()}
            >
                <Input.Search
                    size='small'
                    placeholder={`Filtrar ${nameColumn}`}
                    value={selectedKeys[0]}
                    onChange={(e) => {
                        setSelectedKeys(e.target.value ? [e.target.value] : [])
                        confirm({
                            closeDropdown: false,
                        })
                    }}
                    style={{
                        display: 'block',
                    }}
                />
            </div>
        ),
        filterIcon: () => (<FilterOutlined/>),
        onFilter: (value, record) => {
            return record[dataIndex].toString().toLowerCase().includes(value.toString().toLowerCase())
        }
    })

    const content = (
        <div>
          <p>Sin Clientes</p>
        </div>
    )

    const contentClients = (data) => {
        return <PopOverChannelsExecutives
                data={data}
                />
    }

    const deleteChannelsExecutives = (ids, resetValues) => {

        if(ids.length > 0){
            confirm({
                title   : `¿Está seguro que desea eliminar ${ids.length > 1 ? 'los Comercial teams': 'el Comercial Team'}?`,
                icon    : <ExclamationCircleFilled/>,
                content : 'Esta acción es irreversible',
                zIndex  : 100000000,
                className:"Confirm-Action-Channel-Executive",
                async onOk(){
                    const { response, alertDelete } = await dispatch(DeleteChannelExecutiveReducer(ids))
                    if(response && !alertDelete){
                        setValueCheckbox(false)
                        resetValues([])
                    }
                },
                okButtonProps: {
                    size:"small"
                },
                cancelButtonProps : {
                    size:"small"
                },
                okCancel(){}
            })
        }
    }

    const confirmDelete = async (id) => {
        let response
        confirm({
            title   : "Eliminar Comercial Team",
            icon    : <ExclamationCircleFilled/>,
            content : "¿Está seguro que desea eliminar el Comercial Team?",
            zIndex  : 100000000,
            className:"Confirm-Action-Channel-Executive",
            async onOk(){
                response = await dispatch(DeleteChannelExecutiveReducer(id))
            },
            okButtonProps: {
                size:"small",
            },
            cancelButtonProps : {
                size:"small",
                danger:true
            },
            centered : true,
            okCancel(){},
        })
    }

    const columns = [
        {
            title: 'Item',
            dataIndex: 'item',
            align: 'center',
            render: (text, record, index) => (pageTableGlobal - 1) * pageSizeTableGlobal + index + 1,
            width : '30px',
            fixed : "left"
        },
        Table.SELECTION_COLUMN,
        {
            title: 'REGION',
            dataIndex: 'regional',
            align: 'center',
            ...getColumnSearchProps('regional', 'region'),
            sorter: (a, b) => a.regional ? a.regional.localeCompare(b.regional) : false,
            showSorterTooltip: false,
            render:(_, record) => (
                <div className='Table-Contain-Text-Global-Left'>
                    {record.regional}
                </div>
            ),
            width: 130
        },
        {
            title: 'REGIONAL MANAGER',
            dataIndex: 'regional_manager',
            align: 'center',
            ...getColumnSearchProps('regional_manager', 'regional manager'),
            sorter: (a, b) => a.regional_manager ? a.regional_manager.localeCompare(b.regional_manager) : false,
            showSorterTooltip: false,
            render:(_, record) => (
                <div className='Table-Contain-Text-Global-Left'>
                    {record.regional_manager}
                </div>
            ),
            ellipsis: true,
            width: 130
        },
        {
            title: 'CANAL',
            dataIndex: 'channel',
            align: 'channel',
            ...getColumnSearchProps('channel', 'channel'),
            sorter: (a, b) => a.channel ? a.channel.localeCompare(b.channel) : false,
            showSorterTooltip: false,
            render:(_, record) => (
                <div className='Table-Contain-Text-Global-Left'>
                    {record.channel}
                </div>
            ),
            ellipsis: true,
            width: 130
        },
        {
            title: 'CHANNEL MANAGER',
            dataIndex: 'channel_manager',
            align: 'channel_manager',
            ...getColumnSearchProps('channel_manager', 'channel manager'),
            sorter: (a, b) => a.channel_manager ? a.channel_manager.localeCompare(b.channel_manager) : false,
            showSorterTooltip: false,
            render:(_, record) => (
                <div className='Table-Contain-Text-Global-Left'>
                    {record.channel_manager}
                </div>
            ),
            ellipsis: true,
            width: 130
        },
        {
            title: 'ZONA',
            dataIndex: 'zone',
            align: 'center',
            ...getColumnSearchProps('zone', 'zone'),
            sorter: (a, b) => a.zone ? a.zone.localeCompare(b.zone) : false,
            showSorterTooltip: false,
            render:(_, record) => (
                <div className='Table-Contain-Text-Global-Left'>
                    {record.zone}
                </div>
            ),
            ellipsis: true,
            width: 130
        },
        {
            title: 'ZONE MANAGER',
            dataIndex: 'zone_manager',
            align: 'center',
            ...getColumnSearchProps('zone_manager', 'zone manager'),
            sorter: (a, b) => a.zone_manager ? a.zone_manager.localeCompare(b.zone_manager) : false,
            showSorterTooltip: false,
            render:(_, record) => (
                <div className='Table-Contain-Text-Global-Left'>
                    {record.zone_manager}
                </div>
            ),
            ellipsis: true,
            width: 130
        },
        // {
        //     title: 'INCENTIVOS',
        //     dataIndex: 'zone_manager',
        //     align: 'center',
        //     ...getColumnSearchProps('zone_manager', 'zone manager'),
        //     sorter: (a, b) => a.zone_manager ? a.zone_manager.localeCompare(b.zone_manager) : false,
        //     showSorterTooltip: false,
        //     render:(_, record) => (
        //         <div className='Table-Contain-Text-Global-Left'>
                    
        //         </div>
        //     ),
        //     ellipsis: true,
        //     width: 130
        // },
        {
            title: 'SI EXECUTIVE',
            dataIndex: 'si_executive',
            align: 'center',
            ...getColumnSearchProps('si_executive', 'si_executive'),
            sorter: (a, b) => a.si_executive ? a.si_executive.localeCompare(b.si_executive) : false,
            showSorterTooltip: false,
            render:(_, record) => (
                <div className='Table-Contain-Text-Global-Left'>
                    {record.si_executive}
                </div>
            ),
            ellipsis: true,
            width: 130
        },
        {
            title: 'SO EXECUTOR',
            dataIndex: 'so_executor',
            align: 'center',
            ...getColumnSearchProps('so_executor', 'so_executor'),
            sorter: (a, b) => a.so_executor ? a.so_executor.localeCompare(b.so_executor) : false,
            showSorterTooltip: false,
            render:(_, record) => (
                <div className='Table-Contain-Text-Global-Left'>
                    {record.so_executor}
                </div>
            ),
            ellipsis: true,
            width: 130
        },
        {
            title: 'FECHA ACTUALIZACIÓN',
            dataIndex: 'updated_at',
            align: 'center',
            ...getColumnSearchProps('updated_at', 'fecha actualización'),
            sorter: (a, b) => a.updated_at ? a.updated_at.localeCompare(b.updated_at) : false,
            showSorterTooltip: false,
            render:(_, record) => (
                <div className='Table-Contain-Text-Global-Left'>
                    {moment(record.updated_at).format('DD/MM/YYYY h:mm a')}
                </div>
            ),
            ellipsis: true,
            width: 130
        },
        {
            title: 'CANTIDAD DE DATA',
            dataIndex: 'numberClients',
            align: 'center',
            ...getColumnSearchProps('numberClients', 'número de clientes'),
            sorter: (a, b) => a.clients.length ? a.clients.length - b.clients.length : false,
            showSorterTooltip: false,
            render:(_, record) => (
                <div className='Table-Contain-Text-Global-Center'>
                    <div className="Container-Box-Number-Clients">
                        <span>{record.clients.length}</span>
                        <Popover 
                            overlayClassName="PopOver-Box-Clients"
                            content={record.clients.length > 0 ? contentClients(record.clients): content}
                            title="" 
                            trigger="hover"
                            placement="left"
                        >
                            <EyeTwoTone style={{fontSize:"16px"}}/>
                        </Popover>
                    </div>
                </div>
            ),
            fixed:"right",
            ellipsis: true,
            width: 130
        },
        {
            title: 'Acciones',
            dataIndex: 'actions',
            align: 'center',
            render:(_, record) => (
                <div className='Table-Contain-Text-Global-Center'>
                    <Space>
                        <Tooltip
                            placement='left'
                            title="Editar"
                            color='#3B72FF'
                        >
                            <div
                                onClick={() => {
                                    navigate(`/edit-channels-executives/${record.id_ej_cl}`)
                                }}
                                style={{display:"flex", alignItems:"center"}} 
                            >
                                <EditOptionButtonGlobal/>
                            </div>
                        </Tooltip>
                        <Tooltip
                            placement='right'
                            title="Eliminar"
                            color='#3B72FF'
                        >
                            <div 
                                style={{display:"flex", alignItems:"center"}} 
                                onClick={() => confirmDelete([record.id_ej_cl])}
                            >
                                <TrashButtonGlobalSimpleColor/>
                            </div>
                        </Tooltip>
                    </Space>
                </div>
            ),
            fixed:"right",
            ellipsis: true,
            width: 80
        },
    ]
    
    const { 
        rex_loading_channels_executives,
        rex_data_channels_executives,
        rex_data_update_channels_executives,
        rex_date_filter_channels_executives,
        rex_filters_items_gba,
        rex_filters_items_channel,
        rex_filters_items_zone,
        rex_filters_items_client,
        rex_filters_items_customer_group,
        rex_filters_channels_executive_program_total
    } = useSelector(({channelsExecutives}) => channelsExecutives)

    const {
        rex_txt_uniqueChannels,
        rex_txt_unique_zona,
        rex_txt_unique_sucursal,
        rex_txt_unique_customer_group,
        rex_txt_unique_gba,
    } = rex_filters_channels_executive_program_total.rex_filter_channels_executive_program

    const { 
        rex_users_conected_dashboard,
    } = useSelector(({registerUsers}) => registerUsers)

    const { 
        rex_data_users_online
    } = useSelector(({users}) => users)

    const validateDateDisable = (date) => {

        if(date == "" || typeof(date) == "object"){
            return false
        }
        const currentDate = dayjs().format("YYYY-MM")
        return currentDate == date ? false : true            
    }

    useEffect(() => {
        dispatch(GetDataChannelsExecutivesReducer())
        dispatch(GetFiltersChannelsExecutivesReducer())
    },[])
    
    useEffect(()=> {

    },[rex_data_channels_executives])

    const filterChannelComponent = () => {
        return (
            <div style={{display:"flex", justifyContent:"space-between", alignItems:"center" ,columnGap:"5px"}}>
                <FilterSimpleSelectGlobal
                    typeFilter="Customer Group"
                    list_data = {rex_filters_items_customer_group}
                    value={rex_txt_unique_customer_group == "" ? null : rex_txt_unique_customer_group}
                    view="channelsExecutive"
                    onAction={CambiarTxtFilterChannelsExecutivesReducer}
                    dateFilter={rex_date_filter_channels_executives}
                />
                <FilterSimpleSelectGlobal
                    typeFilter="GBA"
                    list_data = {rex_filters_items_gba}
                    value={rex_txt_unique_gba == "" ? null : rex_txt_unique_gba}
                    view="channelsExecutive"
                    onAction={CambiarTxtFilterChannelsExecutivesReducer}
                    dateFilter={rex_date_filter_channels_executives}
                />
                <FilterSimpleSelectGlobal
                    typeFilter="Canal"
                    value={rex_txt_uniqueChannels == "" ? null : rex_txt_uniqueChannels}
                    list_data = {rex_filters_items_channel}
                    view="channelsExecutive"
                    onAction={CambiarTxtFilterChannelsExecutivesReducer}
                    dateFilter={rex_date_filter_channels_executives}
                />
                <FilterSimpleSelectGlobal
                    typeFilter="Zona"
                    value={rex_txt_unique_zona == "" ? null : rex_txt_unique_zona}
                    list_data = {rex_filters_items_zone}
                    view="channelsExecutive"
                    onAction={CambiarTxtFilterChannelsExecutivesReducer}
                    dateFilter={rex_date_filter_channels_executives}
                />
                <FilterSimpleSelectGlobal
                    typeFilter="Cliente HML"
                    value={rex_txt_unique_sucursal == "" ? null : rex_txt_unique_sucursal}
                    list_data = {rex_filters_items_client}
                    view="channelsExecutive"
                    onAction={CambiarTxtFilterChannelsExecutivesReducer}
                    dateFilter={rex_date_filter_channels_executives}
                />
            </div>
        )
    }

    const saveChannelsExecutives = async () => {
        const response = await dispatch(SaveChannelsExecutivesReducer(rex_date_filter_channels_executives, "save-channels-executives"))
        if(!response){
            confirm({
                title   : "Guardar Comercial Team",
                icon    : <ExclamationCircleFilled/>,
                content : "Existen el Comercial Team para el mes siguiente, ¿Desea reemplazar la información?",
                zIndex  : 100000000,
                async onOk(){
                    const response = await dispatch(SaveChannelsExecutivesReducer(rex_date_filter_channels_executives, "confirm-save-channels-executives"))
                },
                okCancel(){}
            })   
        }
    }

    return (
        <div className="Container-Main Container-Channels-Executives">
            <TitleSearchGlobal
                title='Comercial Team'
                subTitle={moment(rex_data_update_channels_executives).format('D [de] MMMM [del] YYYY h:mm a')}
                showInputSearch={false}
            />
            <TableGlobal
                columns={columns}
                data={rex_data_channels_executives}
                loading_data={rex_loading_channels_executives}
                buttonSaveProductsDisable={validateDateDisable(rex_date_filter_channels_executives)}
                pageTableGlobal={pageTableGlobal}
                pageSizeTableGlobal={pageSizeTableGlobal}
                valueCheckbox={valueCheckbox}
                setValueCheckbox={setValueCheckbox}
                showDownloadProducts={true}
                downloadFileModule={true}
                dataDownload={{route : "channels-executives", nameFile : "Canales Ejecutivos"}}
                selectedRowKeys={selectedRowKeys}
                setSelectedRowKeys={setSelectedRowKeys}
                setPageTableGlobal={setPageTableGlobal}
                setPageSizeTableGlobal={setPageSizeTableGlobal}
                componentFilterExtra={filterChannelComponent()}
                showFilterDate={true}
                actionButtonSave={() => saveChannelsExecutives(rex_date_filter_channels_executives)}
                pathNewButton="/create-channels-executives"
                selectRows={true}
                showSaveProducts={true}
                showTrash={true}
                showNewButton={true}
                showTrashRight={false}
                showDownload={false}
                selectRowsTable={true}
                actionOnRow={() => {}}
                date={rex_date_filter_channels_executives}
                setDate={(dateString) => dispatch(SetDateFilterChannelsExecutivesReducer(dateString))}
                functionDelete={(values, resetValues) => deleteChannelsExecutives(values, resetValues)}
                functionFilterDate = {async (date, dateString) => {
                    setValueCheckbox(false)
                    setSelectedRowKeys([])
                    await dispatch(SetDateFilterChannelsExecutivesReducer(dateString))
                    await dispatch(GetDataChannelsExecutivesReducer())
                }}
            />

            <SideMenu
                usersCurrentView  = {rex_users_conected_dashboard}
                usersAllConnected ={rex_data_users_online}
            />
        </div>
    )
}

export default ChannelsExecutives