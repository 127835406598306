import React, {useState} from "react"
import {useDispatch, useSelector} from "react-redux"
import {Row, Col, Card, Table, Tag, Spin, Tooltip} from 'antd'
import {
    LoadingOutlined,
} from '@ant-design/icons'
import {
    FiltroBotonDataReducer,
    FiltroTableroDinamicoDataReducer
} from '../../../Redux/Actions/PowerBi/PowerBiFiltros'
import { ExpandirReducirTablaPowerBiReducer, ExpandirTablaPowerBiReducer, ReducirTablaPowerBiReducer } from "../../../Redux/Actions/PowerBi/PowerBi"
import Equis from '../../../Assets/Images/PowerBi/Equis.png'

import SemaforoGreen from '../../../Assets/Gifs/KC/BO/BI/velovelogreen_gif.gif'
import SemaforoRed from '../../../Assets/Gifs/KC/BO/BI/redvelovelo.gif'
import SemaforoYellow from '../../../Assets/Gifs/KC/BO/BI/veloveloyellow_gif.gif'

import Expandir from '../../../Assets/Images/PowerBi/Expandir.png'
import Reducir from '../../../Assets/Images/PowerBi/Reducir.png'
import SoundOption from '../../../Assets/Audio/sonidoOption.mp3'

const TablaPowerBi = () => {
    const dispatch = useDispatch()

    const {
        rex_lista_data_dashboard,
        rex_lista_total_data_dashboard,
        rex_cargando_lista,
        rex_cargando_lista_derecha,
        rex_default_expanded_data,
        rex_tabla_expanded_data,
        rex_total_expanded_data,
    } = useSelector(({powerBi}) => powerBi)

    const {
        rex_botones_filtro,
    } = useSelector(({filtros}) => filtros)

    const [filtroCss, setFiltroCss] = useState([])
    const { Column, ColumnGroup } = Table

    let audio = new Audio(SoundOption)

    const reproduceSound = () => {
        audio.play();
    }

    const filtroDisenioCss = (order) => {
        const resultadoFiltro = filtroCss.find(f => f == order)
        setFiltroCss(
            resultadoFiltro
            ?   [...filtroCss]
            :   [...filtroCss, order]
        )
    }

    const quitarFiltroDisenioCss = (order) => {
        const resultado = filtroCss.filter(f => f !== order)
        setFiltroCss(resultado)
    }

    return(
        <Row className="filaTabla">
            <Col span={24}>
                <Spin 
                    spinning={rex_cargando_lista} 
                    indicator={<LoadingOutlined />} 
                    className={`iconoCargandoDerecha ${rex_cargando_lista_derecha ? 'iconoDerecha' : ''}`}
                    size="large"
                >
                <Card 
                    type="inner" 
                    title="Tablero Dinámico" 
                    className="tarjetaTablaContenedor"
                    >
                    {
                        rex_botones_filtro.map(tag => 
                            <>
                            <Tag 
                                color="#3b72ff" 
                                className="etiquetaTabla"
                                style={{
                                    display: tag.estado ? 'inline-block' : 'none'
                                }}
                                closable
                                onClose={(e) => {
                                    dispatch(FiltroBotonDataReducer(tag.nombre, false))
                                    dispatch(FiltroTableroDinamicoDataReducer())
                                    reproduceSound()
                                }}
                                closeIcon={<img style={{marginBottom: '2px', width: '15px'}} src={Equis} />}
                            >
                                {tag.nombre}
                            </Tag><br style={{display: tag.estado ? 'inline' : 'none'}}/>
                            </>
                        )
                    }
                </Card>
                <Table 
                    className="tablaDatos"
                    dataSource={rex_lista_data_dashboard}
                    expandable={{
                        defaultExpandedRowKeys: [0,1],
                        onExpand: (expanded, record) => dispatch(ExpandirReducirTablaPowerBiReducer(expanded, record)),
                        expandedRowKeys: rex_tabla_expanded_data,
                    }}
                    loading={false}
                    scroll={{
                        y: 265,
                        x: 'none',
                    }}
                    summary={() => (
                        <Table.Summary fixed>
                            <Table.Summary.Row className="tablaDatosFooter">
                                <Table.Summary.Cell colSpan={1} index={0}>
                                    {rex_lista_total_data_dashboard.map(t => t.zona)}
                                </Table.Summary.Cell>
                                <Table.Summary.Cell colSpan={1} index={1}>
                                    {rex_lista_total_data_dashboard.map(t => {
                                        let formatoNumero = Intl.NumberFormat('de-DE').format(t.sellIn)
                                        return <>{formatoNumero}</>
                                    })}
                                </Table.Summary.Cell>
                                <Table.Summary.Cell colSpan={1} index={2}>
                                    {rex_lista_total_data_dashboard.map(t => {
                                        let formatoNumero = Intl.NumberFormat('de-DE').format(t.sellOut)
                                        return <>{formatoNumero}</>
                                    })}
                                </Table.Summary.Cell>
                                <Table.Summary.Cell colSpan={1} index={3}>
                                    {
                                        rex_lista_total_data_dashboard.map(t => 
                                            <div className="celdaDatosFooter">
                                                <img 
                                                    className="celdaIconoSemaforo" 
                                                    src={
                                                        t.sellOutLMSP < 0
                                                            ? SemaforoRed
                                                            : t.sellOutLMSP == 0
                                                                ? SemaforoYellow
                                                                : SemaforoGreen
                                                    }
                                                />
                                                <span>{t.sellOutLMSP}%</span>
                                            </div>
                                        )
                                    }
                                </Table.Summary.Cell>
                                <Table.Summary.Cell colSpan={1} index={4}>
                                    {
                                        rex_lista_total_data_dashboard.map(t => 
                                            <div className="celdaDatosFooter">
                                                <img 
                                                    className="celdaIconoSemaforo" 
                                                    src={
                                                        t.sellOutLYSP < 0
                                                            ? SemaforoRed
                                                            : t.sellOutLYSP == 0
                                                                ? SemaforoYellow
                                                                : SemaforoGreen
                                                    }
                                                />
                                                <span>{t.sellOutLYSP}%</span>
                                            </div>
                                        )
                                    }
                                </Table.Summary.Cell>
                                <Table.Summary.Cell colSpan={1} index={5}>
                                    {
                                        rex_lista_total_data_dashboard.map(t => 
                                            <div className="celdaDatosFooter">
                                                <img 
                                                    className="celdaIconoSemaforo" 
                                                    src={
                                                        t.sellOutPL4MSP < 0
                                                            ? SemaforoRed
                                                            : t.sellOutPL4MSP == 0
                                                                ? SemaforoYellow
                                                                : SemaforoGreen
                                                    }
                                                />
                                                <span>{t.sellOutPL4MSP}%</span>
                                            </div>
                                        )
                                    }
                                </Table.Summary.Cell>
                                <Table.Summary.Cell colSpan={1} index={6}>
                                    {rex_lista_total_data_dashboard.map(t => {
                                        let formatoNumero = Intl.NumberFormat('de-DE').format(t.proySellOut)
                                        return <>{formatoNumero}</>
                                    })}
                                </Table.Summary.Cell>
                                <Table.Summary.Cell colSpan={1} index={7}>
                                    {
                                        rex_lista_total_data_dashboard.map(t => 
                                            <div className="celdaDatosFooter">
                                                <img 
                                                    className="celdaIconoSemaforo" 
                                                    src={
                                                        t.sellOutLM < 0
                                                            ? SemaforoRed
                                                            : t.sellOutLM == 0
                                                                ? SemaforoYellow
                                                                : SemaforoGreen
                                                    }
                                                />
                                                <span>{t.sellOutLM}%</span>
                                            </div>
                                        )
                                    }
                                </Table.Summary.Cell>
                                <Table.Summary.Cell colSpan={1} index={8}>
                                    {
                                        rex_lista_total_data_dashboard.map(t => 
                                            <div className="celdaDatosFooter">
                                                <img 
                                                    className="celdaIconoSemaforo"
                                                    src={
                                                        t.sellOutLY < 0
                                                            ? SemaforoRed
                                                            : t.sellOutLY == 0
                                                                ? SemaforoYellow
                                                                : SemaforoGreen
                                                    }
                                                />
                                                <span>{t.sellOutLY}%</span>
                                            </div>
                                        )
                                    }
                                </Table.Summary.Cell>
                                <Table.Summary.Cell colSpan={1} index={9}>
                                    {
                                        rex_lista_total_data_dashboard.map(t => 
                                            <div className="celdaDatosFooter">
                                                <img 
                                                    className="celdaIconoSemaforo"
                                                    src={
                                                        t.sellOutPL4M < 0
                                                            ? SemaforoRed
                                                            : t.sellOutPL4M == 0
                                                                ? SemaforoYellow
                                                                : SemaforoGreen
                                                    }
                                                />
                                                <span>{t.sellOutPL4M}%</span>
                                            </div>
                                        )
                                    }
                                </Table.Summary.Cell>
                                {/* <Table.Summary.Cell colSpan={1} index={10}>843</Table.Summary.Cell>
                                <Table.Summary.Cell colSpan={1} index={11}>21</Table.Summary.Cell>
                                <Table.Summary.Cell colSpan={1} index={12}>80%</Table.Summary.Cell>
                                <Table.Summary.Cell colSpan={1} index={13}>69%</Table.Summary.Cell>
                                <Table.Summary.Cell colSpan={1} index={14}>63%</Table.Summary.Cell> */}
                            </Table.Summary.Row>
                        </Table.Summary>
                    )}
                >
                    <Column 
                        className={
                            filtroCss.find(f => f == "zonaTablaascend" ? "zonaTablaascend" : "")
                        }
                        title={<span className="tituloPrincipalTabla">Zona</span>}
                        dataIndex="zona" 
                        key="zona"
                        width={130}
                        sorter={(a, b, c) => {
                            if(c == "ascend"){
                                filtroDisenioCss("zonaTabla"+c)
                            }else{
                                quitarFiltroDisenioCss("zonaTablaascend")
                            }
                            return a.zona.localeCompare(b.zona)
                        }}
                        showSorterTooltip={false}
                        sortDirections={["ascend","descend","ascend"]}
                        render={(zona) => <span title={zona}>{zona}</span>}
                    />
                    <Column 
                        className={
                            filtroCss.find(f => f == "sellInTablaascend" ? "sellInTablaascend" : "")
                        }
                        title={<span className="tituloPrincipalTabla">Sell In</span>}
                        dataIndex="sellIn" 
                        width={80}
                        key="sellIn"
                        sorter={(a, b, c) => {
                            if(c == "ascend"){
                                filtroDisenioCss("sellInTabla"+c)
                            }else{
                                quitarFiltroDisenioCss("sellInTablaascend")
                            }
                            return a.sellIn - b.sellIn
                        }}
                        showSorterTooltip={false}
                        sortDirections={["ascend","descend","ascend"]}
                        defaultSortOrder="descend"
                        render={(sellIn) => {
                            let formatoNumero = Intl.NumberFormat('de-DE').format(sellIn)
                            return <>{formatoNumero}</>
                        }}
                    />
                    <ColumnGroup 
                        title={
                            <>Sell Out 
                                <div className="iconosTablaReducirExpandir">
                                    <Tooltip title="Reducir">
                                        <img 
                                            onClick={() => {
                                                reproduceSound()
                                                dispatch(ReducirTablaPowerBiReducer())
                                            }}
                                            src={Reducir} 
                                            className="iconoTablaReducir" 
                                            style={
                                                rex_tabla_expanded_data.length == 0 
                                                ? {pointerEvents: 'none'}
                                                : {}
                                            }
                                        />
                                    </Tooltip>
                                    <Tooltip title="Expandir">
                                        <img 
                                            onClick={() => {
                                                reproduceSound()
                                                dispatch(ExpandirTablaPowerBiReducer())
                                            }}
                                            src={Expandir} 
                                            className="iconoTablaExpandir" 
                                            style={
                                                rex_tabla_expanded_data.length == rex_total_expanded_data.length
                                                ? {pointerEvents: 'none'}
                                                : {}
                                            }
                                        />
                                    </Tooltip>
                                </div>
                            </>
                        }
                    >
                        <Column 
                            className={
                                filtroCss.find(f => f == "sellOutTablaascend" ? "sellOutTablaascend" : "")
                            }
                            title="SO"
                            dataIndex="sellOut" 
                            key="sellOut" 
                            width={100}
                            showSorterTooltip={false}
                            sortDirections={["ascend","descend","ascend"]}
                            sorter={(a, b, c) => {
                                if(c == "ascend"){
                                    filtroDisenioCss("sellOutTabla"+c)
                                }else{
                                    quitarFiltroDisenioCss("sellOutTablaascend")
                                }
                                return a.sellOut - b.sellOut
                            }}
                            render={(sellOut) => {
                                let formatoNumero = Intl.NumberFormat('de-DE').format(sellOut)
                                return <>{formatoNumero}</>
                            }}
                        />
                        <Column 
                            className={
                                filtroCss.find(f => f == "sellOutLMSPTablaascend" ? "sellOutLMSPTablaascend" : "")
                            }
                            title="SO vs LM SP"
                            dataIndex="sellOutLMSP" 
                            key="sellOutLMSP" 
                            width={90}
                            showSorterTooltip={false}
                            sortDirections={["ascend","descend","ascend"]}
                            sorter={(a, b, c) => {
                                if(c == "ascend"){
                                    filtroDisenioCss("sellOutLMSPTabla"+c)
                                }else{
                                    quitarFiltroDisenioCss("sellOutLMSPTablaascend")
                                }
                                return a.sellOutLMSP - b.sellOutLMSP
                            }} 
                            render={(sellOutLMSP) => 
                                <div className="celdaDatosBody">
                                    <img 
                                        className="celdaIconoSemaforo"
                                        src={
                                            sellOutLMSP < 0
                                                ? SemaforoRed
                                                : sellOutLMSP == 0
                                                    ? SemaforoYellow
                                                    : SemaforoGreen
                                        }
                                    />
                                    <span>{sellOutLMSP}%</span>
                                </div>
                            }
                        />
                        <Column 
                            className={
                                filtroCss.find(f => f == "sellOutLYSPTablaascend" ? "sellOutLYSPTablaascend" : "")
                            }
                            title="SO vs LY SP"
                            dataIndex="sellOutLYSP" 
                            key="sellOutLYSP" 
                            width={90}
                            showSorterTooltip={false}
                            sortDirections={["ascend","descend","ascend"]}
                            sorter={(a, b, c) => {
                                if(c == "ascend"){
                                    filtroDisenioCss("sellOutLYSPTabla"+c)
                                }else{
                                    quitarFiltroDisenioCss("sellOutLYSPTablaascend")
                                }
                                return a.sellOutLYSP - b.sellOutLYSP
                            }} 
                            render={(sellOutLYSP) => 
                                <div className="celdaDatosBody">
                                    <img 
                                        className="celdaIconoSemaforo"
                                        src={
                                            sellOutLYSP < 0
                                                ? SemaforoRed
                                                : sellOutLYSP == 0
                                                    ? SemaforoYellow
                                                    : SemaforoGreen
                                        }
                                    />
                                    <span>{sellOutLYSP}%</span>
                                </div>
                            }
                        />
                        <Column 
                            className={
                                filtroCss.find(f => f == "sellOutPL4MSPTablaascend" ? "sellOutPL4MSPTablaascend" : "")
                            }
                            title="SO vs PL4M SP"
                            dataIndex="sellOutPL4MSP" 
                            key="sellOutPL4MSP" 
                            showSorterTooltip={false}
                            sortDirections={["ascend","descend","ascend"]}
                            width={100}
                            sorter={(a, b, c) => {
                                if(c == "ascend"){
                                    filtroDisenioCss("sellOutPL4MSPTabla"+c)
                                }else{
                                    quitarFiltroDisenioCss("sellOutPL4MSPTablaascend")
                                }
                                return a.sellOutPL4MSP - b.sellOutPL4MSP
                            }} 
                            render={(sellOutPL4MSP) => 
                                <div className="celdaDatosBody">
                                    <img 
                                        className="celdaIconoSemaforo"
                                        src={
                                            sellOutPL4MSP < 0
                                                ? SemaforoRed
                                                : sellOutPL4MSP == 0
                                                    ? SemaforoYellow
                                                    : SemaforoGreen
                                        }
                                    />
                                    <span>{sellOutPL4MSP}%</span>
                                </div>
                            }
                        />
                        <Column 
                            className={
                                filtroCss.find(f => f == "proySellOutTablaascend" ? "proySellOutTablaascend" : "")
                            }
                            title="FORECAST SO"
                            dataIndex="proySellOut" 
                            key="proySellOut" 
                            width={90}
                            showSorterTooltip={false}
                            sortDirections={["ascend","descend","ascend"]}
                            sorter={(a, b, c) => {
                                if(c == "ascend"){
                                    filtroDisenioCss("proySellOutTabla"+c)
                                }else{
                                    quitarFiltroDisenioCss("proySellOutTablaascend")
                                }
                                return a.proySellOut - b.proySellOut
                            }} 
                            render={(proySellOut) => {
                                let formatoNumero = Intl.NumberFormat('de-DE').format(proySellOut)
                                return <>{formatoNumero}</>
                            }}
                        />
                        <Column 
                            className={
                                filtroCss.find(f => f == "sellOutLMTablaascend" ? "sellOutLMTablaascend" : "")
                            }
                            title="SO vs LM"
                            dataIndex="sellOutLM" 
                            key="sellOutLM" 
                            width={80}
                            showSorterTooltip={false}
                            sortDirections={["ascend","descend","ascend"]}
                            sorter={(a, b, c) => {
                                if(c == "ascend"){
                                    filtroDisenioCss("sellOutLMTabla"+c)
                                }else{
                                    quitarFiltroDisenioCss("sellOutLMTablaascend")
                                }
                                return a.sellOutLM - b.sellOutLM
                            }} 
                            render={(sellOutLM) => 
                                <div className="celdaDatosBody">
                                    <img 
                                        className="celdaIconoSemaforo"
                                        src={
                                            sellOutLM < 0
                                                ? SemaforoRed
                                                : sellOutLM == 0
                                                    ? SemaforoYellow
                                                    : SemaforoGreen
                                        }
                                    />
                                    <span>{sellOutLM}%</span>
                                </div>
                            }
                        />
                        <Column 
                            className={
                                filtroCss.find(f => f == "sellOutLYTablaascend" ? "sellOutLYTablaascend" : "")
                            }
                            title="SO vs LY"
                            dataIndex="sellOutLY" 
                            key="sellOutLY" 
                            width={80}
                            showSorterTooltip={false}
                            sortDirections={["ascend","descend","ascend"]}
                            sorter={(a, b, c) => {
                                if(c == "ascend"){
                                    filtroDisenioCss("sellOutLYTabla"+c)
                                }else{
                                    quitarFiltroDisenioCss("sellOutLYTablaascend")
                                }
                                return a.sellOutLY - b.sellOutLY
                            }} 
                            render={(sellOutLY) => 
                                <div className="celdaDatosBody">
                                    <img 
                                        className="celdaIconoSemaforo"
                                        src={
                                            sellOutLY < 0
                                                ? SemaforoRed
                                                : sellOutLY == 0
                                                    ? SemaforoYellow
                                                    : SemaforoGreen
                                        }
                                    />
                                    <span>{sellOutLY}%</span>
                                </div>
                            }
                        />
                        <Column 
                            className={
                                filtroCss.find(f => f == "sellOutPL4MTablaascend" ? "sellOutPL4MTablaascend" : "")
                            }
                            title="SO vs PL4M"
                            dataIndex="sellOutPL4M" 
                            key="sellOutPL4M" 
                            showSorterTooltip={false}
                            sortDirections={["ascend","descend","ascend"]}
                            width={90}
                            sorter={(a, b, c) => {
                                if(c == "ascend"){
                                    filtroDisenioCss("sellOutPL4MTabla"+c)
                                }else{
                                    quitarFiltroDisenioCss("sellOutPL4MTablaascend")
                                }
                                return a.sellOutPL4M - b.sellOutPL4M
                            }} 
                            render={(sellOutPL4M) => 
                                <div className="celdaDatosBody">
                                    <img 
                                        className="celdaIconoSemaforo"
                                        src={
                                            sellOutPL4M < 0
                                                ? SemaforoRed
                                                : sellOutPL4M == 0
                                                    ? SemaforoYellow
                                                    : SemaforoGreen
                                        }
                                    />
                                    <span>{sellOutPL4M}%</span>
                                </div>
                            }
                        />
                        {/* <Column 
                            className={
                                filtroCss.find(f => f == "invTablaascend" ? "invTablaascend" : "")
                            }
                            title="INV"
                            dataIndex="inv" 
                            key="inv" 
                            width={40}
                            showSorterTooltip={false}
                            sortDirections={["ascend","descend","ascend"]}
                            sorter={(a, b, c) => {
                                if(c == "ascend"){
                                    filtroDisenioCss("invTabla"+c)
                                }else{
                                    quitarFiltroDisenioCss("invTablaascend")
                                }
                                return a.inv - b.inv
                            }} 
                        />
                        <Column 
                            className={
                                filtroCss.find(f => f == "doiTablaascend" ? "doiTablaascend" : "")
                            }
                            title="DOI"
                            dataIndex="doi" 
                            key="doi" 
                            width={40}
                            showSorterTooltip={false}
                            sortDirections={["ascend","descend","ascend"]}
                            sorter={(a, b, c) => {
                                if(c == "ascend"){
                                    filtroDisenioCss("doiTabla"+c)
                                }else{
                                    quitarFiltroDisenioCss("doiTablaascend")
                                }
                                return a.doi - b.doi
                            }} 
                        />
                        <Column 
                            className={
                                filtroCss.find(f => f == "inStockTablaascend" ? "inStockTablaascend" : "")
                            }
                            title="In Stock (%)"
                            dataIndex="inStock" 
                            key="inStock" 
                            width={70}
                            showSorterTooltip={false}
                            sortDirections={["ascend","descend","ascend"]}
                            sorter={(a, b, c) => {
                                if(c == "ascend"){
                                    filtroDisenioCss("inStockTabla"+c)
                                }else{
                                    quitarFiltroDisenioCss("inStockTablaascend")
                                }
                                return a.inStock - b.inStock
                            }} 
                        />
                        <Column 
                            className={
                                filtroCss.find(f => f == "filRateTablaascend" ? "filRateTablaascend" : "")
                            }
                            title="Fil Rate (CJ)"
                            dataIndex="filRate" 
                            key="filRate" 
                            width={70}
                            showSorterTooltip={false}
                            sortDirections={["ascend","descend","ascend"]}
                            sorter={(a, b, c) => {
                                if(c == "ascend"){
                                    filtroDisenioCss("filRateTabla"+c)
                                }else{
                                    quitarFiltroDisenioCss("filRateTablaascend")
                                }
                                return a.filRate - b.filRate
                            }} 
                        />
                        <Column 
                            colSpan={2}
                            className={
                                filtroCss.find(f => f == "filRateValorTablaascend" ? "filRateValorTablaascend" : "")
                            }
                            title="Fil Rate Valor"
                            dataIndex="filRateValor" 
                            key="filRateValor" 
                            width={70}
                            showSorterTooltip={false}
                            sortDirections={["ascend","descend","ascend"]}
                            sorter={(a, b, c) => {
                                if(c == "ascend"){
                                    filtroDisenioCss("filRateValorTabla"+c)
                                }else{
                                    quitarFiltroDisenioCss("filRateValorTablaascend")
                                }
                                return a.filRateValor - b.filRateValor
                            }} 
                        /> */}
                    </ColumnGroup>
                </Table>
                </Spin>
            </Col>
        </Row>
    )
}

export default TablaPowerBi