import React, { useEffect, useState  } from 'react'
import { Row, Space, Col, DatePicker, Select, Divider, Input, Button, Modal, Skeleton } from 'antd'
import TitleSearchGlobal from '../Global/TitleSearchGlobal'
import '../../Styles/Components/ChannelsExecutives/FormChannelsExecutives.css'
import { useDispatch, useSelector } from 'react-redux'
import { AddItemSelectChannelFormEditChannelsExecutivesReducer, AddItemSelectExecutiveFormEditChannelsExecutivesReducer, AddItemSelectZoneFormEditChannelsExecutivesReducer, GetInfoChannelsExecutivesReducer, SaveExecutiveChannelChannelsExecutivesReducer } from '../../Redux/Actions/ChannelsExecutives/ChannelsExecutives'
import TableRowsSelected from './TableRowsSelected'
import SelectionRowsChannelsExecutives from './SelectionRowsChannelsExecutives'
import { 
    PlusOutlined,
    ExclamationCircleFilled
} from '@ant-design/icons'
import dayjs from 'dayjs'
import { useNavigate } from 'react-router'
import { useParams } from 'react-router'
import notificationGlobal from '../Global/NotificationGlobal'
import SideMenu from '../Global/SideMenu'

const FormChannelsExecutives = () => {

    const dispatch = useDispatch()

    const { 
        rex_data_form_edit_channels_executive,
        rex_data_filter_executives_channels_executive,
        rex_data_filter_channels_channels_executive,
        rex_data_filter_zones_channels_executive,
        rex_date_filter_channels_executives,
    } = useSelector(({channelsExecutives}) => channelsExecutives)

    const [ executive, setExecutive ] = useState('')
    const [ loadingData, setLoadingData ] = useState(false)
    const [ channel, setChannel ] = useState('')
    const [ zone, setZone ] = useState('')
    const [ channelEdit, setChannelEdit ] = useState({})
    const [ date, setDate ] = useState(rex_date_filter_channels_executives)
    const { id } = useParams()
    const { confirm } = Modal
    let navigate = useNavigate()


    const { 
        rex_users_conected_dashboard,
    } = useSelector(({registerUsers}) => registerUsers)

    const { 
        rex_data_users_online
    } = useSelector(({users}) => users)

    const onNameChange = (event, type) => {
        if(type == "executive"){
            setExecutive(event.target.value);
        }else if(type == "channel"){
            setChannel(event.target.value)
        }else{
            setZone(event.target.value)
        }
    }

    const addItem = async (e, type) => {
        e.preventDefault();
        if(type == "executive"){
            await dispatch(AddItemSelectExecutiveFormEditChannelsExecutivesReducer(executive))
            setExecutive('')
        }else if(type == "channel"){
            await dispatch(AddItemSelectChannelFormEditChannelsExecutivesReducer(channel))
            setChannel('')
        }else{
            await dispatch(AddItemSelectZoneFormEditChannelsExecutivesReducer(zone))
            setZone('')
        }
    };

    const confirmSave = async () => {

        if((!executive || executive == "") || (!channel || channel == "") || (!zone || zone == "")){
            notificationGlobal("warning", "Los campos ejecutivo, canal y zona son obligatorios")
            return 
        }
        
        if(rex_data_form_edit_channels_executive.length == 0){
            notificationGlobal("warning", "Debe seleccionar al menos 1 cliente")
            return
        }
        
        confirm({
            title   : "Guardar cambios",
            icon    : <ExclamationCircleFilled/>,
            content : "¿Está seguro que desea guardar los cambios realizados?",
            zIndex  : 100000000,
            className:"Confirm-Action-Channel-Executive",
            async onOk(){
                const response = await dispatch(SaveExecutiveChannelChannelsExecutivesReducer(id, executive, channel, zone, date))
                if(response){
                    navigate('/channels-executives')
                }
            },
            okButtonProps: {
                size:"small",
            },
            cancelButtonProps : {
                size:"small",
                danger:true
            },
            okCancel(){}
        })
    }

    const confirmReset = async () => {
        
        confirm({
            title   : "Eliminar cambios",
            icon    : <ExclamationCircleFilled/>,
            content : "¿Está seguro que desea eliminar los cambios realizados?",
            zIndex  : 100000000,
            className:"Confirm-Action-Channel-Executive",
            async onOk(){
                await fetchData()
            },
            okButtonProps: {
                size:"small",
            },
            cancelButtonProps : {
                size:"small",
                danger:true
            },
            okCancel(){}
        })
    }

    const fetchData = async () => {
        setLoadingData(true)
        const {ejecutivo, canal, zona} = await dispatch(GetInfoChannelsExecutivesReducer(id))
        setLoadingData(false)
        setExecutive(ejecutivo)
        setChannel(canal)
        setZone(zona)
        setChannelEdit({...channelEdit, zona: zona, canal: canal, ejecutivo: ejecutivo})
    }

    useEffect(() => {
        fetchData()
    },[])

    useEffect(() => {

    }, [rex_data_form_edit_channels_executive])

    return (
        <div className="Container-Main">
            <TitleSearchGlobal
                title={`${id ?"Editar":"Agregar"} Comercial Team`}
                subTitle={null}
                showInputSearch={false}
            />
            <Row style={{marginTop:"5px"}}>
                <Space>

                    {loadingData
                    ? <>
                        <Skeleton.Input size='small' active={true} />
                        <Skeleton.Input size='small' active={true} />
                        <Skeleton.Input size='small' active={true} />
                        <Skeleton.Input size='small' active={true} />
                    </>
                    :
                    <>
                        <Select 
                            size='small'
                            allowClear={true}
                            onClear={() => setChannel("")}
                            title="Seleccionar Canal"
                            className="Select-Simple-Executive-Form"
                            popupClassName="Select-Executive-Form"
                            placeholder="Seleccionar Canal"
                            value={channel}
                            dropdownRender={(menu) => (
                                <>
                                {menu}
                                <Divider
                                    style={{
                                    margin: '3px 0',
                                    }}
                                />
                                <Space
                                    style={{
                                    padding: '0 8px 4px',
                                    }}
                                >
                                    <Input
                                        className="Input-Filter-Executive-Form"
                                        placeholder="Agregar nuevo"
                                        size="small"
                                        value={channel}
                                        onChange={(e) => onNameChange(e, "channel")}
                                    />
                                        <Button size="small" type="text" icon={<PlusOutlined />} onClick={(e) => addItem(e, "channel")}>
                                    </Button>
                                </Space>
                                </>
                            )}
                            options={rex_data_filter_channels_channels_executive}
                            onChange={(value, option) => setChannel(value ? value : null)}
                        />
                        <Select 
                            size='small'
                            allowClear={true}
                            onClear={() => setZone("")}
                            title="Seleccionar Zona"
                            className="Select-Simple-Executive-Form"
                            popupClassName="Select-Executive-Form"
                            placeholder="Seleccionar Zona"
                            value={zone}
                            dropdownRender={(menu) => (
                                <>
                                {menu}
                                <Divider
                                    style={{
                                    margin: '3px 0',
                                    }}
                                />
                                <Space
                                    style={{
                                    padding: '0 8px 4px',
                                    }}
                                >
                                    <Input
                                        className="Input-Filter-Executive-Form"
                                        placeholder="Agregar nuevo"
                                        size="small"
                                        value={zone}
                                        onChange={(e) => onNameChange(e, "zone")}
                                    />
                                        <Button size="small" type="text" icon={<PlusOutlined />} onClick={(e) => addItem(e, "zone")}>
                                    </Button>
                                </Space>
                                </>
                            )}
                            options={rex_data_filter_zones_channels_executive}
                            onChange={(value, option) => setZone(value ? value : null)}
                        />

                        <Select 
                            size="small"
                            allowClear={true}
                            onClear={() => setExecutive("")}
                            title="Seleccionar Ejecutivo"
                            className="Select-Simple-Executive-Form Select-Executive"
                            popupClassName="Select-Executive-Form"
                            placeholder="Seleccionar Ejecutivo"
                            value={executive}
                            dropdownRender={(menu) => (
                                <>
                                {menu}
                                <Divider
                                    style={{
                                    margin: '3px 0',
                                    }}
                                />
                                <Space
                                    style={{
                                    padding: '0 8px 4px',
                                    }}
                                >
                                    <Input
                                        className="Input-Filter-Executive-Form"
                                        placeholder="Agregar nuevo"
                                        size="small"
                                        value={executive}
                                        onChange={(e) => onNameChange(e, "executive")}
                                    />
                                        <Button size="small" type="text" icon={<PlusOutlined />} onClick={(e) => addItem(e, "executive")}>
                                    </Button>
                                </Space>
                                </>
                            )}
                            options={rex_data_filter_executives_channels_executive}
                            onChange={(value, option) => setExecutive(value ? value : null)}
                        />

                        <DatePicker 
                            size="small" 
                            picker="month"
                            className="DatePicker-Executive-Form"
                            popupClassName='DatePicker-Form-Channel-Executives'
                            value={date ? dayjs(dayjs(date), "AAAA-MM") : dayjs(dayjs(), "AAAA-MM")}
                            onChange={(date, dateString) => {
                                setDate(dateString)
                            }}
                        />
                    </>
                }
                    <Button 
                        size="small"
                        className="Button-Reset-Form-Channels-Executive" 
                        onClick={() => confirmReset()}
                    >Reset</Button>
                    <Button 
                        size="small"
                        className="Button-Save-Form-Channels-Executive" 
                        onClick={() => confirmSave()}
                    >Guardar</Button>
                </Space>
            </Row>
            

            <Row style={{marginTop:"5px"}} gutter={24}>
                <Col className="Container-Box-Form-Channels" md={12} xs={24}>
                    <div className="Title-Box-Form-Channel-Executive">Lista de clientes</div>
                    <div className="Box-Form-Channel-Executive">
                        <div className="Container-Info-Channels-Executives Selection-Rows">
                            {
                                loadingData
                                ?   <>
                                        <Skeleton active={true}/>
                                        <Skeleton active={true}/>
                                    </>
                                : <SelectionRowsChannelsExecutives />
                            }
                        </div>
                    </div>
                </Col>
                
                <Col className="Container-Box-Form-Channels" md={12} xs={24}>
                    <div className="Title-Box-Form-Channel-Executive">Clientes Seleccionados ({rex_data_form_edit_channels_executive.length})</div>
                    <div className="Box-Form-Channel-Executive Table-Selected">
                        <Row>
                            <Col span={24} className="Container-Info-Channels-Executives Table-Selected">
                                {
                                    loadingData
                                    ? <Skeleton active={true}/>
                                    : <TableRowsSelected/>
                                }
                            </Col>
                        </Row>
                    </div>
                </Col>
            </Row>

            <SideMenu
                usersCurrentView  = {rex_users_conected_dashboard}
                usersAllConnected ={rex_data_users_online}
            />
        </div>
    )
}

export default FormChannelsExecutives