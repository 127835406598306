import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, Typography } from 'antd'
import { ClearModuleSelectedReducer, GetDataModulesReducer } from '../../Redux/Actions/Modules/Modules'
import TabModules from '../../Components/Modules/TabListModules/TabModules'
import FilterSelectRadioGlobal from '../../Components/Global/FilterSelectRadioGlobal'
import SideMenu from '../../Components/Global/SideMenu'
import ButtonGlobal from '../../Components/Global/ButtonGlobal'
import NavigationGlobal from '../../Components/Global/NavigationGlobal'

const Modules = () => {
    const { Title } = Typography
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(GetDataModulesReducer())
        dispatch(ClearModuleSelectedReducer())
    }, [])

    const { 
        rex_data_paises,
    } = useSelector(({paises}) => paises)

    const {
        rex_get_data_modules,
    } = useSelector(({modules}) => modules)

    const { 
        rex_users_conected_dashboard,
    } = useSelector(({registerUsers}) => registerUsers)

    const { 
        rex_data_users_online
    } = useSelector(({users}) => users)

    const { 
        rex_data_menu_module_clone
    } = useSelector(({menu}) => menu)

    const menuProfileTypes = [
        {
            url: '/home-administrator',
            nombre: 'Administrador'
        },
        {
            url: '/administrator/modules',
            nombre: 'Módulos'
        },
    ]

    return(
        <div className="Container-Main Container-Modules">
            <Row style={{position: 'relative'}}>
                <Col span={24} style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <Title className="Title-Modules">
                        Módulos
                    </Title>
                </Col>
                <Col span={24} style={{position: 'absolute', top: '6px'}}>
                    <NavigationGlobal menuItems={menuProfileTypes}/>
                </Col>
                <Col span={24} style={{marginBottom: '14px'}}>
                    <FilterSelectRadioGlobal 
                        textSelect="Filtro por pais" 
                        data={rex_data_paises}
                        action={GetDataModulesReducer}
                    />
                </Col>
            </Row>
            <Row>
                <Col span={12} style={{paddingRight: '10px'}}>
                    <Row >
                        <Col span={24}>
                            <div className="Container-SubTitle">
                                <div className="Contaimer-Image-Text">
                                    <div className="Image-Subtitle"></div>
                                    <p className="Text-Subtitle">Home</p>
                                </div>
                                <div style={{marginBottom: '3px'}}>
                                    <ButtonGlobal 
                                        nameRoute="/administrator/modules/create-modules"
                                        txtButton="Nuevo"
                                    />
                                </div>
                            </div>
                            <p className="Text-Menu-Description">Arrastra los módulos para modificar el orden</p>
                        </Col>
                    </Row>
                    <Row style={{rowGap: '8px'}}>
                        {
                            rex_get_data_modules.length > 0 && 
                            <TabModules 
                                type="modules"
                                modules={rex_get_data_modules} 
                            />
                        }
                    </Row>
                </Col>
                <Col span={12} style={{paddingLeft: '10px'}}>
                    <Row>
                        <Col span={24}>
                            <div className="Container-SubTitle" style={{marginTop: '6.52px'}}>
                                <div className="Contaimer-Image-Text">
                                    <div className="Image-Subtitle Image-Menu"></div>
                                    <p className="Text-Subtitle Text-Menu">Menú</p>
                                </div>
                            </div>
                            <p className="Text-Menu-Description">Arrastra los módulos para modificar el orden</p>
                        </Col>
                    </Row>
                    <Row style={{rowGap: '8px'}}>
                        {
                            rex_data_menu_module_clone.length > 0 && 
                            <TabModules 
                                type="menu"
                                modules={rex_data_menu_module_clone} 
                            />
                        }
                    </Row>
                </Col>
            </Row>

            <SideMenu
                usersCurrentView  = {rex_users_conected_dashboard}
                usersAllConnected ={rex_data_users_online}
            />
        </div>
    )
}

export default Modules