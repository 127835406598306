import config from '../../../config'
import {
    CARGANDO_DATA_DAILY,
    OBTENER_DATA_DAILY_GRAFICO_COPY,
    OBTENER_DATA_DAILY_GRAFICO,
} from '../../../Constants/PowerBi/Daily'
import { notification } from 'antd'
import Moment from 'moment'

const notificacionServidor = (type, mensaje) => {
    if(type === 'success'){
        notification['success']({
            message: 'Éxito',
            description: mensaje,
        })
    }else if(type === 'warning'){
        notification['warning']({
            message: 'Advertencia',
            description: mensaje,
        })
    }else if(type === 'error'){
        notification['error']({
            message: 'Error',
            description: mensaje,
        })
    }
}

export const GetDataDailyChartReducer = () => async (dispatch, getState) => {

    const {
        rex_data_formato_original,
        rex_data_talla_original,
        rex_data_conteo_original,
        rex_data_sku_original,
        rex_data_descripcion_material_original,
    } = getState().filtros

    let paisTotal = [
        {paisKey: 1, abreviatura: "PE"},
        {paisKey: 5, abreviatura: "BO"},
        {paisKey: 3, abreviatura: "CL"},
        {paisKey: 7, abreviatura: "PE"},
    ]
    let paisApi = paisTotal.find(p => p.paisKey == parseInt(localStorage.getItem('usupais')))

    let req_anio    = getState().daily.rex_select_daily_anio
    let req_mes     = getState().daily.rex_select_daily_mes
    let req_sku     = getState().daily.rex_select_daily__hml_cod_mat
    let req_hml_mat = getState().daily.rex_select_daily_hml_mat
    let req_hml_cli = getState().daily.rex_select_daily_hml_cli
    let req_filtro  = getState().filtros.rex_data_type_data
    let req_conteo  = getState().daily.rex_select_daily_conteo
    let req_talla   = getState().daily.rex_select_daily_talla
    let req_formato   = getState().daily.rex_select_daily_formato

    let send_sku = req_sku != false && req_sku[0].length == rex_data_sku_original.length ? false : req_sku
    let send_hml_mat = req_hml_mat != false && req_hml_mat[0].length == rex_data_descripcion_material_original.length ? false : req_hml_mat
    let send_hml_cli = req_hml_cli != false && req_hml_cli[0].length == 3 ? false : req_hml_cli
    let send_conteo = req_conteo != false && req_conteo[0].length == rex_data_conteo_original.length ? false : req_conteo
    let send_talla = req_talla != false && req_talla[0].length == rex_data_talla_original.length ? false : req_talla
    let send_formato = req_formato != false && req_formato[0].length == rex_data_formato_original.length ? false : req_formato

    let mes = Moment().month()
    let anio = Moment().year()

    let headerFetch = {
        'Accept' : 'application/json',
        'content-type': 'application/json',
    }

    await fetch(config.api_url_powerbi+'dashboards/daily',
		{
            mode:'cors',
			method: 'POST',
			headers: headerFetch,
            body: JSON.stringify({
                're_anio'                   : req_anio == null ? anio : req_anio,
                're_mes'                    : req_mes == null ? mes + 1 : req_mes,
                're_filtro'                 : req_filtro,
                're_sku'                    : send_sku,
                're_descripcion_material'   : send_hml_mat,
                're_cliente_hml'            : send_hml_cli,
                're_conteo'                 : send_conteo,
                're_talla'                  : send_talla,
                're_formato'                : send_formato,
                're_country'                : paisApi.abreviatura,
            }),
      	}
    )
    .then(async res => {
		return res.json()
    })
    .then(async data => {
		// const estadoRequest = getState().estadoRequest.init_request
		// if(estadoRequest === true){
            dispatch({
                type: OBTENER_DATA_DAILY_GRAFICO,
                payload : data.lista_dias
            })
            dispatch({
                type: OBTENER_DATA_DAILY_GRAFICO_COPY,
                payload : data.lista_dias
            })
		// }else{
        //     notificacionServidor('error', data.message)
        // }
    })
    .catch((error)=> {
        notificacionServidor('error', 'Error con el servidor. Vuelva a ingresar más tarde.')
        // console.log(error)
    })

    dispatch({
        type: CARGANDO_DATA_DAILY,
        payload: false
    })
}