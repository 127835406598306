import React from 'react'
import { Row, Col } from 'antd'

const PopOverChannelsExecutives = ({data}) => {

    return (
        <Row gutter={16}>
            <Col 
                className="Container-ShipTo-PopUp"
            >
                {
                    data.map(cli => (
                        <div style={{display:"flex", alignItems:"center"}}>
                            <b>&bull;</b>
                            <div title={`${cli.hml_client} (${cli.cod_ship_to})`} className="Title-Checkbox-PopUp-Channels-Executives Container-Text-ShipTo">
                                <span style={{marginLeft:"5px"}}>{cli.hml_client} ({cli.cod_ship_to})</span>
                            </div>
                            
                        </div>
                    ))
                }
            </Col>
        </Row>
    )
}

export default PopOverChannelsExecutives