import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import { Row, Col, Form, Input, Upload, Button, Collapse, Checkbox, Modal } from 'antd'
import UploadButtonGlobal from '../../Assets/Images/Global/UploadButtonGlobal'
import CloseIconGlobal from '../../Assets/Images/Global/CloseIcon'
import DownArrowButton from '../../Assets/Images/Global/DownArrow'
import { CloseCircleOutlined } from '@ant-design/icons';
import { useParams } from "react-router-dom"
import { useDispatch, useSelector } from 'react-redux'
import { EditInfoDataControlReducer, GetInfoDataControlReducer, SaveInfoDataControlReducer } from '../../Redux/Actions/DataControl/DataControl'

const FormEditDataControl = ({setShowModal, setInfoModal, infoModal}) => {

    const [ mouseOverButton, setMouseOverButton ] = useState(false)
    const navigate = useNavigate()
    const { Panel } = Collapse
    const { id } = useParams()
    const { confirm } = Modal
    const [ overSelect, setOverSelect ] = useState(false)
    const [ selectOpen, setSelectOpen ] = useState(false)

    const dispatch = useDispatch()

    const { 
        rex_data_paises,
    } = useSelector(({paises}) => paises)

    useEffect(() => {
        dispatch(GetInfoDataControlReducer(id))
    }, [])

    const { 
        rex_info_data_control_edited
    } = useSelector(({dataControl}) => dataControl)

    const confirmEdit = async () => {
        confirm({
            title   : <span>Confirmar</span>,
            icon    : null,
            className :"Modal-Confirm-Data-Control",
            content : `¿Estás seguro de guardar los cambios?`,
            zIndex  : 100000000,
            async onOk(){
                let response = await dispatch(SaveInfoDataControlReducer())
                if(response){
                    setShowModal(true)
                    setInfoModal({
                        ...infoModal, 
                        title : "¡Listo!", 
                        description :`${rex_info_data_control_edited.ardnombre} fue editado con éxito`, action:"edit", status: true
                    })
                }
            },
            okText : "Confirmar",
            okButtonProps: {
                size:"small",
            },
            cancelText: "Cancelar",
            cancelButtonProps : {
                size:"small",
                danger:true
            },
            okCancel(){}
        })
    }

    const editDataControl = async () => {

        let invalidForm = false
        let messageInvalid = ""
        if(rex_info_data_control_edited.countries.length == 0){
            invalidForm = true
            messageInvalid = "Debe selecionar al menos un país"
        }
        if(!rex_info_data_control_edited.file_name){
            invalidForm = true
            messageInvalid = "Es necesario cargar un archivo"
        }
        if(rex_info_data_control_edited.ardnombre == ""){
            invalidForm = true
            messageInvalid = "El nombre es necesario"
        }
        if(invalidForm){
            setShowModal(true)
            setInfoModal({
                ...infoModal, 
                title : "!Alerta!", 
                description :messageInvalid,
                status : false
            })
            return false
        }
        confirmEdit()
    }

    const props = {
        onRemove: (file) => {
        },
        beforeUpload: (file) => {
            dispatch(EditInfoDataControlReducer("file",file))
            return false;
        },
        showUploadList: {
            showDownloadIcon: false,
            downloadIcon: 'Download',
            showPreviewIcon : false,
            showRemoveIcon: true,
            removeIcon: <CloseCircleOutlined onClick={(e) => console.log(e, 'custom removeIcon event')} />,
        },
    };

    return (
        <Form
            layout='vertical'
        >
            <Row gutter={16}>
                <Col xs={{span:24}} sm={{span:24}} md={{span:24}} lg={{span:12}} xl={{span:12}}>
                    <Form.Item
                        label='Nombre de archivo'
                        className='Create-Data-Control-Form-Item-Text'
                    >
                        <Input
                            placeholder='Nombres'
                            size='large'
                            value={rex_info_data_control_edited.ardnombre}
                            onChange={(e) => dispatch(EditInfoDataControlReducer('ardnombre', e.target.value))}
                        />
                    </Form.Item>
                    <Form.Item
                        label='País'
                        className='Create-Data-Control-Form-Item-Text'
                    >
                        <div
                            onMouseLeave={() => {
                                setSelectOpen(false)
                                setOverSelect(false)
                            }}
                            onMouseEnter={() => {
                                setOverSelect(true)
                            }}
                        >
                            <Collapse 
                                expandIcon={({ isActive }) => (<DownArrowButton/>)}
                                className="Collapse-Create-Data-Control" 
                                onChange={(value) => {
                                    setSelectOpen(value.length > 0)
                                }}
                                expandIconPosition='end'
                                activeKey={overSelect == false || selectOpen == false ?  [] : ['1'] }
                            >
                                <Panel 
                                    key={'1'}
                                    className="Header-Create-Data-Control" 
                                    header={rex_info_data_control_edited.countries.length > 0 
                                                ? <div className='Container-List-Countries-Selected'>
                                                    {
                                                        rex_info_data_control_edited.countries.map(cou => {
                                                            return <div className='Box-Country-Selected'>
                                                                <span>{cou.label}</span>
                                                                <span style={{display:"flex", alignItems:"center"}} 
                                                                    onClick={(e) => {
                                                                        e.stopPropagation()
                                                                        dispatch(dispatch(EditInfoDataControlReducer('country', cou)))
                                                                    }}
                                                                ><CloseIconGlobal/></span>
                                                            </div>
                                                        })
                                                    }
                                                </div>
                                                : <span className='Placeholder-Select-Country'>Seleccionar un país o varios países</span>
                                            }
                                >
                                    <div className='Container-Select-Country'>
                                        {
                                            rex_data_paises.map(cou => {
                                                return <div className='Container-Option-Select-Country'>
                                                    <Checkbox 
                                                        onChange={() => {
                                                            dispatch(EditInfoDataControlReducer('country', cou))
                                                        }}
                                                        checked={rex_info_data_control_edited.countries.some(cos => cos.label == cou.label)}
                                                    />
                                                    <span 
                                                        onClick={() => dispatch(EditInfoDataControlReducer('country', cou))}
                                                        className='Title-Option-Select-Country'
                                                    >{cou.label}</span>
                                                </div>
                                            })
                                        }
                                    </div>
                                </Panel>
                            </Collapse>
                        </div>
                    </Form.Item>
                </Col>
                <Col xs={{span:24}} sm={{span:24}} md={{span:24}} lg={{span:12}} xl={{span:12}}>
                    <Form.Item
                        label='Cargar archivo'
                        className='Create-Data-Control-Form-Item-Text'
                    >
                        <Upload
                            size='large'
                            {...props}
                            maxCount={1}
                            className='Button-Create-Upload'
                        >
                            <Button
                                className='Container-Button-Create-Upload'                                
                                onMouseOver={()=> setMouseOverButton(true)}
                                onMouseLeave={() => setMouseOverButton(false)}
                            ><UploadButtonGlobal onMouseOver={mouseOverButton}/><span>Cargar el archivo</span></Button>
                        </Upload>
                        <span>
                            <div className='Container-File-Upload-List'>
                                { rex_info_data_control_edited.file_name 
                                    ? <>  
                                        <span>{rex_info_data_control_edited.file_name}</span>
                                        <CloseCircleOutlined onClick={() => dispatch(EditInfoDataControlReducer('file', null))} style={{color:"#3B72FF", cursor:"pointer"}} />
                                        </>
                                    : <>  
                                    <span style={{color:"red"}}>El archivo es requerido</span>
                                    </>
                                    }
                            </div>
                        </span>
                    </Form.Item>
                    <Row style={{justifyContent:'end'}}>
                        <Col className='Create-Data-Control-Container-Button-Action'>
                            <button
                                className='Create-Data-Control-Form-Container-Button Create-Data-Control-Form-Button-Cancel'
                                type="primary" 
                                htmlType="submit"
                                onClick={() => navigate('/administrator/data-control')}
                            >
                                <span>Cancelar</span>
                                <span className='Text-Span-Cancel-Create-Data-Control'>Cancelar</span>
                            </button>
                            <button
                                className='Create-Data-Control-Form-Container-Button Create-Data-Control-Form-Button-Save'
                                onClick={() => editDataControl()}
                            >
                                Guardar                              
                            </button>
                        </Col>
                    </Row>
                </Col>
            </Row>
        </Form>
    )
}

export default FormEditDataControl