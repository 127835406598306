import React from "react"
import { useDispatch, useSelector } from "react-redux"
import { Row, Col, Typography, Space, Button, Tooltip } from 'antd'
import {
    FiltroBotonDataReducer,
    FiltroTableroDinamicoDataReducer
} from '../../Redux/Actions/PowerBi/PowerBiFiltros'
import TablaPowerBi from "./Componentes/TablaPowerBi"
import SoundOption from '../../Assets/Audio/sonidoOption.mp3'
import IconoTituloTabla from '../../Assets/Images/PowerBi/IconoTituloTabla.png'

const TablaFiltro = () => {

    const dispatch = useDispatch()
    
    const {
        rex_botones_filtro,
    } = useSelector(({filtros}) => filtros)

    const {
        rex_fecha_query,
    } = useSelector(({powerBi}) => powerBi)

    const { Title } = Typography

    let audio = new Audio(SoundOption)

    const reproduceSound = () => {
        audio.play()
    }

    return(
        <>
            <Row style={{marginBottom: '10px', rowGap: '10px'}}>
                <Col xl={17} lg={16} md={24} sm={24} xs={24} style={{padding: 0,}}>
                    <Space size={"large"} className="contenedorSubtitulo">
                        <Title level={4} className="subtituloTablaDinamica">
                            <img src={IconoTituloTabla} style={{width: '14px', marginTop: '-3px'}} /> Tablero Dinámico
                        </Title>
                        <Space size={"large"} className="contenedorBotonesPrincipales">
                        {
                            rex_botones_filtro.map(boton => 
                                <Button 
                                    onClick={() => {
                                        dispatch(FiltroBotonDataReducer(boton.nombre, true))
                                        dispatch(FiltroTableroDinamicoDataReducer())
                                        reproduceSound()
                                    }} 
                                    className={`botonesPrincipales ${boton.estado ? '' : 'desactivado'}`}
                                >
                                    {boton.nombre}
                                    <span 
                                        style={{position: 'absolute',}} 
                                        className="botonNombreDos"
                                    >
                                        {boton.nombre}
                                    </span>
                                </Button>
                            )
                        }
                        </Space>
                    </Space>
                </Col>
                <Col xl={7} lg={8} md={24} sm={24} xs={24} className="colFechaCarousel" >
                    {
                        rex_fecha_query.length > 0
                        ?<Tooltip
                            placement="topRight"
                            overlayClassName="contenedorTooltipFecha" 
                            title={
                                <>
                                {
                                    rex_fecha_query.map(fecha => 
                                        <div className="contenedorInternoFecha">
                                            {fecha.titulo}: <span>{fecha.dia}/{fecha.mesNumero}/{fecha.anio}</span>
                                        </div>
                                    )
                                }
                                </>
                            }
                        >
                            <div className="carousel-tablafiltro">
                                <ul>
                                    {
                                        rex_fecha_query.map((fecha, index) => {
                                            return (
                                                <li>
                                                    <div>
                                                        <span>{fecha.titulo}</span>: Actualización {fecha.dia} de <span>{fecha.mesTexto}</span> del {fecha.anio}
                                                    </div>
                                                </li>            
                                            )
                                        })
                                    }
                                </ul>
                            </div>
                        </Tooltip>
                        :null
                    }
                </Col>
            </Row>
            <TablaPowerBi />
        </>
    )
}

export default TablaFiltro