import dayjs from 'dayjs'
import config from '../../../config'
import {
    GET_USERS_DOWNLOAD_DATA_INTERNAL_CONNECTED
} from '../../../Constants/DownloadDataInternal/DownloadDataInternal'

export const GenerateExcelReducer = (type, fecha, module = null) => async ( dispatch, getState ) => {
    
    let url = ""
    let filter = {}

    const filtersTotalProducts              = getState().filtersProducts.rex_filters_products_total.rex_filter_products
    const filtersTotalClients               = getState().filtersClients.rex_filters_clients_total.rex_filter_clients
    const filtersTotalChannelsExecutives    = getState().channelsExecutives.rex_filters_channels_executive_program_total.rex_filter_channels_executive_program
    const filtersTotalIncentivenProgram     = getState().incentivProgram.rex_filters_incentiv_program_total


    if(module == "Maestra de Productos"){
        filter = {...filter, filtersTotalProducts}
    }else if(module == "Maestra de Clientes"){
        filter = {...filter, filtersTotalClients}
    }else if(module == "Canales Ejecutivos"){
        filter = {
            req_fil_customer    :filtersTotalChannelsExecutives.rex_txt_unique_customer_group,
            req_fil_gba         :filtersTotalChannelsExecutives.rex_txt_unique_gba,
            req_fil_canal       :filtersTotalChannelsExecutives.rex_txt_uniqueChannels,
            req_fil_zona        :filtersTotalChannelsExecutives.rex_txt_unique_zona,
            req_fil_sucursal    :filtersTotalChannelsExecutives.rex_txt_unique_sucursal
        }
    }else if(module == "Incentiven Program"){
        filter = {
            req_filter_channel      : filtersTotalIncentivenProgram.rex_filter_incentiv_program.rex_txt_uniqueChannels,
            req_filter_client       : filtersTotalIncentivenProgram.rex_filter_incentiv_program.rex_txt_unique_cliente,
            req_filter_zone         : filtersTotalIncentivenProgram.rex_filter_incentiv_program.rex_txt_unique_zona,
            req_filter_gba          : filtersTotalIncentivenProgram.rex_filter_incentiv_program.rex_txt_unique_gba,
            req_filter_incentive    : filtersTotalIncentivenProgram.rex_filter_incentiv_program.rex_txt_unique_incentive
        }
    }

    if(typeof(fecha) == "string" && fecha.length == 7){

    }else{
        const date = dayjs(dayjs(), "AAAA-MM")
        const currentMonth  = date.month() + 1 < 10 ? "0"+ (date.month() + 1) : date.month() + 1
        const currentYear   = date.year()
        fecha   = currentYear + "-" + currentMonth
    }

    await fetch(config.api+'download-data/'+type,
        {
            mode:'cors',
            method: 'POST',
            headers: {
                'Accept' : 'application/json',
                'Content-type' : 'application/json',
                'usutoken'	   : localStorage.getItem("usutoken"),
            },
            body: JSON.stringify({
                req_fecha : fecha,
                req_filters : filter
            })
        }
    )
    .then(async res => res.json())
    .then(async data => url = data.data)
    .catch((error)=> {
        console.log(error)
    })

    return url
}

export const RegisterUsersConnectedDownloadDataInternalReducer = (data) => async (dispatch) => {
    dispatch({
        type: GET_USERS_DOWNLOAD_DATA_INTERNAL_CONNECTED,
        payload: data
    })
}