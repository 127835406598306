import { notification } from "antd"

const notificationGlobal = (type, description) => {
    const notificationTypes = {
        success: 'Éxito',
        warning: 'Alerta',
        error: 'Error'
    };
  
    if (!notificationTypes.hasOwnProperty(type)) {
        throw new Error(`Tipo de notificación inválido: ${type}`);
    }
  
    const { placement, duration } = { placement: 'topRight', duration: 5 };
    const title = notificationTypes[type];
  
    notification[type]({
        size:"small",
        message: title,
        description,
        placement,
        duration,
    });
  }


export default notificationGlobal