import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Tooltip, Modal, Pagination, Table, Input } from 'antd'
import {
    CheckCircleOutlined,
    ExclamationCircleOutlined,
    ExclamationCircleFilled,
    FilterOutlined
} from '@ant-design/icons'
import TitleSearchGlobal from '../../Components/Global/TitleSearchGlobal'
import FilterSelectGlobal from '../../Components/Global/FilterSelectGlobal'
import { DeleteAuditsReducer, FilterAuditsReducer, GetAuditsUserReducer } from '../../Redux/Actions/Audits/Audits'
import moment from 'moment'
import ModalDetailJsonTable from '../../Components/Global/ModalDetailJsonTable'
import ModalDetailDescriptionTable from '../../Components/Global/ModalDetailDescriptionTable'
import SideMenu from '../../Components/Global/SideMenu'
import SimpleTableGlobal from '../../Components/Global/SimpleTableGlobal'
import ProfileTypesFilterSelect from '../../Components/ProfileTypes/ProfileTypesFilterSelect'
import TrashButtonGlobal from '../../Assets/Images/Global/TrashButtonGlobal'

const UserAudit = () => {

    const [ pageTableGlobal, setPageTableGlobal ] = useState(1)
    const [ pageSizeTableGlobal, setPageSizeTableGlobal ] = useState(10)
    const [ mouseOverRow ,setMouseOverRow] = useState(null)
    const [ modFilter, setModFilter ] = useState([])
    const [ txtFilter, setTxtFilter ] = useState("")
    const [ showModal, setShowModal ] = useState(false)
    const [ infoModal, setInfoModal ] = useState({})
    const [ keyRowAction, setKeyRowAction ] = useState(null)
    const [ timeFilter, setTimeFilter ] = useState("")
    const [ openModalJson, setOpenModalJson ] = useState(false)
    const [ dataModalJson, setDataModalJson ] = useState(false)
    const [ openModalDescription, setOpenModalDescription ] = useState(false)
    const [ dataModalDescription, setDataModalDescription ] = useState("[]")
    const [ selectedRowKeys, setSelectedRowKeys ] = useState([])

    const dispatch = useDispatch()
    const { confirm } = Modal

    const {
		rex_data_modulos_dashboards_total,
    } = useSelector(({dashboards}) => dashboards)

    const {
		rex_audits_users,
        rex_types_audits,
        rex_types_users_audits
    } = useSelector(({audits}) => audits)


    const { 
        rex_users_conected_dashboard,
    } = useSelector(({registerUsers}) => registerUsers)

    const { 
        rex_data_users_online
    } = useSelector(({users}) => users)

    useEffect(() => {
        dispatch(GetAuditsUserReducer())
    }, [])

    const getColumnSearchProps = (dataIndex, nameColumn) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
            <div
                style={{
                    padding: 8,
                }}
                onKeyDown={(e) => e.stopPropagation()}
            >
                <Input.Search
                    placeholder={`Filtrar ${nameColumn}`}
                    value={selectedKeys[0]}
                    onChange={(e) => {
                        setSelectedKeys(e.target.value ? [e.target.value] : [])
                        confirm({
                            closeDropdown: false,
                        })
                    }}
                    style={{
                        display: 'block',
                    }}
                />
            </div>
        ),
        filterIcon: () => (<FilterOutlined/>),
        onFilter: (value, record) => {
            return record[dataIndex] != null ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : false
        }
    })

    const columns = [
        {
            title: 'Item',
            dataIndex: 'item',
            align: 'center',
            ellipsis : true,
            fixed: 'left',
            render: (text, record, index) => (pageTableGlobal - 1) * pageSizeTableGlobal + index + 1,
            width : "4%"
        },
        Table.SELECTION_COLUMN,
        {
            title: 'Nombre completo',
            dataIndex: 'pernombrecompleto',
            fixed: 'left',
            ...getColumnSearchProps('pernombrecompleto', 'nombre del usuario'),
            sorter: (a, b) => a.pernombrecompleto ? a.pernombrecompleto.localeCompare(b.pernombrecompleto) : false,
            showSorterTooltip: false,
            render:(_,record) => (
                <div className='Simple-Table-Contain-Text-Global'>
                    {record.pernombrecompleto}
                </div>
            ),
            ellipsis: true,
            width : '10%'
        },
        {
            title: 'Usuario',
            dataIndex: 'usucorreo',
            align: 'center',
            ellipsis: true,
            width : '10%',
            ...getColumnSearchProps('usucorreo', 'correo del usuario'),
            sorter: (a, b) => a.usucorreo ? a.usucorreo.localeCompare(b.usucorreo) : false,
            showSorterTooltip: false,
            render:(_, record) => (
                <div className='Simple-Table-Contain-Text-Global'>
                    {record.usucorreo ? record.usucorreo : ''}
                </div>
            )
        },
        {
            title: 'Tipo de perfil',
            dataIndex: 'tpunombre',
            align: 'center',
            ellipsis : true,
            width : '8%',
            sorter: (a, b) => a.tpunombre ? a.tpunombre.localeCompare(b.tpunombre) : false,
            showSorterTooltip: false,
            render:(_, record) => (
                <div className='Simple-Table-Contain-Text-Global'>
                    {record.tpunombre}
                </div>
            ),
            filters: rex_types_users_audits,
            onFilter: (value, record) => record.tpunombre == value,
        },
        {
            title: 'Módulo',
            dataIndex: 'audmodulo',
            align:'center',
            ellipsis : true,
            width : '9%',
            sorter: (a, b) => a.audmodulo ? a.audmodulo.localeCompare(b.audmodulo) : false,
            showSorterTooltip: false,
            render:(_,record) => (
                <div className='Simple-Table-Contain-Text-Global'>
                    {record.audmodulo}
                </div>
            ),
            filters: rex_types_audits,
            onFilter: (value, record) => record.audmodulo == value,
        },
        {
            title: 'Ruta',
            dataIndex: 'audruta',
            align:'center',
            ellipsis : true,
            ...getColumnSearchProps('audruta', 'ruta'),
            sorter: (a, b) => a.audruta ? a.audruta.localeCompare(b.audruta) : false,
            showSorterTooltip: false,
            render:(_,record) => (
                <div className='Simple-Table-Contain-Text-Global'>
                    {record.audruta}
                </div>
            ),
            width : '7%'
        },
        {
            title: 'Acción',
            dataIndex: 'audaccion',
            align: 'center',
            ellipsis : true,
            ...getColumnSearchProps('audaccion', 'acción'),
            sorter: (a, b) => a.audaccion ? a.audaccion.localeCompare(b.audaccion) : false,
            showSorterTooltip: false,
            render:(_, record) => (
                <div className='Simple-Table-Contain-Text-Global'>
                    {record.audaccion}
                </div>
            ),
            width : '8%'
        },
        {
            title: 'Descripción',
            dataIndex: 'auddescripcion',
            align: 'center',
            ellipsis : true,
            width : '10%',
            ...getColumnSearchProps('auddescripcion', 'descripción'),
            sorter: (a, b) => a.auddescripcion ? a.auddescripcion.localeCompare(b.auddescripcion) : false,
            showSorterTooltip: false,
            render:(_, record) => {
                let msg_description = record.auddescripcion.substring(1, record.auddescripcion.length - 1)
                return <div 
                    className='Simple-Table-Contain-Text-Global'
                    onClick={() => {
                        setOpenModalDescription(true)
                        setDataModalDescription(record.auddescripcion)
                    }}
                >
                    {msg_description}
                </div>
            }
        },
        {
            title: 'Entrada',
            dataIndex: 'audjsonentrada',
            align: 'center',
            ellipsis : true,
            width:'9%',
            ...getColumnSearchProps('audjsonentrada', 'datos entrada'),
            sorter: (a, b) => a.audjsonentrada ? a.audjsonentrada.localeCompare(b.audjsonentrada) : false,
            showSorterTooltip: false,
            render:(_, record) => (
                <div
                title={record.audjsonentrada} className='Simple-Table-Contain-Text-Global'
                onClick={() => {
                    setOpenModalJson(true)
                    setDataModalJson(record.audjsonentrada)
                }}
            >
                {record.audjsonsalida}
            </div>
            ),
        },
        {
            title: 'Salida',
            dataIndex: 'audjsonsalida',
            align: 'center',
            ellipsis : true,
            width : '9%',
            ...getColumnSearchProps('audjsonsalida', 'datos salida'),
            sorter: (a, b) => a.audjsonsalida ? a.audjsonsalida.localeCompare(b.audjsonsalida) : false,
            showSorterTooltip: false,
            render:(_, record) => (
                <div
                    className='Simple-Table-Contain-Text-Global'
                    onClick={() => {
                        setOpenModalJson(true)
                        setDataModalJson(record.audjsonsalida)
                    }}
                >
                    {record.audjsonsalida}
                </div>
            )
        },
        {
            title: 'Estado',
            dataIndex: 'audestado',
            align: 'center',
            ellipsis : true,
            width : '4%',
            sorter: (a, b) => a.audestado? a.audestado - b.audestado : false,
            showSorterTooltip: false,
            render:(_, record) => (
                <div>
                    <Tooltip
                        title={`${record.audestado ? "Exito" : "Error"}`}
                        placement='bottom'
                        color='#013A81'
                        overlayStyle={{marginTop:'20px'}}
                        showArrow={false}
                        overlayInnerStyle={{
                            fontSize: "12px",
                            minHeight: "26px",
                            display:"flex",
                            alignItems:"center",
                            height:"26px"
                        }}
                    >
                        {record.audestado 
                            ? <CheckCircleOutlined style={{ color:'#008000' }}/>
                            : <ExclamationCircleOutlined style={{ color:'#ff0000' }}/>
                        }
                    </Tooltip>
                </div>
            ),
            filters: [
                {
                    text: 'Exito',
                    value: true,
                },
                {
                    text: 'Error',
                    value: false,
                },
            ],
            onFilter: (value, record) => record.audestado == value,
            width:'100px'
        },
        {
            title: 'Fecha y hora',
            dataIndex: 'created_at',
            align: 'center',
            ellipsis : true,
            width : '8%',
            sorter: (a, b) => a.audid? a.audid - b.audid : false,
            showSorterTooltip: false,
            render: (_, record) => (
                <div className='Table-Contain-Text-Global'>
                    {record.created_at ? moment(record.created_at).format('DD/MM/YYYY HH:mma') : ''}
                </div>
            )
        },
    ]

    const deleteAudits = ( id, resetValues ) => {

        if(id.length > 0){
            confirm({
                title   : <span>Confirmar</span>,
                // title   : `¿Está seguro que desea eliminar ${id.length > 1 ? 'las auditorías selecionadas': 'la auditoría'}?`,
                icon    : null,
                content : `¿Está seguro que desea eliminar ${id.length > 1 ? 'las auditorías selecionadas': 'la auditoría'}?. Esta acción es irreversible`,
                className :"Modal-Confirm-Data-Control",
                zIndex  : 100000000,
                async onOk(){
                    let response = await dispatch(DeleteAuditsReducer(id))
                    if(response){
                        resetValues([])
                        setShowModal(true)
                        setInfoModal({
                            ...infoModal, 
                            title : "¡Listo!", 
                            description : `${selectedRowKeys.length > 1 ? "Las auditorías fueron eliminadas" :  "La auditoría fue eliminada"} con éxito`,
                            status : true,
                            small: true
                        })
                    }
                },
                okCancel(){}
            })
        }
    }

    const onSelectChange = (newSelectedRowKeys) => {
        setSelectedRowKeys(newSelectedRowKeys);
    };

    const toggleSelectAll = (valueCheckbox) => {
        setSelectedRowKeys((keys) =>
        valueCheckbox
            ? rex_audits_users?.length > 0 ? rex_audits_users.map((r) => r.key) : rex_audits_users.map((r) => r.key) 
            : []
        )
    }

    const rowSelection = {
        columnTitle: true ? <ProfileTypesFilterSelect
                                    showDropDown={true}
                                    toggleSelectAll={toggleSelectAll}
                                    selectedRowKeys={null}
                                    dataAmount={rex_audits_users.length}
                                    setSelectedRowKeys={setSelectedRowKeys}
                                    data={rex_audits_users}
                                    valueCheckbox={selectedRowKeys.length == rex_audits_users.length}
                                    setValueCheckbox={() => console.log("ok")}
                                    actInacChechBox={false}
                                    themeColor='green'
        /> : <></>,
        selectedRowKeys,
        onChange: onSelectChange,
        type: "checkbox",
        fixed: true,
        onSelect: () => console.log("oko"),
    }

    const statusModal = () => {
        setShowModal(false)
    }

    return (
        <div className='Container-Main'>
            <TitleSearchGlobal
                setTxtFilter={setTxtFilter}
                showInputSearch={false}
                funtionFilter={(valueSelected, type) => dispatch(FilterAuditsReducer(modFilter, timeFilter, valueSelected ))}
                title='Control de auditorias'
            />
            <Row className='Users-Container-Actions'>
                <div style={{display:"flex", alignItems: "center", columnGap: "12px"}}>
                    <FilterSelectGlobal
                        data={rex_types_audits}
                        filterBy='tiempo'
                        setTypFilter={setTimeFilter}
                        funtionFilter={(valueSelected, type) => {
                            dispatch(FilterAuditsReducer( modFilter, valueSelected, txtFilter))
                        }}
                    />
                    <FilterSelectGlobal
                        data={rex_types_audits}
                        filterBy='módulo'
                        setTypFilter={setModFilter}
                        funtionFilter={(valueSelected, type) => {
                            dispatch(FilterAuditsReducer( valueSelected, timeFilter, txtFilter))
                        }}
                    />
                    <div style={{display:"flex", alignItems: "center", columnGap: "12px"}}>
                        <TrashButtonGlobal
                            color='#013A81'
                            optionHover={true}
                            functionDelete={() => deleteAudits(selectedRowKeys, setSelectedRowKeys)}
                        />
                    </div>
                </div>
                <Pagination
                    total={rex_audits_users.length}
                    showTotal={(total, range) => `${range[0]}-${range[1]} de ${rex_audits_users.length}`}
                    defaultPageSize={pageSizeTableGlobal}
                    defaultCurrent={pageTableGlobal}
                    current={pageTableGlobal}
                    className='Simple-Table-Pagination-Global'
                    onChange={(page, pageSize) => {
                        setPageTableGlobal(page == 0? 1 : page)
                        setPageSizeTableGlobal(pageSize)
                    }}
                />
            </Row>
            <SimpleTableGlobal
                data={rex_audits_users}
                keyRowAction={keyRowAction}
                rowSelection={rowSelection}
                columns={columns}
                pageTableGlobal={pageTableGlobal}
                pageSizeTableGlobal={pageSizeTableGlobal}
                actionEnterRow={setMouseOverRow}
                actionLeaveRow={() => console.log("ok")}
            />
            <Modal
                open={showModal}
                title={<span className={`${infoModal.status ? "Text-Ok" : "Text-Alert"}`}>{infoModal.title}</span>}
                width={300}
                zIndex={100000000}
                className={`Modal-Create-Data-Control ${infoModal.small && 'Modal-Small'}`}
                footer={[
                    <div style={{display:"flex", justifyContent:"center"}}>
                        <div className={`Button-Close Button${infoModal.status ? "-Ok" : "-Alert"}`} onClick={() => statusModal()}>
                            Ok
                        </div>
                    </div>
                ]}
            >
                <div className='Modal-Description'>
                    {infoModal.description}
                </div>
            </Modal>
            <ModalDetailJsonTable
                setOpenModalJson={setOpenModalJson}
                dataModalJson={dataModalJson}
                openModalJson={openModalJson}
            />
            <ModalDetailDescriptionTable
                setOpenModalDescription={setOpenModalDescription}
                dataModalDescription={dataModalDescription}
                openModalDescription={openModalDescription}
            />

            <SideMenu
                usersCurrentView  = {rex_users_conected_dashboard}
                usersAllConnected ={rex_data_users_online}
            />
        </div>
    )
}

export default UserAudit