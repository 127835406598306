export const GET_DATA_INCENTIV_PROGRAM = 'GET_DATA_INCENTIV_PROGRAM'
export const GET_DATA_INCENTIV_PROGRAM_CLONE = 'GET_DATA_INCENTIV_PROGRAM_CLONE'
export const GET_DATA_LAST_UPDATE_INCENTIV_PROGRAM = 'GET_DATA_LAST_UPDATE_INCENTIV_PROGRAM'
export const GET_DATA_INCENTIV_PROGRAM_EDIT = 'GET_DATA_INCENTIV_PROGRAM_EDIT'
export const GET_DATA_FILTERS_TXT_INCENTIV_PROGRAM_TOTAL = 'GET_DATA_FILTERS_TXT_INCENTIV_PROGRAM_TOTAL'
export const GET_DATA_FILTERS_GBA = 'GET_DATA_FILTERS_GBA'
export const GET_DATA_FILTERS_CHANNEL = 'GET_DATA_FILTERS_CHANNEL'
export const GET_DATA_FILTERS_ZONE = 'GET_DATA_FILTERS_ZONE'
export const GET_DATA_FILTERS_CLIENT = 'GET_DATA_FILTERS_CLIENT'
export const GET_DATA_FILTERS_INCENTIVES = 'GET_DATA_FILTERS_INCENTIVES'
