import config from '../../../config'
import {
    BOTONES_FILTRO,
    OBTENER_DATA_FORMATO,
    OBTENER_DATA_SKU,
    OBTENER_DATA_DESCRIPCION_MATERIAL,
    OBTENER_DATA_CONTEO,
    OBTENER_DATA_TALLA,
    OBTENER_DATA_ANIOS,
    OBTENER_DATA_MESES,
    OBTENER_DATA_CLIENTE_HML,
    SELECCIONADO_DATA_FORMATO,
    SELECCIONADO_DATA_TALLA,
    SELECCIONADO_DATA_CONTEO,
    SELECCIONADO_DATA_SKU,
    SELECCIONADO_DATA_DESCRIPCION_MATERIAL,
    SELECCIONADO_DATA_CLIENTE_HML,
    OBTENER_TYPE_DATA_DAILY
} from '../../../Constants/PowerBi/Filtros'
import {
    OBTENER_LISTA_DATA_DASHBOARD,
    DEFAULT_EXPANDED_DATA,
    OBTENER_LISTA_DATA_COPIA_DASHBOARD,
    OBTENER_LISTA_TOTAL_DATA_DASHBOARD,
    CARGANDO_LISTA_DATA,
    TOTAL_QUERY_DATA,
    OBTENIENDO_FECHAS_ALL,
} from '../../../Constants/PowerBi/PowerBi'
import {
    SELECCIONAR_FILTRO_DAILY_ANIO,
    SELECCIONAR_FILTRO_DAILY_MES,
    SELECCIONAR_FILTRO_DAILY_COD_MAT,
    SELECCIONAR_FILTRO_DAILY_HML_MAT,
    OBTENER_DATA_DAILY_GRAFICO,
    SELECCIONAR_FILTRO_DAILY_HML_CLI,
    SELECCIONAR_FILTRO_DAILY_TALLA,
    SELECCIONAR_FILTRO_DAILY_CONTEO,
    SELECCIONAR_FILTRO_DAILY_FORMATO,
    CARGANDO_DATA_DAILY
} from '../../../Constants/PowerBi/Daily'
import { GetDataDailyChartReducer } from './PowerBiDaily'
import { ObtenerDataFirstTablaReducer, ObtenerDataSecondTablaReducer, ObtenerFechaPowerBiReducer } from './PowerBi'
import { notification } from 'antd'

const notificacionServidor = (type, mensaje) => {
    if(type === 'success'){
        notification['success']({
            message: 'Éxito',
            description: mensaje,
        })
    }else if(type === 'warning'){
        notification['warning']({
            message: 'Advertencia',
            description: mensaje,
        })
    }else if(type === 'error'){
        notification['error']({
            message: 'Error',
            description: mensaje,
        })
    }
}

export const ObtenerDataFiltrosPowerBiReducer = (smoid) => async ( dispatch, getState ) => {

    dispatch({
        type: CARGANDO_LISTA_DATA,
        payload : true
    })

    dispatch({
        type: CARGANDO_DATA_DAILY,
        payload: true
    })

    let headerFetch = {
        'Accept' : 'application/json',
        'Content-type' : 'application/json'
    }

    let rex_obteniendo_fechas_all  = getState().powerBi.rex_obteniendo_fechas_all

    if(rex_obteniendo_fechas_all == true){
        dispatch({
            type: OBTENIENDO_FECHAS_ALL,
            payload : false
        })
        await fetch(config.api_url_powerbi+'dashboard/filters/all',
            {
                mode    : 'cors',
                method  : 'POST',
                headers : headerFetch   
            }
        )
        .then(async res => {
            return res.json()
        })
        .then(async data => {
            // const estadoRequest = getState().estadoRequest.init_request
            // if(estadoRequest === true){
                
                const data_original = JSON.parse(JSON.stringify(data))

                dispatch({
                    type: OBTENER_DATA_ANIOS,
                    payload : data.year
                })
                dispatch({
                    type: OBTENER_DATA_MESES,
                    payload : data.month
                })
                dispatch({
                    type: OBTENER_DATA_CLIENTE_HML,
                    payload : data.client_hml
                })
                dispatch({
                    type: OBTENER_DATA_FORMATO,
                    payload : {
                        data: data.formato,
                        original : data_original.formato
                    }
                })
                dispatch({
                    type: OBTENER_DATA_SKU,
                    payload : {
                        data: data.hml_cod_material,
                        original : data_original.hml_cod_material
                    }
                })
                dispatch({
                    type: OBTENER_DATA_DESCRIPCION_MATERIAL,
                    payload : {
                        data: data.hml_material,
                        original : data_original.hml_material
                    }
                })
                dispatch({
                    type: OBTENER_DATA_TALLA,
                    payload : {
                        data: data.attr_2,
                        original : data_original.attr_2
                    }
                })
                dispatch({
                    type: OBTENER_DATA_CONTEO,
                    payload : {
                        data: data.attr_1,
                        original : data_original.attr_1
                    }
                })

                dispatch({
                    type: TOTAL_QUERY_DATA,
                    payload: 0
                })
                await dispatch(ObtenerFechaPowerBiReducer())
                let validacion = getState().powerBi.rex_mes_validator
                if(validacion){
                    dispatch(ObtenerDataFirstTablaReducer(null, smoid))
                    dispatch(ObtenerDataSecondTablaReducer(null))
                }else{
                    dispatch(ObtenerDataFirstTablaReducer(null, smoid))
                }
                dispatch(GetDataDailyChartReducer())
                
            // }else{
            //     notificacionServidor('error', data.message)
            // }
        })
        .catch((error)=> {
            notificacionServidor('error', 'Error con el servidor. Vuelva a ingresar más tarde.')
        })
    }
}

export const FiltroBotonDataReducer = (nombre, estado) => async (dispatch, getState) => {
    let botonesFiltro = getState().filtros.rex_botones_filtro

    botonesFiltro.map((b, index) => {
        if(b.nombre == nombre){
            b['estado'] = estado
        }
    })

    dispatch({
        type: BOTONES_FILTRO,
        payload: botonesFiltro,
    })
}

export const SeleccionarValorFiltroReducer = (data, tipo) => (dispatch, getState) => {
    const filtros = {
        'SKU': {type: SELECCIONADO_DATA_SKU},
        'Descripción Material': {type: SELECCIONADO_DATA_DESCRIPCION_MATERIAL},
        'Cliente HML': {type: SELECCIONADO_DATA_CLIENTE_HML},
        'Conteo': {type: SELECCIONADO_DATA_CONTEO},
        'Talla': {type: SELECCIONADO_DATA_TALLA},
        'Formato': {type: SELECCIONADO_DATA_FORMATO},
    }
      
    const filtroAccion = filtros[tipo];
    if (filtroAccion) {
        dispatch({
            ...filtroAccion,
            payload: data,
        })
    }
}

export const FiltroTableroDinamicoDataReducer = () => async ( dispatch, getState ) => {

    let botonesFiltro       = getState().filtros.rex_botones_filtro
    let data_simple_powerbi = getState().powerBi.rex_lista_data_copia_dashboard
    let rex_tipo_visualizar_bi = getState().powerBi.rex_tipo_visualizar_bi
    let rex_lista_total_data_dashboard  = getState().powerBi.rex_lista_total_data_dashboard

    let headerFetch = {
        'Accept' : 'application/json',
        'content-type': 'application/json',
    }
    
    await fetch(config.api_url_powerbi+'dashboards/get-data-filters',
		{
            mode:'cors',
			method: 'POST',
			headers: headerFetch,
            body: JSON.stringify({
                're_type'  : rex_tipo_visualizar_bi,
                're_data'  : data_simple_powerbi,
                're_total' : rex_lista_total_data_dashboard,
                're_filterBy' : botonesFiltro
            }),
      	}
    )
    .then(async res => {
		return res.json()
    })
    .then(async data => {
		// const estadoRequest = getState().estadoRequest.init_request
		// if(estadoRequest === true){
            dispatch({
                type: DEFAULT_EXPANDED_DATA,
                payload : data.dataFinal.map(lista => lista.key)
            })
            dispatch({
                type: OBTENER_LISTA_DATA_DASHBOARD,
                payload: [...data.dataFinal]
            })

            dispatch({
                type: OBTENER_LISTA_TOTAL_DATA_DASHBOARD,
                payload : data.dataTotalSellOut
            })
            dispatch({
                type: OBTENER_LISTA_DATA_COPIA_DASHBOARD,
                payload : [...data_simple_powerbi]
            })
		// }else{
        //     notificacionServidor('error', data.message)
        // }
    })
    .catch((error)=> {
        notificacionServidor('error', 'Error con el servidor. Vuelva a ingresar más tarde.')
    })

}

export const FiltroTipoDataTablaReducer = ( nombre ) => async ( dispatch, getState ) => {

    dispatch({
        type: "GUARDAR_TIPO_VISUALIZAR_BI",
        payload : nombre
    })

    let rex_lista_total_data_dashboard  = getState().powerBi.rex_lista_total_data_dashboard
    let botonesFiltro  = getState().filtros.rex_botones_filtro
    let data_simple_powerbi = getState().powerBi.rex_lista_data_copia_dashboard

    let headerFetch = {
        'Accept' : 'application/json',
        'Content-type' : 'application/json'
    }
    
    await fetch(config.api_url_powerbi+'dashboards/get-data-filters',
		{
            mode:'cors',
			method: 'POST',
			headers: headerFetch,
            body: JSON.stringify({
                're_type'  : nombre,
                're_data'  : data_simple_powerbi,
                're_total' : rex_lista_total_data_dashboard,
                're_filterBy' : botonesFiltro
            }),
      	}
    )
    .then(async res => {
		return res.json()
    })
    .then(async data => {
		// const estadoRequest = getState().estadoRequest.init_request
		// if(estadoRequest === true){
            dispatch({
                type: DEFAULT_EXPANDED_DATA,
                payload : data.dataFinal.map(lista => lista.key)
            })
            dispatch({
                type: OBTENER_LISTA_DATA_DASHBOARD,
                payload : [...data.dataFinal]
            })
            dispatch({
                type: OBTENER_LISTA_TOTAL_DATA_DASHBOARD,
                payload : data.dataTotalSellOut
            })
            dispatch({
                type: OBTENER_LISTA_DATA_COPIA_DASHBOARD,
                payload : [...data_simple_powerbi]
            })

		// }else{
        //     notificacionServidor('error', data.message)
        // }
    })
    .catch((error)=> {
        notificacionServidor('error', 'Error con el servidor. Vuelva a ingresar más tarde.')
    })

}

export const FiltroTipoDataReducer = ( nombre ) => async (dispatch, getState) => {

    let dataDaily = getState().daily.rex_data_daily_grafico_copy

    dispatch({
        type: OBTENER_TYPE_DATA_DAILY,
        payload: nombre
    })

    dispatch({
        type: 'CARGANDO_DATA_DAILY',
        payload: true
    })

    let headerFetch = {
        'Accept' : 'application/json',
        'Content-type' : 'application/json'
    }
    
    await fetch(config.api_url_powerbi+'dashboards/daily/type',
		{
            mode:'cors',
			method: 'POST',
			headers: headerFetch,
            body: JSON.stringify({
                'req_type'  : nombre,
                'req_data'  : dataDaily,
            }),
      	}
    )
    .then(async res => {
		return res.json()
    })
    .then(async data => {
		// const estadoRequest = getState().estadoRequest.init_request
		// if(estadoRequest === true){
            dispatch({
                type: OBTENER_DATA_DAILY_GRAFICO,
                payload : data.data
            })
		// }else{
        //     notificacionServidor('error', data.message)
        // }
    })
    .catch((error)=> {
        notificacionServidor('error', 'Error con el servidor. Vuelva a ingresar más tarde.')
    })

    dispatch({
        type: 'CARGANDO_DATA_DAILY',
        payload: false
    })
}

export const SelectFilterDailyReducer = ( value , tipo ) => async ( dispatch, getState ) => {
    if(tipo == 'Año'){
        dispatch({
            type: SELECCIONAR_FILTRO_DAILY_ANIO,
            payload: value
        })
    }else if(tipo == 'Mes'){
        dispatch({
            type: SELECCIONAR_FILTRO_DAILY_MES,
            payload: value
        })
    }else if(tipo == 'SKU'){
        dispatch({
            type: SELECCIONAR_FILTRO_DAILY_COD_MAT,
            payload: value
        })
    }else if(tipo == 'Descripción Material'){
        dispatch({
            type: SELECCIONAR_FILTRO_DAILY_HML_MAT,
            payload: value
        })
    }else if(tipo == 'Cliente HML'){
        dispatch({
            type: SELECCIONAR_FILTRO_DAILY_HML_CLI,
            payload: value
        })
    }else if(tipo == 'Conteo'){
        dispatch({
            type: SELECCIONAR_FILTRO_DAILY_CONTEO,
            payload: value
        })
        dispatch(CambiarFiltrosAplicadosReducer(tipo, value))
    }else if(tipo == 'Talla'){
        dispatch({
            type: SELECCIONAR_FILTRO_DAILY_TALLA,
            payload: value
        })
        dispatch(CambiarFiltrosAplicadosReducer(tipo, value))
    }else if(tipo == 'Formato'){
        dispatch({
            type: SELECCIONAR_FILTRO_DAILY_FORMATO,
            payload: value
        })
        dispatch(CambiarFiltrosAplicadosReducer(tipo, value))
    }
}

export const CambiarFiltrosAplicadosReducer = (type_filter, value_filter) => async ( dispatch, getState ) => {

    let headerFetch = {
        'Accept' : 'application/json',
        'Content-type' : 'application/json'
    }

    const {
        rex_data_formato_original,
        rex_data_talla_original,
        rex_data_conteo_original,
        rex_data_sku_original,
        rex_data_descripcion_material_original,
        rex_data_formato_seleccionado,
        rex_data_talla_seleccionado,
        rex_data_conteo_seleccionado,
        rex_data_sku_seleccionado,
        rex_data_descripcion_material_seleccionado,
    } = getState().filtros

    const {
        rex_select_daily_conteo,
        rex_select_daily_talla,
        rex_select_daily_formato
    }= getState().daily

    const select_daily_formato = rex_select_daily_formato !== false 
        ?   rex_data_formato_seleccionado.length == 0 && rex_data_talla_seleccionado.length == 0 && rex_data_conteo_seleccionado.length == 0
            ? rex_data_formato_original
            : rex_select_daily_formato[0].length == 0 
                ? false : rex_select_daily_formato[0]
        : rex_select_daily_formato
    const select_daily_talla = rex_select_daily_talla !== false 
        ?   rex_data_formato_seleccionado.length == 0 && rex_data_talla_seleccionado.length == 0 && rex_data_conteo_seleccionado.length == 0
            ? rex_data_talla_original
            : rex_select_daily_talla[0].length == 0 
                ? false : rex_select_daily_talla[0]
        : rex_select_daily_talla
    const select_daily_conteo = rex_select_daily_conteo !== false 
        ?   rex_data_formato_seleccionado.length == 0 && rex_data_talla_seleccionado.length == 0 && rex_data_conteo_seleccionado.length == 0
            ? rex_data_conteo_original
            : rex_select_daily_conteo[0].length == 0 
                ? false : rex_select_daily_conteo[0]
        : rex_select_daily_conteo

    await fetch(config.api_url_powerbi+'dashboard/filters/change',
        {
            mode    : 'cors',
            method  : 'POST',
            headers : headerFetch,
            body: JSON.stringify({
                re_formato : rex_data_formato_original,
                re_talla   : rex_data_talla_original,
                re_conteo  : rex_data_conteo_original,
                re_sku     : rex_data_sku_original,
                re_descripcion_material : rex_data_descripcion_material_original,
                filter_applied_formato  : select_daily_formato,
                filter_applied_talla    : select_daily_talla,
                filter_applied_conteo   : select_daily_conteo,
            }),
        }
    )
    .then(async res => {
        return res.json()
    })
    .then(async data => {
        // const estadoRequest = getState().estadoRequest.init_request
        // if(estadoRequest === true){
            if(type_filter != 'Formato'){
                dispatch({
                    type: OBTENER_DATA_FORMATO,
                    payload : {
                        data: data.formato.length == 0 ? rex_data_formato_original : data.formato,
                        original : rex_data_formato_original
                    }
                })
                const dataSeleccionada = data.formato.length == 0 
                    ? rex_select_daily_formato[0]
                    : data.formato.filter(form => {
                        return rex_data_formato_seleccionado.some(fs => fs.key == form.key)
                    })
                dispatch({
                    type: SELECCIONADO_DATA_FORMATO,
                    payload : dataSeleccionada
                })
            }
            if(type_filter != 'SKU'){
                dispatch({
                    type: OBTENER_DATA_SKU,
                    payload : {
                        data: data.hml_cod_material,
                        original : rex_data_sku_original
                    }
                }) 
                const dataSeleccionada = data.hml_cod_material.filter(form => {
                    return rex_data_sku_seleccionado.some(fs => fs.key == form.key)
                })
                dispatch({
                    type: SELECCIONADO_DATA_SKU,
                    payload : dataSeleccionada
                })
            }
            if(type_filter != 'Descripción Material'){
                dispatch({
                    type: OBTENER_DATA_DESCRIPCION_MATERIAL,
                    payload : {
                        data: data.hml_material,
                        original : rex_data_descripcion_material_original
                    }
                })
                const dataSeleccionada = data.hml_material.filter(form => {
                    return rex_data_descripcion_material_seleccionado.some(fs => fs.key == form.key)
                })
                dispatch({
                    type: SELECCIONADO_DATA_DESCRIPCION_MATERIAL,
                    payload : dataSeleccionada
                })
            }
            if(type_filter != 'Talla'){
                dispatch({
                    type: OBTENER_DATA_TALLA,
                    payload : {
                        data: data.attr_2.length == 0 ? rex_data_talla_original : data.attr_2,
                        original : rex_data_talla_original
                    }
                })
                const dataSeleccionada = data.attr_2.length == 0 
                    ? rex_select_daily_talla[0] 
                    : data.attr_2.filter(form => {
                        return rex_data_talla_seleccionado.some(fs => fs.key == form.key)
                    })
                dispatch({
                    type: SELECCIONADO_DATA_TALLA,
                    payload : dataSeleccionada
                })
            }
            if(type_filter != 'Conteo'){
                dispatch({
                    type: OBTENER_DATA_CONTEO,
                    payload : {
                        data: data.attr_1.length == 0 ? rex_data_conteo_original : data.attr_1,
                        original : rex_data_conteo_original
                    }
                })
                const dataSeleccionada = data.attr_1.length == 0 
                    ? rex_select_daily_conteo[0]
                    : data.attr_1.filter(form => {
                        return rex_data_conteo_seleccionado.some(fs => fs.key == form.key)
                    })
                dispatch({
                    type: SELECCIONADO_DATA_CONTEO,
                    payload : dataSeleccionada
                })
            }
        // }else{
        //     notificacionServidor('error', data.message)
        // }
    })
    .catch((error)=> {
        notificacionServidor('error', 'Error con el servidor. Vuelva a ingresar más tarde.')
    })

}