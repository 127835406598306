import React, { useState } from 'react'
import { Tooltip } from 'antd';
import '../../../Styles/Components/Global/DownloadButtonGlobal.css'

const DownloadButtonGlobal = ({
    color = '#3B72FF', 
    downloadFile=false,
    functionDownload
}) => {

    const [ mouseOver, setMouseOver ] = useState(false);

    return (
        <Tooltip
            placement='bottom'
            title='Descargar'
            color={color}
            showArrow={false}
            overlayClassName='Tooltip-Download-Button'
        >
            <div style={{marginLeft:'6px', zIndex:"1"}}>
                <div
                    onMouseOver={() => {
                        setMouseOver(true)
                    }}
                    onClick={() => {
                        if(!downloadFile){
                            const link = document.createElement('a')
                            link.href = 'https://backend-spider-kimberly-corporativa.grow-corporate.com/Sistema/ArchivoData/Excels/SISO YTD PERFORMANCE PERU - Cierre Julio - v_09_08_2023-DuVnN.xlsm'
                            link.click()
                        }else{
                            functionDownload()
                        }
                    }}
                    onMouseLeave={() => setMouseOver(false)}
                    style={{
                        background: mouseOver ? '#EDF2FF':'white',
                        borderRadius:'100%',
                        padding:'8px 9px',
                        cursor:'pointer',
                        display:'flex',alignItems:'center'
                    }}
                >
                    <div className={`Download-Icon ${mouseOver && 'Download-Hover'}`}></div>
                </div>
            </div>
        </Tooltip>
    )
}

export default DownloadButtonGlobal