import {
    SHOW_MODAL_NOTIFICATION_GLOBAL,
    DATA_MODAL_NOTIFICATION_GLOBAL,
    SHOW_MODAL_TERMS_CONTIDITIONS_GLOBAL
} from "../../../Constants/Global/Global"

export const ShowModalNotificationGlobalReducer = ( show, data = {title:"", messageDescription: [],  status:""} ) => async (dispatch, getState) => {

    dispatch({
        type: SHOW_MODAL_NOTIFICATION_GLOBAL,
        payload: show
    })

    dispatch({
        type: DATA_MODAL_NOTIFICATION_GLOBAL,
        payload: data
    })
}

export const ShowModalTermsConditionsGlobalReducer = ( show ) => async (dispatch, getState) => {

    dispatch({
        type: SHOW_MODAL_TERMS_CONTIDITIONS_GLOBAL,
        payload: show
    })

    return true
}