import React from 'react'
import { useDispatch } from 'react-redux'
import { DndContext, closestCenter } from '@dnd-kit/core'
import { arrayMove, SortableContext, verticalListSortingStrategy } from '@dnd-kit/sortable'
import ListItemsSubModules from './ListItemsSubModules'
import { ChangeOrderSubmodulesReducer } from '../../../Redux/Actions/Modules/CreateEditModules'

const ListSubModules = (props) => {
    const submodulos = props.submodulos
    const dispatch = useDispatch()

    const handleDragEnd = (event) => {
        const { active, over } = event
        dispatch(ChangeOrderSubmodulesReducer(active, over, arrayMove))
    }

    return(
        <>
            <DndContext
                collisionDetection={closestCenter}
                onDragEnd={handleDragEnd}
            >
                <SortableContext
                    items={submodulos.map(sub => sub.key)}
                    strategy={verticalListSortingStrategy}
                >
                    {
                        submodulos.map(sub => 
                            <ListItemsSubModules 
                                id={sub.key} 
                                submodulo={sub}
                            />
                        )
                    }
                </SortableContext>
            </DndContext>
        </>
    )
}

export default ListSubModules