import config from '../../../config'
import notificationGlobal from '../../../Components/Global/NotificationGlobal';
import {DATA_PERMISSIONS_USER, SAVE_DATA_USER_LOGIN} from '../../../Constants/LoginGrow/LoginGrow'
import socket from '../../../App/socket'
import {LogOutReducer} from '../LogOut/LogOut'

export const ValidationUserReducer = () => async ( dispatch, getState) => {
    
    let respuesta = false
    await fetch(config.api+'auth/validation-user',
        {
            mode:'cors',
            method: 'POST',
            headers: {
                'Accept' 	   : 'application/json',
                'Content-type' : 'application/json',
                'usutoken'	   : localStorage.getItem('usutoken'),
            }
        })
    .then(res => res.json())
    .then(async data => {
        if(data.response){
            respuesta = true
            
            socket.emit('reg-login', data.usu);
            dispatch({
                type: SAVE_DATA_USER_LOGIN,
                payload : data.usu
            })

            dispatch({
                type    : DATA_PERMISSIONS_USER,
                payload : data.usu.permisos
            })

            // const urlAudio = await dispatch(GetAudioGreetingReducer())
            // const newAudio = new Audio(urlAudio)
            // newAudio.play()

            // dispatch(GetDataModulesHomeReducer())
        }else{
            localStorage.removeItem("usutoken")
            localStorage.removeItem("usupais")
            localStorage.removeItem("usuusuario")
            notificationGlobal('error', data.message)
            await dispatch(LogOutReducer())
            // dispatch(RegisterAuditReducer('validation-user', 'Error al validar al usuario', data.message, localStorage.getItem('usutoken'), data, user? user.usuid : null, 'ip', 'VALIDATE USER'))
        }
    }).catch((error)=> {
        notificationGlobal('warning', 'Ha ocurrido un error en el servidor')
        console.log(error)
    })

    if(localStorage.getItem('presentation')){
        setTimeout(() => {
            localStorage.removeItem('presentation')
        }, 4000)
    }else{
        setTimeout(() => {
            localStorage.setItem('presentation', true)
        }, 4000)
    }

    return respuesta
}