import React from 'react'
import Modal from 'antd/es/modal/Modal'
import '../../Styles/Components/FileUpload/ModalItemNotification.css'
import { Typography, Dropdown, Button } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import CampanaColor from '../../Assets/Images/UploadFile/IconNotification.gif'
import { DeleteItemNotificationReducer, SelectItemNotificationModalFileUploadReducer, ShowNotificationModalFileUploadReducer } from '../../Redux/Actions/FileUpload/FileUpload'
import ThreePunts from '../../Assets/Images/FileUpload/ThreePunts.png'
import DeleteNotification from '../../Assets/Images/Modules/DeleteSkyBlue.png'

const ModalItemNotification = () => {

    const dispatch = useDispatch()

    const { Title } = Typography

    const {
        rex_messages_notifications_upload_files,
        rex_show_notification_modal_upload_file,
        rex_item_notification_modal_upload_file
    } = useSelector(({fileUpload}) => fileUpload);

    const items = [
        {
            key: 1,
            label:  <span 
                        onClick={() => {
                            // setKeyRemove(props.index)
                            dispatch(DeleteItemNotificationReducer(rex_item_notification_modal_upload_file.item))
                        }}
                        className="Delete-Notification-File"
                    >
                        Eliminar notificación 
                        <img src={DeleteNotification} width={14} height={14} />
                    </span>,
        },
    ]

    return (
        <Modal
            title={null}
            centered={true}
            open={rex_show_notification_modal_upload_file}
            width={550}
            mask={true}
            maskClosable={true}
            closeIcon={<></>}
            closable={true}
            onCancel={() => dispatch(ShowNotificationModalFileUploadReducer(false, {}))}
            footer={null}
            bodyStyle={{maxHeight:'80vh'}}
            zIndex={100000000}
            className="Modal-Item-Notification-Upload-File"
        >
            <div className="Container-Header-Notification-Upload-File">
                <div 
                    className={`Button-Action-Notification Select-Prev ${rex_item_notification_modal_upload_file.item == rex_messages_notifications_upload_files.length && "Disable-Action"}`}
                    onClick={() => {
                        if(rex_item_notification_modal_upload_file.item != rex_messages_notifications_upload_files.length){
                            dispatch(SelectItemNotificationModalFileUploadReducer(rex_item_notification_modal_upload_file.item, false))
                        }
                    }}
                >
                    <div>
                        <div className="Container-Icon"></div>
                    </div>
                </div>

                <div className="Container-Header-Title-Notification">
                    <div className="Container-Image-Header">
                        <img src={CampanaColor}/>
                    </div>
                    <Title
                        className="Title-Notificacion-File"
                        level={3}
                    >
                        Notificaciones
                    </Title>

                </div>

                <div 
                    className={`Button-Action-Notification Select-Next ${rex_item_notification_modal_upload_file.item == 1 && "Disable-Action"}`}
                    onClick={() => {
                        if(rex_item_notification_modal_upload_file.item != 1){
                            dispatch(SelectItemNotificationModalFileUploadReducer(rex_item_notification_modal_upload_file.item, true))
                        }
                    }}
                >
                    <div>
                        <div className="Container-Icon"></div>
                    </div>
                </div>
            </div>
            <div className="Container-Body-Notification-Upload-File">
                <div className="Container-Notification-Action-Upload-File">
                    <ol style={{marginRight:'43px'}}>
                        {
                            rex_item_notification_modal_upload_file.data.length > 0
                            ? rex_item_notification_modal_upload_file.data.map((item, index) => (
                                <div className="Container-Text-Notification-Upload-File">
                                    <li>Se encontraron las siguientes observaciones en la columna <b>{item.columna}:</b></li>
                                    <ul className="List-Description-Error">
                                        {item.notificaciones.map(msg => (
                                            <li>{msg.msg}{item.show_lines ? ", en las siguientes líneas:" : null} <b>
                                                {item.show_lines 
                                                    ?msg.rows.map((row, pos) => <>
                                                    {
                                                        pos >= 6
                                                        ?pos == 6
                                                            ?`etc.`
                                                            :null
                                                        : pos+1 == msg.rows.length ? `${row}. ` : `${row}, `
                                                    }
                                                    </>
                                                    )
                                                : null}
                                                </b>
                                            </li>
                                        ))}
                                    </ul>
                                </div>
                              ))
                            : <div className="Container-Text-Notification-Upload-File">{rex_item_notification_modal_upload_file.mensaje}</div>
                        }
                    </ol>
                    <Dropdown
                        menu={{items}} 
                        placement="bottomRight"
                        overlayStyle={{zIndex:'1000000011'}}
                        overlayClassName="Dropdown-Menu-Notification"
                        trigger={['click']}
                    >
                        <Button 
                            className="Button-Deploy-Notification-File"
                            onClick={(e) => {
                                e.preventDefault()
                            }}
                        >
                            <img src={ThreePunts} width={16} height={16} />
                        </Button>
                    </Dropdown>
                </div>
                <span className="Container-Date-Notification-Upload-File">{rex_item_notification_modal_upload_file.date}</span>
            </div>
        </Modal>
    )
}

export default ModalItemNotification