import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate, useParams } from "react-router-dom"
import { CreateEditTypeProfileReducer, EditTypeProfileReducer, SelectedCountryProfileTypeReducer, SelectedModuleSpecificAllReducer, SelectedModulesAllReducer, SelectedSubModuleSpecificAllReducer, SelectedSubSubModuleSpecificAllReducer } from '../../Redux/Actions/ProfileTypes/ProfileTypes'
import { Col, Form, Modal, Checkbox, Collapse, Spin } from 'antd'
import { LoadingOutlined } from '@ant-design/icons'

const FormCreateProfile = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { id } = useParams()
    const { Panel } = Collapse

    const {
        rex_permissions_country,
        rex_data_modules_profile_clone,
        rex_check_all_module_country,
        rex_edit_type_profile,
        rex_selected_country_profile_type,
        rex_loading_profile_type,
        rex_name_type_profile
    } = useSelector(({profileTypes}) => profileTypes)

    const { 
        rex_data_country_clone,
    } = useSelector(({paises}) => paises)

    const antIcon = (
        <LoadingOutlined
            style={{
                fontSize: '14px',
                color: '#fff'
            }}
            spin
        />
    )

    const HeaderCollapse = (type, text, id, checkbox, modulo_id) => (
        <div className={
            `Header-Colapse-Create-Profile 
            ${type == "module" 
                ? checkbox ? 'Check-Active-Module' : '' 
                : checkbox ? 'Check-Active-Submodule' : ''}
        `}>
            <Checkbox
                onClick={(e) => {
                    e.stopPropagation()
                    if(type == "module"){
                        dispatch(SelectedModuleSpecificAllReducer(id, e.target.checked, rex_selected_country_profile_type))
                    }else{
                        dispatch(SelectedSubModuleSpecificAllReducer(id, e.target.checked, modulo_id, rex_selected_country_profile_type))
                    }
                }}
                checked={checkbox}
            ></Checkbox>
            <span className='Text-Header-Collapse-Create-Profile'>{text}</span>
        </div>
    )

    const ModalSuccess = () => {
        Modal.success({
            title: '¡Listo!',
            icon: null,
            className: 'Modal-Create-Success',
            content: id ? 'El Perfil fue editado con éxito' : 'El Nuevo Perfil fue creado con éxito',
            zIndex: 100000,
            okText: 'Ok',
            width: 254,
            centered: true,
            onOk() {
                navigate('/administrator/profile-types')
            },
        })
    }

    const ModalEditActive = () => {
        Modal.confirm({
            title: '¡Recuerda!',
            icon: null,
            className: 'Modal-Edit-Profile',
            content: 'Toda edición realizada, se aplicará a todos los usuarios que tengan este tipo de perfil',
            zIndex: 100000,
            okText: 'Continuar',
            cancelText: 'Cancelar',
            cancelButtonProps: {className: 'Cancel-Button-Modal'},
            width: 248,
            centered: true,
            onOk() {
                dispatch(EditTypeProfileReducer(false))
            },
            onCancel() {}
        })
    }

    return (
        <Col
            className='Create-Profile-Container-Right'
            xs={{span:24}} sm={{span:24}} md={{span:24}} lg={{span:12}} xl={{span:12}} 
        >
            <Form.Item
                label='Permisos por módulos'
                className='Form-Item-Checkbox-Create-Profile'
            >
                <div className={`Container-Row-Buttons-Countries ${rex_selected_country_profile_type && 'Container-Row-Buttons-CountrySelected'}`}>
                {
                    rex_data_country_clone.map(country => {
                        const findModule = rex_permissions_country.length > 0 
                            ? rex_permissions_country.find(cou => cou == country.value)
                            : false
                        return (
                        <div key={country.value} className={`Container-Button-Country-Create-Profile
                            ${rex_selected_country_profile_type == country.value &&'Container-Button-Country-Selected'} `}>
                            <div
                                className={`Button-Country-Create-Profile
                                ${country.value == rex_selected_country_profile_type && 'Country-Selected '}
                                ${findModule ? 'Country-Added':'Country-Disabled'}
                                `}
                                onClick={() => dispatch(SelectedCountryProfileTypeReducer(country.value))}
                            >
                                {country.label}
                            </div>
                        </div>
                    )})
                }
                </div>
            </Form.Item>
            <Form.Item className="Form-Item-Modules-Profile-Types-Create" style={{marginBottom: '16px'}}>
                <div className={`${rex_edit_type_profile ? 'Disabled-Modules-Profile' : ''}`}></div>
                <Checkbox 
                    onClick={(e) => dispatch(SelectedModulesAllReducer(e.target.checked, rex_selected_country_profile_type))}
                    className='Checkbox-SelectAll-Create-Profile'
                    style={{marginBottom: '4px'}}
                    disabled={rex_selected_country_profile_type ? false : true}
                    checked={
                        rex_check_all_module_country.find(cou => cou == rex_selected_country_profile_type ? true : false)
                    }
                >
                    Seleccionar todos los módulos
                </Checkbox>
                {
                    rex_selected_country_profile_type
                    ? rex_data_modules_profile_clone.map((module, index) => 
                        module.paiid == rex_selected_country_profile_type
                        ? <Collapse 
                            className={`Collapse-Create-Profile ${module.smosubmodulos.length == 0 ? 'Icon-Hide-Profile' : ''}`}
                            expandIconPosition="end" 
                            expandIcon={() => <div className="Icon-Arrow-Profile"></div>}
                            key={index}
                          >
                            <Panel header={HeaderCollapse("module", module.modnombre, module.key, module.mod_checked)}>
                                {
                                    module.smosubmodulos.map((submodule, indexsmo) => 
                                        submodule.ssmsubmodulos.length == 0
                                        ? <Checkbox 
                                            className={`Submodule-Item ${submodule.submod_checked ? 'Check-Active-Submodule' : ''}`}
                                            key={indexsmo}
                                            checked={submodule.submod_checked}
                                            onClick={(e) => dispatch(SelectedSubModuleSpecificAllReducer(submodule.smoid, e.target.checked, module.key, rex_selected_country_profile_type))}
                                          >
                                            {submodule.smonombre}
                                          </Checkbox>
                                        : <Collapse 
                                                className='Collapse-Submodule-Profile Submodule-Profile' 
                                                expandIconPosition="end" 
                                                expandIcon={() => (<div className="Icon-Arrow-Profile"></div>)}
                                                key={indexsmo}
                                            >
                                                <Panel header={HeaderCollapse("submodule", submodule.smonombre, submodule.smoid, submodule.submod_checked, module.key)}>
                                                    {
                                                        submodule.ssmsubmodulos.map((subsubmodule, indexssm) => <Checkbox 
                                                            className={`Subsubmodule-Item ${subsubmodule.subsubmod_checked ? 'Check-Active-Subsubmodule' : ''}`} 
                                                            key={indexssm}
                                                            checked={subsubmodule.subsubmod_checked}
                                                            onClick={(e) => dispatch(SelectedSubSubModuleSpecificAllReducer(subsubmodule.ssmid, e.target.checked, module.key, submodule.smoid, rex_selected_country_profile_type))}
                                                        >
                                                            {subsubmodule.ssmnombre}
                                                        </Checkbox>)
                                                    }
                                                </Panel>
                                          </Collapse>
                                    )
                                }
                            </Panel>
                        </Collapse>
                        : null
                    )
                    : rex_data_modules_profile_clone.map((module, index) => 
                        module.paiid == rex_data_modules_profile_clone[0]['paiid']
                        && <div className="Collapse-Disabled-Profile">
                            <Collapse 
                                className={`Collapse-Create-Profile ${module.smosubmodulos.length == 0 ? 'Icon-Hide-Profile' : ''}`}
                                expandIconPosition="end" 
                                expandIcon={() => <div className="Icon-Arrow-Profile Disabled-Icon-Arrow"></div>}
                                key={index}
                                collapsible='disabled'
                            >
                                <Panel header={HeaderCollapse("module", module.modnombre, module.key, module.mod_checked)} collapsible='disabled' />
                            </Collapse>
                        </div>
                    )
                }
            </Form.Item>
            <div className='Form-Create-Profile-Container-Button-Save'>
                <button 
                    className="Button-Cancel-Profile"
                    onClick={() => navigate('/administrator/profile-types')}
                >
                    <span className="Text-Cancel">Cancelar</span>
                    <span className="Text-Cancel-Effect">Cancelar</span>
                </button>
                <button 
                    className={`Button-New-Profile ${rex_name_type_profile.length > 0 && rex_permissions_country.length > 0 ? '' : 'Button-Disabled'}`}
                    onClick={async () => {
                        if(rex_edit_type_profile){
                            ModalEditActive()
                        }else{
                            const respuesta = await dispatch(CreateEditTypeProfileReducer(id))
                            if(respuesta){
                                ModalSuccess()
                            }
                        }
                    }}
                >
                    {
                        rex_edit_type_profile
                        ?<>
                        <span className="Text-New">Editar</span>
                        <span className="Text-New-Effect">Editar</span>
                        </>
                        :<>
                        <span className="Text-New">Guardar</span>
                        <span className="Text-New-Effect">Guardar</span>
                        </>
                    }
                    {
                        rex_loading_profile_type && <Spin className="Spin-Loading" indicator={antIcon} />
                    }
                </button>
            </div>
        </Col>
    )
}

export default FormCreateProfile