import { Modal, Row, Typography, Col } from 'antd'
import React from 'react'
import '../../Styles/Components/UploadedFiles/NotificationsUploadedFiles.css'

const NotificationUploadedFiles = ({openModal, notifications, typeFile, setOpenModal}) => {

    const { Title } = Typography

    let notificationsFormated
    if(notifications?.includes('notificaciones')){
        notificationsFormated = JSON.parse(notifications)
    }


    return (
        <Modal
            zIndex={100000000}
            open={openModal} 
            onCancel={() => setOpenModal(false)}
            footer={null}
            closable={false}
            width={600}
            className='Modal-Notifications-Uploaded-Files'
        >
            <Row className='Modal-Container-Text-Uploaded-Files'>
                <Col span={24} className='Modal-Container-Text-Main'>
                    <Title>
                        Notificación
                    </Title>
                </Col>
            </Row>
            <Row>
            {
                notificationsFormated
                ? <Col span={24}>
                    {
                        notificationsFormated.map((not, index) => {
                            return (
                                <div className='Modal-Container-Error-Notification'>
                                    <div className='Modal-Container-Index-Error-Notification'>{index+1}) Se encontraron observaciones en la columna "{not.columna}":</div>
                                    {not.notificaciones.map((det, index_det) => (
                                        <div className='Modal-Container-Detail-Error-Notification'>
                                            - {det.msg} en las siguientes líneas: {
                                            det.rows.map((row, index_row) => (
                                                <b>
                                                    {
                                                        index_row >= 6
                                                        ? index_row == 6
                                                            ? <span>etc</span>
                                                            : null
                                                        : <span>{row},</span>
                                                    }
                                                </b>

                                            ))}
                                        </div>
                                    ))}
                                </div>
                            )
                        })
                    }
                    </Col>
                : <Col span={24} className='Modal-Container-Text-Success'>
                    <div>
                        El archivo de {typeFile} fue cargado con éxito
                    </div>
                </Col>
            }
            </Row>
        </Modal>
    )
}

export default NotificationUploadedFiles