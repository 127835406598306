import React, { useEffect, useState } from 'react'
import { Table, Input, Checkbox, Modal } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { 
    FilterOutlined,
    ExclamationCircleFilled
} from '@ant-design/icons'
import { DeleteItemFormEditChannelsExecutivesReducer } from '../../Redux/Actions/ChannelsExecutives/ChannelsExecutives'


const TableRowsSelected = () => {

    const dispatch = useDispatch()
    const { confirm } = Modal
    const [ currentData, setCurrentData ] = useState([])

    const getColumnSearchProps = (dataIndex, nameColumn) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
            <div
                style={{
                    padding: 8,
                }}
                onKeyDown={(e) => e.stopPropagation()}
                className="DropDown-Filter-Table-Channels-Executives"
            >
                <Input.Search
                    placeholder={`Filtrar ${nameColumn}`}
                    value={selectedKeys[0]}
                    size='small'
                    onChange={(e) => {
                        setSelectedKeys(e.target.value ? [e.target.value] : [])
                        confirm({
                            closeDropdown: false,
                        })
                    }}
                    style={{
                        display: 'block',
                    }}
                />
            </div>
        ),
        filterIcon: () => (<FilterOutlined/>),
        onFilter: (value, record) => {
            return record[dataIndex] != null ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : false
        },
    })

    const { 
        rex_data_form_edit_channels_executive,
    } = useSelector(({channelsExecutives}) => channelsExecutives)

    const confirmDeselectionAll = async () => {
        let response
        let dataDelete = []
        if(currentData.length > 0){
            dataDelete = currentData
        }else{
            dataDelete = [...rex_data_form_edit_channels_executive]
        }
        if(dataDelete.length > 0){
            confirm({
                title   : "Desmarcar Clientes",
                icon    : <ExclamationCircleFilled/>,
                content : "¿Está seguro que desea desmarcar todos los registros?",
                zIndex  : 100000000,
                className:"Confirm-Action-Channel-Executive",
                async onOk(){
                    response = await dispatch(DeleteItemFormEditChannelsExecutivesReducer(dataDelete, false))
                },
                okButtonProps: {
                    size:"small",
                },
                cancelButtonProps : {
                    size:"small",
                    danger:true
                },
                okCancel(){}
            })
        }

    }

    const columns = [
        {
            title: 'Item',
            dataIndex: 'item',
            align: 'center',
            render: (text, record, index) => index+1,
            fixed: 'left',
            width : 50,
        },
        {
            title: <>
                <Checkbox 
                    checked={rex_data_form_edit_channels_executive.length > 0 ? true : false }
                    disabled={currentData.length > 0 ? rex_data_form_edit_channels_executive.length > 0 ? false : true : false}
                    onChange={() => confirmDeselectionAll()}
                    
                />
            </>,
            dataIndex: 'checked',
            showSorterTooltip: false,
            align: 'center',
            render:(_, record) => (
                <Checkbox checked={true}
                    onChange={() => {
                        dispatch(DeleteItemFormEditChannelsExecutivesReducer(record))
                        const actualRows = currentData.filter(cur => cur.id != record.id)
                        setCurrentData(actualRows)
                    }}
                />
            ),
            fixed: 'left',
            ellipsis : true,
            width : 40
        },
        {
            title: 'COD SHIP TO',
            dataIndex: 'cod_ship_to',
            ...getColumnSearchProps('cod_ship_to', 'COD SHIP TO'),
            sorter: (a, b) => a.cod_ship_to ? a.cod_ship_to.localeCompare(b.cod_ship_to) : false,
            showSorterTooltip: false,
            align: 'center',
            render:(_, record) => (
                <div className='Table-Contain-Text-Global'>
                    {record.cod_ship_to}
                </div>
            ),
            ellipsis : true,
            width : 100
        },
        {
            title: 'SHIP TO',
            dataIndex: 'ship_to',
            ...getColumnSearchProps('ship_to', 'SHIP TO'),
            sorter: (a, b) => a.ship_to ? a.ship_to.localeCompare(b.ship_to) : false,
            showSorterTooltip: false,
            align: 'initial',
            render:(_, record) => (
                <div className='Table-Contain-Text-Global'>
                    {record.ship_to}
                </div>
            ),
            ellipsis : true,
            width : 100
        },
        {
            title: 'COD SOLD TO',
            dataIndex: 'cod_sold_to',
            ...getColumnSearchProps('cod_sold_to', 'COD SOLD TO'),
            sorter: (a, b) => a.cod_sold_to ? a.cod_sold_to.localeCompare(b.cod_sold_to) : false,
            showSorterTooltip: false,
            align: 'center',
            render:(_, record) => (
                <div className='Table-Contain-Text-Global'>
                    {record.cod_sold_to}
                </div>
            ),
            ellipsis : true,
            width : 100
        },
        {
            title: 'SOLD TO',
            dataIndex: 'sold_to',
            ...getColumnSearchProps('cod_sold_to', 'SOLD TO'),
            sorter: (a, b) => a.sold_to ? a.sold_to.localeCompare(b.sold_to) : false,
            showSorterTooltip: false,
            align: 'initial',
            render:(_, record) => (
                <div className='Table-Contain-Text-Global-Form-Channel-Executives'>
                    {record.sold_to}
                </div>
            ),
            width : 250
        },
    ]

    useEffect(() => {
        setCurrentData([...rex_data_form_edit_channels_executive])
    },[])

    
    useEffect(() => {
        setCurrentData([...rex_data_form_edit_channels_executive])
    },[rex_data_form_edit_channels_executive])
    

    return (
        <Table
            dataSource={[...rex_data_form_edit_channels_executive]}
            rowClassName={ record => {
                if(!record.itemDefault){
                    return "Row-Table-Clients-Selected Client-Default"
                }
                return "Row-Table-Clients-Selected"
            }}
            onChange={(pagination, filters, sorter, extra) => {
                setCurrentData(extra.currentDataSource)
            }}
            scroll={{
                y: 300,
                x: 400
            }}
            columns={columns}
            pagination={false} 
        />
    )
}

export default TableRowsSelected