import config from '../../../config'
import socket from '../../../App/socket'

export const LogOutReducer = () => async (dispatch, getState) => {
    
    const usuario = getState().loginGrow.rex_data_user_logingrow
    
    await fetch(config.api+'auth/logout',
    {
        mode:'cors',
        method: 'POST',
        headers: {
            'Accept' 	   : 'application/json',
            'Content-type' : 'application/json',
            'usutoken'	   : localStorage.getItem('usutoken'),
        }
    }
    )
    .then(res => res.json())
    .then(async data => {
        if(data.response){
            await localStorage.clear()
            socket.emit('logout', usuario.usucorreo);
        }
    }).catch((error)=> {
        console.log(error)
    });
    return true
}