import React from 'react'
import { useNavigate } from "react-router-dom"
import { Col, Typography } from 'antd'
import ArrowLink from '../../Assets/Images/HomeAdministrator/arrow-card.svg'

const CardHomeAdministator = ({title, text, url}) => {

    const { Title } = Typography
    const navigate = useNavigate()

    return (
        <Col xs={{span:24}} sm={{span:24}} md={{span:24}} lg={{span:12}} xl={{span:12}}  className='Home-Administrator-Col-Container gutter-row'>
            <div className='Home-Administrator-Container-Card'>
                
                <Title level={3} className='Home-Administrator-Title-Card'>{title}</Title>

                <div className='Home-Administrator-Container-Text-Card'>
                    <p className='Home-Administrator-Text-Card'>{text}</p>
                </div>

                <a>
                    <div 
                        className='Home-Administrator-Container-Link-Card'
                        onClick={() => navigate(`${url}`)}
                    >
                        <div className='Home-Administrator-Link-Card'>
                            <span>Continuar</span>
                        </div>
                        <div className='Home-Administrator-Container-Arrow-Card'>
                            <img src={ArrowLink}/>
                        </div>
                    </div>
                </a>
            </div>
        </Col>
    )
}

export default CardHomeAdministator