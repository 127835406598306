import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { SelectedCountryProfileTypeReducer } from '../../Redux/Actions/ProfileTypes/ProfileTypes'
import { Form } from 'antd'

const ProfileTypesCountriesPermissions = () => {
    const dispatch =  useDispatch()

    const {
        rex_selected_country_profile_type,
        rex_permissions_country,
    } = useSelector(({profileTypes}) => profileTypes)

    const { 
        rex_data_country_clone,
    } = useSelector(({paises}) => paises)

    return (
        <Form.Item
            label='Permisos por módulos'
            className='Form-Item-Checkbox-Countries-Permissions'
        >
            <div className={`Container-Row-Buttons-Countries-Permissions ${rex_selected_country_profile_type && 'Container-Row-Buttons-Country-Permissions-Selected'}`}>
            {
                rex_data_country_clone.map(country => {
                    const findModule = rex_permissions_country.length > 0 
                    ? rex_permissions_country.find(cou => cou == country.value)
                    : false
                    return (
                        <div
                            key={country.value}
                            className={`Container-Button-Country-Permissions 
                                ${country.value == rex_selected_country_profile_type
                                ? 'Container-Button-Country-Permissions-Selected' 
                                : ''}
                            `}
                        >
                            <div 
                                className={`Button-Country-Permissions 
                                    ${findModule
                                        ? 'Country-Permissions-Added'
                                        : 'Country-Permissions-Disabled'}
                                    ${country.value == rex_selected_country_profile_type 
                                        ? 'Country-Permissions-Selected' 
                                        : ''}
                                `}
                                onClick={() => dispatch(SelectedCountryProfileTypeReducer(country.value))}
                            >
                                {country.label}
                            </div>
                        </div>
                    )
                })
            }
            </div>
        </Form.Item>
    )
}

export default ProfileTypesCountriesPermissions