import React, { useState } from 'react'
import { Popover, Checkbox } from 'antd'
import ArrowSelectPopOver from '../../Assets/Images/ProfileTypes/ArrowSelectPopOver'

const ProfileTypesFilterSelect = ({ 
    toggleSelectAll, 
    selectedRowKeys, 
    dataAmount, 
    setSelectedRowKeys, 
    data, 
    setValueCheckbox, 
    valueCheckbox, 
    actInacChechBox, 
    themeColor = 'blue',
    showDropDown = false

}) => {

    const [ showListSelect, setShowListSelect ] = useState(false)
    const [ onHoverPopOver, setOnHoverPopOver ] = useState(false)

    const SelectRowStatus = ( value ) => {
        setSelectedRowKeys((keys) =>
            data.filter(usu => usu.estid == value).map((r) => r.key)
        )
        setValueCheckbox(false)
    }

    const listOptions = () => (
        <div
            style={{display:'flex', flexDirection:'column', width:'120px'}}
            onClick={() =>setShowListSelect(false)}
        >
            <div
                className='ProfileTypesFilterSelect-Option'
                onClick={()=>{
                    setValueCheckbox(true)
                    toggleSelectAll(true)
                }}
                >
                Todas
            </div>
            <div
                className='ProfileTypesFilterSelect-Option'
                onClick={()=> {
                    setValueCheckbox(false)
                    toggleSelectAll(false)
                }}
                >
                Ninguna
            </div>
            {
                actInacChechBox &&
                <>
                <div className='ProfileTypesFilterSelect-Option'
                    onClick={() => SelectRowStatus(1)}
                >
                    Activos
                </div>
                <div className='ProfileTypesFilterSelect-Option'
                    onClick={() => SelectRowStatus(2)}
                >
                    Inactivos
                </div>
                </>
            }
        </div>
    )


    return (
        <Popover
            showArrow={false}
            trigger='click'
            placement="bottomLeft"
            open={showListSelect}
            overlayClassName='ProfileTypesFilterSelect-PopOver'
            content={listOptions}
            onOpenChange={() => showListSelect && setShowListSelect(false)}
        >
            <div
                className={`ProfileTypesFilterSelect-Container-Items${showDropDown ? "-DropDown" : ""} ${themeColor == 'green' && "Theme-Green"}`}
                // className={`ProfileTypesFilterSelect-Container-Items${showDropDown ? "-DropDown" : ""} ${themeColor == 'green' && "Theme-Green"} ${showListSelect && 'Container-Items-Active'}`}
                onMouseOver={() => setOnHoverPopOver(true)}
                onMouseLeave={() => setOnHoverPopOver(false)}
            >
                {/* <div
                    onClick={()=> {
                        setShowListSelect(false)
                        setValueCheckbox(!valueCheckbox)
                        toggleSelectAll(valueCheckbox? false : true)
                    }}
                > */}
                    <Checkbox
                        onChange={() => {
                            setShowListSelect(false)
                            setValueCheckbox(!valueCheckbox)
                            toggleSelectAll(valueCheckbox? false : true)    
                        }}
                        checked={valueCheckbox}
                    ></Checkbox>
                {/* </div> */}
                <div
                    className={`${showDropDown ? 'ProfileTypesFilterSelect-Button' : 'ProfileTypesFilterSelect-Button-Hidden'}  ${showListSelect && 'Filter-Select-Open'}`}
                    onMouseOver={() => setOnHoverPopOver(true)}
                    onMouseLeave={() => setOnHoverPopOver(false)}
                    // Activar Background al tener seleccionado
                    onClick={() =>setShowListSelect(!showListSelect) }
                >               
                   <ArrowSelectPopOver
                    onHover={(onHoverPopOver || showListSelect || valueCheckbox) ? true : false}
                    themeColor={themeColor}
                    />
                </div>

            </div>
        </Popover>

    )
}

export default ProfileTypesFilterSelect