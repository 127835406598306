import React, {useState, useRef} from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { StoreModuleReducer } from '../../../Redux/Actions/Modules/CreateEditModules'
import { Row, Col, Form, Input, Select, Space } from 'antd'
import Picture from '../../../Assets/Images/Modules/Picture.png'
import config from '../../../config'
import notificationGlobal from '../../Global/NotificationGlobal'

const FormNewModules = (props) => {
    const rex_get_module_data_clone = props.rex_get_module_data_clone
    const refBtnImagen = useRef(null)

    const { 
        rex_data_country_clone,
    } = useSelector(({paises}) => paises)

    const [imageUrl, setImageUrl] = useState()
    const dispatch = useDispatch()

    const formato = (type) => {
        if(type === 'image/jpeg' || type === 'image/png' || type === 'image/jpg' || type === 'image/gif' || type === 'image/svg'){
            return true
        }else{
            return false
        }
    }

    const imgChangePerfil = (file) => {
        if(file){
            const errorType = formato(file.type)
            if(!errorType){
                // notificacionMensaje('warning','Solo debes cargar archivos JPG/PNG/JPEG,GIF,SVG')
                return false
            }
            const fileSize = file.size / 1024 / 1024 < 2
            const imageSizeInBytes = file.size;
            const imageSizeInKilobytes = imageSizeInBytes / 1024;
            const imageSizeInMegabytes = imageSizeInKilobytes / 1024;
      
            if (!fileSize) {
                // notificacionMensaje('warning','El archivo debe ser menor a 2MB')
                return false
            }

            const image = new Image();
            image.src = URL.createObjectURL(file);
      
            image.onload = () => {
                const width = image.width;
                const height = image.height;
                if (width > 400 && height > 400) {
                    notificationGlobal("warning", "La imagen debe ser de máx 400x400px")
                    return false
                }else{
                    const reader = new FileReader()
                    reader.readAsDataURL(file)
                    reader.onload = function() {
                        const base64 = reader.result
                        setImageUrl(base64)
                        dispatch(StoreModuleReducer(base64, "imageModulo"))
                    }
                }
            };
        }
    }


    return(
        <Form
            className="Form-Modules"
            layout="vertical"
            autoComplete="off"
            onValuesChange={false}
        >
            <Row gutter={20}>
                <Col md={12} xs={24}>
                    <Form.Item 
                        label="Nombre de Módulo"
                        className="Form-Item-Modules"
                        labelCol={{
                            span: 24,
                        }}
                        wrapperCol={{
                            span: 24,
                        }}
                    >
                        <Input
                            onChange={(e) => {

                                let nameCountry = (rex_get_module_data_clone.modNamCou.normalize("NFD").replace(/[\u0300-\u036f]/g, "")).toLowerCase().split(' ').join('-')
                                let nameRouteSlug = (e.target.value).toLowerCase().split(' ').join('')
                                let nameRoute = (e.target.value).toLowerCase().split(' ').join('-')

                                dispatch(StoreModuleReducer(nameCountry+"."+nameRouteSlug, "slugModulo"))
                                dispatch(StoreModuleReducer(e.target.value, "nombreModulo"))
                                dispatch(StoreModuleReducer(nameRoute, "routeModulo"))
                            }}
                            
                            placeholder="Ingrese nombre" 
                            value={rex_get_module_data_clone.modnombre}
                        />
                    </Form.Item>
                </Col>
                <Col md={12} xs={24}>
                    <Form.Item 
                        label="Ruta de módulo"
                        className="Form-Item-Modules Input-Prefix"
                        labelCol={{
                            span: 24,
                        }}
                        wrapperCol={{
                            span: 24,
                        }}
                    >
                        <Input
                            prefix={
                                <>/Sistema/dashboards/</>
                            }
                            onChange={(e) => {}}
                            value={rex_get_module_data_clone.modruta}
                            disabled={true}
                        />
                    </Form.Item>
                </Col>
                {/* <Col md={12} xs={24}>
                    <Form.Item 
                        label="Enlace Power Bi"
                        className="Form-Item-Modules"
                        labelCol={{
                            span: 24,
                        }}
                        wrapperCol={{
                            span: 24,
                        }}
                    >
                        <Input 
                            onChange={(e) => dispatch(StoreModuleReducer(e.target.value, "linkModulo"))}
                            placeholder="Ingresar enlace" 
                            value={rex_get_module_data_clone.modpowerbi}
                        />
                    </Form.Item>
                </Col> */}
                <Col md={12} xs={24}>
                    <Form.Item 
                        label="País"
                        className="Form-Item-Modules"
                        labelCol={{
                            span: 24,
                        }}
                        wrapperCol={{
                            span: 24,
                        }}
                    >
                        <Select
                            placeholder="Ingresar pais"
                            popupClassName="Item-Select-Country"
                            onChange={(e, index) => {

                                let nameCountry = (index.label).normalize("NFD").replace(/[\u0300-\u036f]/g, "").toLowerCase().split(' ').join('-')
                                let nameRoute = (rex_get_module_data_clone.modnombre).toLowerCase().split(' ').join('')
                                
                                dispatch(StoreModuleReducer(nameCountry+"."+nameRoute, "slugModulo"))
                                dispatch(StoreModuleReducer(index, "countryModulo"))

                            }}
                            options={rex_data_country_clone}
                            suffixIcon={null}
                            value={rex_get_module_data_clone.paiid ? rex_get_module_data_clone.paiid : null}
                        />
                    </Form.Item>
                </Col>
                <Col md={12} xs={24}>
                    <Form.Item 
                        label="Slug del permiso"
                        className="Form-Item-Modules"
                        labelCol={{
                            span: 24,
                        }}
                        wrapperCol={{
                            span: 24,
                        }}
                    >
                        <Input 
                            onChange={(e) => {}}
                            disabled={true}
                            value={rex_get_module_data_clone.modslug}
                        />
                    </Form.Item>
                </Col>

                <Col md={12} xs={24}>
                    <Form.Item 
                        label="Ícono de módulo (400x400px)"
                        className="Form-Item-Modules"
                        labelCol={{
                            span: 24,
                        }}
                        wrapperCol={{
                            span: 24,
                        }}
                    >
                        <div className="Container-Input-Image-Modules">
                            <figure className="Container-Image-Modules" onClick={() => refBtnImagen.current.click()}>
                                <input 
                                    type="file" 
                                    ref={refBtnImagen} 
                                    className="Input-File-Modules" 
                                    onChange={(e) => imgChangePerfil(e.target.files[0])}
                                />
                                <img 
                                    src={
                                        imageUrl
                                        ? imageUrl
                                        : rex_get_module_data_clone.modicono 
                                            ? config.api_url + rex_get_module_data_clone.modicono 
                                            : Picture
                                    } 
                                    style={
                                        imageUrl    
                                        ?   {
                                                width: '50%',
                                                height: 'auto',
                                            }
                                        :   rex_get_module_data_clone.modicono 
                                            ?   {
                                                    width: '50%',
                                                    height: 'auto',
                                                }
                                            :   {}
                                    }
                                />
                            </figure>
                        </div>
                    </Form.Item>
                </Col>
            </Row>
        </Form>
    )
}

export default FormNewModules