import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Row, Col, Typography } from 'antd'
import { useLocation, useParams } from 'react-router'
import { useIdleTimer } from 'react-idle-timer'
import { useNavigate } from "react-router-dom"
import { 
    ObtenerDataModulosDashboardsReducer,
    RegistrarActividadModuloDashboardReducer,
    SeleccionarTipoModuloDashboardsReducer,
    RegistrarEstadoModuloDashboardReducer
} from '../../Redux/Actions/Dashboards/Dashboards'
import {
    RegisterUsersConnectedReducer
} from '../../Redux/Actions/Dashboards/RegisterUsers'
import MenuSliderGlobal from '../../Components/Global/MenuSliderGlobal'
import SideMenu from '../../Components/Global/SideMenu'
import CardMetrics from '../../Components/Dashboards/CardMetrics'
import { PowerBIEmbed } from 'powerbi-client-react';
import socket from '../../App/socket'
import { Link } from 'react-router-dom'
import PowerBi from '../PowerBi/PowerBi'
import { models } from 'powerbi-client'

const Dashboards = () => {

    const { Title } = Typography
    const dispatch = useDispatch()

    const [habilitarRegistroDashboard, setHabilitarRegistroDashboard] = useState(false)

    const location = useLocation()
    const { id, idsub, route } = useParams()
    let navigate = useNavigate()
    //Tiempo inactividad usuario: 8 segundos
    const timeout = 300000

    const { 
        rex_users_conected_dashboard,
    } = useSelector(({registerUsers}) => registerUsers)

    const { 
        rex_data_modulo_seleccionado_dashboards,
        rex_items_menu_slider_global_dashboards,
        rex_title_module,
        rex_title_submodule,
        rex_title_subsubmodule,
        rex_data_dashboard,
        rex_id_selecionado_modulo_dashboards,
        token_acceso_power_bi
    } = useSelector(({dashboards}) => dashboards)

    const { 
        rex_data_user_logingrow
    } = useSelector(({loginGrow}) => loginGrow)

    const { 
        rex_data_users_online
    } = useSelector(({users}) => users)

    useEffect(() => {
        
        if(route != "simplePowerbi"){
            console.log("Ejecuta UseEffect")
            // ObtenerDataModule()
        }

        return () => {
            socket.emit('unreg-dashboards', rex_data_modulo_seleccionado_dashboards, {usuusuario: rex_data_user_logingrow.usuusuario} )
            socket.off('get-users-dashboards')
        };
    },[])

    // useEffect(() => {

    // },[token_acceso_power_bi])

    const ObtenerDataModule = async () => {

        let data = await dispatch(ObtenerDataModulosDashboardsReducer(id, idsub, route, true))
        
        if(data){
            dispatch(RegistrarEstadoModuloDashboardReducer('ACTIVO'))
            dispatch(RegistrarActividadModuloDashboardReducer(rex_data_modulo_seleccionado_dashboards, false, location.pathname, id, idsub, route))
            socket.on('get-users-dashboards', (data_users, registrar=false) => {
                if(registrar == false){
                    dispatch(RegisterUsersConnectedReducer(data_users))
                }
            })
                await dispatch(SeleccionarTipoModuloDashboardsReducer(idsub))
    
            return
        }else{
            navigate(-1)
        }

    }

    const onIdle = () => {
        socket.emit('reg-status-dashboards', rex_data_modulo_seleccionado_dashboards, {usuusuario: rex_data_user_logingrow.usuusuario}, 'ausente' );
        dispatch(RegistrarEstadoModuloDashboardReducer('INACTIVO'))
    }
    const onActive = () => {
        socket.emit('reg-status-dashboards', rex_data_modulo_seleccionado_dashboards, {usuusuario: rex_data_user_logingrow.usuusuario}, 'online' );
        dispatch(RegistrarEstadoModuloDashboardReducer('ACTIVO'))
    }

    useIdleTimer({
        onIdle,
        onActive,
        timeout,
        throttle: 500
    })

    useEffect(() => {
        ObtenerDataModule()
    },[location.pathname])

    useEffect(() => {
        if(habilitarRegistroDashboard){
            dispatch(RegistrarActividadModuloDashboardReducer(rex_data_modulo_seleccionado_dashboards, true, location.pathname, id, idsub, route))
        }
    },[rex_items_menu_slider_global_dashboards])

    useEffect(() => {
        dispatch(RegistrarActividadModuloDashboardReducer(rex_data_modulo_seleccionado_dashboards, true, location.pathname, id, idsub, route))
        const interval = setTimeout(function(){ 
            setHabilitarRegistroDashboard(true)
        }, 1000);

        return () => clearInterval(interval)

    },[])

    return(
        <>
            <div className="Container-Main Container-Dashboards" style={{paddingBottom: '13px', paddingTop: '49px'}}>
                <Row style={{position: 'relative'}}>
                    <Col span={24}>
                        <p className="Text-Route-Dashboard">
                            Filtro aplicado: <Link to="/home-modules" style={{color: 'rgba(1, 58, 129, .4)'}} state={parseInt(id)}><span className="Current-Path-Item">{rex_title_module}</span></Link> / {rex_title_subsubmodule 
                            ? <>
                                <Link to={`/dashboards/modulo/${id}/${rex_data_dashboard.smoruta}`}>
                                    <span className="Current-Path-Item">{rex_title_submodule}</span>
                                </Link>
                                <Link to={`/dashboards/modulo/${id}/submodulo/${rex_data_dashboard.smoid}/${rex_data_modulo_seleccionado_dashboards.ssmruta}`}>
                                    <span className="Current-Path"> {rex_title_subsubmodule}</span>
                                </Link>
                              </> 
                            : <Link to={`/dashboards/modulo/${id}/${rex_data_modulo_seleccionado_dashboards.smoruta}`}>
                                <span className="Current-Path">{rex_title_submodule}</span>
                              </Link>}
                        </p>
                    </Col>
                    <div style={{position: 'absolute', right: 0, top: '-6px'}}>
                        <MenuSliderGlobal
                            titleCategory=""
                            items={rex_items_menu_slider_global_dashboards}
                        />
                    </div>
                </Row>
            </div>
            <Row style={{margin: '0 13px 0 30px'}}>
                <Col span={24}>
                    {
                        route == "simplePowerbi"
                        ?   <PowerBi/>
                        : rex_data_modulo_seleccionado_dashboards.smoreportid
                            ?   <PowerBIEmbed
                                    embedConfig = {{
                                        type: 'report',
                                        id: rex_data_modulo_seleccionado_dashboards.smoreportid,
                                        embedUrl: idsub ? rex_data_modulo_seleccionado_dashboards.ssmpowerbi : rex_data_modulo_seleccionado_dashboards.smopowerbi,
                                        accessToken: token_acceso_power_bi,
                                        tokenType: models.TokenType.Aad,
                                        settings: {
                                            panes: {
                                                filters: {
                                                    expanded: false,
                                                    visible: false
                                                }
                                            },
                                            background: models.BackgroundType.Default,
                                        }
                                    }}
                                
                                    eventHandlers = {
                                        new Map([
                                            ['loaded', function () {console.log('Report loaded');}],
                                            ['rendered', function () {console.log('Report rendered');}],
                                            ['error', function (event) {console.log(event.detail);}],
                                            ['visualClicked', () => console.log('visual clicked')],
                                            ['pageChanged', (event) => console.log(event)],
                                        ])
                                    }
                                        
                                    cssClassName = { "Class-Power-Bi-Embebed" }
                                
                                    getEmbeddedComponent = { (embeddedReport) => {
                                        let report = embeddedReport;
                                    }}
                                />
                            :  <iframe
                                    id="Iframe-Dashboard"
                                    width="100vw"
                                    height="1110"
                                    src={
                                        idsub
                                        ? rex_data_modulo_seleccionado_dashboards.ssmpowerbi
                                        : rex_data_modulo_seleccionado_dashboards.smopowerbi
                                    }
                                    frameborder="0"
                                    
                                ></iframe> 
                            
                    }
                    <div className="Disguise-Iframe-Footer"></div>
                </Col>
            </Row>
            {/* <div className="Container-Main Container-Dashboards" style={{paddingTop: '0', marginTop: '-40px'}}>
                <Row style={{rowGap: '25px'}}>
                    <Col span={24}>
                        <Title
                            level={2}
                            className="Title-Metrics-Dashboards"
                        >
                            Métricas:
                        </Title>
                        <p className="Paragraph-Metrics-Dashboards">Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque</p>
                    </Col>
                    <Col span={24}>
                        <CardMetrics
                            titleMetrics="Rápido y Flexible"
                            paragraphMetrics="Muchos paquetes de autoedición y editores de páginas web ahora usan Lorem Ipsum"
                        />
                    </Col>
                </Row>
            </div> */}
            <SideMenu
                usersCurrentView  = {rex_users_conected_dashboard}
                usersAllConnected ={rex_data_users_online}
            />
        </>
    )
}

export default Dashboards