import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate} from "react-router-dom"
import { 
    ClearDataClientExecutiveReducer,
    GetDataClientsExecutivesReducer,
    DeleteClientExecutiveReducer,
    FilterClientsExecutivesReducer,
} from '../../Redux/Actions/ClientsExecutives/ClientsExecutives'
import { Row, Modal } from 'antd'
import ButtonCreateGlobal from '../../Components/Global/ButtonCreateGlobal'
import TableGlobal from '../../Components/Global/TableGlobal'
import TitleSearchGlobal from '../../Components/Global/TitleSearchGlobal'
import NotificationDeleted from '../../Components/Users/NotificationDeleted'
import NotificationErrorServer from '../../Components/Users/NotificationErrorServer'
import { ExclamationCircleFilled } from '@ant-design/icons'
import NotificationEdited from '../../Components/Users/NotificationEdited'
import { ValidationUserReducer } from '../../Redux/Actions/Validations/Validations'
import SideMenu from '../../Components/Global/SideMenu'

const ClientsExecutives = () => {
    const dispatch = useDispatch()
    let navigate = useNavigate()

    const { confirm } = Modal

    const [ txtFilter, setTxtFilter ] = useState('')
    const [ selectedRowKeys, setSelectedRowKeys ] = useState([])
    const [ valueCheckbox, setValueCheckbox ]     = useState(false)
    const [pageTableGlobal, setPageTableGlobal ] = useState(1);
    const [pageSizeTableGlobal, setPageSizeTableGlobal ] = useState(10);

    const {
		rex_data_clients_executives_clone,
        rex_loading_data_clients_executives,
    } = useSelector(({clientsExecutives}) => clientsExecutives)

    const { 
        rex_users_conected_dashboard,
    } = useSelector(({registerUsers}) => registerUsers)

    const { 
        rex_data_users_online
    } = useSelector(({users}) => users)

    const ValidateUser = async () => {
        const validate_user = await dispatch(ValidationUserReducer())
        if(!validate_user){
            navigate('/login')
        }
    }

    useEffect(() => {
        ValidateUser()
    }, [])

    const deleteUser = (id, resetValues) => {
        if(id.length > 0){
            confirm({
                title   : `¿Está seguro que desea eliminar ${id.length > 1 ? 'a los clientes ejecutivos': 'al cliente ejecutivo'}?`,
                icon    : <ExclamationCircleFilled/>,
                content : 'Esta acción es irreversible',
                zIndex  : 100000000,
                async onOk(){
                    await dispatch(DeleteClientExecutiveReducer(id))
                },
                okCancel(){}
            })
        }
    }

    const filterUsers = (listSelected, type) => {
        dispatch(FilterClientsExecutivesReducer(listSelected))
    }

    const actionOnRow = (data) => {
        navigate(`/edit-clients-executives/${data.id}`)
    }
    
    useEffect(() => {
        if(rex_data_clients_executives_clone.length == 0){
            dispatch(GetDataClientsExecutivesReducer())
        }
        dispatch(ClearDataClientExecutiveReducer())
    },[])

    const columns = [
        {
            title: 'Item',
            dataIndex: 'item',
            align: 'center',
            render: (text, record, index) => (pageTableGlobal - 1) * pageSizeTableGlobal + index + 1,
        },
        {
            title: 'Canal',
            dataIndex: 'canal',
            align: 'center',
            render:(_,record) => (
                <div className='Table-Contain-Text-Global'>
                    {record.canal}
                </div>
            )
        },
        {
            title: 'Ejecutivo',
            dataIndex: 'ejecutivo',
            render:(_,record) => (
                <div className='Table-Contain-Text-Global'>
                    {record.ejecutivo}
                </div>
            )
        },
        {
            title: 'Gerente',
            dataIndex: 'gerente',
            align: 'center',
            render:(_, record) => (
                <div className='Table-Contain-Text-Global'>
                    {record.gerente}
                </div>
            )
        },
        {
            title: 'Codigo de Cliente',
            dataIndex: 'cod_cliente',
            align: 'center',
            render:(_, record) => (
                <div className='Table-Contain-Text-Global'>
                    {record.cod_cliente}
                </div>
            )
        },
        {
            title: 'Cliente',
            dataIndex: 'cliente',
            align: 'center',
            render:(_, record) => (
                <div className='Table-Contain-Text-Global'>
                    {record.cliente}
                </div>
            )
        },
    ]

    return (
        <div className='Container-Main'>

            <TitleSearchGlobal
                setTxtFilter={setTxtFilter}
                funtionFilter={(valueSelected, type) => filterUsers(valueSelected, type)}
                title='Clientes Ejecutivos'
            />
            <Row className='Users-Container-Actions'>
                <div className='Users-Container-Actions-Selects'></div>
                <div>
                    <ButtonCreateGlobal nameRoute='/create-clients-executives'/>
                </div>
            </Row>

            <TableGlobal
                data={rex_data_clients_executives_clone}
                columns={columns}
                loading_data={rex_loading_data_clients_executives}
                showTrash={true}
                valueCheckbox={valueCheckbox}
                setValueCheckbox={setValueCheckbox}
                showDownload={false}
                selectedRowKeys={selectedRowKeys}
                setSelectedRowKeys={setSelectedRowKeys}
                functionDelete={(values, resetValues) => deleteUser(values, resetValues)}
                pageTableGlobal={pageTableGlobal}
                pageSizeTableGlobal={pageSizeTableGlobal}
                setPageTableGlobal={setPageTableGlobal}
                setPageSizeTableGlobal={setPageSizeTableGlobal}
                actionOnRow={actionOnRow}
                actInacChechBox={false}
            />
            <NotificationDeleted/>
            <NotificationErrorServer/>
            <NotificationEdited/>

            <SideMenu
                usersCurrentView  = {rex_users_conected_dashboard}
                usersAllConnected ={rex_data_users_online}
            />
        </div>
    )
}

export default ClientsExecutives