import {
    REGISTER_USERS_CONNECTED_MODULES_FILE_UPLOAD,
    MESSAGES_NOTIFICATIONS_UPLOAD_FILE,
    GET_CARDS_UPLOAD_FILES,
    SHOW_NOTIFICATION_MODAL_UPLOAD_FILE,
    ITEM_NOTIFICATION_MODAL_UPLOAD_FILE
} from "../../../Constants/FileUpload/FileUpload";

const INIT_STATE = {
    rex_users_file_upload_connected         : [],
    rex_messages_notifications_upload_files : [],
    rex_show_notification_modal_upload_file : false,
    rex_item_notification_modal_upload_file : {},
    rex_cards_total_upload_file             : [
        {
            paiid : 1,
            cards: [
                {
                    name    : "Maestras Principales",
                    cards : [
                        {
                            title           : "Master de Clientes",
                            subtitle: "",
                            url             : "upload-file/pe/master-clients-kcpe",
                            // url     : "upload-file/pe/master-clients",
                            disabled        : false,
                            slug            : "peru.fileupload.mastercliente",
                            fileTemplate    : null
                        },
                        {
                            title           : "Master de Producto",
                            subtitle        : "(Actual)",
                            url             : "upload-file/pe/master-products",
                            disabled        : false,
                            slug            : "peru.fileupload.masterproducto",
                            fileTemplate    : null
                        },
                        // {
                        //     title   : "Transaccional Moderno SO",
                        //     subtitle: "",
                        //     url     : "upload-file/pe/transactional-modern-so",
                        //     disabled: false,
                        //     slug    : "peru.fileupload.transactionalmodern"
                        // },
                        // {
                        //     title   : "DPSM Base",
                        //     subtitle: "",
                        //     url     : "upload-file/pe/dpsm",
                        //     disabled: false,
                        //     slug    : "peru.fileupload.dpsmbase"
                        // },
                    ]
                },
                {
                    name    : "Objetivos",
                    cards : [
                        {
                            title           : "Objetivos Sellin",
                            subtitle        : "",
                            url             : "upload-file/pe/goals-sellin",
                            disabled        : false,
                            slug            : "peru.fileupload.objetivosellin",
                            fileTemplate    : null

                        },
                        {
                            title           : "Objetivos Sellout",
                            subtitle        : "",
                            url             : "upload-file/pe/goals-sellout",
                            disabled        : false,
                            slug            : "peru.fileupload.objetivossellout",
                            fileTemplate    : null
                        },
                    ]
                },
                // {
                //     name    : "Area 1",
                //     cards : [
                //         {
                //             title   : "Master de Clientes",
                //             subtitle: "",
                //             url     : "upload-file/pe/master-clients",
                //             disabled: false,
                //             slug    : "peru.fileupload.mastercliente"
                //         },
                //         {
                //             title   : "Master de Producto",
                //             subtitle: "(Actual)",
                //             url     : "upload-file/pe/master-products",
                //             disabled: false,
                //             slug    : "peru.fileupload.masterproducto"
                //         },
                //     ]
                // },
                // {
                //     name    : "PDV Homologado",
                //     cards : [
                //         {
                //             title           : "Plantilla Detalle PDV",
                //             subtitle        : "",
                //             url             : "upload-file/pe/pdvhml/plantilla-detalle-pdv",
                //             disabled        : false,
                //             slug            : "peru.fileupload.plantilladetallepdv",
                //             fileTemplate    : "https://gia-back.grow-corporate.com/download/file?token=72de0ab09fd80d686704b6e2f7799c8600a9147bc0fe948133d6aab411d8"
                //         },
                //     ]
                // }
            ]
        },
        {
            paiid : 2,
            cards: [
                {
                    name    : "Maestras Principales",
                    cards : [
                        {
                            title   : "Master Clientes",
                            subtitle: "Master",
                            url     : "upload-file/ch/master-clients",
                            disabled: false,
                            slug    : "chile.fileupload.mastercliente"
                        },
                        {
                            title   : "Master Productos",
                            subtitle: "Actual",
                            url     : "upload-file/ch/master-products",
                            disabled: false,
                            slug    : "chile.fileupload.masterproducto"
                        },                                
                    ]
                },
                // {
                //     name    : "Area 1",
                //     cards : [
                //         {
                //             title   : "Master Clientes",
                //             subtitle: "Master",
                //             url     : "upload-file/ch/master-clients",
                //             disabled: false,
                //             slug    : "chile.fileupload.mastercliente"
                //         },
                //         {
                //             title   : "Master Productos",
                //             subtitle: "Actual",
                //             url     : "upload-file/ch/master-products",
                //             disabled: false,
                //             slug    : "chile.fileupload.masterproducto"
                //         },                                
                //     ]
                // },
            ]
        },
        {
            paiid : 5,
            cards: [
                {
                    name    : "Maestras Principales",
                    cards : [
                        {
                            title   : "Master Clientes",
                            subtitle: "Master",
                            url     : "upload-file/ch/master-clients",
                            disabled: false,
                            slug    : "bolivia.fileupload.mastercliente"
                        },
                        {
                            title   : "Master Productos",
                            subtitle: "Actual",
                            url     : "upload-file/ch/master-products",
                            disabled: false,
                            slug    : "bolivia.fileupload.masterproducto"
                        },                                
                    ]
                },
                // {
                //     name    : "Area 3",
                //     cards : [
                //         {
                //             title   : "Master Clientes",
                //             subtitle: "Master",
                //             url     : "upload-file/ch/master-clients",
                //             disabled: false,
                //             slug    : "bolivia.fileupload.mastercliente"
                //         },
                //         {
                //             title   : "Master Productos",
                //             subtitle: "Actual",
                //             url     : "upload-file/ch/master-products",
                //             disabled: false,
                //             slug    : "bolivia.fileupload.masterproducto"
                //         },                                
                //         {
                //             title   : "Master Productos",
                //             subtitle: "Actual",
                //             url     : "upload-file/ch/master-products",
                //             disabled: false,
                //             slug    : "bolivia.fileupload.masterproducto"
                //         },                                
                //     ]
                // },
            ]
        },
        {
            paiid : 7,
            cards: [
                {
                    name    : "Maestras Principales",
                    cards : [
                        {
                            title   : "Master Clientes",
                            subtitle: "Master",
                            url     : "upload-file/ch/master-clients",
                            disabled: false,
                            slug    : "andes.fileupload.mastercliente"
                        },
                        {
                            title   : "Master Productos",
                            subtitle: "Actual",
                            url     : "upload-file/ch/master-products",
                            disabled: false,
                            slug    : "andes.fileupload.masterproducto"
                        },                                
                        {
                            title   : "Master Productos",
                            subtitle: "Actual",
                            url     : "upload-file/ch/master-products",
                            disabled: false,
                            slug    : "andes.fileupload.masterproducto"
                        },                                
                    ]
                },
                {
                    name    : "Area Administracion",
                    cards : [
                        {
                            title   : "Master Clientes",
                            subtitle: "Master",
                            url     : "upload-file/ch/master-clients",
                            disabled: false,
                            slug    : "andes.fileupload.mastercliente"
                        },
                        {
                            title   : "Master Productos",
                            subtitle: "Actual",
                            url     : "upload-file/ch/master-products",
                            disabled: false,
                            slug    : "andes.fileupload.masterproducto"
                        },                                
                    ]
                },
            ]
        },
    ],
    rex_cards_upload_file                   : []
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {

        case ITEM_NOTIFICATION_MODAL_UPLOAD_FILE: {
            return {
                ...state,
                rex_item_notification_modal_upload_file : action.payload,
            }
        }
        case SHOW_NOTIFICATION_MODAL_UPLOAD_FILE: {
            return {
                ...state,
                rex_show_notification_modal_upload_file : action.payload,
            }
        }
        case GET_CARDS_UPLOAD_FILES: {
            return {
                ...state,
                rex_cards_upload_file : action.payload,
            }
        }
        case MESSAGES_NOTIFICATIONS_UPLOAD_FILE: {
            return {
                ...state,
                rex_messages_notifications_upload_files : action.payload,
            }
        }
        case REGISTER_USERS_CONNECTED_MODULES_FILE_UPLOAD: {
            return {
                ...state,
                rex_users_file_upload_connected : action.payload,
            }
        }
        default:
            return state;
    }
}