import React from 'react'

const ImageAzure = ({color}) => {
    return (
        <svg width="18" height="14" viewBox="0 0 18 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_1988_2935)">
                <path d="M4.61296 4.57871L10.0698 0L4.19122 12.5284H0L4.61296 4.57871Z" fill={color}/>
                <path d="M8.3153 7.36621L10.5299 1.17383L18.0001 14.0009H4.03418L12.6558 12.5558L8.3153 7.36621Z" fill={color}/>
            </g>
            <defs>
                <clipPath id="clip0_1988_2935">
                    <rect width="18" height="14" fill="white"/>
                </clipPath>
            </defs>
        </svg>
    )
}

const AzureLogo = ({color}) => {
    return (
        <ImageAzure color={color}/>
    )
}

export default AzureLogo