import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom'

const NavigationGlobal = ({menuItems}) => {

    let location = useLocation();
    const navigate = useNavigate();

    const index_ruta = menuItems.findIndex(rut => rut.url === location.pathname);

    if(index_ruta != 0){
        return (        
            <div className='Container-Menu-Navigation-Global'>
                <p className='Title-Menu-Navigation-Global'>Filtro aplicado:</p>
                {
    
                    menuItems.map((fil, index_fill) => {
                        if(index_fill <= index_ruta){
                            return <div
                                    key={index_fill}
                                    className={`Item-Menu-Navigation-Global ${index_ruta == index_fill && 'Item-Menu-Selected-Navigation-Global'}`}
                                    onClick={() => {
                                        navigate(fil.url)
                                    }}
                                    >
                                        &nbsp;<span>{fil.nombre}</span>{index_fill != index_ruta && <p>&nbsp;/</p>}
                                    </div>
                        }
                    })
                }
            </div>
        )
    }
    
    return
}

export default NavigationGlobal