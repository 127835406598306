import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Collapse, Dropdown, Modal, Col, Tooltip } from 'antd'
import { HolderOutlined } from '@ant-design/icons'
import { arrayMove, SortableContext, verticalListSortingStrategy, useSortable } from '@dnd-kit/sortable'
import { DndContext, closestCenter } from '@dnd-kit/core'
import { CSS } from '@dnd-kit/utilities'
import FormNewSubModules from './FormNewSubmodules'
import { ChangeDisabledSubmodulesReducer, ChangeOrderSubsubmodulesReducer, ChangeValueFormReducer, RemoveSubModuleReducer } from '../../../Redux/Actions/Modules/CreateEditModules'
import { DeleteDataSubModuleReducer } from '../../../Redux/Actions/Modules/Modules'
import ListItemsSubSubModules from './ListItemsSubSubModules'

const ListItemsSubModules = (props) => {
    const submodule = props.submodulo
    const lastSubsubmodule = submodule.ssmsubmodulos && submodule.ssmsubmodulos[submodule.ssmsubmodulos.length-1]
    const { confirm } = Modal
    const { Panel } = Collapse
    const { attributes, listeners, setNodeRef, transform, setActivatorNodeRef, isDragging } = useSortable({id: props.id})
    const dispatch = useDispatch()

    const style = {
        transform: CSS.Transform.toString(transform),
    }

    const styleButton = {
        touchAction: 'none',
        cursor: isDragging ? 'grabbing' : 'grab',
    }

    const DragDropSubModule = (event) => {
        const { active, over } = event
        dispatch(ChangeOrderSubsubmodulesReducer(active, over, arrayMove, submodule.key))
    }

    const items = [
        {
            key: '1',
            label:  <span className="Text-Edit-Submodules" onClick={() => dispatch(ChangeValueFormReducer(props.id, true))}>
                        Editar
                        <div className="Icon-Edit-Menu"></div>
                    </span>,
        },
        {
            key: '2',
            label:  <span className="Text-Delete-Submodules" onClick={() => deleteSubModule()}>
                        Eliminar 
                        <div className="Icon-Delete-Menu"></div>
                    </span>,
        },
    ]

    const deleteSubModule = () => {
        confirm({
            title: 'Confirmar',
            zIndex: 100000000,
            icon: <></>,
            content: '¿Estas seguro de eliminar el submódulo?',
            width: 260,
            centered: true,
            className: 'Modal-Delete',
            okButtonProps: {className: 'Confirm-Modal-Delete'},
            okText: <>
                        <span className="First-Confirm">Confirmar</span>
                        <span className="Last-Confirm">Confirmar</span>
                    </>,
            cancelButtonProps: {className: 'Cancel-Modal-Delete'},
            cancelText: <>
                            <span className="First-Cancel">Cancelar</span>
                            <span className="Last-Cancel">Cancelar</span>
                        </>,
            async onOk() {
                if(submodule.smosubmodulosnew){
                    dispatch(RemoveSubModuleReducer(submodule.key))
                }else{
                    const response = await dispatch(DeleteDataSubModuleReducer(submodule.smoid))
                    if(response){
                        dispatch(RemoveSubModuleReducer(submodule.key))
                    }
                }
            },
            onCancel() {
            },
        })
    }

    return(
        <>
            {
                submodule.smoformulario
                ?   !submodule.smosubmodulosnew &&
                    <Col span={24} style={{marginBottom: '4px'}}>
                        <FormNewSubModules 
                            value={submodule}
                        />
                    </Col>
                :   <Col span={24} style={{marginBottom: '0px'}}>
                        <div
                            ref={setNodeRef} 
                            style={style} 
                            {...attributes}
                        >
                            <Collapse
                                className={`Card-Item-SubModules ${submodule.validation ? 'Error-Submodule' : ''} ${!submodule.smovisualizacion ? 'Disabled-Card-Item-SubModules' : ''}`}
                                expandIconPosition="end"
                                collapsible='icon'
                                expandIcon={(e) => 
                                    submodule.ssmsubmodulos.length > 0 
                                    ?<Tooltip
                                        placement='bottom'
                                        title={e.isActive ? 'Cerrar' : 'Desplegar'}
                                        color='#3B72FF'
                                        showArrow={false}
                                        overlayStyle={{marginTop:'20px', fontSize: '12px', minHeight: '30px'}}
                                    >
                                        <div 
                                            className={`Icon-Expand-Item-SubModules ${!submodule.smovisualizacion ? '' : e.isActive ? 'Expand-Rotate-Item-SubModules' : ''}`}
                                            style={{opacity: !submodule.ssmsubmodulos && '0'}}
                                        ></div>
                                    </Tooltip>
                                    :null
                                }
                            >
                                <Panel 
                                    header={
                                        <>
                                            <div className="Container-Text-Item-SubModules">
                                                <Tooltip
                                                    placement='bottom'
                                                    title='Mover'
                                                    color='#3B72FF'
                                                    showArrow={false}
                                                    overlayStyle={{marginTop:'20px', fontSize: '12px', minHeight: '30px'}}
                                                >
                                                    <HolderOutlined 
                                                        className="Icon-DragDrop-Item-SubModules" 
                                                        ref={setActivatorNodeRef} 
                                                        style={styleButton} 
                                                        {...listeners} 
                                                    />
                                                </Tooltip>
                                                <span className={`Text-Item-SubModules ${!submodule.smovisualizacion ? 'Text-Disable-SubModules' : ''}`}>{submodule.smonombre}</span>
                                            </div>
                                            <div className="Container-Icon-Item-SubModules">
                                                {
                                                    !submodule.smovisualizacion
                                                    ?   <Tooltip
                                                            placement='bottom'
                                                            title='Habilitar'
                                                            color='#3B72FF'
                                                            showArrow={false}
                                                            overlayStyle={{marginTop:'20px'}}
                                                        >
                                                            <div
                                                                onClick={() => dispatch(ChangeDisabledSubmodulesReducer(true, submodule.key))}
                                                                className="Icon-View-Disabled-Item-SubModules"
                                                            ></div>
                                                        </Tooltip>
                                                    :   <Tooltip
                                                            placement='bottom'
                                                            title='Deshabilitar'
                                                            color='#3B72FF'
                                                            showArrow={false}
                                                            overlayStyle={{marginTop:'20px'}}
                                                        >
                                                            <div
                                                                onClick={() => dispatch(ChangeDisabledSubmodulesReducer(false, submodule.key))}
                                                                className="Icon-View-Item-SubModules"
                                                            ></div>
                                                        </Tooltip>
                                                }
                                            </div>
                                        </>
                                    }
                                    extra={
                                        <Tooltip
                                            placement='bottom'
                                            title='Opciones'
                                            color='#3B72FF'
                                            showArrow={false}
                                            overlayStyle={{marginTop:'20px', fontSize: '12px', minHeight: '30px'}}
                                        >
                                            <Dropdown
                                                menu={{items}} 
                                                placement="bottomRight"
                                                overlayClassName="Menu-SubModules"
                                                trigger={['click']}
                                            >
                                                <div className="Icon-Menu-Item-SubModules"></div>
                                            </Dropdown>
                                        </Tooltip>
                                    }
                                    key={submodule.key}
                                >
                                    {
                                        submodule.ssmsubmodulos &&
                                        <DndContext
                                            collisionDetection={closestCenter}
                                            onDragEnd={DragDropSubModule}
                                        >
                                            <SortableContext
                                                items={submodule.ssmsubmodulos && submodule.ssmsubmodulos.map(ssm => ssm.key)}
                                                strategy={verticalListSortingStrategy}
                                            >
                                                {
                                                    submodule.ssmsubmodulos.map(ssm => 
                                                        <ListItemsSubSubModules 
                                                            id={ssm.key} 
                                                            subsubmodule={ssm} 
                                                            keysub={submodule.key}
                                                            lastSubsubmodule={lastSubsubmodule}
                                                        />
                                                    )
                                                }
                                            </SortableContext>
                                        </DndContext>
                                    }
                                </Panel>
                            </Collapse>
                        </div>
                    </Col>
            }
        </>
    )
}

export default ListItemsSubModules