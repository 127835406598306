import React, { useState, useEffect } from 'react'
import { useLocation } from 'react-router-dom'
import Index from '../Routes/index'

const App = () => {
    const [timeScroll, setTimeScroll] = useState(true)
    const location = useLocation()

    useEffect(() => {
        if(location.pathname !== "/power-bi"){
            setTimeScroll(false)
        }
    }, [])

    return (
        <div
            style={{
                height: '100vh',
                overflow: timeScroll ? 'inherit' : 'hidden'
            }}
        >
            <Index setTimeScroll={setTimeScroll} />
        </div>
    )
}

export default App