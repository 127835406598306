import React from 'react'
import { Modal, Typography, Row, Col } from 'antd'
import "../../Styles/Components/Global/ModalDetailJsonTable.css"

const ModalDetailJsonTable = (props) => {

    const setOpenModalJson = props.setOpenModalJson
    const dataModalJson = props.dataModalJson
    const openModalJson = props.openModalJson

    const { Title } = Typography

    const data_parse = JSON.parse(dataModalJson)

    const dataEntries = Object.entries(data_parse)

    const messageDetail = ( data) => {
        return <>
            {data.map(([key, value]) => (
                <div key={key}>

                </div>
            ))}
            </>
    }

    return (
        <Modal 
            title={null}
            open={openModalJson}
            closable={false}
            width={550}
            footer={null}
            zIndex={100000000}
            bodyStyle={{maxHeightheight:"400px", minHeight:"200px"}}
            className='Modal-Notification-Detail-Json-Table'
        >
            <Row className='Modal-Container-Text-Detail-Json'>
                <Col span={24} className='Modal-Container-Text-Main'>
                    <Title>
                        Detalle:
                    </Title>
                </Col>
                <Col span={24} className='Modal-Container-Text-Description'>
                    <div>{'{'}</div>
                    {
                        dataEntries.map(([key, value]) => {
                            if(key != 'notificaciones'){
                                return <div className='Modal-Container-Text-Key'>{key} : { typeof(value) == 'object' 
                                ? 
                                <div style={{display:'flex', flexDirection:'column'}}>
                                    <>{' { '}</>
                                    {
                                        Object.entries(value).map(([key_2, value_2]) => (
                                            <>
                                                <div className='Modal-Container-Row-Key-Value'>
                                                    {isNaN(key_2) ? key_2 : parseInt(key_2) + 1} : {value_2.toString()}
                                                </div>
                                            
                                            </>
                                        ))
                                        
                                    }
                                    <>{'}'}</>
                                </div>
                                : <div> {value.toString()}</div> }</div>
                            }
                        })
                    }
                    <div>{'}'}</div>
                </Col>
                <Col span={24} className='Modal-Container-Button-Main'>
                    <div
                        onClick={() => setOpenModalJson(false)}
                        className='Modal-Close-Detail-Json-Table'
                    >
                        Ok
                    </div>
                </Col>
            </Row>
        </Modal>
    )
    
}

export default ModalDetailJsonTable