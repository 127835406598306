import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, DatePicker } from 'antd'
import dayjs from 'dayjs'
import { DownloadOutlined, LoadingOutlined } from '@ant-design/icons'
import { GenerateExcelReducer, RegisterUsersConnectedDownloadDataInternalReducer } from '../../Redux/Actions/DownloadDataInternal/DownloadDataInternal'
import socket from '../../App/socket'
import TitleGlobal from '../../Components/Global/TitleGlobal'
import SideMenu from '../../Components/Global/SideMenu'

const DownloadDataInternal = () => {
    const [loadingDownload, setLoadingDownload] = useState(false)
    const [buttonFeatures, setButtonFeatures] = useState({
        linkFile: "master-clients",
        nombreFile: "Master Clientes.xlsx",
        deleteFile: "master-clients",
    })
    const [ dateFilter, setDateFilter ] = useState(dayjs(dayjs(), "AAAA-MM"))
    const dispatch = useDispatch()

    const { 
        rex_data_users_online
    } = useSelector(({users}) => users)

    const { 
        rex_data_user_logingrow
    } = useSelector(({loginGrow}) => loginGrow)

    const {
        rex_users_donwload_data_internal_connected,
    } = useSelector(({downloadDataInternal}) => downloadDataInternal)

    useEffect(() => {
        socket.on('get-users-modules', (data_users) => {
            dispatch(RegisterUsersConnectedDownloadDataInternalReducer(data_users))
        });

        return () => {
            socket.emit('unreg-modules', { module: 'status'}, {usuusuario: rex_data_user_logingrow.usuusuario} )
            socket.off('get-users-modules');
        }
    },[])

    return(
        <div className="Container-Main" style={{paddingBottom: '5px'}}>
            <Row style={{marginBottom: '10px'}}>
                <Col span={24}>
                    <TitleGlobal 
                        titleSection="Descarga de Data Interno"
                        paragraphSection="Recuerda que solo podrás visualizar los archivos que tienes disponible, pero solo podrás descargarlo desde tu desktop"
                    />
                </Col>
            </Row>
            <Row style={{display:"flex", justifyContent:"space-between"}}>
                <Col className="Col-Button">
                    <div className="Container-Button-Excel">
                        <button 
                            className={`Button-Excel ${buttonFeatures.linkFile == "master-clients" ? "Button-Excel-Active" : ""}`}
                            onClick={() => 
                                setButtonFeatures({
                                    linkFile: "master-clients",
                                    nombreFile: "Master Clientes.xlsx",
                                    deleteFile: "master-clients",
                                })
                            }
                        >
                            Master Clientes
                        </button>
                        <button 
                            className={`Button-Excel ${buttonFeatures.linkFile == "master-products" ? "Button-Excel-Active" : ""}`}
                            onClick={() => 
                                setButtonFeatures({
                                    linkFile: "master-products",
                                    nombreFile: "Master Productos.xlsx",
                                    deleteFile: "master-products",
                                })
                            }
                        >
                            Master Productos
                        </button>
                        <button 
                            className={`Button-Excel ${buttonFeatures.linkFile == "drivers" ? "Button-Excel-Active" : ""}`}
                            onClick={() => 
                                setButtonFeatures({
                                    linkFile: "drivers",
                                    nombreFile: "Drivers.xlsx",
                                    deleteFile: "drivers",
                                })
                            }
                        >
                            Drivers
                        </button>
                    </div>

                </Col>
                <Col className="Col-Filter-Date-Download-Data-Internal">
                    <div style={{display: "flex", justifyContent: "space-between", columnGap:"10px"}}>
                        <DatePicker
                            picker='month'
                            style={{width:"100px"}}
                            popupClassName='DatePicker-Filter-Download-Data-Internal'
                            placeholder='Fecha'
                            defaultValue={dateFilter}
                            onChange={(value, dateString) => {
                                setDateFilter(dateString)
                            }}
                        />
                        <button
                            className="Button-Download"
                            onClick={async () => {
                                setLoadingDownload(true)
                                const fileUrl = await dispatch(GenerateExcelReducer(buttonFeatures.linkFile, dateFilter))
                                const link = document.createElement('a')
                                link.href = fileUrl
                                link.download = buttonFeatures.nombreFile
                                link.click()
                                setLoadingDownload(false)
                            }}
                        >
                            <div className="Icon-Container">
                                {
                                    loadingDownload
                                    ? <LoadingOutlined className="Icon-Download" /> 
                                    : <DownloadOutlined className="Icon-Download" /> 
                                }
                            </div>
                            <span>Descargar</span>
                        </button>
                    </div>
                </Col>
            </Row>
            {
                rex_users_donwload_data_internal_connected && 
                    <SideMenu
                        usersCurrentView  = {rex_users_donwload_data_internal_connected}
                        usersAllConnected = {rex_data_users_online}
                    />
            }
        </div>
    )
}

export default DownloadDataInternal