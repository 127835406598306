import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Col, Checkbox, Collapse } from 'antd'
import { SelectedModuleSpecificAllReducer, SelectedModulesAllReducer, SelectedSubModuleSpecificAllReducer, SelectedSubSubModuleSpecificAllReducer } from '../../Redux/Actions/ProfileTypes/ProfileTypes'

const UserModules = () => {
    const { Panel } = Collapse   

    const {
        rex_data_modules_profile_clone,
        rex_check_all_module_country,
        rex_selected_country_profile_type,
    } = useSelector(({profileTypes}) => profileTypes)

    const dispatch = useDispatch()
    
    const HeaderCollapse = (type, text, id, checkbox, modulo_id) => (
        <div className={
            `Header-Colapse-Create-Profile 
            ${type == "module" 
                ? checkbox ? 'Check-Active-Module' : '' 
                : checkbox ? 'Check-Active-Submodule' : ''}
        `}>
            <Checkbox
                onClick={(e) => {
                    e.stopPropagation()
                    if(type == "module"){
                        dispatch(SelectedModuleSpecificAllReducer(id, e.target.checked, rex_selected_country_profile_type))
                    }else{
                        dispatch(SelectedSubModuleSpecificAllReducer(id, e.target.checked, modulo_id, rex_selected_country_profile_type))
                    }
                }}
                checked={checkbox}
            ></Checkbox>
            <span className='Text-Header-Collapse-Create-Profile'>{text}</span>
        </div>
    )

    return(
        <>
        <Col xs={{span:24}} sm={{span:24}} md={{span:24}} lg={{span:24}} xl={{span:24}}>
            <div className="Container-All-User-Modules">
                <Checkbox
                    onChange={(e) => dispatch(SelectedModulesAllReducer(e.target.checked, rex_selected_country_profile_type))}
                    className='Checkbox-SelectAll-Select-Module'
                    checked={
                        rex_check_all_module_country.find(cou => cou == rex_selected_country_profile_type ? true : false)
                    }
                >
                    Seleccionar todos los módulos
                </Checkbox>
            </div>
        </Col>
        <Col xs={{span:24}} sm={{span:24}} md={{span:24}} lg={{span:12}} xl={{span:12}} style={{paddingRight: '10px'}}>
            <div className="Container-User-Modules">
                {
                    rex_selected_country_profile_type
                    ? rex_data_modules_profile_clone.map((module, index) => {
                        let cantidad_modules = 0
                        const filter_modules_clone = rex_data_modules_profile_clone.filter(mod_pro => mod_pro.paiid == rex_selected_country_profile_type).filter(mod_pro => {
                            if(cantidad_modules <= 7){
                                cantidad_modules = cantidad_modules + 1
                                return true
                            }
                            return false
                        })
                        return(
                            filter_modules_clone.find(mod_pro => mod_pro.key == module.key)
                            ?   <Collapse 
                                    className={`Collapse-Create-Profile ${module.smosubmodulos.length == 0 ? 'Icon-Hide-Profile' : ''}`}
                                    expandIconPosition="end" 
                                    expandIcon={() => (<div className={`Icon-Arrow-Profile ${!module.mod_checked ? 'Module-Active-Icon-Arrow' : ''}`}></div>)}
                                    key={index}
                                >
                                    <Panel header={HeaderCollapse("module", module.modnombre, module.key, module.mod_checked)}>
                                        {
                                            module.smosubmodulos.map((submodule, indexsmo) => 
                                                submodule.ssmsubmodulos.length == 0
                                                ? <Checkbox 
                                                    className={`Submodule-Item ${submodule.submod_checked ? 'Check-Active-Submodule' : ''}`}
                                                    key={indexsmo}
                                                    checked={submodule.submod_checked}
                                                    onClick={(e) => dispatch(SelectedSubModuleSpecificAllReducer(submodule.smoid, e.target.checked, module.key, rex_selected_country_profile_type))}
                                                >
                                                    {submodule.smonombre}
                                                </Checkbox>
                                                : <Collapse 
                                                        className='Collapse-Submodule-Profile Submodule-Profile' 
                                                        expandIconPosition="end" 
                                                        expandIcon={() => (<div className={`Icon-Arrow-Profile ${!submodule.submod_checked ? 'Submodule-Active-Icon-Arrow' : ''}`}></div>)}
                                                        key={indexsmo}
                                                    >
                                                        <Panel header={HeaderCollapse("submodule", submodule.smonombre, submodule.smoid, submodule.submod_checked, module.key)}>
                                                            {
                                                                submodule.ssmsubmodulos.map((subsubmodule, indexssm) => <Checkbox 
                                                                    className={`Subsubmodule-Item ${subsubmodule.subsubmod_checked ? 'Check-Active-Subsubmodule' : ''}`} 
                                                                    key={indexssm}
                                                                    checked={subsubmodule.subsubmod_checked}
                                                                    onClick={(e) => dispatch(SelectedSubSubModuleSpecificAllReducer(subsubmodule.ssmid, e.target.checked, module.key, submodule.smoid, rex_selected_country_profile_type))}
                                                                >
                                                                    {subsubmodule.ssmnombre}
                                                                </Checkbox>)
                                                            }
                                                        </Panel>
                                                </Collapse>
                                            )
                                        }
                                    </Panel>
                                </Collapse>
                            : null
                        )
                    })
                    : rex_data_modules_profile_clone.map((module, index) => {
                        let cantidad_modules = 0
                        const filter_modules_clone = rex_data_modules_profile_clone.filter(mod_pro => mod_pro.paiid == rex_data_modules_profile_clone[0]['paiid']).filter(mod_pro => {
                            if(cantidad_modules <= 7){
                                cantidad_modules = cantidad_modules + 1
                                return true
                            }
                            return false
                        })
                        return(
                            filter_modules_clone.find(mod_pro => mod_pro.key == module.key)
                            && <div className="Collapse-Disabled-Profile">
                                <Collapse 
                                    className={`Collapse-Create-Profile ${module.smosubmodulos.length == 0 ? 'Icon-Hide-Profile' : ''}`}
                                    expandIconPosition="end" 
                                    expandIcon={() => <div className="Icon-Arrow-Profile Disabled-Icon-Arrow"></div>}
                                    key={index}
                                    collapsible='disabled'
                                >
                                    <Panel header={HeaderCollapse("module", module.modnombre, module.key, module.mod_checked)} collapsible='disabled' />
                                </Collapse>
                            </div>
                        )
                    })
                }
            </div>
        </Col>
        <Col xs={{span:24}} sm={{span:24}} md={{span:24}} lg={{span:12}} xl={{span:12}} style={{paddingLeft: '10px'}}>
            <div className="Container-User-Modules">
                {
                    rex_selected_country_profile_type
                    ? rex_data_modules_profile_clone.map((module, index) => {
                        let cantidad_modules = 0
                        const filter_modules_clone = rex_data_modules_profile_clone.filter(mod_pro => mod_pro.paiid == rex_selected_country_profile_type).filter(mod_pro => {
                            if(cantidad_modules > 7){
                                cantidad_modules = cantidad_modules + 1
                                return true
                            }else{
                                cantidad_modules = cantidad_modules + 1
                            }
                            return false
                        })
                        return(
                            filter_modules_clone.find(mod_pro => mod_pro.key == module.key)
                            ?   <Collapse 
                                    className={`Collapse-Create-Profile ${module.smosubmodulos.length == 0 ? 'Icon-Hide-Profile' : ''}`}
                                    expandIconPosition="end" 
                                    expandIcon={() => (<div className={`Icon-Arrow-Profile ${!module.mod_checked ? 'Module-Active-Icon-Arrow' : ''}`}></div>)}
                                    key={index}
                                >
                                    <Panel header={HeaderCollapse("module", module.modnombre, module.key, module.mod_checked)}>
                                        {
                                            module.smosubmodulos.map((submodule, indexsmo) => 
                                                submodule.ssmsubmodulos.length == 0
                                                ? <Checkbox 
                                                    className={`Submodule-Item ${submodule.submod_checked ? 'Check-Active-Submodule' : ''}`}
                                                    key={indexsmo}
                                                    checked={submodule.submod_checked}
                                                    onClick={(e) => dispatch(SelectedSubModuleSpecificAllReducer(submodule.smoid, e.target.checked, module.key, rex_selected_country_profile_type))}
                                                >
                                                    {submodule.smonombre}
                                                </Checkbox>
                                                : <Collapse 
                                                        className='Collapse-Submodule-Profile Submodule-Profile' 
                                                        expandIconPosition="end" 
                                                        expandIcon={() => (<div className={`Icon-Arrow-Profile ${!submodule.submod_checked ? 'Submodule-Active-Icon-Arrow' : ''}`}></div>)}
                                                        key={indexsmo}
                                                    >
                                                        <Panel header={HeaderCollapse("submodule", submodule.smonombre, submodule.smoid, submodule.submod_checked, module.key)}>
                                                            {
                                                                submodule.ssmsubmodulos.map((subsubmodule, indexssm) => <Checkbox 
                                                                    className={`Subsubmodule-Item ${subsubmodule.subsubmod_checked ? 'Check-Active-Subsubmodule' : ''}`} 
                                                                    key={indexssm}
                                                                    checked={subsubmodule.subsubmod_checked}
                                                                    onClick={(e) => dispatch(SelectedSubSubModuleSpecificAllReducer(subsubmodule.ssmid, e.target.checked, module.key, submodule.smoid, rex_selected_country_profile_type))}
                                                                >
                                                                    {subsubmodule.ssmnombre}
                                                                </Checkbox>)
                                                            }
                                                        </Panel>
                                                </Collapse>
                                            )
                                        }
                                    </Panel>
                                </Collapse>
                            : null
                        )
                    })
                    : rex_data_modules_profile_clone.map((module, index) => {
                        let cantidad_modules = 0
                        const filter_modules_clone = rex_data_modules_profile_clone.filter(mod_pro => mod_pro.paiid == rex_data_modules_profile_clone[0]['paiid']).filter(mod_pro => {
                            if(cantidad_modules > 7){
                                cantidad_modules = cantidad_modules + 1
                                return true
                            }else{
                                cantidad_modules = cantidad_modules + 1
                            }
                            return false
                        })
                        return(
                            filter_modules_clone.find(mod_pro => mod_pro.key == module.key)
                            && <div className="Collapse-Disabled-Profile">
                                <Collapse 
                                    className={`Collapse-Create-Profile ${module.smosubmodulos.length == 0 ? 'Icon-Hide-Profile' : ''}`}
                                    expandIconPosition="end" 
                                    expandIcon={() => <div className="Icon-Arrow-Profile Disabled-Icon-Arrow"></div>}
                                    key={index}
                                    collapsible='disabled'
                                >
                                    <Panel header={HeaderCollapse("module", module.modnombre, module.key, module.mod_checked)} collapsible='disabled' />
                                </Collapse>
                            </div>
                        )
                    })
                }
            </div>
        </Col>
        </>
    )
}

export default UserModules