import {
    GET_DATA_CONTROL,
    GET_DATA_CONTROL_CLONE,
    GET_INFO_DATA_CONTROL_EDITED,
    LOADING_DATA_CONTROL
} from "../../../Constants/DataControl/DataControl";

const INIT_STATE = {
    rex_data_control                : [],
    rex_data_control_clone          : [],
    rex_loading_data_control        : false,
    rex_info_data_control_edited    : {
        countries: []
    }
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {

        case LOADING_DATA_CONTROL: {
            return {
                ...state,
                rex_loading_data_control : action.payload,
            }
        }
        case GET_DATA_CONTROL: {
            return {
                ...state,
                rex_data_control : action.payload,
            }
        }
        case GET_DATA_CONTROL_CLONE: {
            return {
                ...state,
                rex_data_control_clone : action.payload,
            }
        }
        case GET_INFO_DATA_CONTROL_EDITED: {
            return {
                ...state,
                rex_info_data_control_edited : action.payload,
            }
        }
        default:
            return state;
    }
}