import React from 'react'
import { useNavigate } from 'react-router'
import { Button } from 'antd'
import {
    PlusCircleOutlined 
} from '@ant-design/icons'
import { useDispatch } from 'react-redux'
import { CleanDataCreateUserReducer } from '../../Redux/Actions/Users/Users'

const ButtonCreateGlobal = ({nameRoute, txtButton}) => {

    let navigate = useNavigate()
    const dispatch = useDispatch()

    return (
        <Button
            className='Button-Create-Global'
            type="primary"
            shape="round"
            size='large'
            onClick={() => {
                dispatch(CleanDataCreateUserReducer())
                navigate(`${nameRoute}`)
            }}
        >
            <PlusCircleOutlined style={{fontSize:'20px'}}/>
            <span>{txtButton ? txtButton : 'Nuevo'}</span>
        </Button>
    )
}

export default ButtonCreateGlobal