import React, { useState } from 'react'
import { Row, Col, Typography, Input } from 'antd'
import {
    ArrowLeftOutlined
} from '@ant-design/icons';
import { Link, useNavigate } from 'react-router-dom';

const TitleSearchGlobal = ({ title, subTitle, setTxtFilter = "", funtionFilter, showInputSearch = true }) => {

    const { Title } = Typography

    const navigate = useNavigate()

    const [ txtValue, setTxtValue ] = useState('')

    const changueTxtValue = (e) => {
        setTxtFilter(e.target.value)
        setTxtValue(e.target.value)
        funtionFilter(e.target.value, 'txtBuscar')
    }

    return (
        <Row>
            <Col style={{display:"flex", justifyContent:"space-between", alignItems: "center"}} span={24}>
                <div style={{display:'flex'}}>
                    <Title level={2} className='Title-Search-Text-Global'>
                        <div
                            style={{
                                marginRight:'10px',
                                cursor:'pointer'
                            }}
                        >
                            <Link
                                to={-1}
                                state={43}
                            >
                                <ArrowLeftOutlined />
                            </Link>
                        </div>
                        {title}<span 
                                    style={{
                                        marginLeft:"5px",
                                        fontSize:"10px",
                                        display:"flex",
                                        marginBottom:"-3px",
                                        alignItems:"center"}}
                                >{subTitle && `(${subTitle})`}</span>
                    </Title>
                </div>
                <div style={{marginRight:"10px", position:"relative", bottom:"-8px"}}>
                    
                    {/* {subTitle && <Title level={2} className='SubTitle-Search-Text-Global'>{subTitle}</Title>} */}
                </div>
            </Col>
            {
                showInputSearch && <Col span={24} style={{marginTop: '5px'}}>
                    <Input
                        onChange={(e)=> changueTxtValue(e)}
                        size='large'
                        placeholder='Buscar'
                        className='Title-Search-Input-Global'
                    />
                </Col>
            }
            
        </Row>
    )
}

export default TitleSearchGlobal