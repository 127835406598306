import React, { useState, useEffect } from 'react'
import { Card, Progress, Typography, Tag, Select } from 'antd'
import moment from 'moment'
import ModalDetailArea from './ModalDetailArea'
import { Element } from 'react-scroll'
import { useDispatch, useSelector } from 'react-redux'
import { 
    EditStatePendingStatesReducer,
    GetInformationStatusDTReducer
} from '../../Redux/Actions/Status/Status'
const loadImage = require.context("../../Assets/Images/Status", true)

const CardDescriptionStatus = (props) => {
    
    const [ showModalDetailArea, setShowModalDetailArea ] = useState(false)
    const [ nameArea, setNameArea ]     = useState('')
    const [ nameAreaBD, setNameAreaBD]  = useState('')
    const [ dataModal, setDataModal]    = useState([])

    const { arenombre, aremostrar, areporcentajeinicial, areresponsable, areicono, stsstatus, aremodal } = props.descriptionStatus

    const { stsnombre } = stsstatus

    const { Title } = Typography
    const dispatch = useDispatch()

    const validateUpdateToday = (updateDate) => {
        let dateEsp = moment(updateDate).utc().format("DD/MM/YYYY");
        let currentDate = moment().utc().format("DD/MM/YYYY")

        return dateEsp == currentDate
    }


    const {
        rex_data_areas_databases_status,
        rex_state_estados_pendientes
    } = useSelector(({status}) => status)

    const { 
        rex_data_user_logingrow
    } = useSelector(({loginGrow}) => loginGrow);

    useEffect(() => {

    }, [rex_data_areas_databases_status]);
    
    return(

        <Element name={areresponsable}>
            <div className={`${aremostrar ? "" : "Card-Description-Status-Not-Avaliable"}`}>
                <div className="Header-Description-Status">
                    <div className='Header-Container-Title-Status'>
                        <img src={loadImage(`./${areicono}`)} alt={areicono}/>
                        <Title
                            level={3}
                            className="Title-Description-Status"
                        >
                            {arenombre}
                        </Title>
                    </div>

                    <div className='Header-Container-Progress-Status'>
                        <Progress 
                            className="Linear-Description-Status"
                            percent={areporcentajeinicial} 
                            strokeColor={areporcentajeinicial <= 70 ? '#FA382D' : areporcentajeinicial <= 95 ? '#FFC700' : '#2AD295'}
                            trailColor="#C4D5FF"
                        />
                    </div>
                </div>
                <Card className="Card-Description-Status">

                    <table className="Table-Description-Status">
                        <thead>
                            <tr>
                                <th>
                                    <small className="Text-Head-Description-Status">Base de datos</small>
                                </th>
                                <th>
                                    <small className="Text-Head-Description-Status">Owner</small>
                                </th>
                                <th>
                                    <small className="Text-Head-Description-Status">Usuario</small>
                                </th>
                                <th>
                                    <small className="Text-Head-Description-Status">DeadLine</small>
                                </th>
                                <th style={{width:'15%'}}>
                                    <small className="Text-Head-Description-Status">DeadLine Actualizado</small>
                                </th>
                                <th>
                                    <small className="Text-Head-Description-Status">Días de Retraso</small>
                                </th>
                                <th>
                                    <small className="Text-Head-Description-Status">Status</small>
                                </th>
                                {
                                    stsnombre == "PDV Homologado" && arenombre == "Data Science Grow" && (rex_data_user_logingrow.usuusuario == "milagros.cordova@grow-analytics.com.pe" || rex_data_user_logingrow.usuusuario == "admin@admin.com")
                                    ? <th>
                                        <small className="Text-Head-Description-Status">Estado</small>
                                    </th>
                                    : null
                                }
                            </tr>
                        </thead>
                        <tbody>
                            {
                                props.descriptionStatus.esps.map((esp, index_esp) => 
                                    <tr key={index_esp}>
                                        <td width={180}><div>{esp.espbasedato}</div></td>
                                        <td width={150}>{esp.espresponsable ? esp.espresponsable : ''}</td>
                                        <td width={150}>{esp.perpersonas ? esp.perpersonas.pernombrecompleto : ''}</td>
                                        <td width={120}>{moment(esp.espfechaprogramado).utc().format('DD/MM/YY')}</td>
                                        
                                        <td width={120}>{esp.espfechactualizacion ? moment(esp.espfechactualizacion).utc().format('DD/MM/YY') : ''}</td>

                                        <td width={150}>
                                            <Tag className={`Tag-Table-Description-Status ${esp.espdiaretraso == 0 ? "Green-Description-Status" : "Red-Description-Status"}`}>{esp.espdiaretraso} días</Tag>
                                        </td>
                                        <td width={100}>
                                            <span className={`Text-Status-Table ${
                                                esp.espbasedato == "Plantilla Promo PDV"
                                                ? esp.espfechactualizacion 
                                                    ? validateUpdateToday(esp.espfechactualizacion) 
                                                        ? "Yellow-Status-Table" 
                                                        : "Green-Status-Table"
                                                    : "Red-Status-Table" 
                                                : esp.espfechactualizacion != null? "Green-Status-Table" : "Red-Status-Table"}`}>
                                                {
                                                    esp.espbasedato == "Plantilla Promo PDV"
                                                    ? esp.espfechactualizacion ? validateUpdateToday(esp.espfechactualizacion) ? "Actualizado": "Cargado" : "Pendiente"
                                                    : esp.espfechactualizacion != null ? "Cargado" : "Pendiente"
                                                }
                                            </span>
                                        </td>
                                        {
                                            esp.espbasedato == "Análisis de datos" && (rex_data_user_logingrow.usuusuario == "milagros.cordova@grow-analytics.com.pe" || rex_data_user_logingrow.usuusuario == "admin@admin.com")
                                            ? <td width={150}>
                                                <Select
                                                    value={esp.eepid}
                                                    showArrow={false}
                                                    onChange={async(value) => {
                                                        await dispatch(EditStatePendingStatesReducer(esp.espid, value))
                                                    }}
                                                    popupClassName="Card-Description-Status-Button-State-PopUp"
                                                    className={`
                                                            Card-Description-Status-Button-State
                                                            ${esp.eepid == 1
                                                                ? " Error-State"
                                                                :   esp.eepid == 2 
                                                                    ? " Alert-State"
                                                                    :   esp.eepid == 3
                                                                        ? " Success-State"
                                                                        : ""
                                                            }`}
                                                    options={rex_state_estados_pendientes}
                                                />
                                            </td>
                                            : null
                                        }
                                    </tr>
                                )
                            }
                            {
                                aremodal 
                                ? <tr>
                                    <td width={180}>
                                        <button
                                            className={`Button-Show-More ${aremostrar ? "" : "Card-Description-Status-Not-Avaliable"}`}
                                            onClick={async () => {
                                                if(aremostrar){
                                                    setShowModalDetailArea(true)
                                                    setNameArea(props.descriptionStatus['esps'][0]['espbasedato'])
                                                    setNameAreaBD(arenombre+"-"+stsstatus.stsnombre)
                                                    let response = await dispatch(GetInformationStatusDTReducer(props.descriptionStatus.areid,props.descriptionStatus))
                                                }
                                            }}
                                        >
                                            <span className="Text-Span">Ver detalle</span>
                                            <span className="Text-Span-Table-Status">Ver detalle</span>
                                            <div className="Circle-Button-Table-Status"></div>
                                        </button>
                                    </td>
                                </tr>
                                : null
                            }
                            
                        </tbody>
                    </table>
                    <ModalDetailArea
                        showModalDetailArea={showModalDetailArea}
                        setShowModalDetailArea={setShowModalDetailArea}
                        nameArea={nameArea}
                        nameAreaBD={nameAreaBD}
                        dataModal={dataModal}
                        setDataModal={setDataModal}
                    />
                </Card>
            </div>
        </Element>
    )
}

export default CardDescriptionStatus
