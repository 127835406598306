import React, { useState, useEffect } from 'react'
import { Typography, Card, Row, Col } from 'antd'
import CardSubModuleItem6 from './CardSubModuleItem7'
import config from '../../../config'

const CardSubModule7 = ({submodule, titlemodule, activeModule, modid, module}) => {
    const { Title } = Typography
    const [activeSub, setActiveSub] = useState(null)
    const [activeSubText, setActiveSubText] = useState("Default-Active")
    const [activeCard, setActiveCard] = useState("")
    const [backgroundColor, setBackgroundColor] = useState('#AF88FD')

    useEffect(() => {
        setActiveSub(null)
        setActiveSubText("Default-Active")
        setActiveCard("")
    }, [activeModule])

    useEffect(() => {
        const updateColor = () => {
            const now = new Date()
            const hour = now.getHours()
        
            if(hour >= 6 && hour < 12){
                setBackgroundColor('#A9D2FE')
            }else if(hour >= 12 && hour < 18){
                setBackgroundColor('#FDCA0F')
            }else{
                setBackgroundColor('#AF88FD')
            }
        }
    
        updateColor()
        const interval = setInterval(updateColor, 60 * 1000)
    
        return () => {
            clearInterval(interval)
        }
    }, [])

    return(
        <div className={`Submodule-7 ${activeSub !== null ? `subsub-${activeSub}` : ''}`}>
            <Row>
                <Col sm={24} md={24} lg={8}>
                    <div className='Container-Image-Title'>
                        <Card className={`Container-Card-Categories ${backgroundColor == "#A9D2FE" ? 'Shadow-Bluesky' : backgroundColor == "#FDCA0F" ? 'Shadow-Yellow' : 'Shadow-Purple'}`}>
                            <div className="Card-Background" style={{backgroundColor: backgroundColor}}></div>
                                <div className="Container-Round"></div>
                                <img 
                                    src={ config.api_url+module.modicono } 
                                    className="Image-Item-Categories"
                                    style={{
                                        borderRadius : '100%'
                                    }}
                                />
                        </Card>
                        <Title 
                            level={3}
                            className="Title-Item-Categories"
                        >
                            {titlemodule}
                        </Title>
                    </div>
                </Col>
            </Row>
            <div className="Container-Submodule">
                {
                    submodule.map((smo, index) => 
                    <CardSubModuleItem6 
                        submodule={smo} 
                        modid={modid} 
                        activeSub={activeSub} 
                        setActiveSub={setActiveSub} 
                        activeSubText={activeSubText} 
                        setActiveSubText={setActiveSubText} 
                        activeCard={activeCard} 
                        setActiveCard={setActiveCard}  
                        index={index} 
                        key={index} 
                    />)
                }
            </div>
        </div>
    )
}

export default CardSubModule7