export const GET_DATA_MASTER_PRODUCTS_MANAGE        = 'GET_DATA_MASTER_PRODUCTS_MANAGE'
export const GET_DATA_MASTER_PRODUCTS_MANAGE_CLONE  = 'GET_DATA_MASTER_PRODUCTS_MANAGE_CLONE'
export const GET_DATA_MASTER_PRODUCT_MANAGE         = 'GET_DATA_MASTER_PRODUCT_MANAGE'
export const GET_DATA_MASTER_PRODUCT_MANUALS_MANAGE = 'GET_DATA_MASTER_PRODUCT_MANUALS_MANAGE'

export const DATE_LAST_UPDATED_PRODUCTS_MANAGE       = 'DATE_LAST_UPDATED_PRODUCTS_MANAGE'

export const LOADING_GET_DATA_MASTER_PRODUCTS_MANAGE = 'LOADING_GET_DATA_MASTER_PRODUCTS_MANAGE'
export const LOADING_GET_DATA_MASTER_PRODUCTS_MANUAL_MANAGE = 'LOADING_GET_DATA_MASTER_PRODUCTS_MANUAL_MANAGE'

export const SHOW_MODAL_ALERT_DELETE_PRODUCTS = 'SHOW_MODAL_ALERT_DELETE_PRODUCTS'
export const DATA_ALERT_DELETE_PRODUCTS = 'DATA_ALERT_DELETE_PRODUCTS'
export const DATA_FORM_EDIT_MASTER_PRODUCT = 'DATA_FORM_EDIT_MASTER_PRODUCT'
export const DATA_FORM_EDIT_MASTER_PRODUCT_MANUAL = 'DATA_FORM_EDIT_MASTER_PRODUCT_MANUAL'
export const DATA_FORM_EDIT_MASTER_PRODUCT_MSL = 'DATA_FORM_EDIT_MASTER_PRODUCT_MSL'

// OBTENER FILTROS DE MAESTRA PRODUCTOS
export const GET_DATA_FILTERS_CHANNELS_MASTER_PRODUCTS_MANAGE = 'GET_DATA_FILTERS_CHANNELS_MASTER_PRODUCTS_MANAGE'
export const GET_DATA_FILTERS_ZONA_MASTER_PRODUCTS_MANAGE = 'GET_DATA_FILTERS_ZONA_MASTER_PRODUCTS_MANAGE'
export const GET_DATA_FILTERS_CLIENTES_MASTER_PRODUCTS_MANAGE = 'GET_DATA_FILTERS_CLIENTES_MASTER_PRODUCTS_MANAGE'
export const GET_DATA_FILTERS_SUCURSAL_MASTER_PRODUCTS_MANAGE = 'GET_DATA_FILTERS_SUCURSAL_MASTER_PRODUCTS_MANAGE'
export const GET_DATA_FILTERS_GBA_MASTER_PRODUCTS_MANAGE = 'GET_DATA_FILTERS_GBA_MASTER_PRODUCTS_MANAGE'
export const GET_DATA_FILTERS_CUSTOMER_GROUP_MASTER_PRODUCTS_MANAGE = 'GET_DATA_FILTERS_CUSTOMER_GROUP_MASTER_PRODUCTS_MANAGE'
export const GET_DATA_FILTERS_TXT_CUSTOMER_GROUP_MASTER_PRODUCTS_MANAGE = 'GET_DATA_FILTERS_TXT_CUSTOMER_GROUP_MASTER_PRODUCTS_MANAGE'

export const GET_DATA_FILTERS_TXT_CHANNELS_MASTER_PRODUCTS_MANAGE = 'GET_DATA_FILTERS_TXT_CHANNELS_MASTER_PRODUCTS_MANAGE'
export const GET_DATA_FILTERS_TXT_ZONA_MASTER_PRODUCTS_MANAGE = 'GET_DATA_FILTERS_TXT_ZONA_MASTER_PRODUCTS_MANAGE'
export const GET_DATA_FILTERS_TXT_CLIENTES_MASTER_PRODUCTS_MANAGE = 'GET_DATA_FILTERS_TXT_CLIENTES_MASTER_PRODUCTS_MANAGE'
export const GET_DATA_FILTERS_TXT_SUCURSAL_MASTER_PRODUCTS_MANAGE = 'GET_DATA_FILTERS_TXT_SUCURSAL_MASTER_PRODUCTS_MANAGE'
export const GET_DATA_FILTERS_TXT_GBA_MASTER_PRODUCTS_MANAGE = 'GET_DATA_FILTERS_TXT_GBA_MASTER_PRODUCTS_MANAGE'

export const GET_DATA_FILTERS_TXT_PRODUCTS_TOTAL = "GET_DATA_FILTERS_TXT_PRODUCTS_TOTAL"


export const GET_DATA_FILTERS_GBA = "GET_DATA_FILTERS_GBA"
export const GET_DATA_FILTERS_CHANNEL = "GET_DATA_FILTERS_CHANNEL"
export const GET_DATA_FILTERS_ZONE = "GET_DATA_FILTERS_ZONE"
export const GET_DATA_FILTERS_CLIENT = "GET_DATA_FILTERS_CLIENT"
export const GET_DATA_FILTERS_INCENTIVES  = "GET_DATA_FILTERS_INCENTIVES"
