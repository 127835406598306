import {
    REGISTER_USERS_CONNECTEDS_DASHBOARDS
} from "../../../Constants/Dashboards/Dashboards";


export const RegisterUsersConnectedReducer = ( list_users ) => async ( dispatch, getState ) => {

    const limit_users = 3
    let number_users_hidden = 0
    let users_shows = list_users

    if( list_users.length > limit_users){
        number_users_hidden = list_users.length - limit_users
        users_shows = [list_users[0], list_users[1], list_users[2]]
    }

    dispatch({
        type: REGISTER_USERS_CONNECTEDS_DASHBOARDS,
        payload: {
            users : list_users,
            users_shows : users_shows,
            number_hidden : number_users_hidden,
        }
    })
}

