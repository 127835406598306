import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Switch, Modal, Col, Typography, Table, Pagination, Input } from 'antd'
import { useNavigate} from "react-router-dom";
import FilterSelectGlobal from '../../Components/Global/FilterSelectGlobal'
import { 
    CleanDataCreateUserReducer,
    DeleteUsersReducer,
    DownloadDataUsersReducer,
    EditStatusUserReducer, 
    FilterUsersReducer,
    GetDataEditUserReducer,
    GetUsersReducer,
    RegisterUsersConnectedUsersReducer
} from '../../Redux/Actions/Users/Users'
import { ClearSelectedPersonalizeTypeProfileReducer, ObtenerTiposPerfilesReducer } from '../../Redux/Actions/ProfileTypes/ProfileTypes'
import NotificationDeleted from '../../Components/Users/NotificationDeleted'
import NotificationErrorServer from '../../Components/Users/NotificationErrorServer'
import moment from "moment";
import socket from '../../App/socket'
import { useIdleTimer } from 'react-idle-timer'
import { ValidationUserReducer } from '../../Redux/Actions/Validations/Validations'
import SideMenu from '../../Components/Global/SideMenu'
import ButtonGlobal from '../../Components/Global/ButtonGlobal';
import NavigationGlobal from '../../Components/Global/NavigationGlobal';
import SimpleTableGlobal from '../../Components/Global/SimpleTableGlobal';
import ProfileTypesFilterSelect from '../../Components/ProfileTypes/ProfileTypesFilterSelect';
import TrashButtonGlobal from '../../Assets/Images/Global/TrashButtonGlobal';
import DownloadButtonGlobal from '../../Assets/Images/Global/DownloadButtonGlobal';
import { 
    FilterOutlined,
} from '@ant-design/icons'

const Users = () => {

    const timeout = 300000
    const dispatch = useDispatch()
    let navigate = useNavigate()

    const { confirm } = Modal
    const { Title } = Typography

    const [ paiFilter, setPaiFilter ] = useState([])
    const [ mouseOverRow ,setMouseOverRow] = useState(null)
    const [ tpuFilter, setTpuFilter ] = useState([])
    const [ txtFilter, setTxtFilter ] = useState('')
    const [ selectedRowKeys, setSelectedRowKeys ] = useState([])
    const [ keyRowAction, setKeyRowAction ] = useState(null)
    const [ showModal, setShowModal ] = useState(false)
    const [ infoModal, setInfoModal ] = useState({})

    const [pageTableGlobal, setPageTableGlobal ] = useState(1);
    const [pageSizeTableGlobal, setPageSizeTableGlobal ] = useState(20);

    const {
		rex_data_filter_users,
        rex_loading_data_users,
        rex_data_users_online,
        rex_users_users_connected
    } = useSelector(({users}) => users);

    const { 
        rex_data_user_logingrow
    } = useSelector(({loginGrow}) => loginGrow);

    const { 
        rex_data_paises,
        rex_data_filtro_paises,
    } = useSelector(({paises}) => paises);

    const { 
        rex_data_tipos_perfiles,
    } = useSelector(({profileTypes}) => profileTypes);

    const ValidateUser = async () => {
        const validate_user = await dispatch(ValidationUserReducer())
        if(!validate_user){
            navigate('/login')
        }else{
            socket.emit('reg-modules', { module: 'users'}, {usuusuario: rex_data_user_logingrow.usuusuario}); 
        }
    }

    useEffect(() => {
        ValidateUser()
    }, [])

    useEffect(() => {
        socket.on('get-users-modules', (data_users) => {
            dispatch(RegisterUsersConnectedUsersReducer(data_users))
        });

        return () => {
            socket.emit('unreg-modules', { module: 'users'}, {usuusuario: rex_data_user_logingrow.usuusuario} );
            socket.off('get-users-modules');
        };
    },[])

    const onIdle = () => {
        socket.emit('reg-status-modules', { module: 'users'}, {usuusuario: rex_data_user_logingrow.usuusuario}, false );
    }
    const onActive = () => {
        socket.emit('reg-status-modules', { module: 'users'}, {usuusuario: rex_data_user_logingrow.usuusuario}, true );
    }

    useIdleTimer({
        onIdle,
        onActive,
        timeout,
        throttle: 500
    })

    const getColumnSearchProps = (dataIndex, nameColumn) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
            <div
                style={{
                    padding: 8,
                }}
                onKeyDown={(e) => e.stopPropagation()}
            >
                <Input.Search
                    placeholder={`Filtrar ${nameColumn}`}
                    value={selectedKeys[0]}
                    onChange={(e) => {
                        setSelectedKeys(e.target.value ? [e.target.value] : [])
                        confirm({
                            closeDropdown: false,
                        })
                    }}
                    style={{
                        display: 'block',
                    }}
                />
            </div>
        ),
        filterIcon: () => (<FilterOutlined/>),
        onFilter: (value, record) => {
            return record[dataIndex] != null ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : false
        }
    })

    const deleteUser = (id, resetValues) => {

        if(id.length > 0){
            confirm({
                
                title   : <span>
                        {`¿Está seguro que desea eliminar ${id.length > 1 ? 'a los usuarios': 'al usuario'}?`}
                        </span>,
                icon    : null,
                content : 'Esta acción es irreversible',
                className :"Modal-Confirm-Data-Control",
                zIndex  : 100000000,
                async onOk(){
                    let respuesta = await dispatch(DeleteUsersReducer(id))
                    if(respuesta){
                        resetValues([])
                        setPageTableGlobal(1)
                    }
                },
                okText : "Confirmar",
                okButtonProps: {
                    size:"small",
                },
                cancelText: "Cancelar",
                cancelButtonProps : {
                    size:"small",
                    danger:true
                },
                okCancel(){},
            })
        }
    }

    const confirmEditStatus = async (value, id) => {
        confirm({
            title   : `Confirmación`,
            icon    : null,
            className :"Modal-Confirm-Data-Control Modal-Semi-large",
            content : <>
                        <p className="Text-Message-Primary">¿Está seguro de {value ? 'activar':'desactivar'} al usuario?</p>
                        {
                            value && <p className="Text-Message-Secondary">Si activas al usuario se enviará un correo automaticamente con sus credenciales</p>
                        }
                      </>,
            zIndex  : 100000000,
            async onOk(){
                let response = await dispatch(EditStatusUserReducer(value, id))
                if(response){
                    setShowModal(true)
                    setInfoModal({
                        ...infoModal, 
                        title : "¡Listo!", 
                        description : `El usuario fue editado con éxito`,
                        status : true,
                        small:true,
                    })
                }
            },
            okCancel(){},
            okText : "Continuar",
            okButtonProps: {
                size:"small",
            },
            cancelText: "Cancelar",
            cancelButtonProps : {
                size:"small",
                danger:true
            },
        })
    }

    const filterUsers = (listSelected, type) => {
        if(type == 'país'){
            dispatch(FilterUsersReducer(listSelected, tpuFilter, txtFilter))
        }else if(type == 'perfiles'){
            dispatch(FilterUsersReducer(paiFilter, listSelected, txtFilter))
        }else if((type =='txtBuscar')){
            dispatch(FilterUsersReducer(paiFilter, tpuFilter, listSelected))
        }
    }

    const onSelectChange = (newSelectedRowKeys) => {
        setSelectedRowKeys(newSelectedRowKeys);
    };
    
    const actionOnRow = ( data ) => {
        dispatch(GetDataEditUserReducer(data))
        // dispatch(ObtenerTipoPerfilReducer(data.tputiposusuarios.tpuid))
        navigate('/administrator/users/edit-user')
    }
    
    useEffect(() => {
        if(rex_data_filter_users.length == 0){
            dispatch(GetUsersReducer())
            dispatch(ObtenerTiposPerfilesReducer())
        }
        dispatch(ClearSelectedPersonalizeTypeProfileReducer())
    },[])

    const menuProfileTypes = [
        {
            url: '/home-administrator',
            nombre: 'Administrador'
        },
        {
            url: '/administrator/users',
            nombre: 'Lista de usuarios'
        },
    ]

    const statusModal = () => {
        setShowModal(false)
    }

    const columns = [
        {
            title: 'Item',
            dataIndex: 'item',
            align: 'center',
            fixed:'left',
            ellipsis : true,
            render: (text, record, index) => (pageTableGlobal - 1) * pageSizeTableGlobal + index + 1,
            width: "50px"
        },
        Table.SELECTION_COLUMN,
        {
            title: 'Nombre completo',
            dataIndex: 'full_name',
            width : '200px',
            ellipsis : true,
            ...getColumnSearchProps('pernombrecompleto', 'nombre persona'),
            sorter: (a, b) => a.pernombrecompleto ? a.pernombrecompleto.localeCompare(b.pernombrecompleto) : false,
            showSorterTooltip: false,
            render:(_,record) => (
                <div className='Simple-Table-Contain-Text-Global Text-Left'>
                    {record.pernombrecompleto}
                </div>
            ),
            align:"center"
        },
        {
            title: 'Nivel país',
            dataIndex: 'level_country',
            sorter: (a, b) => a.paises ? a.paises.localeCompare(b.paises) : false,
            showSorterTooltip: false,
            align : 'center',
            width : '130px',
            ellipsis : true,
            render:(_,record) => (
                <div className='Simple-Table-Contain-Text-Global Text-Left'>
                    {record.paises}
                </div>
            ),
            filters: rex_data_filtro_paises,
            onFilter: (value, record) => {
                return record.valuesCountries.includes(value)
            },
        },
        {
            title: 'Usuario',
            dataIndex: 'usuusuario',
            align: 'center',
            ...getColumnSearchProps('usuusuario', 'correo'),
            sorter: (a, b) => a.usuusuario ? a.usuusuario.localeCompare(b.usuusuario) : false,
            showSorterTooltip: false,
            ellipsis : true,
            width : '180px',
            render:(_, record) => (
                <div className='Simple-Table-Contain-Text-Global Text-Left'>
                    {record.usuusuario}
                </div>
            ),
            align:"center"
        },
        {
            title: 'Contacto',
            dataIndex: 'contact',
            align: 'center',
            ...getColumnSearchProps('usutelefono', 'telefono'),
            sorter: (a, b) => a.key? parseInt(a.key) - parseInt(b.key) : false,
            showSorterTooltip: false,
            width : '100px',
            ellipsis : true,
            render:(_, record) => (
                <div className='Simple-Table-Contain-Text-Global'>
                    {record.usutelefono}
                </div>
            ),
        },
        {
            title: 'Área',
            dataIndex: 'area',
            align: 'center',
            width : '100px',
            ...getColumnSearchProps('usuarea', 'usuarea'),
            ellipsis : true,
            sorter: (a, b) => a.usuarea ? a.usuarea.localeCompare(b.usuarea) : false,
            showSorterTooltip: false,
            render:(_, record) => (
                <div className='Simple-Table-Contain-Text-Global'>
                    {record.usuarea}
                </div>
            ),
        },
        {
            title: 'Estado',
            dataIndex: 'status',
            align: 'center',
            sorter: (a, b) => a.estid? a.estid - b.estid : false,
            ellipsis : true,
            showSorterTooltip : false,
            width : '120px',
            render:(_, record) => {

                let statusText = record.estid ==  1 ? 'Activo' : 'Inactivo'
                return <div title={statusText} style={{display:'flex', justifyContent:"space-between"}}>
                    <span
                        className='Table-Name-Status-Global Table-Contain-Text-Global'
                    >
                        {statusText}
                    </span>
                    <Switch
                        size='small'
                        checked={ record.estid == 1 ? true : false}
                        className='Switch-Status-Users'
                        onChange={ (value, event ) => {
                            event.stopPropagation()
                            confirmEditStatus(value, record.usuid)
                        }}
                    />
                </div>
            },
            filters: [
                {
                  text: 'activo',
                  value: 1,
                },
                {
                  text: 'inactivo',
                  value: 2,
                },
            ],
            onFilter: (value, record) => record.estid == value,
        },
        {
            title: 'Fecha y hora de creación',
            dataIndex: 'created_at',
            align: 'center',
            width : '180px',
            sorter: (a, b) => a.key? parseInt(a.key) - parseInt(b.key) : false,
            showSorterTooltip: false,
            ellipsis : true,
            render:(_, record) => (
                <div className='Table-Contain-Text-Global'>
                    {record.created_at ? moment(record.created_at).format("DD MMM YYYY h:mm a") : ""}
                </div>
            ),
        },
        {
            title: 'Fecha y hora de actualización',
            dataIndex: 'updated_at',
            align: 'center',
            width : '180px',
            ellipsis : true,
            render:(_, record) => (
                <div className='Table-Contain-Text-Global'>
                    {record.updated_at 
                        ? moment(record.updated_at).format("DD MMM YYYY h:mm a") 
                        : moment(record.created_at).format("DD MMM YYYY h:mm a")
                    }
                </div>
            ),
        },
        {
            title: 'Usuario creación',
            dataIndex: 'usucreated',
            align: 'center',
            ...getColumnSearchProps('usucreated', 'usuario que creó'),
            sorter: (a, b) => a.usucreated ? a.usucreated.localeCompare(b.usucreated) : false,
            showSorterTooltip: false,
            ellipsis : true,
            width : '160px',
            render:(_, record) => (
                <div className='Simple-Table-Contain-Text-Global Text-Left'>
                    {record.usucreated}
                </div>
            ),
            align:"center"
        },
        {
            title: 'Usuario actualización',
            dataIndex: 'usuedited',
            align: 'center',
            ...getColumnSearchProps('usuedited', 'usuario que editó'),
            sorter: (a, b) => a.usuedited ? a.usuedited.localeCompare(b.usuedited) : false,
            showSorterTooltip: false,
            ellipsis : true,
            width : '170px',
            render:(_, record) => (
                <div className='Simple-Table-Contain-Text-Global Text-Left'>
                    {record.usuedited ? record.usuedited : record.usucreated }
                </div>
            ),
            align:"center"
        },
    ]

    const toggleSelectAll = (valueCheckbox) => {
        setSelectedRowKeys((keys) =>
        valueCheckbox
            ? rex_data_filter_users?.length > 0 ? rex_data_filter_users.map((r) => r.key) : rex_data_filter_users.map((r) => r.key) 
            : []
        );
    };

    const rowSelection = {
        columnTitle: true ? <ProfileTypesFilterSelect
                                    showDropDown={true}
                                    toggleSelectAll={toggleSelectAll}
                                    selectedRowKeys={null}
                                    dataAmount={rex_data_filter_users.length}
                                    setSelectedRowKeys={setSelectedRowKeys}
                                    data={rex_data_filter_users}
                                    valueCheckbox={selectedRowKeys.length == rex_data_filter_users.length}
                                    setValueCheckbox={() => console.log("ok")}
                                    actInacChechBox={false}
                                    themeColor='green'
        /> : <></>,
        selectedRowKeys,
        onChange: onSelectChange,
        type: "checkbox",
        // fixed: true,
        onSelect: () => console.log("oko"),
        fixed: true,
    };
    
    return (
        <div className='Container-Main Container-Users'>
            <Row style={{position: 'relative'}}>
                <Col span={24} style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                    <Title className="Title-Users">
                        Lista de Usuarios
                    </Title>
                </Col>
                <Col span={24} style={{position: 'absolute', top: '6px'}}>
                    <NavigationGlobal menuItems={menuProfileTypes}/>
                </Col>
            </Row>
            <Row className='Users-Container-Actions'>
                <Col sm={24} md={12} style={{display: 'flex', columnGap: '15px', alignItems: "center"}}>
                    <FilterSelectGlobal
                            data={rex_data_paises}
                            filterBy='país'
                            setTypFilter={setPaiFilter}
                            funtionFilter={(valueSelected, type) => filterUsers(valueSelected, type)}
                    />
                    {/* <FilterSelectGlobal
                        data={rex_data_tipos_perfiles}
                        filterBy='perfiles'
                        setTypFilter={setTpuFilter}
                        funtionFilter={(valueSelected, type) => filterUsers(valueSelected, type)}
                    /> */}
                    <TrashButtonGlobal
                        optionHover={true}
                        color='#013A81'
                        functionDelete={() => deleteUser(selectedRowKeys, setSelectedRowKeys)}
                    />
                    <div style={{marginLeft:"-20px", zIndex:"1"}}>
                        <DownloadButtonGlobal 
                            color='#013A81'
                            functionDownload={ async () => {
                                const link = document.createElement('a')
                                let url = await dispatch(DownloadDataUsersReducer())
                                link.href = url
                                link.click()
                            }}
                            downloadFile={true}
                        />
                    </div>
                </Col>
                <Col sm={24} md={12} style={{display: "flex", justifyContent: "end", columnGap:"15px"}}>
                    <ButtonGlobal 
                        nameRoute="/administrator/users/create-user"
                        txtButton="Nuevo"
                        actionButton = {()=>{
                            dispatch(CleanDataCreateUserReducer())
                        }}
                    />
                    <Pagination
                        total={rex_data_filter_users.length}
                        showTotal={(total, range) => `${range[0]}-${range[1]} de ${rex_data_filter_users.length}`}
                        defaultPageSize={pageSizeTableGlobal}
                        defaultCurrent={pageTableGlobal}
                        current={pageTableGlobal}
                        className='Simple-Table-Pagination-Global'
                        onChange={(page, pageSize) => {
                            setPageTableGlobal(page == 0? 1 : page)
                            setPageSizeTableGlobal(pageSize)
                        }}
                    />
                </Col>
            </Row>

            <SimpleTableGlobal
                data={rex_data_filter_users}
                keyRowAction={keyRowAction}
                rowSelection={rowSelection}
                columns={columns}
                pageTableGlobal={pageTableGlobal}
                pageSizeTableGlobal={pageSizeTableGlobal}
                actionEnterRow={setMouseOverRow}
                actionOnRow={actionOnRow}
                loadingData={rex_loading_data_users}
            />
            <Modal
                open={showModal}
                title={<span className={`${infoModal.status ? "Text-Ok" : "Text-Alert"}`}>{infoModal.title}</span>}
                zIndex={100000000}
                className={`Modal-Create-Data-Control ${infoModal.small && 'Modal-Small'}`}
                footer={[
                    <div style={{display:"flex", justifyContent:"center"}}>
                        <div className={`Button-Close Button${infoModal.status ? "-Ok" : "-Alert"}`} onClick={() => statusModal()}>
                            Ok
                        </div>
                    </div>
                ]}
            >
                <div className='Modal-Description'>
                    {infoModal.description}
                </div>
            </Modal>
            <NotificationDeleted/>
            <NotificationErrorServer/>
            {/* <NotificationEdited/> */}

            {
                rex_users_users_connected &&
                    <SideMenu
                        usersCurrentView  = {rex_users_users_connected}
                        usersAllConnected = {rex_data_users_online}
                    />
            }
        </div>
    )
}

export default Users