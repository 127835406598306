import {
    GET_DATA_USERS,
    GET_DATA_FILTER_USERS,
    DATA_CREATE_USER,
    DATA_USERS_FORM,
    LOADING_DATA_USERS,
    NOTIFICATION_DELETE_USERS,
    NOTIFICATION_ERROR_SERVER,
    NOTIFICATION_EDITED_USER,
    NOTIFICATION_CREATED_USER,
    GET_DATA_USERS_ONLINE,
    REGISTER_USERS_CONNECTED_MODULES_USERS,
    NOTIFICATION_EDITED_USER_MSG,
    NOTIFICATION_CREATED_USER_MSG,
    MESSAGE_NOTIFICATION_DELETE_USERS
} from "../../../Constants/Users/Users"


const INIT_STATE = {
    rex_data_users                          : [],
    rex_data_filter_users                   : [],
    rex_data_create_users                   : { perpersonas : {}, estid: 1, usutelefono : ''},
    rex_data_users_form                     : { perpersonas : {}, estid: 1, usutelefono : '' },
    rex_notification_user_created           : true,
    rex_loading_data_users                  : false,
    rex_notification_deleted_users          : false,
    rex_message_notification_deleted_users  : "",
    rex_notification_edited_user            : false,
    rex_notification_edited_user_msg        : {},
    rex_notification_created_user           : false,
    rex_notification_created_user_msg       : {},
    rex_notification_error_server           : false,
    rex_data_users_online                   : [],
    rex_users_users_connected               : []
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {

        case MESSAGE_NOTIFICATION_DELETE_USERS: {
            return {
                ...state,
                rex_message_notification_deleted_users : action.payload,
            }
        }
        case NOTIFICATION_CREATED_USER_MSG: {
            return {
                ...state,
                rex_notification_created_user_msg : action.payload,
            }
        }
        case NOTIFICATION_EDITED_USER_MSG: {
            return {
                ...state,
                rex_notification_edited_user_msg : action.payload,
            }
        }
        case REGISTER_USERS_CONNECTED_MODULES_USERS: {
            return {
                ...state,
                rex_users_users_connected : action.payload,
            }
        }
        case GET_DATA_USERS_ONLINE: {
            return {
                ...state,
                rex_data_users_online : action.payload,
            }
        }
        case NOTIFICATION_DELETE_USERS: {
            return {
                ...state,
                rex_notification_deleted_users : action.payload,
            }
        }
        case NOTIFICATION_CREATED_USER: {
            return {
                ...state,
                rex_notification_created_user : action.payload,
            }
        }
        case NOTIFICATION_EDITED_USER: {
            return {
                ...state,
                rex_notification_edited_user : action.payload,
            }
        }
        case NOTIFICATION_ERROR_SERVER: {
            return {
                ...state,
                rex_notification_error_server : action.payload,
            }
        }
        case GET_DATA_USERS: {
            return {
                ...state,
                rex_data_users : action.payload,
            }
        }
        case GET_DATA_FILTER_USERS: {
            return {
                ...state,
                rex_data_filter_users : action.payload,
            }
        }
        case DATA_CREATE_USER: {
            return {
                ...state,
                rex_data_create_users : action.payload,
            }
        }
        case DATA_USERS_FORM: {
            return {
                ...state,
                rex_data_users_form : action.payload,
            }
        }
        case LOADING_DATA_USERS: {
            return {
                ...state,
                rex_loading_data_users : action.payload,
            }
        }
        default:
            return state;
    }
}
