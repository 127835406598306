import React from "react"
import { Button } from 'antd'
import { useDispatch } from "react-redux"
import {
    FiltroBotonDataReducer,
    FiltroTipoDataReducer,
    FiltroTipoDataTablaReducer
} from '../../../Redux/Actions/PowerBi/PowerBiFiltros'

const BotonFiltroPrincipal = (props) => {
    const titulo = props.titulo
    const activo = props.activo
    const keylabel = props.keylabel
    const dispatch = useDispatch()
    
    return(
        <Button 
            className={`botonFiltro ${activo && 'activo'}`}
            onClick={() => {
                dispatch(FiltroBotonDataReducer(titulo, true))
                dispatch(FiltroTipoDataReducer(keylabel))
                dispatch(FiltroTipoDataTablaReducer(keylabel))
            }} 
        >
            {titulo}
        </Button>
    )
}

export default BotonFiltroPrincipal