import React, { useEffect, useState } from 'react'
import { useParams } from 'react-router'
import { Form, Typography, Input, Row, Col, Button, Collapse, Modal } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate} from "react-router-dom"
import { 
    CreateMasterProductManageReducer, 
    EditMasterProductManageReducer, 
    EditValueMasterProduct, 
    GetMasterProductManageReducer 
} from '../../../Redux/Actions/Manage/MasterProducts/MasterProducts'
import DividerGlobal from '../../Global/DividerGlobal'
import '../../../Styles/Components/Manage/MasterProducts/FormMasterProduct.css'
import notificationGlobal from '../../Global/NotificationGlobal'
import { 
    ExclamationCircleFilled,
} from '@ant-design/icons'
import SideMenu from '../../Global/SideMenu'

const FormMasterProduct = () => {

    const [ loadingSend, setLoadingSend ] = useState(false)
    const { Panel } = Collapse

    const { id } = useParams()
    const { confirm } = Modal

    const dispatch = useDispatch()
    let navigate = useNavigate()
    const { Title } = Typography
    const [form] = Form.useForm()

    const { 
        rex_users_conected_dashboard,
    } = useSelector(({registerUsers}) => registerUsers)

    const { 
        rex_data_users_online
    } = useSelector(({users}) => users)

    const onFinish = async (values) => {

        //Hasta que esté el input de fecha
        const currentDate = new Date();
        const currentYear = currentDate.getFullYear();
        const currentMonth = currentDate.getMonth() + 1;
        const dateFormat = `${currentYear}-${currentMonth.toString().padStart(2, '0')}`;
        //Hasta que esté el input de fecha

        let response = false
        if(id){
            setLoadingSend(true)
            response = await dispatch(EditMasterProductManageReducer( id,{...values, anio:currentYear, mes:currentMonth, fecha: dateFormat}))
            setLoadingSend(false)
            if(response){
                navigate("/master-products")
            }
        }else{
            confirm({
                title   : "Crear Producto",
                icon    : <ExclamationCircleFilled/>,
                content : "¿Está seguro que desea crear el producto?",
                zIndex  : 100000000,
                async onOk(){
                    response = await dispatch(CreateMasterProductManageReducer({...values, anio:currentYear, mes:currentMonth, fecha: dateFormat}))
                    if(response){
                        navigate("/master-products")
                    }        
                },
                okCancel(){}
            })   
        }
    }

    const editFormField = (data) => {
        form.setFieldsValue({
            cod_sales_organization  : data.cod_sales_organization,
            sales_organization      : data.sales_organization,
            cod_bussiness           : data.cod_bussiness,
            bussiness               : data.bussiness,
            cod_sku                 : data.cod_sku,
            ship_sku                : data.ship_sku,
            cod_ean13               : data.cod_ean13,
            cod_ean14               : data.cod_ean14,
            cod_category            : data.cod_category,
            category                : data.category,
            cod_sub_category        : data.cod_sub_category,
            sub_category            : data.sub_category,
            cod_format              : data.cod_format,
            format                  : data.format,
            cod_family_sku          : data.cod_family_sku,
            family_sku              : data.family_sku,
            cod_marca               : data.cod_marca,
            marca                   : data.marca,
            class_1                 : data.class_1,
            class_2                 : data.class_2,
            class_3                 : data.class_3,
            class_4                 : data.class_4,
            class_5                 : data.class_5,
            class_6                 : data.class_6,
            cod_count               : data.cod_count,
            count                   : data.count,
            cod_size                : data.cod_size,
            size                    : data.size,
            cod_presentation        : data.cod_presentation,
            presentation            : data.presentation,
            cod_peso                : data.cod_peso,
            peso                    : data.peso,
            cod_fc                  : data.cod_fc,
            fc                      : data.fc,
            class_7                 : data.class_7,
            class_8                 : data.class_8,
            class_9                 : data.class_9,
            class_10                : data.class_10,
            cod_cj                  : data.cod_cj,
            cj                      : data.cj,
            tn                      : data.tn,
            cod_tn                  : data.cod_tn,
            cod_gsu                 : data.cod_gsu,
            gsu                     : data.gsu,
            class_11                : data.class_11,
            class_12                : data.class_12,
            class_13                : data.class_13,
            status                  : data.status,
            robot                   : data.robot,
            mslpo                   : data.mslpo
        })
    }

    const onFinishFailed = (errorInfo) => {
        notificationGlobal("warning", "Se han encontrado errores dentro del formulario.")
    }

    const fetchData = async () => {
        if(id){
            let data = await dispatch(GetMasterProductManageReducer(id))
            editFormField(data)
        }
    }

    useEffect(() => {
        fetchData()
    },[])

    return (
        <div className="Container-Main" style={{paddingBottom: '10px'}}>
            <Title level={2} className='Title-Search-Text-Global'>
                {
                    id ? "Editar Producto" : "Nuevo Producto"
                }
            </Title>
            <Form
                layout='vertical'
                onFinish={onFinish}
                onFinishFailed={onFinishFailed}
                form={form}
            >
                <DividerGlobal orientation="left" text="Información principal"/>
                <Row gutter={24}>
                    <Col xs={24} sm={12} md={6} lg={6}>
                        <Form.Item
                            label="Código SKU:"
                            name="cod_sku"
                            className="Create-User-Form-Item-Text"
                            rules={[
                                {
                                    required: true,
                                    message: "El código es requerido",
                                },
                            ]}
                            >
                            <Input
                                autoComplete='off'
                                placeholder="Código SKU"
                                onChange={(e) => dispatch(EditValueMasterProduct('cod_sku', e.target.value))}
                            />
                        </Form.Item>

                    </Col>
                    <Col xs={24} sm={12} md={6} lg={6}>
                        <Form.Item
                            label="SHIP SKU:"
                            name="ship_sku"
                            className="Create-User-Form-Item-Text"
                            rules={[
                                {
                                    required: true,
                                    message: "El nombre es requerido",
                                },
                            ]}
                            >
                            <Input
                                autoComplete='off'
                                placeholder="SHIP SKU"
                                onChange={(e) => dispatch(EditValueMasterProduct('ship_sku', e.target.value))}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={6} lg={6}>
                        <Form.Item
                            label="Código sales organización:"
                            name="cod_sales_organization"
                            className="Create-User-Form-Item-Text"
                            >
                            <Input
                                autoComplete='off'
                                placeholder="Código sales organización"
                                onChange={(e) => dispatch(EditValueMasterProduct('cod_sales_organization', e.target.value))}
                            />
                        </Form.Item>

                    </Col>
                    <Col xs={24} sm={12} md={6} lg={6}>
                        <Form.Item
                            label="Sales organización:"
                            name="sales_organization"
                            className="Create-User-Form-Item-Text"
                            >
                            <Input
                                autoComplete='off'
                                placeholder="Sales organización"
                                onChange={(e) => dispatch(EditValueMasterProduct('sales_organization', e.target.value))}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={6} lg={6}>
                        <Form.Item
                            label="Código Bussiness:"
                            name="cod_bussiness"
                            className="Create-User-Form-Item-Text"
                            >
                            <Input
                                autoComplete='off'
                                placeholder="Código Bussiness"
                                onChange={(e) => dispatch(EditValueMasterProduct('cod_bussiness', e.target.value))}
                            />
                        </Form.Item>

                    </Col>
                    <Col xs={24} sm={12} md={6} lg={6}>
                        <Form.Item
                            label="Bussiness:"
                            name="bussiness"
                            className="Create-User-Form-Item-Text"
                            >
                            <Input
                                autoComplete='off'
                                placeholder="Bussiness"
                                onChange={(e) => dispatch(EditValueMasterProduct('bussiness', e.target.value))}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={6} lg={6}>
                        <Form.Item
                            label="COD EAN 13:"
                            name="cod_ean13"
                            className="Create-User-Form-Item-Text"
                            >
                            <Input
                                autoComplete='off'
                                placeholder="COD EAN 13"
                                onChange={(e) => dispatch(EditValueMasterProduct('cod_ean13', e.target.value))}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={6} lg={6}>
                        <Form.Item
                            label="COD EAN 14:"
                            name="cod_ean14"
                            className="Create-User-Form-Item-Text"
                            >
                            <Input
                                autoComplete='off'
                                placeholder="COD EAN 14"
                                onChange={(e) => dispatch(EditValueMasterProduct('cod_ean14', e.target.value))}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={6} lg={6}>
                        <Form.Item
                            label="MSLPO:"
                            name="mslpo"
                            className="Create-User-Form-Item-Text"
                            >
                            <Input
                                autoComplete='off'
                                placeholder="MSLPO"
                                onChange={(e) => dispatch(EditValueMasterProduct('mslpo', e.target.value))}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <DividerGlobal orientation="left" text="Clasificación"/>
                <Row gutter={24}>
                <Col xs={24} sm={12} md={6} lg={6}>
                        <Form.Item
                            label="Código categoría:"
                            name="cod_category"
                            className="Create-User-Form-Item-Text"
                            >
                            <Input
                                autoComplete='off'
                                placeholder="Código categoría"
                                onChange={(e) => dispatch(EditValueMasterProduct('cod_category', e.target.value))}
                            />
                        </Form.Item>

                    </Col>
                    <Col xs={24} sm={12} md={6} lg={6}>
                        <Form.Item
                            label="Categoría:"
                            name="category"
                            className="Create-User-Form-Item-Text"
                            >
                            <Input
                                autoComplete='off'
                                placeholder="Categoría"
                                onChange={(e) => dispatch(EditValueMasterProduct('category', e.target.value))}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={6} lg={6}>
                        <Form.Item
                            label="Código subcategoria:"
                            name="cod_sub_category"
                            className="Create-User-Form-Item-Text"
                            >
                            <Input
                                autoComplete='off'
                                placeholder="Código subcategoria"
                                onChange={(e) => dispatch(EditValueMasterProduct('cod_sub_category', e.target.value))}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={6} lg={6}>
                        <Form.Item
                            label="Subcategoria:"
                            name="sub_category"
                            className="Create-User-Form-Item-Text"
                            >
                            <Input
                                autoComplete='off'
                                placeholder="Subcategoria"
                                onChange={(e) => dispatch(EditValueMasterProduct('sub_category', e.target.value))}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={6} lg={6}>
                        <Form.Item
                            label="Código formato:"
                            name="cod_format"
                            className="Create-User-Form-Item-Text"
                            >
                            <Input
                                autoComplete='off'
                                placeholder="Código formato"
                                onChange={(e) => dispatch(EditValueMasterProduct('cod_format', e.target.value))}
                            />
                        </Form.Item>

                    </Col>
                    <Col xs={24} sm={12} md={6} lg={6}>
                        <Form.Item
                            label="Formato:"
                            name="format"
                            className="Create-User-Form-Item-Text"
                            >
                            <Input
                                autoComplete='off'
                                placeholder="Formato"
                                onChange={(e) => dispatch(EditValueMasterProduct('format', e.target.value))}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={6} lg={6}>
                        <Form.Item
                            label="Código familia SKU:"
                            name="cod_family_sku"
                            className="Create-User-Form-Item-Text"
                            >
                            <Input
                                autoComplete='off'
                                placeholder="Código familia SKU"
                                onChange={(e) => dispatch(EditValueMasterProduct('cod_family_sku', e.target.value))}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={6} lg={6}>
                        <Form.Item
                            label="Familia SKU:"
                            name="family_sku"
                            className="Create-User-Form-Item-Text"
                            >
                            <Input
                                autoComplete='off'
                                placeholder="Familia SKU"
                                onChange={(e) => dispatch(EditValueMasterProduct('family_sku', e.target.value))}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={6} lg={6}>
                        <Form.Item
                            label="Código marca:"
                            name="cod_marca"
                            className="Create-User-Form-Item-Text"
                            >
                            <Input
                                autoComplete='off'
                                placeholder="Código marca"
                                onChange={(e) => dispatch(EditValueMasterProduct('cod_marca', e.target.value))}
                            />
                        </Form.Item>

                    </Col>
                    <Col xs={24} sm={12} md={6} lg={6}>
                        <Form.Item
                            label="Marca:"
                            name="marca"
                            className="Create-User-Form-Item-Text"
                            >
                            <Input
                                autoComplete='off'
                                placeholder="Marca"
                                onChange={(e) => dispatch(EditValueMasterProduct('marca', e.target.value))}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={24}>
                        <Collapse className="Collapse-Form-Master-Products" onChange={() => {}}>
                            <Panel header="Campos extras">
                                <Row gutter={24}>
                                    <Col xs={24} sm={12} md={6} lg={6}>
                                        <Form.Item
                                            label="Class 1:"
                                            name="class_1"
                                            className="Create-User-Form-Item-Text"
                                            >
                                            <Input
                                                autoComplete='off'
                                                placeholder="Class 1"
                                                onChange={(e) => dispatch(EditValueMasterProduct('class_1', e.target.value))}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={12} md={6} lg={6}>
                                        <Form.Item
                                            label="Class 2:"
                                            name="class_2"
                                            className="Create-User-Form-Item-Text"
                                            >
                                            <Input
                                                autoComplete='off'
                                                placeholder="Class 2"
                                                onChange={(e) => dispatch(EditValueMasterProduct('class_2', e.target.value))}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={12} md={6} lg={6}>
                                        <Form.Item
                                            label="Class 3:"
                                            name="class_3"
                                            className="Create-User-Form-Item-Text"
                                            >
                                            <Input
                                                autoComplete='off'
                                                placeholder="Class 3"
                                                onChange={(e) => dispatch(EditValueMasterProduct('class_3', e.target.value))}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={12} md={6} lg={6}>
                                        <Form.Item
                                            label="Class 4:"
                                            name="class_4"
                                            className="Create-User-Form-Item-Text"
                                            >
                                            <Input
                                                autoComplete='off'
                                                placeholder="Class 4"
                                                onChange={(e) => dispatch(EditValueMasterProduct('class_4', e.target.value))}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={12} md={6} lg={6}>
                                        <Form.Item
                                            label="Class 5:"
                                            name="class_5"
                                            className="Create-User-Form-Item-Text"
                                            >
                                            <Input
                                                autoComplete='off'
                                                placeholder="Class 5"
                                                onChange={(e) => dispatch(EditValueMasterProduct('class_5', e.target.value))}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={12} md={6} lg={6}>
                                        <Form.Item
                                            label="Class 6:"
                                            name="class_6"
                                            className="Create-User-Form-Item-Text"
                                            >
                                            <Input
                                                autoComplete='off'
                                                placeholder="Class 6"
                                                onChange={(e) => dispatch(EditValueMasterProduct('class_6', e.target.value))}
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Panel>
                        </Collapse>
                    </Col>
                </Row>
                <DividerGlobal orientation="left" text="Atributos"/>
                <Row gutter={24}>
                    <Col xs={24} sm={12} md={6} lg={6}>
                        <Form.Item
                            label="Código conteo:"
                            name="cod_count"
                            className="Create-User-Form-Item-Text"
                            >
                            <Input
                                autoComplete='off'
                                placeholder="Código conteo"
                                onChange={(e) => dispatch(EditValueMasterProduct('cod_count', e.target.value))}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={6} lg={6}>
                        <Form.Item
                            label="Conteo:"
                            name="count"
                            className="Create-User-Form-Item-Text"
                            >
                            <Input
                                autoComplete='off'
                                placeholder="Conteo"
                                onChange={(e) => dispatch(EditValueMasterProduct('count', e.target.value))}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={6} lg={6}>
                        <Form.Item
                            label="Código tamaño:"
                            name="cod_size"
                            className="Create-User-Form-Item-Text"
                            >
                            <Input
                                autoComplete='off'
                                placeholder="Código tamaño"
                                onChange={(e) => dispatch(EditValueMasterProduct('cod_size', e.target.value))}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={6} lg={6}>
                        <Form.Item
                            label="Size:"
                            name="size"
                            className="Create-User-Form-Item-Text"
                            >
                            <Input
                                autoComplete='off'
                                placeholder="Size"
                                onChange={(e) => dispatch(EditValueMasterProduct('size', e.target.value))}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={6} lg={6}>
                        <Form.Item
                            label="Código presentación:"
                            name="cod_presentation"
                            className="Create-User-Form-Item-Text"
                            >
                            <Input
                                autoComplete='off'
                                placeholder="Código presentación"
                                onChange={(e) => dispatch(EditValueMasterProduct('cod_presentation', e.target.value))}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={6} lg={6}>
                        <Form.Item
                            label="Presentación:"
                            name="presentation"
                            className="Create-User-Form-Item-Text"
                            >
                            <Input
                                autoComplete='off'
                                placeholder="Presentación"
                                onChange={(e) => dispatch(EditValueMasterProduct('presentation', e.target.value))}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={6} lg={6}>
                        <Form.Item
                            label="Código peso:"
                            name="cod_peso"
                            className="Create-User-Form-Item-Text"
                            >
                            <Input
                                autoComplete='off'
                                placeholder="Código peso"
                                onChange={(e) => dispatch(EditValueMasterProduct('cod_peso', e.target.value))}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={6} lg={6}>
                        <Form.Item
                            label="Peso:"
                            name="peso"
                            className="Create-User-Form-Item-Text"
                            rules={[
                                {
                                    pattern: new RegExp(/^\d+(\.\d{1,2})?$/),
                                    message: "Formato inválido"
                                }
                            ]}
                            >
                            <Input
                                autoComplete='off'
                                placeholder="Peso"
                                onChange={(e) => dispatch(EditValueMasterProduct('peso', e.target.value))}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={6} lg={6}>
                        <Form.Item
                            label="Cod FC:"
                            name="cod_fc"
                            className="Create-User-Form-Item-Text"
                            >
                            <Input
                                autoComplete='off'
                                placeholder="Cod FC"
                                onChange={(e) => dispatch(EditValueMasterProduct('cod_fc', e.target.value))}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={6} lg={6}>
                        <Form.Item
                            label="FC:"
                            name="fc"
                            className="Create-User-Form-Item-Text"
                            >
                            <Input
                                autoComplete='off'
                                placeholder="FC"
                                onChange={(e) => dispatch(EditValueMasterProduct('fc', e.target.value))}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={24}>
                        <Collapse className="Collapse-Form-Master-Products" onChange={() => {}}>
                            <Panel header="Campos extras">
                                <Row gutter={24}>
                                    <Col xs={24} sm={12} md={6} lg={6}>
                                        <Form.Item
                                            label="Class 7:"
                                            name="class_7"
                                            className="Create-User-Form-Item-Text"
                                            >
                                            <Input
                                                autoComplete='off'
                                                placeholder="Class 7"
                                                onChange={(e) => dispatch(EditValueMasterProduct('class_7', e.target.value))}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={12} md={6} lg={6}>
                                        <Form.Item
                                            label="Class 8:"
                                            name="class_8"
                                            className="Create-User-Form-Item-Text"
                                            >
                                            <Input
                                                autoComplete='off'
                                                placeholder="Class 8"
                                                onChange={(e) => dispatch(EditValueMasterProduct('class_8', e.target.value))}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={12} md={6} lg={6}>
                                        <Form.Item
                                            label="Class 9:"
                                            name="class_9"
                                            className="Create-User-Form-Item-Text"
                                            >
                                            <Input
                                                autoComplete='off'
                                                placeholder="Class 9"
                                                onChange={(e) => dispatch(EditValueMasterProduct('class_9', e.target.value))}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={12} md={6} lg={6}>
                                        <Form.Item
                                            label="Class 10:"
                                            name="class_10"
                                            className="Create-User-Form-Item-Text"
                                            >
                                            <Input
                                                autoComplete='off'
                                                placeholder="Class 10"
                                                onChange={(e) => dispatch(EditValueMasterProduct('class_10', e.target.value))}
                                            />
                                        </Form.Item>
                                    </Col>

                                </Row>
                            </Panel>
                        </Collapse>
                    </Col>
                </Row>
                <DividerGlobal orientation="left" text="Factor conversión"/>
                <Row gutter={24}>
                    <Col xs={24} sm={12} md={6} lg={6}>
                        <Form.Item
                            label="Código CJ:"
                            name="cod_cj"
                            className="Create-User-Form-Item-Text"
                            >
                            <Input
                                autoComplete='off'
                                placeholder="Código CJ"
                                onChange={(e) => dispatch(EditValueMasterProduct('cod_cj', e.target.value))}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={6} lg={6}>
                        <Form.Item
                            label="CJ:"
                            name="cj"
                            className="Create-User-Form-Item-Text"
                            >
                            <Input
                                autoComplete='off'
                                placeholder="CJ"
                                onChange={(e) => dispatch(EditValueMasterProduct('cj', e.target.value))}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={6} lg={6}>
                        <Form.Item
                            label="Código TN:"
                            name="cod_tn"
                            className="Create-User-Form-Item-Text"
                            >
                            <Input
                                autoComplete='off'
                                placeholder="Código TN"
                                onChange={(e) => dispatch(EditValueMasterProduct('cod_tn', e.target.value))}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={6} lg={6}>
                        <Form.Item
                            label="TN:"
                            name="tn"
                            className="Create-User-Form-Item-Text"
                            >
                            <Input
                                autoComplete='off'
                                placeholder="TN"
                                onChange={(e) => dispatch(EditValueMasterProduct('tn', e.target.value))}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={6} lg={6}>
                        <Form.Item
                            label="Código GSU:"
                            name="cod_gsu"
                            className="Create-User-Form-Item-Text"
                            >
                            <Input
                                autoComplete='off'
                                placeholder="Código GSU"
                                onChange={(e) => dispatch(EditValueMasterProduct('cod_gsu', e.target.value))}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={6} lg={6}>
                        <Form.Item
                            label="GSU:"
                            name="gsu"
                            className="Create-User-Form-Item-Text"
                            >
                            <Input
                                autoComplete='off'
                                placeholder="GSU"
                                onChange={(e) => dispatch(EditValueMasterProduct('gsu', e.target.value))}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={6} lg={6}>
                        <Form.Item
                            label="Status:"
                            name="status"
                            className="Create-User-Form-Item-Text"
                            >
                            <Input
                                autoComplete='off'
                                placeholder="Status"
                                onChange={(e) => dispatch(EditValueMasterProduct('status', e.target.value))}
                            />
                        </Form.Item>
                    </Col>
                    <Col xs={24} sm={12} md={6} lg={6}>
                        <Form.Item
                            label="Robot:"
                            name="robot"
                            className="Create-User-Form-Item-Text"
                            >
                            <Input
                                autoComplete='off'
                                placeholder="Robot"
                                onChange={(e) => dispatch(EditValueMasterProduct('robot', e.target.value))}
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col xs={24} sm={12} md={6} lg={6}>
                        
                    </Col>
                    <Col xs={24} sm={12} md={6} lg={6}>
                        
                    </Col>
                </Row>
                <Row gutter={24}>
                    <Col span={24}>
                        <Collapse className="Collapse-Form-Master-Products" onChange={() => {}}>
                            <Panel header="Campos extras">
                                <Row gutter={24}>
                                    <Col xs={24} sm={12} md={6} lg={6}>
                                        <Form.Item
                                            label="Class 11:"
                                            name="class_11"
                                            className="Create-User-Form-Item-Text"
                                            >
                                            <Input
                                                autoComplete='off'
                                                placeholder="Class 11"
                                                onChange={(e) => dispatch(EditValueMasterProduct('class_11', e.target.value))}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={12} md={6} lg={6}>
                                        <Form.Item
                                            label="Class 12:"
                                            name="class_12"
                                            className="Create-User-Form-Item-Text"
                                            >
                                            <Input
                                                autoComplete='off'
                                                placeholder="Class 12"
                                                onChange={(e) => dispatch(EditValueMasterProduct('class_12', e.target.value))}
                                            />
                                        </Form.Item>
                                    </Col>
                                    <Col xs={24} sm={12} md={6} lg={6}>
                                        <Form.Item
                                            label="Class 13:"
                                            name="class_13"
                                            className="Create-User-Form-Item-Text"
                                            >
                                            <Input
                                                autoComplete='off'
                                                placeholder="Class 13"
                                                onChange={(e) => dispatch(EditValueMasterProduct('class_13', e.target.value))}
                                            />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Panel>
                        </Collapse>
                    </Col>
                </Row>

                <Row style={{display:"flex", justifyContent:"center", marginTop:"20px"}} gutter={24}>
                    <Col>
                        <Form.Item
                            wrapperCol={{
                                offset: 8,
                                span: 16,
                            }}
                            >
                            <Button
                                onClick={() => navigate("/master-products")}
                                danger
                                style={{
                                    height: "34px",
                                    paddingLeft: "15px",
                                    paddingRight: "15px",
                                    display: "flex",
                                    alignItems: "center",
                                    borderRadius: "20px",
                                    fontSize: "13px",
                                    fontFamily: "Inter",
                                }}
                            >
                                Cancelar
                            </Button>
                        </Form.Item>
                    </Col>
                    <Col>
                        <Form.Item
                            wrapperCol={{
                                offset: 8,
                                span: 16,
                            }}
                            >
                            <Button 
                                loading={loadingSend}
                                type="primary" 
                                htmlType="submit"
                                style={{
                                    background: "#2AD295",
                                    height: "34px",
                                    paddingLeft: "15px",
                                    paddingRight: "15px",
                                    display: "flex",
                                    alignItems: "center",
                                    borderRadius: "20px",
                                    color: "white",
                                    fontSize: "13px",
                                    fontFamily: "Inter",
                                }}
                            >
                                Guardar
                            </Button>
                        </Form.Item>
                    </Col>
                </Row>
            </Form>

            <SideMenu
                usersCurrentView  = {rex_users_conected_dashboard}
                usersAllConnected ={rex_data_users_online}
            />
        </div>
    )
}


export default FormMasterProduct