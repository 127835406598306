import config from '../../../config'
import {
    CARGANDO_LISTA_DATA,
    CARGANDO_LISTA_DATA_DERECHA,
    TABLA_EXPANDED_DATA,
    TOTAL_EXPANDED_DATA,
    TOTAL_QUERY_DATA,
    OBTENER_CODIGO_FECHA,
    MES_VALIDATOR,
    OBTENER_DATA_QUERY_1,
    OBTENER_DATA_QUERY_2,
    OBTENER_FECHA_QUERY,
    OBTENER_LISTA_DATA_DASHBOARD,
    OBTENER_LISTA_TOTAL_DATA_DASHBOARD,
    OBTENER_LISTA_DATA_COPIA_DASHBOARD,
} from '../../../Constants/PowerBi/PowerBi'
import {
    CARGANDO_DATA_DAILY,
} from '../../../Constants/PowerBi/Daily'
import Moment from 'moment'
import {
    GetDataDailyChartReducer,
} from './PowerBiDaily'
import {
    FiltroTableroDinamicoDataReducer
} from './PowerBiFiltros'
import { notification } from 'antd'

const notificacionServidor = (type, mensaje) => {
    if(type === 'success'){
        notification['success']({
            message: 'Éxito',
            description: mensaje,
        })
    }else if(type === 'warning'){
        notification['warning']({
            message: 'Advertencia',
            description: mensaje,
        })
    }else if(type === 'error'){
        notification['error']({
            message: 'Error',
            description: mensaje,
        })
    }
}

export const ObtenerDataTablaReducer = (select) => async (dispatch, getState) => {
    dispatch({
        type: TOTAL_QUERY_DATA,
        payload: 0
    })
    if(select){
        dispatch({
            type: CARGANDO_LISTA_DATA,
            payload : true
        })
        dispatch({
            type: CARGANDO_LISTA_DATA_DERECHA,
            payload : true
        })
    }else{
        dispatch({
            type: CARGANDO_LISTA_DATA,
            payload : true
        })
    }

    dispatch({
        type: CARGANDO_DATA_DAILY,
        payload: true
    })
    
    await dispatch(ObtenerFechaPowerBiReducer())
    let validacion = getState().powerBi.rex_mes_validator
    if(validacion){
        dispatch(ObtenerDataFirstTablaReducer(select))
        dispatch(ObtenerDataSecondTablaReducer(select))
    }else{
        dispatch(ObtenerDataFirstTablaReducer(select))
    }
    dispatch(GetDataDailyChartReducer())

}

export const ObtenerFechaPowerBiReducer = () => async (dispatch, getState) => {

    let req_anio    = getState().daily.rex_select_daily_anio
    let req_mes     = getState().daily.rex_select_daily_mes

    let anio    = Moment().year()
    let mes     = Moment().month()

    let headerFetch = {
        'Accept' : 'application/json',
        'content-type': 'application/json',
    }

    await fetch(config.api_url_powerbi+'dashboards/get-date',
		{
            mode:'cors',
			method: 'POST',
			headers: headerFetch,
            body: JSON.stringify({
                're_anio'   : req_anio == null ? anio : req_anio,
                're_mes'    : req_mes == null ? mes + 1 : req_mes,
            }),
      	}
    )
    .then(async res => {
		return res.json()
    })
    .then(async data => {
		// const estadoRequest = getState().estadoRequest.init_request
        // if(estadoRequest === true){
            await dispatch({
                type: OBTENER_CODIGO_FECHA,
                payload: data.responseDate
            })
            await dispatch({
                type: OBTENER_FECHA_QUERY,
                payload: data.fechaEnvio
            })
            await dispatch({
                type: MES_VALIDATOR,
                payload: data.mes_actual
            })
        // }else{
        //     notificacionServidor('error', data.message)
        // }
    })
    .catch((error)=> {
        notificacionServidor('error', 'Error con el servidor. Vuelva a ingresar más tarde.')
    })

}

export const ObtenerDataFirstTablaReducer = (select, smoid) => (dispatch, getState) => {
    const {
        rex_data_formato_original,
        rex_data_talla_original,
        rex_data_conteo_original,
        rex_data_sku_original,
        rex_data_descripcion_material_original,
    } = getState().filtros

    const {
        rex_data_user_logingrow
    } = getState().loginGrow

    // const {
    //     idsubmoduloSeleccionado
    // } = getState().dashboard

    let req_anio            = getState().daily.rex_select_daily_anio
    let req_mes             = getState().daily.rex_select_daily_mes
    let req_sku             = getState().daily.rex_select_daily__hml_cod_mat
    let req_hml_mat         = getState().daily.rex_select_daily_hml_mat
    let req_hml_cli         = getState().daily.rex_select_daily_hml_cli
    let req_filtro          = getState().filtros.rex_data_type_data
    let req_conteo          = getState().daily.rex_select_daily_conteo
    let req_talla           = getState().daily.rex_select_daily_talla
    let req_formato         = getState().daily.rex_select_daily_formato
    let req_codigo_fecha    = getState().powerBi.rex_codigo_fecha
    let req_mes_validator   = getState().powerBi.rex_mes_validator

    let send_sku = req_sku != false && req_sku[0].length == rex_data_sku_original.length ? false : req_sku
    let send_hml_mat = req_hml_mat != false && req_hml_mat[0].length == rex_data_descripcion_material_original.length ? false : req_hml_mat
    let send_hml_cli = req_hml_cli != false && req_hml_cli[0].length == 3 ? false : req_hml_cli
    let send_conteo = req_conteo != false && req_conteo[0].length == rex_data_conteo_original.length ? false : req_conteo
    let send_talla = req_talla != false && req_talla[0].length == rex_data_talla_original.length ? false : req_talla
    let send_formato = req_formato != false && req_formato[0].length == rex_data_formato_original.length ? false : req_formato

    let mes  = Moment().month()
    let anio = Moment().year()

    let headerFetch = {
        'Accept' : 'application/json',
        'content-type': 'application/json',
    }

    fetch(config.api_url_powerbi+'dashboards/query-table-first',
		{
            mode:'cors',
			method: 'POST',
			headers: headerFetch,
            body: JSON.stringify({
                're_anio'                   : req_anio == null ? anio : req_anio,
                're_mes'                    : req_mes == null ? mes + 1 : req_mes,
                're_filtro'                 : req_filtro,
                're_sku'                    : send_sku,
                're_descripcion_material'   : send_hml_mat,
                're_cliente_hml'            : send_hml_cli,
                're_conteo'                 : send_conteo,
                're_talla'                  : send_talla,
                're_formato'                : send_formato,
                're_codigo_fecha'           : req_codigo_fecha,
                're_validator'              : req_mes_validator,
                're_smoid'                  : 149,
                're_usuid'                  : rex_data_user_logingrow.usuid
            }),
      	}
    )
    .then(async res => {
		return res.json()
    })
    .then(async data => {
		// const estadoRequest = getState().estadoRequest.init_request
        // if(estadoRequest === true){
            dispatch({
                type: OBTENER_DATA_QUERY_1,
                payload: data.query
            })
            if(!req_mes_validator){
                dispatch({
                    type: OBTENER_DATA_QUERY_2,
                    payload: []
                })
            }
            let total = getState().powerBi.rex_total_query_data + 1
            dispatch({
                type: TOTAL_QUERY_DATA,
                payload: total
            })
            if(total == 2 && req_mes_validator){
                dispatch(DataTablaZonaSellReducer(select))
            }else if(total == 1 && !req_mes_validator){
                dispatch(DataTablaZonaSellReducer(select))
            }
        // }else{
        //     notificacionServidor('error', data.message)
        // }
    })
    .catch((error)=> {
        notificacionServidor('error', 'Error con el servidor. Vuelva a ingresar más tarde.')
    })
}

export const ObtenerDataSecondTablaReducer = (select) => (dispatch, getState) => {
    const {
        rex_data_formato_original,
        rex_data_talla_original,
        rex_data_conteo_original,
        rex_data_sku_original,
        rex_data_descripcion_material_original,
    } = getState().filtros

    let req_anio    = getState().daily.rex_select_daily_anio
    let req_mes     = getState().daily.rex_select_daily_mes
    let req_sku     = getState().daily.rex_select_daily__hml_cod_mat
    let req_hml_mat = getState().daily.rex_select_daily_hml_mat
    let req_hml_cli = getState().daily.rex_select_daily_hml_cli
    let req_filtro  = getState().filtros.rex_data_type_data
    let req_conteo  = getState().daily.rex_select_daily_conteo
    let req_talla   = getState().daily.rex_select_daily_talla
    let req_formato = getState().daily.rex_select_daily_formato
    let req_codigo_fecha    = getState().powerBi.rex_codigo_fecha
    let req_mes_validator   = getState().powerBi.rex_mes_validator

    let mes  = Moment().month()
    let anio = Moment().year()

    let send_sku = req_sku != false && req_sku[0].length == rex_data_sku_original.length ? false : req_sku
    let send_hml_mat = req_hml_mat != false && req_hml_mat[0].length == rex_data_descripcion_material_original.length ? false : req_hml_mat
    let send_hml_cli = req_hml_cli != false && req_hml_cli[0].length == 3 ? false : req_hml_cli
    let send_conteo = req_conteo != false && req_conteo[0].length == rex_data_conteo_original.length ? false : req_conteo
    let send_talla = req_talla != false && req_talla[0].length == rex_data_talla_original.length ? false : req_talla
    let send_formato = req_formato != false && req_formato[0].length == rex_data_formato_original.length ? false : req_formato

    let headerFetch = {
        'Accept' : 'application/json',
        'content-type': 'application/json',
    }

    fetch(config.api_url_powerbi+'dashboards/query-table-second',
		{
            mode:'cors',
			method: 'POST',
			headers: headerFetch,
            body: JSON.stringify({
                're_anio'                   : req_anio == null ? anio : req_anio,
                're_mes'                    : req_mes == null ? mes + 1 : req_mes,
                're_filtro'                 : req_filtro,
                're_sku'                    : send_sku,
                're_descripcion_material'   : send_hml_mat,
                're_cliente_hml'            : send_hml_cli,
                're_conteo'                 : send_conteo,
                're_talla'                  : send_talla,
                're_formato'                : send_formato,
                're_codigo_fecha'           : req_codigo_fecha,
                're_validator'              : req_mes_validator,
            }),
      	}
    )
    .then(async res => {
		return res.json()
    })
    .then(async data => {
		// const estadoRequest = getState().estadoRequest.init_request
        // if(estadoRequest === true){
            dispatch({
                type: OBTENER_DATA_QUERY_2,
                payload: data.query
            })
            let total = getState().powerBi.rex_total_query_data + 1
            dispatch({
                type: TOTAL_QUERY_DATA,
                payload: total
            })
            if(total == 2){
                dispatch(DataTablaZonaSellReducer(select))
            }
        // }else{
        //     notificacionServidor('error', data.message)
        // }
    })
    .catch((error)=> {
        notificacionServidor('error', 'Error con el servidor. Vuelva a ingresar más tarde.')
    })
}

export const DataTablaZonaSellReducer = (select) => async (dispatch, getState) => {

    let req_mes_validator = getState().powerBi.rex_mes_validator

    const {
        rex_data_query_1,
        rex_data_query_2
    } = getState().powerBi

    let headerFetch = {
        'Accept' : 'application/json',
        'Content-type' : 'application/json'
    }

    await fetch(config.api_url_powerbi+'dashboards/get-data',
		{
            mode:'cors',
			method: 'POST',
			headers: headerFetch,
            body: JSON.stringify({
                'req_mes_validator' : req_mes_validator,
                're_data_query_1'   : rex_data_query_1,
                're_data_query_2'   : rex_data_query_2,
            }),
      	}
    )
    .then(async res => {
		return res.json()
    })
    .then(async data => {
		// const estadoRequest = getState().estadoRequest.init_request
		// if(estadoRequest === true){
            dispatch({
                type: TABLA_EXPANDED_DATA,
                payload : [0,1]
            })
            dispatch({
                type: OBTENER_LISTA_DATA_DASHBOARD,
                payload : [...data.dataFinal]
            })
            dispatch({
                type: OBTENER_LISTA_TOTAL_DATA_DASHBOARD,
                payload : data.dataTotalSellOut
            })
            dispatch({
                type: OBTENER_LISTA_DATA_COPIA_DASHBOARD,
                payload : [...data.dataFinal]
            })

            dispatch( FiltroTableroDinamicoDataReducer() )
		// }else{
        //     notificacionServidor('error', data.message)
        // }
    })
    .catch((error)=> {
        notificacionServidor('error', 'Error con el servidor. Vuelva a ingresar más tarde.')
    })

    if(select){
        dispatch({
            type: CARGANDO_LISTA_DATA,
            payload : false
        })
        dispatch({
            type: CARGANDO_LISTA_DATA_DERECHA,
            payload : false
        })
    }else{
        dispatch({
            type: CARGANDO_LISTA_DATA,
            payload : false
        })
    }
}

export const ExpandirReducirTablaPowerBiReducer = (expanded, record) => async (dispatch, getState) => {
    let expandirTabla = getState().powerBi.rex_tabla_expanded_data
    const hayFila = expandirTabla.find(key => key == record.key)
    const quitarFila = expandirTabla.filter(key => key !== record.key)
    dispatch({
        type: TABLA_EXPANDED_DATA,
        payload: hayFila || hayFila == 0 
                    ? quitarFila : [...expandirTabla, record.key],
    })
}

export const ReducirTablaPowerBiReducer = () => async (dispatch, getState) => {
    let expandirTabla = getState().powerBi.rex_tabla_expanded_data
    const listaTotal = getState().powerBi.rex_lista_data_dashboard
    let listado = []
    let estado = false
    listaTotal.map(lista => {
        if(lista.children){
            lista.children.find(l => 
                expandirTabla.find(ex => {
                    if(ex == l.key){
                        estado = true
                    }
                })
            )
        }
    })

    let totalExpand = []
    listaTotal.map(lista => {
        totalExpand.push(lista.key)
        lista.children  
            &&  lista.children.map(c => totalExpand.push(c.key))
    })
    dispatch({
        type: TOTAL_EXPANDED_DATA,
        payload: totalExpand,
    })

    if(estado){
        listaTotal.map(lista => listado.push(lista.key))
        dispatch({
            type: TABLA_EXPANDED_DATA,
            payload: listado,
        })
    }else{
        dispatch({
            type: TABLA_EXPANDED_DATA,
            payload: [],
        })
    }
}

export const ExpandirTablaPowerBiReducer = () => async (dispatch, getState) => {
    let expandirTabla = getState().powerBi.rex_tabla_expanded_data
    const listaTotal = getState().powerBi.rex_lista_data_dashboard
    let listado = []
    let total = 0
    listaTotal.map(lista => 
        expandirTabla.map(ex => {
            if(lista.key == ex){
                total = total + 1
            }
        })
    )
    let guardarTodo = false
    let listadoParcial = []
    listaTotal.map((lista, index) => {
        if(total == listaTotal.length){
            guardarTodo = true
            listado.push(lista.key)
            listadoParcial.push(lista.key)
            lista.children  
                &&  lista.children.map((c, i) => {
                    if(index == 1 && i == 0){
                        listadoParcial.push(c.key)
                    }
                    listado.push(c.key)
                })
        }else{
            listado.push(lista.key)
        }
    })

    let totalExpand = []
    listaTotal.map(lista => {
        totalExpand.push(lista.key)
        lista.children  
            &&  lista.children.map(c => totalExpand.push(c.key))
    })
    dispatch({
        type: TOTAL_EXPANDED_DATA,
        payload: totalExpand,
    })

    dispatch({
        type: TABLA_EXPANDED_DATA,
        payload: guardarTodo ? listadoParcial : listado,
    })

    if(guardarTodo){
        setTimeout(() => {
            dispatch({
                type: TABLA_EXPANDED_DATA,
                payload: listado,
            })
        }, 500)
    }
}

export const DonwloadDataExcelPowerBiReducer = () => async (dispatch, getState) => {
    const { rex_lista_data_dashboard, rex_lista_total_data_dashboard } = getState().powerBi
    let url = ""

    let headerFetch = {
        'Accept' : 'application/json',
        'Content-type' : 'application/json'
    }

    await fetch(config.api_url_powerbi+'dashboards/donwload-data',
        {
            mode:'cors',
            method: 'POST',
            headers: headerFetch,
            body: JSON.stringify({
                'req_excel_data' : rex_lista_data_dashboard,
                'req_excel_data_todal' : rex_lista_total_data_dashboard,
            }),
        }
    )
    .then(async res => {
		return res.blob()
    })
    .then((blob) => {
        url = URL.createObjectURL(blob)
    })
    .catch((error)=> {
        notificacionServidor('error', 'Error con el servidor. Vuelva a ingresar más tarde.')
    })

    return url
}