import {
    GET_DATA_MENU,
    GET_DATA_MENU_CLONE,
    GET_DATA_MENU_PROFILE_TYPES_CLONE,
    GET_DATA_MENU_MODULE_CLONE,
} from "../../../../Constants/GetData/Menu/Menu"

const INIT_STATE = {
    rex_data_menu                       : [],
    rex_data_menu_clone                 : [],
    rex_data_menu_profile_types_clone   : [],
    rex_data_menu_module_clone          : [],
}

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_DATA_MENU: {
            return {
                ...state,
                rex_data_menu : action.payload,
            }
        }
        case GET_DATA_MENU_CLONE: {
            return {
                ...state,
                rex_data_menu_clone : action.payload,
            }
        }
        case GET_DATA_MENU_PROFILE_TYPES_CLONE: {
            return {
                ...state,
                rex_data_menu_profile_types_clone : action.payload,
            }
        }
        case GET_DATA_MENU_MODULE_CLONE: {
            return {
                ...state,
                rex_data_menu_module_clone : action.payload,
            }
        }
        default:
            return state;
    }
}
