import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, Typography, Form, Input, Checkbox } from 'antd'
import IconCreateProfile from '../../Assets/Images/CreateProfile/icon-create-profile.png'
import FormCreateProfile from '../../Components/CreateProfile/FormCreateProfile'
import { EditTypeProfileReducer, GetModulesProfileReducer, GetTypeProfileCountryReducer, SaveCheckPermissionsCountryReducer, SaveNameTypeProfileReducer } from '../../Redux/Actions/ProfileTypes/ProfileTypes'
import SideMenu from '../../Components/Global/SideMenu'
import ModalNotification from '../../Components/Global/ModalNotification'

const CreateProfile = () => {

    const { Title } = Typography
    const dispatch = useDispatch()

    const menuProfileTypes = [
        {
            url: '/administrator/profile-types',
            nombre: 'Lista de tipos de perfiles'
        },
        {
            url: '/administrator/create-profile',
            nombre: 'Nuevo tipo de perfil'
        },
    ]

    const {
        rex_name_type_profile,
        rex_permissions_country,
    } = useSelector(({profileTypes}) => profileTypes)

    const { 
        rex_users_conected_dashboard,
    } = useSelector(({registerUsers}) => registerUsers)

    const { 
        rex_data_users_online
    } = useSelector(({users}) => users)

    const { 
        rex_data_country_clone,
    } = useSelector(({paises}) => paises)

    useEffect(() => {
        dispatch(GetModulesProfileReducer())
        dispatch(GetTypeProfileCountryReducer())
        dispatch(EditTypeProfileReducer(false))
    }, [])

    const onChangeCheckboxCountry = (checkedCountries) => {
        dispatch(SaveCheckPermissionsCountryReducer(checkedCountries))
    }

    return (
        <div className='Container-Main'>
            <Form
                layout='vertical'
                className='Create-Profile-Form'
            >
                <Row>
                    <Col
                        className='Create-Profile-Container-Left'
                        xs={{span:24}} sm={{span:24}} md={{span:24}} lg={{span:12}} xl={{span:12}}
                    >
                        <img src={IconCreateProfile} width={50} height={50} />
                        <Title
                            level={2}
                            className='Create-Profile-Text-Main'
                        >
                            Nuevo Perfil
                        </Title>
                        <p className='Create-Profile-Text-Description'>
                            En este módulo podrá crear nuevos perfiles y adaptarlos a su requerimiento.
                        </p>
                        <Form.Item
                            className="Search-Profile"
                            label="Nombre del perfil"
                        >
                            <Input value={rex_name_type_profile} onChange={(e) => dispatch(SaveNameTypeProfileReducer(e.target.value))} />
                        </Form.Item>
                        <Title level={2} className='Form-Create-Profile-Text-Main'>Permisos</Title>
                        <Form.Item
                            label='Acceso nivel país'
                            className='Form-Item-Level-Country-Create-Profile'
                        >
                            <div className='Container-Checkbox-Create-Profile'>
                                <Checkbox.Group
                                    onChange={onChangeCheckboxCountry}
                                    options={rex_data_country_clone}
                                    value={rex_permissions_country}
                                    className="Group-Check-List"
                                >
                                </Checkbox.Group>
                            </div>
                        </Form.Item>
                    </Col>
                    <FormCreateProfile/>
                </Row>
            </Form>

            <ModalNotification/>

            <SideMenu
                usersCurrentView  = {rex_users_conected_dashboard}
                usersAllConnected ={rex_data_users_online}
            />
        </div>
    )
}

export default CreateProfile