
import config from '../../../config'
import { GET_AUDITS_TYPES, GET_AUDITS_TYPES_USERS, GET_AUDITS_USERS, GET_AUDITS_USERS_CLONE } from '../../../Constants/Audits/Audits';

export const GetAuditsUserReducer = () => async ( dispatch, getState) => {
    
    await fetch(config.api_public+'audits/all',
        {
            mode:'cors',
            method: 'POST',
            headers: {
                'Accept' 	   : 'application/json',
                'Content-type' : 'application/json',
                'usutoken'      : localStorage.getItem('usutoken')
            },
        }
    )
    .then( async res => {
        return res.json()
    })
    .then(data => {
        if(data.response){

            dispatch({
                type: GET_AUDITS_USERS,
                payload : data.data.auds
            })
            dispatch({
                type: GET_AUDITS_USERS_CLONE,
                payload : data.data.auds
            })

            let types_mods = data.data.mods

            types_mods.map(typ => {
                typ['value']    = typ.audmodulo
                typ['text']     = typ.audmodulo
                typ['label']    = typ.audmodulo
            })

            dispatch({
                type: GET_AUDITS_TYPES,
                payload : types_mods
            })

            dispatch({
                type: GET_AUDITS_TYPES_USERS,
                payload : data.data.typesUsers
            })
        }
    }).catch((error)=> {
        console.log(error)
    });
}

export const FilterAuditsReducer = ( mod, time, txt ) => async ( dispatch, getState ) => {

    const audits = getState().audits.rex_audits_users_clone
    let filter_audits = []

    if(mod.length == 0 && txt == "" && time == ""){
        filter_audits = audits
    }else{

        filter_audits = audits.filter( aud => ( mod.length > 0 ? mod.includes(aud.audmodulo) : true)
                                            && (time ? aud.created_at.toLowerCase().includes(time.toLowerCase()) : true) 
                                    )
    }

    dispatch({
        type : GET_AUDITS_USERS,
        payload : filter_audits
    })
}

export const DeleteAuditsReducer = ( id ) => async ( dispatch, getState ) => {

    let response = false

    await fetch(config.api+'audits/delete-audits',
        {
            mode:'cors',
            method: 'POST',
            headers: {
                'Accept'        : 'application/json',
                'Content-type'  : 'application/json',
                'usutoken'      : localStorage.getItem('usutoken')
            },
            body: JSON.stringify({
                req_audids : id
            }),
        }
    )
    .then( async res => {
        return res.json()
    })
    .then(async data => {
        if(data.response){
            response = true
            dispatch(GetAuditsUserReducer())
        }
    }).catch((error)=> {

    });

    return response
}

export const RegisterAuditReducer = ( audit ) => async ( req, res ) => {
    
    await fetch(config.api_public+'audits/register-error',
        {
            mode:'cors',
            method: 'POST',
            headers: {
                'Accept'        : 'application/json',
                'Content-type'  : 'application/json',
                'usutoken'      : localStorage.getItem('usutoken')
            },
            body: JSON.stringify({
                req_url : audit.url,
                req_message_error : audit.message,
                req_descripcion : audit.description,
                req_jsonentrada : audit.json_input,
                req_jsonsalida : audit.json_output,
                req_ip : audit.ip,
                req_accion : audit.action,
                req_status : audit.status,
                req_module : audit.module,
            }),
        }
    )
    .then( async res => {
        return res.json()
    })
    .then(async data => {
        if(data.respuesta){

        }else{

        }
    }).catch((error)=> {

    });
}