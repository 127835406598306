import React, { useState, useEffect } from 'react'
import { Link } from "react-router-dom"
import { Row, Col, Typography, Form, Input } from 'antd'
import { ArrowLeftOutlined } from '@ant-design/icons'
import SoundButton from '../../Assets/Audio/sonidoOption.mp3'
import './../../Styles/Routes/Login/RecoverPassword.css'
import { useDispatch } from 'react-redux'
import { RecoverPasswordLoginGrowReducer } from '../../Redux/Actions/LoginGrow/LoginGrow'
import { ShowModalNotificationGlobalReducer } from '../../Redux/Actions/Global/Global'
import ModalNotification from '../../Components/Global/ModalNotification'

const RecoverPassword = () => {
    const { Title } = Typography
    const [ tamanioEmail, setTamanioEmail] = useState(false)
    const [backgroundColor, setBackgroundColor] = useState('')
    const [ focusInputEmail, setFocusInputEmail ] = useState(false)
    const [ email, setEmail ] = useState('')
    const [ loadingRecover, setLoadingRecover ] = useState(false)

    const audio = new Audio(SoundButton)
    const [ clickButton, setClickButton ] = useState(false)
    const reproduceSound = () => {
        audio.play();
    }

    const dispatch = useDispatch()

    const lengthInput = (e) => {
        let lengthValue = e.target.value
        if(lengthValue.length  > 0){
            setTamanioEmail(true)
        }else{
            setTamanioEmail(false)
        }
        setEmail(e.target.value)
    }

    const showModalAlert = (show, title) => {
        let dataModal = {title:title, messageDescription: [],  status:""}
        dispatch(ShowModalNotificationGlobalReducer(show, dataModal))
    }

    useEffect(() => {

        const now = new Date()
        const hour = now.getHours()

        if(hour >= 6 && hour < 12){
            setBackgroundColor('Color-Blue')
        }else if(hour >= 12 && hour < 18){
            setBackgroundColor('Color-Yellow')
        }else{
            setBackgroundColor('Color-Purple')
        }
    }, [])

    return (
        <Row className="Container-Image-Gif-Recover" style={{height: '100vh'}}>
            <Col lg={15} md={24} xs={24}>
                <div className={`Container-Recover-Password`}>
                    <Form
                        name="basic"
                        className="Form-Recover-Password"
                        autoComplete="off"
                    >
                        <Title 
                            level={2}
                            className="Title-Recover-Password"
                        >
                            ¿Olvidaste tu <br/>contraseña?
                        </Title>
                        
                        <p className="Message-Recover">
                            No hay problema. <br/>Introduzca a continuación la dirección de correo <br/>electrónico y le enviaremos un enlace de <br/>restablecimiento de contraseña.
                        </p>

                        <p className="Message-Recover-Responsive">
                            No hay problema. <br/>Introduzca a continuación la dirección de correo electrónico y le enviaremos un enlace de restablecimiento de contraseña.
                        </p>

                        <Form.Item
                            name="correo"
                            style={{
                                marginBottom: '10px',
                                textAlign: 'center',
                                display:'flex',
                                justifyContent:'center',
                            }}
                        >
                            <div style={{ display:'flex', flexDirection:'column', position:'relative'}}>
                                <Input
                                    onFocus={()=> {
                                        setFocusInputEmail(true)
                                    }}
                                    onBlur={() =>{
                                        if(tamanioEmail == false){
                                            setFocusInputEmail(false)
                                        }
                                    }}
                                    onChange={(e) => lengthInput(e, 1)}
                                    className={`Input-Recover`}
                                />
                                <label class={`${focusInputEmail ? `Label-Input-Login-Grow-Focus Color-Yellow`:'Label-Input-Login-Grow' }`}>Email Address</label>
                            </div>
                        </Form.Item>

                        <Form.Item
                            style={{
                                textAlign: 'center',
                                marginTop: '15px'
                            }}
                        >

                            <button
                                className="Button-Login-Grow Recover-Button"
                                htmlType="submit"
                                onClick={async()=> {
                                    reproduceSound()
                                    setClickButton(true)
                                    if(tamanioEmail){
                                        setLoadingRecover(true)
                                        let { response, message } = await dispatch(RecoverPasswordLoginGrowReducer(email))
                                        showModalAlert(true, message)
                                        setLoadingRecover(false)
                                    }else{
                                        showModalAlert(true, "Debe ingresar un email")
                                    }
                                }}
                                onMouseEnter={() => reproduceSound()}
                                onMouseLeave={() => setClickButton(false)}
                            >
                                <span style={{zIndex:"1"}}>{loadingRecover ? "Enviando": "Recuperar Contraseña"}</span>
                                <span className={`Text-Span-Login-Grow Button-Text-Color`}>{loadingRecover ? "Enviando":"Recuperar contraseña"}</span>

                                <div className="Button-Background-Login-Grow"></div>
                                <div className='Button-Corner Button-Top-Left-Login-Grow'></div>
                                <div className='Button-Corner Button-Top-Right-Login-Grow'></div>
                                <div className='Button-Corner Button-Bottom-Right-Login-Grow'></div>
                                <div className='Button-Corner Button-Bottom-Left-Login-Grow'></div>                                
                            </button>

                        </Form.Item>

                        <Link to="/login-grow">
                            <div className="Container-Return-Login-Grow">
                                <ArrowLeftOutlined className="Icon-Return-Login-Grow" />
                                <p className="Text-Return-Login-Grow">Regresar a todas las opciones</p>
                            </div>
                        </Link>
                    </Form>
                </div>
            </Col>
            <Col lg={9} md={0} xs={0} className="Col-Image-Recover"></Col>
            <ModalNotification/>
        </Row>
    )
}

export default RecoverPassword