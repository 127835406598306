import { Button, Form, Input, Modal, Upload, Space, Checkbox } from 'antd'
import React, { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux';
import { EnviarCorreoDashboardReducer } from '../../../Redux/Actions/Dashboards/Dashboards';

const EnviarCorreoAutomatico = ({
	openModal,
	setOpenModal
}) => {

	const { TextArea } = Input;

	const [ newEmail, setNewEmail ] = useState("")
	const [ sendingMail, setSendingMail ] = useState(false)

	const [ listUsers, setListUsers ] = useState([
		'Miguel.Caballero@grow-analytics.com.pe',
	])
	
	const fileList = [
		{
		  uid: '-1',
		  name: 'Dashboard Retail Channel.pdf',
		  status: 'done',
		  url: 'https://backend-spider-kimberly-corporativa.grow-corporate.com/Sistema/bolivia/pdf/Daily%20BO%20Junio%202023.pdf',
		  thumbUrl: 'https://cdn-icons-png.flaticon.com/256/4726/4726010.png',
		},
	];

	const dispatch = useDispatch()

	const sendEmailAutomatic = async () => {
		setSendingMail(true)
		const response = await dispatch(EnviarCorreoDashboardReducer({
			message: 'Dashboard Retail Channel GIA',
			subject: 'Dashboard GIA',
			to : listUsers
		}))
		setSendingMail(false)
		setOpenModal(false)
	}


	const updateList = () => {

		const copyList = [...listUsers]
		copyList.push(newEmail)
		setNewEmail("")
		setListUsers(copyList)
	}

	useEffect(() => {

	},[listUsers])
	
    return (
        <Modal
			open={openModal}
			closeIcon={false}
			centered
			closable={false}
			zIndex={100000000}
			onCancel={() => {
				setOpenModal(false)
			}}
			footer={null}
		>
			<Form
				layout='vertical'
			>

				<Form.Item
					style={{

					}}
					label='Destinatarios:'
				>
					<div style={{display:'flex', gap:'2px'}}>

					</div>
					{
						listUsers.map(usr => (
							<div>{' '}<Checkbox defaultChecked={true}/> {usr}</div>
						))
					}
					<div style={{display:'flex', margin:'10px 0 0 2px'}}>Nuevo:</div>
					<Space.Compact
						style={{
							width: '70%',
						}}
					>
						<Input size='small' 
							onChange={(e) => {
								setNewEmail(e.target.value)
							}}
							value={newEmail}
						/>
						<Button onClick={() => updateList()} size='small' type="primary">Agregar</Button>

					</Space.Compact>
				</Form.Item>
				<div style={{fontSize:'9px', marginBottom:'10px'}}>
				Este mensaje es estrictamente para el uso de la persona o entidad a quien va dirigida y puede contener información privilegiada, confidencial y de acceso restringido según la legislación aplicable. Si el lector de este mensaje no es el destinatario,  el empleado o agente responsable de entregar este mensaje al destinatario indicado, queda notificado de que cualquier divulgación, distribución o copia de esta comunicación está estrictamente prohibido. Si usted ha recibido esta comunicación por error, le rogamos nos lo notifique inmediatamente a la dirección de respuesta.
				</div>
			</Form>
			<div style={{display:'flex', justifyContent:'end', gap:'10px'}}>
				<Button 
					danger
					onClick={() => setOpenModal(false)}
				>Cancelar</Button>
				<Button 
					type='primary'
					loading={sendingMail}
					onClick={() => sendEmailAutomatic()}
				>Enviar Correo</Button>
			</div>
        </Modal>
    )
}

export default EnviarCorreoAutomatico