import React from 'react'

function MenuOption({fill, stroke}){
    return (
        <svg width="14" height="14" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
            <path d="M15 8H1" stroke={stroke} stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            <path d="M8 15L1 8L8 1" stroke={stroke} stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
        </svg>
    )
}

const ButtonBack = ({over}) => {

    let color = '#013A81';
    if(over == 'over'){
        color = '#ffffff';
    }
    return (
        <MenuOption stroke={color}/>
    )
}

export default ButtonBack