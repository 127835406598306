import config from "../../../config";
import { GET_MODULE_DATA_MENU_CLONE } from "../../../Constants/Modules/CreateEditModules"
import {
    OBTENER_TIPOS_PERFILES,
    SELECCIONAR_TIPO_PERFIL,
    OBTENER_TIPO_PERFIL,
    OBTENER_TIPO_PERFIL_COPIA,
    PERSONALIZAR_TIPO_PERFIL,
    MODULO_PAIS_SELECCIONADO,
    SELECCIONAR_TODOS_MODULOS,
    GET_PROFILE_TYPES,
    GET_PROFILE_TYPES_CLONE,
    GET_PROFILE_TYPE_SELECTED,
    MODAL_LIST_USERS_PROFILE,
    LIST_USERS_PROFILE,
    REGISTER_USERS_CONNECTED_MODULES_PROFILE_TYPES,
    GET_DATA_MODULES_PROFILE,
    GET_DATA_MODULES_PROFILE_CLONE,
    CHECK_ALL_MODULE_COUNTRY,
    NAME_TYPE_PROFILE,
    PERMISSIONS_COUNTRY,
    GET_UNIQUE_TYPE_PROFILE,
    GET_TYPE_PROFILE_COUNTRY,
    EDIT_TYPE_PROFILE,
    SELECTED_PROFILE_TYPE_UNIQUE,
    SELECTED_COUNTRY_PROFILE_TYPE,
    LOADING_PROFILE_TYPE,
} from "../../../Constants/ProfileTypes/ProfileTypes"
import {
    DATA_CREATE_USER, DATA_USERS_FORM
} from "../../../Constants/Users/Users"
import { RegisterAuditReducer } from "../Audits/Audits"
import { notification } from 'antd'
import { GetDataMenuAllReducer, GetDataMenuReducer } from "../GetData/Menu/Menu";
import { ShowModalNotificationGlobalReducer } from "../Global/Global";
import socket from '../../../App/socket'
import { GetUserModulosPermisosReducer } from "../Users/Users";

const notificacionServidor = (type, mensaje) => {
    if(type === 'success'){
        notification['success']({
            message: 'Éxito',
            description: mensaje,
        })
    }else if(type === 'warning'){
        notification['warning']({
            message: 'Advertencia',
            description: mensaje,
        })
    }else if(type === 'error'){
        notification['error']({
            message: 'Error',
            description: mensaje,
        })
    }
}

export const GetProfileTypes = () => async ( dispatch, getState ) => {

    dispatch({
        type    : LOADING_PROFILE_TYPE,
        payload : true
    })

    await fetch(config.api+'profile-types/get-profile-types',
    {
        mode:'cors',
        method: 'POST',
        headers: {
            'Accept' 	   : 'application/json',
            'Content-type' : 'application/json',
            'usutoken'      : localStorage.getItem('usutoken')
        },
    }
    )
    .then( async res => {
        return res.json()        
    })
    .then(data => {
        if(data.response){
            dispatch({
                type    : GET_PROFILE_TYPES,
                payload : data.data
            })
            dispatch({
                type    : GET_PROFILE_TYPES_CLONE,
                payload : data.data
            })
        }
        dispatch({
            type    : LOADING_PROFILE_TYPE,
            payload : false
        })    
    }).catch((error)=> {
        console.log(error)
    });
}

export const EditStatusProfileTypeReducer = ( tpuid, value ) => async ( dispatch, getState ) => {

    let response = false

    await fetch(config.api+'profile-types/edit-status-profile-type',
    {
        mode:'cors',
        method: 'POST',
        headers: {
            'Accept' 	   : 'application/json',
            'Content-type' : 'application/json',
            'usutoken'      : localStorage.getItem('usutoken')
        },
        body: JSON.stringify({
            req_estid   : value,
            req_tpuid   : tpuid
        }),
    }
    )
    .then( async res => {
        return res.json()
    })
    .then(data => {
        if(data.response){
            dispatch(GetProfileTypes())
            response = true
        }else{
            const json_audit = {
                url : "/profile-types/edit-status-profile-type",
                ip : '',
                json_input : { req_tpuid : tpuid },
                json_output : data,
                description : data.message,
                message     : data.message,
                action      : "UPDATE STATUS PROFILE TYPE",
                status      : false,
                module      : "Tipos de perfiles"
            }
            dispatch(RegisterAuditReducer(json_audit))
        }
    }).catch((error)=> {
        console.log(error)
    });
    return response
}

export const DeleteProfileTypeReducer = ( tpuids ) => async ( dispatch, getState ) => {

    let response = false

    await fetch(config.api+'profile-types/delete-profile-types',
    {
        mode:'cors',
        method: 'POST',
        headers: {
            'Accept' 	   : 'application/json',
            'Content-type' : 'application/json',
            'usutoken'      : localStorage.getItem('usutoken')
        },
        body: JSON.stringify({
            req_tpuid   : tpuids,
        }),
    }
    )
    .then( async res => {
        return res.json()        
    })
    .then(data => {
        if(data.response){
            dispatch(GetProfileTypes())
            response = true
        }
    }).catch((error)=> {
        console.log(error)
    });

    return response
}

export const FilterProfileTypesReducer = ( pai_values ) => async ( dispatch, getState ) => {

    const dataProfileTypes = [...getState().profileTypes.rex_profile_types]

    let option_pai = pai_values.length > 0 ? true : false

    let profileTypesFilter = dataProfileTypes.filter( tpu => 
            ( option_pai ?
            ( tpu.paises.paiid.filter( pau => (pai_values.includes(pau))).length > 0 )
            : typeof tpu.paises == 'object' )
    )

    dispatch({
        type: GET_PROFILE_TYPES_CLONE,
        payload: profileTypesFilter
    })
}

export const GetListUsersProfileReducer = ( tpuid ) => async ( dispatch, getState ) => {

    await fetch(config.api+'profile-types/list-users-profile',
    {
        mode:'cors',
        method: 'POST',
        headers: {
            'Accept' 	   : 'application/json',
            'Content-type' : 'application/json',
            'usutoken'      : localStorage.getItem('usutoken')
        },
        body: JSON.stringify({
            req_tpuid   : parseInt(tpuid),
        }),
    })
    .then( async res => {
        return res.json()        
    })
    .then(data => {
        if(data.response){
            dispatch({
                type : LIST_USERS_PROFILE,
                payload: data.data
            })
        }
    }).catch((error)=> {
        console.log(error)
    });
}

export const GetTypeProfileSelectedReducer = ( data ) => async ( dispatch, getState ) => {

    dispatch({
        type    : GET_PROFILE_TYPE_SELECTED,
        payload : data
    })
}

export const GetModulesProfileReducer = (id_profile_type) => async ( dispatch, getState ) => {
    await fetch(config.api+'modules/get-modules-profile',
    {
        mode:'cors',
        method: 'POST',
        headers: {
            'Accept' 	   : 'application/json',
            'Content-type' : 'application/json',
            'usutoken'      : localStorage.getItem('usutoken')
        },
    }
    )
    .then( async res => {
        return res.json()        
    })
    .then(async data => {
        if(data.response){
            await dispatch(GetDataMenuAllReducer())
            dispatch({
                type    : GET_DATA_MODULES_PROFILE,
                payload : data.data
            })

            let modules_all = []
            const { rex_data_menu_profile_types_clone } = getState().menu
            modules_all = data.data
            await modules_all.map((mod, index) => {
                modules_all[index]['mod_checked'] = false
                if(mod.smosubmodulos.length > 0){
                    mod.smosubmodulos.map((smo, indexsmo) => {
                        modules_all[index]['smosubmodulos'][indexsmo]['submod_checked'] = false
                        if(smo.ssmsubmodulos.length > 0){
                            smo.ssmsubmodulos.map((ssm, ssmindex) => {
                                modules_all[index]['smosubmodulos'][indexsmo]['ssmsubmodulos'][ssmindex]['subsubmod_checked'] = false
                            })
                        }
                    })
                }
            })

            rex_data_menu_profile_types_clone.map(menu => {
                modules_all.push({
                    modnombre: menu.mnunombre,
                    paiid: menu.paiid,
                    pemid: menu.pemid,
                    mod_checked: false,
                    smosubmodulos: menu.smo,
                })
            })

            modules_all.map((mod, index) => {
                modules_all[index]["key"] = "mod-"+index
            })
            
            await dispatch({
                type    : GET_DATA_MODULES_PROFILE_CLONE,
                payload : modules_all
            })

            if(id_profile_type){
            //     dispatch(GetProfileTypeUniqueReducer(id_profile_type))
            //     dispatch(EditTypeProfileReducer(true))
                dispatch(GetUserModulosPermisosReducer(id_profile_type))
            }
        }
    }).catch((error)=> {
        console.log(error)
    })
}

export const GetTypeProfileCountryReducer = () => async ( dispatch, getState ) => {
    await fetch(config.api+'profile-types/get-profile-types-country',
    {
        mode:'cors',
        method: 'POST',
        headers: {
            'Accept' 	   : 'application/json',
            'Content-type' : 'application/json',
            'usutoken'      : localStorage.getItem('usutoken')
        },
    })
    .then(res => res.json())
    .then(async data => {
        if(data.response){
            const get_country = data.data.map(cou => {
                return {
                    value: cou.pemid,
                    label: cou.pemnombre,
                }
            })
            dispatch({
                type    : GET_TYPE_PROFILE_COUNTRY,
                payload : get_country
            })
        }
    }).catch((error)=> {
        console.log(error)
    })
}

export const CreateEditTypeProfileReducer = (id) => async (dispatch, getState) => {
    const {
        rex_name_type_profile,
        rex_data_modules_profile_clone,
        rex_tipo_perfil_seleccionado,
        rex_personalizar_tipo_perfil,
        rex_permissions_country,
    } = getState().profileTypes

    let respuesta = false

    let validation = true
    if(rex_name_type_profile == ''){
        validation = false
    }

    let permisos = []
    rex_data_modules_profile_clone.map(mod => {
        if(mod.mod_checked) permisos.push(mod.pemid)
        if(mod.smosubmodulos.length > 0){
            mod.smosubmodulos.map(smo => {
                if(smo.submod_checked) permisos.push(smo.pemid)
                if(smo.ssmsubmodulos.length > 0){
                    smo.ssmsubmodulos.map(ssm => {
                        if(ssm.subsubmod_checked) permisos.push(ssm.pemid)
                    })
                }
            })
        }
    })

    if(permisos.length == 0) validation = false
    
    if(validation){
        dispatch({
            type : LOADING_PROFILE_TYPE,
            payload : true,
        })
        let ruta = "create-profile-type"
        let jsonBody = {
            req_tpunombre: rex_name_type_profile,
            req_tuppermisos: permisos,
            req_countries : rex_permissions_country,
            req_personalize: rex_personalizar_tipo_perfil 
                ? rex_tipo_perfil_seleccionado['value']
                : null,
        }
        if(id){
            ruta = "edit-profile-type"
            jsonBody = {
                req_tpuid: parseInt(id),
                req_tpunombre: rex_name_type_profile,
                req_countries : rex_permissions_country,
                req_tuppermisos: permisos,
            }
        }

        await fetch(config.api+'profile-types/'+ruta,
        {
            mode:'cors',
            method: 'POST',
            headers: {
                'Accept' 	   : 'application/json',
                'Content-type' : 'application/json',
                'usutoken'      : localStorage.getItem('usutoken')
            },
            body: JSON.stringify(jsonBody),
        })
        .then(res => res.json())
        .then(data => {
            if(data.response){
                respuesta = true
                
                socket.emit('update-download-files', parseInt(id));

                dispatch(GetDataMenuReducer())
                if(data.data){
                    dispatch({
                        type    : SELECTED_PROFILE_TYPE_UNIQUE,
                        payload : {
                            id_profile: data.data.tpuid,
                            name_profile: data.data.tpunombre,
                            personalize_profile: true,
                        }
                    })

                    const { rex_data_users_form } = getState().users
                    rex_data_users_form['accessCountry'] = rex_permissions_country

                    dispatch({
                        type    : DATA_USERS_FORM,
                        payload : rex_data_users_form
                    })

                }
            }else{
                dispatch(ShowModalNotificationGlobalReducer(true, {title: data.message, messageDescription : data.messageDescription, status:data.status}))
                // notificacionServidor('warning', data.message)
            }
        }).catch((error)=> {
            console.log(error)
        })
        dispatch({
            type : LOADING_PROFILE_TYPE,
            payload : false,
        })
    }else{
        notificacionServidor('warning', 'Por favor llenar todos los campos')
    }

    return respuesta
}

export const GetProfileTypeUniqueReducer = (tpuid, clear_profile) => async (dispatch, getState) => {

    if(clear_profile){
        const { rex_data_modules_profile_clone } = getState().profileTypes
        rex_data_modules_profile_clone.map((mod, index) => {
            rex_data_modules_profile_clone[index]['mod_checked'] = false
            mod.smosubmodulos.map((smo, indexsmo) => {
                rex_data_modules_profile_clone[index]['smosubmodulos'][indexsmo]['submod_checked'] = false
                smo.ssmsubmodulos.map((ssm, ssmindex) => {
                    rex_data_modules_profile_clone[index]['smosubmodulos'][indexsmo]['ssmsubmodulos'][ssmindex]['subsubmod_checked'] = false
                })
            })
        })

        dispatch({
            type    : GET_DATA_MODULES_PROFILE_CLONE,
            payload : rex_data_modules_profile_clone
        })
    }

    await fetch(config.api+'profile-types/get-profile-type',
    {
        mode:'cors',
        method: 'POST',
        headers: {
            'Accept' 	   : 'application/json',
            'Content-type' : 'application/json',
            'usutoken'      : localStorage.getItem('usutoken')
        },
        body: JSON.stringify({
            req_tpuid: parseInt(tpuid),
        }),
    })
    .then(res => res.json())
    .then(data => {
        if(data.response){
            const data_response = data.data
            const filter_tpu = data_response.tuptiposusuariospermisos.filter(tpu => {
                if(tpu.pemid && tpu.pempermisos){
                    return true
                }
                return false
            })

            dispatch({
                type    : GET_UNIQUE_TYPE_PROFILE,
                payload : {
                    tpuid: data_response.tpuid,
                    tpunombre: data_response.tpunombre,
                    created_at: data_response.created_at,
                    updated_at: data_response.updated_at,
                    usuusuarios: data_response.usuusuarios,
                    usus: data_response.usus,
                    ctd_usu: data_response.ctd_usu,
                    tuptiposusuariospermisos: filter_tpu
                }
            })

            dispatch({
                type    : NAME_TYPE_PROFILE,
                payload : data_response.tpunombre
            })

            const modules_profile = getState().profileTypes.rex_data_modules_profile_clone
            
            data_response.tuptiposusuariospermisos.map(tup => {
                modules_profile.map((mod, index) => {
                    if(tup.pemid == mod.pemid){
                        modules_profile[index]['mod_checked'] = true
                    }
                    if(mod.smosubmodulos.length > 0){
                        mod.smosubmodulos.map((smo, indexsmo) => {
                            if(tup.pemid == mod.pemid){
                                modules_profile[index]['smosubmodulos'][indexsmo]['submod_checked'] = true
                            }else if(tup.pemid == smo.pemid){
                                modules_profile[index]['smosubmodulos'][indexsmo]['submod_checked'] = true
                            }
                            if(smo.ssmsubmodulos.length > 0){
                                smo.ssmsubmodulos.map((ssm, ssmindex) => {
                                    if(tup.pemid == mod.pemid || tup.pemid == smo.pemid){
                                        modules_profile[index]['smosubmodulos'][indexsmo]['ssmsubmodulos'][ssmindex]['subsubmod_checked'] = true
                                    }else if(tup.pemid == ssm.pemid){
                                        modules_profile[index]['smosubmodulos'][indexsmo]['ssmsubmodulos'][ssmindex]['subsubmod_checked'] = true
                                    }
                                })
                            }
                        })
                    }
                })
            })
            
            dispatch({
                type    : GET_DATA_MODULES_PROFILE_CLONE,
                payload : modules_profile
            })

            let value_all_country = []
            let enabled_countries = []
            const { rex_data_country_clone } = getState().paises

            rex_data_country_clone.map(cou => {
                let contador_mod = 0
                let val_country = false
                const filter_mod_pai = modules_profile.filter(mp => mp.paiid == cou.value)
                filter_mod_pai.map(fmp => {
                    if(fmp.mod_checked){
                        contador_mod = contador_mod + 1
                        val_country = true
                    }
                    fmp.smosubmodulos.map(smo => {
                        if(smo.submod_checked) val_country = true
                        smo.ssmsubmodulos.map(ssm => {
                            if(ssm.subsubmod_checked) val_country = true
                        })
                    })
                })

                if(filter_mod_pai.length == contador_mod) value_all_country.push(cou.value)
                
                if(val_country) enabled_countries.push(cou.value)
            })

            dispatch({
                type    : CHECK_ALL_MODULE_COUNTRY,
                payload : value_all_country
            })

            dispatch({
                type    : PERMISSIONS_COUNTRY,
                payload : enabled_countries
            })

            const find_paiid = filter_tpu.find(tpu => tpu.pempermisos)
            dispatch({
                type    : SELECTED_COUNTRY_PROFILE_TYPE,
                payload : find_paiid ? find_paiid.pempermisos.paiid : null
            })
        }
    }).catch((error)=> {
        console.log(error)
    })
}

export const GenerateDataMenuReducer = () => async (dispatch, getState) => {
    const { rex_get_module_data_home } = getState().createEditModules
    const { rex_data_country_clone } = getState().paises

    let data_box_menu = []
    rex_data_country_clone.map(country => {
        rex_get_module_data_home.map((home, index) => {
            data_box_menu.push({
                ...home,
                paiid: country.value,
                check_menu: false,
                key: index+"-"+country.label
            })
        })
    })
    
    dispatch({
        type    : GET_MODULE_DATA_MENU_CLONE,
        payload : data_box_menu
    })
}

export const EditTypeProfileReducer = (state_type) => async (dispatch) => {
    dispatch({
        type    : EDIT_TYPE_PROFILE,
        payload : state_type
    })
}

export const SaveNameTypeProfileReducer = (text) => async (dispatch, getState) => {
    let textTypeProfile = text ? text : ''
    dispatch({
        type    : NAME_TYPE_PROFILE,
        payload : textTypeProfile
    })
}

export const SaveCheckPermissionsCountryReducer = (countries) => async (dispatch, getState) => {
    const { 
        rex_selected_country_profile_type, 
        rex_data_modules_profile_clone, 
        rex_check_all_module_country,
    } = getState().profileTypes
    const { rex_data_country_clone } = getState().paises
    const find_selected_country = countries.find(cou => cou == rex_selected_country_profile_type)

    dispatch({
        type    : PERMISSIONS_COUNTRY,
        payload : countries
    })

    if(!find_selected_country){
        let country_selected = null
        countries.map((cou, index) => {
            if(index == 0) country_selected = cou
        })
        dispatch({
            type    : SELECTED_COUNTRY_PROFILE_TYPE,
            payload : country_selected
        })
    }

    rex_data_country_clone.map(cou => {
        const find_cou = countries.find(country => country == cou.value)
        if(!find_cou){
            rex_data_modules_profile_clone.map((mod, index) => {
                if(cou.value === mod.paiid){
                    rex_data_modules_profile_clone[index]['mod_checked'] = false
                    mod.smosubmodulos.map((smo, indexsmo) => {
                        rex_data_modules_profile_clone[index]['smosubmodulos'][indexsmo]['submod_checked'] = false
                        smo.ssmsubmodulos.map((ssm, ssmindex) => {
                            rex_data_modules_profile_clone[index]['smosubmodulos'][indexsmo]['ssmsubmodulos'][ssmindex]['subsubmod_checked'] = false
                        })
                    })
                }
            })
        }
    })

    let countries_all_module = []
    rex_check_all_module_country.map(mod => {
        const find_cou = countries.find(cou => cou == mod)
        if(find_cou) countries_all_module.push(find_cou)
    })

    dispatch({
        type    : CHECK_ALL_MODULE_COUNTRY,
        payload : countries_all_module
    })

    dispatch({
        type    : GET_DATA_MODULES_PROFILE_CLONE,
        payload : rex_data_modules_profile_clone
    })

}

export const SelectedCountryProfileTypeReducer = (country) => async (dispatch) => {
    dispatch({
        type    : SELECTED_COUNTRY_PROFILE_TYPE,
        payload : country
    })
}

export const SelectedModulesAllReducer = (value, country) => async (dispatch, getState) => {
    const { rex_check_all_module_country } = getState().profileTypes
    const modules_profile = getState().profileTypes.rex_data_modules_profile_clone

    modules_profile.map((mod, index) => {
        if(mod.paiid == country){
            modules_profile[index]['mod_checked'] = value
            if(mod.smosubmodulos.length > 0){
                mod.smosubmodulos.map((smo, indexsmo) => {
                    modules_profile[index]['smosubmodulos'][indexsmo]['submod_checked'] = value
                    if(smo.ssmsubmodulos.length > 0){
                        smo.ssmsubmodulos.map((ssm, ssmindex) => {
                            modules_profile[index]['smosubmodulos'][indexsmo]['ssmsubmodulos'][ssmindex]['subsubmod_checked'] = value
                        })
                    }
                })
            }
        }
    })

    dispatch({
        type    : GET_DATA_MODULES_PROFILE_CLONE,
        payload : modules_profile
    })
    
    dispatch({
        type    : CHECK_ALL_MODULE_COUNTRY,
        payload : value ? [...rex_check_all_module_country, country] : rex_check_all_module_country.filter(cou => cou !== country)
    })
}

export const SelectedModuleSpecificAllReducer = (module_id, value, country) => async (dispatch, getState) => {
    const { rex_data_modules_profile_clone, rex_check_all_module_country } = getState().profileTypes
    let data_module = rex_data_modules_profile_clone

    const modules_profile = rex_data_modules_profile_clone.find(mod => mod.key == module_id)
    modules_profile.mod_checked = value
    if(modules_profile.smosubmodulos.length > 0){
        modules_profile.smosubmodulos.map((smo, indexsmo) => {
            modules_profile['smosubmodulos'][indexsmo]['submod_checked'] = value
            if(smo.ssmsubmodulos.length > 0){
                smo.ssmsubmodulos.map((ssm, ssmindex) => {
                    modules_profile['smosubmodulos'][indexsmo]['ssmsubmodulos'][ssmindex]['subsubmod_checked'] = value
                })
            }
        })
    }

    data_module = rex_data_modules_profile_clone.map(module => {
        if(module.key == modules_profile.key){
            return {...modules_profile}
        }else{
            return {...module,}
        }
    })

    dispatch({
        type    : GET_DATA_MODULES_PROFILE_CLONE,
        payload : data_module
    })

    const data_module_country = data_module.filter(mod => mod.paiid == country)
    let contador_mod = 0
    data_module_country.map(mod => {
        if(mod.mod_checked) contador_mod = contador_mod + 1
    })


    dispatch({
        type    : CHECK_ALL_MODULE_COUNTRY,
        payload : contador_mod == data_module_country.length ? [...rex_check_all_module_country, country] : rex_check_all_module_country.filter(cou => cou !== country)
    })
}

export const SelectedSubModuleSpecificAllReducer = (submodule_id, value, modulo_id, country) => async (dispatch, getState) => {
    const { rex_data_modules_profile_clone, rex_check_all_module_country } = getState().profileTypes
    const modules_profile = rex_data_modules_profile_clone.map(mod => {
        if(mod.key == modulo_id){
            const smosub = mod.smosubmodulos.map(smo => {
                if(smo.smoid == submodule_id){
                    const ssmvalue = smo.ssmsubmodulos.map(ssm => {
                        return {
                            ...ssm,
                            subsubmod_checked: value,
                        }
                    })
                    return {
                        ...smo,
                        submod_checked: value,
                        ssmsubmodulos: ssmvalue,
                    }
                }else{
                    return { ...smo }
                }
            })

            let contadorSub = 0
            smosub.map(smo => {
                if(smo.submod_checked){
                    contadorSub = contadorSub + 1
                }
            })

            return {
                ...mod,
                mod_checked: contadorSub == mod.smosubmodulos.length ? true : false,
                smosubmodulos: smosub,
            }
        }else{
            return { ...mod }
        }
    })

    const data_module_country = modules_profile.filter(mod => mod.paiid == country)
    let contador_mod = 0
    data_module_country.map(mod => {
        if(mod.mod_checked) contador_mod = contador_mod + 1
    })

    dispatch({
        type    : GET_DATA_MODULES_PROFILE_CLONE,
        payload : modules_profile
    })

    dispatch({
        type    : CHECK_ALL_MODULE_COUNTRY,
        payload : contador_mod == data_module_country.length ? [...rex_check_all_module_country, country] : rex_check_all_module_country.filter(cou => cou !== country)
    })
}

export const SelectedSubSubModuleSpecificAllReducer = (subsubmodule_id, value, modulo_id, submodule_id, country) => async (dispatch, getState) => {
    const { rex_data_modules_profile_clone, rex_check_all_module_country } = getState().profileTypes
    const modules_profile = rex_data_modules_profile_clone.map(mod => {
        if(mod.key == modulo_id){
            const smosub = mod.smosubmodulos.map(smo => {
                if(smo.smoid == submodule_id){
                    const ssmvalue = smo.ssmsubmodulos.map(ssm => {
                        if(ssm.ssmid == subsubmodule_id){
                            return {
                                ...ssm,
                                subsubmod_checked: value,
                            }
                        }else{
                            return { ...ssm }
                        }
                    })
                    let contadorssm = 0
                    ssmvalue.map(ssm => {
                        if(ssm.subsubmod_checked){
                            contadorssm = contadorssm + 1
                        }
                    })

                    return {
                        ...smo,
                        submod_checked: contadorssm == smo.ssmsubmodulos.length ? true : false,
                        ssmsubmodulos: ssmvalue,
                    }
                }else{
                    return { ...smo }
                }
            })

            let contadorsmo = 0
            smosub.map(smo => {
                if(smo.submod_checked){
                    contadorsmo = contadorsmo + 1
                }
            })
            
            return {
                ...mod,
                mod_checked: contadorsmo == mod.smosubmodulos.length ? true : false,
                smosubmodulos: smosub,
            }
        }else{
            return { ...mod }
        }
    })

    const data_module_country = modules_profile.filter(mod => mod.paiid == country)
    let contador_mod = 0
    data_module_country.map(mod => {
        if(mod.mod_checked) contador_mod = contador_mod + 1
    })

    dispatch({
        type    : GET_DATA_MODULES_PROFILE_CLONE,
        payload : modules_profile
    })

    dispatch({
        type    : CHECK_ALL_MODULE_COUNTRY,
        payload : contador_mod == data_module_country.length ? [...rex_check_all_module_country, country] : rex_check_all_module_country.filter(cou => cou !== country)
    })
}

export const ShowModalListUsersProfileReducer = ( value ) => async ( dispatch, getState ) => {
    dispatch({
        type: MODAL_LIST_USERS_PROFILE,
        payload : value
    })
}

export const ObtenerTiposPerfilesReducer = () => async ( dispatch, getState ) => {

    const user = getState().loginGrow.rex_data_user_logingrow
    
    await fetch(config.api+'profile-types/obtener-tipos-perfiles',
        {
            mode:'cors',
            method: 'POST',
            headers: {
                'Accept' 	   : 'application/json',
                'Content-type' : 'application/json',
                'usutoken'      : localStorage.getItem('usutoken')
            },
        }
    )
    .then( async res => {
        return res.json()        
    })
    .then(data => {
        if(data.respuesta){
            dispatch({
                type: OBTENER_TIPOS_PERFILES,
                payload: data.data
            })
        }else{
            // dispatch(RegisterAuditReducer('tipos-perfiles/obtener-tipos-perfiles', 'Error al obtener los tipos de usuarios', data.message, localStorage.getItem('usutoken'), data, user.usuid, 'ip', 'GET PROFILE TYPES'))
        }
    }).catch((error)=> {
        console.log(error)
    })
}

export const ObtenerTipoPerfilReducer = ( tpuid ) => async ( dispatch, getState ) => {

    const user = getState().loginGrow.rex_data_user_logingrow

    const data_profile_type_get = {
        req_tpuid : tpuid
    }
    
    await fetch(config.api+'profile-types/obtener-tipo-perfil',
        {
            mode:'cors',
            method: 'POST',
            headers: {
                'Accept' 	   : 'application/json',
                'Content-type' : 'application/json',
                'usutoken'      : localStorage.getItem('usutoken')
            },
            body: JSON.stringify(data_profile_type_get)
        }
    )
    .then( async res => {
        return res.json()        
    })
    .then(data => {
        if(data.response){
            dispatch({
                type: OBTENER_TIPO_PERFIL,
                payload: data.data
            })
            dispatch({
                type: OBTENER_TIPO_PERFIL_COPIA,
                payload: structuredClone(data.data)
            })
            dispatch({
                type : MODULO_PAIS_SELECCIONADO,
                payload : null
            })
            dispatch(SetSeleccionarTodosModulosReducer())
        }else{
            // dispatch(RegisterAuditReducer('profile-types/obtener-tipo-perfil', 'Error al obtener al tipo de usuario', data.message, data_profile_type_get, data, user.usuid, 'ip', 'GET PROFILE TYPE'))
        }
    }).catch((error)=> {
        console.log(error)
    });
}

export const SetSeleccionarTodosModulosReducer = () => async ( dispatch, getState ) => {

    let data_type_profile_copy  = [].concat(getState().profileTypes.rex_data_tipo_perfil_copia)
    let todos_pem = true

    for (const pro of data_type_profile_copy) {
        for (const mod of pro.modulos) {
            if(mod.todos_permisos == false && pro.tiene_permiso == true){
                todos_pem = false
                break
            }   
        }
    }

    dispatch({
        type: SELECCIONAR_TODOS_MODULOS,
        payload : todos_pem
    })
}

export const SeleccionarTipoPerfilReducer = ( value ) => async ( dispatch, getState ) => {

    let data_type_profiles  = getState().profileTypes.rex_data_tipos_perfiles
    let data_user_create    = getState().users.rex_data_create_users

    let profile_type        = data_type_profiles.find( pro => pro.value == value)

    data_user_create['tpuid']           = profile_type.value
    data_user_create['usutipoperfil']   = profile_type.label
    
    dispatch({
        type: SELECCIONAR_TIPO_PERFIL,
        payload : profile_type
    })
    
    dispatch({
        type: DATA_CREATE_USER,
        payload : data_user_create
    })
}

export const SelectedProfileTypeReducer = (value) => async ( dispatch, getState ) => {
    const { rex_data_tipos_perfiles } = getState().profileTypes
    const find_profile_type = rex_data_tipos_perfiles.find(tpe => tpe.value == value)

    dispatch({
        type: SELECCIONAR_TIPO_PERFIL,
        payload : {
            value: find_profile_type.value,
            tpuid_padre: find_profile_type.tpuid_padre
        }
    })

    dispatch(SelectedPersonalizeProfileTypeReducer(find_profile_type.tpuid_padre ? true : false))
}

export const SelectedPersonalizeProfileTypeReducer = (value) => async ( dispatch ) => {
    dispatch({
        type: PERSONALIZAR_TIPO_PERFIL,
        payload : value
    })
}

export const ClearSelectedPersonalizeTypeProfileReducer = () => async ( dispatch ) => {
    dispatch({
        type: SELECCIONAR_TIPO_PERFIL,
        payload : null
    })
    dispatch({
        type: PERSONALIZAR_TIPO_PERFIL,
        payload : false
    })
    dispatch({
        type: PERMISSIONS_COUNTRY,
        payload : []
    })
    dispatch({
        type: SELECTED_COUNTRY_PROFILE_TYPE,
        payload : false
    })
    dispatch({
        type    : CHECK_ALL_MODULE_COUNTRY,
        payload : []
    })
    dispatch({
        type    : NAME_TYPE_PROFILE,
        payload : ''
    })
    dispatch({
        type    : SELECTED_PROFILE_TYPE_UNIQUE,
        payload : {}
    })
}

export const PersonalizarTipoPerfilReducer = ( value ) => async ( dispatch, getState ) => {

    let data_usu                    = getState().users.rex_data_create_users
    let profile_selected            = getState().profileTypes.rex_tipo_perfil_seleccionado
    
    dispatch({
        type    : PERSONALIZAR_TIPO_PERFIL,
        payload : value
    })

    dispatch({
        type    : MODULO_PAIS_SELECCIONADO,
        payload : null
    })
    
    if(data_usu.usuid != undefined){
        dispatch(ObtenerTipoPerfilReducer(profile_selected.value))
    }

    if(!value){
        let data_type_profile = structuredClone(getState().profileTypes.rex_data_tipo_perfil)
        dispatch({
            type    : OBTENER_TIPO_PERFIL_COPIA,
            payload : data_type_profile
        })

        dispatch({
            type    : SELECCIONAR_TODOS_MODULOS,
            payload : false
        })
        
    }else{
        
        let data_type_profile_copy = structuredClone(getState().profileTypes.rex_data_tipo_perfil_copia)

        let pai_selected            = data_type_profile_copy.filter(pai => pai.tiene_permiso).map( mod => mod.value)
        let total_permisos_checked  = data_type_profile_copy.filter(pai => pai_selected.includes(pai.value)).map(pai => pai.modulos.filter(mod => mod.todos_permisos).length).reduce((acc, act) => acc+act,0)
        let total_permisos          = data_type_profile_copy.filter(pai => pai_selected.includes(pai.value)).map(pai => pai.modulos.filter(mod => mod.todos_permisos || !mod.todos_permisos ).length).reduce((acc, act) => acc+act,0)

        dispatch({
            type    : SELECCIONAR_TODOS_MODULOS,
            payload : total_permisos_checked == total_permisos ? true : false
        })
        
    }
}

export const SeleccionarTodosModulosReducer = ( value ) => async ( dispatch, getState ) => {
    
    let pai_mod_selected        = getState().profileTypes.rex_modulo_pais_seleccionado    
    let data_type_profile_copy  = getState().profileTypes.rex_data_tipo_perfil_copia

    data_type_profile_copy.map((mod, index_mod) => {
    
        if(mod.value == pai_mod_selected){
            mod.todos_permisos = value
        }
        
        let pem_pai = false
        
        mod.modulos.map( mo => {
            if(pai_mod_selected == null){
                mo.todos_permisos   = value
                mo.tiene_permiso    = true
            }else{
                if(mod.value == pai_mod_selected){
                    pem_pai             = true
                    mo.todos_permisos   = value
                    mo.tiene_permiso    = true
                }
            }
            if(mo.children){
                mo.children.map( ch => {
                    if(pai_mod_selected == null){
                        ch.tiene_permiso = value
                    }else{
                        if(pem_pai){
                            ch.tiene_permiso = value
                        }
                    }
                })
            }
        })
    })

    dispatch({
        type    : OBTENER_TIPO_PERFIL_COPIA,
        payload : data_type_profile_copy
    })

    dispatch({
        type    : SELECCIONAR_TODOS_MODULOS,
        payload : value
    })
}

export const RegisterUsersConnectedProfileTypesReducer = ( data ) => async (dispatch, getState) => {
    dispatch({
        type: REGISTER_USERS_CONNECTED_MODULES_PROFILE_TYPES,
        payload: data
    })
}

export const ClearDataProfileTypeReducer = () => async ( dispatch, getState ) => {
    dispatch({
        type    : GET_UNIQUE_TYPE_PROFILE,
        payload : {}
    })
    dispatch({
        type    : PERMISSIONS_COUNTRY,
        payload : []
    })
    dispatch({
        type    : NAME_TYPE_PROFILE,
        payload : ''
    })
    dispatch({
        type    : GET_DATA_MODULES_PROFILE_CLONE,
        payload : []
    })
    dispatch({
        type    : CHECK_ALL_MODULE_COUNTRY,
        payload : []
    })
    dispatch({
        type: SELECTED_COUNTRY_PROFILE_TYPE,
        payload : false
    })
}

export const SaveProfileTypeUserReducer = () => async ( dispatch, getState ) => {
    const { rex_tipo_perfil_seleccionado, rex_name_type_profile, rex_permissions_country } = getState().profileTypes
    const { rex_data_users_form } = getState().users
    let respuesta = false

    if(rex_tipo_perfil_seleccionado){
        dispatch({
            type    : SELECTED_PROFILE_TYPE_UNIQUE,
            payload : {
                id_profile: rex_tipo_perfil_seleccionado.value,
                name_profile: rex_name_type_profile,
                personalize_profile: rex_tipo_perfil_seleccionado.tpuid_padre ? true : false,
            }
        })

        rex_data_users_form['accessCountry'] = rex_permissions_country

        dispatch({
            type    : DATA_USERS_FORM,
            payload : rex_data_users_form
        })

        respuesta = true
    }else{
        notificacionServidor('warning', 'Se debe asignar un tipo de perfil')
    }

    return respuesta
}

export const DownloadDataProfileTypesReducer = () => async ( dispatch, getState ) => {

    let url = false

    await fetch(config.api+'profile-types/download-data',
        {
            mode:'cors',
            method: 'POST',
            headers: {
                'Accept' 	   : 'application/json',
                'Content-type' : 'application/json',
                'usutoken'      : localStorage.getItem('usutoken')
            },
        }
    )
    .then( async res => {
        return res.json()
    })
    .then(data => {
        url = data.data
    }).catch((error)=> {
        console.log(error)
    });

    return url
}