import config from '../../../config'
import {
    GET_DATA_MODULES_HOME,
    REGISTER_USERS_CONNECTED_MODULES_HOME,
    MENU_HOME_CATEGORY,
} from '../../../Constants/Home/Home'
import {
    OBTENER_DATA_MODULOS_DASHBOARDS_TOTAL,
    OBTENER_DATA_MODULOS_DASHBOARDS_FIRST,
    OBTENER_DATA_MODULOS_DASHBOARDS_SECOND,
} from "../../../Constants/Dashboards/Dashboards"
import notificationGlobal from '../../../Components/Global/NotificationGlobal'
import { 
    SelectModuleActiveClickHomeModulesReducer, 
    SelectModuleActiveHoverHomeModulesReducer, 
    SelectModuleSelectedHomeModulesReducer 
} from '../HomeModules/HomeModules'

export const GetDataModulesHomeReducer = (resetIdMod=false) => async ( dispatch, getState ) => {
    
    let idModuleSelected              = getState().homeModules.rex_module_selected_home_modules
    let respuesta = false
    await fetch(config.api+'modules/get-user-modules',
        {
            mode:'cors',
            method: 'POST',
            headers: {
                'Accept' 	     : 'application/json',
                'Content-type'   : 'application/json',
                'usutoken'	     : localStorage.getItem('usutoken'),
                'middle_usuario' : localStorage.getItem('usutoken'),
            },
            body: JSON.stringify({
                req_usupais : localStorage.getItem('usupais')
            }),
        }
    )
    .then( async res => {
        return res.json()
    })
    .then(async data => {
        if(data.respuesta){

            if(data.data.length > 0){
                let totalModules = data.data
                if((totalModules.length && !idModuleSelected) || resetIdMod){
                    const idModuleSelected = totalModules[0]['modid']
                    dispatch(SelectModuleSelectedHomeModulesReducer(idModuleSelected))
                    dispatch(SelectModuleActiveClickHomeModulesReducer(idModuleSelected))
                    dispatch(SelectModuleActiveHoverHomeModulesReducer(idModuleSelected))
                }
                totalModules.map((tm, index) => totalModules[index]["key"] = index)
                const modulesFirst = totalModules.filter(tm => {
                    if(tm.key < 10){
                        return tm
                    }else{
                        return undefined
                    }
                })
                const modulesSecond = totalModules.filter(tm => {
                    if(tm.key > 9){
                        return tm
                    }else{
                        return undefined
                    }
                })
                        
                dispatch({
                    type: OBTENER_DATA_MODULOS_DASHBOARDS_TOTAL,
                    payload: totalModules
                })
                dispatch({
                    type: OBTENER_DATA_MODULOS_DASHBOARDS_FIRST,
                    payload: modulesFirst
                })
                dispatch({
                    type: OBTENER_DATA_MODULOS_DASHBOARDS_SECOND,
                    payload: modulesSecond
                })
            }
            
        }else{
            notificationGlobal('error', data.message)
        }
    }).catch((error)=> {
        notificationGlobal('warning', 'Ha ocurrido un error en el servidor')
        console.log(error)
    });

    return respuesta
}


export const RegisterUsersConnectedHomeReducer = ( data ) => async (dispatch, getState) => {

    dispatch({
        type: REGISTER_USERS_CONNECTED_MODULES_HOME,
        payload: data
    })
}

export const MenuChangeHomeCategoryReducer = (data) => async (dispatch, getState) => {
    dispatch({
        type: MENU_HOME_CATEGORY,
        payload: data
    })
}