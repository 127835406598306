import React, { useEffect, useState } from 'react'
import { Row, Col, Switch, Modal, Typography, Table, Pagination, Input } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import ButtonGlobal from '../../Components/Global/ButtonGlobal'
import { useIdleTimer } from 'react-idle-timer'
import StarPersonalized from '../../Assets/Images/ProfileTypes/StarPersonalized'
import {
    EditStatusProfileTypeReducer,
    GetProfileTypes, 
    GetTypeProfileSelectedReducer, 
    ShowModalListUsersProfileReducer,
    GetListUsersProfileReducer, 
    RegisterUsersConnectedProfileTypesReducer,
    DeleteProfileTypeReducer,
    ClearDataProfileTypeReducer,
    FilterProfileTypesReducer,
    DownloadDataProfileTypesReducer,
    EditTypeProfileReducer,
} from '../../Redux/Actions/ProfileTypes/ProfileTypes'
import moment from "moment"
import { ExclamationCircleFilled } from '@ant-design/icons'
import ListUsersProfileType from '../../Components/ProfileTypes/ListUsersProfileType'
import socket from '../../App/socket'
import { ValidationUserReducer } from '../../Redux/Actions/Validations/Validations'
import SideMenu from '../../Components/Global/SideMenu'
import NavigationGlobal from '../../Components/Global/NavigationGlobal'
import SimpleTableGlobal from '../../Components/Global/SimpleTableGlobal'
import ProfileTypesFilterSelect from '../../Components/ProfileTypes/ProfileTypesFilterSelect'
import TrashButtonGlobal from '../../Assets/Images/Global/TrashButtonGlobal'
import DownloadButtonGlobal from '../../Assets/Images/Global/DownloadButtonGlobal'
import FilterSelectGlobal from '../../Components/Global/FilterSelectGlobal'
import { 
    FilterOutlined,
} from '@ant-design/icons'

const ProfileTypes = () => {

    const timeout = 300000
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { confirm } = Modal
    const { Title } = Typography

    const [pageTableGlobal, setPageTableGlobal ] = useState(1)
    const [ paiFilter, setPaiFilter ] = useState([])
    const [pageSizeTableGlobal, setPageSizeTableGlobal ] = useState(20)
    const [ mouseOverRow ,setMouseOverRow] = useState(null)
    const [ keyRowAction, setKeyRowAction ] = useState(null)
    const [ selectedRowKeys, setSelectedRowKeys ] = useState([])
    const [ showModal, setShowModal ] = useState(false)
    const [ infoModal, setInfoModal ] = useState({})

    const { 
        rex_data_user_logingrow
    } = useSelector(({loginGrow}) => loginGrow)

    const { 
        rex_data_paises,
        rex_data_filtro_paises
    } = useSelector(({paises}) => paises);

    const ValidateUser = async () => {
        const validate_user = await dispatch(ValidationUserReducer())
        if(!validate_user){
            navigate('/login')
        }
    }

    useEffect(() => {
        ValidateUser()
        socket.emit('reg-modules', { module: 'profile-types'}, {usuusuario: rex_data_user_logingrow.usuusuario}) 
    }, [])

    useEffect(() => {
        socket.on('get-users-modules', (data_users) => {
            dispatch(RegisterUsersConnectedProfileTypesReducer(data_users))
        })

        return () => {
            socket.emit('unreg-modules', { module: 'profile-types'}, {usuusuario: rex_data_user_logingrow.usuusuario} )
            socket.off('get-users-modules')
        }
    },[])

    const onIdle = () => {
        socket.emit('reg-status-modules', { module: 'profile-types'}, {usuusuario: rex_data_user_logingrow.usuusuario}, false )
    }
    const onActive = () => {
        socket.emit('reg-status-modules', { module: 'profile-types'}, {usuusuario: rex_data_user_logingrow.usuusuario}, true )
    }

    useIdleTimer({
        onIdle,
        onActive,
        timeout,
        throttle: 500
    })

    const { 
        rex_data_users_online
    } = useSelector(({users}) => users)

    const { 
        rex_profile_types_clone,
        rex_users_profile_types_connected,
        rex_loading_profile_type
    } = useSelector(({profileTypes}) => profileTypes)

    const actionOnRow = ( data ) => {
        dispatch(GetTypeProfileSelectedReducer(data))
        navigate(`/administrator/edit-profile/${data.tpuid}`)
    }

    const filtersProfileTypes = (listSelected, type) => {
        if(type == 'país'){
            dispatch(FilterProfileTypesReducer(listSelected))
        }else if(type == 'perfiles'){
            // dispatch(FilterUsersReducer(paiFilter, listSelected, txtFilter))
        }
    }

    const showListUsersProfile = (data) => {
        dispatch(GetListUsersProfileReducer(data.tpuid))
        dispatch(ShowModalListUsersProfileReducer(true))
    }

    const editStatusProfileType = ( tpuid, value ) => {
        confirm({
            title   : `Confirmación`,
            icon    : null,
            className :"Modal-Confirm-Data-Control Modal-Semi-large",
            style: {width : "300px !important" },
            content : `¿Está seguro de ${value ? 'activar':'desactivar'} el perfil?`,
            zIndex  : 100000000,
            async onOk(){
                let response = await dispatch(EditStatusProfileTypeReducer(tpuid, value))
                if(response){
                    setShowModal(true)
                    setInfoModal({
                        ...infoModal, 
                        title : "¡Listo!", 
                        description : `El perfil fue editado con éxito`,
                        status : true,
                        small : true
                    })
                }
            },
            okCancel(){},
            okText : "Continuar",
            okButtonProps: {
                size:"small",
            },
            cancelText: "Cancelar",
            cancelButtonProps : {
                size:"small",
                danger:true
            },
        })
    }

    const statusModal = () => {
        setShowModal(false)
    }

    const getColumnSearchProps = (dataIndex, nameColumn) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
            <div
                style={{
                    padding: 8,
                }}
                onKeyDown={(e) => e.stopPropagation()}
            >
                <Input.Search
                    placeholder={`Filtrar ${nameColumn}`}
                    value={selectedKeys[0]}
                    onChange={(e) => {
                        setSelectedKeys(e.target.value ? [e.target.value] : [])
                        confirm({
                            closeDropdown: false,
                        })
                    }}
                    style={{
                        display: 'block',
                    }}
                />
            </div>
        ),
        filterIcon: () => (<FilterOutlined/>),
        onFilter: (value, record) => {
            return record[dataIndex] != null ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : false
        }
    })

    const deleteProfileType = ( id, resetValues ) => {

        let containsUsers = rex_profile_types_clone.filter(tpu => id.includes(tpu.key) && (tpu.ctd_usu > 0))

        if(id.length > 0 && id.length != containsUsers.length){
            confirm({
                title   : <span>Confirmar</span>,
                icon    : null,
                className :"Modal-Confirm-Data-Control",
                content : `¿Estás seguro de eliminar ${id.length > 1 ? 'los perfiles': 'el perfil'}? Esta acción es irreversible`,
                zIndex  : 100000000,
                async onOk(){
                    let response = await dispatch(DeleteProfileTypeReducer(id))
                    if(response){
                        resetValues([])
                        setShowModal(true)
                        setInfoModal({
                            ...infoModal, 
                            title : "¡Listo!", 
                            description : `${id.length > 1 ? 'Los perfiles fueron eliminados': 'El Perfil fue eliminado'} con éxito`,
                            status : true,
                            small : true
                        })
                    }
                },
                okCancel(){}
            })
        }
    }

    const columns = [
        {
            title: 'Item',
            dataIndex: 'item',
            align: 'center',
            fixed:'left',
            ellipsis : true,
            render: (text, record, index) => (pageTableGlobal - 1) * pageSizeTableGlobal + index + 1,
            width: '50px'
        },
        Table.SELECTION_COLUMN,
        {
            title: 'Tipo de Perfil',
            align: "center",
            dataIndex: 'perfil_type',
            ...getColumnSearchProps('tpunombre', 'tipo de perfil'),
            sorter: (a, b) => a.tpunombre ? a.tpunombre.localeCompare(b.tpunombre) : false,
            showSorterTooltip: false,
            ellipsis : true,
            width : '200px',
            render:(_,record) => (
                <div title={record.tpunombre} className='Table-Contain-Text-Global Table-Contain-Text-Center-Align'>
                    {record.personalize && <StarPersonalized onHover={true}/>}
                    <span style={{display:'flex',alignItems:'center'}}>&nbsp;&nbsp;{record.tpunombre}</span>
                </div>
            ),
        },
        {
            title: 'Nivel país',
            dataIndex: 'level_country',
            sorter: (a, b) => a.paises ? a.paises.painombre.localeCompare(b.paises.painombre) : false,
            showSorterTooltip: false,
            ellipsis : true,
            width : '130px',
            render:(_,record) => (
                <div className='Simple-Table-Contain-Text-Global Text-Left'>
                    {record.paises.painombre}
                </div>
            ),
            align : 'center',
            filters: rex_data_filtro_paises,
            onFilter: (value, record) => {
                return record.paises.paiid.includes(value)
            },
        },
        {
            title: 'Fecha y hora de creación',
            dataIndex: 'created_at',
            align: 'center',
            ellipsis : true,
            width : '180px',
            render:(_, record) => (
                <div className='Simple-Table-Contain-Text-Global'>
                    {record.created_at ? moment(record.created_at).format("DD MMM YYYY h:mm a") : ""}
                </div>
            ),
        },
        {
            title: 'Estado',
            dataIndex: 'status',
            align: 'center',
            sorter: (a, b) => a.estid? a.estid - b.estid : false,
            showSorterTooltip : false,
            ellipsis : true,
            width : '120px',
            render:(_, record) => (
                <div title={record.estid ==  1 ? 'activo' : 'inactivo'}>
                    <div style={{display:'flex', justifyContent:"space-between"}}>
                        <span
                            className='Table-Name-Status-Global Table-Contain-Text-Global'
                        >
                            {record.estid ==  1 ? 'activo' : 'inactivo'}
                        </span>
                        <Switch
                            className='Switch-Status-Users'
                            onChange={( value, event ) => {
                                event.stopPropagation()
                                editStatusProfileType( record.tpuid, value )

                            }}
                            size='small'
                            checked={record.estid == 1 ? true : false}
                        />
                    </div>
                </div>
            ),
            filters: [
                {
                  text: 'activo',
                  value: 1,
                },
                {
                  text: 'inactivo',
                  value: 2,
                },
            ],
            className:"class",
            onFilter: (value, record) => record.estid == value,
        },
        {
            title: 'Total de usuarios',
            dataIndex: 'total_users',
            align: 'center',
            width : '150px',
            ...getColumnSearchProps('ctd_usu', 'cantidad'),
            sorter: (a, b) => a.ctd_usu? a.ctd_usu - b.ctd_usu : false,
            showSorterTooltip : false,
            ellipsis : true,
            render: (_, record) => (
                <div 
                    onClick={( event ) => {
                        event.stopPropagation()
                        showListUsersProfile(record)
                    } }
                    className='Simple-Table-Contain-Text-Global'
                >
                    {`${record.ctd_usu} ${record.ctd_usu != 1 ? 'usuarios': 'usuario'}`}
                </div>
            ),
        },
        {
            title: 'Fecha y hora de actualización',
            dataIndex: 'updated_at',
            align: 'center',
            ellipsis : true,
            width : '200px',
            render: (_, record) => (
                <div className='Simple-Table-Contain-Text-Global'>
                    {record.updated_at 
                        ? moment(record.updated_at).format("DD MMM YYYY h:mm a") 
                        : moment(record.created_at).format("DD MMM YYYY h:mm a")
                    }
                </div>
            ),
        },
        {
            title: 'Usuario creación',
            dataIndex: 'usucreated',
            align: 'center',
            width : '160px',
            ...getColumnSearchProps('usucreated', 'usuario que creó'),
            sorter: (a, b) => a.usucreated ? a.usucreated.localeCompare(b.usucreated) : false,
            showSorterTooltip: false,
            ellipsis : true,
            render: (_, record) => (
                <div className='Simple-Table-Contain-Text-Global Text-Left'>
                    {record.usucreated}
                </div>
            ),
        },
        {
            title: 'Usuario actualización',
            dataIndex: 'usuedited',
            align: 'center',
            width : '180px',
            ...getColumnSearchProps('usuedited', 'usuario que actualizó'),
            sorter: (a, b) => a.usuedited ? a.usuedited.localeCompare(b.usuedited) : false,
            showSorterTooltip: false,
            ellipsis : true,
            render: (_, record) => (
                <div className='Simple-Table-Contain-Text-Global Text-Left'>
                    {record.usuedited ? record.usuedited : record.usucreated}
                </div>
            ),
        },
    ]

    const toggleSelectAll = (valueCheckbox) => {
        setSelectedRowKeys((keys) =>
        valueCheckbox
            ? rex_profile_types_clone?.length > 0 ? rex_profile_types_clone.map((r) => r.key) : rex_profile_types_clone.map((r) => r.key) 
            : []
        );
    };

    const onSelectChange = (newSelectedRowKeys) => {
        setSelectedRowKeys(newSelectedRowKeys);
    };

    const rowSelection = {
        columnTitle: true ? <ProfileTypesFilterSelect
                                    showDropDown={true}
                                    toggleSelectAll={toggleSelectAll}
                                    selectedRowKeys={null}
                                    dataAmount={rex_profile_types_clone.length}
                                    setSelectedRowKeys={setSelectedRowKeys}
                                    data={rex_profile_types_clone}
                                    valueCheckbox={selectedRowKeys.length == rex_profile_types_clone.length}
                                    setValueCheckbox={() => console.log("ok")}
                                    actInacChechBox={false}
                                    themeColor='green'
        /> : <></>,
        selectedRowKeys,
        onChange: onSelectChange,
        type: "checkbox",
        fixed: true,
        onSelect: () => console.log("oko"),
    };

    useEffect(() => {
        dispatch(GetProfileTypes())
        dispatch(ClearDataProfileTypeReducer())
        dispatch(EditTypeProfileReducer(true))
    }, [])

    const menuProfileTypes = [
        {
            url: '/home-administrator',
            nombre: 'Administrador'
        },
        {
            url: '/administrator/profile-types',
            nombre: 'Tipos de Perfiles'
        },
    ]

    return (
        <>
            <div className='Container-Main Container-Type-Profile'>

                <Row style={{position: 'relative'}}>
                    <Col span={24} style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                        <Title className="Title-Type-Profile">
                            Tipos de perfiles
                        </Title>
                    </Col>
                    <Col span={24} style={{position: 'absolute', top: '6px'}}>
                        <NavigationGlobal menuItems={menuProfileTypes}/>
                    </Col>
                </Row>

                <Row className='Users-Container-Actions'>
                    <Col span={12} style={{display: 'flex', columnGap: '10px', alignItems: "center"}}>
                        <FilterSelectGlobal
                            data={rex_data_paises}
                            filterBy='país'
                            setTypFilter={setPaiFilter}
                            funtionFilter={(valueSelected, type) => filtersProfileTypes(valueSelected, type)}
                        />
                        <TrashButtonGlobal
                            color='#013A81'
                            optionHover={true}
                            functionDelete={() => deleteProfileType(selectedRowKeys, setSelectedRowKeys)}
                        />
                        <div style={{marginLeft:"-10px", zIndex:"1"}}>
                            <DownloadButtonGlobal 
                                color='#013A81'
                                functionDownload={ async () => {
                                    const link = document.createElement('a')
                                    let url = await dispatch(DownloadDataProfileTypesReducer())
                                    link.href = url
                                    link.click()
                                }}
                                downloadFile={true}
                            />
                        </div>
                    </Col>
                    <Col span={12} style={{display: "flex", justifyContent: "end", columnGap:"15px"}}>
                        <ButtonGlobal 
                            nameRoute="/administrator/create-profile"
                            txtButton="Nuevo"
                        />
                        <Pagination
                            total={rex_profile_types_clone.length}
                            showTotal={(total, range) => `${range[0]}-${range[1]} de ${rex_profile_types_clone.length}`}
                            defaultPageSize={pageSizeTableGlobal}
                            defaultCurrent={pageTableGlobal}
                            current={pageTableGlobal}
                            className='Simple-Table-Pagination-Global'
                            onChange={(page, pageSize) => {
                                setPageTableGlobal(page == 0? 1 : page)
                                setPageSizeTableGlobal(pageSize)
                            }}
                        />
                    </Col>
                </Row>
                <SimpleTableGlobal
                    data={rex_profile_types_clone}
                    keyRowAction={keyRowAction}
                    rowSelection={rowSelection}
                    columns={columns}
                    pageTableGlobal={pageTableGlobal}
                    pageSizeTableGlobal={pageSizeTableGlobal}
                    actionEnterRow={setMouseOverRow}
                    actionLeaveRow={() => console.log("ok")}
                    actionOnRow={actionOnRow}
                    loadingData={rex_loading_profile_type}
                />
                <Modal
                    open={showModal}
                    title={<span className={`${infoModal.status ? "Text-Ok" : "Text-Alert"}`}>{infoModal.title}</span>}
                    width={300}
                    zIndex={100000000}
                    className={`Modal-Create-Data-Control ${infoModal.small && 'Modal-Small'}`}
                    footer={[
                        <div style={{display:"flex", justifyContent:"center"}}>
                            <div className={`Button-Close Button${infoModal.status ? "-Ok" : "-Alert"}`} onClick={() => statusModal()}>
                                Ok
                            </div>
                        </div>
                    ]}
                >
                    <div className='Modal-Description'>
                        {infoModal.description}
                    </div>
                </Modal>
                <ListUsersProfileType/>
                
            </div>
            {
                rex_users_profile_types_connected &&
                    <SideMenu
                        usersCurrentView  = {rex_users_profile_types_connected}
                        usersAllConnected = {rex_data_users_online}
                    />
            }
        </>
    )
}

export default ProfileTypes