import React, { useState } from 'react'

function OptionButton({fill, stroke, size}){
    
    return (
        <svg width={size} height={size} viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_177_2297)">
                <path d="M13.4173 2.625C13.4173 2.14175 13.0256 1.75 12.5423 1.75H10.3397C9.97107 0.704293 8.98442 0.00355469 7.87566 0H6.12566C5.0169 0.00355469 4.03025 0.704293 3.66166 1.75H1.45898C0.975738 1.75 0.583984 2.14175 0.583984 2.625C0.583984 3.10825 0.975738 3.5 1.45898 3.5H1.75066V10.7917C1.75066 12.5636 3.18708 14 4.95898 14H9.04231C10.8142 14 12.2507 12.5636 12.2507 10.7917V3.5H12.5423C13.0256 3.5 13.4173 3.10825 13.4173 2.625ZM10.5007 10.7917C10.5007 11.5971 9.84775 12.25 9.04234 12.25H4.95898C4.15357 12.25 3.50066 11.5971 3.50066 10.7917V3.5H10.5007V10.7917Z" fill={fill}/>

                <path d="M5.54297 10.5C6.02621 10.5 6.41797 10.1082 6.41797 9.625V6.125C6.41797 5.64175 6.02621 5.25 5.54297 5.25C5.05972 5.25 4.66797 5.64175 4.66797 6.125V9.625C4.66797 10.1082 5.05972 10.5 5.54297 10.5Z" fill={fill}/>
                <path d="M8.45898 10.5C8.94223 10.5 9.33398 10.1082 9.33398 9.625V6.125C9.33398 5.64175 8.94223 5.25 8.45898 5.25C7.97574 5.25 7.58398 5.64175 7.58398 6.125V9.625C7.58398 10.1082 7.97574 10.5 8.45898 10.5Z" fill={fill}/>
            </g>
            <defs>
                <clipPath id="clip0_177_2297">
                    <rect width="14" height="14" fill="white"/>
                </clipPath>
            </defs>
        </svg>
    )
}

const TrashOptionButtonGlobal = ({mouseOver, size=14}) => {

    const [ mouseOverDelete , setMouseOverDelete ] = useState(false)

    return (
        <div 
            onMouseEnter={() => {
                setMouseOverDelete(true)
            }}
            onMouseLeave={() => {
                setMouseOverDelete(false)
            }}
            style={{cursor:"pointer", display:'flex', justifyContent :'center',alignItems: 'center'}}
        >
            <OptionButton size={size} fill={mouseOverDelete ? '#3B72FF' : '#013A81'}/>
        </div>

    )
}

export default TrashOptionButtonGlobal