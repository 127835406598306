import React, { useState } from 'react'
import { Modal, Typography, Input, DatePicker } from 'antd'
import TableGlobal from '../Global/TableGlobal'
import {
    FilterOutlined,
    CalendarOutlined
} from '@ant-design/icons'
import moment from "moment";
import { useSelector } from 'react-redux';
import dayjs from "dayjs"

const ModalDetailArea = ({showModalDetailArea, setShowModalDetailArea, nameArea, nameAreaBD, dataModal, setDataModal}) => {

    const { Title } = Typography

    const {
        rex_data_modal_status,
        rex_loading_data_status
    } = useSelector(({status}) => status);

    const [pageTableGlobal, setPageTableGlobal ] = useState(1)
    const [pageSizeTableGlobal, setPageSizeTableGlobal ] = useState(18)
    const [ selectedRowKeys, setSelectedRowKeys ] = useState([])
    const [ valueCheckbox, setValueCheckbox ]     = useState(false)

    const calculateDayLate = (deadline, dateUpdate) => {

        let infoDayLate = {
            daysLate    : null,
            late        : ""
        }

        const fecha1 = moment(deadline);
        const fecha2 = dateUpdate ? moment(dateUpdate) : moment(new Date());
        const diffDays = fecha2.diff(fecha1, 'days');

        if(diffDays > 0){
            infoDayLate = {
                ...infoDayLate,
                late        : true,
                daysLate    : diffDays
            }
        }else{
            infoDayLate = {
                ...infoDayLate,
                late        : false,
                daysLate    : 0
            }
        }

        return infoDayLate
    }

    const getColumnSearchProps = (dataIndex, nameColumn, tableExt = null) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
            <div
                style={{
                    padding: 8,
                }}
                onKeyDown={(e) => e.stopPropagation()}
            >
                {
                    tableExt == "valueDate"
                    ? <DatePicker
                        placeholder={`Filtrar ${nameColumn}`}
                        popupClassName="DatePicker-Modal-Status"
                        format="YYYY-MM-DD"
                        onChange={ async (date, dateString) => {
                            setSelectedKeys(dateString ? [dateString] : [])
                            confirm({
                                closeDropdown: false,
                            })
                        }}
                        value={selectedKeys[0] ? dayjs(dayjs(selectedKeys[0]), "YYYY-MM-DD") : dayjs(dayjs(), "YYYY-MM-DD")}
                        />
                    : <Input.Search
                        placeholder={`Filtrar ${nameColumn}`}
                        value={selectedKeys[0]}
                        onChange={(e) => {
                            setSelectedKeys(e.target.value ? [e.target.value] : [])
                            confirm({
                                closeDropdown: false,
                            })
                        }}
                        style={{
                            display: 'block',
                        }}
                    />
                }
            </div>
        ),
        filterIcon: () => tableExt == "valueDate" ? <CalendarOutlined /> : <FilterOutlined />,
        onFilter: (value, record) => {
            if(tableExt == "clients"){
                return record['clientespe'][dataIndex] != null ? record['clientespe'][dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : false
            }else if(tableExt == "perpersonas"){
                return record['perpersonas'][dataIndex] != null ? record['perpersonas'][dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : false
            }else if(tableExt == "valueDate"){
                return record[dataIndex] != null ? new Date(record[dataIndex]).toISOString().split('T')[0].includes(moment(value).utc().format("YYYY-MM-DD")) : false
            }else{
                return record[dataIndex] != null ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : false
            }
        },
    })

    const columnsHML = [
        {
            title: <span title="N°" className="Title-Table-Modal-Status">N°</span>,
            dataIndex: 'key',
            align: 'center',
            showSorterTooltip: false,
            render: (text, record, index) => (pageTableGlobal - 1) * pageSizeTableGlobal + index + 1,
            ellipsis : true,
            width :40
        },
        {
            title: <span title="Canal" className="Title-Table-Modal-Status">Canal</span>,
            dataIndex: 'regional_gba',
            align: 'left',
            showSorterTooltip: false,
            ...getColumnSearchProps('regional_gba', 'canal', 'clients'),
            sorter: (a, b) => a.clientespe?.regional_gba ? a.clientespe.regional_gba.localeCompare(b.clientespe.regional_gba) : false,
            render: (text, record, index) => record.clientespe ? record.clientespe.regional_gba : "",
            ellipsis : true,
            width : 75
        },
        {
            title: <span title="Sub canal" className="Title-Table-Modal-Status">Sub canal</span>,
            dataIndex: 'channel',
            align: 'left',
            showSorterTooltip: false,
            ...getColumnSearchProps('channel', 'subcanal', 'clients'),
            sorter: (a, b) => a.clientespe?.channel ? a.clientespe.channel.localeCompare(b.clientespe.channel) : false,
            render: (text, record, index) => record.clientespe ? record.clientespe.channel : "",
            ellipsis : true,
            width : 100
        },
        {
            title: <span title="Zona" className="Title-Table-Modal-Status">Zona</span>,
            dataIndex: 'zone',
            align: 'left',
            ellipsis : true,
            showSorterTooltip: false,
            ...getColumnSearchProps('zone', 'zona', 'clients'),
            sorter: (a, b) => a.clientespe?.zone ? a.clientespe.zone.localeCompare(b.clientespe.zone) : false,
            render: (text, record, index) => record.clientespe ? record.clientespe.zone : "",
            width : 85
            // width : 55
        },
        {
            title: <span title="Territorio" className="Title-Table-Modal-Status">Territorio</span>,
            dataIndex: 'locality',
            align: 'left',
            showSorterTooltip: false,
            ...getColumnSearchProps('zone', 'territorio', 'clients'),
            sorter: (a, b) => a.clientespe?.zone ? a.clientespe.zone.localeCompare(b.clientespe.zone) : false,
            render: (text, record, index) => record.clientespe ? record.clientespe.zone : "",
            ellipsis : true,
            width : 100
        },
        {
            title: <span title="Cliente" className="Title-Table-Modal-Status">Cliente</span>,
            dataIndex: 'client_hml',
            align: 'left',
            showSorterTooltip: false,
            ...getColumnSearchProps('client_hml', 'cliente', 'clients'),
            sorter: (a, b) => a.clientespe?.client_hml ? a.clientespe.client_hml.localeCompare(b.clientespe.client_hml) : false,
            render: (text, record, index) => record.clientespe ? record.clientespe.client_hml : "",
            ellipsis : true,
            width : 85
        },
        {
            title: <span title="Sucursal" className="Title-Table-Modal-Status">Sucursal</span>,
            dataIndex: 'client_sub_hml',
            align: 'left',
            ellipsis : true,
            showSorterTooltip: false,
            ...getColumnSearchProps('client_sub_hml', 'sucursal', 'clients'),
            sorter: (a, b) => a.clientespe?.client_sub_hml ? a.clientespe.client_sub_hml.localeCompare(b.clientespe.client_sub_hml) : false,
            render: (text, record, index) => record.clientespe ? record.clientespe.client_sub_hml : "",
            width : 95
        },
        {
            title: <span title="Gerente de Canal" className="Title-Table-Modal-Status">Gerente de Canal</span>,
            dataIndex: 'regional_management',
            align: 'left',
            showSorterTooltip: false,
            ...getColumnSearchProps('regional_management', 'gerente de canal', 'clients'),
            sorter: (a, b) => a.clientespe?.regional_management ? a.clientespe.regional_management.localeCompare(b.clientespe.regional_management) : false,
            render: (text, record, index) => record.clientespe ? record.clientespe.regional_management : "",
            ellipsis : true,
            width : 125
        },
        {
            title: <span title="Owner" className="Title-Table-Modal-Status">Owner</span>,
            dataIndex: 'executor',
            align: 'left',
            showSorterTooltip: false,
            ...getColumnSearchProps('executor', 'owner', 'clients'),
            sorter: (a, b) => a.clientespe?.executor ? a.clientespe.executor.localeCompare(b.clientespe.executor) : false,
            render: (text, record, index) => record.clientespe ?  record.clientespe.executor : "",
            ellipsis : true,
            width : 110
        },
        {
            title: <span title="Usuario" className="Title-Table-Modal-Status">Usuario</span>,
            dataIndex: 'pernombrecompleto',
            align: 'left',
            showSorterTooltip: false,
            ...getColumnSearchProps('pernombrecompleto', 'usuario', 'perpersonas'),
            sorter: (a, b) => a.perpersonas?.pernombrecompleto ? a.perpersonas.pernombrecompleto.localeCompare(b.perpersonas.pernombrecompleto) : false,
            render: (text, record, index) => record.perpersonas ? record.perpersonas.pernombrecompleto : "",
            width : 115
        },
        {
            title: <span title="Deadline" className="Title-Table-Modal-Status">Deadline</span>,
            dataIndex: 'espfechaprogramado',
            align: 'center',
            showSorterTooltip: false,
            ...getColumnSearchProps('espfechaprogramado', 'deadline', 'valueDate'),
            sorter: (a, b) => a.espfechaprogramado ? new Date(a.espfechaprogramado) - new Date(b.espfechaprogramado) : false,
            render: (text, record, index) => record.espfechaprogramado ? moment(record.espfechaprogramado).utc().format('DD/MM/YY') : "",
            ellipsis : true,
            width : 95
        },
        {
            title: <span title="Deadline actualizado" className="Title-Table-Modal-Status">Deadline actualizado</span>,
            dataIndex: 'espfechactualizacion',
            align: 'center',
            showSorterTooltip: false,
            ...getColumnSearchProps('espfechactualizacion', 'deadline actualizado', 'valueDate'),
            sorter: (a, b) => a.espfechactualizacion ? new Date(a.espfechactualizacion) - new Date(b.espfechactualizacion) : false,
            render: (text, record, index) => record.espfechactualizacion ? moment(record.espfechactualizacion).utc().format('DD/MM/YY') : "",
            width : 105
        },
        {
            title: <span title="Días de Retraso" className="Title-Table-Modal-Status">Días de Retraso</span>,
            dataIndex: 'espdiaretraso',
            align: 'center',
            showSorterTooltip: false,
            ...getColumnSearchProps('textespdiaretraso', 'días de retraso', null),
            sorter: (a, b) => a.espdiaretraso? a.espdiaretraso - b.espdiaretraso : false,
            render: (text, record, index) => {
                return (
                    <div className={`Tag-Table-Description-Status ${record.espdiaretraso > 0 
                                ? "Red-Description-Status" 
                                : "Green-Description-Status"}`}>
                                    {record.espdiaretraso} {record.espdiaretraso == 1 ? "día" : "días"}
                    </div>
                )
            },
            className: 'Table-Status-Days-Late',
            width : 90
        },
        {
            title: <span title="Status" className="Title-Table-Modal-Status">Status</span>,
            dataIndex: 'status',
            align: 'center',
            showSorterTooltip: false,
            sorter: (a, b) => a.espfechactualizacion ? a.espfechactualizacion.localeCompare(b.espfechactualizacion) : false,
            render: (text, record, index) => (
                <div className={`Text-Status-Table ${record.espfechactualizacion != null? "Green-Status-Table" : "Red-Status-Table"}`}>
                    {record.espfechactualizacion != null ? "Cargado" : "Pendiente"}
                </div>
            ),
            width : 70
        },
    ]

    const columnsSI = [
        {
            title: <span className="Title-Table-Modal-Status">N°</span>,
            dataIndex: 'key',
            align: 'center',
            render: (text, record, index) => (pageTableGlobal - 1) * pageSizeTableGlobal + index + 1,
            ellipsis : true,
            width :40
        },
        {
            title: <span className="Title-Table-Modal-Status">Zona</span>,
            dataIndex: 'zona',
            align: 'center',
            render: (text, record, index) => record.zona,
            width : 55
        },
        {
            title: <span className="Title-Table-Modal-Status">Canal</span>,
            dataIndex: 'canal',
            align: 'center',
            render: (text, record, index) => record.canal,
            ellipsis : true,
            width : 65
        },
        {
            title: <span className="Title-Table-Modal-Status">Sub canal</span>,
            dataIndex: 'sub_canal',
            align: 'center',
            render: (text, record, index) => record.sub_canal,
            ellipsis : true,
            width : 90
        },
        {
            title: <span className="Title-Table-Modal-Status">Territorio</span>,
            dataIndex: 'territorio',
            align: 'center',
            render: (text, record, index) => record.territorio,
            ellipsis : true,
            width : 65
        },
        {
            title: <span className="Title-Table-Modal-Status">Cliente</span>,
            dataIndex: 'cliente',
            align: 'center',
            render: (text, record, index) => record.cliente,
            ellipsis : true,
            width : 75
        },
        {
            title: <span className="Title-Table-Modal-Status">Sucursal</span>,
            dataIndex: 'sucursal',
            align: 'center',
            render: (text, record, index) => record.sucursal,
            width : 80
        },
        {
            title: <span className="Title-Table-Modal-Status">Gerente de Canal</span>,
            dataIndex: 'gerente_canal',
            align: 'center',
            render: (text, record, index) => record.gerente_canal,
            ellipsis : true,
            width : 120
        },
        {
            title: <span className="Title-Table-Modal-Status">Owner</span>,
            dataIndex: 'responsable',
            align: 'center',
            render: (text, record, index) => record.responsable,
            ellipsis : true,
            width : 100
        },
        {
            title: <span className="Title-Table-Modal-Status">Usuario</span>,
            dataIndex: 'usuario',
            align: 'center',
            render: (text, record, index) => record.usuario,
            width : 135
        },
        {
            title: <span className="Title-Table-Modal-Status">Cuota</span>,
            dataIndex: 'cuota',
            align: 'center',
            render: (text, record, index) => record.cuota,
            width : 60
        },
        {
            title: <span className="Title-Table-Modal-Status">Deadline</span>,
            dataIndex: 'deadline',
            align: 'center',
            render: (text, record, index) => record.deadline ? moment(record.deadline).utc().format('DD/MM/YY') : "",
            ellipsis : true,
            width : 100
        },
        {
            title: <span className="Title-Table-Modal-Status">Deadline actualizado</span>,
            dataIndex: 'deadline_actualizado',
            align: 'center',
            render: (text, record, index) => record.deadline_actualizado ? moment(record.deadline_actualizado).utc().format('DD/MM/YY') : "",
            width : 95
        },
        {
            title: <span className="Title-Table-Modal-Status">Días de Retraso</span>,
            dataIndex: 'dias_retraso',
            align: 'center',
            render: (text, record, index) => {

                let { daysLate, late } = calculateDayLate(record.espfechaprogramado, record.espfechactualizacion)
                return (


    
                    <div className={`Tag-Table-Description-Status ${late 
                                ? "Red-Description-Status" 
                                : "Green-Description-Status"}`}>
                                    {daysLate} días
                    </div>
                )
            },
            className: 'Table-Status-Days-Late',
            width : 90
        },
        {
            title: <span className="Title-Table-Modal-Status">Status</span>,
            dataIndex: 'status',
            align: 'center',
            render: (text, record, index) => (
                <div className={`Text-Status-Table ${record.deadline_actualizado != null? "Green-Status-Table" : "Red-Status-Table"}`}>
                    {record.deadline_actualizado != null ? "Cargado" : "Pendiente"}
                </div>
            ),
            width : 80
        },
    ]

    const closeIconModal = () => {
        return (
            <div className="Button-Close-Modal-Detail-Area">
                <span>+</span>
            </div>
        )
    }

    return (
        <div>
            <Modal
                title={null}
                open={showModalDetailArea}
                centered
                closable={true}
                onCancel={() => {
                    setShowModalDetailArea(false)
                    setDataModal([])
                }}
                closeIcon={closeIconModal()}
                width={'97vw'}
                mask={true}
                maskClosable={true}
                footer={null}
                zIndex={100000000}
                className="Modal-Detail-Area-Status"
            >
                <Title className="Title-Modal-Detail-Area" level={3}>{nameArea == "Plantilla cuota SO" ? "Plantilla de Cuotas" : nameArea}</Title>
                <TableGlobal
                    columns={nameAreaBD== "Ventas KC-PDV Homologado" || nameAreaBD == "Trade-PDV Homologado" ? columnsHML : columnsSI}
                    actionOnRow={() => {}}
                    selectedRowKeys={selectedRowKeys}
                    setSelectedRowKeys={setSelectedRowKeys}
                    valueCheckbox={valueCheckbox}
                    setValueCheckbox={setValueCheckbox}
                    data={rex_data_modal_status}
                    pageTableGlobal={pageTableGlobal}
                    pageSizeTableGlobal={pageSizeTableGlobal}
                    setPageTableGlobal={setPageTableGlobal}
                    setPageSizeTableGlobal={setPageSizeTableGlobal}
                />
            </Modal>
        </div>
    )
}

export default ModalDetailArea