import { 
    TERMS_CONDITIONS,
    TERMS_CONDITIONS_FULL,
} from "../../../Constants/TermsConditions/TermsConditions"

const INIT_STATE = {
    rex_terms_conditions : {
        termsUse: false,
        intellectualPropertyRights: false,
        copyrightTrademark: false,
        acceptanceTerms: false,
        userObligations: false,
    },
    rex_terms_conditions_full : false,
}

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case TERMS_CONDITIONS: {
            return {
                ...state,
                rex_terms_conditions : action.payload,
            }
        }
        case TERMS_CONDITIONS_FULL: {
            return {
                ...state,
                rex_terms_conditions_full : action.payload,
            }
        }
        default:
            return state
    }
}