import config from '../../../config'
import { notification } from 'antd'
import {
    GET_DATA_CLIENTS_EXECUTIVES,
    GET_DATA_CLIENTS_EXECUTIVES_CLONE,
    DATA_CLIENT_EXECUTIVE,
    GET_DATA_MASTER_CLIENTS_EXECUTIVES,
    LOADING_DATA_CLIENTS_EXECUTIVES,
} from '../../../Constants/ClientsExecutives/ClientsExecutives'
import moment from 'moment'

const notificacionServidor = (type, mensaje, title=null) => {
    if(type === 'success'){
        notification['success']({
            message: 'Éxito',
            description: mensaje,
        })
    }else if(type === 'warning'){
        notification['warning']({
            message: 'Advertencia',
            description: mensaje,
        })
    }else if(type === 'error'){
        notification['error']({
            message: title?title:'Error',
            description: <div dangerouslySetInnerHTML={{ __html: mensaje }} />,
            duration: 10
        })
    }
}

export const GetDataClientsExecutivesReducer = () => async ( dispatch ) => {

    dispatch({
        type : LOADING_DATA_CLIENTS_EXECUTIVES,
        payload : true
    })

    await fetch(config.api+'clients-executives/get-clients-executives',
        {
            mode:'cors',
            method: 'POST',
            headers: {
                'Accept' : 'application/json',
                'Content-type' : 'application/json',
                'usutoken'	   : localStorage.getItem("usutoken"),
            },
        }
    )
    .then(res => res.json())
    .then(data => {
        const clients_executives = data.data
        clients_executives.map((ce, index) => {
            clients_executives[index]["key"] = ce.id
            clients_executives[index]["item"] = index + 1
        })
        dispatch({
            type: GET_DATA_CLIENTS_EXECUTIVES,
            payload: clients_executives
        })
        dispatch({
            type: GET_DATA_CLIENTS_EXECUTIVES_CLONE,
            payload: clients_executives
        })
    }).catch((error) => {
        console.log(error)
    })

    dispatch({
        type : LOADING_DATA_CLIENTS_EXECUTIVES,
        payload : false
    })
}

export const GetDataMasterClientsReducer = () => async (dispatch) => {
    await fetch(config.api+'clients-executives/get-master-clients',
        {
            mode:'cors',
            method: 'POST',
            headers: {
                'Accept' : 'application/json',
                'Content-type' : 'application/json',
                'usutoken'	   : localStorage.getItem("usutoken"),
            },
        }
    )
    .then(res => res.json())
    .then(data => {
        dispatch({
            type: GET_DATA_MASTER_CLIENTS_EXECUTIVES,
            payload: data.data
        })
    }).catch((error) => {
        console.log(error)
    })
}

export const GetDataEditClientExecutive = (clientId) => async ( dispatch, getState ) => {
    await fetch(config.api+'clients-executives/get-client-executive',
        {
            mode:'cors',
            method: 'POST',
            headers: {
                'Accept' : 'application/json',
                'Content-type' : 'application/json',
                'usutoken'	   : localStorage.getItem("usutoken"),
            },
            body: JSON.stringify({
                req_id_client: parseInt(clientId)
            }),
        }
    )
    .then(res => res.json())
    .then(data => {
        const client_executive = data.data
        const mesFormat = client_executive.mes < 10 ? "0"+client_executive.mes : client_executive.mes
        client_executive.mesanio = mesFormat+"/"+client_executive.anio
        dispatch({
            type    : DATA_CLIENT_EXECUTIVE,
            payload : client_executive
        })
    }).catch((error)=> {
        console.log(error)
    })

    const { rex_data_client_executive } = getState().clientsExecutives
    return rex_data_client_executive
}

export const CreateClientExecutiveReducer = () => async ( dispatch, getState ) => {

    const data_client = getState().clientsExecutives.rex_data_client_executive
    let respuesta = false

    await fetch(config.api+'clients-executives/create-client-executive',
        {
            mode:'cors',
            method: 'POST',
            headers: {
                'Accept' : 'application/json',
                'Content-type' : 'application/json',
                'usutoken'	   : localStorage.getItem("usutoken"),
            },
            body: JSON.stringify({
                req_canal : data_client.canal,
                req_ejecutivo : data_client.ejecutivo,
                req_gerente : data_client.gerente,
                req_cod_cliente : data_client.cod_cliente,
                req_cliente : data_client.cliente,
                req_anio : data_client.anio,
                req_mes : data_client.mes,
            }),
        }
    )
    .then(res => res.json())
    .then(data => {                
        if(data.response){
            dispatch(GetDataClientsExecutivesReducer())
            notificacionServidor('success', data.message)
            respuesta = true
        }else{
            notificacionServidor('warning', data.message)
        }
    }).catch((error)=> {
        console.log(error)
    })

    return respuesta
}

export const EditClientExecutiveReducer = () => async ( dispatch, getState ) => {

    const data_client = getState().clientsExecutives.rex_data_client_executive
    let respuesta = false

    await fetch(config.api+'clients-executives/edit-client-executive',
        {
            mode:'cors',
            method: 'POST',
            headers: {
                'Accept' : 'application/json',
                'Content-type' : 'application/json',
                'usutoken'	   : localStorage.getItem("usutoken"),
            },
            body: JSON.stringify({
                req_id : data_client.id,
                req_canal : data_client.canal,
                req_ejecutivo : data_client.ejecutivo,
                req_gerente : data_client.gerente,
                req_cod_cliente : data_client.cod_cliente,
                req_cliente : data_client.cliente,
                req_anio : data_client.anio,
                req_mes : data_client.mes,
            }),
        }
    )
    .then(res => res.json())
    .then(data => {
        if(data.response){
            dispatch(GetDataClientsExecutivesReducer())
            notificacionServidor('success', data.message)
            respuesta = true
        }else{
            notificacionServidor('warning', data.message)
        }
    }).catch((error)=> {
        console.log(error)
    })

    return respuesta
}

export const DeleteClientExecutiveReducer = (clientId) => async ( dispatch, getState ) => {

    await fetch(config.api+'clients-executives/delete-client-executive',
        {
            mode:'cors',
            method: 'POST',
            headers: {
                'Accept' : 'application/json',
                'Content-type' : 'application/json',
                'usutoken'	   : localStorage.getItem("usutoken"),
            },
            body: JSON.stringify({
                req_id_client : clientId,
            }),
        }
    )
    .then(res => res.json())
    .then(data => {                
        if(data.response){
            dispatch(GetDataClientsExecutivesReducer())
            notificacionServidor('success', data.message)
        }else{
            notificacionServidor('warning', data.message)
        }
    }).catch((error)=> {
        console.log(error)
    })
}

export const FieldFormClientExecutiveReducer = (type, value) => async ( dispatch, getState ) => {

    const client_executive = getState().clientsExecutives.rex_data_client_executive

    let valueForm = value ? value : ''

    if(type == 'cliente'){
        const dataclient = value ? value.label.split(' - ') : ''
        client_executive['cod_cliente'] = Array.isArray(dataclient) ? dataclient[0] : ''
        client_executive['cliente'] = Array.isArray(dataclient) ? dataclient[1] : ''
    }else if(type == 'fecha'){
        const datafecha = value ? value.split('/') : ''
        client_executive['mes'] = Array.isArray(datafecha) ? parseInt(datafecha[0]) : parseInt(moment().month() + 1)
        client_executive['anio'] = Array.isArray(datafecha) ? parseInt(datafecha[1]) : parseInt(moment().year())
        client_executive['mesanio'] = Array.isArray(datafecha) ? datafecha[0]+"/"+datafecha[1] : moment().format('MM/YYYY')
    }else{
        client_executive[type] = valueForm
    }

    dispatch({
        type : DATA_CLIENT_EXECUTIVE,
        payload : client_executive
    })
}

export const FilterClientsExecutivesReducer = (txtBuscar_value) => async ( dispatch, getState ) => {

    const data_clients_executives = getState().clientsExecutives.rex_data_clients_executives
    
    const clients_filter = data_clients_executives.filter( ce => 
        (
            ce.canal.toLowerCase().includes(txtBuscar_value.toLocaleLowerCase())
            || ce.cod_cliente.toLowerCase().includes(txtBuscar_value.toLocaleLowerCase())
        )
    )

    dispatch({
        type: GET_DATA_CLIENTS_EXECUTIVES_CLONE,
        payload: clients_filter
    })
}

export const ClearDataClientExecutiveReducer = (type, value) => async ( dispatch ) => {
    dispatch({
        type : DATA_CLIENT_EXECUTIVE,
        payload : {
            id: null,
            canal: '',
            ejecutivo: '',
            gerente: '',
            cod_cliente: '',
            cliente: '',
            anio: '',
            mes: '',
        }
    })
}