import config from "../../../config"
import { 
    GET_DATA_AREAS_DATABASES_STATUS,
    REGISTER_USERS_CONNECTED_MODULES_STATUS,
    GET_DATA_GROUP_STATUS,
    SELECCIONAR_STATUS,
    SELECT_DATE_FILTER_STATUS,
    DATA_MODAL_STATUS,
    LOADING_DATA_STATUS,
    STATE_ESTADOS_PENDIENTES
} from "../../../Constants/Status/Status"
import moment from "moment"
import notificationGlobal from "../../../Components/Global/NotificationGlobal"

export const EditStatePendingStatesReducer = (espid, eepid) => async ( dispatch, getState) => {

    let responseState = false
    
    await fetch(config.api+'status/state-pending-states/edit',
        {
            mode:'cors',
            method: 'POST',
            headers: {
                'Accept' 	   : 'application/json',
                'Content-type' : 'application/json',
                'usutoken'      : localStorage.getItem('usutoken')
            },
            body: JSON.stringify({
                req_espid   : espid,
                req_eepid   : eepid,
            }),
        }
    )
    .then( async res => {
        return res.json()
    })
    .then(data => {
        if(data.response){
            responseState = true
            dispatch(GetDataAreasStatusReducer())
        }else{
            notificationGlobal("warning", data.message)
        }
    }).catch((error)=> {
        console.log(error)
    });

    return responseState
}

export const GetStatePendingStatesReducer = () => async ( dispatch, getState) => {

    let responseState = false
    
    await fetch(config.api+'status/state-pending-states/all',
        {
            mode:'cors',
            method: 'POST',
            headers: {
                'Accept' 	   : 'application/json',
                'Content-type' : 'application/json',
                'usutoken'      : localStorage.getItem('usutoken')
            },
        }
    )
    .then( async res => {
        return res.json()
    })
    .then(data => {
        if(data.response){
            responseState = true
            dispatch({
                type : STATE_ESTADOS_PENDIENTES,
                payload : data.data
            })
        }
    }).catch((error)=> {
        console.log(error)
    });

    return responseState
}

export const GetInformationStatusDTReducer = (areid, nameArea) => async ( dispatch, getState) => {
    
    let dataDT = { response : false, data : []}

    dispatch(SetDataModalStatusReducer([], ""))

    await fetch(config.api+'status/information-status/dt',
        {
            mode:'cors',
            method: 'POST',
            headers: {
                'Accept' 	   : 'application/json',
                'Content-type' : 'application/json',
                'usutoken'      : localStorage.getItem('usutoken')
            },
            body: JSON.stringify({
                req_areid   : areid,
            }),
        }
    )
    .then( async res => {
        return res.json()
    })
    .then(data => {
        if(data.response){
            dataDT = {
                ...dataDT,
                response : true,
                data : data.data
            }
            dispatch(SetDataModalStatusReducer(data.data, nameArea))
        }
    }).catch((error)=> {
        console.log(error)
    });

    return dataDT
}

export const RegisterUsersConnectedStatusReducer = ( data ) => async (dispatch, getState) => {

    dispatch({
        type: REGISTER_USERS_CONNECTED_MODULES_STATUS,
        payload: data
    })
}

export const SetLoadingDataStatusReducer = (status) => async ( dispatch, getState ) => {
    dispatch({
        type    : LOADING_DATA_STATUS,
        payload : status
    })
}

export const SetDateFilterStatusReducer = (date) => async ( dispatch, getState ) => {
    dispatch({
        type    : SELECT_DATE_FILTER_STATUS,
        payload : date
    })
    const data = await dispatch(GetDataAreasStatusReducer())
    dispatch(UpdadtePercentageAreasStatusReducer())
}

export const SetDataModalStatusReducer = (data, nameArea) => async ( dispatch, getState ) => {

    let dataEstaticaPlantillaPdv = getState().status.rex_data_modal_estatica_plantilla_promo_pdv
    let dataEstaticaStatusInsumos = getState().status.rex_data_modal_estatica_status_insumo

    let dataDts = []
    if(nameArea.stsstatus.stsnombre == "PDV Homologado" && nameArea.arenombre == "Trade"){
        dataDts = dataEstaticaPlantillaPdv
    }else if(nameArea.stsstatus.stsnombre == "Status de Insumos" && nameArea.arenombre == "Ventas KC"){
        dataDts = dataEstaticaStatusInsumos
    }else{
        dataDts = data
    }

    dispatch({
        type: DATA_MODAL_STATUS,
        payload : dataDts
    })
}


export const GetDataAreasStatusReducer = () => async ( dispatch, getState ) => {

    let dateStatus  = getState().status.rex_select_date_filter_status
    let stsidStatus = getState().status.rex_seleccionar_status

    let dateFilter
    if(!dateStatus){
        dateFilter = moment().format("YYYY-MM")
    }else{
        dateFilter = dateStatus
    }

    dispatch(SetLoadingDataStatusReducer(true))
    
    await fetch(config.api+'status/information-status',
        {
            mode:'cors',
            method: 'POST',
            headers: {
                'Accept' 	   : 'application/json',
                'Content-type' : 'application/json',
                'usutoken'      : localStorage.getItem('usutoken')
            },
            body: JSON.stringify({
                req_fecha   : dateFilter,
                req_paiid   : localStorage.getItem('usupais')
            }),
        }
    )
    .then( async res => {
        return res.json()
    })
    .then(data => {
        if(data.response){

            let mesFilter = dateFilter.split("-");
            mesFilter = mesFilter[1];

            let dataStatus = data.data
            if(data.usu == "rosmar.c.requena@kcc.com" || data.usu  == "milagros.cordova@grow-analytics.com.pe" || data.usu == "admin@admin.com"){
                let dataCopia = [...dataStatus]
                let dataFake = [{
                    "areresponsable": "Ventas",
                    // "areresponsable": "Ventas Kimberly",
                    "areporcentaje": 0,
                    "arenombre": "Rutina de ventas",
                    "areid": 172,
                    "aremostrar": false,
                    "areicono": "comercial.png",
                    "stsid": 1,
                    "esps": [
                        {
                            "perpersonas": null,
                            "espfechaprogramado": `2024-${mesFilter}-10T00:00:00.000Z`,
                            "espfechactualizacion": null,
                            "espbasedato": "Objetivos Sell Out",
                            "espresponsable": "Ventas",
                            // "espresponsable": "Ventas Kimberly",
                            "espdiaretraso": "0",
                            "espdts": false,
                            "areid": 172
                        },
                    ],
                    "areporcentajeinicial": 0,
                    "stsstatus": {
                        "stsnombre": "Smartview"
                    },
                }]

                dataStatus = dataCopia.concat(dataFake)

            }

            dispatch({
                type: GET_DATA_AREAS_DATABASES_STATUS,
                payload : dataStatus
            })
            dispatch({
                type: GET_DATA_GROUP_STATUS,
                payload : data.status
            })

            if(data.status.length > 0){
                dispatch({
                    type: SELECCIONAR_STATUS,
                    payload : stsidStatus ? stsidStatus : data.status[0]["stsid"]
                })
            }
        }else{
            // dispatch(RegisterAuditReducer('get-data/paises', 'Error al obtener los paises', data.message, localStorage.getItem('usutoken'), data, user.usuid, 'ip', 'GET COUNTRIES'))
        }
    }).catch((error)=> {
        console.log(error)
    });

    dispatch(SetLoadingDataStatusReducer(false))
}

export const SelectStatusReducer = (stsid) => async (dispatch) => {
    dispatch({
        type: SELECCIONAR_STATUS,
        payload : stsid
    })
}

export const UpdadtePercentageAreasStatusReducer = () => async ( dispatch, getState ) => {

    let areasStatus = getState().status.rex_data_areas_databases_status

    for (let index = 0; index < 50; index++) {
    // for (let index = 0; index < 34; index++) {
        for await (const are of areasStatus){
            if(are.areporcentajeinicial < are.areporcentaje){
                are['areporcentajeinicial'] = are.areporcentajeinicial + 2
            }
            if(are.areporcentajeinicial > are.areporcentaje){
                are['areporcentajeinicial'] = are.areporcentaje
            }
        }
        dispatch({
            type: GET_DATA_AREAS_DATABASES_STATUS,
            payload : areasStatus
        })
        await new Promise(resolve => setTimeout(resolve, 1));
    }
}