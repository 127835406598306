import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import { useIdleTimer } from 'react-idle-timer'
import { ValidationUserReducer } from '../../Redux/Actions/Validations/Validations'
import { GetDataAreasStatusReducer, GetStatePendingStatesReducer, RegisterUsersConnectedStatusReducer, SelectStatusReducer, UpdadtePercentageAreasStatusReducer } from '../../Redux/Actions/Status/Status'
import { Row, Col, Affix, Skeleton } from 'antd'
import socket from '../../App/socket'
import CardStatus from '../../Components/Status/CardStatus'
import CardDescriptionStatus from '../../Components/Status/CardDescriptionStatus'
import SideMenu from '../../Components/Global/SideMenu'

const Status = () => {
    const timeout = 300000
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const { 
        rex_data_user_logingrow
    } = useSelector(({loginGrow}) => loginGrow);

    const ValidateUser = async () => {
        const validate_user = await dispatch(ValidationUserReducer())
        if(!validate_user){
            navigate('/login')
        }
    }

    useEffect(() => {
        ValidateUser()
        socket.emit('reg-modules', { module: 'status'}, {usuusuario: rex_data_user_logingrow.usuusuario});
        
        dispatch(GetDataAreasStatusReducer())

    }, [])

    useEffect(() => {
        socket.on('get-users-modules', (data_users) => {
            dispatch(RegisterUsersConnectedStatusReducer(data_users))
        });

        return () => {
            socket.emit('unreg-modules', { module: 'status'}, {usuusuario: rex_data_user_logingrow.usuusuario} )
            socket.off('get-users-modules');
        }
    },[])

    const onIdle = () => {
        socket.emit('reg-status-modules', { module: 'status'}, {usuusuario: rex_data_user_logingrow.usuusuario}, false )
    }
    const onActive = () => {
        socket.emit('reg-status-modules', { module: 'status'}, {usuusuario: rex_data_user_logingrow.usuusuario}, true )
    }

    useIdleTimer({
        onIdle,
        onActive,
        timeout,
        throttle: 500
    })

    const { 
        rex_data_users_online
    } = useSelector(({users}) => users)

    const {
        rex_users_status_connected,
        rex_data_areas_databases_status,
        rex_data_group_status,
        rex_seleccionar_status,
        rex_loading_data_status
    } = useSelector(({status}) => status)

    useEffect(() => {
        const timeoutID = setTimeout(() => {
            dispatch(UpdadtePercentageAreasStatusReducer())
        }, 3500);
      
        return () => {
            clearTimeout(timeoutID)
        }
    }, [])

    useEffect(() => {
        dispatch(GetStatePendingStatesReducer())
    }, [])

    useEffect(() => {

    },[rex_data_areas_databases_status])

    return(
        <div className="Container-Main Container-Status" style={{paddingBottom: 0}}>
            <Row>
                <Col span={24}>
                    <Affix offsetTop={36}>
                        <div className="Container-Button-Status">
                            {
                                rex_data_group_status.map(sts => 
                                    <button
                                        className={`Button-Status ${rex_seleccionar_status == sts.stsid ? 'Active-Status-Button' : ''}`}
                                        onClick={() => dispatch(SelectStatusReducer(sts.stsid))}
                                    >
                                        <span className="Text-Span">{sts.stsnombre}</span>
                                        <span className="Text-Span-Table-Status">{sts.stsnombre}</span>
                                        <div className="Circle-Button-Table-Status"></div>
                                    </button>
                                )
                            }
                        </div>
                    </Affix>
                </Col>
                <Col span={24} style={{backgroundColor: '#FFF'}}>
                    <CardStatus status={rex_data_areas_databases_status.filter(are => are.stsid == rex_seleccionar_status)} />
                </Col>
                {
                    rex_loading_data_status
                    ? <div style={{ display:'flex', flexDirection:'column', width:'100%', gap:'20px'}}>
                        <Skeleton.Button active={true} style={{height:'200px',width:'100%' , borderRadius:'15px'}} size='large' block={true} />
                        <Skeleton.Button active={true} style={{height:'200px',width:'100%' , borderRadius:'15px'}} size='large' block={true} />
                    </div>
                    : rex_data_areas_databases_status.length > 0 && rex_data_areas_databases_status.filter(are => are.stsid == rex_seleccionar_status).map((item, index) => 
                        <Col style={{marginTop: '0', marginBottom:'-5px'}} span={24} key={item.key}>
                            <CardDescriptionStatus
                                descriptionStatus={item}
                                index={index}
                            />
                        </Col>
                    )
                }
            </Row>
            {
                rex_users_status_connected && 
                    <SideMenu
                        usersCurrentView  = {rex_users_status_connected}
                        usersAllConnected = {rex_data_users_online}
                    />
            }
        </div>
    )
}

export default Status