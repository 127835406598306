import config from "../../../config"

export const GetAudioGreetingReducer = () => async ( dispatch, getState ) => {
    
    let url = null
    const time_id = Math.floor(Math.random() * 2) + 1;

    await fetch(config.api+'audio-file/get-audio-file',
        {
            mode:'cors',
            method: 'POST',
            headers: {
                'Accept' : 'application/json',
                'Content-type' : 'application/json',
                'usutoken'	   : localStorage.getItem("usutoken"),
            },
            body: JSON.stringify({
                req_time    : 2
            }),
        }
    )
    .then(res => res.json())
    .then(data => {
        if(data.response){
            url = data.data
        }
    }).catch((error) => {
        console.log(error)
    })
    return url
}