import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Col, Row, Input, Form } from 'antd'
import { RegisterUsersConnectedProfileReducer } from '../../Redux/Actions/Profile/Profile'
import socket from '../../App/socket'
import SideMenu from '../../Components/Global/SideMenu'
import moment from 'moment'

const Perfil = () => {
    const dispatch = useDispatch()

    const { 
        rex_data_users_online
    } = useSelector(({users}) => users)

    const { 
        rex_data_user_logingrow
    } = useSelector(({loginGrow}) => loginGrow)

    const {
        rex_users_profile_connected,
    } = useSelector(({profile}) => profile)

    useEffect(() => {
        socket.on('get-users-modules', (data_users) => {
            dispatch(RegisterUsersConnectedProfileReducer(data_users))
        });

        return () => {
            socket.emit('unreg-modules', { module: 'status'}, {usuusuario: rex_data_user_logingrow.usuusuario} )
            socket.off('get-users-modules');
        }
    }, [])
    
    return (
        <div className='Container-Main Perfil-Container'>
            {/* <NavigationGlobal menuItems={menuUsers}/> */}
            <Form
                layout='vertical'
                className='Perfil-Container-Form'
            >

                <Row className='Perfil-Container-Text-Main'>
                    <Col>
                        <p className='Perfil-Text-Main'>Mi perfil</p>
                    </Col>
                </Row>


                <Row className='Perfil-Container-Form-Inputs' gutter={20}>

                    <Col xs={{span:24}} sm={{span:24}} md={{span:24}} lg={{span:12}} xl={{span:12}}>
                        <Row>
                            <Form.Item
                                label='Nombres'
                                className="Perfil-Form-Inputs"
                            >
                                <Input 
                                    placeholder="Nombre" 
                                    size='large' 
                                    disabled 
                                    value={
                                        rex_data_user_logingrow.perpersonas
                                        ? rex_data_user_logingrow.perpersonas.pernombre
                                        : null
                                    } 
                                />
                            </Form.Item>
                        </Row>
                        <Row>
                            <Form.Item
                                label='Apellidos'
                                className="Perfil-Form-Inputs"
                            >
                                <Input 
                                    placeholder="Apellidos" 
                                    size='large' 
                                    disabled 
                                    value={
                                        rex_data_user_logingrow.perpersonas
                                        ? rex_data_user_logingrow.perpersonas.perapellidos
                                        : null
                                    }
                                />
                            </Form.Item>
                        </Row>
                        <Row>
                            <Form.Item
                                label='Posición'
                                className="Perfil-Form-Inputs"
                            >
                                <Input 
                                    placeholder="Posición" 
                                    size='large' 
                                    disabled 
                                    value={
                                        rex_data_user_logingrow.tputiposusuarios
                                        ? rex_data_user_logingrow.tputiposusuarios.tpunombre
                                        : null
                                    }
                                />
                            </Form.Item>
                        </Row>
                        <Row>
                            <Form.Item
                                label='Correo corporativo'
                                className="Perfil-Form-Inputs"
                            >
                                <Input 
                                    placeholder="Correo" 
                                    size='large' 
                                    disabled 
                                    value={rex_data_user_logingrow.usuusuario}
                                />
                            </Form.Item>
                        </Row>
                        <Row>
                            <Form.Item
                                label='Correo personal'
                                className="Perfil-Form-Inputs"
                            >
                                <Input 
                                    placeholder="Correo personal" 
                                    size='large' 
                                    disabled 
                                    value={rex_data_user_logingrow.usucorreo}
                                />
                            </Form.Item>
                        </Row>
                        <Row>
                            <Form.Item
                                label='Género'
                                className="Perfil-Form-Inputs"
                            >
                                <Input 
                                    placeholder="Género" 
                                    size='large' 
                                    disabled 
                                    value={
                                        rex_data_user_logingrow.gnrgenero
                                        ? rex_data_user_logingrow.gnrgenero.gnrnombre
                                        : null
                                    }
                                />
                            </Form.Item>
                        </Row>
                    </Col>
                    <Col xs={{span:24}} sm={{span:24}} md={{span:24}} lg={{span:12}} xl={{span:12}} className='Perfil-Container-Input-Right'>
                        <Row>
                            <Form.Item
                                label='Fecha de incorporación'
                                className='Perfil-Input-Date'
                            >
                                <Input 
                                    placeholder="Fecha de incorporación" 
                                    size='large' 
                                    disabled 
                                    value={moment(rex_data_user_logingrow.usufechaincorporacion).format('DD/MM/YY')}
                                />
                            </Form.Item>
                        </Row>
                        <Row>
                            <Form.Item
                                label='Acceso a Nivel País'
                                className="Perfil-Form-Inputs"
                            >
                                <Input 
                                    placeholder="País" 
                                    size='large' 
                                    disabled 
                                    value={rex_data_user_logingrow.countries}
                                />
                            </Form.Item>
                        </Row>
                        <Row>
                            <Form.Item
                                label='Celular'
                                className="Perfil-Form-Inputs"
                            >
                                <Input 
                                    placeholder="Celular" 
                                    size='large' 
                                    disabled 
                                    value={rex_data_user_logingrow.usutelefono}
                                />
                            </Form.Item>
                        </Row>
                    </Col>
                </Row>
            </Form>

            {
                rex_users_profile_connected && 
                    <SideMenu
                        usersCurrentView  = {rex_users_profile_connected}
                        usersAllConnected = {rex_data_users_online}
                    />
            }
        </div>
    )
}

export default Perfil