import {
    GET_DATA_MSL_MANAGE,
    GET_DATA_MSL_MANAGE_CLONE,
    GET_DATA_CHANNELS_MSL_MANAGE,
    SHOW_MODAL_NOTIFICATIONS_ALERT,
    DATA_MODAL_NOTIFICATIONS_ALERT,
    GET_DATA_MSL_MANAGE_CURRENT_SOURCE,
    LOADING_DATA_MSL_MANAGE
} from "../../../../Constants/Manage/MSL/MSL";

const INIT_STATE = {
    rex_data_msl_manage                         : [],
    rex_data_msl_manage_clone                   : [],
    rex_loading_data_msl_manage                 : true,
    rex_data_msl_manage_current_source          : [],
    rex_data_channels_msl_manage                : [],
    rex_data_modal_notifications_alert_manage   : [],
    rex_show_modal_notifications_alert_manage   : false,
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {

        case LOADING_DATA_MSL_MANAGE: {
            return {
                ...state,
                rex_loading_data_msl_manage : action.payload,
            }
        }
        case GET_DATA_MSL_MANAGE_CURRENT_SOURCE: {
            return {
                ...state,
                rex_data_msl_manage_current_source : action.payload,
            }
        }
        case SHOW_MODAL_NOTIFICATIONS_ALERT: {
            return {
                ...state,
                rex_show_modal_notifications_alert_manage : action.payload,
            }
        }
        case DATA_MODAL_NOTIFICATIONS_ALERT: {
            return {
                ...state,
                rex_data_modal_notifications_alert_manage : action.payload,
            }
        }
        case GET_DATA_MSL_MANAGE: {
            return {
                ...state,
                rex_data_msl_manage : action.payload,
            }
        }
        case GET_DATA_MSL_MANAGE_CLONE: {
            return {
                ...state,
                rex_data_msl_manage_clone : action.payload,
            }
        }
        case GET_DATA_CHANNELS_MSL_MANAGE: {
            return {
                ...state,
                rex_data_channels_msl_manage : action.payload,
            }
        }
        default:
            return state;
    }
}