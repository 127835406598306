import notificationGlobal from '../../../Components/Global/NotificationGlobal'
import config from '../../../config'
import {
    OBTENER_ARCHIVOS_DOWNLOAD_DATA, 
    REGISTER_USERS_CONNECTED_MODULES,
    DATA_DOWNLOAD_SELECTED,
    FILTERS_DOWNLOAD_DATA,
} from "../../../Constants/DownloadData/DownloadData"
import { RegisterAuditReducer } from "../Audits/Audits"
import { notification } from 'antd'

const notificacionServidor = (type, mensaje) => {
    if(type === 'success'){
        notification['success']({
            message: 'Éxito',
            description: mensaje,
        })
    }else if(type === 'warning'){
        notification['warning']({
            message: 'Advertencia',
            description: mensaje,
        })
    }else if(type === 'error'){
        notification['error']({
            message: 'Error',
            description: mensaje,
        })
    }
}

export const ObtenerArchivosDownloadDataReducer = () => async (dispatch, getState) => {

    const usuario = getState().loginGrow.rex_data_user_logingrow

    const data_download_file_get = {
        req_paiid: parseInt(localStorage.getItem("usupais"))
    }

    await fetch(config.api+'data/get-download-data',
        {
            mode:'cors',
            method: 'POST',
            headers: {
                'Accept' : 'application/json',
                'Content-type' : 'application/json',
                'usutoken'	   : localStorage.getItem("usutoken"),
            },
            body: JSON.stringify(data_download_file_get),
        }
    )
    .then( async res => {
        return res.json()
    })
    .then(data => {                
        if(data.response){
            dispatch({
                type: OBTENER_ARCHIVOS_DOWNLOAD_DATA,
                payload: data.data
            })
        }else{
            // dispatch(RegisterAuditReducer('data/download-data', 'Error al obtener la data de los archivos', data.message, data_download_file_get, data, usuario.usuid, 'ip', 'GET DOWNLOAD DATA'))
        }
    }).catch((error)=> {
        console.log(error)
    })
}

export const DownloadDataExcelReducer = (data) => async ( dispatch, getState) => {

    const filtersDownloadData = getState().downloadData.rex_filters_download_data
    const filtersDownloadDataCopy = getState().downloadData.rex_filters_download_data_copy

    let req_filters = { req_id : data.ardid }

    let dataFilters = true
    filtersDownloadData.map(fil => {
        if(fil.show){
            fil.data.map(typ => {
                if(typ.show){
                    req_filters = { ...req_filters, [typ.filterName] : typ.value }
                }else{
                    dataFilters = false
                }
            })
        }
    })

    if(data.ardfiltros){
        if(!dataFilters){
            notificationGlobal("warning", "Es necesario seleccionar todos los filtros")
            return false
        }
    }
    
    let url = ""
    await fetch(config.api+'download-data/download-url',
        {
            mode:'cors',
            method: 'POST',
            headers: {
                'Accept' : 'application/json',
                'Content-type' : 'application/json',
                'usutoken'	   : localStorage.getItem("usutoken"),
            },
            body: JSON.stringify(req_filters)
        }
    )
    .then(res => res.json())
    .then(async data => {
        if(data.response){
            url = data.data
            dispatch({
                type    :  FILTERS_DOWNLOAD_DATA,
                payload : filtersDownloadDataCopy
            })        
            notificacionServidor('success', data.message)
        }else{
            notificacionServidor('warning', data.message)
        }
    })
    .catch((error)=> {
        console.log(error)
    })

    return url
}

export const CreateDownloadDataExcelReducer = () => async (dispatch, getState) => {
    const { rex_data_download_selected } = getState().downloadData

    let validation = true
    let respuesta = false

    if(rex_data_download_selected.usuid){
        validation = false
    }else if(rex_data_download_selected.paiid){
        validation = false
    }else if(rex_data_download_selected.pemid){
        validation = false
    }else if(rex_data_download_selected.ardnombre){
        validation = false
    }else if(rex_data_download_selected.ardarchivo){
        validation = false
    }else if(rex_data_download_selected.ardcredenciales){
        validation = false
    }else if(rex_data_download_selected.ardmostrar){
        validation = false
    }

    if(validation){
        await fetch(config.api+'data/create-download-data',
            {
                mode:'cors',
                method: 'POST',
                headers: {
                    'Accept' : 'application/json',
                    'Content-type' : 'application/json',
                    'usutoken'	   : localStorage.getItem("usutoken"),
                },
                body: JSON.stringify({
                    data_download: rex_data_download_selected
                })
            }
        )
        .then(res => res.json())
        .then(async data => {
            if(data.response){
                notificacionServidor('success', data.message)
                respuesta = true
            }else{
                notificacionServidor('warning', data.message)
            }
        })
        .catch((error)=> {
            console.log(error)
        })
    }else{
        notificacionServidor('warning', 'Por favor llenar todos los campos')
    }

    return respuesta
}

export const GetUniqueDownloadDataReducer = (id) => async (dispatch, getState) => {
    await fetch(config.api+'data/get-unique-download-data',
        {
            mode:'cors',
            method: 'POST',
            headers: {
                'Accept' : 'application/json',
                'Content-type' : 'application/json',
                'usutoken'	   : localStorage.getItem("usutoken"),
            },
            body: JSON.stringify({
                req_id: parseInt(id),
            }),
        }
    )
    .then(res => res.json())
    .then(async data => {                
        if(data.response){
            dispatch({
                type: DATA_DOWNLOAD_SELECTED,
                payload: data.data
            })
        }else{
            notificacionServidor('warning', data.message)
        }
    }).catch((error)=> {
        console.log(error)
    })
}

export const EditDownloadDataExcelReducer = (id) => async (dispatch, getState) => {
    const { rex_data_download_selected } = getState().downloadData

    let validation = true
    let respuesta = false

    if(rex_data_download_selected.usuid){
        validation = false
    }else if(rex_data_download_selected.paiid){
        validation = false
    }else if(rex_data_download_selected.pemid){
        validation = false
    }else if(rex_data_download_selected.ardnombre){
        validation = false
    }else if(rex_data_download_selected.ardarchivo){
        validation = false
    }else if(rex_data_download_selected.ardcredenciales){
        validation = false
    }else if(rex_data_download_selected.ardmostrar){
        validation = false
    }

    if(validation){
        await fetch(config.api+'data/edit-download-data',
            {
                mode:'cors',
                method: 'POST',
                headers: {
                    'Accept' : 'application/json',
                    'Content-type' : 'application/json',
                    'usutoken'	   : localStorage.getItem("usutoken"),
                },
                body: JSON.stringify({
                    req_id: parseInt(id),
                    data_download: rex_data_download_selected,
                })
            }
        )
        .then(res => res.json())
        .then(async data => {
            if(data.response){
                notificacionServidor('success', data.message)
                respuesta = true
            }else{
                notificacionServidor('warning', data.message)
            }
        })
        .catch((error)=> {
            console.log(error)
        })
    }else{
        notificacionServidor('warning', 'Por favor llenar todos los campos')
    }

    return respuesta
}

export const DeleteDownloadDataExcelReducer = (id) => async () => {
    let respuesta = false
    await fetch(config.api+'data/delete-download-data',
        {
            mode:'cors',
            method: 'POST',
            headers: {
                'Accept' : 'application/json',
                'Content-type' : 'application/json',
                'usutoken'	   : localStorage.getItem("usutoken"),
            },
            body: JSON.stringify({
                req_id: parseInt(id),
            })
        }
    )
    .then(res => res.json())
    .then(async data => {
        if(data.response){
            respuesta = true
            notificacionServidor('success', data.message)
        }else{
            notificacionServidor('warning', data.message)
        }
    })
    .catch((error)=> {
        console.log(error)
    })

    return respuesta
}

export const ClearDownloadDataReducer = () => async (dispatch) => {
    dispatch({
        type: DATA_DOWNLOAD_SELECTED,
        payload: {}
    })
}

export const RegisterUsersConnectedModulesReducer = ( data ) => async (dispatch, getState) => {
    dispatch({
        type: REGISTER_USERS_CONNECTED_MODULES,
        payload: data
    })
}

export const SelectFilterDownloadDataReducer = ( type, name, label ) => async (dispatch, getState) => {

    const filtersDownloadData = getState().downloadData.rex_filters_download_data

    filtersDownloadData.map( dod => {

        //Fecha
        if(dod['name'] == type){
            let indexFilter = dod['data'].findIndex(dat => dat.name == name)

            //Año-Mes
            if(label){

                let itemFilter = dod['data'][indexFilter]['items'].find(lab => lab.label == label)
                if(dod['data'][indexFilter]['label'] == label){
                    dod['data'][indexFilter]['value'] = null
                    dod['data'][indexFilter]['label'] = ""
                }else{                    
                    dod['data'][indexFilter]['value'] = itemFilter.value
                    dod['data'][indexFilter]['label'] = itemFilter.label
                }
            }else{
                dod['data'][indexFilter]['value'] = null
                dod['data'][indexFilter]['label'] = ""
            }
            
            let showFilter = dod['data'].some(item => item.value != null)
            
            dod['data'][indexFilter]['show'] = dod['data'][indexFilter]['value'] ? true : false
            dod['show'] = showFilter    
        }
    })

    dispatch({
        type    :  FILTERS_DOWNLOAD_DATA,
        payload : filtersDownloadData
    })
}

export const ResetSelectFilterDownloadDataReducer = ( type, name, label ) => async (dispatch, getState) => {
    const filtersDownloadData = getState().downloadData.rex_filters_download_data

    filtersDownloadData.map(fil => {
        fil['show'] = false
        fil.data.map(dat => {
            dat['show']     = false
            dat['value']    = null
        })
    })
    
    dispatch({
        type    :  FILTERS_DOWNLOAD_DATA,
        payload : filtersDownloadData
    })
}