import React, { useEffect, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Row, Col, Collapse } from 'antd'
import { ObtenerDataModulosDashboardsReducer } from '../../Redux/Actions/Dashboards/Dashboards'
import ImagenModulo from './../../Assets/Images/Home/FrameModule.png'
import ImagenModuloSeleccionado from './../../Assets/Images/Home/ModuloSeleccionado.jpg'

const Modules = () => {

    const { Panel } = Collapse
    const dispatch  = useDispatch()

    const [ moduleSelected, setModuleSelected ] = useState(null)
    const [ moduleSelectedCollapse, setModuleSelectedCollapse ] = useState(1)

    useEffect(() => {
        dispatch(ObtenerDataModulosDashboardsReducer())
    }, [])

    const {
		rex_data_modulos_dashboards_total
    } = useSelector(({dashboards}) => dashboards)

    return (
        <div className='Container-Main'>
            <Row className='Container-Home-Modules'>
                <Col 
                    xs={{span:24}} sm={{span:24}} md={{span:6}} lg={{span:6}} xl={{span:6}}
                    style={{display:'flex', alignItems:'center'}}
                >
                    <Row style={{display:'flex', alignItems:'center'}}>
                        <Col>
                            {rex_data_modulos_dashboards_total.map( ( mod, index_mod ) => (
                                <div key={index_mod}>
                                    <span 
                                        className={`Modules-List-Home-Modules ${moduleSelected == mod.modid? 'Module-Selected-Home-Modules':''}`}
                                        onClick={() => {
                                            setModuleSelected(mod.modid)
                                            setModuleSelectedCollapse(null)
                                        }}
                                    >{mod.modnombre}</span>
                                </div>
                            ))}
                        </Col>
                    </Row>

                </Col>
                <Col 
                    xs={{span:24}} sm={{span:24}} md={{span:8}} lg={{span:8}} xl={{span:8}}
                    style={{marginTop:'5%'}}
                >
                    <Row>
                        <Col>
                            <img
                                className='Container-Img-Module-Home-Modules'
                                src={ImagenModulo}
                            />
                        </Col>
                        <Col 
                            span={14}
                            className='Module-Selected-Main-Home-Modules'
                        >
                            <span>
                                {rex_data_modulos_dashboards_total.filter( mo => mo.modid == moduleSelected).map( mod  => (
                                    <>{mod.modnombre}</>
                                ))}
                            </span>
                        </Col>
                    </Row>

                    <Row className='Container-Collapse-Home-Modules'>
                        <Col span={24}>
                            <Collapse
                                activeKey={moduleSelectedCollapse}
                            >
                            {
                                rex_data_modulos_dashboards_total.filter( mo => mo.modid == moduleSelected ).map( mod => (
                                    mod.children.map((smo, index_smo) => (
                                            <Panel
                                                onClick={() =>{
                                                    if(moduleSelectedCollapse == index_smo){
                                                        setModuleSelectedCollapse(null)
                                                    }else{
                                                        setModuleSelectedCollapse(index_smo)
                                                    }
                                                }}
                                                className={`Header-Collapse-Home-Modules ${moduleSelectedCollapse == index_smo ?'Header-Collapse-Selected-Home-Modules':''}`}
                                                showArrow={false} header={smo.smonombre}
                                            >
                                                {
                                                    smo.children?.map(ssm => (
                                                        <span
                                                            className='SubSubModule-Home-Modules'
                                                        >{ssm.ssmnombre}</span>
                                                    ))
                                                }
                                            </Panel>
                                    ) )
                                ))
                            }

                            </Collapse>

                        </Col>
                    </Row>
                </Col>
                <Col span={2}></Col>
                <Col
                    xs={{span:24}} sm={{span:24}} md={{span:7}} lg={{span:7}} xl={{span:7}}
                    style={{marginTop:'5%'}}
                    className='Container-Image-Home-Modules'
                >
                    <div
                        className='Img-Module-Selected-Home-Modules'
                    >
                        <img src={ImagenModuloSeleccionado}/>
                    </div>
                </Col>
            </Row>
        </div>
    )
}

export default Modules