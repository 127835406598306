import React, { useState, useEffect } from 'react'
import { Row, Col, Typography, Form, Input } from 'antd'
import SoundButton from '../../Assets/Audio/sonidoOption.mp3'
import './../../Styles/Routes/Login/RecoverPassword.css'
import { useNavigate, useParams } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { RestorePasswordLoginGrowReducer } from '../../Redux/Actions/LoginGrow/LoginGrow'
import { ShowModalNotificationGlobalReducer } from '../../Redux/Actions/Global/Global'
import ModalNotification from '../../Components/Global/ModalNotification'

const RestorePassword = () => {

    const { token, id } = useParams()
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const { Title } = Typography
    const [ tamanioEmail, setTamanioEmail] = useState(false)
    const [ lengthPassword, setLengthPassword ] = useState(0)
    const [ valuePassword, setValuePassword ] = useState('')
    const [ valueConfirmPassword, setValueConfirmPassword ] = useState('')
    const [backgroundColor, setBackgroundColor] = useState('')
    const [ focusInputPassword, setFocusInputPassword ] = useState(false)
    const [ focusInputConfirmPassword, setFocusInputConfirmPassword ] = useState(false)
    const [ loadingRestore, setLoadingRestore ] = useState(false)

    const audio = new Audio(SoundButton)
    const [ clickButton, setClickButton ] = useState(false)
    const reproduceSound = () => {
        audio.play();
    }
    
    const lengthInput = (e) => {
        let lengthValue = e.target.value
        setValuePassword(lengthValue)
        if(lengthValue.length  > 0){
            setLengthPassword(true)
        }else{
            setLengthPassword(false)
        }
    }
    
    const onFinish = async (values) => {
    }

    const showModalAlert = ( show, title) => {
        let dataModal = {title:title, messageDescription: [],  status:""}
        dispatch(ShowModalNotificationGlobalReducer(show, dataModal))
    }

    useEffect(() => {

        localStorage.removeItem("usutoken");
        localStorage.removeItem("usupais");

        const now = new Date()
        const hour = now.getHours()

        if(hour >= 6 && hour < 12){
            setBackgroundColor('Color-Blue')
        }else if(hour >= 12 && hour < 18){
            setBackgroundColor('Color-Yellow')
        }else{
            setBackgroundColor('Color-Purple')
        }
    }, [])

    return (
        <Row className="Container-Image-Gif-Recover" style={{height: '100vh'}}>
            <Col lg={15} md={24} xs={24}>
                <div className={`Container-Recover-Password View-Restore`}>
                    <Form
                        name="basic"
                        onFinish={onFinish}
                        className="Form-Recover-Password"
                        autoComplete="off"
                    >
                        <Title 
                            level={2}
                            className="Title-Recover-Password"
                        >
                            ¿Olvidaste tu <br/>contraseña?
                        </Title>
                        
                        <p className="Message-Recover">
                            Introducir una nueva contraseña de 8 caractéres<br/>como mínimo, considerar mayúsculas y minúsculas.
                        </p>

                        <p className="Message-Recover-Responsive">
                            Introducir una nueva contraseña de 8 caractéres<br/>como mínimo, considerar mayúsculas y minúsculas.
                        </p>

                        <Form.Item
                            name="password"
                            style={{
                                marginBottom: '10px',
                                textAlign: 'center',
                                display:'flex',
                                justifyContent:'center',
                            }}
                        >
                            <div style={{ display:'flex', flexDirection:'column', position:'relative'}}>
                                <Input.Password
                                    onFocus={()=> {
                                        setFocusInputPassword(true)
                                    }}
                                    onBlur={() =>{
                                        if(lengthPassword == false){
                                            setFocusInputPassword(false)
                                        }
                                    }}
                                    onChange={(e) => lengthInput(e, 1)}
                                    className={`Input-Restore ${ valuePassword.length > 0 && valuePassword.length < 8 ? 'Input-Restore-Error' : ''}`}
                                />
                                <label class={`${focusInputPassword ? `Label-Input-Login-Grow-Focus`:'Label-Input-Login-Grow' }`}>Ingresar nueva contraseña</label>
                            </div>
                            {
                                valuePassword.length > 0 && valuePassword.length < 8 ? <span style={{fontSize:"12px", color:"#FF1CDB" }}>Contraseña muy corta</span>:null
                            }
                        </Form.Item>
                        <Form.Item
                            name="repeatpassword"
                            style={{
                                marginBottom: '10px',
                                marginTop: valuePassword.length > 0 && valuePassword.length < 8 ? '10px' : '15px',
                                textAlign: 'center',
                                display:'flex',
                                justifyContent:'center',
                            }}
                        >
                            <div style={{ display:'flex', flexDirection:'column', position:'relative'}}>
                                <Input.Password
                                    onFocus={()=> {
                                        setFocusInputConfirmPassword(true)
                                    }}
                                    onBlur={() =>{
                                        if(valueConfirmPassword == false){
                                            setFocusInputConfirmPassword(false)
                                        }
                                    }}
                                    onChange={(e) => {
                                        setValueConfirmPassword(e.target.value)
                                    }}
                                    className={`Input-Restore  ${(valuePassword != valueConfirmPassword) && valueConfirmPassword.length > 0 ? 'Input-Restore-Error' : ''}`}
                                />
                                <label class={`${focusInputConfirmPassword ? `Label-Input-Login-Grow-Focus ${backgroundColor}`:'Label-Input-Login-Grow' }`}>Confirmar nueva contraseña</label>
                                {
                                (valuePassword != valueConfirmPassword) && (valuePassword.length > 7 && valueConfirmPassword.length > 0) ? <span style={{fontSize:"12px", color:"#FF1CDB" }}>Las contraseñas no coinciden</span>:null
                                }
                            </div>
                        </Form.Item>

                        <Form.Item
                            style={{
                                textAlign: 'center',
                                marginTop: '15px'
                            }}
                        >

                            <button
                                className="Button-Login-Grow Restore-Password" 
                                htmlType="submit"
                                onClick={async ()=> {
                                    reproduceSound()
                                    setClickButton(true)
                                    if(valuePassword !== valueConfirmPassword){
                                        return false
                                    }
                                    if(valuePassword === valueConfirmPassword && valuePassword.length > 0){
                                        setLoadingRestore(true)
                                        let {response, message} = await dispatch(RestorePasswordLoginGrowReducer(token, valuePassword, id))
                                        setLoadingRestore(false)
                                        if(response){
                                            navigate('/home')
                                        }else{
                                            showModalAlert(true, message)
                                        }
                                    }else{
                                        showModalAlert(true, "Debe ingresar la contraseña nueva")
                                    }
                                }}
                                onMouseEnter={() => reproduceSound()}
                                onMouseLeave={() => setClickButton(false)}
                            >
                                <span style={{zIndex:"1"}}>{loadingRestore ? "Enviando" : "Iniciar Sesión"}</span>
                                <span className={`Text-Span-Login-Grow ${clickButton?'Button-Text-Color':''}`}>{loadingRestore?"Enviando" : "Iniciar Sesión"}</span>

                                <div className="Button-Background-Login-Grow"></div>
                                <div className='Button-Corner Button-Top-Left-Login-Grow'></div>
                                <div className='Button-Corner Button-Top-Right-Login-Grow'></div>
                                <div className='Button-Corner Button-Bottom-Right-Login-Grow'></div>
                                <div className='Button-Corner Button-Bottom-Left-Login-Grow'></div>
                            </button>

                        </Form.Item>
                    </Form>
                </div>
            </Col>
            <Col lg={9} md={0} xs={0} className="Col-Image-Recover"></Col>
            <ModalNotification/>
        </Row>
    )
}

export default RestorePassword