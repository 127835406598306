import React, { useState } from 'react'
import { Row, Col, Collapse, Modal, Tooltip, InputNumber } from 'antd'
import "../../Styles/Components/Global/TableGlobalCollapse.css"
import { 
    CaretDownOutlined,
    CaretUpOutlined,
    PlusCircleOutlined,
    ExclamationCircleFilled,
    CheckCircleOutlined,
    CloseCircleOutlined
} from '@ant-design/icons'
import EditOptionButtonGlobal from '../../Assets/Images/Global/EditOptionButtonGlobal'
import TrashButtonGlobalSimpleColor from '../../Assets/Images/Global/TrashButtonGlobalSimpleColor'
import { useDispatch } from 'react-redux'
import { AddRowTableIncentivenProgramReducer, DeleteNewRowTableIncentivenProgramReducer, DeleteRowEditingTableIncentivenProgramReducer, EditRowTableIncentivenProgramReducer, EditValueRowTableIncentivenProgramReducer, SaveNewRowTableIncentivenProgramReducer } from '../../Redux/Actions/IncentivProgram/IncentivProgram'
import moment from 'moment'

const TableGlobalCollapse = ({title, columns, data, titleTable, titleSection, dateFilter}) => {

    const [ activeKey, setActiveKey ] = useState(false)
    const { Panel } = Collapse
    const { confirm } = Modal
    const dispatch = useDispatch()

    const confirmDelete = async (titleTable, titleSection, titleDetail, dat, index) => {
        confirm({
            title   : "Eliminar Registro",
            icon    : <ExclamationCircleFilled/>,
            content : "¿Está seguro que desea eliminar el registro?",
            zIndex  : 100000000,
            className:"Confirm-Action-Incentive-Program",
            async onOk(){
                let response = await dispatch(DeleteNewRowTableIncentivenProgramReducer(titleTable, titleSection, titleDetail, dat, dateFilter, index))
            },
            okButtonProps: {
                size:"small",
            },
            cancelButtonProps : {
                size:"small",
                danger:true
            },
            okCancel(){},
        })
    }

    const confirmSave = async (titleTable, titleSection, title, key, index) => {
        confirm({
            title   : "Guardar cambios",
            icon    : <ExclamationCircleFilled/>,
            content : "¿Está seguro que desea guardar los cambios?",
            zIndex  : 100000000,
            className:"Confirm-Action-Incentive-Program",
            async onOk(){
                let response = await dispatch(SaveNewRowTableIncentivenProgramReducer(titleTable, titleSection, title, key, index, dateFilter))
            },
            okButtonProps: {
                size:"small",
            },
            cancelButtonProps : {
                size:"small",
                danger:true
            },
            okCancel(){},
        })
    }

    return (
        <>
            <div 
                title={title} 
                className={`Title-Collapse-Incentiv-Program ${activeKey && "Item-Active"}`}
                onClick={() => {
                    setActiveKey(!activeKey)
                }}
            >
                <span>{title}</span>
                <div
                >
                    {activeKey ?<CaretDownOutlined /> : <CaretUpOutlined /> }
                </div>
            </div>
            <Collapse className="Collapse-Table-Incentiv-Program" activeKey={activeKey ? ['1'] : ['']} >
                <Panel
                    showArrow={false}
                    header={<Row className="Collapse-Table-Container-Header">
                        {
                            columns.map((col, index) => (
                                <Col key={index} className="Column-Secondary-Table-Collapse" span={Math.floor(24/columns.length)}>
                                    <div title={col.name} className="Cell-Column-Secondary-Title">{col.name}</div>
                                </Col>
                            ))
                        }
                    </Row>} 
                    key="1"
                >
                {
                    <>
                        {data.map((dat, index) => (
                        <Row key={index} className="Row-Data-Table-Collapse">
                            <Col span={6} className="Cell-Data-Table-Collapse Cell-Left">
                                {dat.editing 
                                    ? <div style={{display:"flex", justifyContent:"center", alignItems:"center", width:"100%"}}>
                                        <InputNumber
                                            min="0"
                                            max="9999999999"
                                            step="0.01" 
                                            defaultValue={dat.petdesde}
                                            className="Input-Cell-Data-Edit-Left"
                                            onChange={(value) => 
                                                dispatch(EditValueRowTableIncentivenProgramReducer(value,'petdesde', titleTable, titleSection, title, index))
                                            }
                                            size='small'/>
                                        <span className="Separation-Input">% -</span>
                                        <InputNumber
                                            min="0"
                                            max="9999999999"
                                            step="0.01" 
                                            className="Input-Cell-Data-Edit-Right"
                                            defaultValue={dat.pethasta}
                                            onChange={(value) => 
                                                dispatch(EditValueRowTableIncentivenProgramReducer(value,'pethasta', titleTable, titleSection, title, index))
                                            }
                                            style={{width:"30px", fontSize:"11px", color:"#013A81"}}
                                            size='small'/>
                                        <span>  %</span>
                                    </div>
                                    : `${dat.petdesde}% - ${dat.pethasta}%`
                                }
                            </Col>
                            <Col span={6} className="Cell-Data-Table-Collapse Cell-Right">
                                {dat.editing 
                                    ? <div style={{display:"flex", justifyContent:"center", alignItems:"center", width:"100%"}}>
                                        <InputNumber
                                            min="0"
                                            max="9999999999"
                                            className="Input-Cell-Data-Edit-Revenue"
                                            step="0.01" 
                                            onChange={(value) => dispatch(EditValueRowTableIncentivenProgramReducer(value,'petganancia', titleTable, titleSection, title, index))}
                                            defaultValue={dat.petganancia} size='small' style={{fontSize:"11px", textAlign:"end", color: "#013A81"}}
                                            />
                                            <span>{' '}%</span>
                                        </div>
                                    : `${dat.petganancia}%`
                                }
                            </Col>
                            <Col span={6} className="Cell-Data-Table-Collapse Cell-Left">
                                <Tooltip 
                                    showArrow={true}
                                    placement="right"
                                    overlayClassName="Info-Tooltip-Cell-Data-Table-Collapse"
                                    title={`${dat.usuusuarios?.usuusuario} ${moment(dat.updated_at).format('DD/MM/YYYY h:mm a')}`}
                                >
                                    <div style={{width:"100%", columnGap:"3px", textOverflow:"ellipsis", overflow:"hidden", whiteSpace:"nowrap"}}>
                                        <span>{dat.usuusuarios?.usuusuario}</span>
                                    </div>
                                </Tooltip>
                            </Col>
                            <Col span={6} className="Cell-Data-Table-Collapse Container-Actions">
                                {
                                    dat.editing
                                    ? <div style={{display:"flex", columnGap:"5px", alignItems:"center"}}>
                                        <div 
                                            style={{display:"flex", alignItems:"center"}}
                                            onClick={() => confirmSave(titleTable, titleSection, title, dat.petid, index)}
                                        >
                                            <CheckCircleOutlined style={{fontSize:"15px", cursor:"pointer", color: "#2AD295"}}/>
                                        </div>
                                        <div
                                            style={{display:"flex", alignItems:"center"}}
                                            onClick={() => {
                                                dispatch(DeleteRowEditingTableIncentivenProgramReducer(titleTable, titleSection, title, dat.petid, index))
                                                // dispatch(DeleteRowEditingTableIncentivenProgramReducer(titleTable, titleSection, title, dat.petid))
                                            }}
                                        >
                                            <CloseCircleOutlined style={{fontSize:"15px", cursor:"pointer", color: "red"}}/>
                                        </div>
                                    </div>


                                    : <div style={{display:"flex", columnGap:"5px"}}>
                                        <div
                                            onClick={() => dispatch(EditRowTableIncentivenProgramReducer(titleTable, index,titleSection, title))}
                                        >
                                            <EditOptionButtonGlobal size={13}/>
                                        </div>
                                        <div
                                            style={{display:"flex", alignItems:"center"}}
                                            onClick={() => confirmDelete(titleTable, titleSection, title, dat, index)}
                                        >
                                            <TrashButtonGlobalSimpleColor 
                                                size={13}
                                            />
                                        </div>
                                        <div
                                            style={{display:"flex", alignItems:"center"}}
                                            onClick={() => dispatch(AddRowTableIncentivenProgramReducer(titleTable, index, titleSection, title))}
                                        >
                                            <PlusCircleOutlined style={{fontSize:"15px", cursor:"pointer", color: "#013A81"}}/>

                                        </div>
                                    </div>
                                }
                                
                            </Col>
                        </Row>
                        ))}
                    </>
                }
                </Panel>
            </Collapse>
    </>
    )
}

export default TableGlobalCollapse