import React, { useState } from 'react'
import { useDispatch } from 'react-redux'
import { Popover, Radio } from 'antd'
import ArrowSelectPopOver from '../../Assets/Images/ProfileTypes/arrow-popover.png'

const FilterSelectRadioGlobal = ({textSelect, data, action}) => { 
    const [showList, setShowList] = useState(false)
    // se usa localStorage por el momento hasta que se cambie por un variable de redux 
    const [valueData, setValueData] = useState(parseInt(localStorage.getItem('usupais')))

    const dispatch = useDispatch()

    const sendData = (value) => {
        setValueData(value.target.value)
        dispatch(action(value.target.value))
    }

    const listData = () => (
        <Radio.Group 
            className="Container-Radio-PopOver-Global"
            onChange={sendData} 
            value={valueData}
        >
            {
                data.map(data => 
                    <Radio key={data.value} value={data.value}>{data.label}</Radio>
                )
            }
        </Radio.Group>
    )

    return (
        <Popover
            title={null}
            content={listData()}
            trigger='click'
            open={showList}
            showArrow={false}
            placement="bottomLeft"
            onOpenChange={() => setShowList(!showList)}
        >
            <div className="Filter-Container-PopOver-Global">
                <span>{textSelect}</span>
                <img style={{marginLeft:'18px'}} src={ArrowSelectPopOver}/>
            </div>
        </Popover>
    )
}

export default FilterSelectRadioGlobal