import {
    GET_USERS_DOWNLOAD_DATA_INTERNAL_CONNECTED
} from "../../../Constants/DownloadDataInternal/DownloadDataInternal"

const INIT_STATE = {
    rex_users_donwload_data_internal_connected          : [],
}

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case GET_USERS_DOWNLOAD_DATA_INTERNAL_CONNECTED: {
            return {
                ...state,
                rex_users_donwload_data_internal_connected : action.payload,
            }
        }
        default:
            return state;
    }
}