import {
    REGISTER_USERS_CONNECTEDS_DASHBOARDS
} from "../../../Constants/Dashboards/Dashboards";

const INIT_STATE = {
    rex_users_conected_dashboard : [],
    rex_number_users_hidden_dashboard : 0,
    rex_users_shows_dashboard : []
};


export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case REGISTER_USERS_CONNECTEDS_DASHBOARDS: {
            return {
                ...state,
                rex_users_conected_dashboard : action.payload.users,
                rex_users_shows_dashboard    : action.payload.users_shows,
                rex_number_users_hidden_dashboard : action.payload.number_hidden
            }
        }
        default:
            return state;
    }
}
