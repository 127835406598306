import React, { useState, useEffect } from 'react'
import { Card, Typography } from 'antd'
import ItemNotificationFile from './ItemNotificationFile'
import CampanaImage from '../../Assets/Images/UploadFile/campana.svg' 
import CampanaColor from '../../Assets/Images/UploadFile/IconNotification.gif'
import { useSelector } from 'react-redux'

const CardNotificationFile = (props) => {
    const itemNotification = props.itemNotification
    const { Title } = Typography
    const [offset, setOffset] = useState(0)

    const {
        rex_messages_notifications_upload_files,
    } = useSelector(({fileUpload}) => fileUpload);

    useEffect(() => {
        const onScroll = () => setOffset(window.pageYOffset)
        window.removeEventListener('scroll', onScroll)
        window.addEventListener('scroll', onScroll, { passive: true })
        return () => window.removeEventListener('scroll', onScroll)
    }, [])

    return(
        <Card className="Card-Notification-File" 
            style={{top: offset > 50 && '50px'}}
        >
            <div className="Container-Head-Notification-File-Upload">
                <img
                    src={CampanaColor}
                />
                <Title
                    className="Title-Notificacion-File"
                    level={3}
                >
                    Notificaciones
                </Title>
            </div>
            {
                itemNotification.length > 0
                    ?   itemNotification.map((item, key) => 
                            <ItemNotificationFile 
                                key={key} item={item} index={key} />
                        )
                    :   <div className="Container-Not-Notification">
                            <img src={CampanaImage} className="Image-Not-Notification" />
                            <div className="Text-Not-Notification">No hay notificaciones recientes</div>
                        </div>                        
            }
        </Card>
    )
}

export default CardNotificationFile
 