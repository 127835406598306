import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Row, Col, Typography, Modal } from 'antd'
import { useNavigate } from 'react-router'
import SideMenu from '../../Components/Global/SideMenu'
import FormEditDataControl from '../../Components/DataControl/FormEditDataControl'

const EditDataControl = () => {

    const { Title } = Typography
    const navigate = useNavigate()

    const [ showModal, setShowModal ] = useState(false)
    const [ infoModal, setInfoModal ] = useState({})

    const statusModal = () => {
        setShowModal(false)
    }

    const { 
        rex_info_data_control_edited
    } = useSelector(({dataControl}) => dataControl)

    const { 
        rex_users_conected_dashboard,
    } = useSelector(({registerUsers}) => registerUsers)

    const { 
        rex_data_users_online
    } = useSelector(({users}) => users)
    
    return (
        <div className='Container-Main'>
            <div className='Container-Menu-Navigation-Global'>
                <p className='Title-Menu-Navigation-Global'>Filtro aplicado:</p>
                    <div 
                        className={`Item-Menu-Navigation-Global`}
                        onClick={() => {
                            navigate('/home-administrator')
                        }}
                    >
                        &nbsp;<span>Administrador</span><p>&nbsp;/</p>
                    </div>
                    <div 
                        className={`Item-Menu-Navigation-Global`}
                        onClick={() => {
                            navigate('/administrator/data-control')
                        }}
                    >
                        &nbsp;<span>Control de datos</span><p>&nbsp;/</p>
                    </div>
                    <div 
                        className={`Item-Menu-Navigation-Global Item-Menu-Selected-Navigation-Global`}
                    >
                        &nbsp;<span>Editar control de datos</span>
                    </div>
            </div>
            <Row>
                <Col span={24}>
                    <Title level={2} className='Title-Search-Text-Global'>{rex_info_data_control_edited.original_file_name}</Title>
                </Col>
            </Row>
            <FormEditDataControl
                setShowModal={setShowModal}
                setInfoModal={setInfoModal}
                infoModal={infoModal}
            />

            <Modal
                open={showModal}
                title={<span className={`${infoModal.status ? "Text-Ok" : "Text-Alert"}`}>{infoModal.title}</span>}
                width={300}
                zIndex={100000000}
                className='Modal-Create-Data-Control'
                footer={[
                    <div style={{display:"flex", justifyContent:"center"}}>
                        <div className={`Button-Close Button${infoModal.status ? "-Ok" : "-Alert"}`} onClick={() => statusModal()}>
                            Ok
                        </div>
                    </div>
                ]}
            >
                <div className='Modal-Description'>
                    {infoModal.description}
                </div>
            </Modal>

            <SideMenu
                usersCurrentView  = {rex_users_conected_dashboard}
                usersAllConnected ={rex_data_users_online}
            />
            
        </div>
    )
}

export default EditDataControl