import React, { useState } from 'react'
import { Dropdown, Button, Typography } from 'antd'
import { LeftOutlined, RightOutlined } from '@ant-design/icons'

const MenuSliderGlobal = (props) => {
    const titleCategory = props.titleCategory ? props.titleCategory : ''
    const items = props.items
    const { Title } = Typography
    const [activeListLeft, setActiveListLeft] = useState(false)
    const [activeListRigth, setActiveListRigth] = useState(false)
    return(
        <div className="Container-Categories-Global">
            <Title className="Title-Categories-Dashboard">
                {titleCategory}
            </Title>
            <div className='Container-Buttons-Prev-Next'>
                <div className="Container-Prev">
                    <Dropdown
                        onOpenChange={(e) => setActiveListLeft(e)}
                        menu={{items}}
                        arrow
                        placement="bottomRight"
                        overlayClassName="Dropdown-Categories-Dashboard"
                    >
                        <div className={`Container-Button ${activeListLeft ? 'Active-List' : ''}`}>
                            <Button className="Button-Prev">
                                <LeftOutlined className="Icon-Button-Prev" />
                            </Button>
                            <div className="Border-Button"></div>
                        </div>
                    </Dropdown>
                </div>
                <div className="Container-Next">
                    <Dropdown
                        onOpenChange={(e) => setActiveListRigth(e)}
                        menu={{items}}
                        arrow
                        placement="bottomRight"
                        overlayClassName="Dropdown-Categories-Dashboard"
                    >
                        <div className={`Container-Button ${activeListRigth ? 'Active-List' : ''}`}>
                            <Button className="Button-Next">
                                <RightOutlined className="Icon-Button-Next" />
                            </Button>
                            <div className="Border-Button"></div>
                        </div>
                    </Dropdown>
                </div>
            </div>
        </div>
    )
}

export default MenuSliderGlobal 