import React, { useState, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { SaveTermsConditionsReducer, ValidationTermsConditionsReducer } from '../../Redux/Actions/TermsConditions/TermsConditions'
import { Row, Col, Typography, Affix } from 'antd'
import ItemTermsConditions from '../../Components/TermsConditions/ItemTermsConditions'
import ContentTermsConditions from '../../Components/TermsConditions/ContentTermsConditions'

const TermsConditions = () => {
    const { Title } = Typography
    const [activeTermsConditions, setActiveTermsConditions] = useState("Condiciones de uso")
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { 
        rex_terms_conditions,
        rex_terms_conditions_full,
    } = useSelector(({termsConditions}) => termsConditions)

    const { 
        rex_data_user_logingrow,
    } = useSelector(({loginGrow}) => loginGrow)

    useEffect(() => {
        const handleScroll = () => {
            const scrollTop = window.scrollY
            const vh = window.innerHeight
            if(vh < 730){
                if(scrollTop < 50){
                    setActiveTermsConditions("Condiciones de uso")
                }else if(scrollTop < 150){
                    setActiveTermsConditions("Derechos de Propiedad Intelectual")
                }else if(scrollTop < 280){
                    setActiveTermsConditions("Derechos de Autor y Marca")
                }else if(scrollTop < 375){
                    setActiveTermsConditions("Aceptación de términos")
                }else if(scrollTop >= 375){
                    setActiveTermsConditions("Obligaciones de los usuarios")
                }
            }else{
                if(scrollTop < 35){
                    setActiveTermsConditions("Condiciones de uso")
                }else if(scrollTop < 85){
                    setActiveTermsConditions("Derechos de Propiedad Intelectual")
                }else if(scrollTop < 130){
                    setActiveTermsConditions("Derechos de Autor y Marca")
                }else if(scrollTop < 160){
                    setActiveTermsConditions("Aceptación de términos")
                }else if(scrollTop >= 160){
                    setActiveTermsConditions("Obligaciones de los usuarios")
                }
            }
        }
      
        window.addEventListener('scroll', handleScroll)
      
        return () => {
            window.removeEventListener('scroll', handleScroll)
        }
    }, [])

    useEffect(() => {
        dispatch(ValidationTermsConditionsReducer())
    }, [rex_data_user_logingrow])

    return(
        <div className="Container-Main Container-Terms-Conditions">
            <Row style={{rowGap: '30px'}}>
                <Col span={24}>
                    <Title
                        level={2}
                        className="Title-Terms-Conditions"
                    >
                        Términos y Condiciones de GIA
                    </Title>
                    <p className="Text-Terms-Conditions">A GIA le preocupa proteger su derecho a la privacidad. Los siguientes términos se proporcionan para explicar cómo recopilamos y utilizamos la información sobre usted que proporciona al acceder a nuestro sitio web para que pueda tomar una decisión informada sobre el uso del sitio web.</p>
                    <p className="Text-Second-Terms-Conditions">Para continuar en la plataforma usted tiene que leer y aceptar todos los terminos y condiciones de GIA.</p>
                </Col>
                <Col span={8} style={{paddingRight: '42px'}}>
                    <Affix offsetTop={74}>
                        <div>
                            <Title
                                level={3}
                                className="Title-Content-Terms-Conditions"
                            >
                                Contenido
                            </Title>
                            <ContentTermsConditions 
                                title="Condiciones de uso"
                                switchValue={rex_terms_conditions.termsUse}
                                activeTermsConditions={activeTermsConditions}
                                type="termsUse"
                            />
                            <ContentTermsConditions 
                                title="Derechos de Propiedad Intelectual"
                                switchValue={rex_terms_conditions.intellectualPropertyRights}
                                activeTermsConditions={activeTermsConditions}
                                type="intellectualPropertyRights"
                            />
                            <ContentTermsConditions 
                                title="Derechos de Autor y Marca"
                                switchValue={rex_terms_conditions.copyrightTrademark}
                                activeTermsConditions={activeTermsConditions}
                                type="copyrightTrademark"
                            />
                            <ContentTermsConditions 
                                title="Aceptación de términos"
                                switchValue={rex_terms_conditions.acceptanceTerms}
                                activeTermsConditions={activeTermsConditions}
                                type="acceptanceTerms"
                            />
                            <ContentTermsConditions 
                                title="Obligaciones de los usuarios"
                                switchValue={rex_terms_conditions.userObligations}
                                activeTermsConditions={activeTermsConditions}
                                type="userObligations"
                            />
                        </div>
                    </Affix>
                </Col>
                <Col span={16} style={{paddingLeft: '42px'}}>
                    <Row>
                        <Col span={24}>
                            <ItemTermsConditions 
                                titleItem="Condiciones de uso"
                                textItem="El usuario del sitio Web y/o App se compromete a leer detenidamente los términos y condiciones, antes de utilizar los portales y servicios Web ofrecidos. Ello implica que usted acepta expresamente los términos y condiciones. En caso de no aceptarlos, se le solicita que no haga uso, ni acceda, ni manipule la información de los servicios ofrecidos por el sitio Web; ya que usted (usuario) está haciendo un uso inadecuado de éste.El propósito del sitio Web y/o App, es el cumplimiento del proyecto Spider; cuyo uso está dirigido exclusivamente a aquellos usuarios autorizados por los países Kimberly Clark Perú, Bolivia y Chile."
                            />
                        </Col>      
                        <Col span={24}>
                            <ItemTermsConditions 
                                titleItem="Derechos de Propiedad Intelectual"
                                textItem="Todos los derechos de propiedad intelectual del sitio Web y/o App son de propiedad de Grow Analytics. Se prohíbe el uso de cualquier derecho de propiedad intelectual sin contar con el consentimiento previo, expreso y por escrito de aquella."
                            />
                        </Col>
                        <Col span={24}>
                            <ItemTermsConditions 
                                titleItem="Derechos de Autor y Marca"
                                textItem={<>
                                <p style={{marginBottom: '20px'}}>Este Sitio se encuentra Protegido por la Normativa Vigente sobre derechos de autor. Todos los derechos involucrados, como, por ejemplo, textos, imágenes gráficas, dashboards y su diseño visual, son de titularidad de Grow Analytics, por lo que <span style={{fontWeight: 'bold', fontStyle: 'italic'}}>se encuentra estrictamente prohibido su empleo, replica similar, parecida o equivalente al layaout de los graficos e indicadores creados</span>, modificación, reproducción, distribución, transmisión, o comercialización de los derechos involucrados sin el permiso previo, expreso y por escrito de Grow Analytics; o, transferir los contenidos, creaciones publicadas en el portal, marcas, textos e imágenes gráficas de este Sitio a terceros que no formen parte de este proyecto, ni competidores directos de Grow Analytics.</p>
                                <p style={{marginBottom: '0'}}>Las prohibiciones antes mencionadas son a título enunciativo y no taxativo, por lo que se extenderán a todas aquellas que se deriven del respeto y reconocimiento de los derechos de Grow Analytics protegidos por las leyes de la propiedad intelectual e industrial y derechos de autor.</p>
                                </>}
                            />
                        </Col>
                        <Col span={24}>
                            <ItemTermsConditions 
                                titleItem="Aceptación de términos"
                                textItem={<>
                                <p style={{marginBottom: '20px'}}>El sitio Web está disponible para que el usuario bajo su responsabilidad lo utilice adecuadamente sin aprovecharse de alguna falla que ocurra y saque provecho de la misma. Si encuentra alguna falla en nuestro sitio Web reportarla a soporte@grow-analytics.com.pe</p>
                                <p style={{marginBottom: '20px'}}>Queda prohibido el uso del nombre, logotipos, marcas, diseños o cualquier signo distintivo de autoría de Grow Analytics, sin previa y expresa autorización de ésta.</p>
                                <p>El Usuario, en general, deberá cumplir con todas y cada una de las demás obligaciones establecidas en las presentes Condiciones, así como las demás que puedan inferirse de un uso correcto, de buena fe y/o racional del Sitio. El incumplimiento de las mismas dará lugar a las responsabilidades civiles y penales correspondientes.</p>
                                </>}
                            />
                        </Col>
                        <Col span={24}>
                            <ItemTermsConditions 
                                titleItem="Obligaciones de los usuarios"
                                textItem={<>
                                <p style={{marginBottom: '20px'}}>El Usuario, previamente autorizado por los países Kimberly Clark Perú, Bolivia y Chile, acepta, reconoce y se obliga a que la contraseña, debe ser mantenida en reserva personal, no debe ser divulgada ni permitido su conocimiento, por acción u omisión, por parte de terceros. Es de total e ilimitada responsabilidad del Usuario mantener la confidencialidad de la contraseña. El Usuario es totalmente responsable de todas y cada una de las actividades que se realicen bajo su contraseña. El proporcionar la contraseña a terceros o el posibilitar que terceros la conozcan (por acción, omisión o descuido), importa la asunción del Usuario de todos los riesgos, daños, perjuicios y las responsabilidades civiles o penales que ello implique directa, indirecta o consecuentemente. Grow Analytics no asumirá en ningún caso responsabilidad y queda exenta totalmente de ella por un uso indebido por parte del Usuario o de terceros de la referida contraseña o equivalentes.</p>
                                <p>Asimismo, a efectos de garantizar la seguridad, toda transacción realizada con su Usuario y contraseña será vigilada para que no intente hacer de un mal uso o un uso distinta del Sitio, respecto de la finalidad del Proyecto.</p>
                                </>}
                            />
                        </Col>
                    </Row>
                </Col>
                <Col span={24}>
                    <button 
                        className={`Button-Terms-Conditions ${rex_terms_conditions_full ? 'Active-Button-Terms-Conditions' : ''}`}
                        onClick={async () => {
                            if(rex_terms_conditions_full){
                                await dispatch(SaveTermsConditionsReducer()) && navigate(-1)
                            }
                        }}
                    >
                        <span className="Text-Span-First-Terms-Conditions">Leí y estoy conforme</span>
                        <span className="Text-Span-Terms-Conditions">Leí y estoy conforme</span>
                    </button>
                </Col>
            </Row>
        </div>
    )
}

export default TermsConditions