import React, { useState } from 'react'
import { Modal, Typography, Row, Col } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { ShowModalNotificationsAlertReducer } from '../../../Redux/Actions/Manage/MSL/MSL'
import "../../../Styles/Components/Manage/MSL/ModalMessageAlert.css"

const ModalMessageAlert = ({ view, dateProducts, dateMsl, dateNewProducts }) => {

    const { Title } = Typography

    const dispatch = useDispatch()

    const {
        rex_data_modal_notifications_alert_manage,
        rex_show_modal_notifications_alert_manage
    } = useSelector(({msl}) => msl)

    return (
        <Modal 
            title={null}
            open={rex_show_modal_notifications_alert_manage}
            closable={false}
            width={700}
            footer={null}
            zIndex={100000000}
            className='Modal-Notification-Alert-MSL'
        >
            <Row className='Modal-Container-Alert-MSL'>
                <Col span={24} className='Modal-Container-Text-Main'>
                    <Title>
                        ¡Alerta!
                    </Title>
                </Col>
                <Col span={24} className='Modal-Container-Text-Description'>
                    <p>Los siguientes códigos ya se encuentran registrados con la fecha seleccionada:</p>
                    {
                        rex_data_modal_notifications_alert_manage.map((msg, index) => (
                            <p key={index}>- {msg}</p>
                        ))
                    }
                </Col>
                <Col span={24} className='Modal-Container-Button-Main'>
                    <div
                        onClick={() => dispatch(ShowModalNotificationsAlertReducer(false, []))}
                        className='Modal-Close-Alert-MSL'
                    >
                        Ok
                    </div>
                </Col>
            </Row>
        </Modal>
    )
}

export default ModalMessageAlert