import React, { useEffect, useState } from 'react'
import { Row, Col } from 'antd'
import CardFile from '../../Components/FileUpload/CardFile'
import CardNotificationFile from '../../Components/FileUpload/CardNotificationFile'
import TitleGlobal from '../../Components/Global/TitleGlobal'
import { useNavigate } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import { ValidationUserReducer } from '../../Redux/Actions/Validations/Validations'
import SideMenu from '../../Components/Global/SideMenu'
import { useIdleTimer } from 'react-idle-timer'
import socket from '../../App/socket'
import { 
    GetCardsFileUpload, 
    RegisterUsersConnectedFileUploadReducer 
} from '../../Redux/Actions/FileUpload/FileUpload'
import ModalItemNotification from '../../Components/FileUpload/ModalItemNotification'
import {
    funPermisosObtenidos,
    funPermisoAreaFileUpload
} from '../../Functions/funPermisos'

const FileUpload = () => {

    const timeout = 300000
    const dispatch = useDispatch()
    const navigate = useNavigate()

    const { 
        rex_data_user_logingrow
    } = useSelector(({loginGrow}) => loginGrow);

    const ValidateUser = async () => {
        const validate_user = await dispatch(ValidationUserReducer())
        if(!validate_user){
            navigate('/login')
        }
    }

    const { 
        rex_data_permissions_user
    } = useSelector(({loginGrow}) => loginGrow)

    useEffect(() => {
        ValidateUser()
        dispatch(GetCardsFileUpload())
        socket.emit('reg-modules', { module: 'file-upload'}, {usuusuario: rex_data_user_logingrow.usuusuario})
    }, [])

    useEffect(() => {
        socket.on('get-users-modules', (data_users) => {
            dispatch(RegisterUsersConnectedFileUploadReducer(data_users))
        });

        return () => {
            socket.emit('unreg-modules', { module: 'file-upload'}, {usuusuario: rex_data_user_logingrow.usuusuario} );
            socket.off('get-users-modules');
        };
    },[])

    const onIdle = () => {
        socket.emit('reg-status-modules', { module: 'file-upload'}, {usuusuario: rex_data_user_logingrow.usuusuario}, false );
    }
    const onActive = () => {
        socket.emit('reg-status-modules', { module: 'file-upload'}, {usuusuario: rex_data_user_logingrow.usuusuario}, true );
    }

    useIdleTimer({
        onIdle,
        onActive,
        timeout,
        throttle: 500
    })

    const { 
        rex_data_users_online
    } = useSelector(({users}) => users);

    const {
        rex_users_file_upload_connected,
        rex_messages_notifications_upload_files,
        rex_cards_upload_file,
        rex_cards_total_upload_file,
        rex_show_notification_modal_upload_file
    } = useSelector(({fileUpload}) => fileUpload);

    useEffect(() => {

    }, [rex_messages_notifications_upload_files])
    
    return(
        <div className="Container-Main Container-File-Upload">
            <Row>
                <Col span={24}>
                    <TitleGlobal 
                        titleSection="File Upload"
                    />
                </Col>
            </Row>
            <Row style={{rowGap: '24px', marginTop: '-10px'}}>
                <Col xl={17} lg={15} md={24} xs={24}>
                    {
                        rex_cards_upload_file.filter(rca => funPermisoAreaFileUpload( rca.cards, rex_data_permissions_user)).map((car, index) => {
                            return car.cards.length > 0
                                ? (
                                    <>
                                        <div className="Title-Card-File-Upload">
                                            {car.name == "Area 1" ?  
                                                rex_data_user_logingrow.usuusuario == "rosmar.c.requena@kcc.com" || rex_data_user_logingrow.usuusuario == "milagros.cordova@grow-analytics.com.pe" || rex_data_user_logingrow.usuusuario == "admin@admin.com"
                                                ? "FFVV" : car.name
                                            : car.name
                                        }
                                        </div>
                                        <Row gutter={{xs: 0, sm: 20}} style={{margin: '0', rowGap: '20px', marginBottom: '5px'}}>
                                            {car.cards.map((item, indexItem) => {
                                                return funPermisosObtenidos(
                                                    rex_data_permissions_user, item.slug,
                                                    <Col key={indexItem} style={{maxHeight:'290px', height: '270px'}} className="gutter-row" xl={6} lg={12} sm={12} xs={24}>
                                                            {
                                                                rex_data_user_logingrow.usuusuario == "rosmar.c.requena@kcc.com" || rex_data_user_logingrow.usuusuario == "milagros.cordova@grow-analytics.com.pe" || rex_data_user_logingrow.usuusuario == "admin@admin.com" 
                                                                ? car.name == "Area 1"
                                                                    ? item.title == "Master de Producto" ? null : item.title == "Master de Clientes" 
                                                                    ? <CardFile
                                                                    title   = {"Objetivos Sell Out"}
                                                                    subtitle= {item.subtitle}
                                                                    url     = {item.url}
                                                                    disabled= {item.disabled}
                                                                    template= {item.fileTemplate}
                                                                    area    = {car.name}
                                                                /> : <CardFile
                                                                    title   = {item.title}
                                                                    subtitle= {item.subtitle}
                                                                    url     = {item.url}
                                                                    template= {item.fileTemplate}
                                                                    disabled= {item.disabled}
                                                                    area    = {car.name}
                                                                />
                                                                    : <CardFile
                                                                        title   = {item.title}
                                                                        subtitle= {item.subtitle}
                                                                        url     = {item.url}
                                                                        disabled= {item.disabled}
                                                                        template= {item.fileTemplate}
                                                                        area    = {car.name}
                                                                    /> 
                                                                : <CardFile
                                                                    title   = {item.title}
                                                                    subtitle= {item.subtitle}
                                                                    url     = {item.url}
                                                                    disabled= {item.disabled}
                                                                    template= {item.fileTemplate}
                                                                    area    = {car.name}
                                                                />
                                                            }
                                                        </Col>
                                                )
                                            })}
                                        </Row>
                                    </>
                                )
                                : null
                        })
                    }
                </Col>
                <Col xl={7} lg={9} md={24} xs={24}>
                    <Row gutter={{xs: 0, sm: 20}} style={{margin: '0', height: '100%'}}>
                        <Col className="gutter-row" span={24}>
                            <CardNotificationFile
                                itemNotification={rex_messages_notifications_upload_files}
                            />
                        </Col>
                    </Row>
                </Col>
            </Row>
            {
                rex_users_file_upload_connected &&
                    <SideMenu
                        usersCurrentView  = {rex_users_file_upload_connected}
                        usersAllConnected = {rex_data_users_online}
                    />
            }

            {
                rex_show_notification_modal_upload_file && <ModalItemNotification/>
            }
            
        </div>
    )
}

export default FileUpload