import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Row, Col, Typography } from 'antd'
import FormCreateUser from '../../Components/Users/FormCreateUser'
import NotificationCreated from '../../Components/Users/NotificationCreated'
import NavigationGlobal from '../../Components/Global/NavigationGlobal'
import SideMenu from '../../Components/Global/SideMenu'
import { CleanDataCreateUserReducer } from '../../Redux/Actions/Users/Users'

const CreateUser = () => {

    const { Title } = Typography
    const dispatch = useDispatch()

    const menuUsers = [
        {
            url: '/home-administrator',
            nombre: 'Administrador'
        },
        {
            url: '/administrator/users',
            nombre: 'Lista de usuarios'
        },
        {
            url: '/administrator/users/create-user',
            nombre: 'Nuevo usuario'
        },
    ]

    const { 
        rex_users_conected_dashboard,
    } = useSelector(({registerUsers}) => registerUsers)

    useEffect(() => {
        if (window.location.hash === "/create-user") {
            dispatch(CleanDataCreateUserReducer())
        }
    },[])

    const { 
        rex_data_users_online
    } = useSelector(({users}) => users)

    return (
        <div className='Container-Main Container-Users-Create'>
            <NavigationGlobal menuItems={menuUsers}/>
            <Row>
                <Col span={24}>
                    <Title level={2} className='Title-Create-User-New'>Nuevo usuario</Title>
                </Col>
            </Row>
            <FormCreateUser />
            <NotificationCreated />

            <SideMenu
                usersCurrentView  = {rex_users_conected_dashboard}
                usersAllConnected ={rex_data_users_online}
            />
        </div>
    )
}

export default CreateUser