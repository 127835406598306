import {
    OBTENER_DATA_MODULOS_DASHBOARDS_TOTAL,
    OBTENER_DATA_MODULOS_DASHBOARDS_FIRST,
    OBTENER_DATA_MODULOS_DASHBOARDS_SECOND,
    OBTENER_DATA_MODULO_SELECCIONADO_DASHBOARDS,
    OBTENER_DATA_OTROS_MODULO_SELECCIONADO_DASHBOARDS,
    OBTENER_DATA_ITEMS_SLIDER_GLOBAL_DASHBOARDS,
    REGISTRAR_ACTIVIDAD_MODULO_DASHBOARDS,
    REGISTRAR_ID_MODULO_SELECCIONADO_DASHBOARDS,
    REGISTRAR_DRIID_MODULO_DASHBOARDS,
    REGISTRAR_RISID_MODULO_DASHBOARDS,
    REGISTRAR_TIPO_MODULO_DASHBOARDS,
    PRELOAD_ANIMATION,
    OBTENER_ITEMS_OTROS_MODULOS_DASHBOARDS,
    GET_SUBMODULE_DASHBOARD,
    DATA_DASHBOARD,
    TITLE_MODULE,
    TITLE_SUBMODULE,
    TITLE_SUBSUBMODULE,
    TOKEN_ACCESO_POWER_BI
} from "../../../Constants/Dashboards/Dashboards"

const INIT_STATE = {
    rex_data_modulos_dashboards_total               : [],
    rex_data_modulos_dashboards_first               : [],
    rex_data_modulos_dashboards_second              : [],
    rex_data_modulo_seleccionado_dashboards         : [],
    rex_data_otros_modulo_seleccionado_dashboards   : [],
    rex_items_menu_slider_global_dashboards         : [],
    rex_registrar_actividad_modulo_dashboards       : 'ACTIVO',
    rex_risid_modulo_seleccionado_dashboards        : 0,
    rex_id_selecionado_modulo_dashboards            : 0,
    rex_driid_selecionado_modulo_dashboards         : 0,
    rex_seleccionar_tipo_modulo_dashboards          : null,
    rex_preload                                     : false,
    rex_items_otros_modulos_dashboards              : [],
    rex_submodule_dashboard                         : null,
    rex_data_dashboard                              : {},
    rex_title_module                                : null,
    rex_title_submodule                             : null,
    rex_title_subsubmodule                          : null,
    token_acceso_power_bi                           : null
};


export default (state = INIT_STATE, action) => {
    switch (action.type) {
        
        case TOKEN_ACCESO_POWER_BI: {
            return {
                ...state,
                token_acceso_power_bi : action.payload,
            }
        }
        case OBTENER_ITEMS_OTROS_MODULOS_DASHBOARDS: {
            return {
                ...state,
                rex_items_otros_modulos_dashboards : action.payload,
            }
        }
        case REGISTRAR_TIPO_MODULO_DASHBOARDS: {
            return {
                ...state,
                rex_seleccionar_tipo_modulo_dashboards : action.payload,
            }
        }
        case REGISTRAR_DRIID_MODULO_DASHBOARDS: {
            return {
                ...state,
                rex_driid_selecionado_modulo_dashboards : action.payload,
            }
        }
        case REGISTRAR_RISID_MODULO_DASHBOARDS: {
            return {
                ...state,
                rex_risid_modulo_seleccionado_dashboards : action.payload,
            }
        }
        case REGISTRAR_ID_MODULO_SELECCIONADO_DASHBOARDS: {
            return {
                ...state,
                rex_id_selecionado_modulo_dashboards : action.payload,
            }
        }
        case REGISTRAR_ACTIVIDAD_MODULO_DASHBOARDS: {
            return {
                ...state,
                rex_registrar_actividad_modulo_dashboards : action.payload,
            }
        }
        case OBTENER_DATA_ITEMS_SLIDER_GLOBAL_DASHBOARDS: {
            return {
                ...state,
                rex_items_menu_slider_global_dashboards : action.payload,
            }
        }
        case OBTENER_DATA_MODULOS_DASHBOARDS_TOTAL: {
            return {
                ...state,
                rex_data_modulos_dashboards_total : action.payload,
            }
        }
        case OBTENER_DATA_MODULOS_DASHBOARDS_FIRST: {
            return {
                ...state,
                rex_data_modulos_dashboards_first : action.payload,
            }
        }
        case OBTENER_DATA_MODULOS_DASHBOARDS_SECOND: {
            return {
                ...state,
                rex_data_modulos_dashboards_second : action.payload,
            }
        }
        case OBTENER_DATA_MODULO_SELECCIONADO_DASHBOARDS: {
            return {
                ...state,
                rex_data_modulo_seleccionado_dashboards : action.payload,
            }
        }
        case OBTENER_DATA_OTROS_MODULO_SELECCIONADO_DASHBOARDS: {
            return {
                ...state,
                rex_data_otros_modulo_seleccionado_dashboards : action.payload,
            }
        }
        case PRELOAD_ANIMATION: {
            return {
                ...state,
                rex_preload : action.payload,
            }
        }
        case GET_SUBMODULE_DASHBOARD: {
            return {
                ...state,
                rex_submodule_dashboard : action.payload,
            }
        }
        case DATA_DASHBOARD: {
            return {
                ...state,
                rex_data_dashboard : action.payload,
            }
        }
        case TITLE_MODULE: {
            return {
                ...state,
                rex_title_module : action.payload,
            }
        }
        case TITLE_SUBMODULE: {
            return {
                ...state,
                rex_title_submodule : action.payload,
            }
        }
        case TITLE_SUBSUBMODULE: {
            return {
                ...state,
                rex_title_subsubmodule : action.payload,
            }
        }
        default:
            return state;
    }
}
