import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Route, Routes, Navigate, useLocation } from "react-router-dom"
import Login from './Login/Login'
import LoginGrow from './Login/LoginGrow'
import RecoverPassword from './Login/RecoverPassword'
import Home from './Home/Home'
import DownloadData from './DownloadData/DownloadData'
import Status from './Status/Status'
import Modules from './Modules/Modules'
import NewModules from './Modules/NewModules'
import Perfil from './Perfil/Perfil'
import TopMenu from './Menu/TopMenu'
import ProfileTypes from './ProfileTypes/ProfileTypes'
import ProfileTypeSelect from './ProfileTypes/ProfileTypeSelect'
import HomeAdministrator from './HomeAdministrator/HomeAdministrator'
import FileUpload from './FileUpload/FileUpload'
import Dashboards from './Dashboards/Dashboards'
import Analytics from './Analytics/Analytics'
import CreateProfile from './CreateProfile/CreateProfile'
import Users from './Users/Users'
import Top from '../Components/Menu/Top'
import TermsConditions from './TermsConditions/TermsConditions'
import CreateUser from './CreateUser/CreateUser'
import DataControl from './DataControl/DataControl'
import CreateDataControl from './DataControl/CreateDataControl'
import UploadedFiles from './UploadedFiles/UploadedFiles'
import UserAudit from './Audit/UserAudit'
import EditUser from './Users/EditUser.js'
import Footer from './Footer/Footer'
import EditProfile from '../Components/ProfileTypes/EditProfile'
import ModulesHome from './Home/Modules'
import HomeModules from './HomeModules/HomeModules'
import ClientsExecutives from './ClientsExecutives/ClientsExecutives'
import Preload from '../Components/Preload/Preload'
import PreloadLogin from '../Components/Preload/PreloadLogin'
import PowerBi from './PowerBi/PowerBi'
import '../Styles/PostCss/index.css'
import { ActivarVozReducer } from '../Redux/Actions/Dashboards/Dashboards'
import MasterClients from '../Components/Manage/MasterClients/MasterClients'
import MasterProducts from '../Components/Manage/MasterProducts/MasterProducts'
import CreateEditExecutives from './ClientsExecutives/CreateEditExecutives'
import DownloadDataInternal from './DownloadDataInternal/DownloadDataInternal'
import MSL from '../Components/Manage/MSL/MSL'
import IncentivProgram from './IncentivProgram/IncentivProgram'
import ChannelsExecutives from './ChannelsExecutives/ChannelsExecutives'
import FormMasterProduct from '../Components/Manage/MasterProducts/FormMasterProduct'
import FormMasterClient from '../Components/Manage/MasterClients/FormMasterClient'
import FormChannelsExecutives from '../Components/ChannelsExecutives/FormChannelsExecutives'
import EditDataControl from './DataControl/EditDataControl'
import FileControl from './FileControl/FileControl'
import RestorePassword from './Login/RestorePassword'
import ModalTermsConditions from '../Components/Global/ModalTermsConditions'
import inteligenciaartificial from '../Assets/Images/Home/logoGrow.png'
import IaPlus from './IaPlus/IaPlus'

const Index = ({setTimeScroll}) => {

    const ref_redirect = useRef(null)

    let location = useLocation()

    let routesUnauthorized = ['/login', '/login-grow', '/recover-password']
    const dispatch = useDispatch()

    const { 
        rex_data_user_logingrow
    } = useSelector(({loginGrow}) => loginGrow)

    setTimeout(() => {
        setTimeScroll(true)
    }, 4500)

    const validateToken = () => {
        return !localStorage.getItem('usutoken') ? false : true
    }

    useEffect(() => {
        dispatch(ActivarVozReducer())
    }, [])

    return (
        <div
            style={{
                position:'relative',
                minHeight: '100%',
                display: 'grid',
                gridTemplateRows: 'auto 1fr auto',
                gridTemplateColumns: '100%'
            }}
        >
            {
                validateToken() ?
                !routesUnauthorized.includes(location['pathname']) && 
                <>
                <Preload />
                <Top buttonBack={location['pathname'] == '/home' ? false : true}/>
                </>
                : location['pathname'] == '/login' || location['pathname'] == '/login-grow'
                    ? <PreloadLogin /> : null
            }

            <Routes>
                <Route path="*" element={<Navigate replace to="/login" />} />
                <Route exact path='/login' element={validateToken() ? <Navigate replace to="/home" /> :  <Login />}/>
                <Route exact path='/login-grow' element={validateToken() ? <Navigate replace to="/home" /> : <LoginGrow /> }></Route>
                <Route exact path='/recover-password' element={ validateToken() ? <Navigate replace to="/home" />  : <RecoverPassword /> }></Route>
                <Route exact path='/restore-password/:token/:id' element={ validateToken() ? <Navigate replace to="/home" />  : <RestorePassword /> }></Route>
                {validateToken() &&
                    <>
                        <Route exact path='/home' element={<Home />}></Route>
                        <Route exact path='/home-modules' element={<HomeModules />}></Route>
                        
                        <Route exact path='/profile' element={<Perfil />}></Route>
                        <Route exact path='/ia/iaplus' element={<IaPlus />}></Route>
                        <Route exact path='/terms-conditions' element={<TermsConditions />}></Route>
                        <Route exact path='/download-data' element={<DownloadData />}></Route>
                        <Route exact path='/file-upload' element={<FileUpload />}></Route>
                        <Route exact path='/dashboards/modulo/:id/:route' element={<Dashboards />}></Route>
                        <Route exact path='/dashboards/modulo/:id/submodulo/:idsub/:route' element={<Dashboards />}></Route>
                        {/* <Route exact path='/dashboards/modulo/:id/:route' element={<Dashboards />}></Route>
                        <Route exact path='/dashboards/modulo/:id/submodulo/:idsub/:route' element={<Dashboards />}></Route> */}
                        <Route exact path='/analytics' element={<Analytics />}></Route>
                        <Route exact path='/status' element={<Status />}></Route>
                        {/* <Route exact path='/power-bi' element={<PowerBi />}></Route> */}


                        {/* ****************************************************************************************************************** */}
                        {/* ****************************************
                            1. MODULOS ADMINISTRATIVOS
                            *****************************************/}
                        <Route exact path='/home-administrator' element={<HomeAdministrator />}></Route>
                        
                        {/* ****************************************
                            1.1. MOD ADM. MODULOS
                            *****************************************/}
                        <Route exact path='/administrator/modules' element={<Modules />}></Route>
                        <Route exact path='/administrator/modules/create-modules' element={<NewModules />}></Route>
                        <Route exact path='/administrator/modules/edit-modules/:id?' element={<NewModules />}></Route>

                        {/* ****************************************
                            1.2. MOD ADM. USUARIOS
                            *****************************************/}
                        <Route exact path='/administrator/users' element={<Users />}></Route>
                        <Route exact path='/administrator/users/edit-user' element={<EditUser />}></Route>
                        <Route exact path='/administrator/users/create-user' element={<CreateUser />}></Route>

                        {/* ****************************************
                            1.3. MOD ADM. TIPOS USUARIOS OJO CON PROFILE-TYPE-SELECT Y EDIT-PROFILE
                            *****************************************/}
                        <Route exact path='/administrator/profile-types' element={<ProfileTypes />}></Route>
                        <Route exact path='/administrator/create-profile' element={<CreateProfile />}></Route>
                        <Route exact path='/profile-type-select' element={<ProfileTypeSelect />}></Route> {/* OJO */}
                        <Route exact path='/administrator/edit-profile/:id' element={<EditProfile />}></Route> {/* OJO */}

                        {/* ****************************************
                            1.4. MOD ADM. CONTROL DATA (ARCHIVOS PARA DESCARGAR)
                            *****************************************/}
                        <Route exact path='/administrator/data-control' element={<DataControl />}></Route>
                        <Route exact path='/administrator/create-data-control' element={<CreateDataControl />}></Route>                        
                        <Route exact path='/administrator/edit-data-control/:id' element={<EditDataControl />}></Route>                        
                        
                        {/* ****************************************
                            1.5. MOD ADM. AUDITORIAS
                            **************************************** */}
                        <Route exact path='/user-audit' element={<UserAudit />}></Route>
                        
                        {/* ****************************************
                            1.6. MOD ADM. ARCHIVOS SUBIDOS
                            **************************************** */}
                        <Route exact path='/uploaded-files' element={<UploadedFiles/>}></Route>

                        {/* ****************************************
                            1.7. MOD ADM. CONTROL DE ARCHIVOS
                            **************************************** */}
                        <Route exact path='/administrator/file-control' element={<FileControl/>}></Route>
                        
                        {/* ****************************************************************************************************************** */}

                        {/* ****************************************
                            2. MODULOS PARA PROYECTO INCENTIVOS
                            *****************************************/}
                        {/* ****************************************
                            2.1. MOD. INCENT. DESCARGAS INTERNAS
                            *****************************************/}
                        <Route exact path='/download-data-internal' element={<DownloadDataInternal />}></Route>
                        
                        {/* ****************************************
                            2.2. MOD. INCENT. COMMERCIAL TEAM (ADM. EJECUTIVOS CLIENTES)
                            *****************************************/}
                        <Route exact path='/clients-executives' element={<ClientsExecutives />}></Route>
                        <Route exact path='/channels-executives' element={<ChannelsExecutives />}></Route>
                        <Route exact path='/create-channels-executives' element={<FormChannelsExecutives />}></Route>
                        <Route exact path='/edit-channels-executives/:id' element={<FormChannelsExecutives />}></Route>
                        <Route exact path='/create-clients-executives' element={<CreateEditExecutives />}></Route>
                        <Route exact path='/edit-clients-executives/:id' element={<CreateEditExecutives />}></Route>

                        {/* ****************************************
                            2.3. MOD. INCENT. PRODUCTOS FOCO
                            **************************************** */}
                        <Route exact path='/master-products' element={<MSL/>}></Route>

                        {/* ****************************************
                            2.4. MOD. INCENT. MASTERS
                            **************************************** */}
                        <Route exact path='/master-clients' element={<MasterClients/>}></Route>
                        {/* <Route exact path='master-products' element={<MasterProducts/>}></Route> */}
                        <Route exact path='/edit/master-product/:id' element={<FormMasterProduct/>}></Route>
                        <Route exact path='/create/master-product' element={<FormMasterProduct/>}></Route>
                        <Route exact path='/create/master-client' element={<FormMasterClient/>}></Route>
                        <Route exact path='/edit/master-client/:id' element={<FormMasterClient/>}></Route>

                        {/* ****************************************
                            2.4. MOD. INCENT. PROGRAM
                            **************************************** */}
                        <Route exact path='/incentive-program' element={<IncentivProgram/>}></Route>

                        
                        {/* ****************************************
                            MODULOS CREADOS PARA TESTEO
                            **************************************** */}
                        <Route exact path='/modules-home' element={<ModulesHome/>}></Route>
                        <Route exact path='/test' element={<TopMenu />}></Route>
                    </>
                }
            </Routes>
            
            {
                validateToken() &&
                !routesUnauthorized.includes(location['pathname']) && 
                <Footer />
            }

            {
                validateToken() 
                ? <>
            <div
                className='Consultar-Soporte-Intrologin'
                onClick={() => [
                    ref_redirect.current.click()
                ]}
            >
                <div className='Nube-Contactar-Soporte' >
                    Chat with us
                </div>

                <div >
                    <img className='Icono-Soporte' src={inteligenciaartificial} />
                </div>
            </div>

            <a
                ref={ref_redirect} 
                href="https://api.whatsapp.com/send?phone=51951331671" target="_blank" style={{display:'none'}}>
            </a>
                </>
                : null
            }


            {/* {
                validateToken() && rex_data_user_logingrow.usuaceptoterminos === null 
                ?<ModalTermsConditions/>
                : null
            } */}
        </div>
    )
}

export default Index