import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Checkbox, Form, Collapse, Row, Col } from 'antd'
import { SelectedModuleSpecificAllReducer, SelectedModulesAllReducer, SelectedSubModuleSpecificAllReducer, SelectedSubSubModuleSpecificAllReducer } from '../../Redux/Actions/ProfileTypes/ProfileTypes'

const ProfileTypesSelectModules = () => {

    const { Panel } = Collapse   

    const {
        rex_personalizar_tipo_perfil,
        rex_data_modules_profile_clone,
        rex_check_all_module_country,
        rex_selected_country_profile_type,
    } = useSelector(({profileTypes}) => profileTypes)

    const dispatch = useDispatch()
    
    const HeaderCollapse = (type, text, id, checkbox, modulo_id) => (
        <div className={
            `Header-Colapse-Create-Profile 
            ${type == "module" 
                ? checkbox ? 'Check-Active-Module' : '' 
                : checkbox ? 'Check-Active-Submodule' : ''}
        `}>
            <Checkbox
                onClick={(e) => {
                    e.stopPropagation()
                    if(type == "module"){
                        dispatch(SelectedModuleSpecificAllReducer(id, e.target.checked, rex_selected_country_profile_type))
                    }else{
                        dispatch(SelectedSubModuleSpecificAllReducer(id, e.target.checked, modulo_id, rex_selected_country_profile_type))
                    }
                }}
                checked={checkbox}
            ></Checkbox>
            <span className='Text-Header-Collapse-Create-Profile'>{text}</span>
        </div>
    )

    return (
        <Form.Item className="Form-Item-Modules-Profile-Types">
            <div className={`${!rex_personalizar_tipo_perfil ? 'Container-Disabled-Profile-Types' : ''}`}></div>
            <Checkbox
                onChange={(e) => dispatch(SelectedModulesAllReducer(e.target.checked, rex_selected_country_profile_type))}
                className='Checkbox-SelectAll-Select-Module'
                checked={
                    rex_check_all_module_country.find(cou => cou == rex_selected_country_profile_type ? true : false)
                }
            >
                Seleccionar todos los módulos
            </Checkbox>
            <Row>
                <Col span={24}>
                    
                    {
                        rex_selected_country_profile_type
                        ? rex_data_modules_profile_clone.map((module, index) => {
                            let cantidad_modules = 0
                            const filter_modules_clone = rex_data_modules_profile_clone.filter(mod_pro => mod_pro.paiid == rex_selected_country_profile_type).filter(mod_pro => {
                                if(cantidad_modules <= 5){
                                    cantidad_modules = cantidad_modules + 1
                                    return true
                                }
                                return false
                            })
                            return(
                                filter_modules_clone.find(mod_pro => mod_pro.key == module.key)
                                ?   <Collapse 
                                        className={`Collapse-Create-Profile ${module.smosubmodulos.length == 0 ? 'Icon-Hide-Profile' : ''}`}
                                        expandIconPosition="end" 
                                        expandIcon={() => (<div className={`Icon-Arrow-Profile ${!module.mod_checked ? 'Module-Active-Icon-Arrow' : ''}`}></div>)}
                                        key={index}
                                    >
                                        <Panel header={HeaderCollapse("module", module.modnombre, module.key, module.mod_checked)}>
                                            {
                                                module.smosubmodulos.map((submodule, indexsmo) => 
                                                    submodule.ssmsubmodulos.length == 0
                                                    ? <Checkbox 
                                                        className={`Submodule-Item ${submodule.submod_checked ? 'Check-Active-Submodule' : ''}`}
                                                        key={indexsmo}
                                                        checked={submodule.submod_checked}
                                                        onClick={(e) => dispatch(SelectedSubModuleSpecificAllReducer(submodule.smoid, e.target.checked, module.key, rex_selected_country_profile_type))}
                                                    >
                                                        {submodule.smonombre}
                                                    </Checkbox>
                                                    : <Collapse 
                                                            className='Collapse-Submodule-Profile Submodule-Profile' 
                                                            expandIconPosition="end" 
                                                            expandIcon={() => (<div className={`Icon-Arrow-Profile ${!submodule.submod_checked ? 'Submodule-Active-Icon-Arrow' : ''}`}></div>)}
                                                            key={indexsmo}
                                                        >
                                                            <Panel header={HeaderCollapse("submodule", submodule.smonombre, submodule.smoid, submodule.submod_checked, module.key)}>
                                                                {
                                                                    submodule.ssmsubmodulos.map((subsubmodule, indexssm) => <Checkbox 
                                                                        className={`Subsubmodule-Item ${subsubmodule.subsubmod_checked ? 'Check-Active-Subsubmodule' : ''}`} 
                                                                        key={indexssm}
                                                                        checked={subsubmodule.subsubmod_checked}
                                                                        onClick={(e) => dispatch(SelectedSubSubModuleSpecificAllReducer(subsubmodule.ssmid, e.target.checked, module.key, submodule.smoid, rex_selected_country_profile_type))}
                                                                    >
                                                                        {subsubmodule.ssmnombre}
                                                                    </Checkbox>)
                                                                }
                                                            </Panel>
                                                    </Collapse>
                                                )
                                            }
                                        </Panel>
                                    </Collapse>
                                : null
                            )
                        })
                        : rex_data_modules_profile_clone.map((module, index) => {
                            let cantidad_modules = 0
                            const filter_modules_clone = rex_data_modules_profile_clone.filter(mod_pro => mod_pro.paiid == rex_data_modules_profile_clone[0]['paiid']).filter(mod_pro => {
                                if(cantidad_modules <= 5){
                                    cantidad_modules = cantidad_modules + 1
                                    return true
                                }
                                return false
                            })
                            return(
                                filter_modules_clone.find(mod_pro => mod_pro.key == module.key)
                                && <div className="Collapse-Disabled-Profile">
                                    <Collapse 
                                        className={`Collapse-Create-Profile ${module.smosubmodulos.length == 0 ? 'Icon-Hide-Profile' : ''}`}
                                        expandIconPosition="end" 
                                        expandIcon={() => <div className="Icon-Arrow-Profile Disabled-Icon-Arrow"></div>}
                                        key={index}
                                        collapsible='disabled'
                                    >
                                        <Panel header={HeaderCollapse("module", module.modnombre, module.key, module.mod_checked)} collapsible='disabled' />
                                    </Collapse>
                                </div>
                            )
                        })
                    }
                </Col>
            </Row>
        </Form.Item>
    )
}
    
export default ProfileTypesSelectModules