export const GET_DATA_MASTER_CLIENTS_MANAGE                 = 'GET_DATA_MASTER_CLIENTS_MANAGE'
export const GET_DATA_MASTER_CLIENTS_MANUALS_MANAGE         = 'GET_DATA_MASTER_CLIENTS_MANUALS_MANAGE'
export const LOADING_GET_DATA_MASTER_CLIENTS_MANAGE         = 'LOADING_GET_DATA_MASTER_CLIENTS_MANAGE'
export const LOADING_GET_DATA_MASTER_CLIENTS_MANUALS_MANAGE = 'LOADING_GET_DATA_MASTER_CLIENTS_MANUALS_MANAGE'
export const GET_DATA_MASTER_CLIENT_MANAGE                  = 'GET_DATA_MASTER_CLIENT_MANAGE'
export const GET_DATA_MASTER_CLIENTS_MANAGE_CLONE           = 'GET_DATA_MASTER_CLIENTS_MANAGE_CLONE'
export const GET_DATA_FILTERS_TXT_CLIENTS_TOTAL             = 'GET_DATA_FILTERS_TXT_CLIENTS_TOTAL'
export const DATA_FORM_EDIT_MASTER_CLIENT                   = 'DATA_FORM_EDIT_MASTER_CLIENT'
export const DATA_FORM_EDIT_MASTER_CLIENT_MANUAL            = 'DATA_FORM_EDIT_MASTER_CLIENT_MANUAL'
export const SHOW_MODAL_ALERT_DELETE_CLIENTS                = 'SHOW_MODAL_ALERT_DELETE_CLIENTS'
export const DATA_ALERT_DELETE_CLIENTS                      = 'DATA_ALERT_DELETE_CLIENTS'
export const DATE_LAST_UPDATED_CLIENTS_MANAGE               = 'DATE_LAST_UPDATED_CLIENTS_MANAGE'

export const DATA_FILTER_DATE_MASTER_CLIENTS                = 'DATA_FILTER_DATE_MASTER_CLIENTS'
export const DATA_FILTER_DATE_MASTER_CLIENTS_MANUAL         = 'DATA_FILTER_DATE_MASTER_CLIENTS_MANUAL'