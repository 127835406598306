import React, { useEffect } from 'react'
import { useDispatch, useSelector} from 'react-redux'
import { useNavigate, useParams  } from "react-router-dom"
import dayjs from 'dayjs'
import {
    CreateClientExecutiveReducer,
    EditClientExecutiveReducer,
    FieldFormClientExecutiveReducer,
    GetDataEditClientExecutive,
    GetDataMasterClientsReducer,
} from '../../Redux/Actions/ClientsExecutives/ClientsExecutives'
import { Row, Col, Form, Input, DatePicker, Select } from 'antd'
import ArrowDownCollapse from '../../Assets/Images/CreateProfile/ArrowDownCollapse'
import '../../Styles/Components/Users/FormCreateUser.css'

const FormCreateEditExecutives = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const { id } = useParams()
    const [form] = Form.useForm()

    const {
        rex_data_master_clients_executives,
        rex_data_client_executive,
    } = useSelector(({clientsExecutives}) => clientsExecutives)

    const onFinish = async ()  => {
        let respuesta
        if(id){
            respuesta = await dispatch(EditClientExecutiveReducer())
        }else{
            respuesta = await dispatch(CreateClientExecutiveReducer())
        }
        if(respuesta){
            navigate(`/clients-executives`)
        }
    }

    useEffect(() => {
        fetchData()
    },[])

    const fetchData = async () => {
        dispatch(GetDataMasterClientsReducer())
        if(id){
            const data_client_executive = await dispatch(GetDataEditClientExecutive(id))
            editFormField(data_client_executive)
        }
    }

    const editFormField = (data_client_executive) => {
        const dateFormat = dayjs(`${data_client_executive.mesanio}`, 'MM-YYYY')
        form.setFieldsValue({
            canal       : data_client_executive.canal,
            ejecutivo   : data_client_executive.ejecutivo,
            gerente     : data_client_executive.gerente,
            cliente     : data_client_executive.cod_cliente,
            fecha       : dateFormat,
        })
    }
    
    return (
        <>
            <Form
                layout='vertical'
                autoComplete='off'
                onFinish={() => onFinish()}
                form={form}
                style={{height: '100%'}}
            >
                <Row style={{justifyContent: 'space-between', height: '100%'}}>
                    <Col xl={{span:24}}>
                        <Row>
                            <Col
                                xs={{span:24}} sm={{span:24}} md={{span:24}} lg={{span:12}} xl={{span:12}}
                                className='Form-Create-User-Col-Left'
                            >
                                <Form.Item
                                    label='Canal'
                                    name='canal'
                                    className='Create-User-Form-Item-Text'
                                    rules={[
                                        {required: true, message: 'El campo canal es requerido'},
                                    ]}
                                >
                                    <Input 
                                        placeholder="Canal" 
                                        size='large' 
                                        value={rex_data_client_executive.canal}
                                        onChange={(e) => dispatch(FieldFormClientExecutiveReducer('canal', e.target.value))}
                                    />
                                </Form.Item>
                                <Form.Item
                                    label='Gerente'
                                    name='gerente'
                                    className='Create-User-Form-Item-Text'
                                    rules={[
                                        {required: true, message: 'El campo gerente es requerido'},
                                    ]}
                                >
                                    <Input 
                                        placeholder="Gerente"
                                        size='large'
                                        value={rex_data_client_executive.gerente}
                                        onChange={(e) => dispatch(FieldFormClientExecutiveReducer('gerente', e.target.value))}
                                    />
                                </Form.Item>
                                <Form.Item
                                    label='Fecha'
                                    name='fecha'
                                    className='Create-User-Form-Item-Text'
                                    rules={[
                                        {required: true, message: 'El campo fecha es requerida'},
                                    ]}
                                >
                                    <DatePicker
                                        placeholder='MM/AAAA'
                                        popupClassName='DatePicker-Form-Executives'
                                        size='large'
                                        format='MM/YYYY'
                                        picker="month"
                                        value={
                                            rex_data_client_executive.mesanio ?
                                            dayjs(`${rex_data_client_executive.mesanio}`, 'MM/YYYY')
                                            : ''
                                        }
                                        onChange={(_, date) => dispatch(FieldFormClientExecutiveReducer('fecha', date))}
                                    />
                                </Form.Item>
                            </Col>
                            <Col
                                xs={{span:24}} sm={{span:24}} md={{span:24}} lg={{span:12}} xl={{span:12}}
                                className='Form-Create-User-Col-Left'
                            >
                                <Form.Item
                                    label='Ejecutivo'
                                    name='ejecutivo'
                                    className='Create-User-Form-Item-Text'
                                    rules={[
                                        {required: true, message: 'El campo ejecutivo es requerido'},
                                    ]}
                                >
                                    <Input
                                        placeholder="Ejecutivo"
                                        size='large'
                                        value={rex_data_client_executive.ejecutivo}
                                        onChange={(e) => dispatch(FieldFormClientExecutiveReducer('ejecutivo', e.target.value))}
                                    />
                                </Form.Item>
                                <Form.Item
                                    label='Cliente'
                                    name='cliente'
                                    className='Create-User-Form-Item-Text'
                                    rules={[
                                        {required: true, message: 'El campo cliente es requerido'},
                                    ]}
                                >
                                    <Select
                                        className="Profile-Type-Select-Type-Profile"
                                        size='large'
                                        showSearch
                                        suffixIcon={<ArrowDownCollapse/>}
                                        placeholder="Clientes"
                                        value={rex_data_client_executive.cod_cliente}
                                        onChange={(_, dataclient) => 
                                            dispatch(FieldFormClientExecutiveReducer('cliente', dataclient))
                                        }
                                        filterOption={(input, option) =>
                                            (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
                                        }
                                        bordered={false}
                                        options={rex_data_master_clients_executives.map((mce) => {
                                            return {
                                                value: mce.cod_sold_to,
                                                label: mce.cod_sold_to+" - "+mce.sold_to,
                                            }
                                        })}
                                    />
                                </Form.Item>
                            </Col>
                        </Row>
                    </Col>
                    <Col
                        xs={{span:24}} sm={{span:24}} md={{span:24}} lg={{span:24}} xl={{span:24}}
                        className='Form-Create-User-Col-Right'
                    >
                        <Row style={{justifyContent:'end'}}>
                            <Col className='Create-User-Container-Button-Action'>
                                <button
                                    className='Create-User-Form-Container-Button Create-User-Form-Button-Cancel'
                                    onClick={() => {
                                        navigate('/clients-executives')
                                    }}
                                    type='primary'
                                >
                                    <span>Cancelar</span>
                                    <span className='Text-Span-Cancel-Create-User'>Cancelar</span>
                                </button>
                                <button
                                    className='Create-User-Form-Container-Button Create-User-Form-Button-Save'
                                    type="primary" 
                                    htmlType="submit"
                                >
                                    Guardar
                                </button>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Form>
        </>
    )
}

export default FormCreateEditExecutives