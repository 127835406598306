import {
    SHOW_MODAL_NOTIFICATION_GLOBAL,
    DATA_MODAL_NOTIFICATION_GLOBAL,
    SHOW_MODAL_TERMS_CONTIDITIONS_GLOBAL
} from "../../../Constants/Global/Global"


const INIT_STATE = {
    rex_show_modal_notification_global      : false,
    rex_data_modal_notification_global      : {
        title:"", 
        messageDescription: [], 
        status:""
    },
    rex_show_modal_terms_conditions_global  : false
};


export default (state = INIT_STATE, action) => {
    switch (action.type) {

        case SHOW_MODAL_NOTIFICATION_GLOBAL: {
            return {
                ...state,
                rex_show_modal_notification_global : action.payload,
            }
        }
        case DATA_MODAL_NOTIFICATION_GLOBAL: {
            return {
                ...state,
                rex_data_modal_notification_global : action.payload,
            }
        }
        case SHOW_MODAL_TERMS_CONTIDITIONS_GLOBAL: {
            return {
                ...state,
                rex_show_modal_terms_conditions_global : action.payload,
            }
        }
        default:
            return state;
    }
}


