import React from 'react'
import { useDispatch } from 'react-redux'
import { Link } from "react-router-dom"
import { ChangeOrderSubModulesReducer, ChangeDisabledCollapseModulesReducer, ChangeDisabledStatusModulesReducer, DeleteDataModuleReducer } from '../../../Redux/Actions/Modules/Modules'
import { Collapse, Avatar, Dropdown, Modal, Tooltip } from 'antd'
import { HolderOutlined } from '@ant-design/icons'
import { DndContext, closestCenter } from '@dnd-kit/core'
import { arrayMove, SortableContext, verticalListSortingStrategy, useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import TabListSubModules from './TabListSubModules'
import config from '../../../config'

import ImgCategories from '../../../Assets/Images/Menu/CategoryBlue.png'
import ImgStatus from '../../../Assets/Images/Menu/StatusBlue.png'
import ImgAnalytics from '../../../Assets/Images/Menu/AnalyticBlue.png'
import ImgDownload from '../../../Assets/Images/Menu/DownloadBlue.png'
import ImgFileUpload from '../../../Assets/Images/Menu/UploadBlue.png'
import ImgAdministrador from '../../../Assets/Images/Menu/AdminBlue.png'

const TabListModules = (props) => {
    const module = props.module
    const type = props.type
    const lastSubmodule = module.children && module.children[module.children.length-1]
    const { Panel } = Collapse
    const { confirm } = Modal
    const dispatch = useDispatch()

    const { attributes, listeners, setNodeRef, transform, setActivatorNodeRef, isDragging } = useSortable({id: props.id})

    const items = [
        {
            key: '1',
            label:  <Link to={`/administrator/modules/edit-modules/${module.modid}`}>
                    <span className="Text-Edit-Menu">
                        Editar
                        <div className="Icon-Edit-Menu"></div>
                    </span>
                    </Link>,
        },
        {
            key: '2',
            label:  <span className="Text-Delete-Menu" onClick={() => deleteModule()}>
                        Eliminar 
                        <div className="Icon-Delete-Menu"></div>
                    </span>,
        },
    ]

    const style = {
        transform: CSS.Transform.toString(transform),
    }

    const styleCollapse = {
        boxShadow: isDragging ? '#EFEBE6 0px 2px 0 1px' : ''
    }

    const styleButton = {
        touchAction: 'none',
        cursor: isDragging ? 'grabbing' : 'grab',
    }

    const DragDropSubModule = (event) => {
        const { active, over } = event
        dispatch(ChangeOrderSubModulesReducer(active, over, arrayMove, module.key))
    }

    const deleteModule = () => {
        confirm({
            title: 'Confirmar',
            zIndex: 100000000,
            icon: <></>,
            content: '¿Estas seguro de eliminar el módulo?',
            width: 260,
            centered: true,
            className: 'Modal-Delete-Module',
            okButtonProps: {className: 'Confirm-Modal-Delete-Module'},
            okText: <>
                        <span className="First-Confirm">Confirmar</span>
                        <span className="Last-Confirm">Confirmar</span>
                    </>,
            cancelButtonProps: {className: 'Cancel-Modal-Delete-Module'},
            cancelText: <>
                            <span className="First-Cancel">Cancelar</span>
                            <span className="Last-Cancel">Cancelar</span>
                        </>,
            onOk() {
                dispatch(DeleteDataModuleReducer(module.modid))
            },
            onCancel() {
            },
        })
    }
    
    return(
        <div
            ref={setNodeRef} 
            style={style} 
            {...attributes}
        >
        <Collapse
            className={`Card-Item-Modules ${!module.modvisualizacion ? 'Disabled-Card-Item-Modules' : ''} ${module.children.length == 0 ? 'Icon-Expand-Inabled-Modules' : ''}`}
            expandIconPosition="end"
            collapsible='icon'
            expandIcon={(e) => 
                type == "menu"
                ? null
                : <Tooltip
                    placement='bottom'
                    title={e.isActive ? 'Cerrar' : 'Desplegar'}
                    color='#3B72FF'
                    showArrow={false}
                    overlayStyle={{marginTop:'20px', fontSize: '12px', minHeight: '30px'}}
                >
                    <div 
                        onClick={() => dispatch(ChangeDisabledCollapseModulesReducer(e.isActive, module.key))}
                        className={`Icon-Expand-Item-Modules ${module.disable ? '' : e.isActive ? 'Expand-Rotate-Item-Modules' : ''}`}
                        style={{opacity: !module.children && '0'}}
                    ></div>
                </Tooltip>
            }
            style={styleCollapse}
            activeKey={module.collapse && module.key}
        >
            <Panel 
                header={
                    <>
                        <div className="Container-Text-Item-Modules">
                            <Tooltip
                                placement='bottom'
                                title='Mover'
                                color='#3B72FF'
                                showArrow={false}
                                overlayStyle={{marginTop:'20px', fontSize: '12px', minHeight: '30px'}}
                            >
                                <HolderOutlined 
                                    className="Icon-DragDrop-Item-Modules" 
                                    ref={setActivatorNodeRef} 
                                    style={styleButton} 
                                    {...listeners} 
                                />
                            </Tooltip>
                            <span className="Text-Item-Modules">{module.modnombre}</span>
                        </div>
                        <div className="Container-Icon-Item-Modules">
                            <Avatar 
                                className="Image-Item-Modules" 
                                style={{marginRight: type == "menu" ? '3px' : ''}}
                                src={
                                    module.modicono
                                    ?   <img src={config.api_url+ module.modicono} 
                                    className="Image-Avatar-Item-Modules" />
                                    :   module.modnombre == "Status"
                                        ?<img src={ImgStatus} className="Image-Avatar-Item-Home" />
                                        :module.modnombre == "Analytics"
                                        ?<img src={ImgAnalytics} className="Image-Avatar-Item-Home" />
                                        : module.modnombre == "Download Files"
                                        ?<img src={ImgDownload} className="Image-Avatar-Item-Home" />
                                        : module.modnombre == "File Upload"
                                        ?<img src={ImgFileUpload} className="Image-Avatar-Item-Home" />
                                        : module.modnombre == "Administrador"
                                        ?<img src={ImgAdministrador} className="Image-Avatar-Item-Home" />
                                        :<img src={ImgCategories} className="Image-Avatar-Item-Home" />
                                }
                            />
                            {
                                type == "menu"
                                ? null
                                : !module.modvisualizacion
                                    ?   <Tooltip
                                            placement='bottom'
                                            title='Habilitar'
                                            color='#3B72FF'
                                            showArrow={false}
                                            overlayStyle={{marginTop:'20px', fontSize: '12px', minHeight: '30px'}}
                                        >
                                            <div
                                                onClick={() => dispatch(ChangeDisabledStatusModulesReducer(true, module.key))                                                        
                                                }
                                                className="Icon-View-Disabled-Item-Modules"
                                            ></div>
                                        </Tooltip>
                                    :   <Tooltip
                                            placement='bottom'
                                            title='Deshabilitar'
                                            color='#3B72FF'
                                            showArrow={false}
                                            overlayStyle={{marginTop:'20px', fontSize: '12px', minHeight: '30px'}}
                                        >
                                            <div
                                                onClick={() => dispatch(ChangeDisabledStatusModulesReducer(false, module.key))
                                                }
                                                className="Icon-View-Item-Modules"
                                            ></div>
                                        </Tooltip>
                            }
                        </div>
                    </>
                }
                extra={
                    type == "menu"
                    ? null
                    : <Tooltip
                        placement='bottom'
                        title='Opciones'
                        color='#3B72FF'
                        showArrow={false}
                        overlayStyle={{marginTop:'20px', fontSize: '12px', minHeight: '30px'}}
                    >
                        <Dropdown
                            menu={{items}} 
                            placement="bottomRight"
                            overlayClassName="Menu-Item-Modules"
                            trigger={['click']}
                        >
                            <div className="Icon-Menu-Item-Modules"></div>
                        </Dropdown>
                    </Tooltip>
                }
                key={module.key}
            >
                {
                    module.children &&
                    <DndContext
                        collisionDetection={closestCenter}
                        onDragEnd={DragDropSubModule}
                    >
                        <SortableContext
                            items={module.children && module.children.map(subm => subm.key)}
                            strategy={verticalListSortingStrategy}
                        >
                            {
                                module.children.map(subm => 
                                    <TabListSubModules 
                                        id={subm.key} 
                                        submodule={subm}
                                        key={subm.key}
                                        moduleId={props.id} 
                                        lastSubmodule={lastSubmodule}
                                        idModule={module.modid}
                                    />
                                )
                            }
                        </SortableContext>
                    </DndContext>
                }
            </Panel>
        </Collapse>
        </div>
    )
}

export default TabListModules