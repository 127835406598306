import React, { useState } from "react"
import { useSelector } from "react-redux"
import {
    ComposedChart,
    Line,
    Bar,
    XAxis,
    YAxis,
    CartesianGrid,
    Tooltip,
    Legend,
    ResponsiveContainer,
    LabelList,
    Cell,
} from 'recharts'

const EstadisticaGrafica = () => {
    
    const {
        rex_data_daily_grafico,
    } = useSelector(({daily}) => daily)

    const [activeIndex, setActiveIndex] = useState(null)
    const [mouseMove, setMouseMove] = useState(true)
    const [tooltipView, setTooltipView] = useState(false)

    const tooltipDisenio = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
            const pvValor = Intl.NumberFormat('de-DE').format(payload[0].payload.m_actual)
            const uvValor = Intl.NumberFormat('de-DE').format(payload[1].payload.m_anterior)
            const amtValor = Intl.NumberFormat('de-DE').format(payload[2].payload.a_anterior)
            return (
                <div className="custom-tooltip" style={{opacity: !tooltipView && 0}}>
                    <div className="tooltipContenedorIn">
                        <p>Dia Actual</p>
                        <p>{label}</p>
                    </div>
                    <div className="tooltipContenedorIn">
                        <p>Venta Actual</p>
                        <p>{pvValor}</p>
                    </div>
                    <div className="tooltipContenedorIn">
                        <p>Mes Anterior (Mismo # Día)</p>
                        <p>{uvValor}</p>
                    </div>
                    <div className="tooltipContenedorIn">
                        <p>Año Anterior (Mismo # Día)</p>
                        <p>{amtValor}</p>
                    </div>
                </div>
            )
        }
        return null
    }

    const valorGraficoLineasMes = (props) => {
        // La variable X representa el eje X y la variable Y representa el eje Y
        const { x, y, value } = props
        const valorDatos = Intl.NumberFormat('de-DE').format(value)
        const valueString = value.toString()
        const valueLength = valueString.length
        return (
            <g 
                font-size="10" 
                onMouseOver={() => setTooltipView(true)}
                onMouseOut={() => setTooltipView(false)}
            >
                {
                    value !== 0
                        && 
                        <>
                         <rect x={valueLength <= 3 ? x - 15 : x - 20} y={y + 5} width={valueLength <= 3 ? "30" : "37"} height="20" rx="3" fill="#EA67FF" filter="drop-shadow(0px 4px 4px #D9D9D9)" />
                        <text x={valueLength <= 3 ? x - 11 : x - 15} y={y + 19} fill="#fff">{valorDatos}</text>
                        </>
                }
            </g>
        )
    }

    const valorGraficoLineasAnio = (props) => {
        // La variable X representa el eje X y la variable Y representa el eje Y
        const { x, y, value } = props
        const valorDatos = Intl.NumberFormat('de-DE').format(value)
        const valueString = value.toString()
        const valueLength = valueString.length
        return (
            <g 
                font-size="10" 
                onMouseOver={() => setTooltipView(true)}
                onMouseOut={() => setTooltipView(false)}
            >
                {
                    value !== 0
                        &&
                        <>
                        <rect x={valueLength <= 3 ? x - 15 : x - 13} y={y - 27} width={valueLength <= 3 ? "30" : "37"} height="20" rx="3" fill="#3B72FF" filter="drop-shadow(0px 4px 4px #D9D9D9)" />
                        <text x={valueLength <= 3 ? x - 10 : x - 9} y={y - 14} fill="#fff">{valorDatos}</text>
                        </>
                }
            </g>
        )
    }

    const valorGraficoBarras = (props) => {
        // La variable X representa el eje X y la variable Y representa el eje Y
        const { x, y, value } = props
        const valorDatos = Intl.NumberFormat('de-DE').format(value)
        const valueString = value.toString()
        const valueLength = valueString.length
        return (
            <g 
                font-size="10"
                onMouseOver={() => setTooltipView(true)}
                onMouseOut={() => setTooltipView(false)}
            >
                {
                    value !== 0
                        &&  
                        <>
                        <rect x={valueLength <= 3 ? x : x - 3} y={value == 0 ? y - 24 : 430} width={valueLength <= 3 ? "30" : "37"} height="20" rx="3" fill="#61cca5" filter="drop-shadow(0px 4px 4px #D9D9D9)" />
                        <text x={valueLength <= 3 ? x + 5 : x} y={value == 0 ? y - 10 : 444} fill="#fff">{valorDatos}</text>
                        </>
                }
            </g>
        )
    }

    const cambiarColorBarra = (props, i) => {
        if(i >= 0){
            setActiveIndex(i)
            setMouseMove(true)
        }
        setTooltipView(true)
    }

    const colorOriginal = (over) => {
        if(over){
            setMouseMove(false)
        }
        setTooltipView(false)
    }

    const barraEjeX = (props, data) => {
        // La variable X representa el eje X y la variable Y representa el eje Y
        const { x, y, payload, index } = props
        const { value } = payload
        const feriado = data[index]
        const valorSeparado = value.split(' ')
        return (
            <g transform={`translate(${x},${y})`}>
                <text x={0} y={0} dy={16} fill={
                    feriado.feriado
                    ?   "#efb03b"
                    :   "#013A81"
                }>
                    <tspan textAnchor="middle" x="0" dy="13" fontFamily="Inter">
                        {valorSeparado[0]}
                    </tspan>
                    <tspan textAnchor="middle" x="0" dy="17" fontFamily="Inter">
                        {valorSeparado[1]}
                    </tspan>
                </text>
            </g>
        )
    }

    const barraEjeY = (props) => {
        // La variable X representa el eje X y la variable Y representa el eje Y
        const { x, y, payload } = props
        const { value } = payload
        const valorBarraEjeY = Intl.NumberFormat('de-DE').format(value)
        return (
            <g transform={`translate(${x - 30},${valorBarraEjeY == 0 ? y - 22 : y-15})`}>
                <text x={0} y={0} dy={16} fill="#3B72FF">
                    <tspan textAnchor="middle" x="-10" dy="12" fontFamily="Inter" font-weight="bold">
                        {valorBarraEjeY}
                    </tspan>
                </text>
            </g>
        )
    }

    return(
        <div className="estadisticaGrafica">
            <ResponsiveContainer width="100%" minWidth={900} height={550}>
                <ComposedChart
                    data={rex_data_daily_grafico}
                    margin={{
                        top: 35,
                        right: 50,
                        bottom: 60,
                        left: 50,
                    }}
                >
                    <defs>
                        <linearGradient id="colorUv"  gradientTransform="rotate(90)">
                            <stop offset="0%" stopColor="#C9F5E5" />
                            <stop offset="100%" stopColor="#EFFFC6" />
                        </linearGradient>
                    </defs>

                    <defs>
                        <linearGradient id="colorUvWeekend"  gradientTransform="rotate(90)">
                            <stop offset="0%" stopColor="#f8d18a" />
                            <stop offset="100%" stopColor="#EFFFC6" />
                        </linearGradient>
                    </defs>

                    <CartesianGrid stroke="#EFEBE6" />
                    <XAxis 
                        dataKey="name"
                        interval={0} 
                        tick={(props) => barraEjeX(props, rex_data_daily_grafico)}
                    />
                    <YAxis 
                        domain={[0, 'auto']} 
                        tick={barraEjeY}
                        tickSize={15} 
                    />
                    <Tooltip 
                        content={(content) => {
                            return (tooltipDisenio(content))
                        }}
                    />
                    <Legend 
                        verticalAlign="top" 
                        wrapperStyle={{left: '33px', top: '15px'}} 
                        height={26}
                    />
                    <Bar 
                        dataKey="m_actual" 
                        barSize={30} 
                        fill="url(#colorUv)"
                        name={<span className="etiquetaNombre">Ventas Actual</span>}
                        legendType="circle"
                        onMouseOver={cambiarColorBarra}
                        onMouseOut={() => colorOriginal("salir")}
                        radius={[8, 8, 0, 0]}
                    >
                        <LabelList 
                            content={valorGraficoBarras} 
                            dataKey="m_actual" 
                            position="top" 
                        />
                        {rex_data_daily_grafico.map((entry, index) => 
                            <Cell 
                                fill={
                                    index == activeIndex && mouseMove
                                        ?   entry.feriado
                                            ?   "#f8d18a"
                                            :   "#C9F5E5"
                                        :   entry.feriado
                                                ?   "url(#colorUvWeekend)"
                                                :   "url(#colorUv)"
                                }
                                key={`cell-${index}`}
                            />
                        )}
                    </Bar>
                    <Line 
                        type="step"
                        dataKey="m_anterior" 
                        stroke="#EA67FF" 
                        fill="#ffffff"
                        legendType="circle"
                        strokeMiterlimit={50}
                        name={<span className="etiquetaNombre">Mes Anterior (Mismo # Día)</span>}
                    >
                        <LabelList 
                            content={valorGraficoLineasMes} 
                            dataKey="m_anterior" 
                            position="bottom" 
                        />
                    </Line>
                    <Line 
                        type="step" 
                        dataKey="a_anterior" 
                        stroke="#3B72FF" 
                        fill="#ffffff"
                        legendType="circle"
                        strokeDasharray="5 3"
                        name={<span className="etiquetaNombre">Año Anterior (Mismo # Día)</span>}
                    >
                        <LabelList 
                            content={valorGraficoLineasAnio} 
                            dataKey="a_anterior" 
                            position="bottom" 
                        />
                    </Line>
                </ComposedChart>
            </ResponsiveContainer>
        </div>
    )
}

export default EstadisticaGrafica