import React, { useState } from 'react'
import { Link } from "react-router-dom"
import { Row, Col, Typography, Button, Avatar, Select } from 'antd'
import PeruIcono from '../../Assets/Images/Login/peru.png'
import Google from '../../Assets/Images/Login/google.png'
import GrowLogo from '../../Assets/Images/components/Login/GrowLogo'
import AzureLogo from '../../Assets/Images/components/Login/AzureLogo'

const Login = () => {

    const { Title } = Typography
    const { Option } = Select

    const [ iconGrowHover, setIconGrowHover ]   = useState(false)
    const [ iconAzureHover, setIconAzureHover ] = useState(false)

    return (
        <>
        <Row className="Container-Image-Gif">
            <Col lg={15} md={0} xs={0} className="Col-Image-Login"></Col>
            <Col lg={9} md={24} xs={24} className="Col-Form-Login">
                <div className="Container-Login">
                    <div className="Container-Filter">
                        
                        <Select
                            className="Filter-Country"
                            defaultValue="Peru"
                        >
                            <Option value="Peru"><img src={PeruIcono} className="Image-Filter-Login" />Peru</Option>
                        </Select>
                    </div>
                    <div className="Image-Login"></div>
                    <Title 
                        level={2}
                        className="Title-Login"
                    >
                        Inicia Sesión
                    </Title>
                    <Link to="/login-grow" style={{marginBottom: '15px'}}>
                        <button 
                            onMouseEnter={() => setIconGrowHover(true)}
                            onMouseLeave={() => setIconGrowHover(false)}
                            className="Button-Grow-Login"
                        >
                            <Avatar 
                                icon={<GrowLogo color={iconGrowHover ? "#3B72FF": "#2AD295"}/>}
                                className="Avatar-Button-Grow-Login" 
                            />
                            <span className="Text-Span">Iniciar con Grow</span>
                            <span className="Text-Span-Grow-Login">Iniciar con Grow</span>
                            <div className='Circle-Button-Grow-Login'></div>
                        </button>
                    </Link>
                    {/* <div className="Container-Or">
                        <p className="Text-Or">Or</p>
                    </div> */}
                    <Button className="Button-Google-Login">
                        <Avatar 
                            icon={<img src={Google} className="Icon-Button-Google-Login" />}
                            className="Avatar-Button-Google-Login" 
                        />Iniciar con Google
                        <div className='Circle-Button-Google-Login'></div>
                    </Button>
                    <Button 
                        onMouseEnter={() => setIconAzureHover(true)}
                        onMouseLeave={() => setIconAzureHover(false)}
                        className="Button-Azure-Login"
                    >
                        <Avatar 
                            icon={<AzureLogo color={iconAzureHover ? "#1B2143" : "#013A81"}/>}
                            className="Avatar-Button-Azure-Login" 
                        />Iniciar con Azure
                        <div className='Circle-Button-Azure-Login'></div>
                    </Button>
                    <p className="Copyright-Login">© 2022 GROW ANALYTICS</p>
                </div>
            </Col>
        </Row>
        </>        
    )
}

export default Login