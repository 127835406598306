import React, { useState } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Typography, Checkbox, Spin } from 'antd'
import {
    ObtenerDataTablaReducer,
} from '../../../Redux/Actions/PowerBi/PowerBi'
import {
    FilterFilled,
    LoadingOutlined
} from '@ant-design/icons'

import { Picky } from "react-picky-with-clear"
import 'react-picky-with-clear/dist/picky.css'
import FlechaDesplegar from '../../../Assets/Images/PowerBi/FlechaDesplegar.png'
import { SeleccionarValorFiltroReducer, SelectFilterDailyReducer } from "../../../Redux/Actions/PowerBi/PowerBiFiltros"

const SelectCheckBox = (props) => {
    const titulo = props.titulo
    const data = props.data
    const seleccionado = props.seleccionado
    const placeholder = props.placeholder
    const manySelectedPlaceholder = props.manySelectedPlaceholder
    const allSelectedPlaceholder = props.allSelectedPlaceholder
    const selectAllText = props.selectAllText
    const filterPlaceholder = props.filterPlaceholder
    const numberDisplayed = props.numberDisplayed
    const labelKey = props.labelKey
    const valueKey = props.valueKey
    const multiple = props.multiple
    const clearFilterOnClose = props.clearFilterOnClose
    const includeFilter = props.includeFilter
    const includeSelectAll = props.includeSelectAll
    const height = props.height
    const dataLength = data.length
    const dataReducida = data.filter((red, index) => index < 50 && {...red})

    const dispatch = useDispatch()

    const {
        rex_cargando_lista_derecha,
    } = useSelector(({powerBi}) => powerBi)

    const { Title } = Typography
    const [abrir, setAbrir] = useState(false)
    const [dataTotal, setDataTotal] = useState([])

    return(
        <>
        <div className="contenedorSimpleFiltro">
            <Title className="filtroTitulo">{titulo}</Title>
            {
                seleccionado.length > 0
                &&  <FilterFilled 
                        className="iconoFiltro" 
                        onClick={async () => {
                            await dispatch(SeleccionarValorFiltroReducer([], titulo))
                            dispatch(SelectFilterDailyReducer([[], dataLength], titulo ))
                            dispatch(ObtenerDataTablaReducer(true))
                        }}
                    />
            }
            {
                abrir
                ?   <img className="imgIconoFlechaFiltro flechaAbajo" src={FlechaDesplegar} />
                :   <img className="imgIconoFlechaFiltro" src={FlechaDesplegar} />
            }
            <Picky
                className="filtro"
                options={data.length <= 50 ? data : dataTotal.length == 0 ? dataReducida : dataTotal}
                placeholder={placeholder}
                manySelectedPlaceholder={manySelectedPlaceholder}
                allSelectedPlaceholder={allSelectedPlaceholder}
                selectAllText={selectAllText}
                filterPlaceholder={filterPlaceholder}
                numberDisplayed={numberDisplayed}
                labelKey={labelKey}
                valueKey={valueKey}
                multiple={multiple}
                includeFilter={includeFilter}
                clearFilterOnClose={clearFilterOnClose}
                includeSelectAll={includeSelectAll}
                value={seleccionado}
                onChange={async (data) => {
                    await dispatch(SeleccionarValorFiltroReducer(data, titulo))
                    dispatch(SelectFilterDailyReducer([data, dataLength], titulo))
                    dispatch(ObtenerDataTablaReducer(true))
                }}
                dropdownHeight={height ? height : 215}
                onOpen={() => {
                    setAbrir(true)
                    if(data.length > 50){
                        setTimeout(() => {
                            setDataTotal(data)
                        }, 400)
                    }
                    
                }}
                onClose={() => {
                    setAbrir(false)
                    if(data.length > 50){
                        setTimeout(() => {
                            setDataTotal(dataReducida)
                        }, 300)
                    }
                }}
                renderSelectAll={({
                    filtered,
                    tabIndex,
                    allSelected,
                    toggleSelectAll,
                    multiple,
                }) => {
                    if(includeSelectAll && data.length > 0){
                        if (multiple && !filtered) {
                            return (
                                <Spin 
                                    tabIndex={tabIndex} 
                                    spinning={rex_cargando_lista_derecha} 
                                    className="iconoCheckBox"
                                    indicator={<LoadingOutlined />}
                                >
                                <Checkbox 
                                    className={allSelected == "all" ? 'option selected' : 'option'} 
                                    onChange={toggleSelectAll} 
                                    checked={allSelected == "all" ? true : false}
                                >
                                    <span title="Seleccionar Todo">Seleccionar Todo</span>
                                </Checkbox>
                                </Spin>
                            )
                        }
                    }
                }}
                render={({
                    isSelected,
                    item,
                    selectValue,
                    labelKey,
                    valueKey,
                }) => {
                    let palabrasCambiadas = {
                        'PA� HUG ACTIVESEC CAP M 4X52': {text: "PAÑ HUG ACTIVESEC CAP M 4X52"},
                        'Practipa�al': {text: "Practipañal"},
                        'Facial H�medo': {text: "Facial Húmedo"},
                        'Reci�n nacido': {text: "Recién nacido"},
                        'Practipa�al Fem': {text: "Practipañal Fem"},
                        'PH H�medo - Moist': {text: "PH Húmedo - Moist"},
                        'PA� HUG ACTIVESEC CAP G 4X44': {text: "PAÑ HUG ACTIVESEC CAP G 4X44"},
                    }
                    let textoCorregido = palabrasCambiadas[item[labelKey]]
                    return (
                        <div
                            style={
                                rex_cargando_lista_derecha
                                ?   {   clear: 'both',
                                        overflow: 'hidden',
                                        opacity: '0.5',
                                        userSelect: 'none',
                                        pointerEvents: 'none',
                                        position: 'relative',
                                        transition: 'opacity 0.3s',
                                    }
                                :   {}
                            }
                            key={item[valueKey]}
                        >
                        <Checkbox 
                            className={isSelected ? 'option selected' : 'option'} 
                            onChange={() => selectValue(item)} 
                            checked={isSelected}
                        >
                            <span 
                                title={
                                    textoCorregido
                                        ? textoCorregido.text
                                        : item[labelKey]
                                }
                            >
                                {
                                    textoCorregido
                                        ? textoCorregido.text
                                        : item[labelKey]
                                }
                            </span>
                        </Checkbox>
                        </div>
                    )
                }}
            />
        </div>
        </>
    )
}

export default SelectCheckBox