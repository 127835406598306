import { 
    REGISTER_USERS_CONNECTED_MODULES_STATUS,
    GET_DATA_AREAS_DATABASES_STATUS,
    GET_DATA_GROUP_STATUS,
    SELECCIONAR_STATUS,
    SELECT_DATE_FILTER_STATUS,
    DATA_MODAL_STATUS,
    DATA_ESTATICA_MODAL_PLANTILLA_PROMO_DV,
    DATA_ESTATICA_MODAL_ESTATUS_INSUMO,
    LOADING_DATA_STATUS,
    STATE_ESTADOS_PENDIENTES
} from "../../../Constants/Status/Status"

const INIT_STATE = {
    rex_users_status_connected      : [],
    rex_data_areas_databases_status : [],
    rex_data_group_status           : [],
    rex_loading_data_status         : false,
    rex_seleccionar_status          : null,
    rex_select_date_filter_status   : null,
    rex_data_modal_status           : [],
    rex_state_estados_pendientes    : [],
    rex_data_modal_estatica_plantilla_promo_pdv : [
        {
            key                     : 1,
            zona                    : "Lima",
            sub_canal               : "Lima Distribuidores",
            territorio              : "Lima 2",
            cliente                 : "AUREN",
            responsable             : "Trade",
            gerente_canal           : "Javier Ley",
            sucursal                : "AUREN",
            clientespe : {
                channel         : "DTT 1 -LIMA",
                regional_management : "Javier Ley",
                client_hml      : "AUREN",
                ship_to         : "AUREN SA",
                sub_channel     : "",
                locality        : "LIMA",
                zone            : "Lima Distribuidores"
            },
            perpersonas : {
                pernombrecompleto : "Eunice Calle"
            },
            
            espresponsable        : "Trade",
            espfechaprogramado    : "2024-02-29T00:00:00.000Z",
            espfechactualizacion  : null,
            espdiaretraso         : "0",
        },
    ],
    rex_data_modal_estatica_status_insumo : [
        {
            key             : 1,
            zona            : "Lima",
            canal           : "DTT 1 -LIMA",
            sub_canal       : "Lima Distribuidores",
            territorio      : "Lima 2",
            cliente         : "AUREN",
            sucursal        : "AUREN",
            cuota           : 300,
            gerente_canal   : "Javier Ley",
            responsable     : "Ventas",
            usuario         : "Eunice Calle",
            deadline        : "2024-02-29T00:00:00.000Z",
            deadline_actualizado    : null,
            dias_retraso        : "0",
        },
    ]
}

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        
        case STATE_ESTADOS_PENDIENTES: {
            return {
                ...state,
                rex_state_estados_pendientes : action.payload,
            }
        }
        case LOADING_DATA_STATUS: {
            return {
                ...state,
                rex_loading_data_status : action.payload,
            }
        }
        case DATA_ESTATICA_MODAL_ESTATUS_INSUMO: {
            return {
                ...state,
                rex_data_modal_estatica_status_insumo : action.payload,
            }
        }
        case DATA_ESTATICA_MODAL_PLANTILLA_PROMO_DV: {
            return {
                ...state,
                rex_data_modal_estatica_plantilla_promo_pdv : action.payload,
            }
        }
        case DATA_MODAL_STATUS: {
            return {
                ...state,
                rex_data_modal_status : action.payload,
            }
        }
        case SELECT_DATE_FILTER_STATUS: {
            return {
                ...state,
                rex_select_date_filter_status : action.payload,
            }
        }
        case GET_DATA_AREAS_DATABASES_STATUS: {
            return {
                ...state,
                rex_data_areas_databases_status : action.payload,
            }
        }
        case REGISTER_USERS_CONNECTED_MODULES_STATUS: {
            return {
                ...state,
                rex_users_status_connected : action.payload,
            }
        }
        case GET_DATA_GROUP_STATUS: {
            return {
                ...state,
                rex_data_group_status : action.payload,
            }
        }
        case SELECCIONAR_STATUS: {
            return {
                ...state,
                rex_seleccionar_status : action.payload,
            }
        }
        default:
            return state;
    }
}