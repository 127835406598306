import React, {useState, useEffect} from 'react'

const BarraHome = () => {
    const [offset, setOffset] = useState(0)

    useEffect(() => {
        const onScroll = () => setOffset(window.pageYOffset)
        window.removeEventListener('scroll', onScroll)
        window.addEventListener('scroll', onScroll, { passive: true })
        return () => window.removeEventListener('scroll', onScroll)
    }, [])
    
    return (
        <div className="Barra-Home" style={{display: offset > 0 && 'none'}}></div>
    )
}

export default BarraHome