import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import { Row, Col, Typography, Modal, Button } from 'antd'
import FormCreateDataControl from '../../Components/DataControl/FormCreateDataControl'
import NavigationGlobal from '../../Components/Global/NavigationGlobal'
import SideMenu from '../../Components/Global/SideMenu'
import { useNavigate } from 'react-router'

const CreateDataControl = () => {

    const { Title } = Typography
    const [ showModal, setShowModal ] = useState(false)
    const [ infoModal, setInfoModal ] = useState({})
    const navigate = useNavigate()

    const menuDataControl = [
        {
            url: '/home-administrator',
            nombre: 'Administrador'
        },
        {
            url: '/administrator/data-control',
            nombre: 'Control de datos'
        },
        {
            url: '/administrator/create-data-control',
            nombre: 'Nuevo control de datos'
        },
    ]

    const { 
        rex_users_conected_dashboard,
    } = useSelector(({registerUsers}) => registerUsers)

    const { 
        rex_data_users_online
    } = useSelector(({users}) => users)

    const statusModal = () => {
        setShowModal(false)
    }
    
    return (
        <div className='Container-Main'>
            <NavigationGlobal menuItems={menuDataControl}/>
            <Row>
                <Col span={24}>
                    <Title level={2} className='Title-Search-Text-Global'>Nuevo control de datos</Title>
                </Col>
            </Row>
            <FormCreateDataControl
                setShowModal={setShowModal}
                setInfoModal={setInfoModal}
                infoModal={infoModal}
            />

            <SideMenu
                usersCurrentView  = {rex_users_conected_dashboard}
                usersAllConnected ={rex_data_users_online}
            />
            <Modal
                open={showModal}
                title={<span className={`${infoModal.status ? "Text-Ok" : "Text-Alert"}`}>{infoModal.title}</span>}
                width={300}
                zIndex={100000000}
                className='Modal-Create-Data-Control'
                footer={[
                    <div style={{display:"flex", justifyContent:"center"}}>
                        <div className={`Button-Close Button${infoModal.status ? "-Ok" : "-Alert"}`} onClick={() => {
                            statusModal()
                            navigate('/administrator/data-control')
                        }}>
                            Ok
                        </div>
                    </div>
                ]}
            >
                <div className='Modal-Description'>
                    {infoModal.description}
                </div>
            </Modal>
        </div>
    )
}

export default CreateDataControl