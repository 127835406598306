import {
    GET_DATA_FILTERS_TXT_CLIENTS_TOTAL
} from "../../../../Constants/Manage/MasterClients/MasterClients";

const INIT_STATE = {

    rex_filters_clients_total : {
        rex_filter_clients : {
            rex_txt_uniqueChannels : "",
            rex_txt_unique_zona : "",
            rex_txt_unique_cliente : "",
            rex_txt_unique_sucursal : "",
            rex_txt_unique_gba : "",
            rex_txt_customer_group : ""
        },
        rex_filter_new : {
            rex_txt_uniqueChannels : "",
            rex_txt_unique_zona : "",
            rex_txt_unique_cliente : "",
            rex_txt_unique_sucursal : "",
            rex_txt_unique_gba : "",
            rex_txt_customer_group : ""
        }
    },
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {

        case GET_DATA_FILTERS_TXT_CLIENTS_TOTAL: {
            return {
                ...state,
                rex_filters_clients_total : action.payload,
            }
        }

        default:
            return state;
    }
}