import React, { useState, useRef } from 'react'
import { Link } from 'react-router-dom'
import { DownOutlined } from '@ant-design/icons'

const CardSubModuleItem1 = ({submodule, modid, activeSub, setActiveSub, index}) => {
    const [timerAnimation, setTimerAnimation] = useState(null)
    const [activeSubModule, setActiveSubModule] = useState(false)
    const titleModule = useRef(null)

    const activeTimerAnimation = () => {
        const timerId = setTimeout(() => {
            if(activeSub !== index){
                setActiveSub(index)
            }
        }, 500)
    
        setTimerAnimation(timerId)
    }

    const timerCanceled = () => {
        if (timerAnimation) {
            clearTimeout(timerAnimation)
        }
    }

    return (
        <div 
            className={`Card-Submodule-Item ${activeSub !== null ? activeSub == index ? 'active-submodule-item active-sub-0' : 'inactive-sub-0' : ''}`}
            onMouseLeave={() => setActiveSubModule(false)}
            onMouseOver={() => {
                setActiveSubModule(true)
                if(submodule.children.length == 0){
                    activeTimerAnimation()
                }
            }}
            onMouseOut={() => {
                if(submodule.children.length == 0){
                    timerCanceled()
                }
            }}
        >
            <div className="Container-Header-Submodule" style={{height: activeSub == index ? `${titleModule.current.clientHeight + 40}px` : '102px'}}>
                {submodule.smoruta.includes('creciendo-juntos')
                ?   <a href='https://smartview.grow-corporate.com' target='_blank'>
                        <div 
                            className="Text-Submodule" 
                            ref={titleModule} 
                            style={{
                                bottom: activeSub == index
                                    ? `calc(100% - ${titleModule.current.clientHeight}px)` 
                                    : activeSubModule 
                                        ? `calc(100% - ${titleModule.current.clientHeight}px)` 
                                        : 0,
                                transition: 'bottom .5s',
                            }}
                        >{submodule.smonombre}</div>
                    </a>

                : submodule.smoruta.includes('innovacion')
                ? <a href='https://spider.grow-corporate.com/Sistema/simplePowerbi' target='_blank'>
                <div 
                    className="Text-Submodule" 
                    ref={titleModule} 
                    style={{
                        bottom: activeSub == index
                            ? `calc(100% - ${titleModule.current.clientHeight}px)` 
                            : activeSubModule 
                                ? `calc(100% - ${titleModule.current.clientHeight}px)` 
                                : 0,
                        transition: 'bottom .5s',
                    }}
                >
                    {submodule.smonombre}
                </div>
            </a>
            : <Link 
            to={
                `/dashboards/modulo/${modid}/${submodule.smoruta}`
            }
            >
                <div 
                    className="Text-Submodule" 
                    ref={titleModule} 
                    style={{
                        bottom: activeSub == index
                            ? `calc(100% - ${titleModule.current.clientHeight}px)` 
                            : activeSubModule 
                                ? `calc(100% - ${titleModule.current.clientHeight}px)` 
                                : 0,
                        transition: 'bottom .5s',
                    }}
                >
                    {submodule.smonombre}
                </div>
            </Link>
                }
            </div>
            {
                submodule.children.length > 0 ?
                <>
                    <div 
                        onClick={() => {
                            if(activeSub == index){
                                setActiveSub("inactive")
                            }else{
                                setActiveSub(index)
                            }
                        }} 
                        className="Container-Icon-Submodule"
                    >
                        <DownOutlined 
                            className="Icon-Submodule-Item" 
                            rotate={activeSub == index ? 180 : 0}
                        />
                    </div>
                    {
                        activeSub == index &&
                        submodule.children.map((ssm, index) => 
                        <div 
                            className="Container-Text-SubSubmodule"
                            key={index}
                        >
                            <span className="Icon-SubSubmodule"></span>
                            <Link to={ssm.ssmrutaabsoluta ? `/${ssm.ssmruta}` : `/dashboards/modulo/${modid}/submodulo/${submodule.smoid}/${ssm.ssmruta}`}>
                                <div className="Text-SubSubmodule">
                                    {ssm.ssmnombre}
                                    <span className="Underline-SubSubmodule"></span>
                                </div>
                            </Link>
                        </div>)
                    }
                </>
                : submodule.smoruta.includes('creciendo-juntos')
                ?   <a href='https://smartview.grow-corporate.com' target='_blank'>
                        <div className="Container-Icon-Without-Submodule">
                            <DownOutlined 
                                className="Icon-Submodule-Without-Item" 
                                rotate={270}
                            />
                        </div>
                    </a>
                : submodule.smoruta.includes('innovacion')
                ? <a href='https://spider.grow-corporate.com/Sistema/simplePowerbi' target='_blank'>
                    <div className="Container-Icon-Without-Submodule">
                        <DownOutlined 
                            className="Icon-Submodule-Without-Item" 
                            rotate={270}
                        />
                    </div>
                </a>
                :<Link to={submodule.smorutaabsoluta ? `/${submodule.smoruta}` : `/dashboards/modulo/${modid}/$ {submodule.smoruta}`}>
                        <div className="Container-Icon-Without-Submodule">
                            <DownOutlined 
                                className="Icon-Submodule-Without-Item" 
                                rotate={270}
                            />
                        </div>
                    </Link>
            }
        </div>
    )
}

export default CardSubModuleItem1