import React, { useState } from 'react'

function OptionButton({fill, stroke}){
    
    return (
        <svg width="18" height="18" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g clip-path="url(#clip0_166_5350)">
                <path d="M4.52579 3.87959L6.14797 2.25684L6.16022 10.2085C6.16022 10.6917 6.55195 11.0835 7.03518 11.0835C7.5184 11.0835 7.91014 10.6917 7.91014 10.2085L7.89789 2.26676L9.51075 3.87962C9.84646 4.2272 10.4004 4.23682 10.7479 3.90111C11.0955 3.5654 11.1051 3.01149 10.7694 2.66392C10.7624 2.65661 10.7552 2.64945 10.7479 2.64242L8.87435 0.768829C7.84928 -0.256272 6.18729 -0.256272 5.16219 0.768801L5.16216 0.768829L3.28859 2.6424C2.95288 2.98998 2.9625 3.54388 3.31008 3.87959C3.64916 4.20708 4.18671 4.20708 4.52579 3.87959Z" fill={fill}/>
                <path d="M13.1244 8.45703C12.6412 8.45703 12.2495 8.84877 12.2495 9.33199V12.0099C12.2491 12.1416 12.1425 12.2482 12.0109 12.2485H1.98849C1.85686 12.2482 1.75023 12.1416 1.74992 12.0099V9.33199C1.74992 8.84877 1.35819 8.45703 0.874962 8.45703C0.391737 8.45703 0 8.84877 0 9.33199V12.0099C0.0012851 13.1076 0.890821 13.9971 1.98849 13.9984H12.0109C13.1086 13.9971 13.9981 13.1076 13.9994 12.0099V9.33199C13.9994 8.84877 13.6077 8.45703 13.1244 8.45703Z" fill={fill}/>
            </g>
            <defs>
                <clipPath id="clip0_166_5350">
                    <rect width="14" height="14" fill="white"/>
                </clipPath>
            </defs>
        </svg>

    )
}

const UploadButtonGlobal = ({onMouseOver}) => {

    return (
        <OptionButton fill={onMouseOver ? '#013A81' : 'rgba(1, 58, 129, 0.4)'}/>

    )
}

export default UploadButtonGlobal