import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Form, Checkbox } from 'antd'
import { SaveCheckPermissionsCountryReducer } from '../../Redux/Actions/ProfileTypes/ProfileTypes'

const ProfileTypesLevelCountries = () => {
    const dispatch = useDispatch()

    const {
        rex_permissions_country,
        rex_tipo_perfil_seleccionado,
        rex_personalizar_tipo_perfil,
    } = useSelector(({profileTypes}) => profileTypes)

    const { 
        rex_data_country_clone,
    } = useSelector(({paises}) => paises)

    return (
        <Form.Item
            label='Acceso nivel país'
            className='Form-Item-Level-Countries'
        >
            <div className='Container-Checkbox-Level-Countries'>
                <Checkbox.Group
                    onChange={(checkedValues) => dispatch(SaveCheckPermissionsCountryReducer(checkedValues))}
                    options={rex_data_country_clone}
                    value={rex_permissions_country}
                    disabled={!rex_tipo_perfil_seleccionado || !rex_personalizar_tipo_perfil ? true : false}
                />
            </div>
        </Form.Item>
    )
}

export default ProfileTypesLevelCountries