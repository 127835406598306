import React, { useState } from 'react'
import { Typography, Card } from 'antd'
import '../../Styles/Components/Analytics/TableAnalytics.css'

const TableAnalytics = (props) => {
    const titleTable = props.titleTable
    const itemTable = props.itemTable
    const [seeMore, setSeeMore] = useState(false)
    const { Title } = Typography
    return(
        <Card className="Container-Card-Analytics">
            <Title
                level={3}
                className="Title-Analytics"
            >
                {titleTable}
            </Title>
            <table className="Container-Table-Analytics">
                <thead>
                    <tr>
                        <th style={{width: "15%"}}>Item</th>
                        <th style={{width: "48%"}}>
                            <span style={{display:"flex"}}>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Contenido</span>
                        </th>
                        <th style={{width: "20%"}}>Duración promedio de actividad</th>
                        <th style={{width: "17%"}}>Vistas</th>
                    </tr>
                </thead>
                <tbody>
                    {
                        itemTable.length <= 10
                            ?   itemTable.map((i, index) => 
                                    <tr key={index}>
                                        <td>{index + 1}</td>
                                        <td>{i.contenido}</td>
                                        <td>{i.duracion} min</td>
                                        <td>{i.vista}</td>
                                    </tr>
                                )
                            :   itemTable.map((i, index) => {
                                    return(
                                        seeMore
                                            ?   <tr key={index} className="Last-Row">
                                                    <td>{index + 1}</td>
                                                    <td>{i.contenido}</td>
                                                    <td>{i.duracion}</td>
                                                    <td>{i.vista}</td>
                                                </tr>
                                            :   index < 11
                                                    &&  <tr key={index} className="Last-Row">
                                                            <td>{index + 1}</td>
                                                            <td>{i.contenido}</td>
                                                            <td>{i.duracion}</td>
                                                            <td>{i.vista}</td>
                                                        </tr>
                                    )
                                })
                    }
                </tbody>
            </table>
                {
                    itemTable.length > 10
                    &&  <div 
                            className="Text-See-More"
                            onClick={() => setSeeMore(!seeMore)}
                        >
                            { seeMore ? "ver menos" : "ver más" }
                        </div>
                }
        </Card>
    )
}

export default TableAnalytics