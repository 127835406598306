import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Link, scroller } from 'react-scroll'
import arrowWhite from './../../Assets/Images/Global/arrow-down-white.png'
import arrowBlue from './../../Assets/Images/Global/arrow-down-blue.png'

const ButtonFloat = (props) => {
    const scrollMove            = props.scrollMove
    const lastScrollMove        = props.lastScrollMove
    const scrollPosition        = props.scrollPosition

    const { 
        rex_menu_home_category
    } = useSelector(({home}) => home)

    useEffect(() => {
        if(rex_menu_home_category == "Home"){
            scroller.scrollTo('Home', {
                isDynamic: true,
                smooth: true,
                duration: 0,
            })
        }else if(rex_menu_home_category == "Category"){
            scroller.scrollTo('Category', {
                isDynamic: true,
                smooth: true,
                duration: 0,
            })
        }
    }, [rex_menu_home_category])

    return (
        <Link to={scrollMove} isDynamic={true} smooth={true} duration={500}>
            <div
                className={`Float-Bottom-Top-Button ${lastScrollMove && 'Last-Link'}`}
            >
                <img className='First-Icon' src={scrollPosition ? arrowBlue : arrowWhite} />
                <img className='Second-Icon' src={scrollPosition ? arrowBlue : arrowWhite} />
            </div>
        </Link>
    )
}

export default ButtonFloat