import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import moment from "moment"
import { GetListUsersProfileReducer, GetModulesProfileReducer, GetTypeProfileCountryReducer, SaveCheckPermissionsCountryReducer, SaveNameTypeProfileReducer, ShowModalListUsersProfileReducer } from '../../Redux/Actions/ProfileTypes/ProfileTypes'
import { Row, Col, Typography, Form, Checkbox, Input } from 'antd'
import FormCreateProfile from '../CreateProfile/FormCreateProfile'
import IconCreateProfile from '../../Assets/Images/CreateProfile/icon-create-profile.png'
import ListUsersProfileType from './ListUsersProfileType'
import SideMenu from '../Global/SideMenu'
import ModalNotification from '../Global/ModalNotification'

const EditProfile = () => {

    const { Title } = Typography

    const {
        rex_unique_type_profile,
        rex_permissions_country,
        rex_edit_type_profile,
        rex_name_type_profile,
    } = useSelector(({profileTypes}) => profileTypes)

    const { 
        rex_users_conected_dashboard,
    } = useSelector(({registerUsers}) => registerUsers)

    const { 
        rex_data_users_online
    } = useSelector(({users}) => users)

    const { 
        rex_data_country_clone,
    } = useSelector(({paises}) => paises)

    const dispatch = useDispatch()
    const { id } = useParams()

    useEffect(() => {
        dispatch(GetModulesProfileReducer(id))
        dispatch(GetTypeProfileCountryReducer())
    }, [])

    const menuProfileTypes = [
        {
            url: '/administrator/profile-types',
            nombre: 'Lista de tipos de perfiles'
        },
        {
            url: `/administrator/edit-profile/${id}`,
            nombre: 'Editar tipo de perfil'
        },
    ]

    const showListUsersProfile = ( tpuid ) => {
        dispatch(ShowModalListUsersProfileReducer(true))
        dispatch(GetListUsersProfileReducer(tpuid))
    }

    const onChangeCheckboxCountry = (checkedCountries) => {
        dispatch(SaveCheckPermissionsCountryReducer(checkedCountries))
    }

    return (
        <div className='Container-Main'>
            {/* <NavigationGlobal menuItems={menuProfileTypes}/> */}
            <Form
                layout='vertical'
                className='Create-Profile-Form'
            >
            <Row>
                <Col
                    className='Create-Profile-Container-Left Edit-Profile-Container-Left'
                    xs={{span:24}} sm={{span:24}} md={{span:24}} lg={{span:12}} xl={{span:12}}
                >
                    <img src={IconCreateProfile} width={50} height={50}/>
                    <Title
                        level={2}
                        className='Create-Profile-Text-Main'
                    >
                        {rex_unique_type_profile.tpunombre}
                    </Title>
                    <p
                        className='Create-Profile-Text-Description'
                    >
                        Aquí podrá encontrar los permisos que tiene el perfil.
                    </p>
                    <div
                        className='Create-Profile-Text-Title'
                    >
                        Fecha de creación
                    </div>
                    <p
                        className='Create-Profile-Text-Description'
                    >
                        {moment(rex_unique_type_profile.created_at).format("DD/MM/YYYY")}
                    </p>
                    <div
                        className='Create-Profile-Text-Title'
                    >
                        Fecha de última actualización
                    </div>
                    <p
                        className='Create-Profile-Text-Description'
                    >
                        {moment(rex_unique_type_profile.updated_at).format("DD/MM/YYYY")}
                    </p>
                    <div
                        className='Create-Profile-Text-Title'
                    >
                        Creador del perfil
                    </div>
                    <p
                        className='Create-Profile-Text-Description'
                    >
                        {
                            rex_unique_type_profile.usuusuarios
                            ? rex_unique_type_profile.usuusuarios.perpersonas.pernombrecompleto
                            : ''
                        }
                    </p>
                    <div
                        className='Create-Profile-Text-Title'
                    >
                        Total de usuarios con el perfil
                    </div>
                    <div className='Create-Profile-Text-Description Create-Profile-Text-Select'>
                        <p
                            onClick={() => showListUsersProfile(rex_unique_type_profile.tpuid)}
                            className='Edit-Profile-Text-Description-Users Create-Profile-Text-Select'
                        >
                            {`${rex_unique_type_profile.ctd_usu} ${rex_unique_type_profile.ctd_usu != 1 ? 'usuarios': 'usuario'}`}
                        </p>
                    </div>
                    {
                        !rex_edit_type_profile 
                        &&<>
                        <Form.Item
                            className="Search-Profile"
                            label="Nombre del perfil"
                        >
                            <Input 
                                value={rex_name_type_profile} 
                                onChange={(e) => dispatch(SaveNameTypeProfileReducer(e.target.value))} 
                            />
                        </Form.Item>
                        <Title level={2} className='Form-Create-Profile-Text-Main'>Permisos</Title>
                        <Form.Item
                            label='Acceso nivel país'
                            className='Form-Item-Level-Country-Create-Profile'
                        >
                            <div
                                className='Container-Checkbox-Create-Profile'
                            >
                                <Checkbox.Group
                                    onChange={onChangeCheckboxCountry}
                                    options={rex_data_country_clone}
                                    value={rex_permissions_country}
                                    className="Group-Check-List"
                                >
                                </Checkbox.Group>
                            </div>
                        </Form.Item>
                        </>
                    }
                </Col>

                <ListUsersProfileType/>
                <FormCreateProfile/>
            </Row>
            </Form>

            <ModalNotification/>

            <SideMenu
                usersCurrentView  = {rex_users_conected_dashboard}
                usersAllConnected ={rex_data_users_online}
            />
        </div>
    )
}

export default EditProfile