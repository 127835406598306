import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { useNavigate } from "react-router-dom"
import { Col, Row, Typography } from 'antd'
import NavigationGlobal from '../../Components/Global/NavigationGlobal'
import FormCreateUser from '../../Components/Users/FormCreateUser'
import NotificationEdited from '../../Components/Users/NotificationEdited'
import SideMenu from '../../Components/Global/SideMenu'
import { CheckBoxChangeCountryReducer } from '../../Redux/Actions/Users/Users'

const EditUser = () => {
    
    const { Title } = Typography
    const navigate = useNavigate()
    const dispatch = useDispatch()

    const menuUsers = [
        {
            url: '/home-administrator',
            nombre: 'Administrador'
        },
        {
            url: '/administrator/users',
            nombre: 'Lista de usuarios'
        },
        {
            url: '/administrator/users/edit-user',
            nombre: 'Editar Usuario'
        },
    ]

    const { 
        rex_users_conected_dashboard,
    } = useSelector(({registerUsers}) => registerUsers)

    const { 
        rex_data_users_online,
        rex_data_users_form,
    } = useSelector(({users}) => users)

    useEffect(() => {
        if(!rex_data_users_form['usuid']){
            navigate('/administrator/users')
        }

        if(rex_data_users_form['accessCountry']){
            dispatch(CheckBoxChangeCountryReducer(rex_data_users_form['accessCountry']))
        }
    }, [])

    return (
        <div className='Container-Main Container-Users-Create'>
            <NavigationGlobal menuItems={menuUsers}/>
            <Row>
                <Col span={24}>
                    <Title level={2} className='Title-Search-Text-Global'>Editar usuario</Title>
                </Col>
            </Row>
            <FormCreateUser edit={true} />
            <NotificationEdited/>

            <SideMenu
                usersCurrentView  = {rex_users_conected_dashboard}
                usersAllConnected ={rex_data_users_online}
            />
        </div>
    )
}

export default EditUser