import React from 'react'
import { Select } from 'antd'
import '../../Styles/Components/Global/FilterSimpleSelectGlobal.css'
import {
    CambiarTxtFilterMasterProductsReducer
} from '../../Redux/Actions/Manage/MasterProducts/MasterProducts'
import { useDispatch } from 'react-redux'

const FilterSimpleSelectGlobal = ({
        typeFilter='', 
        list_data=[], 
        dateFilter = null, 
        view, 
        value = "",
        onAction = () => {} 
    }) => {

    const dispatch = useDispatch()
    
    return (
        <Select
            title={`Seleccionar ${typeFilter}`}
            size='small'
            showSearch
            allowClear={true}
            placeholder={typeFilter}
            className="Simple-Select-Global"
            value={value}
            onClear={() => dispatch(CambiarTxtFilterMasterProductsReducer(typeFilter, "", dateFilter, view))}
            popupClassName="Item-Simple-Select-Global"
            filterOption={(input, option) =>
                (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
            }
            // style={{width:'100px'}}
            onChange={(e) => {
                dispatch(onAction(typeFilter, e, dateFilter, view))
            }}
            options={list_data}
        />
    )
}

export default FilterSimpleSelectGlobal