import React, { useState } from 'react'
import { Table, Pagination, Row, Col, DatePicker, Space, Tooltip, ConfigProvider, Button, Skeleton } from 'antd'
import { useDispatch } from 'react-redux'
import ProfileTypesFilterSelect from '../../Components/ProfileTypes/ProfileTypesFilterSelect'
import TrashButtonGlobal from '../../Assets/Images/Global/TrashButtonGlobal'
import DownloadButtonGlobal from '../../Assets/Images/Global/DownloadButtonGlobal'
import { 
    EditOutlined,
    PlusCircleOutlined,
    LoadingOutlined
} from '@ant-design/icons'
import es_ES from "antd/lib/locale/es_ES"
import 'dayjs/locale/es';
import { useNavigate} from "react-router-dom"
import { GenerateExcelReducer } from '../../Redux/Actions/DownloadDataInternal/DownloadDataInternal'
import dayjs from 'dayjs'
dayjs.locale('es')

const TableGlobal = ({
        actionOnRow = () => {},
        actInacChechBox = true,
        buttonSaveProductsDisable=false,
        actionButtonSave = () => {},
        columns,
        data,
        dataDownload = {},
        date,
        componentSelect,
        componentFilterExtra,
        componentExtraLeft,
        componentExtraLeftPagination,
        functionComponentSelect,
        functionDelete,
        functionEditSelectedRows,
        functionFilterDate,
        loading_data = false,
        pageSizeTableGlobal,
        pageTableGlobal,
        pathNewButton = "",
        rowClassName,
        selectRows,
        selectRowsTable = false,
        selectedRowKeys,
        setDate,
        setMouseOverRow,
        setSelectedRowKeys,
        setPageSizeTableGlobal,
        setPageTableGlobal,
        setValueCheckbox,
        showComponentExtraLeft,
        showDownload = true,
        showDownloadProducts = false,
        downloadFileModule = false,
        showEdit,
        showEditRight,
        showSaveProducts = false,
        showFilterDate,
        showNewButton = false,
        showTrash = true,
        showTrashRight,
        showSaveButton = false,
        valueCheckbox,
    }) => {

    const [ loadingDownload, setLoadingDownload ] = useState(false)
    const [ loadingSave, setLoadingSave ] = useState(false)
    const [ dateInput, setDateInput ] = useState("2023-07")
    const [ currentDateSource, setCurrentDataSource] = useState(null)

    let navigate = useNavigate()

    const handleSelect = (record, selected) => {
        if (selected) {
            setSelectedRowKeys((keys) => [...keys, record.key]);
            if(selectedRowKeys.length + 1 === data.length || selectedRowKeys.length+1 == currentDateSource?.length){
                setValueCheckbox(true)
            }
        } else {
            setSelectedRowKeys((keys) => {
                const index = keys.indexOf(record.key);
                return [...keys.slice(0, index), ...keys.slice(index + 1)];
            });
            setValueCheckbox(false)
        }
    }

    const toggleSelectAll = (valueCheckbox) => {
        setSelectedRowKeys((keys) =>
        valueCheckbox
            ? currentDateSource?.length > 0 ? currentDateSource.map((r) => r.key) : data.map((r) => r.key) 
            : []
        );
    };

    const rowSelection = {
        columnTitle: selectRowsTable ? <ProfileTypesFilterSelect
                                    toggleSelectAll={toggleSelectAll}
                                    selectedRowKeys={selectedRowKeys}
                                    dataAmount={data.length}
                                    setSelectedRowKeys={setSelectedRowKeys}
                                    data={data}
                                    valueCheckbox={valueCheckbox}
                                    setValueCheckbox={setValueCheckbox}
                                    actInacChechBox={actInacChechBox}
                                    themeColor='green'
        /> : <></>,
        selectedRowKeys,
        type: "checkbox",
        fixed: true,
        onSelect: handleSelect,
    };
    
    const dispatch = useDispatch()

    return (
        <>
            <Row className='Table-Container-Actions-Global'>
                <Col xl={16} lg={24} className='Table-Col-Container-Actions-Global'>
                    {selectRows &&
                        <div className='Table-Col-Container-Actions-Global'>
                            <div style={{display:"flex", justifyContent:"space-between", alignItems:"center" ,columnGap:"4px"}}>
                                {
                                    !selectRowsTable && <ProfileTypesFilterSelect
                                        toggleSelectAll={toggleSelectAll}
                                        selectedRowKeys={selectedRowKeys}
                                        dataAmount={data.length}
                                        setSelectedRowKeys={setSelectedRowKeys}
                                        data={data}
                                        valueCheckbox={valueCheckbox}
                                        setValueCheckbox={setValueCheckbox}
                                        actInacChechBox={actInacChechBox}
                                        themeColor='blue'
                                    /> 
                                }
                                {componentSelect && <div onClick={() => functionComponentSelect(selectedRowKeys, setSelectedRowKeys)}>{componentSelect}</div>}
                                {showComponentExtraLeft && componentExtraLeft}
                                { showDownload && <DownloadButtonGlobal/> }
                                { showFilterDate && <ConfigProvider
                                >
                                    <DatePicker locale={es_ES}
                                        onChange={(date, dateString) => {
                                            functionFilterDate(date, dateString)
                                            setDateInput(dateString)
                                            setDate(dateString)
                                        }}
                                        popupClassName="Table-Global-Filter-PopUp-DatePicker"
                                        value={date ? dayjs(dayjs(date), "AAAA-MM") : dayjs(dayjs(), "AAAA-MM")}
                                        size='small'
                                        picker='month'
                                        className='Table-Global-Filter-DatePicker'
                                    /> 
                                </ConfigProvider>
                                }
                                { componentFilterExtra && <div>{componentFilterExtra}</div> }
                                { showTrash && <TrashButtonGlobal
                                    functionDelete={() => functionDelete(selectedRowKeys, setSelectedRowKeys)} /> 
                                }
                                { showNewButton &&
                                    <Tooltip
                                        placement="bottom"
                                        title="Nuevo"
                                        color="#3B72FF"
                                        showArrow={false}
                                        overlayStyle={{marginTop:"20px"}}
                                    >
                                        <div
                                            className="Container-Button-New"
                                            onClick={() => navigate(pathNewButton)}
                                        >
                                            <PlusCircleOutlined style={{fontSize:'18px', color : "#013A81"}}/>
                                        </div>
                                    </Tooltip>
                                }
                                { showEdit && 
                                    <EditOutlined 
                                    onClick={() => functionEditSelectedRows(selectedRowKeys, setSelectedRowKeys)}
                                    style={{fontSize:'18px', color:'#013A81', cursor:'pointer'}}/>
                                }
                            </div>
                        </div>
                    }
                </Col>
                {/* <Col xl={12} lg={24} className='Table-Col-Container-Actions-Global Col-Right'> */}
                <Col xl={8} lg={24} className='Table-Col-Container-Actions-Global Col-Right' style={{display:"flex", justifyContent:"end"}}>
                    <div style={{display:"flex", justifyContent:"space-between", alignItems:"center" ,columnGap:"4px"}}>
                        
                        { componentExtraLeftPagination && <div>{componentExtraLeftPagination}</div> }
                        {
                            showSaveProducts &&
                            <Button
                                className={`${buttonSaveProductsDisable? "Button-Disabled Buttons-Actions Button-Save-Products":"Buttons-Actions Button-Save-Products"} `}
                                onClick={async () => {
                                    if(!buttonSaveProductsDisable){
                                        setLoadingSave(true)
                                        const response = await actionButtonSave()
                                        setLoadingSave(false)
                                    }
                                }}
                                style={{
                                    width:'55px'
                                }}
                            >
                                { loadingSave ? <LoadingOutlined/> : "Guardar"}
                            </Button>
                        }
                        { showDownloadProducts &&
                            <Button
                            onClick={async() => {
                                    setLoadingDownload(true)
                                    const fileUrl = await dispatch(GenerateExcelReducer(dataDownload.route, date, dataDownload.nameFile))
                                    const link = document.createElement('a')
                                    link.href = fileUrl
                                    link.download = dataDownload.nameFile
                                    link.click()

                                    // setTimeout(() => {
                                    //     const link = document.createElement('a')
                                    //     link.href = "https://backend-spider-kimberly-corporativa.grow-corporate.com/Sistema/peru/po/PO-3WQM2SX.xlsx"
                                    //     link.download = "PO.xlsx"
                                    //     link.click()
                                    // }, 2000);
                                    
                                    // for await(const c of cant){
                                    //     if(c != "1"){
                                    //         this.setTimeout(() => {
                                    //             const link = document.createElement('a')
                                    //             link.href = c
                                    //             link.download = "PO.xlsx"
                                    //             link.click()
                                    //         }, 2);
                                    //     }else{
                                    //         // const fileUrl = await dispatch(GenerateExcelReducer(dataDownload.route, date))
                                    //         // const link = document.createElement('a')
                                    //         // link.href = fileUrl
                                    //         // link.download = dataDownload.nameFile
                                    //         // link.click()
                                    //     }
                                    // }
                                    setLoadingDownload(false)
                                }}
                                className="Buttons-Actions Button-Download-Products"
                            >
                                { loadingDownload ? <LoadingOutlined/> : "Descargar"}
                            </Button>
                        }
                        { showTrashRight && <TrashButtonGlobal
                                functionDelete={() => functionDelete(selectedRowKeys, setSelectedRowKeys)} /> 
                        }
                        { showEditRight && 
                            <Tooltip
                                placement='bottom'
                                title='Editar'
                                color='#3B72FF'
                                showArrow={false}
                            >
                                <EditOutlined
                                    onClick={() => functionEditSelectedRows(selectedRowKeys, setSelectedRowKeys)}
                                    style={{fontSize:'18px', color:'#013A81', cursor:'pointer'}}
                                />
                            </Tooltip>
                        }

                        {
                            loading_data
                            ?   <Skeleton.Input size='small' active={true}/>
                            :   <Pagination
                                    total={currentDateSource?.length > 0 ? currentDateSource.length: data.length}
                                    showTotal={(total, range) => `${range[0]}-${range[1]} de ${total.toLocaleString('es-US')}`}
                                    defaultPageSize={pageSizeTableGlobal}
                                    defaultCurrent={pageTableGlobal}
                                    current={pageTableGlobal}
                                    className='Table-Pagination-Global'
                                    onChange={(page, pageSize) => {
                                        setPageTableGlobal(page == 0? 1 : page)
                                        setPageSizeTableGlobal(pageSize)
                                    }}
                                />
                        }
                        
                    </div>
                </Col>
            </Row>
            {
                loading_data
                ? <div style={{marginTop:"10px"}}>
                    <Skeleton active={true}/>
                    <Skeleton active={true}/>
                    <Skeleton active={true}/>
                </div>
                : <Table
                    scroll={{ 
                        x: "1500",
                        y: "max-content",
                    }}
                    rowSelection={selectRows ? rowSelection : null}
                    className="Table-Global"
                    columns={columns}
                    dataSource={data}
                    onChange={(pagination, filters, sorter, extra) => {

                        setCurrentDataSource(extra.currentDataSource)
                        if(extra.currentDataSource.length == 0){
                            setValueCheckbox(false)
                            toggleSelectAll(false)
                        }
                        if(selectedRowKeys.length == extra.currentDataSource.length && extra.currentDataSource.length != 0){
                            toggleSelectAll(true)
                            setValueCheckbox(true)
                        }else{
                            setValueCheckbox(false)
                        }
                        if(extra.currentDataSource.length == data.length){
                            setSelectedRowKeys([])
                        }
                        if(valueCheckbox){
                            toggleSelectAll(true)
                        }
                    }}
                    
                    loading={loading_data}
                    onRow={(record, rowIndex) => {
                        return {
                            onClick : event => { 
                                actionOnRow(record)
                            }
                        }
                    }}
                    rowClassName={ record => {
                        let estestado       = record.estid ? record.estid == 1 ? 'Table-Row-Global' : 'Table-Row-Disabled-Global' : 'Table-Row-Global'
                        let carprocesado    = record.carfechaprocesado  ? 'Row-File-Process' : 'Row-File-No-Process'
                        let productManual   = record.producto_manual ? record.producto_manual == true ? 'Row-Product-Manual' : '' : ''
                        let clientManual   = record.client_manual ? record.client_manual == true ? 'Row-Client-Manual' : '' : ''
                        return `${estestado} ${carprocesado} ${productManual} ${clientManual}`
                    }}
                    pagination={{
                        defaultCurrent: pageTableGlobal,
                        current: pageTableGlobal,
                        pageSize: pageSizeTableGlobal,
                        position: ['topRight'],
                    }}
                />
                }
            
        </>
    )
}

export default TableGlobal



// onClick={async() => {
//     setLoadingDownload(true)

//     if(downloadFileModule){
//         const fileUrl = await dispatch(GenerateExcelReducer(dataDownload.route, date))
//         const link = document.createElement('a')
//         link.href = fileUrl
//         link.download = dataDownload.nameFile
//         link.click()
//     }else{
//         const cant = ["1","https://backend-spider-kimberly-corporativa.grow-corporate.com/Sistema/peru/po/PO.xlsx"]
//         for await(const c of cant){
//             if(c != "1"){
//                 const link = document.createElement('a')
//                 link.href = c
//                 link.download = "PO.xlsx"
//                 link.click()
//             }else{
//                 const fileUrl = await dispatch(GenerateExcelReducer(dataDownload.route, date))
//                 const link = document.createElement('a')
//                 link.href = fileUrl
//                 link.download = dataDownload.nameFile
//                 link.click()
//             }
//         }
//     }
//     setLoadingDownload(false)
// }}