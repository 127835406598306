import {
    OBTENER_LISTA_DATA_DASHBOARD,
    OBTENER_LISTA_TOTAL_DATA_DASHBOARD,
    CARGANDO_LISTA_DATA,
    CARGANDO_LISTA_DATA_DERECHA,
    OBTENER_DATA_QUERY_1,
    OBTENER_DATA_QUERY_2,
    OBTENER_FECHA_QUERY,
    DEFAULT_EXPANDED_DATA,
    TABLA_EXPANDED_DATA,
    TOTAL_EXPANDED_DATA,
    TOTAL_QUERY_DATA,
    OBTENER_CODIGO_FECHA,
    MES_VALIDATOR,
    OBTENER_LISTA_DATA_COPIA_DASHBOARD,
    OBTENIENDO_FECHAS_ALL
} from '../../../Constants/PowerBi/PowerBi'

const INIT_STATE = {
    rex_lista_data_dashboard: [
        // {key: 0, zona: "Farma", sellIn: 10, sellOut: 20, sellOutLMSP: 30, sellOutLYSP: 40, sellOutPL4MSP: 50, proySellOut: 60, sellOutLM: 70, sellOutLY: 80,sellOutPL4M: 90, children: [
        //     {key: 'a0', zona: "Farma", sellIn: 10, sellOut: 20, sellOutLMSP: 30, sellOutLYSP: 40, sellOutPL4MSP: 50, proySellOut: 60, sellOutLM: 70, sellOutLY: 80,sellOutPL4M: 90, children: [
        //         {key: 'b0', zona: "Farma", sellIn: 10, sellOut: 20, sellOutLMSP: 30, sellOutLYSP: 40, sellOutPL4MSP: 50, proySellOut: 60, sellOutLM: 70, sellOutLY: 80,sellOutPL4M: 90},
        //         {key: 'b1', zona: "Farma", sellIn: 10, sellOut: 20, sellOutLMSP: 30, sellOutLYSP: 40, sellOutPL4MSP: 50, proySellOut: 60, sellOutLM: 70, sellOutLY: 80,sellOutPL4M: 90},
        //         {key: 'b2', zona: "Farma", sellIn: 10, sellOut: 20, sellOutLMSP: 30, sellOutLYSP: 40, sellOutPL4MSP: 50, proySellOut: 60, sellOutLM: 70, sellOutLY: 80,sellOutPL4M: 90},
        //         {key: 'b3', zona: "Farma", sellIn: 10, sellOut: 20, sellOutLMSP: 30, sellOutLYSP: 40, sellOutPL4MSP: 50, proySellOut: 60, sellOutLM: 70, sellOutLY: 80,sellOutPL4M: 90},
        //         {key: 'b4', zona: "Farma", sellIn: 10, sellOut: 20, sellOutLMSP: 30, sellOutLYSP: 40, sellOutPL4MSP: 50, proySellOut: 60, sellOutLM: 70, sellOutLY: 80,sellOutPL4M: 90},
        //         {key: 'b5', zona: "Farma", sellIn: 10, sellOut: 20, sellOutLMSP: 30, sellOutLYSP: 40, sellOutPL4MSP: 50, proySellOut: 60, sellOutLM: 70, sellOutLY: 80,sellOutPL4M: 90},
        //         {key: 'b6', zona: "Farma", sellIn: 10, sellOut: 20, sellOutLMSP: 30, sellOutLYSP: 40, sellOutPL4MSP: 50, proySellOut: 60, sellOutLM: 70, sellOutLY: 80,sellOutPL4M: 90},
        //         // {key: 'b7', zona: "Farma", sellIn: 10, sellOut: 20, sellOutLMSP: 30, sellOutLYSP: 40, sellOutPL4MSP: 50, proySellOut: 60, sellOutLM: 70, sellOutLY: 80,sellOutPL4M: 90},
        //         // {key: 'b8', zona: "Farma", sellIn: 10, sellOut: 20, sellOutLMSP: 30, sellOutLYSP: 40, sellOutPL4MSP: 50, proySellOut: 60, sellOutLM: 70, sellOutLY: 80,sellOutPL4M: 90},
        //         // {key: 'b9', zona: "Farma", sellIn: 10, sellOut: 20, sellOutLMSP: 30, sellOutLYSP: 40, sellOutPL4MSP: 50, proySellOut: 60, sellOutLM: 70, sellOutLY: 80,sellOutPL4M: 90},
        //         // {key: 'b10', zona: "Farma", sellIn: 10, sellOut: 20, sellOutLMSP: 30, sellOutLYSP: 40, sellOutPL4MSP: 50, proySellOut: 60, sellOutLM: 70, sellOutLY: 80,sellOutPL4M: 90},
        //         // {key: 'b11', zona: "Farma", sellIn: 10, sellOut: 20, sellOutLMSP: 30, sellOutLYSP: 40, sellOutPL4MSP: 50, proySellOut: 60, sellOutLM: 70, sellOutLY: 80,sellOutPL4M: 90},
        //         // {key: 'b12', zona: "Farma", sellIn: 10, sellOut: 20, sellOutLMSP: 30, sellOutLYSP: 40, sellOutPL4MSP: 50, proySellOut: 60, sellOutLM: 70, sellOutLY: 80,sellOutPL4M: 90},
        //         // {key: 'b13', zona: "Farma", sellIn: 10, sellOut: 20, sellOutLMSP: 30, sellOutLYSP: 40, sellOutPL4MSP: 50, proySellOut: 60, sellOutLM: 70, sellOutLY: 80,sellOutPL4M: 90},
        //         // {key: 'b14', zona: "Farma", sellIn: 10, sellOut: 20, sellOutLMSP: 30, sellOutLYSP: 40, sellOutPL4MSP: 50, proySellOut: 60, sellOutLM: 70, sellOutLY: 80,sellOutPL4M: 90},
        //         // {key: 'b15', zona: "Farma", sellIn: 10, sellOut: 20, sellOutLMSP: 30, sellOutLYSP: 40, sellOutPL4MSP: 50, proySellOut: 60, sellOutLM: 70, sellOutLY: 80,sellOutPL4M: 90},
        //         // {key: 'b16', zona: "Farma", sellIn: 10, sellOut: 20, sellOutLMSP: 30, sellOutLYSP: 40, sellOutPL4MSP: 50, proySellOut: 60, sellOutLM: 70, sellOutLY: 80,sellOutPL4M: 90},
        //         // {key: 'b17', zona: "Farma", sellIn: 10, sellOut: 20, sellOutLMSP: 30, sellOutLYSP: 40, sellOutPL4MSP: 50, proySellOut: 60, sellOutLM: 70, sellOutLY: 80,sellOutPL4M: 90},
        //         // {key: 'b18', zona: "Farma", sellIn: 10, sellOut: 20, sellOutLMSP: 30, sellOutLYSP: 40, sellOutPL4MSP: 50, proySellOut: 60, sellOutLM: 70, sellOutLY: 80,sellOutPL4M: 90},
        //         // {key: 'b19', zona: "Farma", sellIn: 10, sellOut: 20, sellOutLMSP: 30, sellOutLYSP: 40, sellOutPL4MSP: 50, proySellOut: 60, sellOutLM: 70, sellOutLY: 80,sellOutPL4M: 90},
        //         // {key: 'b20', zona: "Farma", sellIn: 10, sellOut: 20, sellOutLMSP: 30, sellOutLYSP: 40, sellOutPL4MSP: 50, proySellOut: 60, sellOutLM: 70, sellOutLY: 80,sellOutPL4M: 90},
        //         // {key: 'b21', zona: "Farma", sellIn: 10, sellOut: 20, sellOutLMSP: 30, sellOutLYSP: 40, sellOutPL4MSP: 50, proySellOut: 60, sellOutLM: 70, sellOutLY: 80,sellOutPL4M: 90},
        //         // {key: 'b22', zona: "Farma", sellIn: 10, sellOut: 20, sellOutLMSP: 30, sellOutLYSP: 40, sellOutPL4MSP: 50, proySellOut: 60, sellOutLM: 70, sellOutLY: 80,sellOutPL4M: 90},
        //         // {key: 'b23', zona: "Farma", sellIn: 10, sellOut: 20, sellOutLMSP: 30, sellOutLYSP: 40, sellOutPL4MSP: 50, proySellOut: 60, sellOutLM: 70, sellOutLY: 80,sellOutPL4M: 90},
        //     ]},
        //     {key: 'a1', zona: "Farma", sellIn: 10, sellOut: 20, sellOutLMSP: 30, sellOutLYSP: 40, sellOutPL4MSP: 50, proySellOut: 60, sellOutLM: 70, sellOutLY: 80,sellOutPL4M: 90, children: [
        //         {key: 'b24', zona: "Farma", sellIn: 10, sellOut: 20, sellOutLMSP: 30, sellOutLYSP: 40, sellOutPL4MSP: 50, proySellOut: 60, sellOutLM: 70, sellOutLY: 80,sellOutPL4M: 90},
        //         {key: 'b25', zona: "Farma", sellIn: 10, sellOut: 20, sellOutLMSP: 30, sellOutLYSP: 40, sellOutPL4MSP: 50, proySellOut: 60, sellOutLM: 70, sellOutLY: 80,sellOutPL4M: 90},
        //         {key: 'b26', zona: "Farma", sellIn: 10, sellOut: 20, sellOutLMSP: 30, sellOutLYSP: 40, sellOutPL4MSP: 50, proySellOut: 60, sellOutLM: 70, sellOutLY: 80,sellOutPL4M: 90},
        //         {key: 'b27', zona: "Farma", sellIn: 10, sellOut: 20, sellOutLMSP: 30, sellOutLYSP: 40, sellOutPL4MSP: 50, proySellOut: 60, sellOutLM: 70, sellOutLY: 80,sellOutPL4M: 90},
        //         {key: 'b28', zona: "Farma", sellIn: 10, sellOut: 20, sellOutLMSP: 30, sellOutLYSP: 40, sellOutPL4MSP: 50, proySellOut: 60, sellOutLM: 70, sellOutLY: 80,sellOutPL4M: 90},
        //         {key: 'b29', zona: "Farma", sellIn: 10, sellOut: 20, sellOutLMSP: 30, sellOutLYSP: 40, sellOutPL4MSP: 50, proySellOut: 60, sellOutLM: 70, sellOutLY: 80,sellOutPL4M: 90},
        //         {key: 'b30', zona: "Farma", sellIn: 10, sellOut: 20, sellOutLMSP: 30, sellOutLYSP: 40, sellOutPL4MSP: 50, proySellOut: 60, sellOutLM: 70, sellOutLY: 80,sellOutPL4M: 90},
        //         // {key: 'b31', zona: "Farma", sellIn: 10, sellOut: 20, sellOutLMSP: 30, sellOutLYSP: 40, sellOutPL4MSP: 50, proySellOut: 60, sellOutLM: 70, sellOutLY: 80,sellOutPL4M: 90},
        //         // {key: 'b32', zona: "Farma", sellIn: 10, sellOut: 20, sellOutLMSP: 30, sellOutLYSP: 40, sellOutPL4MSP: 50, proySellOut: 60, sellOutLM: 70, sellOutLY: 80,sellOutPL4M: 90},
        //         // {key: 'b33', zona: "Farma", sellIn: 10, sellOut: 20, sellOutLMSP: 30, sellOutLYSP: 40, sellOutPL4MSP: 50, proySellOut: 60, sellOutLM: 70, sellOutLY: 80,sellOutPL4M: 90},
        //         // {key: 'b34', zona: "Farma", sellIn: 10, sellOut: 20, sellOutLMSP: 30, sellOutLYSP: 40, sellOutPL4MSP: 50, proySellOut: 60, sellOutLM: 70, sellOutLY: 80,sellOutPL4M: 90},
        //         // {key: 'b35', zona: "Farma", sellIn: 10, sellOut: 20, sellOutLMSP: 30, sellOutLYSP: 40, sellOutPL4MSP: 50, proySellOut: 60, sellOutLM: 70, sellOutLY: 80,sellOutPL4M: 90},
        //     ]},
        // ]},
        // {key: 1, zona: "Farma", sellIn: 10, sellOut: 20, sellOutLMSP: 30, sellOutLYSP: 40, sellOutPL4MSP: 50, proySellOut: 60, sellOutLM: 70, sellOutLY: 80,sellOutPL4M: 90, children: [
        //     {key: 'a2', zona: "Farma", sellIn: 10, sellOut: 20, sellOutLMSP: 30, sellOutLYSP: 40, sellOutPL4MSP: 50, proySellOut: 60, sellOutLM: 70, sellOutLY: 80,sellOutPL4M: 90, children: [
        //         {key: 'b36', zona: "Farma", sellIn: 10, sellOut: 20, sellOutLMSP: 30, sellOutLYSP: 40, sellOutPL4MSP: 50, proySellOut: 60, sellOutLM: 70, sellOutLY: 80,sellOutPL4M: 90},
        //         {key: 'b37', zona: "Farma", sellIn: 10, sellOut: 20, sellOutLMSP: 30, sellOutLYSP: 40, sellOutPL4MSP: 50, proySellOut: 60, sellOutLM: 70, sellOutLY: 80,sellOutPL4M: 90},
        //         {key: 'b38', zona: "Farma", sellIn: 10, sellOut: 20, sellOutLMSP: 30, sellOutLYSP: 40, sellOutPL4MSP: 50, proySellOut: 60, sellOutLM: 70, sellOutLY: 80,sellOutPL4M: 90},
        //         {key: 'b39', zona: "Farma", sellIn: 10, sellOut: 20, sellOutLMSP: 30, sellOutLYSP: 40, sellOutPL4MSP: 50, proySellOut: 60, sellOutLM: 70, sellOutLY: 80,sellOutPL4M: 90},
        //         {key: 'b40', zona: "Farma", sellIn: 10, sellOut: 20, sellOutLMSP: 30, sellOutLYSP: 40, sellOutPL4MSP: 50, proySellOut: 60, sellOutLM: 70, sellOutLY: 80,sellOutPL4M: 90},
        //         {key: 'b41', zona: "Farma", sellIn: 10, sellOut: 20, sellOutLMSP: 30, sellOutLYSP: 40, sellOutPL4MSP: 50, proySellOut: 60, sellOutLM: 70, sellOutLY: 80,sellOutPL4M: 90},
        //         {key: 'b42', zona: "Farma", sellIn: 10, sellOut: 20, sellOutLMSP: 30, sellOutLYSP: 40, sellOutPL4MSP: 50, proySellOut: 60, sellOutLM: 70, sellOutLY: 80,sellOutPL4M: 90},
        //         // {key: 'b43', zona: "Farma", sellIn: 10, sellOut: 20, sellOutLMSP: 30, sellOutLYSP: 40, sellOutPL4MSP: 50, proySellOut: 60, sellOutLM: 70, sellOutLY: 80,sellOutPL4M: 90},
        //         // {key: 'b44', zona: "Farma", sellIn: 10, sellOut: 20, sellOutLMSP: 30, sellOutLYSP: 40, sellOutPL4MSP: 50, proySellOut: 60, sellOutLM: 70, sellOutLY: 80,sellOutPL4M: 90},
        //         // {key: 'b45', zona: "Farma", sellIn: 10, sellOut: 20, sellOutLMSP: 30, sellOutLYSP: 40, sellOutPL4MSP: 50, proySellOut: 60, sellOutLM: 70, sellOutLY: 80,sellOutPL4M: 90},
        //         // {key: 'b46', zona: "Farma", sellIn: 10, sellOut: 20, sellOutLMSP: 30, sellOutLYSP: 40, sellOutPL4MSP: 50, proySellOut: 60, sellOutLM: 70, sellOutLY: 80,sellOutPL4M: 90},
        //         // {key: 'b47', zona: "Farma", sellIn: 10, sellOut: 20, sellOutLMSP: 30, sellOutLYSP: 40, sellOutPL4MSP: 50, proySellOut: 60, sellOutLM: 70, sellOutLY: 80,sellOutPL4M: 90},
        //         // {key: 'b48', zona: "Farma", sellIn: 10, sellOut: 20, sellOutLMSP: 30, sellOutLYSP: 40, sellOutPL4MSP: 50, proySellOut: 60, sellOutLM: 70, sellOutLY: 80,sellOutPL4M: 90},
        //         // {key: 'b49', zona: "Farma", sellIn: 10, sellOut: 20, sellOutLMSP: 30, sellOutLYSP: 40, sellOutPL4MSP: 50, proySellOut: 60, sellOutLM: 70, sellOutLY: 80,sellOutPL4M: 90},
        //         // {key: 'b50', zona: "Farma", sellIn: 10, sellOut: 20, sellOutLMSP: 30, sellOutLYSP: 40, sellOutPL4MSP: 50, proySellOut: 60, sellOutLM: 70, sellOutLY: 80,sellOutPL4M: 90},
        //         // {key: 'b51', zona: "Farma", sellIn: 10, sellOut: 20, sellOutLMSP: 30, sellOutLYSP: 40, sellOutPL4MSP: 50, proySellOut: 60, sellOutLM: 70, sellOutLY: 80,sellOutPL4M: 90},
        //         // {key: 'b52', zona: "Farma", sellIn: 10, sellOut: 20, sellOutLMSP: 30, sellOutLYSP: 40, sellOutPL4MSP: 50, proySellOut: 60, sellOutLM: 70, sellOutLY: 80,sellOutPL4M: 90},
        //         // {key: 'b53', zona: "Farma", sellIn: 10, sellOut: 20, sellOutLMSP: 30, sellOutLYSP: 40, sellOutPL4MSP: 50, proySellOut: 60, sellOutLM: 70, sellOutLY: 80,sellOutPL4M: 90},
        //         // {key: 'b54', zona: "Farma", sellIn: 10, sellOut: 20, sellOutLMSP: 30, sellOutLYSP: 40, sellOutPL4MSP: 50, proySellOut: 60, sellOutLM: 70, sellOutLY: 80,sellOutPL4M: 90},
        //     ]},
        // ]},
    ],
    rex_lista_total_data_dashboard: [],
    rex_lista_data_copia_dashboard: [],
    rex_filtro_fecha_meses: [],
    rex_data_query_1: [],
    rex_data_query_2: [],
    rex_fecha_query: [],
    rex_mes_validator: false,
    rex_cargando_lista: false,
    rex_cargando_lista_derecha: false,
    rex_total_query_data: 0,
    rex_default_expanded_data: [],
    rex_tabla_expanded_data: [0,1],
    rex_total_expanded_data: [],
    rex_codigo_fecha: [],
    rex_tipo_visualizar_bi : "cajas",
    rex_query_one: [],
    rex_query_two: [],
    rex_query_three: [],
    rex_query_four: [],
    rex_position_query: 0,
    rex_obteniendo_fechas_all : true
}

export default (state = INIT_STATE, action) => {
    switch (action.type) {
        case OBTENER_LISTA_DATA_DASHBOARD :
            return {
                ...state,
                rex_lista_data_dashboard : action.payload
            }
        case OBTENER_LISTA_TOTAL_DATA_DASHBOARD :
            return {
                ...state,
                rex_lista_total_data_dashboard : action.payload
            }
        case OBTENER_LISTA_DATA_COPIA_DASHBOARD :
            return {
                ...state,
                rex_lista_data_copia_dashboard : action.payload
            }
        case OBTENER_DATA_QUERY_1 :
            return {
                ...state,
                rex_data_query_1 : action.payload
            }
        case OBTENER_DATA_QUERY_2 :
            return {
                ...state,
                rex_data_query_2 : action.payload
            }
        case OBTENER_FECHA_QUERY :
            return {
                ...state,
                rex_fecha_query : action.payload
            }
        case MES_VALIDATOR :
            return {
                ...state,
                rex_mes_validator : action.payload
            }
        case CARGANDO_LISTA_DATA :
            return {
                ...state,
                rex_cargando_lista : action.payload
            }
        case CARGANDO_LISTA_DATA_DERECHA :
            return {
                ...state,
                rex_cargando_lista_derecha : action.payload
            }
        case DEFAULT_EXPANDED_DATA :
            return {
                ...state,
                rex_default_expanded_data : action.payload
            }
        case TABLA_EXPANDED_DATA :
            return {
                ...state,
                rex_tabla_expanded_data : action.payload
            }
        case TOTAL_EXPANDED_DATA :
            return {
                ...state,
                rex_total_expanded_data : action.payload
            }
        case TOTAL_QUERY_DATA :
            return {
                ...state,
                rex_total_query_data : action.payload
            }
        case OBTENER_CODIGO_FECHA :
            return {
                ...state,
                rex_codigo_fecha : action.payload
            }
        case "GUARDAR_TIPO_VISUALIZAR_BI":
            return {
                ...state,
                rex_tipo_visualizar_bi : action.payload
            }
        case OBTENIENDO_FECHAS_ALL:{
            return {
                ...state,
                rex_obteniendo_fechas_all : action.payload
            }
        }
        default:
            return state;
    }
}