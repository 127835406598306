import {
    GET_USERS_HOME_MODULES_CONNECTED,
    GET_MODULE_SELECTED_HOME_MODULES,
    GET_MODULE_ACTIVE_CLICK_HOME_MODULES,
    GET_MODULE_ACTIVE_HOVER_HOME_MODULES
} from '../../../Constants/HomeModules/HomeModules'

export const RegisterUsersConnectedHomeModulesReducer = (data) => async (dispatch) => {
    dispatch({
        type: GET_USERS_HOME_MODULES_CONNECTED,
        payload: data
    })
}

export const SelectModuleSelectedHomeModulesReducer = ( id ) => async (dispatch) => {

    dispatch({
        type: GET_MODULE_SELECTED_HOME_MODULES,
        payload: id
    })

    dispatch({
        type: GET_MODULE_ACTIVE_CLICK_HOME_MODULES,
        payload: id
    })

    dispatch({
        type: GET_MODULE_ACTIVE_HOVER_HOME_MODULES,
        payload: id
    })
}

export const SelectModuleActiveClickHomeModulesReducer = ( id ) => async (dispatch) => {

    dispatch({
        type: GET_MODULE_ACTIVE_CLICK_HOME_MODULES,
        payload: id
    })
}
export const SelectModuleActiveHoverHomeModulesReducer = ( id ) => async (dispatch) => {

    dispatch({
        type: GET_MODULE_ACTIVE_HOVER_HOME_MODULES,
        payload: id
    })
}