import React, { useEffect, useState } from 'react'
import { Button, Modal } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { CancelConfirmDeleteClientsManageReducer, DeleteMasterClientManageReducer } from '../../../Redux/Actions/Manage/MasterClients/MasterClients'

const ModalConfirmDeleteClients = ({dateClients, dateManuals}) => {

    const [ deletingClients, setDeletingClients ] = useState(false)

    const { 
        rex_show_modal_alert_delete_clients,
        rex_data_alert_delete_clients
    } = useSelector(({masterClients}) => masterClients)

    const dispatch = useDispatch()

    const confirmDeleteClients = async () => {
        
        setDeletingClients(true)
        let idsDelete = rex_data_alert_delete_clients.idsDelete
        const { response, alertDelete } = await dispatch(DeleteMasterClientManageReducer( idsDelete, dateClients, dateManuals, true ))
        if( response && !alertDelete ){
            dispatch(CancelConfirmDeleteClientsManageReducer())
        }
        setDeletingClients(false)
    }

    return (
        <Modal
            title={null}
            open={rex_show_modal_alert_delete_clients}
            closable={false}
            width={500}
            footer={null}
            zIndex={100000000}
            bodyStyle={{minHeight:"150px", maxHeight:"70vh"}}
            className='Modal-Confirm-Delete-Products'
        >
            <div>

                <div className="Container-Message-Delete-Products">
                    {
                        rex_data_alert_delete_clients.listClients?.length > 0 && rex_data_alert_delete_clients.listClients.map(cli => (
                            <>
                                <div className="Title-Confirm-Delete-Products">{cli.info}:</div>
                                {cli.data.map(msg => {
                                    return <div>- {msg}</div>    
                                })}
                            </>                                
                        ))
                    }
                </div>
            </div>
            <div style={{display: "flex", justifyContent:"center", columnGap: "10px"}}>
                <Button
                    onClick={() => dispatch(CancelConfirmDeleteClientsManageReducer())}
                    danger
                    style={{
                        height: "34px",
                        paddingLeft: "15px",
                        paddingRight: "15px",
                        display: "flex",
                        alignItems: "center",
                        borderRadius: "20px",
                        fontSize: "13px",
                        fontFamily: "Inter",
                    }}
                >
                    Cancelar
                </Button>
                <Button 
                    onClick={confirmDeleteClients}
                    loading={deletingClients}
                    type="primary" 
                    style={{
                        background: "#2AD295",
                        height: "34px",
                        paddingLeft: "15px",
                        paddingRight: "15px",
                        display: "flex",
                        alignItems: "center",
                        borderRadius: "20px",
                        color: "white",
                        fontSize: "13px",
                        fontFamily: "Inter",
                    }}
                >
                    Confirmar
                </Button>

            </div>
        </Modal>
    )
}

export default ModalConfirmDeleteClients