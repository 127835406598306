import React, { useState, useRef } from "react"
import { useDispatch, useSelector } from "react-redux"
import { Row, Col, Tabs, Card, Button, Tooltip } from 'antd'
import IconoExcelDescargar from '../../Assets/Images/PowerBi/iconoexcel.png'
import IconoPDFDescargar from '../../Assets/Images/PowerBi/iconopdf.png'
import IconoEnviarCorreoManual from '../../Assets/Images/PowerBi/correoManual.png'
import IconoEnviarCorreo from '../../Assets/Images/PowerBi/iconoCorreoEnviar.png'
import SelectCheckBox from "./Componentes/SelectCheckBox"
import SelectRadio from "./Componentes/SelectRadio"
import BotonFiltroPrincipal from "./Componentes/BotonFiltroPrincipal"
import Moment from 'moment'
import TituloPowerBi from "./Componentes/TituloPowerBi"
import { DonwloadDataExcelPowerBiReducer } from "../../Redux/Actions/PowerBi/PowerBi"
import EnviarCorreoManual from "./Correo/EnviarCorreoManual"
import EnviarCorreoAutomatico from "./Correo/EnviarCorreoAutomatico"
import { EnviarFileDashboardReducer } from "../../Redux/Actions/Dashboards/Dashboards"

const BotonSelectFiltro = () => {

    const [botonActive, setBotonActive] = useState("cajas")
    const [titleActive, setTitleActive] = useState(true)

    const [ mostrarManual, setMostrarManual ] = useState(false)
    const [ mostrarAutomatico, setMostrarAutomatico ] = useState(false)

    const dispatch = useDispatch()

    const {
        rex_fecha_anios,
        rex_fecha_meses,
        rex_data_formato,
        rex_data_talla,
        rex_data_conteo,
        rex_data_sku,
        rex_data_descripcion_material,
        rex_data_cliente_hml,
        rex_data_cliente_hml_seleccionado,
        rex_data_formato_seleccionado,
        rex_data_talla_seleccionado,
        rex_data_conteo_seleccionado,
        rex_data_sku_seleccionado,
        rex_data_descripcion_material_seleccionado
    } = useSelector(({filtros}) => filtros)

    let month = Moment().month()
    let year = Moment().year()
    const refdownloadpdf = useRef(null)

    return(
        <div style={{position: 'relative'}}>
            <div 
                className="containerTextTitle"
                onMouseEnter={() => setTitleActive(false)}
                onMouseLeave={() => setTimeout(() => {setTitleActive(true)}, 500)}
            >
                <TituloPowerBi 
                    active={titleActive}
                    titulo="Daily Sell Out Summary" 
                />
            </div>

            <div className="containerButtonDownload-Dos">

                    
                <div style={{display:'flex', alignItems:'center'}} className="downloadText">Email: </div>
                <Button
                    className="botonDownload excelboton Email"
                    onClick={() => {
                        setMostrarManual(true)
                    }}
                    style={{
                        width: '30px',
                        // position: 'relative',
                    }}
                >
                    <img 
                        src={IconoEnviarCorreoManual}
                        style={{
                            width: '25px',
                            height:'auto'
                        }}
                    />
                </Button>
                <a style={{display: 'none'}} ref={refdownloadpdf} href="https://backend-spider-kimberly-corporativa.grow-corporate.com/Sistema/bolivia/pdf/Daily%20BO%20Junio%202023.pdf" download target="_blank"></a>
                <Button
                    className="botonDownload pdfboton Email"
                    style={{
                        width: '30px',
                        position: 'relative',
                        marginTop:'-4px'
                    }}
                    onClick={ () => {
                        setMostrarAutomatico(true)
                    }}
                >
                    <img 
                        src={IconoEnviarCorreo} 
                        style={{
                            width: '25px',
                        }}
                    />
                </Button>
            </div>

                
            <div className="containerButtonDownload">
                    <div className="downloadText">Descargar: </div>
                    <Button
                        className="botonDownload excelboton"
                        onClick={async () => {
                            const fileUrl = await dispatch(DonwloadDataExcelPowerBiReducer())
                            const link = document.createElement('a')
                            link.href = fileUrl
                            link.download = 'Excel Power Bi.xlsx'
                            link.click()
                        }}
                        style={{
                            width: '50px',
                            position: 'relative',
                        }}
                    >
                        <img 
                            src={IconoExcelDescargar} 
                            style={{
                                width: '36px',
                            }}
                        />
                    </Button>
                    <a 
                        style={{display: 'none'}} 
                        ref={refdownloadpdf} 
                        // href="/public/DashboardRetailChannel.pdf" 
                        // href="../../../public/DashboardRetailChannel.pdf" 
                        // download
                        // href="/public/Dashboard Retail Channel.pdf" 
                        href="https://backend-spider-kimberly-corporativa.grow-corporate.com/Sistema/bolivia/pdf/Daily%20BO%20Junio%202023.pdf" 
                        target="_blank"></a>
                    <Button
                        className="botonDownload pdfboton"
                        style={{
                            width: '50px',
                            position: 'relative',
                        }}
                        onClick={async () => {
                            // refdownloadpdf.current.click()
                            const fileUrl = await dispatch(EnviarFileDashboardReducer())
                            const link = document.createElement('a')
                            link.href = fileUrl
                            link.download = 'Dashboard Retail Channel.pdf'
                            link.click()
                        }}
                    >
                        <img 
                            src={IconoPDFDescargar} 
                            style={{
                                width: '36px',
                            }}
                        />
                    </Button>
            </div>
            <Tabs
                style={{overflow: 'unset'}}
                className="contenedorPestañas"
            >
                <Tabs.TabPane 
                    tab={
                        <div className="pestañaFiltro">
                            <div className="imagenIconoFiltro"></div> Filtros
                        </div>
                    } 
                    key="item-1">
                    <Card className="cardFiltro">
                        <Row gutter={1} className="rowFiltroSelect" justify="space-between">
                            <Col className="gutter-row" lg={2} md={4} sm={7} xs={22}>
                                <SelectRadio
                                    titulo="Año"
                                    data={rex_fecha_anios}
                                    labelKey="anio"
                                    valueKey="key"
                                    placeholder="Años"
                                    includeFilter={false}
                                    defaultAnio={rex_fecha_anios.find((dat) => dat.anio == year)}
                                />
                            </Col>
                            <Col className="gutter-row" lg={2} md={4} sm={7} xs={22}>
                                <SelectRadio 
                                    titulo="Mes"
                                    data={rex_fecha_meses}
                                    labelKey="mes"
                                    valueKey="key"
                                    placeholder="Mes"
                                    includeFilter={true}
                                    filterPlaceholder="Buscar"
                                    clearFilterOnClose={true}
                                    defaultAnio={rex_fecha_meses.find((dat) => dat.key == month+1 )}
                                />
                            </Col>
                            <Col className="gutter-row" lg={3} md={5} sm={7} xs={22}>
                                <SelectCheckBox 
                                    titulo="Cliente HML"
                                    data={rex_data_cliente_hml}
                                    seleccionado={rex_data_cliente_hml_seleccionado}
                                    placeholder="Todas"
                                    manySelectedPlaceholder="Selección Múltiple"
                                    allSelectedPlaceholder="Todas"
                                    selectAllText="Seleccionar Todo"
                                    filterPlaceholder="Buscar"
                                    numberDisplayed={1}
                                    labelKey="dato"
                                    valueKey="key"
                                    multiple={true}
                                    includeFilter={true}
                                    clearFilterOnClose={true}
                                    includeSelectAll={true}
                                />
                            </Col>
                            <Col className="gutter-row" lg={3} md={5} sm={7} xs={22}>
                                <SelectCheckBox 
                                    titulo="Formato"
                                    data={rex_data_formato}
                                    seleccionado={rex_data_formato_seleccionado}
                                    placeholder="Todas"
                                    manySelectedPlaceholder="Selección Múltiple"
                                    allSelectedPlaceholder="Todas"
                                    selectAllText="Seleccionar Todo"
                                    filterPlaceholder="Buscar"
                                    numberDisplayed={1}
                                    labelKey="dato"
                                    valueKey="key"
                                    multiple={true}
                                    includeFilter={true}
                                    clearFilterOnClose={true}
                                    includeSelectAll={true}
                                />
                            </Col>
                            <Col className="gutter-row" lg={3} md={4} sm={7} xs={22}>
                                <SelectCheckBox 
                                    titulo="Talla"
                                    data={rex_data_talla}
                                    seleccionado={rex_data_talla_seleccionado}
                                    placeholder="Todas"
                                    manySelectedPlaceholder="Selección Múltiple"
                                    allSelectedPlaceholder="Todas"
                                    selectAllText="Seleccionar Todo"
                                    filterPlaceholder="Buscar"
                                    numberDisplayed={1}
                                    labelKey="dato"
                                    valueKey="key"
                                    multiple={true}
                                    includeFilter={true}
                                    clearFilterOnClose={true}
                                    includeSelectAll={true}
                                />
                            </Col>
                            <Col className="gutter-row" lg={3} md={5} sm={7} xs={22}>
                                <SelectCheckBox 
                                    titulo="Conteo"
                                    data={rex_data_conteo}
                                    seleccionado={rex_data_conteo_seleccionado}
                                    placeholder="Todas"
                                    manySelectedPlaceholder="Selección Múltiple"
                                    allSelectedPlaceholder="Todas"
                                    selectAllText="Seleccionar Todo"
                                    filterPlaceholder="Buscar"
                                    numberDisplayed={1}
                                    labelKey="dato"
                                    valueKey="key"
                                    multiple={true}
                                    includeFilter={true}
                                    clearFilterOnClose={true}
                                    includeSelectAll={true}
                                />
                            </Col>
                            <Col className="gutter-row" lg={3} md={5} sm={7} xs={22}>
                                <SelectCheckBox 
                                    titulo="SKU"
                                    data={rex_data_sku}
                                    seleccionado={rex_data_sku_seleccionado}
                                    placeholder="Todas"
                                    manySelectedPlaceholder="Selección Múltiple"
                                    allSelectedPlaceholder="Todas"
                                    selectAllText="Seleccionar Todo"
                                    filterPlaceholder="Buscar"
                                    numberDisplayed={1}
                                    labelKey="dato"
                                    valueKey="key"
                                    multiple={true}
                                    includeFilter={true}
                                    clearFilterOnClose={true}
                                    includeSelectAll={true}
                                />
                            </Col>
                            <Col className="gutter-row" lg={3} md={5} sm={7} xs={22}>
                                <SelectCheckBox 
                                    titulo="Descripción Material"
                                    data={rex_data_descripcion_material}
                                    seleccionado={rex_data_descripcion_material_seleccionado}
                                    placeholder="Todas"
                                    manySelectedPlaceholder="Selección Múltiple"
                                    allSelectedPlaceholder="Todas"
                                    selectAllText="Seleccionar Todo"
                                    filterPlaceholder="Buscar"
                                    numberDisplayed={1}
                                    labelKey="dato"
                                    valueKey="key"
                                    multiple={true}
                                    includeFilter={true}
                                    clearFilterOnClose={true}
                                    includeSelectAll={true}
                                />
                            </Col>
                            <Col lg={0} md={4} sm={7}></Col>
                            <Col lg={0} md={3} sm={0}></Col>
                        </Row>
                    </Card>
                </Tabs.TabPane>
                <Tabs.TabPane 
                    tab={
                        <div className="pestañaVisualizar">
                            <div className="imagenIconoOjo"></div> Visualizar
                        </div>
                    } 
                    key="item-2"
                >
                    <Row gutter={1} className="filaContenedorPestañas">
                        <Col className="gutter-row" xl={4} lg={4} md={12} sm={12} xs={24}>
                            <div onClick={() => setBotonActive("cajas")}>
                                <BotonFiltroPrincipal 
                                    titulo="Cajas"
                                    keylabel="cajas"
                                    activo={botonActive == "cajas" ? true : false}
                                />
                            </div>
                        </Col>
                        <Col className="gutter-row" xl={4} lg={4} md={12} sm={12} xs={24}>
                            <div onClick={() => setBotonActive("soniv")}>
                                <BotonFiltroPrincipal 
                                    titulo="NIV"
                                    keylabel="soniv"
                                    activo={botonActive == "soniv" ? true : false}
                                />
                            </div>
                        </Col>
                        <Col className="gutter-row" xl={4} lg={4} md={12} sm={12} xs={24}>
                            <div onClick={() => setBotonActive("ventaPublica")}>
                                <BotonFiltroPrincipal 
                                    titulo="PVP"
                                    keylabel="ventapublico"
                                    activo={botonActive == "ventaPublica" ? true : false}
                                />
                            </div>
                        </Col>
                        <Col className="gutter-row" xl={4} lg={4} md={12} sm={12} xs={24}>
                            <div onClick={() => setBotonActive("toneladas")}>
                                <BotonFiltroPrincipal 
                                    titulo="Toneladas"
                                    keylabel="toneladas"
                                    activo={botonActive == "toneladas" ? true : false}
                                />
                            </div>
                        </Col>
                        <Col className="gutter-row" xl={4} lg={4} md={12} sm={12} xs={24}>
                            <div onClick={() => setBotonActive("gsu")}>
                                <BotonFiltroPrincipal 
                                    titulo="GSU"
                                    keylabel="gsu"
                                    activo={botonActive == "gsu" ? true : false}
                                />
                            </div>
                        </Col>
                    </Row>
                </Tabs.TabPane>
            </Tabs>
            <EnviarCorreoManual
                openModal = {mostrarManual}
                setOpenModal= {setMostrarManual}
            />
            <EnviarCorreoAutomatico
                openModal = {mostrarAutomatico}
                setOpenModal= {setMostrarAutomatico}
            />
            </div>
    )
}

export default BotonSelectFiltro