import React, { useState } from 'react'
import { Input, Modal, Table, Space, Skeleton, InputNumber, Tooltip } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import TableGlobal from '../../Global/TableGlobal'
import { 
    FilterOutlined,
    ExclamationCircleFilled,
    CheckCircleOutlined,
    CloseCircleOutlined,
    LoadingOutlined
} from '@ant-design/icons'
import FormCreateMSL from './FormCreateMSL'
import ModalMessageAlert from './ModalMessageAlert'
import EditOptionButtonGlobal from '../../../Assets/Images/Global/EditOptionButtonGlobal'
import TrashButtonGlobalSimpleColor from '../../../Assets/Images/Global/TrashButtonGlobalSimpleColor'
import { CambiarTxtFilterMasterProductsReducer, DeleteMasterProductManageReducer, EditMasterProductManageReducer, EditRowMasterProduct, EditValueMasterProduct, GetMasterProductsManageReducer, SaveMasterProductsReducer } from '../../../Redux/Actions/Manage/MasterProducts/MasterProducts'
import ModalConfirmDeleteProducts from '../MasterProducts/ModalConfirmDeleteProducts'
import moment from 'moment'
import FilterSimpleSelectGlobal from '../../Global/FilterSimpleSelectGlobal'
import dayjs from 'dayjs';

const ViewProducts = ( {data, componentExtraLeftPagination, dateProducts, dateMsl, dateNewProducts, setDateMSL} ) => {

    const [pageTableGlobal, setPageTableGlobal ] = useState(1)
    const [pageSizeTableGlobal, setPageSizeTableGlobal ]= useState(20)
    const [ dateFilter, setDateFilter ] = useState('')
    const [ idsSelectedMsl, setIdsSelectedMsl ] = useState([])
    const [ openModal, setOpenModal ] = useState('')
    const [ typeActionMsl, setTypeActionMsl ] = useState('')
    const [ dataMsl, setDataMsl ] = useState({})
    const [ selectedRowKeys, setSelectedRowKeys ] = useState([])
    const [ valueCheckbox, setValueCheckbox ]     = useState(false)
    const [ loadingEdit, setLoadingEdit ] = useState({id : null, status: false})

    const dispatch = useDispatch()
    const { confirm } = Modal
    
    const { 
        rex_data_form_edit_master_product,
        rex_loading_data_master_products_manage
    } = useSelector(({masterProducts}) => masterProducts)

    const { 
        rex_uniqueChannels,
        rex_unique_zona,
        rex_unique_cliente,
        rex_unique_sucursal,
        rex_unique_gba,
        rex_unique_customer_group,
        rex_filters_products_total
    } = useSelector(({filtersProducts}) => filtersProducts)

    const {
        rex_txt_unique_gba,
        rex_txt_uniqueChannels,
        rex_txt_unique_zona,
        rex_txt_unique_cliente,
        rex_txt_unique_sucursal,
        rex_txt_customer_group
    } = rex_filters_products_total.rex_filter_products

    const getColumnSearchProps = (dataIndex, nameColumn) => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys, confirm }) => (
            <div
                style={{
                    padding: 8,
                }}
                onKeyDown={(e) => e.stopPropagation()}
            >
                <Input.Search
                    placeholder={`Filtrar ${nameColumn}`}
                    value={selectedKeys[0]}
                    onChange={(e) => {
                        setSelectedKeys(e.target.value ? [e.target.value] : [])
                        confirm({
                            closeDropdown: false,
                        })
                    }}
                    style={{
                        display: 'block',
                    }}
                />
            </div>
        ),
        filterIcon: () => (<FilterOutlined/>),
        onFilter: (value, record) => {
            return record[dataIndex] != null ? record[dataIndex].toString().toLowerCase().includes(value.toLowerCase()) : false
        }
    })

    const filterChannelComponent = () => {
        return (
                <div style={{display:"flex", justifyContent:"space-between", alignItems:"center" ,columnGap:"5px"}}>
                    <FilterSimpleSelectGlobal
                        typeFilter="Customer Group"
                        value={rex_txt_customer_group == "" ? null : rex_txt_customer_group}
                        list_data = {rex_unique_customer_group}
                        dateFilter={dateFilter}
                        onAction={CambiarTxtFilterMasterProductsReducer}
                        view="products"
                    />
                    <FilterSimpleSelectGlobal
                        typeFilter="GBA"
                        list_data = {rex_unique_gba}
                        value={rex_txt_unique_gba == "" ? null : rex_txt_unique_gba}
                        view="products"
                        onAction={CambiarTxtFilterMasterProductsReducer}
                    />
                    <FilterSimpleSelectGlobal
                        typeFilter="Canal"
                        value={rex_txt_uniqueChannels == "" ? null : rex_txt_uniqueChannels}
                        list_data = {rex_uniqueChannels}
                        dateFilter={dateFilter}
                        onAction={CambiarTxtFilterMasterProductsReducer}
                        view="products"
                    />
                    <FilterSimpleSelectGlobal
                        typeFilter="Zona"
                        value={rex_txt_unique_zona == "" ? null : rex_txt_unique_zona}
                        list_data = {rex_unique_zona}
                        dateFilter={dateFilter}
                        onAction={CambiarTxtFilterMasterProductsReducer}
                        view="products"
                    />
                    <FilterSimpleSelectGlobal
                        typeFilter="Cliente HML"
                        value={rex_txt_unique_cliente == "" ? null : rex_txt_unique_cliente}
                        list_data = {rex_unique_cliente}
                        dateFilter={dateFilter}
                        onAction={CambiarTxtFilterMasterProductsReducer}
                        view="products"
                    />
                    <FilterSimpleSelectGlobal
                        typeFilter="Sucursal HML"
                        value={rex_txt_unique_sucursal == "" ? null : rex_txt_unique_sucursal}
                        list_data = {rex_unique_sucursal}
                        dateFilter={dateFilter}
                        onAction={CambiarTxtFilterMasterProductsReducer}
                        view="products"
                    />
                </div>
            )
    }

    const parseNumber = (value) => {

        const number = parseFloat(value);
        const numberFormated = number.toLocaleString("es-US", { minimumFractionDigits: 2, maximumFractionDigits: 2 });

        return numberFormated
    }

    const editRow = async (id) => {

        confirm({
            title   : "Editar Producto",
            icon    : <ExclamationCircleFilled/>,
            content : "Está seguro que desea editar el producto?",
            zIndex  : 100000000,
            async onOk(){
                setLoadingEdit({...loadingEdit, id : id, status : true})
                const response = await dispatch(EditMasterProductManageReducer(id, 'products', dateFilter))
                setLoadingEdit({...loadingEdit, id : null, status : false})
            },
            okCancel(){}
        })
    }

    const deleteMasterProducts = (ids, resetValues) => {

        if(ids.length > 0){
            confirm({
                title   : "Eliminar Producto",
                icon    : <ExclamationCircleFilled/>,
                content : `¿Está seguro que desea eliminar ${ids.length > 1 ? 'los productos': 'el producto'}?`,
                zIndex  : 100000000,
                async onOk(){
                    const { response, alertDelete } = await dispatch(DeleteMasterProductManageReducer(ids, false, dateFilter))
                    if(response && !alertDelete){
                        setValueCheckbox(false)
                        resetValues([])
                    }
                },
                okCancel(){}
            })
        }
    }

    const deleteMasterProduct = (id) => {
        confirm({
            title   : "Eliminar Producto",
            icon    : <ExclamationCircleFilled/>,
            content : "¿Está seguro que desea eliminar el producto?",
            zIndex  : 100000000,
            async onOk(){
                await dispatch(DeleteMasterProductManageReducer(id, false, dateFilter))
            },
            okCancel(){}
        })
    }

    const columnsProducts = [
        {
            title: 'Item',
            dataIndex: 'item',
            align: 'center',
            render: (text, record, index) => (pageTableGlobal - 1) * pageSizeTableGlobal + index + 1,
            fixed: 'left',
            width : '50px',
        },
        Table.SELECTION_COLUMN,
        {
            title: 'Cod SKU',
            dataIndex: 'cod_sku',
            ...getColumnSearchProps('cod_sku', 'cod sku'),
            sorter: (a, b) => a.cod_sku ? a.cod_sku.localeCompare(b.cod_sku) : false,
            showSorterTooltip: false,
            align: 'center',
            render:(_, record) => {
                const dataRow = rex_data_form_edit_master_product.find(dat => dat.id == record.id)
            
                return <div className='Table-Contain-Text-Global'>
                    {
                        dataRow
                        ? <Input
                            defaultValue={record.cod_sku? record.cod_sku : ''}
                            value={dataRow.data.cod_sku}
                            size="small"
                            placeholder='Cod SKU'
                            onChange={(e) => dispatch(EditValueMasterProduct('cod_sku', e.target.value, record.id))}
                        />
                        : record.cod_sku? record.cod_sku : ''
                    }
                </div>
            },
            fixed: 'left',
            ellipsis : true,
            width : 120
        },
        {
            title: 'Ship SKU',
            dataIndex: 'ship_sku',
            ...getColumnSearchProps('ship_sku', 'ship sku'),
            sorter: (a, b) => a.ship_sku ? a.ship_sku.localeCompare(b.ship_sku) : false,
            showSorterTooltip: false,
            align: 'center',
            render:(_, record) => {
                const dataRow = rex_data_form_edit_master_product.find(dat => dat.id == record.id)
                return <div className='Table-Contain-Text-Global'>
                    {
                        dataRow
                        ? <Input
                            defaultValue={record.ship_sku? record.ship_sku : ''}
                            value={dataRow.data.ship_sku}
                            size="small"
                            placeholder='Ship sku'
                            onChange={(e) => dispatch(EditValueMasterProduct('ship_sku', e.target.value, record.id))}
                        />
                        : record.ship_sku? record.ship_sku : ''
                    }
                </div>
            },
            fixed: 'left',
            ellipsis : true,
            width : 220
        },
        {
            title: 'Producto Foco',
            dataIndex: 'producto_foco',
            ...getColumnSearchProps('producto_foco', 'producto foco'),
            sorter: (a, b) => a.producto_foco ? a.producto_foco.localeCompare(b.producto_foco) : false,
            showSorterTooltip: false,
            align: 'center',
            render:(_, record) => {
                const dataRow = rex_data_form_edit_master_product.find(dat => dat.id == record.id)
                return <div className='Table-Contain-Text-Global'>
                    {
                        dataRow
                        ? <Input
                            defaultValue={record.mslpo? record.mslpo : ''}
                            value={dataRow.data.mslpo}
                            size="small"
                            placeholder='MSLPO'
                            onChange={(e) => dispatch(EditValueMasterProduct('mslpo', e.target.value, record.id))}
                        />
                        : record.producto_foco
                    }
                </div>
            },
            ellipsis : true,
            width : 130
        },
        {
            title: 'Cod sales organización',
            dataIndex: 'cod_sales_organization',
            ...getColumnSearchProps('cod_sales_organization', 'cod sales organización'),
            sorter: (a, b) => a.cod_sales_organization ? a.cod_sales_organization.localeCompare(b.cod_sales_organization) : false,
            showSorterTooltip: false,
            align: 'center',
            render:(_, record) => {
                const dataRow = rex_data_form_edit_master_product.find(dat => dat.id == record.id)
                return <div className='Table-Contain-Text-Global'>
                    {
                        dataRow
                        ? <Input
                            defaultValue={record.cod_sales_organization? record.cod_sales_organization : ''}
                            value={dataRow.data.cod_sales_organization}
                            size="small"
                            placeholder='Cod sales organización'
                            onChange={(e) => dispatch(EditValueMasterProduct('cod_sales_organization', e.target.value, record.id))}
                        />
                        : record.cod_sales_organization? record.cod_sales_organization : ''
                    }
                </div>
            },
            ellipsis : true,
            width : 130
        },
        {
            title: 'Sales organizacion',
            dataIndex: 'sales_organization',
            ...getColumnSearchProps('sales_organization', 'sales organizacion'),
            sorter: (a, b) => a.sales_organization ? a.sales_organization.localeCompare(b.sales_organization) : false,
            showSorterTooltip: false,
            align: 'center',
            render:(_, record) => {
                const dataRow = rex_data_form_edit_master_product.find(dat => dat.id == record.id)            
                return <div className='Table-Contain-Text-Global'>
                    {
                        dataRow
                        ? <Input
                            defaultValue={record.sales_organization? record.sales_organization : ''}
                            value={dataRow.data.sales_organization}
                            size="small"
                            placeholder='Sales organizacion'
                            onChange={(e) => dispatch(EditValueMasterProduct('sales_organization', e.target.value, record.id))}
                        />
                        : record.sales_organization? record.sales_organization : ''
                    }
                </div>
            },
            ellipsis : true,
            width : 140
        },
        {
            title: 'Cod Bussiness',
            dataIndex: 'cod_bussiness',
            ...getColumnSearchProps('cod_bussiness', 'cod bussiness'),
            sorter: (a, b) => a.cod_bussiness ? a.cod_bussiness.localeCompare(b.cod_bussiness) : false,
            showSorterTooltip: false,
            align: 'center',
            render:(_, record) => {
                const dataRow = rex_data_form_edit_master_product.find(dat => dat.id == record.id)
                return <div className='Table-Contain-Text-Global'>
                    {
                        dataRow
                        ? <Input
                            defaultValue={record.cod_bussiness? record.cod_bussiness : ''}
                            value={dataRow.data.cod_bussiness}
                            size="small"
                            placeholder='Cod Bussiness'
                            onChange={(e) => dispatch(EditValueMasterProduct('cod_bussiness', e.target.value, record.id))}
                        />
                        : record.cod_bussiness? record.cod_bussiness : ''
                    }
                </div>
            },
            ellipsis : true,
            width : 130
        },
        {
            title: 'Bussiness',
            dataIndex: 'bussiness',
            ...getColumnSearchProps('bussiness', 'bussiness'),
            sorter: (a, b) => a.bussiness ? a.bussiness.localeCompare(b.bussiness) : false,
            showSorterTooltip: false,
            align: 'center',
            render:(_, record) => {
                const dataRow = rex_data_form_edit_master_product.find(dat => dat.id == record.id)
                return<div className='Table-Contain-Text-Global'>
                    {
                        dataRow
                        ? <Input
                            defaultValue={record.bussiness? record.bussiness : ''}
                            value={dataRow.data.bussiness}
                            size="small"
                            placeholder='Bussiness'
                            onChange={(e) => dispatch(EditValueMasterProduct('bussiness', e.target.value, record.id))}
                        />
                        : record.bussiness? record.bussiness : ''
                    }
                </div>
            },
            ellipsis : true,
            width : 120
        },
        {
            title: 'Cod EAN 13',
            dataIndex: 'cod_ean13',
            ...getColumnSearchProps('cod_ean13', 'cod ean 13'),
            sorter: (a, b) => a.cod_ean13 ? a.cod_ean13.localeCompare(b.cod_ean13) : false,
            showSorterTooltip: false,
            align: 'center',
            render:(_, record) => {
                const dataRow = rex_data_form_edit_master_product.find(dat => dat.id == record.id)
                return <div className='Table-Contain-Text-Global'>
                    {
                        dataRow
                        ? <Input
                            defaultValue={record.cod_ean13? record.cod_ean13 : ''}
                            value={dataRow.data.cod_ean13}
                            size="small"
                            placeholder='Cod EAN 13'
                            onChange={(e) => dispatch(EditValueMasterProduct('cod_ean13', e.target.value, record.id))}
                        />
                        : record.cod_ean13? record.cod_ean13 : ''
                    }
                </div>
            },
            ellipsis : true,
            width : 100
        },
        {
            title: 'Cod EAN 14',
            dataIndex: 'cod_ean14',
            ...getColumnSearchProps('cod_ean14', 'cod ean 14'),
            sorter: (a, b) => a.cod_ean14 ? a.cod_ean14.localeCompare(b.cod_ean14) : false,
            showSorterTooltip: false,
            align: 'center',
            render:(_, record) => {
                const dataRow = rex_data_form_edit_master_product.find(dat => dat.id == record.id)
                return <div className='Table-Contain-Text-Global'>
                    {
                        dataRow
                        ? <Input
                            defaultValue={record.cod_ean14? record.cod_ean14 : ''}
                            value={dataRow.data.cod_ean14}
                            size="small"
                            placeholder='Cod EAN 14'
                            onChange={(e) => dispatch(EditValueMasterProduct('cod_ean14', e.target.value, record.id))}
                        />
                        : record.cod_ean14? record.cod_ean14 : ''
                    }
                </div>
            },
            ellipsis : true,
            width : 100
        },
        {
            title: 'Cod category',
            dataIndex: 'cod_category',
            ...getColumnSearchProps('cod_category', 'cod category'),
            sorter: (a, b) => a.cod_category ? a.cod_category.localeCompare(b.cod_category) : false,
            showSorterTooltip: false,
            align: 'center',
            render:(_, record) => {
                const dataRow = rex_data_form_edit_master_product.find(dat => dat.id == record.id)
                return <div className='Table-Contain-Text-Global'>
                    {
                        dataRow
                        ? <Input
                            defaultValue={record.cod_category? record.cod_category : ''}
                            value={dataRow.data.cod_category}
                            size="small"
                            placeholder='Cod category'
                            onChange={(e) => dispatch(EditValueMasterProduct('cod_category', e.target.value, record.id))}
                        />
                        : record.cod_category? record.cod_category : ''
                    }
                </div>
            },
            ellipsis : true,
            width : 120,
        },
        {
            title: 'Category',
            dataIndex: 'category',
            ...getColumnSearchProps('category', 'category'),
            sorter: (a, b) => a.category ? a.category.localeCompare(b.category) : false,
            showSorterTooltip: false,
            align: 'center',
            render:(_, record) => {
                const dataRow = rex_data_form_edit_master_product.find(dat => dat.id == record.id)
                return <div className='Table-Contain-Text-Global'>
                    {
                        dataRow
                        ? <Input
                            defaultValue={record.category? record.category : ''}
                            value={dataRow.data.category}
                            size="small"
                            placeholder='Category'
                            onChange={(e) => dispatch(EditValueMasterProduct('category', e.target.value, record.id))}
                        />
                        : record.category? record.category : ''
                    }
                </div>
            },
            ellipsis : true,
            width : 120
        },
        {
            title: 'Cod subcategory',
            dataIndex: 'cod_sub_category',
            ...getColumnSearchProps('cod_sub_category', 'cod subcategory'),
            sorter: (a, b) => a.cod_sub_category ? a.cod_sub_category.localeCompare(b.cod_sub_category) : false,
            showSorterTooltip: false,
            align: 'center',
            render:(_, record) => {
                const dataRow = rex_data_form_edit_master_product.find(dat => dat.id == record.id)
                return <div className='Table-Contain-Text-Global'>
                    {
                        dataRow
                        ? <Input
                            defaultValue={record.cod_sub_category? record.cod_sub_category : ''}
                            value={dataRow.data.cod_sub_category}
                            size="small"
                            placeholder='Cod subcategory'
                            onChange={(e) => dispatch(EditValueMasterProduct('cod_sub_category', e.target.value, record.id))}
                        />
                        : record.cod_sub_category? record.cod_sub_category : ''
                    }
                </div>
            },
            ellipsis : true,
            width : 120
        },
        {
            title: 'Subcategory',
            dataIndex: 'sub_category',
            ...getColumnSearchProps('sub_category', 'sub category'),
            sorter: (a, b) => a.sub_category ? a.sub_category.localeCompare(b.sub_category) : false,
            showSorterTooltip: false,
            align: 'center',
            render:(_, record) => {
                const dataRow = rex_data_form_edit_master_product.find(dat => dat.id == record.id)
                return <div className='Table-Contain-Text-Global'>
                    {
                        dataRow
                        ? <Input
                            defaultValue={record.sub_category? record.sub_category : ''}
                            value={dataRow.data.sub_category}
                            size="small"
                            placeholder='Subcategory'
                            onChange={(e) => dispatch(EditValueMasterProduct('sub_category', e.target.value, record.id))}
                        />
                        : record.sub_category? record.sub_category : ''
                    }
                </div>
            },
            ellipsis : true,
            width : 120
        },
        {
            title: 'Cod format',
            dataIndex: 'cod_format',
            ...getColumnSearchProps('cod_format', 'cod format'),
            sorter: (a, b) => a.cod_format ? a.cod_format.localeCompare(b.cod_format) : false,
            showSorterTooltip: false,
            align: 'center',
            render:(_, record) => {
                const dataRow = rex_data_form_edit_master_product.find(dat => dat.id == record.id)
                return <div className='Table-Contain-Text-Global'>
                    {
                        dataRow
                        ? <Input
                            defaultValue={record.cod_format? record.cod_format : ''}
                            value={dataRow.data.cod_format}
                            size="small"
                            placeholder='Cod format'
                            onChange={(e) => dispatch(EditValueMasterProduct('cod_format', e.target.value, record.id))}
                        />
                        : record.cod_format? record.cod_format : ''
                    }
                </div>
            },
            ellipsis: true,
            width : 130,
        },
        {
            title: 'Format',
            dataIndex: 'format',
            ...getColumnSearchProps('format', 'format'),
            sorter: (a, b) => a.format ? a.format.localeCompare(b.format) : false,
            showSorterTooltip: false,
            align: 'center',
            render:(_, record) => {
                const dataRow = rex_data_form_edit_master_product.find(dat => dat.id == record.id)
                return <div className='Table-Contain-Text-Global'>
                    {
                        dataRow
                        ? <Input
                            defaultValue={record.format? record.format : ''}
                            value={dataRow.data.format}
                            size="small"
                            placeholder='Format'
                            onChange={(e) => dispatch(EditValueMasterProduct('format', e.target.value, record.id))}
                        />
                        : record.format? record.format : ''
                    }
                </div>
            },
            ellipsis: true,
            width : 130,
        },
        {
            title: 'Cod family sku',
            dataIndex: 'cod_family_sku',
            ...getColumnSearchProps('cod_family_sku', 'cod family sku'),
            sorter: (a, b) => a.cod_family_sku ? a.cod_family_sku.localeCompare(b.cod_family_sku) : false,
            showSorterTooltip: false,
            align: 'center',
            render:(_, record) => {
                const dataRow = rex_data_form_edit_master_product.find(dat => dat.id == record.id)
                return <div className='Table-Contain-Text-Global'>
                    {
                        dataRow
                        ? <Input
                            defaultValue={record.cod_family_sku? record.cod_family_sku : ''}
                            value={dataRow.data.cod_family_sku}
                            size="small"
                            placeholder='Cod family sku'
                            onChange={(e) => dispatch(EditValueMasterProduct('cod_family_sku', e.target.value, record.id))}
                        />
                        : record.cod_family_sku? record.cod_family_sku : ''
                    }
                </div>
            },
            ellipsis: true,
            width : 130,
        },
        {
            title: 'Family sku',
            dataIndex: 'family_sku',
            ...getColumnSearchProps('family_sku', 'family sku'),
            sorter: (a, b) => a.family_sku ? a.family_sku.localeCompare(b.family_sku) : false,
            showSorterTooltip: false,
            align: 'center',
            render:(_, record) => {
                const dataRow = rex_data_form_edit_master_product.find(dat => dat.id == record.id)
                return <div className='Table-Contain-Text-Global'>
                    {
                        dataRow
                        ? <Input
                            defaultValue={record.family_sku? record.family_sku : ''}
                            value={dataRow.data.family_sku}
                            size="small"
                            placeholder='Family sku'
                            onChange={(e) => dispatch(EditValueMasterProduct('family_sku', e.target.value, record.id))}
                        />
                        : record.family_sku? record.family_sku : ''
                    }
                </div>
            },
            ellipsis: true,
            width : 130,
        },
        {
            title: 'Cod marca',
            dataIndex: 'cod_marca',
            ...getColumnSearchProps('cod_marca', 'cod marca'),
            sorter: (a, b) => a.cod_marca ? a.cod_marca.localeCompare(b.cod_marca) : false,
            showSorterTooltip: false,
            align: 'center',
            render:(_, record) => {
                const dataRow = rex_data_form_edit_master_product.find(dat => dat.id == record.id)
                return <div className='Table-Contain-Text-Global'>
                    {
                        dataRow
                        ? <Input
                            defaultValue={record.cod_marca? record.cod_marca : ''}
                            value={dataRow.data.cod_marca}
                            size="small"
                            placeholder='Cod marca'
                            onChange={(e) => dispatch(EditValueMasterProduct('cod_marca', e.target.value, record.id))}
                        />
                        : record.cod_marca? record.cod_marca : ''
                    }
                </div>
            },
            ellipsis: true,
            width : 130,
        },
        {
            title: 'Marca',
            dataIndex: 'marca',
            ...getColumnSearchProps('marca', 'marca'),
            sorter: (a, b) => a.marca ? a.marca.localeCompare(b.marca) : false,
            showSorterTooltip: false,
            align: 'center',
            render:(_, record) => {
                const dataRow = rex_data_form_edit_master_product.find(dat => dat.id == record.id)
                return <div className='Table-Contain-Text-Global'>
                    {
                        dataRow
                        ? <Input
                            defaultValue={record.marca? record.marca : ''}
                            value={dataRow.data.marca}
                            size="small"
                            placeholder='Marca'
                            onChange={(e) => dispatch(EditValueMasterProduct('marca', e.target.value, record.id))}
                        />
                        : record.marca? record.marca : ''
                    }
                </div>
            },
            ellipsis: true,
            width : 130,
        },
        {
            title: 'Class 1',
            dataIndex: 'class_1',
            ...getColumnSearchProps('class_1', 'class 1'),
            sorter: (a, b) => a.class_1 ? a.class_1.localeCompare(b.class_1) : false,
            showSorterTooltip: false,
            align: 'center',
            render:(_, record) => {
                const dataRow = rex_data_form_edit_master_product.find(dat => dat.id == record.id)
                return <div className='Table-Contain-Text-Global'>
                    {
                        dataRow
                        ? <Input
                            defaultValue={record.class_1? record.class_1 : ''}
                            value={dataRow.data.class_1}
                            size="small"
                            placeholder='Class 1'
                            onChange={(e) => dispatch(EditValueMasterProduct('class_1', e.target.value, record.id))}
                        />
                        : record.class_1? record.class_1 : ''
                    }
                </div>
            },
            width : 100,
            ellipsis: true,
        },
        {
            title: 'Class 2',
            dataIndex: 'class_2',
            ...getColumnSearchProps('class_2', 'class 2'),
            sorter: (a, b) => a.class_2 ? a.class_2.localeCompare(b.class_2) : false,
            showSorterTooltip: false,
            align: 'center',
            render:(_, record) => {
                const dataRow = rex_data_form_edit_master_product.find(dat => dat.id == record.id)
                return <div className='Table-Contain-Text-Global'>
                    {
                        dataRow
                        ? <Input
                            defaultValue={record.class_2? record.class_2 : ''}
                            value={dataRow.data.class_2}
                            size="small"
                            placeholder='Class 2'
                            onChange={(e) => dispatch(EditValueMasterProduct('class_2', e.target.value, record.id))}
                        />
                        : record.class_2? record.class_2 : ''
                    }
                </div>
            },
            width : 100,
            ellipsis: true,
        },
        {
            title: 'Class 3',
            dataIndex: 'class_3',
            ...getColumnSearchProps('class_3', 'class 3'),
            sorter: (a, b) => a.class_3 ? a.class_3.localeCompare(b.class_3) : false,
            showSorterTooltip: false,
            align: 'center',
            render:(_, record) => {
                const dataRow = rex_data_form_edit_master_product.find(dat => dat.id == record.id)
                return <div className='Table-Contain-Text-Global'>
                    {
                        dataRow
                        ? <Input
                            defaultValue={record.class_3? record.class_3 : ''}
                            value={dataRow.data.class_3}
                            size="small"
                            placeholder='Class 3'
                            onChange={(e) => dispatch(EditValueMasterProduct('class_3', e.target.value, record.id))}
                        />
                        : record.class_3? record.class_3 : ''
                    }
                </div>
            },
            width : 100,
            ellipsis: true,
        },
        {
            title: 'Class 4',
            dataIndex: 'class_4',
            ...getColumnSearchProps('class_4', 'class 4'),
            sorter: (a, b) => a.class_4 ? a.class_4.localeCompare(b.class_4) : false,
            showSorterTooltip: false,
            align: 'center',
            render:(_, record) => {
                const dataRow = rex_data_form_edit_master_product.find(dat => dat.id == record.id)
                return <div className='Table-Contain-Text-Global'>
                    {
                        dataRow
                        ? <Input
                            defaultValue={record.class_4? record.class_4 : ''}
                            value={dataRow.data.class_4}
                            size="small"
                            placeholder='Class 4'
                            onChange={(e) => dispatch(EditValueMasterProduct('class_4', e.target.value, record.id))}
                        />
                        : record.class_4? record.class_4 : ''
                    }
                </div>
            },
            width : 100,
            ellipsis: true,
        },
        {
            title: 'Class 5',
            dataIndex: 'class_5',
            ...getColumnSearchProps('class_5', 'class 5'),
            sorter: (a, b) => a.class_5 ? a.class_5.localeCompare(b.class_5) : false,
            showSorterTooltip: false,
            align: 'center',
            render:(_, record) => {
                const dataRow = rex_data_form_edit_master_product.find(dat => dat.id == record.id)
                return <div className='Table-Contain-Text-Global'>
                    {
                        dataRow
                        ? <Input
                            defaultValue={record.class_5? record.class_5 : ''}
                            value={dataRow.data.class_5}
                            size="small"
                            placeholder='Class 5'
                            onChange={(e) => dispatch(EditValueMasterProduct('class_5', e.target.value, record.id))}
                        />
                        : record.class_5? record.class_5 : ''
                    }
                </div>
            },
            width : 100,
            ellipsis: true,
        },
        {
            title: 'Class 6',
            dataIndex: 'class_6',
            ...getColumnSearchProps('class_6', 'class 6'),
            sorter: (a, b) => a.class_6 ? a.class_6.localeCompare(b.class_6) : false,
            showSorterTooltip: false,
            align: 'center',
            render:(_, record) => {
                const dataRow = rex_data_form_edit_master_product.find(dat => dat.id == record.id)
                return <div className='Table-Contain-Text-Global'>
                    {
                        dataRow
                        ? <Input
                            defaultValue={record.class_6? record.class_6 : ''}
                            value={dataRow.data.class_6}
                            size="small"
                            placeholder='Class 6'
                            onChange={(e) => dispatch(EditValueMasterProduct('class_6', e.target.value, record.id))}
                        />
                        : record.class_6? record.class_6 : ''
                    }
                </div>
            },
            width : 100,
            ellipsis: true,
        },
        {
            title: 'Cod count',
            dataIndex: 'cod_count',
            ...getColumnSearchProps('cod_count', 'cod count'),
            sorter: (a, b) => a.cod_count ? a.cod_count.localeCompare(b.cod_count) : false,
            showSorterTooltip: false,
            align: 'center',
            render:(_, record) => {
                const dataRow = rex_data_form_edit_master_product.find(dat => dat.id == record.id)
                return <div className='Table-Contain-Text-Global'>
                    {
                        dataRow
                        ? <Input
                            defaultValue={record.cod_count? record.cod_count : ''}
                            value={dataRow.data.cod_count}
                            size="small"
                            placeholder='Cod count'
                            onChange={(e) => dispatch(EditValueMasterProduct('cod_count', e.target.value, record.id))}
                        />
                        : record.cod_count? record.cod_count : ''
                    }
                </div>
            },
            width : 100,
            ellipsis: true,
        },
        {
            title: 'Count',
            dataIndex: 'count',
            ...getColumnSearchProps('count', 'count'),
            sorter: (a, b) => a.count ? a.count.localeCompare(b.count) : false,
            showSorterTooltip: false,
            align: 'center',
            render:(_, record) => {
                const dataRow = rex_data_form_edit_master_product.find(dat => dat.id == record.id)
                return <div className='Table-Contain-Text-Global'>
                    {
                        dataRow
                        ? <Input
                            defaultValue={record.count? record.count : ''}
                            value={dataRow.data.count}
                            size="small"
                            placeholder='Count'
                            onChange={(e) => dispatch(EditValueMasterProduct('count', e.target.value, record.id))}
                        />
                        : record.count? record.count : ''
                    }
                </div>
            },
            width : 180,
            ellipsis: true,
        },
        {
            title: 'Cod size',
            dataIndex: 'cod_size',
            ...getColumnSearchProps('cod_size', 'cod size'),
            sorter: (a, b) => a.cod_size ? a.cod_size.localeCompare(b.cod_size) : false,
            showSorterTooltip: false,
            align: 'center',
            render:(_, record) => {
                const dataRow = rex_data_form_edit_master_product.find(dat => dat.id == record.id)
                return <div className='Table-Contain-Text-Global'>
                    {
                        dataRow
                        ? <Input
                            defaultValue={record.cod_size? record.cod_size : ''}
                            value={dataRow.data.cod_size}
                            size="small"
                            placeholder='Cod size'
                            onChange={(e) => dispatch(EditValueMasterProduct('cod_size', e.target.value, record.id))}
                        />
                        : record.cod_size? record.cod_size : ''
                    }
                </div>
            },
            width : 100,
            ellipsis: true,
        },
        {
            title: 'Size',
            dataIndex: 'size',
            ...getColumnSearchProps('size', 'size'),
            sorter: (a, b) => a.size ? a.size.localeCompare(b.size) : false,
            showSorterTooltip: false,
            align: 'center',
            render:(_, record) => {
                const dataRow = rex_data_form_edit_master_product.find(dat => dat.id == record.id)
                return <div className='Table-Contain-Text-Global'>
                    {
                        dataRow
                        ? <Input
                            defaultValue={record.size? record.size : ''}
                            value={dataRow.data.size}
                            size="small"
                            placeholder='Size'
                            onChange={(e) => dispatch(EditValueMasterProduct('size', e.target.value, record.id))}
                        />
                        : record.size? record.size : ''
                    }
                </div>
            },
            width : 100,
            ellipsis: true,
        },
        {
            title: 'Cod presentation',
            dataIndex: 'cod_presentation',
            ...getColumnSearchProps('cod_presentation', 'cod presentation'),
            sorter: (a, b) => a.cod_presentation ? a.cod_presentation.localeCompare(b.cod_presentation) : false,
            showSorterTooltip: false,
            align: 'center',
            render:(_, record) => {
                const dataRow = rex_data_form_edit_master_product.find(dat => dat.id == record.id)
                return <div className='Table-Contain-Text-Global'>
                    {
                        dataRow
                        ? <Input
                            defaultValue={record.cod_presentation? record.cod_presentation : ''}
                            value={dataRow.data.cod_presentation}
                            size="small"
                            placeholder='Cod presentation'
                            onChange={(e) => dispatch(EditValueMasterProduct('cod_presentation', e.target.value, record.id))}
                        />
                        : record.cod_presentation? record.cod_presentation : ''
                    }
                </div>
            },
            width : 150,
            ellipsis: true,
        },
        {
            title: 'Presentation',
            dataIndex: 'presentation',
            ...getColumnSearchProps('presentation', 'presentation'),
            sorter: (a, b) => a.presentation ? a.presentation.localeCompare(b.presentation) : false,
            showSorterTooltip: false,
            align: 'center',
            render:(_, record) => {
                const dataRow = rex_data_form_edit_master_product.find(dat => dat.id == record.id)
                return <div className='Table-Contain-Text-Global'>
                    {
                        dataRow
                        ? <Input
                            defaultValue={record.presentation? record.presentation : ''}
                            value={dataRow.data.presentation}
                            size="small"
                            placeholder='Presentation'
                            onChange={(e) => dispatch(EditValueMasterProduct('presentation', e.target.value, record.id))}
                        />
                        : record.presentation? record.presentation : ''
                    }
                </div>
            },
            width : 150,
            ellipsis: true,
        },
        {
            title: 'Cod peso',
            dataIndex: 'cod_peso',
            ...getColumnSearchProps('cod_peso', 'cod peso'),
            sorter: (a, b) => a.cod_peso ? a.cod_peso.localeCompare(b.cod_peso) : false,
            showSorterTooltip: false,
            align: 'center',
            render:(_, record) => {
                const dataRow = rex_data_form_edit_master_product.find(dat => dat.id == record.id)
                return <div className='Table-Contain-Text-Global'>
                    {
                        dataRow
                        ? <Input
                            defaultValue={record.cod_peso? record.cod_peso : ''}
                            value={dataRow.data.cod_peso}
                            size="small"
                            placeholder='Cod peso'
                            onChange={(e) => dispatch(EditValueMasterProduct('cod_peso', e.target.value, record.id))}
                        />
                        : record.cod_peso? record.cod_peso : ''
                    }
                </div>
            },
            width : 150,
            ellipsis: true,
        },
        {
            title: 'Peso',
            dataIndex: 'peso',
            ...getColumnSearchProps('peso', 'peso'),
            sorter: (a, b) => a.peso ? a.peso - b.peso : false,
            showSorterTooltip: false,
            align: 'center',
            render:(_, record) => {
                const dataRow = rex_data_form_edit_master_product.find(dat => dat.id == record.id)
                return <div className='Table-Contain-Text-Global'>
                    {
                        dataRow
                        ? <InputNumber
                            defaultValue={record.peso? record.peso : ''}
                            value={dataRow.data.peso}
                            min="0"
                            max="9999999999"
                            step="0.01"
                            size="small"
                            placeholder='Peso'
                            stringMode
                            onChange={(value) => dispatch(EditValueMasterProduct('peso', value, record.id))}
                        />
                        : isNaN(record.peso) || !record.peso ? '-' : parseNumber(record.peso)
                    }
                </div>
            },
            width : 100,
            ellipsis: true,
        },
        {
            title: 'Cod fc',
            dataIndex: 'cod_fc',
            ...getColumnSearchProps('cod_fc', 'cod fc'),
            sorter: (a, b) => a.cod_fc ? a.cod_fc.localeCompare(b.cod_fc) : false,
            showSorterTooltip: false,
            align: 'center',
            render:(_, record) => {
                const dataRow = rex_data_form_edit_master_product.find(dat => dat.id == record.id)
                return <div className='Table-Contain-Text-Global'>
                    {
                        dataRow
                        ? <Input
                            defaultValue={record.cod_fc? record.cod_fc : ''}
                            value={dataRow.data.cod_fc}
                            size="small"
                            placeholder='Cod fc'
                            onChange={(e) => dispatch(EditValueMasterProduct('cod_fc', e.target.value, record.id))}
                        />
                        : record.cod_fc? record.cod_fc : ''
                    }
                </div>
            },
            width : 100,
            ellipsis: true,
        },
        {
            title: 'FC',
            dataIndex: 'fc',
            ...getColumnSearchProps('fc', 'fc'),
            sorter: (a, b) => a.fc ? a.fc.localeCompare(b.fc) : false,
            showSorterTooltip: false,
            align: 'center',
            render:(_, record) => {
                const dataRow = rex_data_form_edit_master_product.find(dat => dat.id == record.id)
                return <div className='Table-Contain-Text-Global'>
                    {
                        dataRow
                        ? <Input
                            defaultValue={record.fc? record.fc : ''}
                            value={dataRow.data.fc}
                            size="small"
                            placeholder='FC'
                            onChange={(e) => dispatch(EditValueMasterProduct('fc', e.target.value, record.id))}
                        />
                        : isNaN(record.fc) || !record.fc ? '-' : parseNumber(record.fc)
                    }
                </div>
            },
            width : 100,
            ellipsis: true,
        },
        {
            title: 'Class 7',
            dataIndex: 'class_7',
            ...getColumnSearchProps('class_7', 'class 7'),
            sorter: (a, b) => a.class_7 ? a.class_7.localeCompare(b.class_7) : false,
            showSorterTooltip: false,
            align: 'center',
            render:(_, record) => {
                const dataRow = rex_data_form_edit_master_product.find(dat => dat.id == record.id)
                return <div className='Table-Contain-Text-Global'>
                    {
                        dataRow
                        ? <Input
                            defaultValue={record.class_7? record.class_7 : ''}
                            value={dataRow.data.class_7}
                            size="small"
                            placeholder='Class 7'
                            onChange={(e) => dispatch(EditValueMasterProduct('class_7', e.target.value, record.id))}
                        />
                        : record.class_7? record.class_7 : ''
                    }
                </div>
            },
            width : 100,
            ellipsis: true,
        },
        {
            title: 'Class 8',
            dataIndex: 'class_8',
            ...getColumnSearchProps('class_8', 'class 8'),
            sorter: (a, b) => a.class_8 ? a.class_8.localeCompare(b.class_8) : false,
            showSorterTooltip: false,
            align: 'center',
            render:(_, record) => {
                const dataRow = rex_data_form_edit_master_product.find(dat => dat.id == record.id)
                return <div className='Table-Contain-Text-Global'>
                    {
                        dataRow
                        ? <Input
                            defaultValue={record.class_8? record.class_8 : ''}
                            value={dataRow.data.class_8}
                            size="small"
                            placeholder='Class 8'
                            onChange={(e) => dispatch(EditValueMasterProduct('class_8', e.target.value, record.id))}
                        />
                        : record.class_8? record.class_8 : ''
                    }
                </div>
            },
            width : 100,
            ellipsis: true,
        },
        {
            title: 'Class 9',
            dataIndex: 'class_8',
            ...getColumnSearchProps('class_9', 'class 9'),
            sorter: (a, b) => a.class_9 ? a.class_9.localeCompare(b.class_9) : false,
            showSorterTooltip: false,
            align: 'center',
            render:(_, record) => {
                const dataRow = rex_data_form_edit_master_product.find(dat => dat.id == record.id)
                return <div className='Table-Contain-Text-Global'>
                    {
                        dataRow
                        ? <Input
                            defaultValue={record.class_9? record.class_9 : ''}
                            value={dataRow.data.class_9}
                            size="small"
                            placeholder='Class 9'
                            onChange={(e) => dispatch(EditValueMasterProduct('class_9', e.target.value, record.id))}
                        />
                        : record.class_9? record.class_9 : ''
                    }
                </div>
            },
            width : 100,
            ellipsis: true,
        },
        {
            title: 'Class 10',
            dataIndex: 'class_10',
            ...getColumnSearchProps('class_10', 'class 10'),
            sorter: (a, b) => a.class_10 ? a.class_10.localeCompare(b.class_10) : false,
            showSorterTooltip: false,
            align: 'center',
            render:(_, record) => {
                const dataRow = rex_data_form_edit_master_product.find(dat => dat.id == record.id)
                return <div className='Table-Contain-Text-Global'>
                    {
                        dataRow
                        ? <Input
                            defaultValue={record.class_10? record.class_10 : ''}
                            value={dataRow.data.class_10}
                            size="small"
                            placeholder='Class 10'
                            onChange={(e) => dispatch(EditValueMasterProduct('class_10', e.target.value, record.id))}
                        />
                        : record.class_10? record.class_10 : ''
                    }
                </div>
            },
            width : 100,
            ellipsis: true,
        },
        {
            title: 'Cod cj',
            dataIndex: 'cod_cj',
            ...getColumnSearchProps('cod_cj', 'cod cj'),
            sorter: (a, b) => a.cod_cj ? a.cod_cj.localeCompare(b.cod_cj) : false,
            showSorterTooltip: false,
            align: 'center',
            render:(_, record) => {
                const dataRow = rex_data_form_edit_master_product.find(dat => dat.id == record.id)
                return <div className='Table-Contain-Text-Global'>
                    {
                        dataRow
                        ? <Input
                            defaultValue={record.cod_cj? record.cod_cj : ''}
                            value={dataRow.data.cod_cj}
                            size="small"
                            placeholder='Cod cj'
                            onChange={(e) => dispatch(EditValueMasterProduct('cod_cj', e.target.value, record.id))}
                        />
                        : record.cod_cj? record.cod_cj : ''
                    }
                </div>
            },
            width : 120,
            ellipsis: true,
        },
        {
            title: 'Cj',
            dataIndex: 'cj',
            ...getColumnSearchProps('cj', 'cj'),
            sorter: (a, b) => a.cj ? a.cj.localeCompare(b.cj) : false,
            showSorterTooltip: false,
            align: 'center',
            render:(_, record) => {
                const dataRow = rex_data_form_edit_master_product.find(dat => dat.id == record.id)
                return <div className='Table-Contain-Text-Global'>
                    {
                        dataRow
                        ? <Input
                            defaultValue={record.cj? record.cj : ''}
                            value={dataRow.data.cj}
                            size="small"
                            placeholder='Cj'
                            onChange={(e) => dispatch(EditValueMasterProduct('cj', e.target.value, record.id))}
                        />
                        : isNaN(record.cj) || !record.cj ? '-' : parseNumber(record.cj)
                    }
                </div>
            },
            width : 120,
            ellipsis: true,
        },
        {
            title: 'Cod tn',
            dataIndex: 'cod_tn',
            ...getColumnSearchProps('cod_tn', 'cod tn'),
            sorter: (a, b) => a.cod_tn ? a.cod_tn.localeCompare(b.cod_tn) : false,
            showSorterTooltip: false,
            align: 'center',
            render:(_, record) => {
                const dataRow = rex_data_form_edit_master_product.find(dat => dat.id == record.id)
                return <div className='Table-Contain-Text-Global'>
                    {
                        dataRow
                        ? <Input
                            defaultValue={record.cod_tn? record.cod_tn : ''}
                            value={dataRow.data.cod_tn}
                            size="small"
                            placeholder='Cod tn'
                            onChange={(e) => dispatch(EditValueMasterProduct('cod_tn', e.target.value, record.id))}
                        />
                        : record.cod_tn? record.cod_tn : ''
                    }
                </div>
            },
            width : 120,
            ellipsis: true,
        },
        {
            title: 'TN',
            dataIndex: 'tn',
            ...getColumnSearchProps('tn', 'tn'),
            sorter: (a, b) => a.tn ? a.tn.localeCompare(b.tn) : false,
            showSorterTooltip: false,
            align: 'center',
            render:(_, record) => {
                const dataRow = rex_data_form_edit_master_product.find(dat => dat.id == record.id)
                return <div className='Table-Contain-Text-Global'>
                    {
                        dataRow
                        ? <Input
                            defaultValue={record.tn? record.tn : ''}
                            value={dataRow.data.tn}
                            size="small"
                            placeholder='TN'
                            onChange={(e) => dispatch(EditValueMasterProduct('tn', e.target.value, record.id))}
                        />
                        : isNaN(record.tn) || !record.tn ? '-' : parseNumber(record.tn)
                    }
                </div>
            },
            width : 120,
            ellipsis: true,
        },
        {
            title: 'Cod gsu',
            dataIndex: 'cod_gsu',
            ...getColumnSearchProps('cod_gsu', 'cod gsu'),
            sorter: (a, b) => a.cod_gsu ? a.cod_gsu.localeCompare(b.cod_gsu) : false,
            showSorterTooltip: false,
            align: 'center',
            render:(_, record) => {
                const dataRow = rex_data_form_edit_master_product.find(dat => dat.id == record.id)
                return <div className='Table-Contain-Text-Global'>
                    {
                        dataRow
                        ? <Input
                            defaultValue={record.cod_gsu? record.cod_gsu : ''}
                            value={dataRow.data.cod_gsu}
                            size="small"
                            placeholder='Cod gsu'
                            onChange={(e) => dispatch(EditValueMasterProduct('cod_gsu', e.target.value, record.id))}
                        />
                        : record.cod_gsu? record.cod_gsu : ''
                    }
                </div>
            },
            width : 140,
            ellipsis: true,
        },
        {
            title: 'GSU',
            dataIndex: 'gsu',
            ...getColumnSearchProps('gsu', 'gsu'),
            sorter: (a, b) => a.gsu ? a.tn.localeCompare(b.gsu) : false,
            showSorterTooltip: false,
            align: 'center',
            render:(_, record) => {
                const dataRow = rex_data_form_edit_master_product.find(dat => dat.id == record.id)
                return <div className='Table-Contain-Text-Global'>
                    {
                        dataRow
                        ? <Input
                            defaultValue={record.gsu? record.gsu : ''}
                            value={dataRow.data.gsu}
                            size="small"
                            placeholder='GSU'
                            onChange={(e) => dispatch(EditValueMasterProduct('gsu', e.target.value, record.id))}
                        />
                        : isNaN(record.gsu) || !record.gsu ? '-' : parseNumber(record.gsu)
                    }
                </div>
            },
            width : 140,
            ellipsis: true,
        },
        {
            title: 'Class 11',
            dataIndex: 'class_11',
            ...getColumnSearchProps('class_11', 'class 11'),
            sorter: (a, b) => a.class_11 ? a.class_11.localeCompare(b.class_11) : false,
            showSorterTooltip: false,
            align: 'center',
            render:(_, record) => {
                const dataRow = rex_data_form_edit_master_product.find(dat => dat.id == record.id)
                return <div className='Table-Contain-Text-Global'>
                    {
                        dataRow
                        ? <Input
                            defaultValue={record.class_11? record.class_11 : ''}
                            value={dataRow.data.class_11}
                            size="small"
                            placeholder='Class 11'
                            onChange={(e) => dispatch(EditValueMasterProduct('class_11', e.target.value, record.id))}
                        />
                        : record.class_11? record.class_11 : ''
                    }
                </div>
            },
            width : 100,
            ellipsis: true,
        },
        {
            title: 'Class 12',
            dataIndex: 'class_12',
            ...getColumnSearchProps('class_12', 'class 12'),
            sorter: (a, b) => a.class_12 ? a.class_12.localeCompare(b.class_12) : false,
            showSorterTooltip: false,
            align: 'center',
            render:(_, record) => {
                const dataRow = rex_data_form_edit_master_product.find(dat => dat.id == record.id)
                return <div className='Table-Contain-Text-Global'>
                    {
                        dataRow
                        ? <Input
                            defaultValue={record.class_12? record.class_12 : ''}
                            value={dataRow.data.class_12}
                            size="small"
                            placeholder='Class 12'
                            onChange={(e) => dispatch(EditValueMasterProduct('class_12', e.target.value, record.id))}
                        />
                        : record.class_12? record.class_12 : ''
                    }
                </div>
            },
            width : 100,
            ellipsis: true,
        },
        {
            title: 'Class 13',
            dataIndex: 'class_13',
            ...getColumnSearchProps('class_13', 'class 13'),
            sorter: (a, b) => a.class_13 ? a.class_13.localeCompare(b.class_13) : false,
            showSorterTooltip: false,
            align: 'center',
            render:(_, record) => {
                const dataRow = rex_data_form_edit_master_product.find(dat => dat.id == record.id)
                return <div className='Table-Contain-Text-Global'>
                    {
                        dataRow
                        ? <Input
                            defaultValue={record.class_13? record.class_13 : ''}
                            value={dataRow.data.class_13}
                            size="small"
                            placeholder='Class 13'
                            onChange={(e) => dispatch(EditValueMasterProduct('class_13', e.target.value, record.id))}
                        />
                        : record.class_13? record.class_13 : ''
                    }
                </div>
            },
            width : 100,
            ellipsis: true,
        },
        {
            title: 'Status',
            dataIndex: 'status',
            ...getColumnSearchProps('status', 'status'),
            sorter: (a, b) => a.status ? a.status.localeCompare(b.status) : false,
            showSorterTooltip: false,
            align: 'center',
            render:(_, record) => {
                const dataRow = rex_data_form_edit_master_product.find(dat => dat.id == record.id)
                return <div className='Table-Contain-Text-Global'>
                    {
                        dataRow
                        ? <Input
                            defaultValue={record.status? record.status : ''}
                            value={dataRow.data.status}
                            size="small"
                            placeholder='Status'
                            onChange={(e) => dispatch(EditValueMasterProduct('status', e.target.value, record.id))}
                        />
                        : record.status? record.status : ''
                    }
                </div>
            },
            width : 140,
            ellipsis: true,
        },
        {
            title: 'Robot',
            dataIndex: 'robot',
            ...getColumnSearchProps('robot', 'robot'),
            sorter: (a, b) => a.robot ? a.robot.localeCompare(b.robot) : false,
            showSorterTooltip: false,
            align: 'center',
            render:(_, record) => {
                const dataRow = rex_data_form_edit_master_product.find(dat => dat.id == record.id)
                return <div className='Table-Contain-Text-Global'>
                    {
                        dataRow
                        ? <Input
                            defaultValue={record.robot? record.robot : ''}
                            value={dataRow.data.robot}
                            size="small"
                            placeholder='Robot'
                            onChange={(e) => dispatch(EditValueMasterProduct('robot', e.target.value, record.id))}
                        />
                        : record.robot? record.robot : ''
                    }
                </div>
            },
            width : 120,
            ellipsis: true,
        },
        {
            title: 'Usuario',
            dataIndex: 'usuusuario',
            ...getColumnSearchProps('usucorreo', 'usuario'),
            sorter: (a, b) => a.usucorreo ? a.usucorreo.localeCompare(b.usucorreo) : false,
            showSorterTooltip: false,
            align: 'center',
            render:(_, record) => (
                <div className='Table-Contain-Text-Global'>
                    {record.usucorreo}
                </div>
            ),
            width : 120,
            ellipsis: true,
        },
        {
            title: 'Fecha de actualización',
            dataIndex: 'updated_at',
            ...getColumnSearchProps('updated_at', 'fecha de actualización'),
            sorter: (a, b) => a.updated_at ? a.updated_at.localeCompare(b.updated_at) : false,
            showSorterTooltip: false,
            align: 'center',
            render:(_, record) => (
                <div className='Table-Contain-Text-Global'>
                    {record.updated_at ? moment(record.updated_at).format('DD/MM/YYYY h:mm a') : ''}
                </div>
            ),
            width : 180,
            ellipsis: true,
        },
        {
            title: 'Acciones',
            dataIndex: 'acciones',
            align: 'center',
            render:(_, record) => {

                const EditingRow = rex_data_form_edit_master_product.find(dat => dat.id == record.id)

                return <div className="Container-Actions-Table-Products">
                    {
                        EditingRow
                        ? loadingEdit.status
                            ?   loadingEdit.id == record.id
                                ? <LoadingOutlined style={{color: "#2AD295"}}/>
                                : <div className="Actions-Table-Products-Editing">
                                    <Skeleton.Avatar active={true} size="small"/><Skeleton.Avatar active={true} size="small" />
                                </div>
                            :   <div className="Actions-Table-Products-Editing">
                                    <Tooltip
                                        placement='left'
                                        title="Confirmar"
                                        color='#3B72FF'
                                    >
                                        <div onClick={() => editRow(record.id)}>
                                            <CheckCircleOutlined style={{ color:'#008000', fontSize:"18px"}}/>
                                        </div>
                                    </Tooltip>
                                    <Tooltip
                                        placement='right'
                                        title="Cancelar"
                                        color='#3B72FF'
                                    >
                                        <div onClick={() => dispatch(EditRowMasterProduct(record.id, false))}>
                                            <CloseCircleOutlined style={{ color:'red', fontSize:"18px"}}/>
                                        </div>
                                    </Tooltip>
                                </div>
                        : <>
                            <Tooltip
                                placement='right'
                                title="Editar"
                                color='#3B72FF'
                            >
                                <div
                                    style={{display:"flex",alignItems:"center"}} 
                                    onClick={() => dispatch(EditRowMasterProduct(record.id, true))}
                                >
                                    <EditOptionButtonGlobal/>
                                </div>
                            </Tooltip>
                            <Tooltip
                                placement='right'
                                title="Eliminar"
                                color='#3B72FF'
                            >
                                <div onClick={() => deleteMasterProduct([record.id])} style={{display:"flex",alignItems:"center"}}>
                                    <TrashButtonGlobalSimpleColor/>
                                </div>
                            </Tooltip>
                        </>

                    }
                    
                </div>
            },
            width: 100,
            fixed: 'right'
        },
    ]
    
    const validateDateDisable = (date) => {

        if(date == "" || typeof(date) == "object"){
            return false
        }

        const currentDate = dayjs().format("YYYY-MM")
        return currentDate == date ? false : true            
    }

    const saveMasterProducts = async () => {
        const response = await dispatch(SaveMasterProductsReducer(dateProducts, "save-master-products"))
        if(!response){
            confirm({
                title   : "Guardar Maestra de Productos",
                icon    : <ExclamationCircleFilled/>,
                content : "Existen productos para el mes siguiente, ¿Desea reemplazar la información?",
                zIndex  : 100000000,
                async onOk(){
                    const response = await dispatch(SaveMasterProductsReducer(dateProducts, "confirm-save-master-products"))
                },
                okCancel(){}
            })   
        }
    }

    return (
        <div className="Container-View-Products">
            <TableGlobal
                data={data}
                date={dateProducts}
                setDate={setDateMSL}
                buttonSaveProductsDisable={validateDateDisable(dateProducts)}
                componentExtraLeftPagination={componentExtraLeftPagination}
                actionButtonSave={() => saveMasterProducts()}
                columns={columnsProducts}
                selectRows={ true }
                dataDownload={{route : "pe/msl-products", nameFile : "Maestra de Productos"}}
                selectRowsTable={true}
                selectedRowKeys={selectedRowKeys}
                setSelectedRowKeys={setSelectedRowKeys}
                showFilterDate={true}
                loading_data={rex_loading_data_master_products_manage}
                valueCheckbox={valueCheckbox}
                showSaveButton={true}
                showDownloadProducts={true}
                showNewButton={true}
                pathNewButton="/create/master-product"
                setValueCheckbox={setValueCheckbox}
                pageTableGlobal={pageTableGlobal}
                pageSizeTableGlobal={pageSizeTableGlobal}
                setPageTableGlobal={setPageTableGlobal}
                setPageSizeTableGlobal={setPageSizeTableGlobal}
                showTrash={true}
                actInacChechBox={false}
                showEdit = {false}
                showDownload={false}
                showTrashRight={false}
                showEditRight={false}
                actionOnRow={() => {}}
                functionDelete={(values, resetValues) => deleteMasterProducts(values, resetValues)}
                functionEditSelectedRows={(values, resetValues) => {
                    if(values.length > 0){
                        setOpenModal(!openModal)
                        setIdsSelectedMsl(values)
                        setTypeActionMsl('edit')
                    }
                }}
                functionFilterDate = {(date, dateString) => {
                    setDateFilter(dateString)
                    dispatch(GetMasterProductsManageReducer(dateString))
                }}
                
                componentFilterExtra={filterChannelComponent()}
                showSaveProducts={true}
            />
            <FormCreateMSL
                setOpenModal={setOpenModal}
                openModal={openModal}
                idsSelectedMsl={idsSelectedMsl}
                typeActionMsl={typeActionMsl}
                dataMsl={dataMsl}
                dateFilter={dateFilter}
                setDataMsl={setDataMsl}
                setSelectedRowKeys={setSelectedRowKeys}
                setValueCheckbox={setValueCheckbox}
            />
            <ModalMessageAlert/>
            <ModalConfirmDeleteProducts
                dateMsl={dateMsl}
                dateNewProducts={dateNewProducts}
                dateProducts={dateProducts}
            />
        </div>
    )
}

export default ViewProducts