import React, { useEffect, useState } from 'react'
import { Typography } from 'antd'

import { Swiper, SwiperSlide } from "swiper/react"
import "swiper/swiper-bundle.min.css"
import 'swiper/swiper.min.css'
import '../../Styles/Components/Analytics/SliderAnalytics.css'

import { useDispatch } from 'react-redux'
import { ObtenerGraficaSeleccionadaReducer } from '../../Redux/Actions/Analytics/Graficos/GraficosAnalytics'

const SliderAnalytics = (props) => {

    const dispatch = useDispatch()
    const items = props.itemSlider
    const setTextTooltip = props.setTextTooltip
    const [activeSlider, setActiveSlider] = useState(1)
    const { Title } = Typography


    // Alicorp
    useEffect(() => {
        dispatch(ObtenerGraficaSeleccionadaReducer(1, true))
    }, [])

    // useEffect(() => {
    //     dispatch(ObtenerGraficaSeleccionadaReducer(1, true))
    // }, [items])

    return(
        <Swiper
            slidesPerView={1}
            spaceBetween={0}
            breakpoints={{
                992: {
                    slidesPerView: 4,
                },
            }}
            className="Container-Slider"
        >
            {
                items.map(item =>
                    <SwiperSlide 
                        className="Container-Section-Slider" 
                        key={item.key}
                        onClick={() => {
                            console.log(item.key)
                            dispatch(ObtenerGraficaSeleccionadaReducer(item.key))
                            setActiveSlider(item.key)
                            const arrayTextTooltip = ["Vistas", "Minutos", "Usuario", "Usuario"]
                            setTextTooltip(arrayTextTooltip[item.key - 1])
                        }}
                    >
                        <div className={`Container-Item-Slider ${activeSlider == item.key && "Active-Shadow"}`}>
                            <p className="Title-Slider">{item.titleSlider}</p>
                            <Title
                                level={2}
                                className="Numeric-Slider"
                            >
                                {item.numericSlider}
                            </Title>
                            <p className="Text-Slider">{item.textSlider}</p>
                        </div>
                        <div 
                            className={`Bar-Bottom ${activeSlider == item.key && "Active-Bar"}`} 
                        >
                            {
                                items.map(i => 
                                    <div className={`Bar-Item-Bottom ${i.key == item.key && "Active-Item-Bar"}`}></div>
                                )
                            }
                        </div>
                    </SwiperSlide>
                )
            }
        </Swiper>
    )
}

export default SliderAnalytics