import React, { useState } from 'react'
import { Row, Col, Card, Progress, DatePicker, Affix, Skeleton } from 'antd'
import CalendarStatus from '../../Assets/Images/Status/calendar-status.png'
import dayjs from 'dayjs'
import { Link } from 'react-scroll'
import { useDispatch, useSelector } from 'react-redux'
import { SetDateFilterStatusReducer } from '../../Redux/Actions/Status/Status'
const loadImage = require.context("../../Assets/Images/Status", true)

const CardStatus = (props) => {

    const [ indexItemSelected, setIndexItemSelected ] = useState(null)
    const [dateYearMonth, setDateYearMonth] = useState(dayjs())
    const data_status = props.status
    const dispatch = useDispatch()

    const {
        rex_loading_data_status
    } = useSelector(({status}) => status)

    return(
        <Affix offsetTop={78}>
            <Card className="Card-Circle-Status">
                <Row gutter={20}>
                    <Col lg={0} xs={24}>
                        <div className="Container-Title-Circle-Status">
                            <p>Área</p>
                            <p>% Avance</p>
                        </div>
                    </Col>
                    <Col lg={2} xs={0}>
                        <div className='Container-Item-Header-Status-Date'>
                            <img className='Image-Indicator-Status-Top' src={CalendarStatus}></img>
                            <div className='Container-Date-Filter-Status'>
                                <span>Fecha</span>
                                <DatePicker
                                    size='small'
                                    className='DatePicker-Filter-Status'
                                    allowClear={false}
                                    value={dateYearMonth}
                                    picker="month"
                                    popupClassName='DatePicker-Filter-Status-PopUp'
                                    suffixIcon={null}
                                    onChange={(e, date) => {
                                        dispatch(SetDateFilterStatusReducer(date))
                                        setDateYearMonth(dayjs(date))
                                    }}
                                />
                            </div>
                        </div>
                    </Col>
                    <Col lg={22} xs={24}>
                        <Row className='Container-Item-Header-Area' gutter={22}>
                            {
                                rex_loading_data_status
                                ? <div style={{display:'flex', alignItems:'center', gap:'10px'}}>
                                    <Skeleton.Button active={true} style={{height:'60px',width:'250px' , borderRadius:'15px'}} size='large' block={false} />
                                    <Skeleton.Button active={true} style={{height:'60px',width:'250px' , borderRadius:'15px'}} size='large' block={false} />
                                    <Skeleton.Button active={true} style={{height:'60px',width:'250px' , borderRadius:'15px'}} size='large' block={false} />
                                </div>
                                : data_status.length > 0 && data_status.map((are, index) =>
                                    <Col lg={6} xs={24} key={index} className='Container-Item-Area'>
                                        <Link style={{width:'100%', display:'flex', justifyContent:'center'}} to={indexItemSelected == index ? are.areresponsable : null} isDynamic={true} smooth={true} offset={-90} duration={500}>
                                            <div 
                                                onClick={() => {
                                                    if(are.aremostrar){
                                                        setIndexItemSelected(null)
                                                    }
                                                }}
                                                onMouseEnter={() => {
                                                    if(are.aremostrar){
                                                        setIndexItemSelected(index)
                                                    }
                                                }}
                                                className={`Container-Section-Status ${indexItemSelected == index ? "Item-Selected":""} ${indexItemSelected == null ? "Section-Minimun-Space": ""} ${are.aremostrar ? "" : "Card-Circle-Status-Not-Avaliable"}`}
                                            >
                                                <div className="Container-Item-Status">
                                                    <img className='Image-Indicator-Area-Top' src={loadImage(`./${are.areicono}`)} alt={are.areicono}/>
                                                    <div className='Container-Description-Item-Status'>
                                                        <p className="Title-Item-Status">{are.arenombre}</p>
                                                        <small className="Responsible-Item-Status">{are.areresponsable}</small>
                                                    </div>
                                                </div>
                                                <Progress 
                                                    className="Percentage-Circle-Status"
                                                    type="circle" 
                                                    percent={are.areporcentajeinicial}
                                                    strokeColor={are.areporcentajeinicial <= 70 ? '#FA382D' : are.areporcentajeinicial <= 95 ? '#FFC700' : '#2AD295'}
                                                    trailColor="#C4D5FF"
                                                    width={70}
                                                    strokeWidth={8}
                                                    format={(percent) => `${percent}%`}
                                                />
                                                <Progress 
                                                    className="Percentage-Linear-Status"
                                                    percent={are.areporcentajeinicial} 
                                                    strokeColor={are.areporcentajeinicial <= 70 ? '#FA382D' : are.areporcentajeinicial <= 95 ? '#FFC700' : '#2AD295'}
                                                    trailColor="#C4D5FF"
                                                    format={(percent) => `${percent}%`}
                                                />
                                            </div>
                                        </Link>
                                    </Col>
                                )
                            }
                        </Row>
                    </Col>
                </Row>
            </Card>
        </Affix>
    )
}

export default CardStatus