import React, { useState } from 'react'
import { Link } from "react-router-dom"
import { useNavigate } from "react-router-dom"
import { HashLink as RouterHashLink } from "react-router-hash-link"
import { scroller } from 'react-scroll'
import { Menu, Row, Col } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { LogOutReducer } from '../../Redux/Actions/LogOut/LogOut'
import { ArrowRightOutlined } from '@ant-design/icons'
import SoundOption from '../../Assets/Audio/sonidoOptionNew.mp3'
import { SelectedMenuReducer } from '../../Redux/Actions/GetData/Menu/Menu'
import { StatusMenuOpenReducer } from '../../Redux/Actions/LoginGrow/LoginGrow'
import { MenuChangeHomeCategoryReducer } from '../../Redux/Actions/Home/Home'

function RightMenu({ setVisible, setNameClassButton, menuAbierto, setTopMenu }) {
    const navigate = useNavigate()
    const dispatch = useDispatch()

    let audio = new Audio(SoundOption)

    const reproduceSound = () => {
        audio.play()
    }

    const { 
        rex_status_menu_open
    } = useSelector(({loginGrow}) => loginGrow)

    const { 
        rex_data_menu_clone,
    } = useSelector(({menu}) => menu)

    return (
        <Menu mode="vertical">
            <Row
                className='Menu-Container'
                onClick={() => {
                    setVisible(false)
                    setNameClassButton('not-active')
                }}
                // onMouseLeave={() => setOnMouseOverOption(null)}
            >
                {
                    rex_data_menu_clone.map((option, indexOption) => {
                        if(option.toUrl == "/categoría" || option.toUrl == "/home"){
                            return <RouterHashLink to={option.toUrl == "/home" ? "/home#Home": "/home#Category"}>
                                <Col
                                    onMouseEnter={ () => reproduceSound() }
                                    // onMouseOver={()=> setOnMouseOverOption(indexOption)}
                                    className={`
                                        Container-Option-Menu Menu-Row-Option ${option.selectedMenu && 'Menu-Option-Selected'}`} 
                                    onClick={async () => {
                                        if(option.id == 1){
                                            await dispatch(LogOutReducer())
                                            
                                            navigate('/login')
                                        }else{
                                            if(option.toUrl == "/home"){
                                                await dispatch(MenuChangeHomeCategoryReducer("Home"))
                                                scroller.scrollTo('Home', {
                                                    isDynamic: true,
                                                    smooth: true,
                                                    duration: 0,
                                                })
                                            }else{
                                                await dispatch(MenuChangeHomeCategoryReducer("Category"))
                                                scroller.scrollTo('Category', {
                                                    isDynamic: true,
                                                    smooth: true,
                                                    duration: 0,
                                                })
                                            }
                                            await dispatch(StatusMenuOpenReducer(!rex_status_menu_open))
                                            setTopMenu(false)
                                            dispatch(SelectedMenuReducer(option.title))
                                        }
                                    }}
                                >
                                    <div
                                        className='Menu-Image'
                                    >
                                        {option.component(indexOption)}
                                    </div>
                                    <div className='Menu-Text'>
                                        {option.title}
                                    </div>
                                    <ArrowRightOutlined
                                        className="Arrow-Menu-Icon"
                                    />
                                </Col>
                            </RouterHashLink>
                        }else{
                            if(option.toUrl){
                                return <Link to={option.toUrl}>
                                    <Col
                                        onMouseEnter={ () => reproduceSound() }
                                        // onMouseOver={()=> setOnMouseOverOption(indexOption)}
                                        className={`
                                            Container-Option-Menu Menu-Row-Option ${option.selectedMenu && 'Menu-Option-Selected'}`} 
                                        onClick={async () => {
                                            if(option.id == 1){
                                                await dispatch(LogOutReducer())
                                                navigate('/login')
                                            }else{
                                                await dispatch(StatusMenuOpenReducer(!rex_status_menu_open))
                                                setTopMenu(false)
                                                dispatch(SelectedMenuReducer(option.title))
                                            }
                                        }}
                                    >
                                        <div
                                            className='Menu-Image'
                                        >
                                            {option.component(indexOption)}
                                        </div>
                                        <div className='Menu-Text'>
                                            {option.title}
                                        </div>
                                        <ArrowRightOutlined
                                            className="Arrow-Menu-Icon"
                                        />
                                    </Col>
                                </Link>  
                            }else{
                                return <Col
                                    onMouseEnter={ () => reproduceSound() }
                                    className={`
                                        Container-Option-Menu Menu-Row-Option ${option.selectedMenu && 'Menu-Option-Selected'}`} 
                                    onClick={async () => {
                                        if(option.id == 1){
                                            await dispatch(LogOutReducer())
                                            navigate('/login')
                                        }else{
                                            await dispatch(StatusMenuOpenReducer(!rex_status_menu_open))
                                            setTopMenu(false)
                                            dispatch(SelectedMenuReducer(option.title))
                                        }
                                    }}
                                >
                                    <div
                                        className='Menu-Image'
                                    >
                                        {option.component(indexOption)}
                                    </div>
                                    <div className='Menu-Text'>
                                        {option.title}
                                    </div>
                                    <ArrowRightOutlined
                                        className="Arrow-Menu-Icon"
                                    />
                                </Col>
                            }
                        }
                    })
                }
            </Row>
        </Menu>
    )
}

export default RightMenu;