import React, { useEffect, useState } from 'react'
import { Modal, Row, Col, Typography, Input, Select, Space, Form, Button, DatePicker } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import "../../../Styles/Components/Global/FormCreateMSL.css"
import { CreateMslManageReducer, EditMslManageReducer } from '../../../Redux/Actions/Manage/MSL/MSL'
import notificationGlobal from '../../Global/NotificationGlobal'
import dayjs from 'dayjs'

const FormCreateMSL = (props) => {

    const setOpenModal      = props.setOpenModal
    const openModal         = props.openModal
    const idsSelectedMsl    = props.idsSelectedMsl
    const typeActionMsl     = props.typeActionMsl
    const dataMsl           = props.dataMsl
    const setDataMsl        = props.setDataMsl
    const dateFilter        = props.dateFilter
    const setSelectedRowKeys = props.setSelectedRowKeys
    const setValueCheckbox = props.setValueCheckbox

    const [loadingSave, setLoadingSave] = useState(false)

    const { Title } = Typography
    const { Option } = Select
    const [form] = Form.useForm()
    const dispatch = useDispatch()

    form.setFieldsValue({
        channel : dataMsl.channel ? dataMsl.channel : "",
        msl     : dataMsl.msl ? dataMsl.msl : "",
        date    : dataMsl.date ?  dayjs(dataMsl.date) : null
    })

    const { 
        rex_data_channels_msl_manage,
    } = useSelector(({msl}) => msl)
    
    const onFinish = async (values) => {
        
        if(idsSelectedMsl.length == 0){
            notificationGlobal("warning", "Debe seleccionar algún producto")
            return false
        }

        let response = false

        setLoadingSave(true)

        if(typeActionMsl == 'create'){
            response = await dispatch(CreateMslManageReducer(idsSelectedMsl, values.channel, values.msl, values.date, dateFilter))
        }else{
            response = await dispatch(EditMslManageReducer(idsSelectedMsl, values.channel, values.msl, values.date, dateFilter))
            if(response){
                setDataMsl([])
            }
        }
        if(response){
            setValueCheckbox(false)
            setSelectedRowKeys([])
            setOpenModal(false)
            form.setFieldsValue({
                channel : "",
                msl     : "",
                date    : null
            })
        }
        setLoadingSave(false)
    }
    
    const onFinishFailed = (errorInfo) => {
        if(idsSelectedMsl.length == 0){
            notificationGlobal("warning", "Debe seleccionar algún producto")
        }else{
            notificationGlobal("warning", "Todos los campos son obligatorios")
        }
    }

    useEffect(() => {
    },[])

    useEffect(() => {
    },[form])

    return (

        <Modal 
            title={null}
            open={openModal}
            closable={false}
            width={500}
            footer={null}
            zIndex={100000000}
            className='Modal-Create-MSL'
        >
            <Row className='Modal-Container-Text-Create-MSL'>
                <Col span={24} className='Modal-Container-Text-Main'>
                    <Title>
                        {typeActionMsl == 'create' ? 'Nuevo MSL' : 'Editar MSL'}
                    </Title>
                </Col>
            </Row>
            <Form
                autoComplete='off'
                layout='vertical'
                onFinish={onFinish}
                form={form}
                onFinishFailed={onFinishFailed}
                initialValues={{
                    remember: false,
                }}
                setFieldsValue={{
                    msl : 'asdasdad'
                }}
            >
                <Row gutter={16} className='Modal-Container-Form-Create-MSL'>
                    <Col span={24}>
                        <Form.Item
                            label="Canal:"
                            name="channel"
                            className='Form-Create-MSL-Item'
                            rules={[
                                {
                                    required    : true,
                                    message     : "El canal es requerido",
                                },
                            ]}
                        >
                            <Select
                                popupClassName="Form-Create-Msl-Select"
                                placeholder="Seleccionar"
                            >
                                {
                                    rex_data_channels_msl_manage.map(ch => (
                                        <Option key={ch.value} value={ch.value}>{ch.label}</Option>
                                    ))
                                }
                            </Select>
                        </Form.Item>
                    </Col>
                    <Col span={24}>
                        <Form.Item
                            label="MSL:"
                            name="msl"
                            className='Form-Create-MSL-Item'
                            rules={[
                                {
                                    required    : true,
                                    message     : "El MSL es requerido",
                                },
                            ]}
                        >
                            <Input/>
                        </Form.Item>
                    </Col>
                    <Col span={24} className='Form-Create-Msl-Col-Datepicker'>
                        <Form.Item
                            label="Fecha:"
                            name="date"
                            className='Form-Create-MSL-Item'
                            rules={[
                                {
                                    required    : true,
                                    message     : "La fecha es requerida",
                                },
                            ]}
                        >
                            <DatePicker
                                format={'YYYY/MM'}
                                popupClassName="Form-Create-Msl-DatePicker"
                                picker="month" 
                            />
                        </Form.Item>
                    </Col>
                </Row>
                <Row>
                    <Col span={24} className='Form-Create-Msl-Buttons-Action'>
                        <Space>
                            <Button
                                danger
                                onClick={() => setOpenModal(false)}
                            >
                                Cancelar
                            </Button>
                            <Button 
                                className='Form-Create-MSL-Button-Create'
                                type="primary" htmlType="submit"
                                loading={loadingSave}
                            >
                                Guardar
                            </Button>
                        </Space>
                    </Col>
                </Row>
            </Form>

        </Modal>
    )
}

export default FormCreateMSL