import React, { useState } from 'react'
import { Button, Modal } from 'antd'
import { useDispatch, useSelector } from 'react-redux'
import { CancelConfirmDeleteProductsManageReducer, DeleteMasterProductManageReducer } from '../../../Redux/Actions/Manage/MasterProducts/MasterProducts'
import '../../../Styles/Components/Manage/MasterProducts/ModalConfirmDeleteProducts.css'

const ModalConfirmDeleteProducts = ({dateMsl, dateProducts, DateNewProduct}) => {

    const [ deletingProducts, setDeletingProducts ] = useState(false)

    const { 
        rex_show_modal_alert_delete_products,
        rex_data_alert_delete_products
    } = useSelector(({masterProducts}) => masterProducts)

    const dispatch = useDispatch()

    const confirmDeleteProducts = async () => {
        
        setDeletingProducts(true)
        let idsDelete = rex_data_alert_delete_products.idsDelete
        const { response, alertDelete } = await dispatch(DeleteMasterProductManageReducer( idsDelete, true, dateProducts, dateMsl, DateNewProduct))
        if( response && !alertDelete ){
            dispatch(CancelConfirmDeleteProductsManageReducer())
        }
        setDeletingProducts(false)
    }

    return (
        <Modal
            title={null}
            open={rex_show_modal_alert_delete_products}
            closable={false}
            width={500}
            footer={null}
            zIndex={100000000}
            bodyStyle={{minHeight:"150px"}}
            className='Modal-Confirm-Delete-Products'
        >
            <div>

                <div className="Container-Message-Delete-Products">
                    {
                        rex_data_alert_delete_products.listProducts?.length > 0 && rex_data_alert_delete_products.listProducts.map(pro => (
                            <>
                                <div className="Title-Confirm-Delete-Products">{pro.info}:</div>
                                {pro.data.map(msg => {
                                    return <div>- {msg}</div>    
                                })}
                            </>                                
                        ))
                    }
                </div>
            </div>
            <div style={{display: "flex", justifyContent:"center", columnGap: "10px"}}>
                <Button
                    onClick={() => dispatch(CancelConfirmDeleteProductsManageReducer())}
                    danger
                    style={{
                        height: "34px",
                        paddingLeft: "15px",
                        paddingRight: "15px",
                        display: "flex",
                        alignItems: "center",
                        borderRadius: "20px",
                        fontSize: "13px",
                        fontFamily: "Inter",
                    }}
                >
                    Cancelar
                </Button>
                <Button 
                    onClick={confirmDeleteProducts}
                    loading={deletingProducts}
                    type="primary" 
                    style={{
                        background: "#2AD295",
                        height: "34px",
                        paddingLeft: "15px",
                        paddingRight: "15px",
                        display: "flex",
                        alignItems: "center",
                        borderRadius: "20px",
                        color: "white",
                        fontSize: "13px",
                        fontFamily: "Inter",
                    }}
                >
                    Confirmar
                </Button>

            </div>
        </Modal>
    )
}

export default ModalConfirmDeleteProducts