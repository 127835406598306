import config from "../../../../config";
import {
    OBTENER_GRAFICO_VISTAS_PROMEDIO_ANALYTICS,
    OBTENER_GRAFICO_VISTAS_PROMEDIOS_MINUTOS_ANALYTICS,
    OBTENER_GRAFICO_USUARIOS_GANADOS_ANALYTICS,
    OBTENER_GRAFICO_USUARIOS_PERDIDOS_ANALYTICS,
    OBTENER_GRAFICO_SELECCIONADO_ANALYTICS,
    OBTENER_GRAFICO_VISITAS_USUARIO_ANALYTICS
} from "../../../../Constants/Analytics/Graficos/GraficosAnalytics";
import { RegisterAuditReducer } from "../../Audits/Audits";
const moment = require('moment')
moment.locale()


export const ObtenerGraficaVistasReducer = () => async (dispatch, getState) => {

    const user = getState().loginGrow.rex_data_user_logingrow

    const data_module_average_views = {
        req_paiid : parseInt(localStorage.getItem('usupais')),
    }

    let fechas_grafica  = []
    let data_chart      = []
    for(let i = 0 ; i < 30 ; i ++){
        let fecha_grafica = moment().subtract(i, 'd').startOf('d').format('DD MMM')
        fechas_grafica.push(fecha_grafica)
    }

    let fecha_grafica_reverse = fechas_grafica.reverse()

    fecha_grafica_reverse.map((fecha, index_fecha) => {
        data_chart.push({
            label   : fecha,
            cantidad: 0
            // cantidad: fecha_vista_reverse[index_fecha]['cantidad_vistas']
        })
    })

    let dataViews = data_chart
    let arrayViews = [
        40, 70, 35,  28, 39,  62, 16, 47, 30,
        17, 82, 18, 43, 36, 21, 23, 43,  13,
        48, 81, 31, 33, 58, 24,  89, 38, 18,
        17, 43, 46
    ]
    dataViews.map((dat, index) => {
        dat['cantidad'] = arrayViews[index]
    })
    dispatch({
        type : OBTENER_GRAFICO_VISTAS_PROMEDIO_ANALYTICS,
        payload: data_chart
    })

    dispatch({
        type : OBTENER_GRAFICO_SELECCIONADO_ANALYTICS,
        payload: data_chart
    })


    dispatch(ObtenerGraficaVistasPromedioReducer())
    // await fetch(config.api + 'analytics/audit/graph/views-average',
    //     {
    //         mode:'cors',
    //         method: 'POST',
    //         headers: {
    //             'Accept'        : 'application/json',
    //             'Content-type'  : 'application/json',
    //             'usutoken'	    : localStorage.getItem('usutoken'),
    //         },
    //         body: JSON.stringify(data_module_average_views),
    //     }
    // )
    // .then( async res => {
    //     // await dispatch(estadoRequestReducer(res.status))
    //     return res.json()
    // })
    // .then(data => {
    //     if(data.respuesta == true){
    //         let dataViews = data.data_chart
    //         let arrayViews = [
    //             40, 70, 35,  28, 39,  62, 16, 47, 30,
    //             17, 82, 18, 43, 36, 21, 23, 43,  13,
    //             48, 81, 31, 33, 58, 24,  89, 38, 18,
    //             17, 43, 46
    //         ]
    //         dataViews.map((dat, index) => {
    //             dat['cantidad'] = arrayViews[index]
    //         })
    //         dispatch({
    //             type : OBTENER_GRAFICO_VISTAS_PROMEDIO_ANALYTICS,
    //             payload: data.data_chart
    //         })

    //         dispatch({
    //             type : OBTENER_GRAFICO_SELECCIONADO_ANALYTICS,
    //             payload: data.data_chart
    //         })
    //     }else{
    //         const audit = {
    //             url         : 'analytics/audit/graph/views-average',
    //             message     : 'Error al obtener los datos de la gráfica de las vistas promedio', 
    //             description :data.message, 
    //             json_input  : data_module_average_views,
    //             json_output : data, 
    //             ip          : 'ip', 
    //             action      : 'GET GRAPH AVERAGE VIEWS',
    //             status      : data.respuesta,
    //             module      : "ANALYTICS"
    //         }
    //         dispatch(RegisterAuditReducer(audit))
    //     }

    // }).catch((error)=> {
    //     console.log(error)
    //     // dispatch(RegistrarErrorReducer(error, config.api+'login', datos))
    // });
}

export const ObtenerGraficaVistasPromedioReducer = () => async (dispatch, getState) => {

    const user = getState().loginGrow.rex_data_user_logingrow

    const data_module_graph_views_average_minutes = {
        req_paiid : parseInt(localStorage.getItem('usupais')),
    }
    let fechas_grafica  = []
    let data_chart      = []
    for(let i = 0 ; i < 30 ; i ++){
        let fecha_grafica = moment().subtract(i, 'd').startOf('d').format('DD MMM')
        fechas_grafica.push(fecha_grafica)
    }

    let fecha_grafica_reverse = fechas_grafica.reverse()

    fecha_grafica_reverse.map((fecha, index_fecha) => {
        data_chart.push({
            label   : fecha,
            cantidad: 0
            // cantidad: fecha_vista_reverse[index_fecha]['cantidad_vistas']
        })
    })

    const dataViewsAverage = [ 35, 39, 45, 43, 41, 36, 20, 22, 32, 23, 44, 24, 42, 43, 40, 18, 46, 52, 29, 37, 18, 50, 24, 45, 41, 26, 51, 26, 39, 41 ]
    
    const dataPrev = data_chart

    dataPrev.map((dat, index) => {
        dat['cantidad'] = dataViewsAverage[index]
    })
    dispatch({
        type : OBTENER_GRAFICO_VISTAS_PROMEDIOS_MINUTOS_ANALYTICS,
        payload: dataPrev
    })
    dispatch({
        type : OBTENER_GRAFICO_SELECCIONADO_ANALYTICS,
        payload: dataPrev
    })

    dispatch(ObtenerGraficaUsuariosGanadosReducer())


    // await fetch(config.api + 'analytics/audit/graph/views-average-minutes',
    //     {
    //         mode:'cors',
    //         method: 'POST',
    //         headers: {
    //             'Accept'        : 'application/json',
    //             'Content-type'  : 'application/json',
    //             'usutoken'	    : localStorage.getItem('usutoken'),
    //         },
    //         body: JSON.stringify(data_module_graph_views_average_minutes),
    //     }
    // )
    // .then( async res => {
    //     // await dispatch(estadoRequestReducer(res.status))
    //     return res.json()
    // })
    // .then(data => {
    //     if(data.respuesta == true){

    //         const dataPrev = data.data_chart

    //         dataPrev.map((dat, index) => {
    //             dat['cantidad'] = dataViewsAverage[index]
    //         })
    //         dispatch({
    //             type : OBTENER_GRAFICO_VISTAS_PROMEDIOS_MINUTOS_ANALYTICS,
    //             payload: dataPrev
    //         })
    //         dispatch({
    //             type : OBTENER_GRAFICO_SELECCIONADO_ANALYTICS,
    //             payload: dataPrev
    //         })
    //     }else{
    //         const audit = {
    //             url         : 'analytics/audit/graph/views-average-minutes',
    //             message     : 'Error al obtener los datos de la gráfica de las vistas promedio por minutos', 
    //             description :data.message, 
    //             json_input  : data_module_graph_views_average_minutes,
    //             json_output : data, 
    //             ip          : 'ip', 
    //             action      : 'GET GRAPH AVERAGE VIEWS MINUTES',
    //             status      : data.respuesta,
    //             module      : "ANALYTICS"
    //         }
    //         dispatch(RegisterAuditReducer(audit))
    //     }

    // }).catch((error)=> {
    //     console.log(error)
    //     // dispatch(RegistrarErrorReducer(error, config.api+'login', datos))
    // });
}
export const ObtenerGraficaUsuariosGanadosReducer = () => async (dispatch, getState) => {

    const user = getState().loginGrow.rex_data_user_logingrow

    const data_module_graph_gained_users = {
        req_paiid : parseInt(localStorage.getItem('usupais')),
    }

    const dataGained = [
        7,  4, 15, 13,  8, 6, 13, 11, 16,
        13, 14, 11, 10, 12, 9, 11, 10, 9,
        16, 14, 13, 9, 8, 15, 7, 16,  8,
        3, 11, 10
    ]

    let fechas_grafica  = []
    let data_chart      = []
    for(let i = 0 ; i < 30 ; i ++){
        let fecha_grafica = moment().subtract(i, 'd').startOf('d').format('DD MMM')
        fechas_grafica.push(fecha_grafica)
    }

    let fecha_grafica_reverse = fechas_grafica.reverse()

    fecha_grafica_reverse.map((fecha, index_fecha) => {
        data_chart.push({
            label   : fecha,
            cantidad: 0
            // cantidad: fecha_vista_reverse[index_fecha]['cantidad_vistas']
        })
    })

    const dataPrev = data_chart

    dataPrev.map((dat, index) => {
        dat['cantidad'] = dataGained[index]
    })

    dispatch({
        type : OBTENER_GRAFICO_USUARIOS_GANADOS_ANALYTICS,
        payload: dataPrev
    })
    dispatch({
        type : OBTENER_GRAFICO_SELECCIONADO_ANALYTICS,
        payload: dataPrev
    })

    dispatch(ObtenerGraficaUsuariosPerdidosReducer())

    // await fetch(config.api + 'analytics/audit/graph/users-gained',
    //     {
    //         mode:'cors',
    //         method: 'POST',
    //         headers: {
    //             'Accept'        : 'application/json',
    //             'Content-type'  : 'application/json',
    //             'usutoken'	    : localStorage.getItem('usutoken'),
    //         },
    //         body: JSON.stringify(data_module_graph_gained_users),
    //     }
    // )
    // .then( async res => {
    //     // await dispatch(estadoRequestReducer(res.status))
    //     return res.json()
    // })
    // .then(data => {
    //     if(data.respuesta == true){

    //         const dataPrev = data.data_chart

    //         dataPrev.map((dat, index) => {
    //             dat['cantidad'] = dataGained[index]
    //         })

    //         dispatch({
    //             type : OBTENER_GRAFICO_USUARIOS_GANADOS_ANALYTICS,
    //             payload: dataPrev
    //         })
    //         dispatch({
    //             type : OBTENER_GRAFICO_SELECCIONADO_ANALYTICS,
    //             payload: dataPrev
    //         })

    //     }else{
    //         const audit = {
    //             url         : 'analytics/audit/graph/users-gained',
    //             message     : 'Error al obtener los datos de la gráfica de los usuarios ganados', 
    //             description :data.message, 
    //             json_input  : data_module_graph_gained_users,
    //             json_output : data, 
    //             ip          : 'ip', 
    //             action      : 'GET GRAPH GAINED USERS',
    //             status      : data.respuesta,
    //             module      : "ANALYTICS"
    //         }
    //         dispatch(RegisterAuditReducer(audit))
    //     }

    // }).catch((error)=> {
    //     console.log(error)
    //     // dispatch(RegistrarErrorReducer(error, config.api+'login', datos))
    // });
}

export const ObtenerGraficaUsuariosPerdidosReducer = () => async (dispatch, getState) => {

    const user = getState().loginGrow.rex_data_user_logingrow

    const data_module_graph_lost_user = {
        req_paiid : parseInt(localStorage.getItem('usupais')),
    }

    const dataLost = [ 0, 0, 1, 0, 1, 0, 0, 2, 0, 1, 1, 1, 1, 0, 1, 0, 1, 0, 0, 0, 2, 0, 0, 0, 1, 0, 1, 1, 0, 0 ]

    let fechas_grafica  = []
    let data_chart      = []
    for(let i = 0 ; i < 30 ; i ++){
        let fecha_grafica = moment().subtract(i, 'd').startOf('d').format('DD MMM')
        fechas_grafica.push(fecha_grafica)
    }

    let fecha_grafica_reverse = fechas_grafica.reverse()

    fecha_grafica_reverse.map((fecha, index_fecha) => {
        data_chart.push({
            label   : fecha,
            cantidad: 0
        })
    })

    const dataPrev = data_chart

    dataPrev.map((dat, index) => {
        dat['cantidad'] = dataLost[index]
    })

    dispatch({
        type : OBTENER_GRAFICO_USUARIOS_PERDIDOS_ANALYTICS,
        payload: dataPrev
    })
    dispatch({
        type : OBTENER_GRAFICO_SELECCIONADO_ANALYTICS,
        payload: dataPrev
    })
    
    // await fetch(config.api + 'analytics/audit/graph/users-lost',
    //     {
    //         mode:'cors',
    //         method: 'POST',
    //         headers: {
    //             'Accept'        : 'application/json',
    //             'Content-type'  : 'application/json',
    //             'usutoken'	    : localStorage.getItem('usutoken'),
    //         },
    //         body: JSON.stringify(data_module_graph_lost_user),
    //     }
    // )
    // .then( async res => {
    //     // await dispatch(estadoRequestReducer(res.status))
    //     return res.json()
    // })
    // .then(data => {
    //     if(data.respuesta == true){

    //         const dataPrev = data.data_chart

    //         dataPrev.map((dat, index) => {
    //             dat['cantidad'] = dataLost[index]
    //         })

    //         dispatch({
    //             type : OBTENER_GRAFICO_USUARIOS_PERDIDOS_ANALYTICS,
    //             payload: dataPrev
    //         })
    //         dispatch({
    //             type : OBTENER_GRAFICO_SELECCIONADO_ANALYTICS,
    //             payload: dataPrev
    //         })
    //     }else{
    //         const audit = {
    //             url         : 'analytics/audit/graph/users-lost',
    //             message     : 'Error al obtener los datos de la gráfica de los usuarios perdidos', 
    //             description :data.message, 
    //             json_input  : data_module_graph_lost_user,
    //             json_output : data, 
    //             ip          : 'ip', 
    //             action      : 'GET GRAPH LOST USERS',
    //             status      : data.respuesta,
    //             module      : "ANALYTICS"
    //         }
    //         dispatch(RegisterAuditReducer(audit))
    //     }

    // }).catch((error)=> {
    //     console.log(error)
    //     // dispatch(RegistrarErrorReducer(error, config.api+'login', datos))
    // });
}

export const ObtenerGraficaVistasUsuariosReducer = () => async (dispatch, getState) => {

    const user = getState().loginGrow.rex_data_user_logingrow

    const data_module_graph_visits_users = {
        req_paiid : parseInt(localStorage.getItem('usupais')),
    }

    await fetch(config.api + 'analytics/audit/graph/views-users',
        {
            mode:'cors',
            method: 'POST',
            headers: {
                'Accept'        : 'application/json',
                'Content-type'  : 'application/json',
                'usutoken'	    : localStorage.getItem('usutoken'),
            },
            body: JSON.stringify(data_module_graph_visits_users),
        }
    )
    .then( async res => {
        // await dispatch(estadoRequestReducer(res.status))
        return res.json()
    })
    .then(data => {
        if(data.respuesta == true){
            dispatch({
                type : OBTENER_GRAFICO_VISITAS_USUARIO_ANALYTICS,
                payload: data.data_chart
            })
            dispatch({
                type : OBTENER_GRAFICO_SELECCIONADO_ANALYTICS,
                payload: data.data_chart
            })

            dispatch(ObtenerGraficaSeleccionadaReducer(1, true))
        }else{
            const audit = {
                url         : 'analytics/audit/graph/views-users',
                message     : 'Error al obtener los datos de la gráfica de las visitas de los usuarios', 
                description :data.message, 
                json_input  : data_module_graph_visits_users,
                json_output : data, 
                ip          : 'ip', 
                action      : 'GET GRAPH VISITS USERS',
                status      : data.respuesta,
                module      : "ANALYTICS"
            }
            dispatch(RegisterAuditReducer(audit))
        }

    }).catch((error)=> {
        console.log(error)
        // dispatch(RegistrarErrorReducer(error, config.api+'login', datos))
    });
}

export const ObtenerGraficaSeleccionadaReducer = (key, getData) => async ( dispatch, getState ) => {

    let grafica_vistas              = getState().graficosAnalytics.rex_grafico_visitas_promedio
    let grafica_vistas_promedios    = getState().graficosAnalytics.rex_grafico_vistas_promedios_minutos
    let grafica_usuarios_ganados    = getState().graficosAnalytics.rex_grafico_usuarios_ganados
    let grafica_usuarios_perdidos   = getState().graficosAnalytics.rex_grafico_usuarios_perdidos
    let grafico_visitas_usuario   = getState().graficosAnalytics.rex_grafico_visitas_usuario

    let data_grafica = []

    switch(key){
        case 1:
            if(grafica_vistas.length == 0){
                dispatch(ObtenerGraficaVistasReducer())
            }else{
                data_grafica = grafica_vistas
            }
            break
        case 2:
            if(grafica_vistas_promedios.length == 0){
                dispatch(ObtenerGraficaVistasPromedioReducer())
            }else{
                data_grafica = grafica_vistas_promedios
            }
            break
        case 3:
            if(grafica_usuarios_ganados.length == 0){
                dispatch(ObtenerGraficaUsuariosGanadosReducer())
            }else{
                data_grafica = grafica_usuarios_ganados
            }
            break
        case 4:
            if(grafica_usuarios_perdidos.length == 0){
                dispatch(ObtenerGraficaUsuariosPerdidosReducer())
            }else{
                data_grafica = grafica_usuarios_perdidos
            }
            break
        case 5:
            data_grafica = grafico_visitas_usuario
        default:
            break
    }
    dispatch({
        type: OBTENER_GRAFICO_SELECCIONADO_ANALYTICS,
        payload: data_grafica
    })
}