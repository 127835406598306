import React from 'react'
import { Row, Col, Typography } from 'antd'
import { useParams } from 'react-router'
import { useSelector } from 'react-redux'
import FormCreateEditExecutives from '../../Components/ClientsExecutives/FormCreateEditExecutives'
import NotificationCreated from '../../Components/Users/NotificationCreated'
import NavigationGlobal from '../../Components/Global/NavigationGlobal'
import SideMenu from '../../Components/Global/SideMenu'

const CreateExecutives = () => {

    const { Title } = Typography
    const { id } = useParams()

    const menuUsers = [
        {
            url: '/users',
            nombre: 'Lista de usuarios'
        },
        {
            url: '/create-user',
            nombre: 'Nuevo usuario'
        },
        {
            url: '/profile-type-select',
            nombre: 'Seleccionar tipo de perfil'
        },
    ]

    const { 
        rex_users_conected_dashboard,
    } = useSelector(({registerUsers}) => registerUsers)

    const { 
        rex_data_users_online
    } = useSelector(({users}) => users)

    return (
        <div className='Container-Main'>
            <NavigationGlobal menuItems={menuUsers}/>
            <Row>
                <Col span={24}>
                    <Title level={2} className='Title-Search-Text-Global'>
                        {
                            id ? "Editar Cliente Ejecutivo" : "Nuevo Cliente Ejecutivo"
                        }
                    </Title>
                </Col>
            </Row>
            <FormCreateEditExecutives />
            <NotificationCreated />

            <SideMenu
                usersCurrentView  = {rex_users_conected_dashboard}
                usersAllConnected ={rex_data_users_online}
            />
        </div>
    )
}

export default CreateExecutives