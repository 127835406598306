import { 
    REGISTER_USERS_CONNECTED_MODULES_ANALYTICS,
    OBTENER_VISTAS_USUARIO_ANALYTICS,
    OBTENER_VISTAS_ANALYTICS,
    OBTENER_VISTAS_PROMEDIOS_MINUTOS_ANALYTICS,
    OBTENER_USUARIOS_GANADOS_ANALYTICS,
    OBTENER_USUARIOS_PERDIDOS_ANALYTICS,
    OBTENER_VISTAS_MODULOS_ANALYTICS,
    OBTENER_VISTAS_SUBMODULOS_ANALYTICS,
    OBTENER_VISTAS_SUBSUBMODULOS_ANALYTICS,
    OBTENER_DATA_SLIDER_ANALYTICS,
    OBTENER_DATA_FECHAS_ANALYTICS
} from "../../../Constants/Analytics/Analytics";

const INIT_STATE = {
    rex_users_analytics_connected : [],
    rex_vistas_usuario              : [],
    rex_fechas_analytics            : [],
    rex_vistas                      : [],
    rex_vistas_promedios_minutos    : [],
    rex_usuarios_ganados            : [],
    rex_usuarios_perdidos           : [],
    rex_vistas_modulos              : [],
    rex_vistas_submodulos           : [],
    rex_vistas_subsubmodulos        : [],
    rex_data_slider                 : [
        {
            key         : 1,
            titleSlider : 'Vistas',
        },
        {
            key         : 2,
            titleSlider : 'Tiempo promedio de actividad'
        },
        {
            key         : 3,
            titleSlider : 'Usuarios ganados'}
            ,
        {
            key         : 4,
            titleSlider : 'Usuarios perdidos'
        }
    ]
};

export default (state = INIT_STATE, action) => {
    switch (action.type) {

        case OBTENER_DATA_FECHAS_ANALYTICS: {
            return {
                ...state,
                rex_fechas_analytics : action.payload,
            }
        }

        case REGISTER_USERS_CONNECTED_MODULES_ANALYTICS: {
            return {
                ...state,
                rex_users_analytics_connected : action.payload,
            }
        }
        case OBTENER_VISTAS_USUARIO_ANALYTICS: {
            return {
                ...state,
                rex_vistas_usuario : action.payload
            }
        }
        case OBTENER_VISTAS_ANALYTICS: {
            return {
                ...state,
                rex_vistas : action.payload
            }
        }
        case OBTENER_VISTAS_PROMEDIOS_MINUTOS_ANALYTICS: {
            return {
                ...state,
                rex_vistas_promedios_minutos : action.payload
            }
        }
        case OBTENER_USUARIOS_GANADOS_ANALYTICS: {
            return {
                ...state,
                rex_usuarios_ganados : action.payload
            }
        }
        case OBTENER_USUARIOS_PERDIDOS_ANALYTICS: {
            return {
                ...state,
                rex_usuarios_perdidos : action.payload
            }
        }
        case OBTENER_VISTAS_MODULOS_ANALYTICS: {
            return {
                ...state,
                rex_vistas_modulos : action.payload
            }
        }
        case OBTENER_VISTAS_SUBMODULOS_ANALYTICS: {
            return {
                ...state,
                rex_vistas_submodulos : action.payload
            }
        }
        case OBTENER_VISTAS_SUBSUBMODULOS_ANALYTICS: {
            return {
                ...state,
                rex_vistas_subsubmodulos : action.payload
            }
        }
        case OBTENER_DATA_SLIDER_ANALYTICS: {
            return {
                ...state,
                rex_data_slider : action.payload
            }
        }
        default:
            return state;
    }
}