import React from 'react'
import { Modal, Typography, Row, Col } from 'antd'
import "../../Styles/Components/Global/ModalDetailDescriptionTable.css"

const ModalDetailDescriptionTable = (props) => {

    const setOpenModalDescription = props.setOpenModalDescription
    const dataModalDescription = JSON.parse(props.dataModalDescription)
    const openModalDescription = props.openModalDescription

    const { Title } = Typography

    return (
        <Modal 
            title={null}
            open={openModalDescription}
            closable={false}
            width={700}
            footer={null}
            zIndex={100000000}
            className='Modal-Notification-Description-Table'
        >
            <Row className='Modal-Container-Text-Detail-Json'>
                <Col span={24} className='Modal-Container-Text-Main'>
                    <Title>
                        Detalle:
                    </Title>
                </Col>
                <Col span={24} className='Modal-Container-Text-Description'>
                    {
                        dataModalDescription.map((dat, index) => (
                            <p key={index}>- {JSON.stringify(dat)}</p>
                        ))
                    }
                </Col>
                <Col span={24} className='Modal-Container-Button-Main'>
                    <div
                        onClick={() => setOpenModalDescription(false)}
                        className='Modal-Close-Detail-Json-Table'
                    >
                        Ok
                    </div>
                </Col>
            </Row>
        </Modal>
    )
    
}

export default ModalDetailDescriptionTable