
import notificationGlobal from "../../../../Components/Global/NotificationGlobal";
import config from "../../../../config";
import {
    DATA_ALERT_DELETE_CLIENTS,
    DATA_FORM_EDIT_MASTER_CLIENT,
    DATA_FORM_EDIT_MASTER_CLIENT_MANUAL,
    DATE_LAST_UPDATED_CLIENTS_MANAGE,
    GET_DATA_FILTERS_TXT_CLIENTS_TOTAL,
    GET_DATA_MASTER_CLIENT_MANAGE,
    GET_DATA_MASTER_CLIENTS_MANAGE,
    GET_DATA_MASTER_CLIENTS_MANAGE_CLONE,
    GET_DATA_MASTER_CLIENTS_MANUALS_MANAGE,
    LOADING_GET_DATA_MASTER_CLIENTS_MANAGE,
    LOADING_GET_DATA_MASTER_CLIENTS_MANUALS_MANAGE,
    SHOW_MODAL_ALERT_DELETE_CLIENTS
} from "../../../../Constants/Manage/MasterClients/MasterClients";
import { 
    GET_DATA_FILTERS_CHANNELS_MASTER_PRODUCTS_MANAGE, 
    GET_DATA_FILTERS_ZONA_MASTER_PRODUCTS_MANAGE,
    GET_DATA_FILTERS_CLIENTES_MASTER_PRODUCTS_MANAGE,
    GET_DATA_FILTERS_SUCURSAL_MASTER_PRODUCTS_MANAGE,
    GET_DATA_FILTERS_GBA_MASTER_PRODUCTS_MANAGE,
    GET_DATA_FILTERS_CUSTOMER_GROUP_MASTER_PRODUCTS_MANAGE
} from "../../../../Constants/Manage/MasterProducts/MasterProducts";
import dayjs from 'dayjs';

export const GetMasterClientsManageReducer = ( date = null) => async ( dispatch, getState) => {

    dispatch({
        type    : LOADING_GET_DATA_MASTER_CLIENTS_MANAGE,
        payload : true
    })

    const { 
        rex_filters_clients_total
    } = getState().filtersClients

    let currentDate
    if(typeof(date) == "string" && date.length == 7){
        currentDate = date
    }else{
        const date = dayjs(dayjs(), "AAAA-MM")
        const currentMonth  = date.month() + 1 < 10 ? "0"+ (date.month() + 1) : date.month() + 1
        const currentYear   = date.year()
        currentDate   = currentYear + "-" + currentMonth
    }
    
    await fetch(config.api+'manage/pe/master-clients',
        {
            mode:'cors',
            method: 'POST',
            headers: {
                'Accept' 	   : 'application/json',
                'Content-type' : 'application/json',
                'usutoken'      : localStorage.getItem('usutoken')
            },
            body: JSON.stringify({
                req_date : currentDate,
                req_fil_customer : rex_filters_clients_total.rex_filter_clients.rex_txt_customer_group,
                req_fil_gba : rex_filters_clients_total.rex_filter_clients.rex_txt_unique_gba,
                req_fil_zona : rex_filters_clients_total.rex_filter_clients.rex_txt_unique_zona,
                req_fil_canal : rex_filters_clients_total.rex_filter_clients.rex_txt_uniqueChannels,
                req_fil_cliente : rex_filters_clients_total.rex_filter_clients.rex_txt_unique_cliente,
                req_fil_sucursal : rex_filters_clients_total.rex_filter_clients.rex_txt_unique_sucursal,
            }),
        }
    )
    .then( async res => {
        return res.json()
    })
    .then(data => {
        if(data.response){

            dispatch({
                type: GET_DATA_MASTER_CLIENTS_MANAGE,
                payload : data.data
            })
            dispatch({
                type: GET_DATA_MASTER_CLIENTS_MANAGE_CLONE,
                payload : data.data
            })
            dispatch({
                type : DATE_LAST_UPDATED_CLIENTS_MANAGE,
                payload : data.dateLastUpdated
            })
        }
    }).catch((error)=> {
        console.log(error)
    });

    dispatch({
        type    : LOADING_GET_DATA_MASTER_CLIENTS_MANAGE,
        payload : false
    })
}

export const GetMasterClientsManualsManageReducer = ( date = null ) => async ( dispatch, getState) => {

    dispatch({
        type    : LOADING_GET_DATA_MASTER_CLIENTS_MANUALS_MANAGE,
        payload : true
    })

    const { 
        rex_filters_clients_total
    } = getState().filtersClients

    let currentDate
    if(typeof(date) == "string" && date.length == 7){
        currentDate = date
    }else{
        const date = dayjs(dayjs(), "AAAA-MM")
        const currentMonth  = date.month() + 1 < 10 ? "0"+ (date.month() + 1) : date.month() + 1
        const currentYear   = date.year()
        currentDate   = currentYear + "-" + currentMonth
    }
    
    await fetch(config.api+'manage/pe/master-clients-manuals',
        {
            mode:'cors',
            method: 'POST',
            headers: {
                'Accept' 	   : 'application/json',
                'Content-type' : 'application/json',
                'usutoken'      : localStorage.getItem('usutoken')
            },
            body: JSON.stringify({
                req_date : currentDate,
                req_fil_customer : rex_filters_clients_total.rex_filter_new.rex_txt_customer_group,
                req_fil_gba : rex_filters_clients_total.rex_filter_new.rex_txt_unique_gba,
                req_fil_zona : rex_filters_clients_total.rex_filter_new.rex_txt_unique_zona,
                req_fil_canal : rex_filters_clients_total.rex_filter_new.rex_txt_uniqueChannels,
                req_fil_cliente : rex_filters_clients_total.rex_filter_new.rex_txt_unique_cliente,
                req_fil_sucursal : rex_filters_clients_total.rex_filter_new.rex_txt_unique_sucursal,
            }),
        }
    )
    .then( async res => {
        return res.json()
    })
    .then(data => {
        if(data.response){

            dispatch({
                type: GET_DATA_MASTER_CLIENTS_MANUALS_MANAGE,
                payload : data.data
            })
        }
    }).catch((error)=> {
        console.log(error)
    });

    dispatch({
        type    : LOADING_GET_DATA_MASTER_CLIENTS_MANUALS_MANAGE,
        payload : false
    })
}

export const GetMasterClientManageReducer = (id) => async ( dispatch, getState) => {

    await fetch(config.api+'manage/pe/get-master-client',
    {
        mode:'cors',
        method: 'POST',
        headers: {
            'Accept' 	   : 'application/json',
            'Content-type' : 'application/json',
            'usutoken'      : localStorage.getItem('usutoken')
        },
        body: JSON.stringify({req_id : id}),
    }
    )
    .then( async res => {
        return res.json()
    })
    .then(data => {
        if(data.response){
            dispatch({
                type: GET_DATA_MASTER_CLIENT_MANAGE,
                payload : data.data
            })
        }
    }).catch((error)=> {
        console.log(error)
    });
    const { rex_data_master_client_manage } = getState().masterClients
    return rex_data_master_client_manage
}

export const CreateMasterClientManageReducer = ( data ) => async ( dispatch, getState ) => {

    let response = false
    await fetch(config.api+'manage/pe/create-master-client',
        {
            mode:'cors',
            method: 'POST',
            headers: {
                'Accept' 	   : 'application/json',
                'Content-type' : 'application/json',
                'usutoken'      : localStorage.getItem('usutoken')
            },
            body: JSON.stringify({
                req_data : data
            }),
        }
    )
    .then( async res => {
        return res.json()
    })
    .then(data => {
        if(data.response){
            notificationGlobal('success', data.message)
            response = true
        }else{
            notificationGlobal('warning', data.message)
        }
    }).catch((error)=> {
        console.log(error)
    });

    return response
}

export const EditMasterClientManageReducer = (id, typeForm) => async ( dispatch, getState ) => {

    let dataRows
    if(typeForm == 'manual'){
        dataRows = getState().masterClients.rex_data_form_edit_master_client_manual
    }else{
        dataRows = getState().masterClients.rex_data_form_edit_master_client
    }

    const dataRow = dataRows.find(dat => dat.id == id)
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    const currentMonth = currentDate.getMonth() + 1;
    const dateFormat = `${currentYear}-${currentMonth.toString().padStart(2, '0')}`;

    const dataProduct = {...dataRow.data, anio:currentYear, mes:currentMonth, fecha: dateFormat}

    let response = false
    await fetch(config.api+'manage/pe/edit-master-client',
        {
            mode:'cors',
            method: 'POST',
            headers: {
                'Accept' 	   : 'application/json',
                'Content-type' : 'application/json',
                'usutoken'      : localStorage.getItem('usutoken')
            },
            body: JSON.stringify({
                req_id : id,
                req_data : dataProduct
            }),
        }
    )
    .then( async res => {
        return res.json()
    })
    .then(data => {
        if(data.response){
            const rowsEditing = dataRows.filter(dat => dat.id != id)
            notificationGlobal('success', data.message)
            dispatch(GetMasterClientsManageReducer())
            dispatch(GetMasterClientsManualsManageReducer())

            if(typeForm == 'manual'){
                dispatch({
                    type    : DATA_FORM_EDIT_MASTER_CLIENT_MANUAL,
                    payload : rowsEditing
                })
            }else{
                dispatch({
                    type    : DATA_FORM_EDIT_MASTER_CLIENT,
                    payload : rowsEditing
                })
            }
            response = true
        }else{
            notificationGlobal('warning', data.message)
        }
    }).catch((error)=> {
        console.log(error)
    });

    return response
}

export const EditRowMasterClientManageReducer = (id, record) => async ( dispatch, getState ) => {

    const dataClient = getState().masterClients.rex_data_form_edit_master_client
    let updateData

    if(!record){
        updateData = dataClient.filter(dat => dat.id != id)
    }else{
        const newRow = {id: id, data: {}}
        updateData = [ ...dataClient,  newRow]
    }
    
    dispatch({
        type    : DATA_FORM_EDIT_MASTER_CLIENT,
        payload : updateData
    })
}
export const EditRowMasterClientManualManageReducer = (id, record) => async ( dispatch, getState ) => {

    const dataClient = getState().masterClients.rex_data_form_edit_master_client_manual
    let updateData

    if(!record){
        updateData = dataClient.filter(dat => dat.id != id)
    }else{
        const newRow = {id: id, data: {}}
        updateData = [ ...dataClient,  newRow]
    }
    
    dispatch({
        type    : DATA_FORM_EDIT_MASTER_CLIENT_MANUAL,
        payload : updateData
    })
}


export const DeleteMasterClientManageReducer = (ids, dateClients = null, dateClientsManual = null, confirmDelete = false) => async ( dispatch, getState ) => {

    let response = false
    let alertDelete
    let messageConfirm = []

    let currentDateClients
    if(typeof(dateClients) == "string" && dateClients.length == 7){
        currentDateClients = dateClients
    }else{
        const date = dayjs(dayjs(), "AAAA-MM")
        const currentMonth  = date.month() + 1 < 10 ? "0"+ (date.month() + 1) : date.month() + 1
        const currentYear   = date.year()
        currentDateClients   = currentYear + "-" + currentMonth
    }

    let currentDateClientsManual
    if(typeof(dateClientsManual) == "string" && dateClientsManual.length == 7){
        currentDateClientsManual = dateClientsManual
    }else{
        const date = dayjs(dayjs(), "AAAA-MM")
        const currentMonth  = date.month() + 1 < 10 ? "0"+ (date.month() + 1) : date.month() + 1
        const currentYear   = date.year()
        currentDateClientsManual   = currentYear + "-" + currentMonth
    }

    await fetch(config.api+'manage/pe/delete-master-client',
        {
            mode:'cors',
            method: 'POST',
            headers: {
                'Accept' 	   : 'application/json',
                'Content-type' : 'application/json',
                'usutoken'      : localStorage.getItem('usutoken')
            },
            body: JSON.stringify({
                req_ids : ids,
                req_confirmDelete : confirmDelete
            }),
        }
    )
    .then( async res => {
        return res.json()
    })
    .then(data => {
        if(data.response){

            if(!data.alertDelete){
                notificationGlobal('success', data.message)
                dispatch(GetMasterClientsManageReducer(currentDateClients))
                dispatch(GetMasterClientsManualsManageReducer(currentDateClientsManual))
            }else{
                alertDelete = true
                dispatch({
                    type    : DATA_ALERT_DELETE_CLIENTS,
                    payload : data.messageConfirmDelete
                })

                dispatch({
                    type    : SHOW_MODAL_ALERT_DELETE_CLIENTS,
                    payload : true
                })
            }
            messageConfirm = data.messageConfirmDelete
            response = true

        }else{
            notificationGlobal('warning', data.message)
        }
    }).catch((error)=> {
        console.log(error)
    });

    return { response, alertDelete, messageConfirm }
}


export const EditValueMasterClientManual = ( field, value, id) => async ( dispatch, getState ) => {
    const dataClient = getState().masterClients.rex_data_form_edit_master_client_manual

    dataClient.map((dat, index) => {
        if(dat.id == id){
            let valueForm = value ? value : ''
            dataClient[index]['data'][field] = valueForm
        }
    })

    dispatch({
        type : DATA_FORM_EDIT_MASTER_CLIENT_MANUAL,
        payload : dataClient
    })
}
export const EditValueMasterClient = ( field, value, id ) => async ( dispatch, getState ) => {

    const dataClient = getState().masterClients.rex_data_form_edit_master_client

    dataClient.map((dat, index) => {
        if(dat.id == id){
            let valueForm = value ? value : ''
            dataClient[index]['data'][field] = valueForm
        }
    })

    await dispatch({
        type : DATA_FORM_EDIT_MASTER_CLIENT,
        payload : dataClient
    })
}

export const CancelConfirmDeleteClientsManageReducer  = ( data ) => async ( dispatch, getState) => {

    dispatch({
        type : SHOW_MODAL_ALERT_DELETE_CLIENTS,
        payload : false
    })

    dispatch({
        type : DATA_ALERT_DELETE_CLIENTS,
        payload : []
    })
}

export const FilterMasterClientsManageReducer = (txtSearch, countrySearch) => async ( dispatch, getState ) => {

    const master_clients = getState().masterClients.rex_data_master_clients_manage_clone

    const dataFilter = master_clients.filter( cli => (
        cli.ship_to.toLowerCase().includes(txtSearch.toLowerCase())
    ))

    dispatch({
        type : GET_DATA_MASTER_CLIENTS_MANAGE,
        payload : dataFilter
    })
}

export const GetFiltersMasterClientsManageReducer = () => async ( dispatch, getState) => {

    const filtersTotal      = getState().filtersClients.rex_filters_clients_total.rex_filter_clients

    await fetch(config.api+'manage/pe/filters-master-clients',
        {
            mode:'cors',
            method: 'POST',
            headers: {
                'Accept' 	   : 'application/json',
                'Content-type' : 'application/json',
                'usutoken'      : localStorage.getItem('usutoken')
            },
            body: JSON.stringify({
                req_filter_channel  : filtersTotal.rex_txt_uniqueChannels,
                req_filter_client   : filtersTotal.rex_txt_unique_cliente,
                req_filter_zone     : filtersTotal.rex_txt_unique_zona,
                req_filter_sucursal : filtersTotal.rex_txt_unique_sucursal,
                req_fil_gba         : filtersTotal.rex_txt_unique_gba,
                req_customer_group  : filtersTotal.rex_txt_customer_group
                // req_date            : date
            }),
        }
    )
    .then( async res => {
        return res.json()
    })
    .then(data => {
        if(data.response){


            dispatch({
                type: GET_DATA_FILTERS_CHANNELS_MASTER_PRODUCTS_MANAGE,
                payload : data.uniqueChannels
            })

            dispatch({
                type: GET_DATA_FILTERS_ZONA_MASTER_PRODUCTS_MANAGE,
                payload : data.unique_zona
            })
            
            dispatch({
                type: GET_DATA_FILTERS_CLIENTES_MASTER_PRODUCTS_MANAGE,
                payload : data.unique_cliente
            })
            
            dispatch({
                type: GET_DATA_FILTERS_SUCURSAL_MASTER_PRODUCTS_MANAGE,
                payload : data.unique_sucursal
            })

            dispatch({
                type: GET_DATA_FILTERS_GBA_MASTER_PRODUCTS_MANAGE,
                payload : data.unique_gba
            })
            dispatch({
                type: GET_DATA_FILTERS_CUSTOMER_GROUP_MASTER_PRODUCTS_MANAGE,
                payload : data.unique_customer_group
            })
        }
    }).catch((error)=> {
        console.log(error)
    });
}

export const CambiarTxtFilterMasterClientsReducer = (type, txt_data, dateFilter, view) => async ( dispatch, getState) => {

    const filtersTotal = getState().filtersClients.rex_filters_clients_total

    if(!txt_data){
        txt_data = ""
    }

    if(type == "Customer Group"){
        type = "rex_txt_customer_group"
    }else if(type == "Canal"){
        type = "rex_txt_uniqueChannels"
    }else if(type == "Zona"){
        type = "rex_txt_unique_zona"
    }else if(type == "Cliente HML"){
        type = "rex_txt_unique_cliente"
    }else if(type == "GBA"){
        type = "rex_txt_unique_gba"
    }else{
        type = "rex_txt_unique_sucursal"
    }

    if(view == "clients"){
        const valuesFilter = filtersTotal['rex_filter_clients']
        filtersTotal['rex_filter_clients'] = {...valuesFilter, [type] : txt_data}
        
    }else{
        const valuesFilter = filtersTotal['rex_filter_new']
        filtersTotal['rex_filter_new'] = {...valuesFilter, [type] : txt_data}
    }

    dispatch({
        type : GET_DATA_FILTERS_TXT_CLIENTS_TOTAL,
        payload : filtersTotal
    })

    await dispatch(GetFiltersMasterClientsManageReducer())

    if(view == "clients"){
        await dispatch(GetMasterClientsManageReducer(dateFilter))
    }else{
        await dispatch(GetMasterClientsManualsManageReducer(dateFilter))
    }
}

export const SaveMasterClientsReducer = (date, action) => async ( dispatch, getState ) => {
    
    const filterDate    = date ? dayjs(dayjs(date), "AAAA-MM") : dayjs(dayjs(), "AAAA-MM")
    const filterYear    = filterDate.year()
    const filterMonth   = filterDate.month() + 1

    const confirmDelete = action == "confirm-save-master-clients"

    let response

    await fetch(config.api+'manage/pe/'+action,
        {
            mode:'cors',
            method: 'POST',
            headers: {
                'Accept' : 'application/json',
                'Content-type' : 'application/json',
                'usutoken'	   : localStorage.getItem("usutoken"),
            },
            body: JSON.stringify({
                req_month   : filterMonth,
                req_year    : filterYear
            }),
        }
    )
    .then(res => res.json())
    .then(data => {
        response = data.response
        if(confirmDelete || data.generate){
            notificationGlobal(data.response ? "success" : "warning", data.message)
        }
    }).catch((error) => {
        console.log(error)
    })

    return response
}